import * as types from "../types/campaigns";

const initialState = {
  isLoading: true,
  isPartialLoading: false,
  isCampaignFileLoading: false,
  isError: null,
  isCampaignsLoading: false,
  campaigns: {
    data: [],
  },
  campaign: {},
  isTabLoading: true,
  campaignSummary: {},
  campaignTargetGroups: {},
  campaignMedia: {},
  campaignTheatres: {},
  campaignScreens: {},
  campaignFiles: { isOpen: false, files: [] },
  campaignSpots: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_CAMPAIGN_FILE_LOADING:
      return { ...state, isCampaignFileLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.IS_CAMPAIGNS_LOADING:
      return { ...state, isCampaignsLoading: payload };
    case types.CAMPAIGNS:
      return { ...state, campaigns: payload };
    case types.CAMPAIGN:
      return { ...state, campaign: payload };
    case types.IS_TAB_LOADING:
      return { ...state, isTabLoading: payload };
    case types.CAMPAIGN_SUMMARY:
      return { ...state, campaignSummary: payload };
    case types.CAMPAIGN_TARGET_GROUPS:
      return { ...state, campaignTargetGroups: payload };
    case types.CAMPAIGN_MEDIA:
      return { ...state, campaignMedia: payload };
    case types.CAMPAIGN_THEATRES:
      return { ...state, campaignTheatres: payload };
    case types.CAMPAIGN_SCREENS:
      return { ...state, campaignScreens: payload };
    case types.CAMPAIGN_FILES:
      return { ...state, campaignFiles: payload };
    case types.CAMPAIGN_SPOTS:
      return { ...state, campaignSpots: payload };
    default:
      return state;
  }
}
