import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { values } from "lodash";
import Checkbox from "../Checkbox";
import styles from "../../../styles/FilterSection.module.scss";
import { getTagTypeDisplayName, getTagDisplayName } from "../../../utils";
import { TAG_TYPE } from "../../../constants";
import TagSearch from "./TagSearch";

class SearchFilterSection extends Component {
  static propTypes = {
    tagType: PropTypes.oneOf(values(TAG_TYPE)).isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  onFilterChange = (filter) => {
    this.props.onFilterChange(filter);
    if (filter?.type)
      ReactGA.event({ category: "filter", action: "right-panel-search", label: filter.type });
  };

  render() {
    const { tagType, selected, onFilterChange } = this.props;
    const placeholder = `Search ${getTagTypeDisplayName(tagType)}`;
    return (
      <div>
        <TagSearch
          showSubText
          tagTypes={tagType}
          placeholder={placeholder}
          onSelect={onFilterChange}
        />
        {selected.length > 0 && (
          <div className={styles.selectedList}>
            {selected.map((tag) => (
              <Checkbox
                key={tag.id}
                checked
                label={getTagDisplayName(tag)}
                onChange={() => this.onFilterChange(tag)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default SearchFilterSection;
