import React, { Component } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import { sum } from "lodash";
import { ExpandTable } from "workbench";
import classNames from "classnames";
import StatusIconLegend from "../common/StatusIconLegend";
import SwitchButtonWithText from "../common/SwitchButtonWithText";
import * as constants from "../../constants";
import FilterChips from "../common/Filters/FilterChips";
import TableControls from "../common/TableControls";
import TableLoading from "../common/TableLoading";
import FooterControls from "../common/FooterControls";
import pageStyles from "../../styles/App.module.scss";
import { modifyTableColumns } from "../../utils";

export default class TheatreTable extends Component {
  state = {
    selectedColumns: ["Theatre / Screen", "Chain", "Location", "Theatre ID"],
  };

  static propTypes = {
    selectedColumns: PropTypes.array,
    columns: PropTypes.array,
    bulkActions: PropTypes.array,
    statusList: PropTypes.array,
    tagTypes: PropTypes.array,
    isLoading: PropTypes.bool,
    withToggleSwitch: PropTypes.bool,
    sortable: PropTypes.bool,
    showPagination: PropTypes.bool,
    searchBar: PropTypes.bool,
    showRowSize: PropTypes.bool,
    isFilterable: PropTypes.bool,
  };

  static defaultProps = {
    selectedColumns: [],
    columns: [],
    bulkActions: [],
    statusList: [],
    tagTypes: [constants.TAG_TYPE.THEATRE],
    withToggleSwitch: false,
    isSelectable: false,
    sortable: true,
    showPagination: true,
    searchBar: true,
    showRowSize: true,
    isFilterable: true,
  };

  // componentDidMount() {
  //   const { selectedColumns } = this.props;

  //   this.setState({
  //     selectedColumns: [...this.state.selectedColumns, ...selectedColumns],
  //   });
  // }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  renderStatusIconLegend = () => {
    const { statusList, isPartialLoading } = this.props;
    if (isPartialLoading) {
      return (
        <div
          className={classNames("flex flex-auto flex-wrap", isPartialLoading && Classes.SKELETON)}
        />
      );
    }

    if (sum(statusList.map((status) => status.count)) === 0) return null;

    return (
      <div className={classNames("flex flex-auto flex-wrap")}>
        <StatusIconLegend statusInfo={statusList} onFilterSelect={this.props.onFilterSelect} />
      </div>
    );
  };

  render = () => {
    const {
      match: { params },
      history,
      theatreList,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      onToggleSwitch,
      isPartialLoading,
      isFilterLoading,
      columns,
      searchBar,
      showRowSize,
      isFilterable,
      statusList,
      withToggleSwitch,
      tagTypes,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = theatreList?.totalCount || 0;

    return (
      <div className={pageStyles.pageContainer}>
        <div
          className={classNames(
            "flex flex-auto flex-wrap",
            pageStyles.statusGroup,
            isPartialLoading && pageStyles.statusGroupLoading
          )}
        >
          {this.renderStatusIconLegend(statusList)}
          {withToggleSwitch && (
            <div className="flex align-center justify-end flex-wrap">
              <SwitchButtonWithText
                buttons={[
                  {
                    isActive: params.tabId === "screens",
                    text: "Button.screens",
                    onClick: () => onToggleSwitch("screens"),
                  },
                  {
                    isActive: params.tabId === "theatres",
                    text: "Button.theatres",
                    onClick: () => onToggleSwitch("theatres"),
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
          <TableControls
            searchBar={searchBar}
            columnFilter
            showRowSize={showRowSize}
            pagination
            columns={columns}
            selectedColumns={selectedColumns}
            reorderedColumns={reorderedColumns}
            data={theatreList}
            query={query}
            ps={ps}
            page={page}
            tagTypes={tagTypes}
            onSearchFilterSelect={this.props.onFilterSelect}
            onFilterChange={this.props.onFilterChange}
            onColumnFilterSelect={this.onColumnSelect}
            onRowSizeChange={onQueryChange}
            filters={filters}
          />
          {isFilterable && (
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
          )}
          <ExpandTable
            data={theatreList.data}
            loading={isPartialLoading || isFilterLoading}
            columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            defaultPageSize={constants.DEFAULT_PAGE_SIZE}
            sorted={sort}
            onSortedChange={onSortedChange}
            selectable={false}
            childIdentificationKey="screens"
            LoadingComponent={
              <TableLoading
                columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              />
            }
            onRowClick={({ original }) => history.push(`/theatres/${original.id}/details`)}
            onChildRowClick={({ original }) => history.push(`/screens/${original.id}/details`)}
          />
          <FooterControls
            pagination
            data={theatreList}
            query={query}
            ps={ps}
            page={page}
            onRowSizeChange={onQueryChange}
          />
        </div>
      </div>
    );
  };
}
