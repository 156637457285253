import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { searchAdvertisers, searchCurrencies } from "../../../../../api";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  FORM_FIELD_TYPE,
} from "../../../../../constants";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { CustomMenuItem } from "../../../../common";
import Form from "../../../../common/Form";
import InfoBlock from "../../../../common/InfoBlock";
import Popup from "../../../../common/Popup";
import RemoveIcon from "../../../../common/RemoveIcon";
import { SearchIcon } from "../../../../common/SearchIcon";
import { getDateRange } from "../../utils/campaignCreate";

export default class BasicDetails extends Component {
  state = {
    isFetchingPreferences: false,
    shouldSetCountryData: null,
  };

  static propTypes = {
    campaign: PropTypes.object.isRequired,
    hasFormSaved: PropTypes.bool,
  };

  static defaultProps = {
    hasFormSaved: false,
  };

  // Separate method since we want to set state after preferences are fetched
  onAdvertiserSelect = async (advertiser) => {
    const {
      onInputChange,
      companyPreferences,
      companyId,
      getCompanyAdvertiserPreferences,
    } = this.props;
    this.setState({ isFetchingPreferences: true });

    //SLATE-1399 Nov21 Anushree:- action getCompanyAdvertiserPreferences called which takes two arguments companyId and advertiserId
    const { data: companyAdvertiserPreferences } = getCompanyAdvertiserPreferences(
      companyId,
      advertiser.id
    );
    const currency =
      companyAdvertiserPreferences?.campaignDefaultCurrency ||
      companyPreferences.campaignDefaultCurrency;
    const billingCycle =
      companyAdvertiserPreferences?.campaignDefaultBillingCycle ||
      companyPreferences.campaignDefaultBillingCycle;
    this.setState({ isFetchingPreferences: false });
    if (billingCycle) onInputChange(billingCycle, "billingCycle");
    //SLATE-1418 Dec6 Anushree:- currency is stored as currencyId in state
    //if (currency) onInputChange(currency, "currency");
    if (currency?.id) onInputChange(currency, "currencyId");
    onInputChange(advertiser, "advertiserId");
  };

  // Separate method since we want to confirm before changing currency
  onCurrencySelect = (data, isConfirmed = false) => {
    const { onInputChange, campaign } = this.props;
    if (isConfirmed || isEmpty(campaign?.currencyId) || campaign.currencyId === data.id) {
      onInputChange(data, "currencyId");
      if (isConfirmed) this.setState({ shouldSetCountryData: null });
    } else this.setState({ shouldSetCountryData: data });
  };
  onCountryRemove = (data, isConfirmed = false) => {
    const { campaign, onInputChange, removeCountryItem } = this.props;
    if (isConfirmed || isEmpty(campaign?.countryIds) || !campaign.id) {
      if (data === "clear") onInputChange(null, "countryIds");
      else removeCountryItem(data);
      if (isConfirmed) this.setState({ shouldSetCountryData: null });
    } else this.setState({ shouldSetCountryData: data });
  };
  // onCampaignTypeSelect = (data, isConfirmed = false) => {
  //   const { onInputChange, campaign } = this.props;
  //   if (isConfirmed || isEmpty(campaign?.campaignType) || campaign.campaignType.id === data.id)
  //     onInputChange(data, "campaignType");
  // };

  renderCountryMenuItem = (data, { modifiers, handleClick }) => (
    <CustomMenuItem
      key={data.id}
      active={modifiers.active}
      icon={
        this.props.campaign?.countryIds?.find((c) => c?.id === data.id)
          ? IconNames.TICK
          : IconNames.BLANK
      }
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  render() {
    const { shouldSetCountryData } = this.state;
    const {
      campaign,
      campaignTypes,
      onInputChange,
      hasFormSaved,
      countryList,
      searchCountries,
      campaignTargetGroups,
    } = this.props;

    //SLATE-1399 Nov21 Anushree:- Error should be shown if required details are not filled during campaign creation when id not available
    //const showError = campaign.id && campaign.name && hasFormSaved;
    const showError = campaign.name && hasFormSaved;

    const allowModifyingStartDate = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
      CAMPAIGN_ACTIONS.EDIT_VALIDITY
    ].includes(campaign.status);
    //!Date Range Validation
    const today = moment();
    const isTgPresent = campaignTargetGroups && campaignTargetGroups.length > 0 ? true : false;
    //date range displayed if start date modification allowed and no media present
    const isDateRangeDisplayed = allowModifyingStartDate && !isTgPresent;
    //date range: {from:today, to: undefined}
    const dateRangeMin = new Date(today);
    const dateRangeMax = undefined;
    //! today validation not added in start date from, as truncating/deleting tg not handeled yet
    //start date:  {from: undefined, to: min(tg's start date)}
    const startDateMin = undefined;
    const startDateMax = moment
      .min(campaignTargetGroups?.map((tg) => moment(tg.validity.fromDate)))
      ?.toDate();
    //end date:(tg present)    { from: max(tg end date),to: undefined }
    //end date:(no tg present) { from: fixed_start_date,to: undefined }
    const endDateMin = isTgPresent
      ? moment.max(campaignTargetGroups?.map((tg) => moment(tg.validity.toDate)))?.toDate()
      : new Date(campaign.validity.fromDate);
    const endDateMax = dateRangeMax;

    return (
      <>
        <InfoBlock header="Campaign Details" childClassName={styles.childWrapper}>
          <div
            className={classNames(
              "flex flex-wrap clearfix",
              styles.formContainer,
              styles.basicDetails
            )}
          >
            <Form
              isFormGroup={false}
              config={[
                {
                  id: "name",
                  label: "Campaign Name",
                  type: FORM_FIELD_TYPE.INPUT,
                  className: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  placeholder: "Enter Name",
                  value: campaign.name || "",
                  errorMessage: "Please Enter Campaign Name",
                  error: hasFormSaved && !campaign.name,
                  onChange: (e) => onInputChange(e.target.value, "name"),
                },
                {
                  id: "Campaign_Type",
                  type: FORM_FIELD_TYPE.SELECT,
                  className: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  title: "Campaign Type",
                  list: campaignTypes?.map((item) => ({
                    ...item,
                    value: item.name,
                  })),
                  placeHolder: "Select Campaign Type",
                  showLabelInButton: false,
                  compareKey: "id",
                  textKey: "campaignTargetTypeName",
                  singleSelect: true,
                  usePortal: false,
                  onSelect: ([data]) => {
                    return onInputChange(
                      campaignTypes?.find((t) => t.id === data.id),
                      "campaignTargetTypeId"
                    );
                  },
                  selectedList: !isEmpty(campaign.campaignTargetTypeName)
                    ? [campaign.campaignTargetTypeName]
                    : [],
                  errorMessage: "Please Select Campaign Type",
                  //error: showError && isEmpty(campaign?.campaignType),
                  error: showError && isEmpty(campaign?.campaignTargetTypeName),
                },
                {
                  id: "advertiserId",
                  label: "Advertisers",
                  type: FORM_FIELD_TYPE.API_SEARCH,
                  className: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  placeholder: "Search Advertisers",
                  value: campaign.advertiserName,
                  query: campaign.advertiserName ? campaign.advertiserName : "",
                  errorMessage: "Please Select Advertiser",
                  error: showError && !campaign.advertiserId,
                  fetchAction: (text) => searchAdvertisers(text),
                  parseResults: (response) => response,
                  onSelect: (data) => this.onAdvertiserSelect(data),
                  disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_ADVERTISER
                  ].includes(campaign.status),
                },
                {
                  type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                  formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                  title: "Validity",
                  containerClassName: "col-11",
                  size: {
                    lg: 6,
                    md: 6,
                  },
                  errorMessage: "Please Select Validity",
                  error: showError && isEmpty(campaign?.validity),
                  handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                  dateRange: getDateRange(campaign?.validity),
                  isLabel: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_VALIDITY
                  ].includes(campaign.status),
                  //isHidden: !allowModifyingStartDate,
                  isHidden: !isDateRangeDisplayed,
                  minDate: dateRangeMin,
                  maxDate: dateRangeMax,
                  initialMonth: dateRangeMin,
                },
                {
                  id: "startDate",
                  //type: FORM_FIELD_TYPE.INPUT,
                  type: allowModifyingStartDate ? FORM_FIELD_TYPE.DATE : FORM_FIELD_TYPE.INPUT,
                  label: "Validity (Start Date)",
                  title: "Validity (Start Date)",
                  size: { lg: 2, md: 2 },
                  onChange: (v) => onInputChange([v, null], "validity"),
                  value: !allowModifyingStartDate
                    ? moment(campaign.validity.fromDate).format("MMM DD, YYYY")
                    : new Date(campaign.validity.fromDate),
                  placeHolder: "Start Date",
                  disabled: !allowModifyingStartDate,
                  isHidden: isDateRangeDisplayed,
                  minDate: startDateMin,
                  maxDate: startDateMax,
                  initialMonth: startDateMax,
                  defaultValue: startDateMax,
                },
                {
                  id: "endDate",
                  type: FORM_FIELD_TYPE.DATE,
                  title: "Validity (End Date)",
                  size: { lg: 2, md: 2 },
                  onChange: (v) => onInputChange([null, v], "validity"),
                  value: new Date(campaign.validity.toDate),
                  placeholder: "End Date",
                  //isHidden: allowModifyingStartDate,
                  isHidden: isDateRangeDisplayed,
                  minDate: endDateMin,
                  maxDate: endDateMax,
                  initialMonth: endDateMin,
                  disabled: endDateMax < endDateMin,
                  defaultValue: endDateMin,
                },
                // {
                //   id: "maxPlaysPerScreen",
                //   title: "Max Plays Per Screen",
                //   type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                //   size: {
                //     lg: 6,
                //     md: 6,
                //   },
                //   placeholder: "Enter Plays Per Screen",
                //   value: campaign?.maxPlaysPerScreen ? campaign?.maxPlaysPerScreen : 0,
                //   // errorMessage: "Please Enter Max Plays Per Screen",
                //   // error: showError && !campaign?.maxPlaysPerScreen,
                //   onValueChange: (value) => onInputChange(value, "maxPlaysPerScreen"),
                // },
                // {
                //   id: "budgetedPlays",
                //   title: "Budgeted Plays",
                //   type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                //   size: {
                //     lg: 6,
                //     md: 6,
                //   },
                //   placeholder: "Enter Budgeted Plays",
                //   value: campaign?.budgetedPlays || 0,
                //   onValueChange: (value) => onInputChange(value, "budgetedPlays"),
                // },
                {
                  id: "currency",
                  label: "Currency",
                  title: "Currency",
                  type: FORM_FIELD_TYPE.API_SEARCH,
                  size: {
                    lg: 6,
                    md: 6,
                    sm: 6,
                  },
                  className: "col col-11",
                  placeholder: "Search currency",
                  onSelect: (data) => onInputChange(data, "currencyId"),
                  //SLATE-1418 Dec6 Anushree:- currencyName is returned in api response
                  //query: campaign?.currency?.name ?? "",
                  query: campaign?.currencyName ?? "",
                  searchIcon: "right",
                  value: campaign.currencyName,
                  showSubText: false,
                  parseResults: (data) => {
                    //SLATE-1399 Nov21 Anushree:- parseResults is the function called to parse the data received from fetchAction, from api response data is directly returned
                    //return data?.data.map((item) => {
                    return data?.map((item) => {
                      return {
                        id: item.id,
                        // name: `${item.country} - ${item.currency}`,
                        // name: `${item.countries.map((country) => country.name).join(", ")} - ${
                        //   item.name
                        // }`,
                        name: item.name,
                        tag: item.type,
                        item: item,
                      };
                    });
                  },
                  errorMessage: "Please Select Currency",
                  error: showError && isEmpty(campaign?.currencyId),
                  fetchAction: (text) => searchCurrencies(text),
                  disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_CURRENCY
                  ].includes(campaign.status),
                },
                {
                  id: "countries", // TODO: Need to remove countries and move it to location targeting
                  label: "Country",
                  type: FORM_FIELD_TYPE.MULTI_SELECT,
                  size: {
                    lg: 6,
                    md: 6,
                    sm: 6,
                  },
                  className: `col col-11 ${styles.countrySelector}`,
                  //isHidden: isEmpty(campaign?.currencyId),
                  items: countryList ?? [],
                  onItemSelect: (data) => onInputChange(data, "countryIds"),
                  itemRenderer: this.renderCountryMenuItem,
                  resetOnSelect: true,
                  tagRenderer: (data) => data.name,
                  tagInputProps: {
                    placeholder: "Search Countries",
                    tagProps: { intent: Intent.NONE, minimal: true },
                    onRemove: (data, index) => this.onCountryRemove(index),
                    rightElement:
                      campaign?.countryIds?.length > 0 ? (
                        <RemoveIcon onClick={() => this.onCountryRemove("clear")} />
                      ) : (
                        <SearchIcon />
                      ),
                    disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                      CAMPAIGN_ACTIONS.EDIT_COUNTRY
                    ].includes(campaign.status),
                  },
                  onQueryChange: (text) => searchCountries({ q: text }),
                  selectedItems: campaign?.countries ?? [],
                  value: campaign?.countries?.map((c) => c.name).join(", ") ?? "",
                  errorMessage: "Please Select Country",
                  error: showError && isEmpty(campaign?.countryIds),
                },
                {
                  id: "effectiveRate",
                  title: "Effective Rate",
                  type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  placeholder: "Enter Effective Rate",
                  value: campaign?.effectiveRate,
                  onValueChange: (value) => onInputChange(value, "effectiveRate"),
                  isOptional: true,
                },
              ]}
            />
          </div>
        </InfoBlock>
        <Popup
          isOpen={!!shouldSetCountryData || shouldSetCountryData === 0}
          onClose={() => this.setState({ shouldSetCountryData: null })}
          onConfirm={() => {
            this.onCountryRemove(shouldSetCountryData, true);
          }}
          title="Popup.title"
          body="Popup.campaignCurrencyChangeConfirmation"
        />
      </>
    );
  }
}
