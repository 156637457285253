import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { BUTTON_TYPE } from "prefab";
import { bindDispatch } from "../../../utils";
import LocalizedButton from "../../common/LocalizedButton";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import styles from "../../../styles/Verifications/ViewVerification.module.scss";
import * as constants from "../../../constants";
import BrandVerificationListItem from "./BrandVerificationListItem";
import VerificationDetails from "./utils/VerificationDetails";
import RejectVerification from "./utils/rejectVerification";
import BrandVerificationBreadcrumbs from "./utils/BrandVerificationBreadcrumbs";

export class BrandVerificationView extends Component {
  state = {
    activeBrandId: null,
    activeBrand: null,
    isRejectPanelOpen: false,
  };

  componentDidMount = async () => {
    await this.getBrandVerification();
    await this.props.actions.getBusinessTypesList();
  };

  componentDidUpdate = (prevProps) => {
    //check if data loaded or not
    if (
      prevProps.brandVerifications &&
      prevProps.brandVerifications.isLoading &&
      !this.props.brandVerifications.isLoading
    ) {
      this.openNextPendingTab();
    }
  };

  toggleRejectPanel = () => {
    this.setState({
      isRejectPanelOpen: !this.state.isRejectPanelOpen,
    });
  };

  getNextPendingTab = () => {
    const {
      brandVerifications: { brandVerificationDraft },
    } = this.props;
    const pending = brandVerificationDraft.filter(
      (brand) => brand.status === constants.VERIFICATION_STATUSES.PENDING
    );
    return {
      brandId: pending.length > 0 ? pending[0].id : null,
      brand: pending.length > 0 ? pending[0].brand : null,
    };
  };

  openNextPendingTab = () => {
    const { brandId, brand } = this.getNextPendingTab();
    if (brandId) {
      this.changeActiveBrandId(brandId, brand);
    }
  };

  changeActiveBrandId = (id, brand) =>
    this.setState({ activeBrandId: id, activeBrand: brand }, async () => {
      await this.getBrandDuplicates();
      await this.props.actions.clearGetBrandById();
    });

  renderName = (data) => {
    return [data.parentBrandName, data.individualBrandName, data.productName, data.name]
      .filter(Boolean)
      .join(" · ");
  };

  getBrandIdData = (verification, brandId) => {
    const data = verification.filter((item) => item.id === brandId);
    return data.length === 1 ? data[0] : null;
  };

  getBrandVerification = async () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.mergeId) {
      await actions.initMergeBrandVerification(params);
    } else {
      await actions.getBrandVerification(params);
    }
  };

  getBrandDuplicates = () => {
    const {
      brandVerifications: { brandVerification },
      actions: { fetchDuplicates },
      match: { params },
    } = this.props;
    const { activeBrandId, activeBrand } = this.state;
    fetchDuplicates(activeBrandId, activeBrand, params.companyId, brandVerification.businessType);
  };

  getBrandData = (id, brand) => {
    this.props.actions.getBrandById(id, brand);
  };

  reset = () => {
    const {
      actions: { resetPendingVerification },
      brandVerifications: { brandVerificationDraft },
    } = this.props;
    const { activeBrandId, activeBrand } = this.state;
    resetPendingVerification(brandVerificationDraft, activeBrandId, activeBrand);
  };

  approve = () => {
    const {
      actions: { approvePendingVerification },
      brandVerifications: { brandVerificationDraft },
    } = this.props;
    const { activeBrandId, activeBrand } = this.state;
    approvePendingVerification(
      brandVerificationDraft,
      activeBrandId,
      activeBrand,
      this.openNextPendingTab
    );
  };

  reject = (rejectionParams) => {
    const {
      actions: { rejectPendingVerification },
      brandVerifications: { brandVerificationDraft },
    } = this.props;
    const { activeBrandId, activeBrand } = this.state;
    rejectPendingVerification(
      brandVerificationDraft,
      activeBrandId,
      activeBrand,
      rejectionParams,
      this.openNextPendingTab
    );
  };

  merge = (mergeParams) => {
    const {
      actions: { mergePendingVerification },
      brandVerifications: { brandVerificationDraft },
      match: { params },
    } = this.props;
    const { activeBrandId, activeBrand } = this.state;
    mergePendingVerification(
      brandVerificationDraft,
      activeBrandId,
      activeBrand,
      params.companyId,
      mergeParams,
      this.openNextPendingTab
    );
  };

  submit = () => {
    const {
      actions: { submitPendingVerification },
      brandVerifications: { brandVerificationDraft },
      match: { params },
      userData,
    } = this.props;
    let { companyId } = params;
    if (!companyId) companyId = userData.company.id;
    submitPendingVerification(companyId, brandVerificationDraft, this.goBack, async () => {
      await this.getBrandVerification();
    });
  };

  goBack = () => {
    const {
      brandVerifications: { brandVerificationDraft },
      history,
      match: { params },
    } = this.props;
    if (params.mergeId) {
      const brandToMerge = brandVerificationDraft.find((b) => b.id === params.mergeId);
      history.push(
        `/brands/${constants.BRAND_TAG_TYPE[brandToMerge.brand]}/${brandToMerge.id}/details`
      );
    } else history.push(`/brand-verifications`);
  };

  clear = async () => {
    const {
      actions: { clearPendingVerification },
      brandVerifications: { brandVerification },
    } = this.props;
    await clearPendingVerification(brandVerification);
    this.openNextPendingTab();
  };

  renderBrandVerificationListItem = (brand, activeBrandId, nextPendingTab, allowSubmit) => {
    return (
      <BrandVerificationListItem
        key={brand.id}
        name={this.renderName(brand)}
        status={brand.status}
        isUpdate={brand.activity === constants.VERIFICATION_ACTIONS.UPDATE}
        hasConflict={brand.hasConflict}
        onClick={() => {
          this.changeActiveBrandId(brand.id, brand.brand);
        }}
        isActive={activeBrandId === brand.id}
        // disable if nextPendingTab's brand is lower in hierarchy
        disabled={
          !allowSubmit &&
          constants.BRANDS.indexOf(nextPendingTab.brand) < constants.BRANDS.indexOf(brand.brand)
        }
      />
    );
  };

  render = () => {
    const {
      brandVerifications: {
        brandVerification,
        brandVerificationDraft,
        brandDuplicates,
        brandData,
        isLoading,
        isBrandLoading,
        isConflictsLoading,
      },
      businessTypes: { list },
    } = this.props;

    const { activeBrandId, isRejectPanelOpen } = this.state;

    if (isLoading || !brandVerification.parentBrandId) return <PageLoadingWithTable />;

    const activeBrandData = this.getBrandIdData(brandVerificationDraft, activeBrandId);
    const nextPendingTab = this.getNextPendingTab();
    const allowNext =
      activeBrandData && activeBrandData.status !== constants.VERIFICATION_STATUSES.PENDING;
    const allowSubmit = allowNext && nextPendingTab.brandId === null;

    // Used to toggle Prompt
    const isAllPendingVerifications =
      brandVerificationDraft.filter(
        (brand) => brand.status !== constants.VERIFICATION_STATUSES.PENDING
      ).length < 1;

    const pendingParentBrand = brandVerificationDraft.filter(
      (brand) => brand.brand === constants.BRANDS[0]
    );
    const pendingIndividualBrands = brandVerificationDraft.filter(
      (brand) => brand.brand === constants.BRANDS[1]
    );

    const pendingProducts = brandVerificationDraft.filter(
      (brand) => brand.brand === constants.BRANDS[2]
    );

    const pendingVariants = brandVerificationDraft.filter(
      (brand) => brand.brand === constants.BRANDS[3]
    );

    return (
      <div className="col-12 clearfix">
        <BrandVerificationBreadcrumbs {...this.props} />
        <Prompt
          when={!allowSubmit && !isAllPendingVerifications}
          message={() => `Are you sure you want to leave? All your changes will be lost.`}
        />
        <div className={`clearfix ${styles.verificationContainer}`}>
          <div className={`sm-col sm-col-12 lg-col-4 ${styles.brandsListContainer}`}>
            {pendingParentBrand.length > 0 ? (
              <div className={`${styles.brandSection}`}>
                <h3 className={`${styles.brandTitle}`}>
                  Parent Brand{pendingParentBrand.length > 1 ? "s" : ""}
                </h3>
                {pendingParentBrand.map((brand) =>
                  this.renderBrandVerificationListItem(
                    brand,
                    activeBrandId,
                    nextPendingTab,
                    allowSubmit
                  )
                )}
              </div>
            ) : null}

            {pendingIndividualBrands.length > 0 ? (
              <div className={`${styles.brandSection}`}>
                <h3 className={`${styles.brandTitle}`}>
                  Individual Brand{pendingIndividualBrands.length > 1 ? "s" : ""}
                </h3>
                {pendingIndividualBrands.map((brand) =>
                  this.renderBrandVerificationListItem(
                    brand,
                    activeBrandId,
                    nextPendingTab,
                    allowSubmit
                  )
                )}
              </div>
            ) : null}

            {pendingProducts.length > 0 ? (
              <div className={`${styles.brandSection}`}>
                <h3 className={`${styles.brandTitle}`}>
                  Product{pendingProducts.length > 1 ? "s" : ""}
                </h3>
                {pendingProducts.map((brand) =>
                  this.renderBrandVerificationListItem(
                    brand,
                    activeBrandId,
                    nextPendingTab,
                    allowSubmit
                  )
                )}
              </div>
            ) : null}

            {pendingVariants.length > 0 ? (
              <div className={`${styles.brandSection}`}>
                <h3 className={`${styles.brandTitle}`}>
                  Variant{pendingVariants.length > 1 ? "s" : ""}
                </h3>
                {pendingVariants.map((brand) =>
                  this.renderBrandVerificationListItem(
                    brand,
                    activeBrandId,
                    nextPendingTab,
                    allowSubmit
                  )
                )}
              </div>
            ) : null}
            <div className={`${styles.buttonContainer}`}>
              <LocalizedButton
                text={"Button.submit"}
                buttonType={BUTTON_TYPE.PRIMARY}
                className={styles.submitButton}
                onClick={allowSubmit ? this.submit : () => {}}
                disabled={!allowSubmit}
              />
              <LocalizedButton
                text={"Button.cancel"}
                className={styles.cancelButton}
                onClick={this.goBack}
              />
            </div>
          </div>
          {activeBrandId && (
            <VerificationDetails
              data={activeBrandData}
              brandDuplicates={brandDuplicates}
              brandData={brandData}
              isBrandLoading={isBrandLoading}
              isConflictsLoading={isConflictsLoading}
              getBrandData={this.getBrandData}
              reset={this.reset}
              approve={this.approve}
              reject={this.toggleRejectPanel}
              merge={this.merge}
              businessTypesList={list}
            />
          )}
        </div>
        <RejectVerification
          isOpen={isRejectPanelOpen}
          rejection={{
            rejectionReasonId: activeBrandData && activeBrandData.rejectionReasonId,
            rejectionNotes: activeBrandData && activeBrandData.rejectionNotes,
          }}
          onClose={this.toggleRejectPanel}
          onReject={this.reject}
        />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.brandVerifications,
  (state) => state.businessTypes,
  (state) => state.userData,
  (brandVerifications, businessTypes, userData) => ({
    brandVerifications,
    businessTypes,
    userData: userData.user,
  })
);
export default connect(mapStateToProps, bindDispatch)(BrandVerificationView);
