import * as types from "../types/movies";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  isError: null,
  movies: {
    data: [],
  },
  movie: {},
  movieScreens: {
    data: [],
  },
  movieTheatres: {
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.MOVIES:
      return { ...state, movies: payload };
    case types.MOVIE:
      return { ...state, movie: payload };
    case types.MOVIE_SCREENS:
      return { ...state, movieScreens: payload };
    case types.MOVIE_THEATRES:
      return { ...state, movieTheatres: payload };
    default:
      return state;
  }
}
