import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import classNames from "classnames";
import PageLoadingWithTabs from "../../common/PageLoadingWithTabs";
import { bindDispatch } from "../../../utils";
import PageHeader from "../../common/PageHeader";
import Breadcrumb from "../../common/BreadCrumb";
import TabView from "../../common/Tabs";
// import ContentTableWithFilters from "../content/common/Content";
import CompanyDetails from "./Tabs/Details";
import UserDetails from "./Tabs/Users";

const _LINKS = [{ name: "PageHeader.companies", path: "/companies/media_agencies" }];

export class MediaAgency extends Component {
  state = {
    selectedColumns: ["In Use", "Campaign", "Played Count"],
  };
  componentDidMount = async () => {
    await this.getMediaAgency();
  };

  getMediaAgency = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getMediaAgency(params);
  };

  render = () => {
    const {
      history,
      companies: { mediaAgency },
      match: { params },
    } = this.props;

    const isLoading = isEmpty(mediaAgency);

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={3} />;
    }

    return (
      <div className={classNames("col-12 clearfix")}>
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: mediaAgency.name }]}
          />
        </div>
        <PageHeader name={mediaAgency.name} isLoading={isLoading} />
        <TabView
          id="navbar"
          selectedTabId={params.tabId}
          tabs={[
            {
              id: "details",
              title: "Details",
              goto: `/companies/media_agencies/${mediaAgency.id}/details`,
              panel: <CompanyDetails company={mediaAgency} />,
            },
            {
              id: "users",
              title: "Users",
              count: mediaAgency?.users?.length,
              goto: `/companies/media_agencies/${mediaAgency.id}/users`,
              panel: <UserDetails company={mediaAgency} />,
            },
            // TODO: UNCOMMENT WHEN API IS READY
            // {
            //   id: "content",
            //   title: "Content",
            //   //TODO: Fetch and show actual count, once the api is available
            //   count: 50,
            //   goto: `/companies/media_agencies/${mediaAgency.id}/content`,
            //   panel: (
            //     <ContentTableWithFilters
            //       companyId={mediaAgency.id}
            //       selectedColumns={this.state.selectedColumns}
            //       {...this.props}
            //     />
            //   ),
            // },
          ]}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.companies,
  (companies) => ({ companies })
);

export default connect(mapStateToProps, bindDispatch)(MediaAgency);
