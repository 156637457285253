const IS_LOADING = "brandVerifications/IS_LOADING";
const IS_ERROR = "brandVerifications/IS_ERROR";
const BRAND_VERIFICATIONS = "brandVerifications/BRAND_VERIFICATIONS";
const REJECTED_BRAND_VERIFICATIONS = "brandVerifications/REJECTED_BRAND_VERIFICATIONS";
const BRAND_VERIFICATION = "brandVerifications/BRAND_VERIFICATION";
const BRAND_VERIFICATION_DRAFT = "brandVerifications/BRAND_VERIFICATION_DRAFT";
const BRAND_DUPLICATES = "brandVerifications/BRAND_DUPLICATES";
const REJECTED_BRAND_VERIFICATION = "brandVerifications/REJECTED_BRAND_VERIFICATION";
const BRAND_DATA = "brandVerification/BRAND_DATA";
const IS_BRAND_LOADING = "brandVerification/IS_BRAND_LOADING";
const IS_CONFLICTS_LOADING = "brandVerification/IS_CONFLICTS_LOADING";

export {
  IS_LOADING,
  IS_ERROR,
  BRAND_VERIFICATIONS,
  REJECTED_BRAND_VERIFICATIONS,
  BRAND_VERIFICATION,
  BRAND_VERIFICATION_DRAFT,
  BRAND_DUPLICATES,
  REJECTED_BRAND_VERIFICATION,
  BRAND_DATA,
  IS_CONFLICTS_LOADING,
  IS_BRAND_LOADING,
};
