import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, modifyTableColumns } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import PageHeader from "../../common/PageHeader";
import HeaderButton from "../../common/HeaderButtons";

const columns = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "order",
    Header: "Order",
    width: 150,
    accessor: "order",
  },
];

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.businessTypes" }];

export class BusinessTypes extends Component {
  state = {
    selectedColumns: ["Name", "Code", "Order"],
  };

  static propTypes = { businessTypes: PropTypes.object };

  componentDidMount = () => {
    this.props.actions.getBusinessTypesList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      businessTypes: { list = [], isLoading = false },
      history,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          name="PageHeader.businessTypes"
          isLoading={isLoading}
          count={list.length}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              pageSize={list.length}
              sortable={false}
              data={list.map((l, i) => ({ ...l, order: i + 1 }))}
              loading={isLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.businessTypes,
  (businessTypes) => ({ businessTypes })
);

export default connect(mapStateToProps, bindDispatch)(BusinessTypes);
