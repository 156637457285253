import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import * as Sentry from "@sentry/browser";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, modifyTableColumns, createdAtColumn } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import styles from "../../../styles/Logs.module.scss";
import PageHeader from "../../common/PageHeader";
import WithFetchList from "../../hoc/withFetchList";
import FilterChips from "../../common/Filters/FilterChips";
import TableControls from "../../common/TableControls";
import TableLoading from "../../common/TableLoading";
import FooterControls from "../../common/FooterControls";
import { SPECIAL_CHARACTERS, TAG_TYPE } from "../../../constants";
import { logConfig } from "./logConfig";

class AuditLogs extends Component {
  state = { selectedColumns: ["Done On", "Done By", "Action", "Updates"], sort: [] };

  componentDidMount = () => {
    const { match } = this.props;
    this.props.fetchData(null, false, [{ matchParams: match.params }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  getName = () => {
    const {
      logs: { logInfo },
      match,
    } = this.props;
    const config = logConfig[logInfo?.source];
    const parentName = config?.parentRoute?.displayName ?? "";
    if (match.params.route === "source") {
      return { parentName, childName: config?.displayName ?? "" };
    } else {
      let childName = "";
      if (config?.displayName) childName += config.displayName;
      if (logInfo?.displayName && config?.displayName)
        childName += ` ${SPECIAL_CHARACTERS.MIDDLE_DOT}`;
      if (logInfo?.displayName) childName += logInfo.displayName;
      return { parentName, childName };
    }
  };

  getRoute = () => {
    const {
      logs: { logInfo },
    } = this.props;
    const config = logConfig[logInfo?.source];
    if (!config) {
      const source = logInfo?.source;
      if (source) {
        // Source is correct but no Log Config. Raise alert!
        Sentry.captureMessage(`🚨 No log config found for the source - ${source} 🚨`);
      }
    }
    const parentRoute = config?.parentRoute?.route ?? "/";
    return { parentRoute, childRoute: config?.route ?? "/" };
  };

  render() {
    const {
      logs: { log, source, isLoading, isPartialLoading },
      history,
      match,
      filters,
      ps,
      page,
      sort,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const columns = [
      createdAtColumn("Done On"),
      {
        id: "userName",
        Header: "Done By",
        accessor: (d) => (
          <span
            className={styles.active}
            onClick={() => this.props.onFilterIdSelect(d.userInfo.userId)}
          >
            {d.userInfo.userName ? d.userInfo.userName : "-"}
          </span>
        ),
      },
      {
        id: "event",
        Header: "Action",
        accessor: (d) => (
          <span className={styles.active} onClick={() => this.props.onFilterIdSelect(d.eventTag)}>
            {d.event ? d.event : "-"}
          </span>
        ),
      },
      {
        id: "data",
        Header: "Updates",
        sortable: false,
        accessor: "data",
        Cell: (prop) => (
          <pre className={`left-align ${styles.pre}`}>
            {prop.value ? JSON.stringify(prop.value, null, 4) : "-"}
          </pre>
        ),
      },
    ];
    const query = queryString.parse(history.location.search);
    const getName = this.getName(),
      getRoute = this.getRoute();
    const tableData = match.params.route === "source" ? source : log;
    const data = tableData.data || [];
    const [parentLink, ...rest] = [
      { name: getName.parentName, path: getRoute.parentRoute },
      { name: getName.childName, path: getRoute.childRoute },
      { name: "Audit Logs" },
    ];
    const { selectedColumns, reorderedColumns } = this.state;
    return (
      <div className="col-12 clearfix">
        <Breadcrumb
          className="h5"
          history={history}
          isLoading={isLoading}
          links={[...[getName.parentName ? parentLink : ""], ...[...rest]].filter(Boolean)}
        />
        <PageHeader
          name={`Logs of ${getName.childName}`}
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={tableData.totalCount}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              query={query}
              ps={ps}
              page={page}
              filters={filters}
              data={tableData}
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              tagTypes={[TAG_TYPE.EVENT, TAG_TYPE.CREATED_AT]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={tableData.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading}
            />
            <Table
              pageSize={(query && parseInt(query.ps)) || 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={data}
              loading={isPartialLoading || isFilterLoading}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              sorted={sort}
              onSortedChange={onSortedChange}
              manual
            />
            <FooterControls
              pagination
              query={query}
              ps={ps}
              page={page}
              data={tableData}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const AuditLogsList = WithFetchList("getAuditLogs", {
  sort: [{ id: "createdAt", desc: true }],
})(AuditLogs);

const mapStateToProps = createSelector(
  (state) => state.logs,
  (logs) => ({ logs })
);

export default connect(mapStateToProps, bindDispatch)(AuditLogsList);
