import moment from "moment";

const diffBetweenDays = (from, to) => {
  const timeDiff = new Date(to).getTime() - new Date(from).getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return isNaN(diffDays) ? -1 : diffDays;
};

const formatDate = (date, format = "MMM DD, YYYY", locale = "en") => {
  return moment(date)
    .locale(locale)
    .format(Array.isArray(format) ? format[0] : format);
};

const parseDate = (str, format = "MMM DD, YYYY", locale = "en") => {
  const m = moment(str, format, locale, true);
  return m.toDate();
};

export { diffBetweenDays, formatDate, parseDate };
