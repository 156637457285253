import React from "react";
import { isEmpty } from "lodash";
import InfoBlock from "../../../../../common/InfoBlock";
import PageStyles from "../../../../../../styles/App.module.scss";
import TableInfoBlock from "../../../../../common/TableInfoBlock";

const renderAddress = (address, city, province, country) => {
  return (
    <div>
      <i>Street Address</i>: {address.street},{address.area}
      <br />
      <i>City</i>: {city.name},<br />
      <i>State</i>: {province.name},<br />
      <i>Country</i>: {country.name},<br />
      <i>Post Code</i>: {address.postalCode}
    </div>
  );
};

const renderTicketPrices = (seatingClasses) => {
  if (isEmpty(seatingClasses)) return "-";

  return seatingClasses.map((seatingClass, index) => {
    return (
      <div key={index}>
        <i>{seatingClass.name}</i>
        &nbsp;
        {seatingClass.value}
        <br />
      </div>
    );
  });
};

const screenDetails = (screen) => {
  const concatItems = (list, accessor = "name") =>
    !isEmpty(list) ? list.map((item) => item[accessor]).join(", ") : "-";
  const withDefaultValues = (data) => data ?? "-";

  return (
    <div className={PageStyles.pageContainer}>
      <InfoBlock
        header={"Screen Details"}
        config={[
          { title: "Screen number", value: screen.number },
          { title: "Screen name", value: screen.name },
          { title: "Screen ID", value: screen.id },
          { title: "DAVP TH Code", value: screen.davpCode },
          { title: "Screen ERP ID", value: screen.erpId },
          {
            title: "Experience",
            value: concatItems(screen?.threeDModels ?? []),
          },
          {
            title: "Sound Mix",
            value: concatItems(screen?.soundMixes ?? []),
          },
          {
            title: "Digital Integrator",
            value: withDefaultValues(screen?.digitalIntegrator?.name),
          },
          {
            title: "Projection Type",
            value: concatItems(screen?.projectionTypes ?? []),
          },
          { title: "Cooling Type", value: withDefaultValues(screen?.coolingType?.name) },
          {
            title: "Auditorium Dimensions",
            value: `W: ${screen?.width || 0} ft x H: ${screen?.height || 0} ft x L: ${
              screen?.length || 0
            } ft`,
          },
          {
            title: "Ticket prices",
            value: renderTicketPrices(screen?.seatingClasses),
          },
          {
            title: "Currency",
            value: concatItems(screen?.currencies ?? [], "code"),
          },
          { title: "Auditorium Capacity", value: withDefaultValues(screen?.seatingCapacity) },
          { title: "Average occupancy", value: `${screen?.averageOccupancyInPercentage || 0}%` },
        ]}
      />
      <TableInfoBlock
        header="Taxes"
        data={screen.taxes}
        isLargeHeader={false}
        columns={[
          {
            Header: "Code",
            accessor: "code",
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Percentage",
            accessor: "taxPercentage",
          },
          {
            Header: "Description",
            accessor: "description",
          },
        ]}
      />
      <InfoBlock
        header={"Theatre Details"}
        config={[
          { title: "Theatre name", value: screen.theatre.name },
          { title: "Theatre ID", value: screen.theatre.id },
          { title: "Chain name", value: withDefaultValues(screen?.chain?.name) },
          { title: "Theatre type", value: withDefaultValues(screen?.theatre?.type?.name) },
          {
            title: "Theatre location type",
            value: withDefaultValues(screen.theatre?.locationType?.name),
          },
          {
            title: "Address",
            value: renderAddress(screen.address, screen.city, screen.province, screen.country),
          },
        ]}
      />
    </div>
  );
};

export default screenDetails;
