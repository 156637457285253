import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import pageStyles from "../../../../../../styles/App.module.scss";
import { bindDispatch, modifyTableColumns, dateValidity, titleCase } from "../../../../../../utils";
import * as constants from "../../../../../../constants";
import FilterChips from "../../../../../common/Filters/FilterChips";
import ActionIcon from "../../../../../common/ActionIcon";
import StatusIconLegend from "../../../../../common/StatusIconLegend";
import LocalizedButton from "../../../../../common/LocalizedButton";
import WithFetchList from "../../../../../hoc/withFetchList";
import TableControls from "../../../../../common/TableControls";
import FooterControls from "../../../../../common/FooterControls";
import checkScopeAuth from "../../../../../../utils/checkScopeAuth";
import EditRight from "./editRight";

class ScreenRightsList extends Component {
  state = {
    selectedColumns: ["Screen", "Company Name", "Rights", "Playlist · Pack · Segment", "Validity"],
  };

  componentDidMount = () => {
    const {
      history: { location },
      match: { params },
      actions,
      fetchData,
    } = this.props;

    if (params.action) {
      if (params.action === "new") {
        actions.createScreenRight();
        fetchData();
      } else {
        // Enable if unique id is available for an item
        //actions.editScreenRightById(params.action);
        this.props.history.replace(
          `/theatres/${params.screenId}/${params.tabId}${location.search}`
        );
      }
    } else fetchData(null, false, [{ screenId: params.screenId }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (right) => {
    const {
      history: { location },
      actions,
    } = this.props;
    const params = queryString.parse(location.search);
    actions.saveScreenRight(params, {}, right);
  };

  createRight = () => {
    const {
      actions,
      history,
      match: { params },
      history: { location },
    } = this.props;
    actions.createScreenRight();
    history.replace(`/screens/${params.screenId}/${params.tabId}/new${location.search}`);
  };

  editRight = (right) => {
    const { actions } = this.props;
    actions.editScreenRight(right);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      match: { params },
      history: { location },
    } = this.props;
    actions.cancelEditScreenRight();
    history.replace(`/screens/${params.screenId}/${params.tabId}${location.search}`);
  };

  renderPlaylistPackSegments = (d) => {
    return (
      <div>
        {d.playlistTemplateName}
        {d.playlistTemplateName && d.playlistPackTypeName && <span>&nbsp;·&nbsp;</span>}
        {d.playlistPackTypeName}
        {(d.playlistTemplateName || d.playlistPackTypeName) && d.segmentName && (
          <span>&nbsp;·&nbsp;</span>
        )}
        {d.segmentName}
      </div>
    );
  };

  renderStatusIconLegend = (data) => {
    const dataStatus = data.map((item) => item.status);
    const statusInfo = constants.RIGHT_STATUS_LIST.map((status) => ({
      name: status.displayName,
      count: dataStatus.filter((d) => d === status.id).length,
      type: constants.STATUS_ICON_TYPES.CIRCLE,
      color: status.color,
      tagType: constants.TAG_TYPE.RIGHT_STATUS,
      id: status.id,
    }));

    return <StatusIconLegend statusInfo={statusInfo} onFilterSelect={this.props.onFilterSelect} />;
  };

  render = () => {
    const {
      history,
      screens: { screenRights, isScreenRightsLoading, screenRightsEdit },
      userData,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    const { isOpen, isEdit, right, rights, companies } = screenRightsEdit;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = screenRights.totalCount || 0;

    const actionButtonProps = {
      scope: constants.SCOPES.RIGHTS,
      scopeAction: constants.SCOPE_ACTIONS.APPROVE,
      userData,
    };

    const hasApprovalScope = checkScopeAuth(
      userData,
      constants.SCOPES.RIGHTS,
      constants.SCOPE_ACTIONS.APPROVE
    );

    const columns = [
      {
        id: "companyName",
        Header: "Company Name",
        accessor: (d) => d.companyName,
      },
      {
        id: "rightCode",
        Header: "Rights",
        accessor: (d) => (d.rightCode ? titleCase(d.rightCode) : "-"),
      },
      {
        id: "playlistPackSegment",
        Header: "Playlist · Pack · Segment",
        accessor: (d) => (d.playlistTemplateName ? this.renderPlaylistPackSegments(d) : "-"),
        sortable: false,
      },
      {
        id: "updatedAt",
        Header: "Validity",
        accessor: (d) => dateValidity({ ...d.validity, status: d?.status?.toLowerCase() ?? "" }),
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (d) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.update",
                onClick: () => {
                  this.editRight(d.original);
                },
                iconName: "EditIcon",
              },
            ]}
          />
        ),
      },
    ].filter((column) => column.id !== "icon" || hasApprovalScope);

    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
        {screenRights.data.length > 0 && (
          <div className={pageStyles.statusGroup}>
            <div className="col-6">{this.renderStatusIconLegend(screenRights.data)}</div>
            <div className="col-6 flex align-center justify-end flex-wrap">
              <LocalizedButton
                iconName="AddFilledIcon"
                onClick={this.createRight}
                text="Button.assignRights"
                {...actionButtonProps}
              />
            </div>
          </div>
        )}
        <TableControls
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={screenRights}
          query={query}
          ps={ps}
          page={page}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />
        <Table
          data={screenRights.data}
          loading={isScreenRightsLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
        />
        <FooterControls
          pagination
          data={screenRights}
          query={query}
          userData={userData}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
          addButton
          addButtonScope={actionButtonProps.scope}
          addButtonScopeAction={actionButtonProps.scopeAction}
          buttonLabel="Button.assignRights"
          addNewField={this.createRight}
        />
        <EditRight
          isOpen={isOpen}
          isEdit={isEdit}
          right={right}
          rights={rights}
          companies={companies}
          onClose={this.cancelEdit}
          onSave={this.save}
        />
      </div>
    );
  };
}

export const ScreenRightsListWithFilters = WithFetchList("getScreenRights", {
  sort: [
    {
      id: "statusRank",
      desc: false,
    },
    {
      id: "updatedAt",
      desc: true,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(ScreenRightsList);

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({ screens, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(ScreenRightsListWithFilters));
