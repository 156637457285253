import React from "react";
import { Position } from "@blueprintjs/core";
import { BUTTON_TYPE } from "prefab";
import { SPECIAL_CHARACTERS, SCOPE_ACTIONS, SCOPES } from "../../../../../constants";
import { ActiveTableCellRowFromArray } from "../../../../common/ActiveTableCell";
import { getTimeFromSeconds } from "../../../../../utils/timeUtils";
import LocalizedButton from "../../../../common/LocalizedButton";
import styles from "../../../../../styles/Content/cpls.module.scss";
import { CORE_BASE_URL } from "../../../../../config";

const getBasicCPLColumns = () => [
  {
    id: "name",
    Header: "CPL Name",
    accessor: "name",
    Cell: (prop) => <span className={styles.name}>{prop.value}</span>,
  },
  {
    id: "id",
    Header: "CPL ID",
    accessor: "id",
    sortable: false,
  },
  {
    id: "durationInSeconds",
    Header: "Duration",
    accessor: (d) => getTimeFromSeconds(d.durationInSeconds),
  },
];

const getMapCPLColumns = (openMapCPL) => [
  {
    id: "brand",
    Header: "Brand",
    scope: SCOPES.COMPOSITIONS,
    scopeAction: SCOPE_ACTIONS.MAP,
    width: 175,
    sortable: false,
    accessor: (d) => (
      <div className={styles.mapButtonWrapper}>
        <LocalizedButton
          id="add"
          iconName="AddIcon"
          onClick={() => openMapCPL(d)}
          text="Button.map"
          buttonType={BUTTON_TYPE.LINK}
        />
      </div>
    ),
  },
  {
    id: "advertisers",
    Header: "Advertisers",
    scope: SCOPES.COMPOSITIONS,
    scopeAction: SCOPE_ACTIONS.MAP,
    width: 175,
    sortable: false,
    accessor: (d) => (
      <div className={styles.mapButtonWrapper}>
        <LocalizedButton
          id="map"
          iconName="AddIcon"
          buttonType={BUTTON_TYPE.LINK}
          onClick={() => openMapCPL(d)}
          text="Button.map"
        />
      </div>
    ),
  },
];

const getBrandColumn = (onFilterIdSelect) => ({
  id: "parentBrandName;individualBrandName;productName;variantName",
  Header: "Brand",
  accessor: (d) => {
    const type = d.mappedResourceType;
    if (type === "movies") return null;
    const brandList = [{ name: d.parentBrandName, id: d.parentBrandId }];
    if (type !== "parentBrand") {
      brandList.push({ name: d.individualBrandName, id: d.individualBrandId });
    }
    if (type === "product" || type === "variant")
      brandList.push({ name: d.productName, id: d.productId });
    if (type === "variant") brandList.push({ name: d.variantName, id: d.variantId });
    return ActiveTableCellRowFromArray(brandList, onFilterIdSelect, SPECIAL_CHARACTERS.MIDDLE_DOT);
  },
});

const getViewCPLIcon = (props, token) => ({
  id: "view",
  toolTip: props.original.hasXml ? "Tooltip.view" : "Tooltip.xmlNotAvailable",
  toolTipPosition: Position.TOP_RIGHT,
  onClick: () => {
    if (props.original.hasXml) {
      window.open(`${CORE_BASE_URL}cpls/${props.original.id}/xml?token=${token}`, "_blank");
    }
  },
  iconName: "ViewIcon",
  className: `viewButton ${!props.original.hasXml ? styles.disabledButton : ""}`,
});

export { getBrandColumn, getBasicCPLColumns, getMapCPLColumns, getViewCPLIcon };
