import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import moment from "moment";
import { Table } from "workbench";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, modifyTableColumns, formatDateTime } from "../../../utils";
import * as constants from "../../../constants";
import FilterChips from "../../common/Filters/FilterChips";
import withFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import FooterControls from "../../common/FooterControls";
import ActionIcon from "../../common/ActionIcon";
import UserTableAccessor from "../../common/UserTableAccessor";
import { ActiveTableCell } from "../../common/ActiveTableCell";

class AdminGenerated extends Component {
  state = {
    selectedColumns: [
      "Report Requested",
      "Date Range",
      "Filters Applied",
      "Requested By",
      "Requested On",
      "Generated On",
    ],
  };

  componentDidMount() {
    this.props.fetchData();
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render() {
    const { history, reports, filters, ps, page, onQueryChange } = this.props;

    const { selectedColumns, reorderedColumns } = this.state;
    const { adminGenerated, isLoading } = reports;
    if (isLoading)
      return (
        <PageLoadingWithTable
          showBreadcrumb={false}
          tableRowsCount={constants.DEFAULT_LOADING_ROW_COUNT}
        />
      );

    const query = queryString.parse(history.location.search);
    const activeTableCell = (id, name) =>
      ActiveTableCell(id, name, () => this.props.onFilterIdSelect(id));

    const columns = [
      {
        id: "name",
        Header: "Report Requested",
        accessor: (d) => activeTableCell(d.id, d.name),
      },
      {
        id: "dateRange",
        Header: "Date Range",
        accessor: (d) =>
          `${moment(d.fromDate).format("MMM DD, YYYY")} - ${moment(d.toDate).format(
            "MMM DD, YYYY"
          )}`,
      },
      {
        id: "filters",
        Header: "Filters Applied",
        sortable: false,
        accessor: (d) => d.filters.map((f) => f.displayName).join(", "),
      },
      {
        id: "requestedBy",
        Header: "Requested By",
        accessor: UserTableAccessor("requestedBy", this.props.onFilterIdSelect),
      },
      {
        id: "requestedAt",
        Header: "Requested On",
        showTimeZone: true,
        accessor: "requestedAt",
        Cell: (prop) => formatDateTime(prop.value),
      },
      {
        id: "generatedAt",
        Header: "Generated On",
        showTimeZone: true,
        accessor: "generatedAt",
        Cell: (prop) => formatDateTime(prop.value),
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        accessor: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                onClick: () => this.download(props),
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={adminGenerated}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[constants.TAG_TYPE.REPORTS]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={adminGenerated.totalCount}
          onFilterChange={this.props.onFilterChange}
          loading={isLoading}
        />
        <Table
          data={adminGenerated.data || []}
          defaultPageSize={ps}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
        />
        <FooterControls
          pagination
          data={adminGenerated}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={this.onQueryChange}
        />
      </div>
    );
  }
}

export const AdminGeneratedList = withFetchList("getAdminReports", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(AdminGenerated);

const mapStateToProps = createSelector(
  (state) => state.reports,
  (reports) => ({ reports })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(AdminGeneratedList));
