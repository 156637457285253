import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup } from "@blueprintjs/core";
import { BUTTON_TYPE } from "prefab";
import LocalizedButton from "../common/LocalizedButton";
import styles from "../../styles/Switch.module.scss";

const SwitchButtonWithText = ({ label, buttons }) => (
  <div className="flex">
    <div className={`flex ${styles.buttonGroupLabel}`}>{label}</div>
    <ButtonGroup className={styles.toggle}>
      {buttons.map(({ isActive, ...rest }, i) => {
        return (
          <LocalizedButton
            key={i}
            buttonType={isActive ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
            {...rest}
          />
        );
      })}
    </ButtonGroup>
  </div>
);
SwitchButtonWithText.propTypes = {
  label: PropTypes.string,
  buttons: PropTypes.array,
};

SwitchButtonWithText.defaultProps = {
  label: "VIEW AS",
  buttons: [],
};
export default SwitchButtonWithText;
