import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import { bindDispatch, modifyTableColumns } from "../../../../utils";
import pageStyles from "../../../../styles/App.module.scss";
import * as constants from "../../../../constants";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import PageHeader from "../../../common/PageHeader";
import FilterChips from "../../../common/Filters/FilterChips";
import WithFetchList from "../../../hoc/withFetchList";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import MapBrand from "../common/MapBrand";
import getTableColumns from "./utils/getTableColumns";
import getHeaderButtons from "./utils/getHeaderButtons";
import getPageTitle from "./utils/getPageTitle";
import getStatusFromParams from "./utils/getStatusParams";
import getTagTypes from "./utils/getTagTypes";

class CPLs extends Component {
  state = {
    selectedColumns: [
      "CPL Name",
      "CPL ID",
      "Duration",
      "Brand",
      "Advertisers",
      "Movie",
      "Language",
      "Release Date",
      "Synced On",
      "Mapped By",
      "Mapped On",
    ],
  };

  componentDidMount = async () => {
    const {
      match: { params },
      actions,
      fetchData,
    } = this.props;
    const status = getStatusFromParams(this.props);
    if (params.action) {
      if (params.action === "map") {
        actions.openMapCPLId(params.cplId);
        await fetchData(null, false, [{ status }], null);
      }
    } else await fetchData(null, true, [{ status }], null);
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.match.params.status !== prevProps.match.params.status) {
      const status = getStatusFromParams(this.props);
      await this.props.fetchData(null, false, [{ status }], null);
    }
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  ignoreCPL = (props) => {
    this.props.actions.ignoreCPL(props.original.id);
  };

  cancelMap = (mapSuccess = false) => {
    const {
      actions,
      history,
      history: { location },
      match: { params },
    } = this.props;
    actions.cancelMapCPL();
    history.replace(
      mapSuccess
        ? `/cpl_mappings/mapped${location.search}`
        : `/cpl_mappings/${params.status || "unmapped"}${location.search}`
    );
  };

  onSaveMap = (mapSuccess = false) => {
    this.cancelMap(mapSuccess);
    const status = getStatusFromParams(this.props);
    this.props.fetchData(null, true, [{ status }], null);
  };

  openMapCPL = (cpl) => {
    const {
      actions,
      history,
      history: { location },
      match: { params },
    } = this.props;
    actions.openMapCPL(cpl);
    history.replace(`/cpl_mappings/${params.status || "unmapped"}/${cpl.id}/map${location.search}`);
  };

  map = (map) => {
    const {
      actions,
      cpls: {
        mapCPL: { cpl },
      },
    } = this.props;
    actions.mapCPL(
      cpl.id,
      {
        cplId: cpl.id,
        ...map,
        advertiserIds: map.advertisers.map((a) => a.id),
      },
      this.onSaveMap
    );
  };

  render = () => {
    const {
      history,
      cpls: { cpls, isLoading, isPartialLoading, mapCPL },
      userData,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      onFilterIdSelect,
    } = this.props;
    if (isLoading) return <PageLoadingWithTable />;
    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, isEditLoading, cpl } = mapCPL;
    const query = queryString.parse(history.location.search);
    const resultsCount = cpls.totalCount || 0;
    const statusParam = getStatusFromParams(this.props);
    const columns = getTableColumns(
      statusParam,
      userData,
      onFilterIdSelect,
      this.openMapCPL,
      this.ignoreCPL
    );
    return (
      <div>
        <PageHeader
          name={getPageTitle(this.props)}
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={cpls.totalCount}
          renderRightSideComponent={getHeaderButtons(this.props)}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={cpls}
              query={query}
              ps={ps}
              page={page}
              tagTypes={getTagTypes(this.props)}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <Table
              data={cpls.data}
              loading={isPartialLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              data={cpls}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
        <MapBrand
          isLoading={isEditLoading}
          isOpen={isOpen}
          data={cpl}
          onClose={this.cancelMap}
          onSave={this.map}
          showCpl
        />
      </div>
    );
  };
}
export const CPLsWithFilters = WithFetchList("getCPLs", {
  sort: [{ id: "syncedAt", desc: true }],
})(CPLs);

const mapStateToProps = createSelector(
  (state) => state.cpls,
  (state) => state.userData,
  (cpls, userData) => ({ cpls, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CPLsWithFilters);
