import React from "react";
import InfoBlock from "../../../common/InfoBlock";

const getBillingInfo = (billingInfo, header = "Billing Details") =>
  billingInfo && (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Biller Name",
          value: billingInfo.billerName,
        },
        {
          title: "Billing Address",
          value: billingInfo.billingAddress,
          size: {
            lg: 8,
          },
        },
        {
          title: "Billing Cycle",
          value: billingInfo.billingCycleName,
          size: {
            sm: 6,
            xs: 6,
          },
        },
        {
          title: "Payment Term",
          value: `${billingInfo.paymentTermInDays} Days`,
          size: {
            sm: 6,
            xs: 6,
          },
        },
        {
          title: "Advance Amount",
          value: billingInfo.isAdvanceAmountRequired ? billingInfo.advanceAmount : "Not Required",
          size: {
            sm: 6,
            xs: 6,
          },
        },
        {
          title: "Taxes",
          //SLATE-1374 Nov15-2023 Anushree:- exemptTax replaced with isExemptTax
          //value: billingInfo.exemptTax ? "Exempted" : "Not Exempted",
          value: billingInfo.isExemptTax ? "Exempted" : "Not Exempted",
          size: {
            sm: 6,
            xs: 6,
          },
        },
        {
          title: "Purchase Order Number",
          value: billingInfo.purchaseOrderNumber,
          size: {
            sm: 6,
            xs: 6,
          },
        },
      ]}
    />
  );

export default getBillingInfo;
