import React, { Component } from "react";
import PropTypes from "prop-types";
import { Classes, Button } from "@blueprintjs/core";
import classNames from "classnames";
import pageHeaderStyles from "../../styles/PageHeader.module.scss";
import TabView from "./Tabs";
import BreadcrumbLoading from "./BreadcrumbLoading";
import InfoBlockLoading from "./InfoBlockLoading";

export default class PageLoadingWithTabs extends Component {
  static propTypes = {
    showBreadcrumb: PropTypes.bool,
    tabsCount: PropTypes.number,
  };

  static defaultProps = {
    showBreadcrumb: false,
    tabsCount: 4,
  };

  render = () => {
    const { showBreadcrumb, tabsCount } = this.props;

    const tabs = Array(tabsCount)
      .fill({})
      .map((item, index) => {
        return {
          id: `tab${index + 1}`,
          title: (
            <p>
              <span className={`inline ${Classes.SKELETON}`}>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              </span>
            </p>
          ),
          panel: <InfoBlockLoading />,
          disabled: true,
        };
      });

    return (
      <div className="col-12 clearfix">
        {showBreadcrumb && <BreadcrumbLoading />}
        <div className={`flex flex-wrap col-12 clearfix ${pageHeaderStyles.pageHeader}`}>
          <div className="flex flex-auto flex-wrap">
            <div className={`flex ${pageHeaderStyles.header}`}>
              <div className={classNames("flex h2", pageHeaderStyles.headerText, Classes.SKELETON)}>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              </div>
            </div>
          </div>
          <div className={`flex ${pageHeaderStyles.headerButtonContainer}`}>
            <div className="flex-auto" />
            <div className="flex">
              <Button
                className={Classes.SKELETON}
                text="&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"
              />
            </div>
          </div>
        </div>
        <TabView
          animate
          id="navbar"
          large
          selectedTabId="tab1"
          renderActiveTabPanelOnly
          tabs={tabs}
          isLoader
        />
      </div>
    );
  };
}
