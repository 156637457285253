import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditReason extends Component {
  constructor(props) {
    super(props);
    this.state = { reason: { type: "", reason: "" }, hasEditError: false };
  }

  static propTypes = {
    reason: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  componentDidUpdate(prevProps) {
    if (this.props.reason !== prevProps.reason) this.setState({ reason: this.props.reason });
  }

  cancelEdit = () => {
    this.setState({ hasEditError: false });
    this.props.onClose();
  };

  isValid = (reason) => {
    const { types } = this.props;
    return types.includes(reason.type) && reason.reason.trim().length !== 0;
  };

  save = () => {
    const { reason } = this.state;
    if (this.isValid(reason)) {
      this.props.onSave(reason);
      this.cancelEdit();
    } else {
      return this.setState({ hasEditError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    const { reason } = this.state;
    this.setState({
      reason: { ...reason, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen, types } = this.props;
    const { reason = { type: "", reason: "" }, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={reason.id ? "RightPanelHeader.editReason" : "RightPanelHeader.addReason"}
        showFooter={true}
        isEdit={reason.id ? true : false}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.SELECT,
              title: "Type",
              label: "",
              list: types,
              selectedList: reason.type ? [reason.type] : [],
              placeHolder: "Select Type",
              singleSelect: true,
              error: hasEditError && reason.type.length === 0,
              errorMessage: "Select Valid Type",
              showLabelInButton: false,
              onSelect: ([value]) => this.inputOnChange(value, "type"),
              usePortal: false,
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Reason",
              className: "col-12",
              placeholder: "Enter Reason",
              value: reason.reason,
              error: hasEditError && reason.reason.trim().length === 0,
              errorMessage: "Enter Valid Reason",
              onChange: (e) => this.inputOnChange(e.target.value, "reason"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
