import moment from "moment";

// Sorts the array of objects with provider accessor for date in a provided format
const sortByEarliestDate = (dates, accessor, format) => {
  if (!dates || dates.length < 1) {
    return [];
  }

  return dates.sort((date1, date2) => {
    const momentDate1 = moment(date1[accessor], format);
    const momentDate2 = moment(date2[accessor], format);
    return momentDate1.isBefore(momentDate2) ? -1 : 1;
  });
};

export default sortByEarliestDate;
