import { CPL_STATUSES, TAG_TYPE } from "../../../../../constants";
import getStatusFromParams from "./getStatusParams";

const getTagTypes = (props) => {
  const status = getStatusFromParams(props);
  const commonTags = [TAG_TYPE.CPL];
  if (status === CPL_STATUSES.MAPPED)
    return [
      ...commonTags,
      TAG_TYPE.BRAND,
      TAG_TYPE.ADVERTISER,
      TAG_TYPE.ALL_CATEGORIES,
      TAG_TYPE.UPDATED_AT,
    ];
  if (status === CPL_STATUSES.IGNORED) return commonTags;
  if (status === CPL_STATUSES.MOVIE_MAPPED) return [...commonTags, TAG_TYPE.MOVIE];
  return commonTags;
};

export default getTagTypes;
