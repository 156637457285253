import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NumericInput } from "@blueprintjs/core";
import styles from "../../styles/InputHours.module.scss";

/**
 * Gets TimeInSeconds from an input field of hh:mm:ss
 */
export default class InputHours extends Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    showSeconds: PropTypes.bool,
    showMinutes: PropTypes.bool,
    buttonPosition: PropTypes.oneOf(["right", "left", "none"]),
  };
  static defaultProps = {
    showMinutes: true,
    showSeconds: true,
    buttonPosition: "none",
  };

  onChange = (data, type) => {
    const hrs = Math.floor(this.props.value / 3600);
    const mins = Math.floor((this.props.value / 60) % 60);
    const secs = Math.floor(this.props.value % 60);

    switch (type) {
      case "hrs":
        this.props.onChange(data * 3600 + mins * 60 + secs);
        break;
      case "mins":
        this.props.onChange(hrs * 3600 + data * 60 + secs);
        break;
      case "secs":
        this.props.onChange(hrs * 3600 + mins * 60 + data);
        break;
      default:
    }
  };

  render() {
    const { value, showSeconds, showMinutes, error, ...rest } = this.props;
    const hrs = Math.floor(value / 3600);
    const mins = Math.floor((value / 60) % 60);
    const secs = Math.floor(value % 60);

    return (
      <div className={classNames(styles.hoursInput, error && styles.error)}>
        <NumericInput
          {...rest}
          id="hrs"
          value={hrs}
          min={0}
          max={120}
          stepSize={1}
          onValueChange={(data) => {
            if (data >= 0 && data < 120) {
              this.onChange(data, "hrs");
            } else {
              this.onChange(0, "hrs");
            }
          }}
          placeholder="hh"
        />
        {showMinutes && <span>&nbsp;:&nbsp;</span>}
        {showMinutes && (
          <NumericInput
            {...rest}
            id="mins"
            value={mins}
            min={0}
            max={59}
            stepSize={1}
            onValueChange={(data) => {
              if (data >= 0 && data < 60) {
                this.onChange(data, "mins");
              } else {
                this.onChange(0, "mins");
              }
            }}
            placeholder="mm"
          />
        )}
        {showSeconds && <span>&nbsp;:&nbsp;</span>}
        {showSeconds && (
          <NumericInput
            {...rest}
            id="secs"
            value={secs}
            min={0}
            max={59}
            stepSize={1}
            onValueChange={(data) => {
              if (data >= 0 && data < 60) {
                this.onChange(data, "secs");
              } else {
                this.onChange(0, "secs");
              }
            }}
            placeholder="ss"
          />
        )}
      </div>
    );
  }
}
