import { SCREEN_STATUSES } from "../constants";
import { formatDate } from "./DatePickerUtil";

const getScreenValidity = (status, startDate, endDate = "") => {
  if (!status || !startDate) return "";
  if (status === SCREEN_STATUSES.ACTIVE && !endDate) return "";
  let screenValidity = "";
  const endDateValue = endDate ? ` to ${formatDate(endDate)}` : "";
  screenValidity = `From ${formatDate(startDate)}${endDateValue}`;

  if (status === SCREEN_STATUSES.INACTIVE_IN_FUTURE && !endDate) {
    screenValidity = `Active till ${formatDate(startDate)}`;
  }

  return screenValidity;
};

export default getScreenValidity;
