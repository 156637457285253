import { getLogsByRef, getLogsBySource, getTagByID } from "../../api";
import {
  UPDATE_LOG,
  UPDATE_SOURCE,
  UPDATE_LATEST_LOG,
  IS_LOADING,
  IS_PARTIAL_LOADING,
  ERROR,
} from "../types/logs";
import * as constants from "../../constants";

const updateLog = (payload) => {
  return { type: UPDATE_LOG, payload: payload };
};

const updateSource = (payload) => {
  return { type: UPDATE_SOURCE, payload: payload };
};
const updateLogInfo = (payload) => {
  return { type: UPDATE_LATEST_LOG, payload: payload };
};
const handleError = (payload) => {
  return { type: ERROR, payload: payload };
};

const handleLoading = (payload) => {
  return { type: IS_LOADING, payload: payload };
};

const handlePartialLoading = (payload) => {
  return { type: IS_PARTIAL_LOADING, payload: payload };
};

const getAuditLogs = (
  params,
  filters = [],
  { getLogsBySourceApi, getLogsByRefApi } = {
    getLogsBySourceApi: getLogsBySource,
    getLogsByRefApi: getLogsByRef,
  },
  isPartial = true
) => {
  const {
    matchParams: { ref, route },
    ps = constants.DEFAULT_PAGE_SIZE,
    page = 1,
    sort = "updatedAt:desc",
  } = params;
  return async (dispatch) => {
    try {
      isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
      if (route === "ref") {
        const { data } = await getLogsByRefApi(ref, ps, (page - 1) * ps, sort, filters);
        const { data: tag } = await getTagByID(ref);
        await dispatch(updateLog(data));
        dispatch(updateLogInfo({ ...tag, source: tag.type }));
      } else {
        const { data } = await getLogsBySource(ref, ps, (page - 1) * ps, sort, filters);
        await dispatch(updateSource(data));
        dispatch(updateLogInfo({ source: ref }));
      }
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      await dispatch(handleError({ isError: true, message: error.message }));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    }
  };
};

export { getAuditLogs, updateSource, updateLog };
