import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import styles from "../../styles/Suggestion.module.scss";
import popOverStyles from "../../styles/PopOver.module.scss";
import TextInput from "./TextInput";
import { CustomMenuItem } from ".";

class Suggestion extends Component {
  state = {
    isOpen: null, // Set state as null instead of false, to alternate the Popover between controlled and uncontrolled states
  };

  static propTypes = {
    closeOnSelect: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    textKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    subTextKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    compareKey: PropTypes.string,
    errorMessage: PropTypes.string,
    error: PropTypes.bool,
    noIcon: PropTypes.bool,
    inputClassName: PropTypes.string,
    popoverPosition: PropTypes.string,
    query: PropTypes.string,
    showEmptyMessage: PropTypes.bool,
    emptyMessage: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    closeOnSelect: true,
    popoverPosition: Position.BOTTOM_LEFT,
    noIcon: false,
    usePortal: false,
    selectedItem: {},
    showEmptyMessage: false,
    emptyMessage: "No suggestions found",
    subTextKey: null,
    disabled: false,
  };

  renderIcon = (item, selectedItem, textKey, compareKey) => {
    if (!Object.getOwnPropertyNames(selectedItem).length > 0) return;
    if (selectedItem[textKey].length !== 0 && selectedItem[compareKey] === item[compareKey])
      return IconNames.SMALL_TICK;
  };

  renderEmptyMessage = (emptyMessage) => (
    <Menu className={styles.menu}>
      <MenuItem
        key="emptyMessage"
        text={emptyMessage}
        icon=""
        onClick={() => {}}
        shouldDismissPopover={this.props.closeOnSelect}
      />
    </Menu>
  );

  renderMenuItemWithSubText = (text, subText) => (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.subText}>{subText}</div>
    </>
  );

  renderMenu = (options, selectedItem, textKey, subTextKey, compareKey, noIcon) => {
    const filteredOptions = options.filter((data) => {
      return _.isFunction(textKey) ? textKey(data).length > 0 : data[textKey].length > 0;
    });
    if (!filteredOptions || filteredOptions.length === 0) return null;
    return (
      <Menu className={styles.menu}>
        {filteredOptions.map((item, index) => {
          let icon;
          if (!noIcon)
            icon = selectedItem[compareKey] === item[compareKey] ? IconNames.SMALL_TICK : "";

          const text = _.isFunction(textKey) ? textKey(item) : item[textKey];
          const subText = _.isFunction(subTextKey) ? subTextKey(item) : item[subTextKey];

          return (
            <CustomMenuItem
              key={index}
              textClassName={styles.menuItem}
              text={subText ? this.renderMenuItemWithSubText(text, subText) : text}
              icon={icon}
              onClick={() => this.props.onSelect(item)}
              shouldDismissPopover={this.props.closeOnSelect}
            />
          );
        })}
      </Menu>
    );
  };

  handleFocus = () => this.setState({ isOpen: true });

  handleBlur = () => this.setState({ isOpen: null });

  render() {
    const {
      options,
      selectedItem,
      onChange,
      inputClassName,
      textKey,
      subTextKey,
      popoverPosition,
      usePortal,
      compareKey,
      closeOnSelect,
      query,
      noIcon,
      disabled,
      showEmptyMessage,
      emptyMessage,
      ...rest
    } = this.props;
    const value =
      query !== undefined
        ? query
        : _.isFunction(textKey)
        ? textKey(selectedItem)
        : selectedItem[textKey];

    const content = showEmptyMessage
      ? this.renderEmptyMessage(emptyMessage)
      : this.renderMenu(options, selectedItem, textKey, subTextKey, compareKey, noIcon);

    return (
      <Popover
        popoverClassName={popOverStyles.popOver}
        usePortal={usePortal}
        content={content}
        position={popoverPosition}
        isOpen={this.state.isOpen}
        minimal
      >
        <TextInput
          {...rest}
          value={value || ""}
          className={classNames(inputClassName)}
          onChange={(e) => onChange(e.target.value)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          disabled={disabled}
        />
      </Popover>
    );
  }
}

export default Suggestion;
