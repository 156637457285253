import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditSegmentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentType: props.segmentType,
      hasEditError: false,
    };
  }

  static propTypes = {
    segmentType: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.segmentType !== prevProps.segmentType)
      this.setState({ segmentType: this.props.segmentType });
  }

  cancelEdit = () => {
    this.setState({ hasEditError: false });
    this.props.onClose();
  };
  isNotValidColor = (color) => {
    const pattern = /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/;
    return !pattern.test(color);
  };
  isValid = (segmentType) => {
    if (segmentType.code.trim().length === 0) return false;
    if (segmentType.name.trim().length === 0) return false;
    if (this.isNotValidColor(segmentType.color)) return false;
    if (!segmentType.rank) return false;
    return true;
  };

  save = () => {
    const { segmentType } = this.state;
    if (!this.isValid(segmentType)) return this.setState({ hasEditError: true });
    this.props.onSave(segmentType);
    this.cancelEdit();
  };

  inputOnChange = (value, accessor) => {
    const { segmentType } = this.state;
    this.setState({
      segmentType: { ...segmentType, [accessor]: value },
    });
  };
  inputNumberChange = (value, accessor) => {
    const { segmentType } = this.state;
    if (!isNaN(value)) {
      this.setState({
        segmentType: { ...segmentType, [accessor]: Number(value) },
      });
    }
  };
  render = () => {
    const { isOpen } = this.props;
    const { segmentType = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={
          segmentType.id ? "RightPanelHeader.editSegmentType" : "RightPanelHeader.addSegmentType"
        }
        showFooter={true}
        isEdit={segmentType.id ? true : false}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: segmentType.id ? FORM_FIELD_TYPE.LABEL : FORM_FIELD_TYPE.INPUT,
              label: "Code",
              className: "col-12",
              placeholder: "Enter Code",
              value: segmentType.code,
              error: hasEditError && segmentType.code.trim().length === 0,
              errorMessage: "Enter Valid Code",
              onChange: (e) => this.inputOnChange(e.target.value, "code"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Name",
              className: "col-12",
              placeholder: "Enter Name",
              value: segmentType.name,
              error: hasEditError && segmentType.name.trim().length === 0,
              errorMessage: "Enter Valid Name",
              onChange: (e) => this.inputOnChange(e.target.value, "name"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Color",
              className: "col-12",
              placeholder: "Enter Color",
              value: segmentType.color,
              error: hasEditError && this.isNotValidColor(segmentType.color),
              errorMessage: "Enter Valid Color",
              onChange: (e) => this.inputOnChange(e.target.value, "color"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Rank",
              className: "col-12",
              placeholder: "Enter Rank",
              value: segmentType.rank,
              error: hasEditError && !segmentType.rank,
              errorMessage: "Enter Valid Rank",
              onChange: (e) => this.inputNumberChange(e.target.value, "rank"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
