import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import InfoBlockLoading from "../../../../../../common/InfoBlockLoading";
import { bindDispatch } from "../../../../../../../utils";
import { SCOPE_ACTIONS } from "../../../../../../../constants";
import pageStyles from "../../../../../../../styles/App.module.scss";
import SettingsDetails from "./SettingsDetails";

class UserSettings extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount = async () => {
    const {
      actions,
      match: { params },
    } = this.props;
    await actions.getContentTypesList();
    await actions.getScreenSettings(params);

    this.setState({
      isLoading: false,
    });
  };

  render = () => {
    const {
      actions,
      history,
      match,
      screens: {
        screen,
        screenSettings,
        isScreenSettingsLoading,
        isUpdateScreenStatusLoading,
        screenSettingsEdit,
      },
      userData,
      contentTypes,
    } = this.props;
    if (isScreenSettingsLoading || this.state.isLoading)
      return (
        <div className={pageStyles.pageContainer}>
          <InfoBlockLoading />
        </div>
      );
    return (
      <div>
        <SettingsDetails
          match={match}
          history={history}
          screen={screen}
          screenSettings={screenSettings.length > 0 && screenSettings[0]}
          screenSettingsEdit={screenSettingsEdit}
          userData={userData}
          actions={actions}
          contentTypes={contentTypes}
          showEdit={userData.scopes?.inventory?.includes(SCOPE_ACTIONS.WRITE)}
          isActivationPaneLoading={isUpdateScreenStatusLoading}
        />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.contentType,
  (state) => state.userData,
  (screens, contentType, userData) => ({
    screens,
    contentTypes: contentType.data.data,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(UserSettings));
