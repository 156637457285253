import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditNotificationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationTemplate: props.notificationTemplate,
      hasEditError: false,
    };
  }

  static propTypes = {
    notificationTemplate: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.notificationTemplate !== prevProps.notificationTemplate)
      this.setState({ notificationTemplate: this.props.notificationTemplate });
  }

  cancelEdit = () => {
    this.setState({ hasEditError: false, notificationTemplate: {} });
    this.props.onClose();
  };

  isValid = (notificationTemplate) => {
    if (notificationTemplate.code.trim().length === 0) return false;
    if (notificationTemplate.subject.trim().length === 0) return false;
    if (notificationTemplate.body.trim().length === 0) return false;
    return true;
  };

  save = () => {
    const { notificationTemplate } = this.state;
    if (this.isValid(notificationTemplate)) {
      this.props.onSave(notificationTemplate);
      this.cancelEdit();
    } else this.setState({ hasEditError: true });
  };

  inputOnChange = (value, accessor) => {
    const { notificationTemplate } = this.state;
    this.setState({
      notificationTemplate: { ...notificationTemplate, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { notificationTemplate = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={
          notificationTemplate.id
            ? "RightPanelHeader.editNotificationTemplate"
            : "RightPanelHeader.addNotificationTemplate"
        }
        showFooter={true}
        isEdit={notificationTemplate.id ? true : false}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Code",
              className: "col-12",
              placeholder: "Enter Code",
              value: notificationTemplate.code,
              error: hasEditError && notificationTemplate.code.trim().length === 0,
              errorMessage: "Enter Valid Code",
              onChange: (e) => this.inputOnChange(e.target.value, "code"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Subject",
              className: "col-12",
              placeholder: "Enter Subject",
              value: notificationTemplate.subject,
              error: hasEditError && notificationTemplate.subject.trim().length === 0,
              errorMessage: "Enter Valid Subject",
              onChange: (e) => this.inputOnChange(e.target.value, "subject"),
            },
            {
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Body",
              placeholder: "Enter Body",
              value: notificationTemplate.body,
              error: hasEditError && notificationTemplate.body.trim().length === 0,
              errorMessage: "Enter Valid Body",
              onChange: (e) => this.inputOnChange(e.target.value, "body"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
