import React, { Component } from "react";
import PropTypes from "prop-types";
import { Intent, Classes } from "@blueprintjs/core";
import { startCase } from "lodash";
import { IconNames } from "@blueprintjs/icons";
import { uniqWith, isEqual } from "lodash";
import classNames from "classnames";
import { FORM_FIELD_TYPE } from "../../../../constants";
import styles from "../../../../styles/Content/cpls.module.scss";
import RightPanel from "../../../common/RightPanel";
import { CustomMenuItem } from "../../../common";
import Form from "../../../common/Form";
import { searchBrands } from "../../../../api";
import RemoveIcon from "../../../common/RemoveIcon";
import { getBrandById } from "../../../../api";

export default class MapBrand extends Component {
  state = {
    isFetchingBrand: false,
    brand: {
      advertisers: [],
      categories: [],
    },
    map: {
      advertisers: [],
      mappedResource: null,
    },
    hasMapError: false,
  };

  static propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
    showCpl: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showCpl: false,
  };

  componentDidMount = () => {
    const { mappedBrand } = this.props;
    if (mappedBrand?.id) this.fetchBrandById(mappedBrand);
    this.initialiseData();
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.initialiseData();
    }
  }

  initialiseData = () => {
    const { data } = this.props;
    if (data?.id)
      this.fetchBrandById({
        id: data[`${data.mappedResourceType}Id`],
        type: data.mappedResourceType,
        advertisers: data.advertisers,
      });
  };

  parseBrandSearchResults = (data) =>
    data.map((item) => ({
      id: item[`${item.type}Id`],
      name: item[`${item.type}Name`],
      type: item.type,
      tag: startCase(item.type),
    }));

  cancelMap = () => {
    this.setState({ hasMapError: false });
    this.props.onClose();
  };

  isValid = (map) => map.mappedResource && map.mappedResource.id;

  mapBrand = () => {
    const { map } = this.state;
    if (this.isValid(map)) {
      this.props.onSave(map);
      this.setState({ hasMapError: false });
    } else {
      return this.setState({ hasMapError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    const { map } = this.state;
    this.setState({
      map: { ...map, [accessor]: value },
    });
  };

  removeItem = (itemIndex, key) => {
    const { map } = this.state;
    this.setState({
      map: {
        ...map,
        ...{ [key]: map[key].filter((item, index) => index !== itemIndex) },
      },
    });
  };

  clearMultiSelect = (key) => {
    const { map } = this.state;
    this.setState({
      map: {
        ...map,
        ...{ [key]: [] },
      },
    });
  };

  renderAdvertiserMenu = (data, { modifiers, handleClick }) => (
    <CustomMenuItem
      key={data.id}
      active={modifiers.active}
      icon={this.isSelectedAdvertiser(data) ? IconNames.TICK : IconNames.BLANK}
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  isSelectedAdvertiser = (data) => {
    const { map } = this.state;
    return map.advertisers && map.advertisers.find((advertiser) => advertiser.id === data.id);
  };

  onBrandSelect = (item) => {
    const { map } = this.state;
    if (!item) this.setState({ isFetchingBrand: false, map: { ...map, mappedResource: {} } });
    else {
      this.setState({
        isFetchingBrand: true,
        hasMapError: false,
        map: { ...map, mappedResource: { id: item.id, type: item.type } },
      });
      this.fetchBrandById(item);
    }
  };

  fetchBrandById = async (item) => {
    const data = await getBrandById(item.id, item.type);
    this.setState({
      isFetchingBrand: false,
      brand: data,
      map: {
        mappedResource: { id: item.id, type: item.type },
        advertisers: item?.advertisers ?? data?.advertisers ?? [],
      },
    });
  };

  renderForm = () => {
    const { data } = this.props;
    const { map, hasMapError, isFetchingBrand, brand } = this.state;
    const config = [
      {
        id: "brand",
        type: FORM_FIELD_TYPE.API_SEARCH,
        label: "Brand",
        placeholder: "Search for Brand",
        onSelect: this.onBrandSelect,
        searchIcon: "right",
        showSubText: true,
        parseResults: this.parseBrandSearchResults,
        fetchAction: (value) => searchBrands(value),
        error: hasMapError,
        errorMessage: "Select a brand",
        clearSelectOnReset: true,
        query: data.mappedResourceType ? data[`${data.mappedResourceType}Name`] : null,
      },
      {
        id: "advertisers",
        type: FORM_FIELD_TYPE.MULTI_SELECT,
        label: "Advertisers",
        items: brand?.advertisers ?? [],
        itemRenderer: this.renderAdvertiserMenu,
        className: classNames({ [Classes.SKELETON]: isFetchingBrand }),
        onItemSelect: (data) =>
          this.setState({
            map: { ...map, advertisers: uniqWith([...map.advertisers, data], isEqual) },
          }),
        resetOnSelect: true,
        tagRenderer: (data) => data.name,
        tagInputProps: {
          placeholder: "Select Advertisers",
          tagProps: { intent: Intent.NONE, minimal: true },
          disabled: !this.isValid(map) || !brand?.advertisers?.length,
          onRemove: (_, index) => this.removeItem(index, "advertisers"),
          rightElement: <RemoveIcon onClick={() => this.clearMultiSelect("advertisers")} />,
        },
        selectedItems: map.advertisers,
      },
    ];
    return <Form config={config} />;
  };

  render = () => {
    const { isOpen, data, isLoading, showCpl } = this.props;
    const { isFetchingBrand } = this.state;
    return (
      <RightPanel
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={() => this.cancelMap()}
        header="RightPanelHeader.mapBrand"
        showFooter={true}
        primaryButtonProps={{
          text: "Button.map",
          onClick: this.mapBrand,
          loading: isFetchingBrand,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelMap }}
      >
        {!isLoading && (
          <>
            <div className={styles.groupWrapper}>
              <div className={styles.groupTitle}>{showCpl ? "CPL " : ""}Name</div>
              <p className={styles.name}>{data.name}</p>
            </div>
            {showCpl && (
              <div className={styles.groupWrapper}>
                <div className={styles.groupTitle}>CPL ID</div>
                <p>{data.id}</p>
              </div>
            )}
            {this.renderForm()}
          </>
        )}
      </RightPanel>
    );
  };
}
