import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import PageStyles from "../../../../../styles/App.module.scss";
import { bindDispatch } from "../../../../../utils";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import { getScrollableSummaryBlock } from "../../utils";
import { TargetGroupTheatresWithTargetGroupId } from "../TargetGroup/TargetGroupTheatres";
import { THEATRE_CRITERIA_TYPES } from "../../../../../constants";

export class CampaignApprovalTargetGroups extends Component {
  state = {
    targetGroup: {},
  };
  componentDidMount = async () => {
    const { targetGroupId, actions, targetGroup } = this.props;
    this.setState({ targetGroup: targetGroup });
    await actions.getTargetGroupSummaryById(targetGroupId);
  };
  componentDidUpdate = (prevProps) => {
    const { targetGroup } = this.props;
    if (prevProps.targetGroup !== targetGroup) {
      this.setState({ targetGroup: targetGroup });
    }
  };

  handleTheatreExclusion = async (exclusion) => {
    const {
      actions,
      campaignCreate: { campaignTargetGroup },
    } = this.props;
    const criteria = {
      inclusions: campaignTargetGroup.criteria.inclusions,
      exclusions: [...campaignTargetGroup.criteria.exclusions, { ...exclusion }],
    };
    campaignTargetGroup.criteria = criteria;
    await actions.createOrUpdateTargetGroup(
      campaignTargetGroup.campaignId,
      campaignTargetGroup,
      "edit"
    );
  };
  excludeTheatre = (obj) => {
    const { id, name, screens } = obj;
    const exclusion = {
      id,
      name,
      tags: [id],
      type: !!screens ? THEATRE_CRITERIA_TYPES.THEATRE_BY_ID : THEATRE_CRITERIA_TYPES.SCREEN_BY_ID,
      targetGroupType: "theatre",
    };
    return exclusion;
  };

  render = () => {
    const {
      campaignCreate: { campaignTargetGroup },
      targetGroupId,
    } = this.props;
    const { targetGroup } = this.state;

    return (
      <div>
        <div className={PageStyles.summaryContainer}>
          {isEmpty(campaignTargetGroup) || targetGroup.id !== campaignTargetGroup.id ? (
            <InfoBlockLoading />
          ) : (
            getScrollableSummaryBlock({
              validity: campaignTargetGroup?.summary?.validity,
              maxPlaysPerScreen: campaignTargetGroup?.summary?.maxPlaysPerScreen,
              ...campaignTargetGroup?.summary?.estimated,
            })
          )}
        </div>
        {targetGroup.criteria && (
          <TargetGroupTheatresWithTargetGroupId
            onExclusion={this.handleTheatreExclusion}
            targetGroupId={targetGroupId}
            criteria={targetGroup.criteria}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (campaignCreate, userData) => ({
    campaignCreate,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(CampaignApprovalTargetGroups));
