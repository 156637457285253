import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import PageLoadingWithTabs from "../../common/PageLoadingWithTabs";
import { bindDispatch } from "../../../utils";
import PageHeader from "../../common/PageHeader";
import Breadcrumb from "../../common/BreadCrumb";
import TabView from "../../common/Tabs";
import ContentTableWithFilters from "../content/common/Content";
import CompanyDetails from "./Tabs/Details";

const _LINKS = [{ name: "PageHeader.companies", path: "/companies" }];
export class Exhibitor extends Component {
  state = {
    selectedColumns: ["In Use", "Campaign", "Played Count"],
  };
  componentDidMount = async () => {
    await this.getExhibitor();
  };

  getExhibitor = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getExhibitor(params);
  };

  render = () => {
    const {
      history,
      companies: { exhibitor },
      match: { params },
    } = this.props;

    const isLoading = isEmpty(exhibitor);

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={3} />;
    }

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: exhibitor.name }]}
          />
        </div>
        <PageHeader name={exhibitor.name} isLoading={isLoading} />
        <TabView
          id="navbar"
          selectedTabId={params.tabId}
          tabs={[
            {
              id: "details",
              title: "Details",
              goto: `/companies/exhibitors/${exhibitor.id}/details`,
              panel: <CompanyDetails company={exhibitor} />,
            },
            {
              id: "content",
              title: "Content",
              //TODO: Fetch and show actual count, once the api is available
              count: 50,
              goto: `/companies/exhibitors/${exhibitor.id}/content`,
              panel: (
                <ContentTableWithFilters
                  companyId={exhibitor.id}
                  selectedColumns={this.state.selectedColumns}
                  {...this.props}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.companies,
  (companies) => ({ companies })
);

export default connect(mapStateToProps, bindDispatch)(Exhibitor);
