import pluralize from "./pluralize";

const getHoursFromSeconds = (seconds) => {
  if (typeof seconds !== "number" || seconds < 0) {
    return "";
  }
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds / 60) % 60);
  return [pluralize(hrs, "hr", "s", true), pluralize(mins, "min")].filter(Boolean).join(" ");
};

const formatNumberToTwoDigits = (value) => `0${value}`.slice(-2);

const getTimeFromSeconds = (seconds) => {
  if (typeof seconds !== "number" || seconds < 0) {
    return "";
  }
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds / 60) % 60);
  const secs = Math.floor(seconds % 60);
  return `${formatNumberToTwoDigits(hrs)}:${formatNumberToTwoDigits(
    mins
  )}:${formatNumberToTwoDigits(secs)}`;
};

export { getHoursFromSeconds, getTimeFromSeconds, formatNumberToTwoDigits };
