import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icons } from "prefab";
import { formatDataBytes } from "../../../../utils";
import styles from "../../../../styles/Campaigns/CampaignFiles.module.scss";
import RightPanel from "../../../common/RightPanel";

const { DownloadIcon } = Icons;

export default class ViewFiles extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    files: PropTypes.array,
  };
  renderFileCards = (file) => {
    const { campaignId, downloadFile } = this.props;
    return (
      <div className={styles.fileCards}>
        <div>
          <div className={styles.fileName}>{file.name}</div>
          <div className={styles.fileType}>{`${file.type} • ${formatDataBytes(file.size)}`}</div>
        </div>
        <div onClick={() => downloadFile(campaignId, file)}>
          <DownloadIcon />
        </div>
      </div>
    );
  };
  render() {
    const { isOpen, isLoading, files, onClose } = this.props;
    return (
      <RightPanel
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        header="RightPanelHeader.files"
      >
        <div>
          {files && files.map((file, index) => <div key={index}>{this.renderFileCards(file)}</div>)}
        </div>
      </RightPanel>
    );
  }
}
