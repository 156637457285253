import { fetchBusinessTypes } from "../../api";
import * as types from "../types/businessTypes";

const updateBusinessTypesList = (payload) => {
  return { type: types.LIST, payload: payload };
};
const handleError = (payload) => {
  return { type: types.IS_ERROR, payload: payload };
};

const handleBusy = (payload) => {
  return { type: types.IS_LOADING, payload: payload };
};

const getBusinessTypesList = (get_list = fetchBusinessTypes) => {
  return async (dispatch) => {
    dispatch(handleBusy(true));
    try {
      const { data } = await get_list();
      dispatch(updateBusinessTypesList(data));
      dispatch(handleBusy(false));
    } catch (error) {
      dispatch(handleBusy(false));
      dispatch(handleError(error));
    }
  };
};

export { updateBusinessTypesList, getBusinessTypesList };
