import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import RightPanel from "../../../common/RightPanel";
import InfoBlock from "../../../common/InfoBlock";

export default class ActionDetails extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string,
  };

  static defaultProps = {
    header: "RightPanelHeader.screenPauseDetails",
    statusChanges: [],
  };

  handleFormCancel = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderPanelContent = () => {
    const { actionType, statusChanges } = this.props;
    const filteredList = statusChanges.filter(({ status }) => status === actionType);

    if (filteredList.length !== 0) {
      return statusChanges
        .filter(({ status }) => status === actionType)
        .map((item) => (
          <InfoBlock
            cardType="secondary"
            config={[
              {
                title: "Date Range",
                value: [
                  moment(item.fromDate).format("MMM DD, YYYY"),
                  moment(item.toDate).format("MMM DD, YYYY"),
                ].join(" to "),
                size: {
                  lg: 12,
                  md: 12,
                  sm: 12,
                },
              },
              {
                title: "Reason",
                value: item.reason,
                size: {
                  lg: 12,
                  md: 12,
                  sm: 12,
                },
              },
              {
                title: "Notes",
                value: item.notes,
                size: {
                  lg: 12,
                  md: 12,
                  sm: 12,
                },
              },
            ]}
          />
        ));
    }

    return <div>No {actionType} Data Found</div>;
  };

  render = () => {
    const { isOpen, header } = this.props;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.handleFormCancel()}
        header={header}
        showFooter={false}
      >
        {this.renderPanelContent()}
      </RightPanel>
    );
  };
}
