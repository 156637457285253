import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import { isInteger, join, keys, map } from "lodash";
import { VIEW_PORT_SIZE_CLASS } from "../../../constants";

const getCardClassName = (props) => {
  const cardClassName = [];
  map(keys(VIEW_PORT_SIZE_CLASS), (currentPort) => {
    const currentPortValue = props[currentPort];
    if (isInteger(currentPortValue)) {
      cardClassName.push(`col-${currentPort}-${currentPortValue}`);
    } else {
      cardClassName.push(`col ${currentPortValue}`);
    }
  });

  return join(cardClassName, " ");
};

const Card = ({ className, children, ...restProps }) => {
  const cardClassName = ClassNames(className, getCardClassName(restProps));
  return <div className={cardClassName}>{children}</div>;
};

Card.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Card.defaultProps = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 3,
};

export default Card;
