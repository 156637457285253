import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons } from "prefab";
import { Tooltip, Position } from "@blueprintjs/core";
import { VERIFICATION_STATUSES } from "../../../constants";
import styles from "../../../styles/Verifications/ViewVerification.module.scss";
import toolTipStyles from "../../../styles/ToolTip.module.scss";

const { AddFilledIcon, AddIcon, EditIcon, MergeIcon, CancelIcon, AlertIcon } = Icons;
const ToolTipWrapper = ({ content, children }) => (
  <Tooltip
    content={content}
    position={Position.TOP}
    className={classNames(toolTipStyles.content, styles.iconContainer)}
    boundary
  >
    {children}
  </Tooltip>
);

const BrandVerificationListItem = ({
  name,
  status,
  isUpdate,
  hasConflict,
  onClick,
  isActive,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        styles.brandItem,
        { [styles.brandItemDisabled]: disabled },
        { [styles.brandItemActive]: isActive }
      )}
      onClick={disabled ? null : onClick}
    >
      <span className={styles.brandItemName}>{name}</span>
      <span className={styles.iconContainer}>
        {status === VERIFICATION_STATUSES.APPROVED && (
          <ToolTipWrapper content="Approved">
            <AddFilledIcon className={styles.successIcon} />
          </ToolTipWrapper>
        )}
        {status === VERIFICATION_STATUSES.MERGED && (
          <ToolTipWrapper content="Merged">
            <MergeIcon />
          </ToolTipWrapper>
        )}
        {status === VERIFICATION_STATUSES.REJECTED && (
          <ToolTipWrapper content="Rejected">
            <CancelIcon className={styles.errorIcon} />
          </ToolTipWrapper>
        )}
        {hasConflict && (
          <ToolTipWrapper content="Conflict">
            <AlertIcon className={styles.errorIcon} />
          </ToolTipWrapper>
        )}
        {isUpdate && (
          <ToolTipWrapper content="Update">
            <EditIcon />
          </ToolTipWrapper>
        )}
        {!isUpdate && (
          <ToolTipWrapper content="Addition">
            <AddIcon />
          </ToolTipWrapper>
        )}
      </span>
    </div>
  );
};

BrandVerificationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(VERIFICATION_STATUSES)),
  isUpdate: PropTypes.bool,
  hasConflict: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

BrandVerificationListItem.defaultProps = {
  status: VERIFICATION_STATUSES.PENDING,
  isUpdate: false,
  hasConflict: false,
};

export default BrandVerificationListItem;
