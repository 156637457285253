import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { DEFAULT_LOADING_ROW_COUNT } from "../../../constants";
import { bindDispatch, modifyTableColumns, lastUpdatedAtColumn } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import PageHeader from "../../common/PageHeader";

import HeaderButton from "../../common/HeaderButtons";

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.billingCycles" }];

export class BillingCycles extends Component {
  state = {
    selectedColumns: ["Code", "Rank", "Billing Cycle", "Last Updated On"],
  };
  static propTypes = { app: PropTypes.object, reasons: PropTypes.object };
  componentDidMount = () => {
    const { actions } = this.props;
    actions.getBillingCycles();
  };
  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });
  render = () => {
    const {
      billingCycles: { data, isLoading },
      history,
    } = this.props;

    if (isLoading)
      return (
        <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={DEFAULT_LOADING_ROW_COUNT} />
      );

    const { selectedColumns, reorderedColumns } = this.state;
    const columns = [
      {
        id: "code",
        Header: "Code",
        accessor: "code",
      },
      {
        id: "rank",
        Header: "Rank",
        accessor: "rank",
      },
      {
        id: "name",
        Header: "Billing Cycle",
        accessor: "name",
      },
      lastUpdatedAtColumn(),
    ];
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          name="PageHeader.billingCycles"
          count={data.data.length}
          isLoading={isLoading}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              data={data.data || []}
              pageSize={data && data.length}
              sortable={false}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.billingCycles,
  (billingCycles) => ({ billingCycles })
);

export default connect(mapStateToProps, bindDispatch)(BillingCycles);
