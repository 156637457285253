import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import * as constants from "../../../../constants";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedAtColumn,
} from "../../../../utils";
import styles from "../../../../styles/Lists/Lists.module.scss";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import ActionIcon from "../../../common/ActionIcon";
import FilterChips from "../../../common/Filters/FilterChips";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import WithFetchList from "../../../hoc/withFetchList";
import HeaderButton from "../../../common/HeaderButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import { lastUpdatedByColumn } from "../../../../utils";
import EditCategory from "./EditCategory";
import CreateCategory from "./CreateCategory";

const _LINKS = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.productCategories" },
];

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [
        "Product Category",
        "Product Sub-Category 1",
        "Product Sub-Category 2",
        "Last Updated By",
        "Last Updated On",
      ],
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
      actions,
      fetchData,
    } = this.props;

    if (params.action) {
      if (params.action === "new") {
        actions.addCategory();
        fetchData();
      } else {
        this.props.onFilterIdSelect(params.action);
        actions.editCategoryById(params.action);
      }
    } else fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  addCategory = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addCategory();
    history.replace(`/lists/product-categories/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditCategory();
    history.replace(`/lists/product-categories${location.search}`);
  };

  cancelCreate = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelCreateCategory();
    history.replace(`/lists/product-categories${location.search}`);
  };

  editCategory = (category) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editCategory(category);
    const categoryId = category.subCategory2Id || category.subCategory1Id || category.categoryId;
    history.replace(`/lists/product-categories/${categoryId}${location.search}`);
  };

  render = () => {
    const {
      categories: { categories, isLoading, isPartialLoading, categoryEdit, categoryCreate },
      history,
      actions,
      filters,
      userData,
      ps,
      page,
      onQueryChange,
      sort,
      onSortedChange,
      fetchData,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isEditOpen, category: editCategory, isEditError } = categoryEdit;
    const { isCreateOpen, isCreateError, category: newCategory } = categoryCreate;

    const activeTableCell = (id, value) => (
      <span className={styles.active} onClick={() => this.props.onFilterIdSelect(id)}>
        {value}
      </span>
    );

    const columns = checkScopes(
      [
        {
          id: "categoryId",
          Header: "UUID",
          accessor: (d) => <span className={`${styles.monoSpace}`}>{d.categoryId}</span>,
        },
        {
          id: "categoryName",
          Header: "Product Category",
          accessor: (d) => activeTableCell(d.categoryId, d.categoryName),
        },
        {
          id: "subCategory1Name",
          Header: "Product Sub-Category 1",
          accessor: (d) => activeTableCell(d.subCategory1Id, d.subCategory1Name),
        },
        {
          id: "subCategory2Name",
          Header: "Product Sub-Category 2",
          accessor: (d) => activeTableCell(d.subCategory2Id, d.subCategory2Name),
        },
        {
          ...lastUpdatedByColumn(this.props.onFilterIdSelect),
          sortable: false,
        },
        {
          ...lastUpdatedAtColumn(),
          sortable: false,
        },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.CATEGORIES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editCategory(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const query = queryString.parse(history.location.search);
    const data = categories.data || [];

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.CATEGORIES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addCategory,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          name="PageHeader.productCategories"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={categories.totalCount}
          renderRightSideComponent={() => (
            <HeaderButton
              logsButton
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CATEGORIES}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={categories}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[
                constants.TAG_TYPE.CATEGORY,
                constants.TAG_TYPE.SUB_CATEGORY_1,
                constants.TAG_TYPE.SUB_CATEGORY_2,
                constants.TAG_TYPE.UPDATED_AT,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <div className={pageStyles.filterChips}>
              <FilterChips
                selected={filters}
                showResultsCount
                resultsCount={categories.totalCount}
                onFilterChange={this.props.onFilterChange}
                loading={isLoading}
              />
            </div>
            <Table
              pageSize={(query && parseInt(query.ps)) || 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={data}
              loading={isPartialLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              data={categories}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
              {...addButtonProps}
            />
          </div>
        </div>
        <EditCategory
          isError={isEditError}
          isOpen={isEditOpen && !isEmpty(editCategory)}
          actions={actions}
          categories={categories}
          category={editCategory}
          onClose={this.cancelEdit}
          getCategoriesList={() => {
            fetchData(null, true);
          }}
        />
        <CreateCategory
          isError={isCreateError}
          isOpen={isCreateOpen}
          actions={actions}
          categories={categories}
          category={newCategory}
          onClose={this.cancelCreate}
          getCategoriesList={() => {
            fetchData(null, true);
          }}
        />
      </div>
    );
  };
}

export const CategoriesPage = WithFetchList("getCategories", {
  sort: [
    {
      id: "categoryName",
      desc: false,
    },
    {
      id: "subCategory1Name",
      desc: false,
    },
    {
      id: "subCategory2Name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: true,
})(Categories);

const mapStateToProps = createSelector(
  (state) => state.categories,
  (state) => state.userData,
  (categories, userData) => ({ categories, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CategoriesPage);
