import { isNumber } from "lodash";

const formatDataBytes = (bytes, decimals = 2) => {
  if (!isNumber(bytes)) return null;
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  // Formatted untill Peta Bytes
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatDataBytes;
