import * as types from "../types/showFailureSpotStatus";

const initialState = {
  isLoading: true,
  isError: false,
  filters: [],
  showFailureSpotStatusData: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.SHOWFAILURE_SPOTSTATUS_DATA:
      return { ...state, showFailureSpotStatusData: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.FILTER:
      return { ...state, filters: payload };
    default:
      return state;
  }
}
