import {
  getMovies as getMoviesApi,
  getMovie as getMovieApi,
  getMovieScreens as getMovieScreensApi,
  getMovieTheatres as getMovieTheatresApi,
} from "../../api";
import * as types from "../types/movies";
import * as constants from "../../constants";
import { sortByEarliestDate } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const handlePartialLoading = (payload) => ({ type: types.IS_PARTIAL_LOADING, payload });

const mergeCertificatesAndReleaseDates = ({ releaseDates = [], certifications = [] }) => {
  const mergedCertificatesAndReleaseDates = [
    ...releaseDates
      .concat(certifications)
      .reduce(
        (acc, currVal) =>
          acc.set(currVal.code, Object.assign(acc.get(currVal.code) || {}, currVal)),
        new Map()
      )
      .values(),
  ];
  return mergedCertificatesAndReleaseDates;
};

const updateMovies = (payload) => {
  const { data } = payload;
  const transformedData = data.map((movie) => {
    const certificatesAndReleaseDates = mergeCertificatesAndReleaseDates(movie);
    const [earliestDate] = sortByEarliestDate(
      certificatesAndReleaseDates,
      "releaseDate",
      "YYYY-MM-DD"
    );
    return {
      ...movie,
      certificatesAndReleaseDates,
      earliestDate,
    };
  });
  return { type: types.MOVIES, payload: { ...payload, data: transformedData } };
};

const updateMovie = (payload) => {
  return {
    type: types.MOVIE,
    payload: { ...payload, certificatesAndReleaseDates: mergeCertificatesAndReleaseDates(payload) },
  };
};

const getMoviesList = (params, filters = [], getList = getMoviesApi, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateMovies(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getMovie = (params) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await getMovieApi(params.movieId);
      dispatch(updateMovie(data));

      // Fetching data ahead of time,is needed to show the count of list items in the tab title.
      // The fetching also happens in the componentDidMount of tab-list component, as it has
      // filters and sorting that needs to be handled via server-side.
      // TODO: Find a better way to avoid multi-fetching -_-
      const { data: screensData } = await getMovieScreensApi(params.movieId, 0, 0, [], {});
      dispatch(updateMovieScreens(screensData));

      const { data: theatresData } = await getMovieTheatresApi(params.movieId, 0, 0, [], {});
      dispatch(updateMovieTheatres(theatresData));

      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateMovieScreens = (payload) => {
  return { type: types.MOVIE_SCREENS, payload: payload };
};

const getMovieScreens = (params, filters = [], getList = getMovieScreensApi, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", movieId } = params;
  return async (dispatch) => {
    isPartial
      ? dispatch(handlePartialLoading(true))
      : dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await getList(movieId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateMovieScreens(data));
      isPartial
        ? dispatch(handlePartialLoading(false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(handlePartialLoading(false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateMovieTheatres = (payload) => {
  return { type: types.MOVIE_THEATRES, payload: payload };
};

const getMovieTheatres = (
  params,
  filters = [],
  getList = getMovieTheatresApi,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", movieId } = params;

  return async (dispatch) => {
    isPartial
      ? dispatch(handlePartialLoading(true))
      : dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await getList(movieId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateMovieTheatres(data));
      isPartial
        ? dispatch(handlePartialLoading(false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(handlePartialLoading(false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export { getMoviesList, getMovie, getMovieScreens, getMovieTheatres };
