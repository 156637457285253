import { omit } from "lodash";
import {
  getTargetGroup as getTargetGroupApi,
  getTargetGroupSummary as getTargetGroupSummaryApi,
  getTargetGroupMedia as getTargetGroupMediaApi,
  getTargetGroupTheatres as getTargetGroupTheatresApi,
  getTargetGroupScreens as getTargetGroupScreensApi,
  cancelTargetGroups as cancelTargetGroupsApi,
  pauseOrResumeTargetGroup as pauseOrResumeTargetGroupApi,
  getTargetGroupSpots as getTargetGroupSpotsApi,
} from "../../api";
import * as types from "../types/targetGroups";
import * as constants from "../../constants";
import { showToast, createFilterRequest } from "../../utils";
import { modifyTargetGroupApiData } from "./campaignCreate";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateTargetGroup = (payload) => {
  return { type: types.TARGET_GROUP, payload: payload };
};

const updateTargetGroupSummary = (payload) => {
  return { type: types.TARGET_GROUP_SUMMARY, payload: payload };
};

const updateTargetGroupMedia = (payload) => {
  return { type: types.TARGET_GROUP_MEDIA, payload: payload };
};
const updateTargetGroupTheatres = (payload) => ({ type: types.TARGET_GROUP_THEATRES, payload });
const updateTargetGroupScreens = (payload) => ({ type: types.TARGET_GROUP_SCREENS, payload });
const updateTargetGroupSpots = (payload) => ({ type: types.TARGET_GROUP_SPOTS, payload: payload });

const getTargetGroup = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getTargetGroupApi(params.targetGroupId);
      const _targetGroup = { ...modifyTargetGroupApiData(data) };
      dispatch(updateTargetGroup(_targetGroup));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getTargetGroupSummary = (targetGroupId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getTargetGroupSummaryApi(targetGroupId);
      dispatch(updateTargetGroupSummary(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getTargetGroupMedia = (params = {}, filters = [], getList = getTargetGroupMediaApi) => {
  const {
    page = 1,
    ps = constants.DEFAULT_PAGE_SIZE,
    sort = "createdAt:asc",
    targetGroupId,
  } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(targetGroupId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTargetGroupMedia(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};
const getTheatresList = (id, params) => {
  const { ps, page, sort, filters = [], targetType } = params;
  switch (targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return getTargetGroupTheatresApi(id, ps, (page - 1) * ps, sort, filters);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return getTargetGroupScreensApi(id, ps, (page - 1) * ps, sort, filters);
    default:
      return;
  }
};

const updateTheatresAndScreens = (params, data) => {
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return updateTargetGroupTheatres(data);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return updateTargetGroupScreens(data);
    default:
      return;
  }
};

const getTargetGroupTheatres = (params = {}) => {
  const { id } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getTheatresList(id, params);
      dispatch(updateTheatresAndScreens(params, data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const pauseOrResumeTargetGroup = (data) => {
  const { req: ft } = createFilterRequest(
    data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const targetGroup = { ft, ...data };
  return async (dispatch) => {
    try {
      await pauseOrResumeTargetGroupApi(targetGroup);
      dispatch(getTargetGroup({ targetGroupId: data.targetGroupId }));
      showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      dispatch(getTargetGroup({ targetGroupId: data.targetGroupId }));
      showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const cancelTargetGroup = (data, callback = () => {}) => {
  const { req: ft } = createFilterRequest(
    data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const targetGroup = { ft, ...data };
  return async (dispatch) => {
    try {
      await cancelTargetGroupsApi(
        data.campaignId,
        omit(targetGroup, ["ids", "campaignId", "targetGroupId"])
      );
      dispatch(getTargetGroup({ targetGroupId: data.targetGroupId }));
      callback();
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
//action to get Target Group Spots
const getTargetGroupSpots = (params = {}, filters = [], getList = getTargetGroupSpotsApi) => {
  const {
    page = 1,
    ps = constants.DEFAULT_PAGE_SIZE,
    sort = "createdAt:asc",
    targetGroupId,
  } = params;
  return async (dispatch) => {
    try {
      const { filters: defaultFilter } = params;
      filters = filters.length === 0 ? defaultFilter : filters;
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(targetGroupId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTargetGroupSpots(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

export {
  getTargetGroup,
  getTargetGroupSummary,
  getTargetGroupMedia,
  getTargetGroupTheatres,
  cancelTargetGroup,
  pauseOrResumeTargetGroup,
  getTargetGroupSpots,
};
