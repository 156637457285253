import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import CompanySettings from "../Lists/CompanySettings";
import styles from "../../../../styles/Settings/CompanySettings.module.scss";

export class GeneralSettings extends Component {
  render() {
    const { userData, actions, thirdpartyIdentifiers } = this.props;

    return (
      <div className={`col col-12 ${styles.companyContainer}`}>
        <CompanySettings
          searchThirdpartyIdentifier={actions.searchThirdpartyIdentifier}
          thirdpartyIdentifiers={thirdpartyIdentifiers}
          saveCompanyPreferences={actions.saveCompanyPreferences}
          userData={userData}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(GeneralSettings);
