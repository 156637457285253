// DEBUG ONLY - SAMPLE COMPONENT TO COMBINE ALL TABLE COMPONENTS FOR TESTING

import React, { Component } from "react";

import { FormGroup, TextArea } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Table, TablePagination } from "workbench";
import * as constants from "../../constants";
import Selector from "../common/Selector";
import Popup from "../common/Popup";
import cleanIDSelection from "../../utils/cleanIDSelection";

const generateDebugData = () => {
  const friends = [];
  for (let i = 1; i < 241; i++) {
    friends.push({
      id: `${i}`,
      name: `${i % 2 ? "Jane" : "John"} Doe ${i}`,
      age: 20 + (i % 5),
      gender: i % 2 ? "Female" : "Male",
      friend: { name: `${i - (1 % 2) ? "Jane" : "John"} Doe ${i + 1}`, age: 20 + ((i + 1) % 5) },
    });
  }
  return friends;
};

class TableSample extends Component {
  state = {
    shownColumns: [
      "Name",
      "Age",
      "Gender",
      "Friend Name - Long Header Example Which Should Ellipsize",
      "Friend Age",
    ],
    pageSize: 50,
    currentPage: 1,
    selection: [],
    selectAll: false,
    showPopup: false,
    uuid: "",
    allFriends: generateDebugData(),
  };

  onPageChange = (currentPage) => this.setState({ currentPage });
  onColumnSelect = (shownColumns) => this.setState({ shownColumns });
  changePageSize = ([pageSize]) => this.setState({ pageSize, currentPage: 1 });
  onRowSelect = (selection, selectAll) => this.setState({ selection, selectAll });

  selectUUID = () => {
    this.setState((state) => {
      const { allFriends, uuid } = state;
      const selection = cleanIDSelection(uuid);
      const selectedFriends = selection
        .map((s) => allFriends.find((f) => f.id === s))
        .filter((s) => s);
      if (selection.length > 0) {
        const newData = [
          ...selectedFriends,
          ...allFriends.filter((f) => !selection.includes(f.id)),
        ];
        return {
          allFriends: newData,
          selection: selectedFriends.map((s) => s.id),
          selectAll: false,
          showPopup: false,
          uuid: "",
          currentPage: 1,
        };
      }
    });
  };
  logSelection = () => console.log("selection:", this.state.selection); // eslint-disable-line no-console
  handleUUID = (e) => this.setState({ uuid: e.target.value });
  selectUsingID = ([selectIDType]) => this.setState({ selectIDType, showPopup: true });
  closePopup = () => this.setState({ showPopup: false, uuid: "" });

  renderSelectUUID = () => {
    return (
      <div>
        <FormGroup helperText="Separate multiple IDs with a new line or a comma. This will override your current selection.">
          <TextArea fill rows={15} onChange={this.handleUUID} value={this.state.uuid} />
        </FormGroup>
      </div>
    );
  };

  render() {
    const { shownColumns, selectAll, selection, pageSize, currentPage, allFriends } = this.state;

    const offset = pageSize * (currentPage - 1);
    const data = allFriends.slice(offset, offset + pageSize);
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        id: "friendName",
        Header: "Friend Name - Long Header Example Which Should Ellipsize",
        accessor: (d) => d.friend.name,
      },
      {
        id: "friendAge",
        Header: "Friend Age",
        accessor: (d) => d.friend.age,
      },
    ];

    const pagination = (
      <TablePagination
        totalRecords={allFriends.length}
        pageSize={pageSize}
        onPageChange={this.onPageChange}
        currentPage={currentPage}
      />
    );

    const tableColumns = columns.filter((c) => !c.Header || shownColumns.includes(c.Header));
    return (
      <div style={{ padding: "15px" }}>
        <div className="clearfix">
          <div onClick={this.logSelection} className="col col-12 m2 h3">
            Table Example
          </div>
        </div>
        <div className="flex flex-wrap col-12 py2 px2 clearfix">
          <div className="flex-auto" />
          <div className="flex flex-wrap align-center">
            <div className="mr2">
              <Selector
                label="Select using ID"
                onSelect={this.selectUsingID}
                singleSelect
                noSelectedIcon
                list={["Movie", "Screen", "Theatre"]}
                placeHolder="Select using ID"
              />
            </div>
            <div className="flex mr2">
              <Selector
                label="Columns"
                list={columns.map((c) => c.Header)}
                selectedList={shownColumns}
                onSelect={this.onColumnSelect}
                placeHolder="Columns"
              />
            </div>
            <div className="mr2 h6">
              <Selector
                label="SHOW"
                selectedList={[pageSize]}
                onSelect={this.changePageSize}
                showLabelInButton={false}
                singleSelect
                list={constants.PAGE_SIZE_OPTIONS}
                placeHolder="SHOW"
              />
            </div>
            <div className="flex-wrap">{pagination}</div>
          </div>
        </div>
        <div className="clearfix">
          <Table
            selectable
            manual={false}
            data={data}
            columns={tableColumns}
            pageSize={pageSize}
            selectAll={selectAll}
            selection={selection}
            onSelect={this.onRowSelect}
          />
          <Popup
            isOpen={this.state.showPopup}
            icon={IconNames.INFO_SIGN}
            onClose={this.closePopup}
            onConfirm={this.selectUUID}
            title={`Select using ${this.state.selectIDType} ID`}
            body={this.renderSelectUUID()}
          />
        </div>

        <div className="flex flex-wrap col-12 py2 px2 clearfix">
          <div className="flex-auto" />
          <div className="flex flex-wrap ">
            <div className="flex-wrap">{pagination}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableSample;
