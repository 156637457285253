import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "workbench";
import { formatDateTime } from "../../../../utils";
import * as constants from "../../../../constants";
import ActionIcon from "../../../common/ActionIcon";
import FooterControls from "../../../common/FooterControls";
import { checkScopes } from "../../../../utils";
import EditTax from "./EditTax";

export default class Taxes extends Component {
  static propTypes = {
    taxes: PropTypes.array,
    isLoading: PropTypes.bool,
    actions: PropTypes.object,
    tax: PropTypes.object,
    editTax: PropTypes.func,
    cancelEdit: PropTypes.func,
  };
  addNewField = () => this.props.addTax();
  onSave = (value) => {
    const { params, countryId, actions } = this.props;
    actions.createOrUpdateTax(countryId, { ...value, countryId }, () =>
      actions.getCountryTaxes(params.countryId)
    );
  };

  render = () => {
    const { taxes, isLoading, tax, editTax, cancelEdit, userData } = this.props;

    const columns = checkScopes(
      [
        { id: "code", Header: "Code", sortable: false, accessor: "code" },
        { id: "name", Header: "Name", sortable: false, accessor: "name" },
        { id: "percentage", Header: "Percentage", sortable: false, accessor: "taxPercentage" },
        {
          id: "validity",
          Header: "Validity",
          sortable: false,
          showTimeZone: true,
          accessor: (d) => `${formatDateTime(d.validFrom)} - ${formatDateTime(d.validTo)}`,
        },
        { id: "description", Header: "Description", sortable: false, accessor: "description" },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.COUNTRIES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => editTax(props.original.countryId, props.original.id),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.COUNTRIES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addNewField,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Table data={taxes} loading={isLoading} pageSize={taxes.length} columns={columns} />
        <FooterControls {...addButtonProps} />
        {tax && tax.isOpen && (
          <EditTax tax={tax.tax} isOpen={tax.isOpen} onSave={this.onSave} onClose={cancelEdit} />
        )}
      </div>
    );
  };
}
