import { isEmpty } from "lodash";
import React, { Component } from "react";

import { Position, Tooltip } from "@blueprintjs/core";
import { CAMPAIGN_STATUS_LIST } from "../../../../constants";
import styles from "../../../../styles/Campaigns/CampaignTree.module.scss";
import InfoBlock from "../../../common/InfoBlock";
import StatusIcon from "../../../common/StatusIcon";
import { getCompleteCampaignSummary } from "../../../../api";
import CampaignCard from "./cards/CampaignCard";
import CardSkeletonLoader from "./cards/CardSkeletonLoader";
import MediaCard from "./cards/MediaCard";
import TargetGroupCard from "./cards/TargetGroupCard";

export default class CampaignTree extends Component {
  state = {
    isExpanded: true,
    summaryData: {},
    isLoading: true,
  };

  static defaultProps = {
    isNewCampaign: false,
  };

  async componentDidMount() {
    const { campaignCreate, campaignIdForSummary } = this.props;

    let summaryData = {};
    const _id = campaignCreate?.campaign?.id || campaignIdForSummary;

    try {
      if (_id) {
        summaryData = (await getCompleteCampaignSummary(_id)).data;
        this.setState({
          summaryData: summaryData,
          isLoading: false,
        });
      }
    } catch (error) {}
  }

  handleCardToggle = (status) => {
    this.setState({
      isExpanded: status,
    });
  };

  renderTargetGroups = () => {
    const { campaignCreate } = this.props; // Removed reference to campaignTreeData
    // const { campaignCreate, campaignTreeData } = this.props; // Removed reference to campaignTreeData
    const { isExpanded, summaryData } = this.state;
    // const targetGroupList = campaignTreeData?.targetGroups ?? [];
    const targetGroupList = summaryData?.targetGroupSummaryData ?? []; // Changed to mockSummaryData

    return targetGroupList.map((targetGroup, index) => {
      const mediaList = targetGroup?.mediaSummaryData ?? [];

      return (
        <TargetGroupCard
          key={index}
          title={targetGroup.name || "New Target Group"}
          targetGroupData={{
            ...targetGroup,
          }}
          isExpanded={isExpanded}
          campaignCreate={campaignCreate}
        >
          {!isEmpty(mediaList) &&
            mediaList.map((media, index) => (
              <MediaCard key={index} mediaData={media} title={media.name || "New Media"} />
            ))}
        </TargetGroupCard>
      );
    });
  };

  campaignStatusInfo = CAMPAIGN_STATUS_LIST.map((status) => ({
    name: status.displayName,
    id: status.id,
    type: status.type,
    color: status.color,
  }));

  render() {
    // const { isLoading } = this.props; // Removed to campaignTreeData
    const { summaryData, isLoading } = this.state;
    //const { campaignCreate, campaignTreeData } = this.props;
    // if (!(campaignCreate?.campaign?.id || campaignTreeData?.id))
    //   return <div className={styles.loaderContainer}></div>;
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <CardSkeletonLoader />
          <CardSkeletonLoader />
          <CardSkeletonLoader />
          <CardSkeletonLoader />
        </div>
      );
    }

    return (
      <div className={styles.container} style={{ overflow: "auto", maxHeight: "35rem" }}>
        <InfoBlock
          header={
            <div className={styles.header}>
              <Tooltip
                className={styles.toolTip}
                boundary={true}
                content={summaryData?.status} // Changed to summaryData
                position={Position.AUTO}
                usePortal={false}
              >
                <StatusIcon
                  color={
                    this.campaignStatusInfo.find((status) => status.id === summaryData?.status)
                      ?.color
                  }
                  type={
                    this.campaignStatusInfo.find((status) => status.id === summaryData?.status)
                      ?.type
                  }
                />
              </Tooltip>

              <span className={styles.summaryText}>Campaign Summary</span>
            </div>
          }
          className={""}
        >
          <CampaignCard
            campaignData={summaryData} // Changed to mockSummaryData
            onCardToggle={(status) => this.handleCardToggle(status)}
            campaignCreate={null} // Changed to null
          >
            {this.renderTargetGroups()}
          </CampaignCard>
        </InfoBlock>
      </div>
    );
  }
}
