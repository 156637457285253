import React from "react";
import { FormattedMessage } from "react-intl";

const emptyDataMessage = (header = "", body = "") => {
  return {
    heading: <FormattedMessage id={header} defaultMessage={header} />,
    body: <FormattedMessage id={body} defaultMessage={body} />,
  };
};

export default emptyDataMessage;
