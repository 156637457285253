import React from "react";
import moment from "moment";
import StatusIcon from "../components/common/StatusIcon";
import { STATUS_ICON_COLORS, STATUS_ICON_TYPES } from "../constants";

const getValidityStatusColor = (status) => {
  switch (status) {
    case "active":
      return STATUS_ICON_COLORS.GREEN;
    case "valid":
      return STATUS_ICON_COLORS.GREEN;
    case "future":
      return STATUS_ICON_COLORS.LIGHT_BLUE;
    case "expired":
      return STATUS_ICON_COLORS.GREY;
    default:
      return STATUS_ICON_COLORS.GREY;
  }
};
const dateValidity = (date) => {
  return (
    <>
      <span className="align-top">
        <StatusIcon color={getValidityStatusColor(date.status)} type={STATUS_ICON_TYPES.CIRCLE} />
      </span>
      {!date.fromDate && !date.toDate && " - "}
      {date.fromDate && !date.toDate && "From "}
      {date.fromDate
        ? moment(`${date.fromDate} ${date.startTime}`, "YYYY-MM-DD HH:mm")
            .utcOffset("+05:30")
            .format("MMM DD, YYYY")
        : ""}
      {date.fromDate && date.toDate && " - "}
      {!date.fromDate && date.toDate && "Till "}
      {date.toDate
        ? moment(`${date.toDate} ${date.endTime}`, "YYYY-MM-DD HH:mm")
            .utcOffset("+05:30")
            .format("MMM DD, YYYY")
        : ""}
    </>
  );
};

export default dateValidity;
