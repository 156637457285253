// parses sort string into array
const parseSort = (sort) => {
  if (!sort) return;
  const temp = decodeURIComponent(sort).split(",");
  const sorted = temp.map((d) => ({
    id: d.substr(0, d.indexOf(":")),
    desc: d.substr(d.indexOf(":") + 1) === "desc",
  }));
  return sorted;
};

// changes single sort item to string
const stringifySort = (column, isDesc) => {
  const order = isDesc ? "desc" : "asc";
  return `${column}:${order}`;
};

// Returns params in a format needed by actions
// Mainly parses only sort :p
const getParams = ({ ps, page, sort, ...extra }) => {
  const sortParams = sort?.reduce(
    (params, sortItem, index) =>
      `${params}${stringifySort(sortItem.id, sortItem.desc)}${
        index + 1 !== sort.length ? "," : ""
      }`,
    ""
  );
  return {
    ps,
    page,
    sort: sortParams,
    ...extra,
  };
};

export { parseSort, stringifySort, getParams };
