import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty, partition } from "lodash";
import { FORM_FIELD_TYPE, SCREEN_STATUSES } from "../../../../../constants";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import styles from "../../../../../styles/Inventory/ActivateOrDeactivateScreens.module.scss";
import LocalizedButton from "../../../../common/LocalizedButton";
import BulkActionStyles from "../../../../../styles/BulkActionButtons.module.scss";

export default class ActivateOrDeactivateScreens extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: {
        startDate: null,
        endDate: null,
      },
      status: null,
      hasError: false,
      hasMultipleScreens: false,
      futureScreenStatus: null,
      currentScreenStatus: null,
    };
  }

  componentDidMount() {
    const { selectedScreens, isBulkAction, activationType } = this.props;
    if (
      selectedScreens &&
      selectedScreens.length === 1 &&
      selectedScreens[0]?.screenSettings?.length === 1
    ) {
      this.updateScreenStatus(selectedScreens[0]?.screenSettings[0]);
    } else {
      this.setState({
        hasMultipleScreens: isBulkAction && selectedScreens.length > 1,
        status: activationType,
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { selectedScreens } = this.props;
    if (
      prevProps.selectedScreens !== selectedScreens &&
      selectedScreens.length === 1 &&
      selectedScreens[0]?.screenSettings?.length === 1
    ) {
      this.updateScreenStatus(selectedScreens[0]?.screenSettings[0]);
    }
  };

  updateScreenStatus = (settings) => {
    const { screenStatusList } = settings;
    const [[currentScreenStatus = null], [futureScreenStatus = null]] = partition(
      screenStatusList,
      (s) => s.isCurrent
    );
    const { startDate = null, endDate = null, screenStatus = "" } =
      futureScreenStatus ?? currentScreenStatus;
    if (
      !isEmpty(futureScreenStatus) &&
      [SCREEN_STATUSES.ACTIVE, SCREEN_STATUSES.INACTIVE].includes(screenStatus)
    ) {
      this.handleClosePanel();
    } else {
      this.setState({
        period: {
          startDate: futureScreenStatus ? startDate : moment().format("YYYY-MM-DD"),
          endDate: futureScreenStatus ? endDate : null,
        },
        status: screenStatus,
        futureScreenStatus: futureScreenStatus,
        currentScreenStatus: currentScreenStatus,
      });
    }
  };

  componentWillUnmount = () => {
    this.handleClosePanel();
  };

  handleClosePanel = () => {
    this.setState({
      hasError: false,
      period: {
        startDate: null,
        endDate: null,
      },
    });
    this.props.onClose();
  };

  isDatePeriodValid = (period) => {
    return period.endDate
      ? moment(period.endDate).isAfter(period.startDate)
      : moment(period.startDate).isValid();
  };

  getActivationType = () => {
    const { status } = this.state;
    if ([SCREEN_STATUSES.INACTIVE_IN_FUTURE, SCREEN_STATUSES.ACTIVE].includes(status))
      return SCREEN_STATUSES.INACTIVE;

    if ([SCREEN_STATUSES.ACTIVE_IN_FUTURE, SCREEN_STATUSES.INACTIVE].includes(status))
      return SCREEN_STATUSES.ACTIVE;
  };

  save = () => {
    const { period } = this.state;
    if (!this.isDatePeriodValid(period)) {
      this.setState({ hasError: true });
      return;
    }
    this.props.onActivateOrDeactivate(
      {
        startDate: moment(period.startDate).format("YYYY-MM-DD"),
        endDate: period.endDate ? moment(period.endDate).format("YYYY-MM-DD") : null,
        status: this.getActivationType(),
      },
      this.handleClosePanel
    );
  };

  clear = () => {
    this.props.onActivateOrDeactivate(
      {
        startDate: moment().format("YYYY-MM-DD"),
        status: this.getActivationType(),
      },
      this.handleClosePanel
    );
  };

  inputOnChange = (value, accessor) => {
    const { period } = this.state;
    period[accessor] = value;
    // Resetting To date to avoid invalid date error when from date is changed
    // Since End Date is optional clearing the field should set the value to null
    if (accessor === "endDate" && value === "Invalid date") {
      period.endDate = null;
    } else if (accessor === "startDate") {
      if (value === "Invalid date") period.startDate = null;
      period.endDate = null;
    }
    this.setState({
      period,
      hasError: false,
    });
  };

  resolveConflicts = () => {
    this.setState({
      hasMultipleScreens: false,
      period: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: null,
      },
    });
  };

  getPanelHeaderTitle = () => {
    const { status } = this.state;

    if (status === SCREEN_STATUSES.INACTIVE) {
      return "RightPanelHeader.activateScreen";
    } else if (status === SCREEN_STATUSES.ACTIVE) {
      return "RightPanelHeader.deactivateScreen";
    } else if (status === SCREEN_STATUSES.INACTIVE_IN_FUTURE) {
      return "RightPanelHeader.editDeactivation";
    } else {
      return "RightPanelHeader.editActivation";
    }
  };

  render = () => {
    const { isOpen, selectionInfo, isBulkAction, screenInfo, isLoading } = this.props;
    const {
      status,
      period = { startDate: null, endDate: null },
      hasError,
      hasMultipleScreens,
    } = this.state;
    const isActivation = [SCREEN_STATUSES.ACTIVE_IN_FUTURE, SCREEN_STATUSES.INACTIVE].includes(
      status
    );
    const activationButtonText = isActivation ? "Button.activate" : "Button.deactivate";
    const fieldLabel = isActivation ? "Activate" : "Deactivate";
    const startDate = period?.startDate ? new Date(period?.startDate) : new Date();
    const isEdit = [SCREEN_STATUSES.INACTIVE_IN_FUTURE, SCREEN_STATUSES.ACTIVE_IN_FUTURE].includes(
      status
    );

    return (
      <RightPanel
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={() => this.handleClosePanel()}
        header={this.getPanelHeaderTitle()}
        showFooter
        primaryButtonProps={[
          {
            text: isEdit ? "Button.save" : activationButtonText,
            onClick: this.save,
            isHidden: hasMultipleScreens,
            className: styles.save,
          },
          {
            text: "Button.clear",
            isHidden: hasMultipleScreens || !isEdit,
            onClick: this.clear,
          },
        ]}
        secondaryButtonProps={{
          text: "Button.cancel",
          onClick: this.handleClosePanel,
        }}
      >
        {isBulkAction && (
          <div className={BulkActionStyles.selectionInfoContainer}>
            {selectionInfo.map(({ count, label }, index) => (
              <div className={BulkActionStyles.selectionInfo} key={index}>
                <span>{count}</span> {label}{" "}
                {index + 1 === selectionInfo.length ? "Selected" : null}
              </div>
            ))}
          </div>
        )}
        {isBulkAction && hasMultipleScreens && (
          <>
            <p className={styles.actionInfo}>Selected Screens have Multiple Durations</p>
            <LocalizedButton
              className={`${styles.removeButton}`}
              iconName="CancelFilledIcon"
              text="Button.removeDurations"
              onClick={(e) => {
                this.resolveConflicts();
              }}
            />
          </>
        )}
        {!hasMultipleScreens && screenInfo && (
          <div className={styles.actionInfo}>
            {screenInfo?.theatre ? `${screenInfo.theatre.name} - ` : ""}
            {screenInfo.name}
          </div>
        )}
        {!hasMultipleScreens && (
          <Form
            config={[
              {
                type: FORM_FIELD_TYPE.DATE,
                title: `${fieldLabel} From`,
                error: hasError && !period.startDate,
                errorMessage: "Enter Valid From Date",
                invalidDateMessage: "Invalid From Date",
                showClearIcon: !!period.startDate,
                onClear: () => this.inputOnChange(null, "startDate"),
                onChange: (v) => {
                  this.inputOnChange(moment(v).format("YYYY-MM-DD"), "startDate");
                },
                value: period.startDate ? new Date(period.startDate) : null,
                formatDate: (date) => moment(date).format("YYYY-MM-DD"),
                placeholder: "MMM DD, YYYY",
                minDate: new Date(),
              },
              {
                type: FORM_FIELD_TYPE.DATE,
                title: `${fieldLabel} To (Optional)`,
                error:
                  hasError && period.endDate && !moment(period.endDate).isAfter(period.startDate),
                errorMessage: "Enter Valid To Date",
                invalidDateMessage: "Invalid To Date",
                showClearIcon: !!period.endDate,
                onClear: () => this.inputOnChange(null, "endDate"),
                onChange: (v) => {
                  this.inputOnChange(moment(v).format("YYYY-MM-DD"), "endDate");
                },
                value: period.endDate ? new Date(period.endDate) : null,
                formatDate: (date) => moment(date).format("YYYY-MM-DD"),
                placeholder: "MMM DD, YYYY",
                minDate: startDate,
                initialMonth: startDate,
                defaultValue: startDate,
              },
            ]}
          />
        )}
      </RightPanel>
    );
  };
}

ActivateOrDeactivateScreens.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onActivateOrDeactivate: PropTypes.func.isRequired,
  screenInfo: PropTypes.object,
  isBulkAction: PropTypes.bool,
  activationType: PropTypes.string,
};
