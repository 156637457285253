import React, { Component } from "react";
import queryString from "query-string";
import { SIZE } from "prefab";
import styles from "../../styles/Login.module.scss";
import { QA_CLIENT_ID, QA_BASE_URL, QA_PRODUCT_ID } from "../../config";
import { SlateLogo, LoginBanner } from "../../assets";
import LocalizedButton from "../common/LocalizedButton";
import { LOCAL_STORAGE_KEY } from "../../constants";

const isAdminApp = process.env.REACT_APP_IS_ADMIN_APP;

export class Login extends Component {
  componentDidMount() {
    const { location } = this.props.history;
    const params = queryString.parse(location.search);
    const nextUrl = params?.next ?? "";
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY.NEXT_URL, nextUrl);
    } catch {
      // eslint-disable-next-line no-console
      console.log("Local Storage Error");
    }
  }

  redirect = (redirect_uri) => {
    const url = `${QA_BASE_URL}dialog/authorize?${queryString.stringify({
      client_id: QA_CLIENT_ID,
      product_id: QA_PRODUCT_ID,
      redirect_uri,
      response_type: "token",
    })}`;
    window.location = url;
  };

  login = () => this.redirect(`${window.location.origin}/auth`);

  register = (ct) =>
    this.redirect(`${window.location.origin}/auth?${queryString.stringify({ ct })}`);

  renderAdminLogin = () => {
    return (
      <div className={styles.admin}>
        <div className={styles.card}>
          <div className={styles.logo}>
            <SlateLogo width="225" height="32" />
          </div>
          <div className={styles.intro}>
            Qube Slate is a content scheduling platform that acts as a marketplace to allow
            Advertisers, Media Agencies and Exhibitors to plan campaigns, target content to specific
            theatres, and schedule content at the theatres for playback.
          </div>
          <div className={styles.buttonWrap}>
            <LocalizedButton
              buttonSize={SIZE.LARGE}
              onClick={this.login}
              text="Button.login"
              className={styles.button}
            />
          </div>
        </div>
      </div>
    );
  };

  renderUserLogin = () => {
    return (
      <div className={styles.user}>
        <div className={styles.navBar}>
          <div className={styles.logo}>
            <SlateLogo width="225" height="32" />
          </div>
          <div className={styles.actions}>
            <LocalizedButton onClick={this.login} text="Button.login" buttonSize={SIZE.LARGE} />
            {/* NOTE: To enable Sign Up, Pass Customer Segment to Register Function; See example below */}
            {/* <LocalizedButton
              onClick={() => this.register(constants.CUSTOMER_SEGMENT.MEDIA_AGENCY)}
              text="Button.signup"
              buttonSize={SIZE.LARGE}
            /> */}
          </div>
        </div>
        <div className={styles.page}>
          <div className={styles.description}>
            <span className={styles.title}>Taking your Ads to the Cinemas of your choice</span>
            <p>
              Qube Slate is a content scheduling platform that acts as a marketplace to allow
              Advertisers, Media Agencies and Exhibitors to plan campaigns, target content to
              specific theatres, and schedule content at the theatres for playback.
            </p>
          </div>
          <div className={styles.placeholder}>
            <LoginBanner />
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (isAdminApp) return this.renderAdminLogin();
    else return this.renderUserLogin();
  }
}

export default Login;
