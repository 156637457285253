import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Position } from "@blueprintjs/core";
import { createSelector } from "reselect";
import moment from "moment";
import { Icons } from "prefab";
import { isEmpty, includes } from "lodash";
import { bindDispatch, getScreenValidity, getParams } from "../../../../../../utils";
import { getScreenStatusLegends } from "../../../../../../utils/screens/statusLegend";
import checkScopeAuth from "../../../../../../utils/checkScopeAuth";
import PageLoadingWithTable from "../../../../../common/PageLoadingWithTable";
import * as constants from "../../../../../../constants";
import ActionIcon from "../../../../../common/ActionIcon";
import StatusIcon from "../../../../../common/StatusIcon";
import ScreenTable from "../../../../../tables/Screen";
import { ActiveTableCellColumnFromArray } from "../../../../../common/ActiveTableCell";
import styles from "../../../../../../styles/Inventory/ScreenList.module.scss";

const { LogsIcon, ViewIcon, PauseIcon, PlayIcon, EditIcon, CancelIcon } = Icons;
export class TheatreScreensList extends Component {
  static defaultProps = {
    isAdminApp: false,
  };

  constructor(props) {
    super(props);
    this.screenTable = React.createRef();
  }

  state = {
    selectedColumns: [
      "Screen Name",
      "Experiences",
      "Digital Integrator",
      "Projection Type",
      "Cooling Type",
      "Auditorium Dimensions (W x L x H)",
      "Auditorium Capacity",
      "Occupancy",
      "Ticket Price",
    ],
    selection: [],
  };

  openAction = (action, screenToBeModified) => {
    this.setState({ openedAction: action, screenToBeModified: screenToBeModified });
  };

  renderExperiences = (d) => {
    if (isEmpty(d.threeDModels) && isEmpty(d.soundMixes)) {
      return "-";
    }
    return (
      <>
        <div>{ActiveTableCellColumnFromArray(d.threeDModels, this.props.onFilterIdSelect)}</div>
        <div>{ActiveTableCellColumnFromArray(d.soundMixes, this.props.onFilterIdSelect)}</div>
      </>
    );
  };

  renderTicketPrices = (seatingClasses) => {
    if (isEmpty(seatingClasses)) {
      return "-";
    }

    return seatingClasses.map((seatingClass, index) => {
      return (
        <div key={index}>
          <i>{seatingClass.name}</i>
          &nbsp;
          {seatingClass.value}
          <br />
        </div>
      );
    });
  };

  renderDropdownMenu = (props, hasWriteScope) => {
    const {
      SCOPES,
      SCREEN_STATUSES: { ACTIVE, INACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
    } = constants;
    const { history, userData } = this.props;
    const {
      companyScreenStatus = [{ screenStatus: "" }],
      id,
      screenStatusList = [],
    } = props.original;

    const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const currentScreenStatus = isEmpty(futureScreenStatus)
      ? companyScreenStatus?.[0]
      : futureScreenStatus;
    const { screenStatus: status } = currentScreenStatus;

    return (
      <ActionIcon
        iconProps={[
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: [
              {
                text: "View",
                onClick: () => history.push(`/screens/${props.original.id}/details`),
                icon: <ViewIcon />,
              },
              {
                text: "Logs",
                onClick: () => this.props.history.push(`/logs/ref/${id}`),
                hideMenuItem: !checkScopeAuth(userData, SCOPES.LOGS),
                icon: <LogsIcon />,
              },
              {
                text: "Activate",
                onClick: () => this.screenTable?.openScreenAction(ACTIVE, props.original),
                hideMenuItem: !(hasWriteScope && status === INACTIVE),
                icon: <PlayIcon />,
              },
              {
                text: "Deactivate",
                onClick: () => this.screenTable?.openScreenAction(INACTIVE, props.original),
                hideMenuItem: !(hasWriteScope && status === ACTIVE),
                icon: <PauseIcon />,
              },
              {
                text: "Edit Deactivation",
                onClick: () =>
                  this.screenTable?.openScreenAction(INACTIVE_IN_FUTURE, props.original),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === INACTIVE_IN_FUTURE
                ),
                icon: <EditIcon />,
              },
              {
                text: "Clear Deactivation",
                onClick: () =>
                  this.props.actions.updateTheatreScreensStatus(
                    this.props.userData.company.id,
                    [id],
                    {
                      status: ACTIVE,
                      startDate: moment().format("YYYY-MM-DD"),
                    },
                    getParams({
                      ps: this.props.ps,
                      page: this.props.page,
                      sort: this.props.sort,
                      companyId: this.props.userData.company.id,
                    })
                  ),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === INACTIVE_IN_FUTURE
                ),
                icon: <CancelIcon />,
              },
              {
                text: "Edit Activation",
                onClick: () => this.screenTable?.openScreenAction(ACTIVE_IN_FUTURE, props.original),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === ACTIVE_IN_FUTURE
                ),
                icon: <EditIcon />,
              },
              {
                text: "Clear Activation",
                onClick: () =>
                  this.props.actions.updateTheatreScreensStatus(
                    this.props.userData.company.id,
                    [id],
                    {
                      status: INACTIVE,
                      startDate: moment().format("YYYY-MM-DD"),
                    },
                    getParams({
                      ps: this.props.ps,
                      page: this.props.page,
                      sort: this.props.sort,
                      companyId: this.props.userData.company.id,
                    })
                  ),
                hideMenuItem: !(hasWriteScope && status === ACTIVE_IN_FUTURE),
                icon: <CancelIcon />,
              },
            ],
          },
        ]}
      />
    );
  };

  renderStatusIcon = (cellProps) => {
    if (isEmpty(cellProps?.original?.companyScreenStatus) || this.props.isAdminApp) return null;

    const {
      SCREEN_STATUSES: { ACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
      STATUS_ICON_COLORS: { GREEN, GREY },
      STATUS_ICON_TYPES: { DURATION, CIRCLE },
    } = constants;
    const {
      companyScreenStatus = [],
      screenSettings: [{ screenStatusList }],
    } = cellProps.original;
    const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const currentScreenStatus = isEmpty(futureScreenStatus)
      ? companyScreenStatus?.[0]
      : futureScreenStatus;
    const { screenStatus, startDate, endDate } = currentScreenStatus;

    return (
      <div className={classNames("flex align-center", styles.status)}>
        <StatusIcon
          toolTipClass={styles.statusTooltip}
          color={[ACTIVE, INACTIVE_IN_FUTURE].includes(screenStatus) ? GREEN : GREY}
          type={[ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(screenStatus) ? DURATION : CIRCLE}
          showToolTip={!isEmpty(futureScreenStatus)}
          toolTip={getScreenValidity(screenStatus, startDate, endDate)}
          toolTipPosition={Position.LEFT}
        />
      </div>
    );
  };

  renderStatusWithActions = (cellProps, hasWriteScope) => {
    return (
      <div
        className={classNames("flex align-center", styles.actionItems)}
        onClick={this.props.onFilterIdSelect}
      >
        {this.renderStatusIcon(cellProps)}
        <div className={classNames("flex align-center", styles.moreItems)}>
          {this.renderDropdownMenu(cellProps, hasWriteScope)}
        </div>
      </div>
    );
  };

  getScreenListByStatus = (status) => {
    const {
      theatres: {
        theatre: { screens },
      },
    } = this.props;

    const { selection } = this.state;

    if (selection.length > 0) {
      return (
        screens.filter((screen) => {
          return (
            includes(selection, screen.id) &&
            screen?.companyScreenStatus?.length > 0 &&
            screen?.companyScreenStatus[0]?.screenStatus === status
          );
        }).length === selection.length
      );
    }

    return false;
  };

  getScreenColumns = () => {
    const { userData } = this.props;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.WRITE
    );
    return [
      {
        id: "name",
        Header: "Screen Name",
        accessor: (d) => `#${d.number} · ${d.name}`,
      },
      {
        id: "id",
        Header: "Screen ID",
        accessor: "id",
        sortable: false,
      },
      {
        id: "erpId",
        Header: "Screen ERP ID",
        accessor: "erpId",
      },
      {
        id: "davpCode",
        Header: "DAVP TH Code",
        accessor: "davpCode",
      },
      {
        id: "experiences",
        Header: "Experiences",
        accessor: (d) => this.renderExperiences(d),
      },
      {
        id: "digitalIntegrator",
        Header: "Digital Integrator",
        accessor: (d) => d?.digitalIntegrator?.name ?? "-",
      },
      {
        id: "projectionType",
        Header: "Projection Type",
        accessor: (d) =>
          d?.projectionTypes?.map((p) => p.name).join(`${constants.SPECIAL_CHARACTERS.COMMA} `) ??
          "-",
      },
      {
        id: "coolingType",
        Header: "Cooling Type",
        accessor: (d) => d?.coolingType?.name ?? "-",
      },
      {
        id: "auditoriumDimensions",
        Header: "Auditorium Dimensions (W x L x H)",
        accessor: (d) =>
          `W: ${d?.width || 0} ft x H: ${d?.height || 0} ft x L: ${d?.length || 0} ft`,
      },
      {
        id: "auditoriumCapacity",
        Header: "Auditorium Capacity",
        accessor: "seatingCapacity",
      },
      {
        id: "occupancy",
        Header: "Occupancy",
        accessor: (d) => `${d.averageOccupancyInPercentage || 0}%`,
      },
      {
        id: "ticketPrice",
        Header: "Ticket Price",
        accessor: (d) => this.renderTicketPrices(d.seatingClasses),
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        Cell: (d) => this.renderStatusWithActions(d, hasWriteScope),
      },
    ];
  };

  render = () => {
    const {
      theatres: { isLoading, theatreScreens, isTheatreScreensLoading },
      userData,
      actions,
    } = this.props;

    const { selectedColumns } = this.state;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.WRITE
    );
    return (
      <ScreenTable
        {...this.props}
        ref={(ref) => (this.screenTable = ref)}
        showScreenStatusActions
        withStatusLegend={false}
        screenList={{ data: theatreScreens }}
        isLoading={isTheatreScreensLoading}
        selectedColumns={selectedColumns}
        columns={this.getScreenColumns()}
        statusList={getScreenStatusLegends(theatreScreens)}
        tagTypes={[constants.TAG_TYPE.SCREEN]}
        sortable={false}
        showPagination={false}
        searchBar={false}
        showRowSize={false}
        isFilterable={false}
        isSelectable={hasWriteScope}
        updateScreenStatus={actions.updateTheatreScreensStatus}
      />
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.theatres,
  (state) => state.userData,
  (theatres, userData) => ({ theatres, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(TheatreScreensList));
