import React from "react";
import PropTypes from "prop-types";
import { BUTTON_TYPE } from "prefab";
import LocalizedButton from "../../../common/LocalizedButton";
import {
  VERIFICATION_DETAIL_FIELDS,
  VERIFICATION_STATUSES,
  VERIFICATION_ACTIONS,
} from "../../../../constants";
import styles from "../../../../styles/Verifications/ViewVerification.module.scss";
import MergeOptions from "./MergeOptions";
import renderDetails from "./details";

const renderName = (data) => {
  return [data.parentBrandName, data.individualBrandName, data.productName, data.name]
    .filter(Boolean)
    .join(" · ");
};

const VerificationDetails = ({
  data,
  brandDuplicates,
  brandData,
  getBrandData,
  reset,
  approve,
  reject,
  merge,
  businessTypesList,
  isBrandLoading,
  isConflictsLoading,
}) => {
  if (!data) {
    return null;
  }
  return (
    <div className={`sm-col sm-col-12 lg-col-8 ${styles.brandDetailsWrapper}`}>
      <div className={`col col-6 ${styles.detailsContainer}`}>
        <h3 className={`${styles.brandTitle}`}>{renderName(data)}</h3>
        {renderDetails(data, VERIFICATION_DETAIL_FIELDS[data.brand], businessTypesList)}
        <div className={`${styles.actionButtonContainer}`}>
          {data.isNewEntry && !data.hasConflict ? null : data.hasConflict ? (
            <LocalizedButton
              text={
                data.status === VERIFICATION_STATUSES.APPROVED
                  ? "Button.approved"
                  : "Button.ignoreConflictsAndApprove"
              }
              buttonType={BUTTON_TYPE.CONSTRUCTIVE_SECONDARY}
              isActive={!!(data.status === VERIFICATION_STATUSES.APPROVED)}
              className="ignoreButton"
              onClick={approve}
            />
          ) : (
            <LocalizedButton
              text={
                data.status === VERIFICATION_STATUSES.APPROVED
                  ? "Button.approved"
                  : "Button.approve"
              }
              buttonType={BUTTON_TYPE.CONSTRUCTIVE_SECONDARY}
              isActive={!!(data.status === VERIFICATION_STATUSES.APPROVED)}
              className={styles.approveButton}
              onClick={approve}
            />
          )}
          {data.activity === VERIFICATION_ACTIONS.UPDATE && (
            <LocalizedButton
              text={
                data.status === VERIFICATION_STATUSES.REJECTED ? "Button.rejected" : "Button.reject"
              }
              buttonType={BUTTON_TYPE.DESTRUCTIVE_SECONDARY}
              isActive={!!(data.status === VERIFICATION_STATUSES.REJECTED)}
              className={styles.rejectButton}
              onClick={reject}
            />
          )}
        </div>
      </div>
      <MergeOptions
        data={data}
        brand={data.brand}
        brandDuplicates={brandDuplicates}
        isBrandLoading={isBrandLoading}
        isConflictsLoading={isConflictsLoading}
        brandData={brandData}
        getBrandData={getBrandData}
        reset={reset}
        merge={merge}
        businessTypesList={businessTypesList}
      />
    </div>
  );
};

VerificationDetails.propTypes = {
  data: PropTypes.object,
  brandDuplicates: PropTypes.object,
  brandData: PropTypes.object,
  getBrandData: PropTypes.func,
  approve: PropTypes.func,
  reject: PropTypes.func,
  merge: PropTypes.func,
  businessTypesList: PropTypes.array,
};

export default VerificationDetails;
