import React from "react";
import { BaseButton } from "prefab";
import { BUTTON_TYPE, SIZE } from "prefab";

const RemoveIcon = ({ onClick }) => (
  <BaseButton
    iconName="CancelIcon"
    buttonType={BUTTON_TYPE.SECONDARY}
    buttonSize={SIZE.SMALL}
    onClick={onClick}
  />
);

export default RemoveIcon;
