import React, { Component } from "react";
import { connect } from "react-redux";
import { flatten, compact, isEmpty } from "lodash";
import { createSelector } from "reselect";
import { getStatusDisplayName } from "../../../../utils/screens/statusLegend";
import { bindDispatch } from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import * as constants from "../../../../constants";
import TheatreList from "./TheatreList";

class UserTheatresList extends Component {
  getStatusList = () => {
    const {
      theatres: { theatres },
    } = this.props;
    const {
      SCREEN_STATUSES: { ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
    } = constants;
    return flatten(
      theatres.data?.map((theatre) => {
        if (theatre.screens.length === 0) return null;
        return compact(
          theatre.screens?.map((screen) => {
            if (isEmpty(screen?.activationStatuses)) return "";
            let screenStatus;
            if (screen.activationStatuses.length === 1) {
              [screenStatus] = screen.activationStatuses;
            } else if (screen.activationStatuses.length > 1) {
              [screenStatus] = screen.activationStatuses.filter((status) =>
                [ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(status)
              );
            }
            return screenStatus;
          })
        );
      })
    );
  };

  renderStatusIconLegend = () => {
    const {
      SCREEN_STATUSES: { ACTIVE, ACTIVE_IN_FUTURE, INACTIVE, INACTIVE_IN_FUTURE },
      STATUS_ICON_TYPES,
      STATUS_ICON_COLORS,
      TAG_TYPE,
    } = constants;
    const statusList = this.getStatusList();
    const statusLegends = [
      {
        id: ACTIVE,
        name: getStatusDisplayName(ACTIVE),
        count: statusList.filter((d) => d === ACTIVE).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.SCREEN_STATUS,
      },
      {
        id: INACTIVE,
        name: getStatusDisplayName(INACTIVE),
        count: statusList.filter((d) => d === INACTIVE).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.SCREEN_STATUS,
      },
      {
        id: INACTIVE_IN_FUTURE,
        name: getStatusDisplayName(INACTIVE_IN_FUTURE),
        count: statusList.filter((d) => d === INACTIVE_IN_FUTURE).length,
        type: STATUS_ICON_TYPES.DURATION,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.SCREEN_STATUS,
      },
      {
        id: ACTIVE_IN_FUTURE,
        name: getStatusDisplayName(ACTIVE_IN_FUTURE),
        count: statusList.filter((d) => d === ACTIVE_IN_FUTURE).length,
        type: STATUS_ICON_TYPES.DURATION,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.SCREEN_STATUS,
      },
    ];
    return statusLegends;
  };
  render = () => {
    return (
      <TheatreList
        {...this.props}
        statusList={this.renderStatusIconLegend()}
        selectedColumns={["Theatre / Screen", "Chain", "Location", "Playlist Template", "Rights"]}
      />
    );
  };
}

export const UserTheatresListWithFilters = WithFetchList("getUserTheatresList", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(UserTheatresList);

const mapStateToProps = createSelector(
  (state) => state.theatres,
  (state) => state.userData,
  (theatres, userData) => ({ theatres, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(UserTheatresListWithFilters);
