import moment from "moment";
import { BUTTON_TYPE } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FORM_FIELD_TYPE } from "../../../../constants";
import styles from "../../../../styles/Campaigns/PauseOrResumeActions.module.scss";
import Form from "../../../common/Form";
import RightPanel from "../../../common/RightPanel";

export default class MoveCampaigns extends Component {
  state = {
    fromDate: this.props.fromDate,
    hasError: false,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    moveCampaigns: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fromDate: null,
    selectionInfo: null,
  };

  componentWillUnmount = () => {
    this.closeAction();
  };

  closeAction = () => {
    this.setState({
      fromDate: null,
      hasError: false,
    });
    this.props.onClose();
  };

  save = (duration) => {
    // Moved fromDate
    const { fromDate } = this.state;
    //Validation to check if fromDate is entered
    if (!fromDate) {
      this.setState({ hasError: true });
      return;
    }
    // Moved toDate
    const toDate = this.addDaysToDate(fromDate, duration);
    this.props.moveCampaigns({ fromDate, toDate });
  };

  findDuration(startDate, endDate) {
    // Parse the dates using Moment.js
    const start = moment(startDate);
    const end = moment(endDate);
    // return the difference in days
    return end.diff(start, "days");
  }

  addDaysToDate(date, days) {
    // Parse the date using Moment.js
    const momentDate = moment(date);
    // Add the days to the date
    momentDate.add(days, "days");
    // Return the new date
    return momentDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
  }

  subtractDaysFromDate(date, days) {
    // Parse the date using Moment.js
    const momentDate = moment(date);
    // Subtract the days from the date
    momentDate.subtract(days, "days");
    // Return the new date
    return momentDate;
  }

  render = () => {
    const { isOpen, header, actionType, selected, item, parentValidity } = this.props;
    const { fromDate, hasError } = this.state;

    //!Date Range Validation
    //parentValidity: Tg Validity for media, Campaign Validity for Tg, undefined for campaign
    const parentStartDate = parentValidity ? parentValidity?.fromDate : undefined;
    const parentEndDate = parentValidity ? parentValidity?.toDate : undefined;

    const today = moment();
    //duration = endDate - startDate
    const duration = this.findDuration(selected[0]?.validity.fromDate, selected[0].validity.toDate);
    //date range: {from:max(parentStartDate,today) , to:(parentEndDate - duration)}
    const minDate = parentStartDate
      ? moment.max(moment(parentStartDate), today).toDate()
      : today.toDate();
    const maxDate = parentEndDate
      ? this.subtractDaysFromDate(parentEndDate, duration).toDate()
      : undefined;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.closeAction()}
        header={header}
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.move",
            buttonType: BUTTON_TYPE.PRIMARY,
            onClick: () => this.save(duration),
          },
        ]}
        secondaryButtonProps={{ text: "Button.close", onClick: this.closeAction }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.DATE,
              title: `${actionType} To`,
              invalidDateMessage: "Invalid To Date",
              onChange: (v) => {
                this.setState({ fromDate: moment(v).format("YYYY-MM-DDTHH:mm:ss[Z]") });
              },
              value: fromDate ? new Date(fromDate) : null,
              placeholder: "MM/DD/YYYY",
              error: hasError && !fromDate,
              errorMessage: "Enter Valid To Date",
              minDate: minDate,
              maxDate: maxDate,
              disabled: minDate > maxDate,
              defaultValue: minDate,
            },
          ]}
        />
        <div className={styles.campaignActionInfo}>
          <span>
            The {item.replace("_", " ")} start date will be readjusted to align with the selected
            date above.
          </span>
        </div>
      </RightPanel>
    );
  };
}
