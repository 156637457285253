/*
 PlacementTable Component
 
 This component renders a table for managing placements.
 It allows to select checkboxes for different days and time slots.
 */

import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { flattenDeep, values, keys, map, isEqual, isEmpty, groupBy } from "lodash";
import { Tooltip } from "@blueprintjs/core";
import SecondaryTable from "../../../common/SecondaryTable";
import Checkbox from "../../../common/Checkbox";
import styles from "../../../../styles/Schedules/placementTable.module.scss";
import { DAY_OF_WEEK } from "../../../../constants";

// css for checkboxes
const defaultIconProps = {
  width: "20px",
  height: "20px",
};

export default class PlacementTable extends Component {
  state = {
    placementTable: [], // Array to store placement data
    isAllSelected: false,
    weeks: [...DAY_OF_WEEK.slice(1), DAY_OF_WEEK[0]],
    timesOfDay: this.props.timesOfDay
      .filter((t) => t.isDefault)
      .sort((a, b) => moment(a.startTime, "HH:mm:ss") - moment(b.startTime, "HH:mm:ss")),
  };

  componentDidMount = () => {
    const { plays } = this.props;
    const { timesOfDay, weeks } = this.state;

    // Group plays by day of the week
    const groupedPlays = groupBy(plays, "dayOfWeek");

    // Update weeks with isRowSelected
    const updatedWeeks = weeks.map((week) => ({
      ...week,
      isRowSelected: groupedPlays[week.value]?.length === timesOfDay.length,
    }));

    // Generate placement data and find if all are selected
    const placementData = this.generatePlayData();
    const allPlays = placementData.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );

    this.setState({
      placementTable: placementData,
      weeks: updatedWeeks,
      isAllSelected: !flattenDeep(allPlays).includes(false),
    });
  };

  componentDidUpdate = (prevProps) => {
    const { plays } = this.props;

    // Generate placement data and find if all are selected when props change
    const placementData = this.generatePlayData();
    const allPlays = placementData.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );

    // Update state with the new plays data
    if (plays.length > 0 && !isEqual(plays, prevProps?.plays)) {
      this.setState({
        placementTable: this.generatePlayData(plays),
        isAllSelected: !flattenDeep(allPlays).includes(false),
      });
    }
  };

  // Generate placement data for the table based on prop (plays) used when component is mounting or updating
  // this function is used to fill the initialState of placementTable
  generatePlayData = (modifiedPlays = []) => {
    const { plays } = this.props;
    const { weeks, timesOfDay } = this.state;
    const playData = modifiedPlays.length > 0 ? modifiedPlays : plays;

    // creates an array of object, each object has timeofday with corr. plays for each day of week
    return timesOfDay.map(({ id }) => {
      const currentPlay = playData?.filter((p) => p.timeOfDayId === id) ?? [];
      const weekPlays = map(weeks, ({ value }) => {
        if (!!currentPlay.find(({ dayOfWeek }) => dayOfWeek === value)) return { [value]: true };
        return { [value]: false };
      });
      return {
        plays: weekPlays,
        timeOfDayId: id,
      };
    });
  };

  // Filter the placement data to get selected plays (used when input checkbox is change)
  // It is used to update the state in the parent component using onInputChange method
  filterPlacementData = (placementsData) => {
    const { numberOfPlays } = this.props;
    const filteredData = [];
    map(placementsData, (item) => {
      const dayList = item.plays?.filter((day) => !values(day).includes(false));
      map(dayList, (day) => {
        filteredData.push({
          timeOfDayId: item.timeOfDayId,
          dayOfWeek: parseInt(keys(day)[0]),
          numberOfPlays: parseInt(numberOfPlays),
        });
      });
    });
    return filteredData;
  };

  // Function to check if checkboxes clicked have overlapping timings
  checkTimeOfDayOverlap = () => {
    const { placementTable, timesOfDay, weeks } = this.state;

    // array to track checked timesOfDay and their corresponding days
    const checkedTimeOfDay = [];

    // Loop through placementTable to find checked timesOfDay and their corresponding days
    placementTable.forEach((placement) => {
      const checkedDays = [];

      // Check if the checkbox for the day is checked (value === true)
      placement.plays.forEach((day) => {
        if (Object.values(day)[0] === true) {
          const [dayOfWeek] = Object.keys(day); // Destructure the day of the week
          checkedDays.push(weeks.find((week) => week.value === parseInt(dayOfWeek)).name);
        }
      });

      // If any days were checked, add the corresponding timeOfDay to the list
      if (checkedDays.length > 0) {
        const timeOfDay = timesOfDay.find((time) => time.id === placement.timeOfDayId);
        checkedTimeOfDay.push({
          startTime: timeOfDay.startTime,
          endTime: timeOfDay.endTime,
          days: checkedDays,
        });
      }
    });

    // Check for overlapping times on the same day
    const isOverlap = checkedTimeOfDay.some((time1, index1) => {
      for (let index2 = index1 + 1; index2 < checkedTimeOfDay.length; index2++) {
        const time2 = checkedTimeOfDay[index2];

        // Check if they are selected on the same day
        if (
          time1.days.some((day) => time2.days.includes(day)) &&
          // Check if there is a time overlap between time1 and time2
          ((time1.startTime >= time2.startTime && time1.startTime < time2.endTime) ||
            (time2.startTime >= time1.startTime && time2.startTime < time1.endTime))
        ) {
          return true; // Overlapping times found
        }
      }
      return false;
    });

    return isOverlap;
  };

  // Modify a play based on the day and timeofday
  // this is called when a checkbox for an individual play is clicked.
  modifyPlay = (timeOfDayId, day) => {
    let { placementTable } = this.state;

    placementTable = map(placementTable, (item) => {
      if (item.timeOfDayId === timeOfDayId) {
        // Find the index of the play in the current time of day
        const currentIndex = item.plays?.findIndex((play) => keys(play).includes(day.toString()));

        // If play exists, toggle selection status
        if (currentIndex > -1) item.plays[currentIndex][day] = !item.plays[currentIndex][day];
      }
      return item;
    });

    // Filter plays data for the callback below
    const playsPerDay = this.filterPlacementData(placementTable);

    // Callback function provided as a prop to update the parent component
    this.props.onInputChange(playsPerDay, "placement", "plays");

    // Update the state with the modified placement table
    this.setState({
      placementTable,
    });
  };

  // Check if all rows of a time of day are selected
  isAllRowSelected = (timeOfDayId) => {
    const { placementTable } = this.state;
    const filteredPlay = placementTable.find((item) => item.timeOfDayId === timeOfDayId);
    return !filteredPlay?.plays?.find((item) => values(item).includes(false));
  };

  // Check if all checkboxes in a column are selected
  isAllColumnSelected = (day) => {
    const { placementTable } = this.state;
    const filteredPlay = placementTable.map(
      (item) => item.plays?.find((play) => keys(play)[0] === day.toString())[day]
    );
    return !filteredPlay.includes(false);
  };

  // Modify plays by row or column //!(bulk modifications)
  // Modifies plays data based on row (day) or column (time of day) selection.
  // Updates the state with the modified placement table and triggers a callback
  // function to update the parent component with the new plays data.
  //
  // Params:
  //   data: Represents the day or timeofday to be modified.
  //   type: Specifies whether the modification is by "day" or "timeOfDay."
  //   isSelectAll: Indicates whether all items in the row or column should be selected or deselected.
  modifyPlaysByRowOrColumn = (data, type, isSelectAll = false) => {
    let { placementTable, weeks } = this.state;

    const { isAllSelected } = this.state;

    const currentDayIndex = weeks.findIndex((item) => item.value === data);

    placementTable = map(placementTable, (item) => {
      if (isSelectAll) {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          return { [dayValue]: !isAllSelected };
        });
      } else if (!isSelectAll && type === "day") {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          if (dayValue === data.toString())
            return {
              [dayValue]: !weeks[currentDayIndex].isRowSelected,
            };
          return play;
        });
      } else if (item.timeOfDayId === data && type === "timeOfDay") {
        item.plays = item.plays?.map((play) => {
          const [dayValue] = keys(play);
          return { [dayValue]: !this.isAllRowSelected(item.timeOfDayId) };
        });
      }
      return item;
    });

    if (isSelectAll) {
      weeks = map(weeks, (week) => {
        return {
          ...week,
          isRowSelected: !isAllSelected,
        };
      });
    } else if (type === "day" && !isSelectAll) {
      weeks[currentDayIndex].isRowSelected = !weeks[currentDayIndex].isRowSelected;
    }

    const playsPerDay = this.filterPlacementData(placementTable);

    // Callback function provided as a prop to update the parent component
    this.props.onInputChange(playsPerDay, "placement", "plays");

    this.setState({
      placementTable,
      isAllSelected: !isAllSelected,
      weeks,
    });
  };

  // Render the header for a day or the entire week
  renderHeader = (day, dayValue, isSelectAll = false) => {
    const { placementTable, weeks } = this.state;
    const currentDayIndex = weeks.findIndex((item) => item.value === dayValue);
    const isAllSelected = flattenDeep(
      map(placementTable, (item) => values(item.plays).map((day) => values(day)))
    );
    const isColumnSelected =
      !isSelectAll && (this.isAllColumnSelected(dayValue) || weeks[currentDayIndex].isRowSelected);
    const isChecked = !isSelectAll ? isColumnSelected : !isAllSelected.includes(false);

    return (
      <div className={classNames(styles.header, isSelectAll && styles.selectAll)}>
        <Checkbox
          {...defaultIconProps}
          disabled={this.props.numberOfPlays < 1}
          className={classNames(styles.checkboxContainer, {
            [styles.checked]: isChecked,
            [styles.disabled]: this.props.numberOfPlays < 1,
          })}
          checked={isChecked}
          onChange={() => this.modifyPlaysByRowOrColumn(dayValue, "day", isSelectAll)}
        />
        <div className={styles.title}>{isSelectAll ? "Entire Week" : day}</div>
      </div>
    );
  };

  // play checkbox in a table cell
  renderPlay = (d, dayValue) => {
    const isChecked = !!d.plays.find((day) => day[dayValue]);
    return (
      <div className={styles.bodyColumn}>
        <Checkbox
          {...defaultIconProps}
          disabled={this.props.numberOfPlays < 1}
          className={classNames(styles.checkboxContainer, {
            [styles.checked]: isChecked,
            [styles.disabled]: this.props.numberOfPlays < 1,
          })}
          checked={isChecked}
          onChange={() => this.modifyPlay(d.timeOfDayId, dayValue, "day")}
        />
      </div>
    );
  };

  render() {
    const { isError, numberOfPlays } = this.props;
    const { timesOfDay, placementTable, weeks } = this.state;

    // Calculate the total number of plays based on the placement data
    const allPlays = placementTable.map((placement) =>
      placement?.plays?.map((play) => values(play))
    );
    const totalPlays = flattenDeep(allPlays).filter((play) => !!play).length;

    if (isEmpty(placementTable)) return null;

    // Check if there is an overlap in checked timesOfDay
    const isOverlap = this.checkTimeOfDayOverlap();

    return (
      <div className={styles.placementContainer}>
        {isError && <div className={styles.errors}>Please Select the Placements</div>}
        <SecondaryTable
          data={placementTable}
          rowHeaderClass={styles.headerContainer}
          bodyColumnClass={styles.columnContainer}
          rowHeader={styles.headerColumn}
          columns={[
            {
              id: "timeOfDay",
              Header: this.renderHeader(null, null, true), // header for timeOfDay
              accessor: (d) => {
                const timeOfDay = timesOfDay.find((day) => day.id === d.timeOfDayId);
                if (!timeOfDay) return null;

                // cell containing a checkbox for timeOfDay
                return (
                  <div className={styles.timeOfDayHeader}>
                    <Checkbox
                      {...defaultIconProps}
                      className={classNames(styles.checkboxContainer, {
                        [styles.checked]: this.isAllRowSelected(d.timeOfDayId),
                        [styles.disabled]: numberOfPlays < 1,
                      })}
                      disabled={numberOfPlays < 1}
                      checked={this.isAllRowSelected(d.timeOfDayId)}
                      onChange={() =>
                        this.modifyPlaysByRowOrColumn(d.timeOfDayId, "timeOfDay", false)
                      }
                    />
                    <Tooltip
                      content={`${moment(timeOfDay.startTime, "HH:mm:ss").format("LT")} - ${moment(
                        timeOfDay.endTime,
                        "HH:mm:ss"
                      ).format("LT")}`}
                    >
                      <div className={styles.timeOfDayContent}>
                        <div className={styles.placementsTimeName}>{timeOfDay.name}</div>
                      </div>
                    </Tooltip>
                  </div>
                );
              },
              width: 140,
            },
            // Generate columns for each day of the week
            ...weeks.map((dayOfWeek) => ({
              id: dayOfWeek.value, // Key for the column
              Header: this.renderHeader(dayOfWeek.label, dayOfWeek.value), // header for the day
              accessor: (d) => this.renderPlay(d, dayOfWeek.value),
              width: 80,
            })),
          ]}
        />

        {/* Display overlap message */}
        {isOverlap && (
          <div className={styles.errors}>
            Some selected slot timings for the day overlap with each other.
          </div>
        )}

        <div className={styles.totalPlays}>{`Total Number of Plays: ${
          totalPlays * numberOfPlays
        }`}</div>
      </div>
    );
  }
}
