import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import LocalizedButton from "../LocalizedButton";
import TooltipButton from "../TooltipButton";
import styles from "../../../styles/InfoBlock.module.scss";

export default class Header extends Component {
  static propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    isSubHeader: PropTypes.bool,
    rightSideButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.element]),
    rightSideIcons: PropTypes.array,
    rightSideContainerClass: PropTypes.string,
    headerClassName: PropTypes.string,
  };

  static defaultProps = {
    isSubHeader: false,
    header: "",
    rightSideButtons: [],
    rightSideIcons: [],
    hideHeader: false,
    headerClassName: "",
  };

  renderButton = () => {
    const { rightSideButtons, rightSideContainerClass } = this.props;
    if (!isArray(rightSideButtons)) return rightSideButtons;

    return rightSideButtons.map((btn, i) => {
      return (
        <div
          key={i}
          className={classNames("flex", {
            [rightSideContainerClass]: rightSideContainerClass,
          })}
        >
          <LocalizedButton {...btn} />
        </div>
      );
    });
  };

  renderIcons = () => {
    const { rightSideIcons } = this.props;
    return rightSideIcons.map(({ scopeAction, isHidden, ...rest }, i) => {
      if (isHidden) return null;
      return (
        <TooltipButton
          key={i}
          className={classNames("flex", styles.icon)}
          toolTipClass={styles.toolTip}
          {...rest}
        />
      );
    });
  };

  render() {
    const { header, isSubHeader, headerIcon, headerClassName } = this.props;
    const headerClass = classNames("flex-auto", styles.header, {
      [styles.subHeader]: isSubHeader,
    });
    return (
      <div className={classNames("flex col-12", styles.headerWrapper, headerClassName)}>
        <div className={headerClass}>
          {headerIcon && <div className={styles.headerIcon}>{headerIcon}</div>}
          <span className={styles.headerTitle}>{header}</span>
        </div>
        {this.renderButton()}
        {this.renderIcons()}
      </div>
    );
  }
}
