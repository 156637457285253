import React from "react";
import { Position, Toaster, Intent } from "@blueprintjs/core";
import { FormattedMessage, IntlProvider } from "react-intl";
import combineMessages from "../localization/combineMessages";
import { DEFAULT_TOAST_TIMEOUT } from "../constants";

const Message = (message) => (
  <IntlProvider
    locale={window.CURRENT_LANGUAGE}
    messages={combineMessages(window.CURRENT_LANGUAGE)}
  >
    <FormattedMessage id={message} defaultMessage={message} />
  </IntlProvider>
);

const showToastWithPosition = (
  message,
  isSuccess = true,
  timeout = DEFAULT_TOAST_TIMEOUT,
  position = Position.TOP_RIGHT
) => {
  const DefaultToast = Toaster.create({
    className: "toast",
    position,
  });
  DefaultToast.show({
    intent: isSuccess ? Intent.SUCCESS : Intent.DANGER,
    message: Message(message),
    timeout,
  });
};

export default showToastWithPosition;
