import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { CAMPAIGN_STATUSES } from "../../../../../constants";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import { bindDispatch, isSlateAdminCompanyUser } from "../../../../../utils";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import {
  getApprovalDetails,
  getBillingInfo,
  getCampaignBasicDetails,
  getContactDetails,
  getRejectionDetails,
  getRequestedChanges,
  getScrollableSummaryBlock,
} from "../../utils";
import CampaignActionDetails from "../../utils/CampaignActions";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";

class CampaignDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    showCampaignActions: PropTypes.bool,
    withCampaignTree: PropTypes.bool,
    showEstimates: PropTypes.bool,
  };

  static defaultProps = {
    showEstimates: false,
    showCampaignActions: false,
    withCampaignTree: false,
  };

  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    const {
      campaign: { id: campaignId },
      actions,
    } = this.props;
    await actions.getCampaignSummary(campaignId);
    // if (this.props.withCampaignTree) await this.fetchCampaignTreeData();
  };

  // fetchCampaignTreeData = () => {
  //   const { params } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  renderCampaignActionDetails = (campaign) => {
    const unapprovedStatuses = [
      CAMPAIGN_STATUSES.DRAFT,
      CAMPAIGN_STATUSES.PROPOSED,
      CAMPAIGN_STATUSES.PLANNED,
    ];

    if (campaign.status === CAMPAIGN_STATUSES.REJECTED) {
      return getRejectionDetails(campaign);
    } else if (!unapprovedStatuses.includes(campaign.status)) {
      return getApprovalDetails(campaign);
    } else {
      return getRequestedChanges(campaign.requestedChanges);
    }
  };

  renderCampaignPauseAction = (campaign) => {
    const statusChanges = campaign?.statusChanges.filter(
      (changes) => changes.status === campaign.status
    );

    return <CampaignActionDetails actionChanges={statusChanges} />;
  };

  render = () => {
    const {
      campaign,
      isLoading,
      showCampaignActions,
      showEstimates,
      userData,
      withCampaignTree,
    } = this.props;
    const isAdminApp = isSlateAdminCompanyUser(userData);

    const details = {
      summary: this.props.campaigns?.campaignSummary,
      maxPlaysPerScreen: this.props.campaigns?.campaignSummary.maxPlaysPerScreen,
    };
    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    return (
      <div className="col-12 clearfix flex">
        <div className={styles.details}>
          {showEstimates && isEmpty(details.summary) ? (
            <InfoBlockLoading />
          ) : (
            getScrollableSummaryBlock(
              {
                maxPlaysPerScreen: details.maxPlaysPerScreen,
                validity: details.summary.validity,
                ...details.summary.estimated,
              },
              {
                xs: 12,
                sm: 4,
                md: 3,
                lg: 3,
              }
            )
          )}
          {getCampaignBasicDetails(campaign, isAdminApp)}
          {campaign?.contacts?.length > 0 && getContactDetails(campaign)}
          {/*SLATE-1374 Nov15-2023 Anushree:- billingCycle replaced by billingInfo */}
          {/*getBillingInfo(campaign.billingCycle)*/}
          {getBillingInfo(campaign.billingInfo)}
          {/* {campaign.status === CAMPAIGN_STATUSES.PAUSED && (
            <CampaignActionDetails actionList={campaign.statusChanges} status={campaign.status} />
          )} */}
          {/*SLATE-1382 Nov15-2023 Anushree:- Campaign Action Details only shown when statusChanges info present in campaigns data */}
          {campaign.status === CAMPAIGN_STATUSES.PAUSED && campaign.statusChanges && (
            <CampaignActionDetails actionList={campaign.statusChanges} status={campaign.status} />
          )}
          {showCampaignActions && this.renderCampaignActionDetails(campaign)}
        </div>
        {withCampaignTree && (
          <div className={styles.campaignTree}>
            <CampaignTree
            // isLoading={isCampaignStructureLoading}
            // campaignTreeData={campaignStructure}
            />
          </div>
        )}
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.campaigns,
  (state) => state.userData,
  (state) => state.timesOfDay,
  (campaignCreate, campaigns, userData, timesOfDay) => ({
    campaignCreate,
    userData: userData.user,
    timesOfDay: timesOfDay,
    campaigns,
  })
);

export default connect(mapStateToProps, bindDispatch)(CampaignDetails);
