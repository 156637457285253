import React, { Component } from "react";
import EmptyPage from "../common/EmptyPage";

class NotFound extends Component {
  static defaultProps = {
    heading: "EmptyState.notFoundHeading",
    bodyMessage: "EmptyState.notFoundMessage",
    isHomeLinkVisible: true,
  };
  render() {
    const { heading, bodyMessage, isHomeLinkVisible } = this.props;

    return <EmptyPage heading={heading} body={bodyMessage} isHomeLinkVisible={isHomeLinkVisible} />;
  }
}

export default NotFound;
