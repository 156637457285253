import * as types from "../types/countries";

const initialState = {
  isLoading: false,
  isError: null,
  certifications: [],
  certification: {},
  country: {},
  taxes: [],
  tax: {},
  list: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.DATA:
      return { ...state, list: payload };
    case types.CERTIFICATIONS:
      return { ...state, certifications: payload };
    case types.CERTIFICATION:
      return { ...state, certification: payload };
    case types.COUNTRY:
      return { ...state, country: payload };
    case types.TAXES:
      return { ...state, taxes: payload };
    case types.TAX:
      return { ...state, tax: payload };
    case types.ACTIVE_COUNTRY:
      return { ...state, activeCountriesCount: payload };
    default:
      return state;
  }
}
