import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { ExpandTable } from "workbench";
import * as constants from "../../../../../constants";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import { bindDispatch, locationColumn, modifyTableColumns } from "../../../../../utils";
import ActionIcon from "../../../../common/ActionIcon";
import { ActiveTableCell } from "../../../../common/ActiveTableCell";
import FilterChips from "../../../../common/Filters/FilterChips";
import FooterControls from "../../../../common/FooterControls";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import TableControls from "../../../../common/TableControls";
import TableLoading from "../../../../common/TableLoading";
import WithFetchList from "../../../../hoc/withFetchList";
import AvailabilityDetails from "../../actions/AvailabilityDetails";
import { THEATRE_CRITERIA_TYPES } from "../../../../../constants";

class TargetGroupTheatres extends Component {
  state = {
    selectedColumns: [
      "Theatre • Screen",
      "Location",
      "Media",
      "Content Name",
      "Est. Plays",
      "Days Unavailable",
      "Spots Unavailable",
      "Theatre",
    ],
    selection: [],
    isActionDetailOpen: false,
  };

  componentDidMount = () => {
    //this.props.fetchData();
    this.props.fetchData(null, false, [
      {
        validity: this.props.campaignCreate.campaign.validity,
        criteria: this.props.criteria,
      },
    ]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  excludeTheatre = (obj) => {
    const { id, name, screens } = obj;
    const exclusion = {
      id,
      name,
      tags: [id],
      type: !!screens ? THEATRE_CRITERIA_TYPES.THEATRE_BY_ID : THEATRE_CRITERIA_TYPES.SCREEN_BY_ID,
      targetGroupType: "theatre",
    };
    this.props.onExclusion(exclusion);
  };

  handleActionDetailsPanel = (rowData) => {
    this.setState({ isActionDetailOpen: true, selectedRowData: rowData });
  };

  closeActionDetails = () => {
    this.setState({ isActionDetailOpen: false });
  };

  /**
   * Removes exclusions from the provided data based on the criteria's exclusions.
   * @param {Array} data - The data to be filtered.
   * @returns {Array} - The filtered data without exclusions.
   */
  removeExclusionsFromApiData = (data) => {
    const { exclusions } = this.props.criteria;
    if (!exclusions || exclusions.length === 0) return data;
    const theatreFilteredData = data?.filter(
      (d) => !exclusions.some((e) => e.tags.includes(d.id) || e.tags.includes(d.theatreId))
    );
    if (!theatreFilteredData) return [];
    const filteredData = theatreFilteredData.map((d) => {
      d.screens = d.screens?.filter((s) => !exclusions.some((e) => e.id === s.id));
      if (!d.id || !d.mediaId) return d;
      d.screenId = d.id;
      return d;
    });
    return filteredData;
  };
  render = () => {
    const {
      campaignCreate: { criteriaTheatres, isLoading, isPartialLoading },
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    let { columns } = this.props;
    const _columns = [
      {
        id: "name",
        Header: "Theatre • Screen",
        width: 250,
        accessor: (d, filter = this.props.onFilterIdSelect) => (
          <>
            {ActiveTableCell(d.theatreId, d.theatreName, () => filter(d.theatreId))}•{" "}
            {ActiveTableCell(d.id, d.name, () => filter(d.id))}
          </>
        ),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "mediaId",
        Header: "Media",
        accessor: "mediaName",
      },
      {
        id: "contentName",
        Header: "Content Name",
        accessor: "contentName",
      },
      {
        id: "priority",
        Header: "Priority",
        accessor: "priority",
      },
      {
        id: "estimatedPlays",
        Header: "Est. Plays",
        accessor: "estimatedPlay",
      },
      // {
      //   id: "daysUnavailable",
      //   Header: "Days Unavailable",
      //   accessor: (d) => (d.daysUnavailable ? d.daysUnavailable.length : ""),
      // },
      {
        id: "spotsUnavailable",
        Header: "Spots Unavailable",
        accessor: "spotsUnavailable",
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        scope: constants.SCOPES.CAMPAIGNS,
        scopeAction: constants.SCOPE_ACTIONS.READ,
        Cell: (d) => (
          <ActionIcon
            iconProps={[
              {
                id: "info",
                toolTip: "Tooltip.availability",
                iconName: "InfoFilledIcon",
                toolTipClass: styles.infoIconToolTip,
                onClick: () => this.handleActionDetailsPanel(d.original),
              },
              {
                toolTip: "Tooltip.exclude",
                iconName: "RemoveFilledIcon",
                onClick: (e) => {
                  if (d.original.status !== "Active") {
                    this.excludeTheatre(d.original);
                  } else {
                    e.stopPropagation();
                  }
                },
                className: d.original.status === "Active" ? styles.disabledBtn : "",
              },
            ]}
          />
        ),
      },
    ];

    const { selectedColumns, reorderedColumns, selection, isActionDetailOpen } = this.state;

    if (isLoading) return <PageLoadingWithTable />;
    const resultsCount = criteriaTheatres.totalCount || 0;
    if (!columns) columns = _columns;
    return (
      <div className="col-12 clearfix">
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={criteriaTheatres}
          ps={ps}
          page={page}
          tagTypes={[constants.TAG_TYPE.THEATRE, constants.TAG_TYPE.LOCATION]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />
        <ExpandTable
          data={this.removeExclusionsFromApiData(criteriaTheatres.data)}
          loading={isPartialLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          childIdentificationKey="screens"
          selection={selection}
          onSelect={(selection) => this.setState({ selection })}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
        />
        <FooterControls
          pagination
          data={criteriaTheatres}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
        {this.state.isActionDetailOpen && (
          <AvailabilityDetails
            isOpen={isActionDetailOpen}
            header="RightPanelHeader.availability"
            selectedRowData={this.state.selectedRowData}
            onClose={this.closeActionDetails}
          />
        )}
      </div>
    );
  };
}

// eslint-disable-next-line import/group-exports
export const TargetGroupTheatresWithFilters = WithFetchList("getTargetGroupTheatresByCriteria", {
  sort: [{ id: "name", desc: false }],
})(TargetGroupTheatres);

const TargetGroupTheatresWithFilterByTargetGropuId = WithFetchList(
  "getTargetGroupTheatresByTargetGroupId",
  {
    sort: [{ id: "name", desc: false }],
  },
  "targetGroupId"
)(TargetGroupTheatres);

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (userData, campaignCreate) => ({
    userData: userData.user,
    campaignCreate: campaignCreate,
  })
);

const TargetGroupTheatresWithTargetGroupId = connect(
  mapStateToProps,
  bindDispatch
)(withRouter(TargetGroupTheatresWithFilterByTargetGropuId));
export default connect(mapStateToProps, bindDispatch)(withRouter(TargetGroupTheatresWithFilters));
// eslint-disable-next-line import/group-exports
export { TargetGroupTheatresWithTargetGroupId };
