import {
  searchNotificationTemplates,
  saveNotificationTemplate,
  getNotificationTemplate,
} from "../../api";
import * as types from "../types/notificationTemplate";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const updateNotificationTemplate = (payload) => {
  return { type: types.DATA, payload: payload };
};

const handleError = (payload) => {
  return { type: types.IS_ERROR, payload };
};

const handleLoading = (payload) => {
  return { type: types.IS_LOADING, payload };
};

const handlePartialLoading = (payload) => {
  return { type: types.IS_PARTIAL_LOADING, payload };
};

const setTemplate = (payload) => {
  return { type: types.NOTIFICATION, payload };
};

const editTemplate = (payload) => ({ type: types.EDIT, payload });

const fetchNotificationTemplate = (templateId, getTemplate = getNotificationTemplate) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await getTemplate(templateId);
      dispatch(setTemplate(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const editNotificationTemplate = (notificationTemplate) => (dispatch) =>
  dispatch(editTemplate({ notificationTemplate, isOpen: true }));

const addNotificationTemplate = () => (dispatch) =>
  dispatch(
    editTemplate({
      isOpen: true,
      notificationTemplate: { code: "", body: "", subject: "" },
    })
  );

const cancelEditNotificationTemplate = () => (dispatch) =>
  dispatch(editTemplate({ isOpen: false, notificationTemplate: {} }));

const editNotificationTemplateById = (notificationTemplateId, get = getNotificationTemplate) => {
  return async (dispatch) => {
    try {
      const { data } = await get(notificationTemplateId);
      dispatch(editNotificationTemplate(data));
    } catch (error) {
      dispatch(editTemplate({ type: types.IS_ERROR, payload: error.message }));
    }
  };
};

const getNotificationTemplates = (
  params = {},
  filters = [],
  fetchNotificationTemplates = searchNotificationTemplates,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetchNotificationTemplates(ps, (page - 1) * ps, sort, filters);
      dispatch(updateNotificationTemplate(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};
const createOrUpdateNotificationTemplate = (
  notificationTemplate,
  params,
  req,
  save = saveNotificationTemplate,
  getNotificationTemp = getNotificationTemplates
) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      await save(notificationTemplate);
      showToast(
        notificationTemplate.id
          ? "Toast.notificationTemplateUpdated"
          : "Toast.notificationTemplateAdded"
      );
      await dispatch(getNotificationTemp(params, req));
      await dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      showToast(error.message, false);
      await dispatch(getNotificationTemp(params, req));
      dispatch(handleError(error));
    }
  };
};
export {
  updateNotificationTemplate,
  getNotificationTemplates,
  fetchNotificationTemplate,
  createOrUpdateNotificationTemplate,
  addNotificationTemplate,
  editNotificationTemplate,
  editNotificationTemplateById,
  cancelEditNotificationTemplate,
};
