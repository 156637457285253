import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import _ from "lodash";
import { ICON_POS } from "prefab";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import * as constants from "../../../../constants";
import { bindDispatch, isSlateAdminCompanyUser, checkScopeAuth } from "../../../../utils";
import HeaderButton from "../../../common/HeaderButtons";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import TabView from "../../../common/Tabs";
import LocalizedButton from "../../../common/LocalizedButton";
import { QUBE_WIRE_CINEMAS_BASE_URL } from "../../../../config";
import ButtonStyles from "../../../../styles/Button.module.scss";
import screenDetails from "./tabs/screen/details";
import ScreenRights from "./tabs/screen/rights";
// import ScreenRateCards from "./tabs/screen/rateCards.js";
// import ScreenPlaylist from "./tabs/screen/playlistTemplates";
import AdminSettings from "./tabs/screen/settings/AdminSettings";
import UserSettings from "./tabs/screen/settings/UserSettings";
import ScreenSpotStatus from "./tabs/screen/ScreenSpotStatus";

const _LINKS = [{ name: "PageHeader.screens", path: "/screens" }];
export class ScreenView extends Component {
  state = {};

  componentDidMount = async () => {
    await this.getScreen();
  };

  componentWillUnmount = () => {
    this.props.actions.resetScreenData();
  };

  getScreen = () => {
    const {
      history,
      match: { params },
      actions,
      userData,
    } = this.props;
    const hasReadAccess = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.READ
    );
    if (params?.tabId === "settings" && !hasReadAccess) {
      history.replace(`/screens/${params.screenId}/details`);
    }
    actions.getScreen(params);
    actions.getScreenRightsCount(params);
  };

  render = () => {
    const {
      history,
      screens: { screen, screenRights },
      match: { params },
      userData,
    } = this.props;
    const isLoading = _.isEmpty(screen);

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[
              ..._LINKS,
              { name: `${screen.name} · ${screen.theatre.name} · ${screen.chain.name}` },
            ]}
          />
        </div>
        <PageHeader
          name={`${screen.name} · ${screen.theatre.name} · ${screen.chain.name}`}
          isLoading={isLoading}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                scope={constants.SCOPES.INVENTORY}
                scopeAction={constants.SCOPE_ACTIONS.WRITE}
                className={ButtonStyles.buttonSpacing}
                userData={userData}
                text="Button.edit"
                iconName="ExportIcon"
                iconPos={ICON_POS.RIGHT}
                onClick={() => {
                  window.open(
                    `${QUBE_WIRE_CINEMAS_BASE_URL}theatres/${screen.theatre.id}/edit`,
                    "_blank"
                  );
                }}
              />

              <HeaderButton
                userData={userData}
                logsButton
                history={history}
                logsPath={`/logs/ref/${screen.id}`}
              />
            </>
          )}
        />
        <div>
          <TabView
            scopeCheck
            id="navbar"
            selectedTabId={params.tabId}
            tabs={[
              {
                id: "details",
                title: "Details",
                goto: `/screens/${screen.id}/details`,
                panel: screenDetails(screen),
                scope: constants.SCOPES.INVENTORY,
                scopeAction: constants.SCOPE_ACTIONS.READ,
                userData: userData,
              },
              {
                id: "settings",
                title: "Settings",
                userData: userData,
                scope: constants.SCOPES.INVENTORY,
                scopeAction: constants.SCOPE_ACTIONS.READ,
                goto: `/screens/${screen.id}/settings`,
                panel: isSlateAdminCompanyUser(userData) ? <AdminSettings /> : <UserSettings />,
              },
              // {
              //   id: "playlistTemplates",
              //   title: "Playlist Templates",
              //   count: 1,
              //   goto: `/screens/${screen.id}/playlistTemplates`,
              //   panel: <ScreenPlaylist />,
              //   scope: constants.SCOPES.INVENTORY,
              //   scopeAction: constants.SCOPE_ACTIONS.READ,
              //   userData: userData,
              // },
              {
                id: "rights",
                title: "Rights",
                count: screenRights?.totalCount ?? 0,
                goto: `/screens/${screen.id}/rights`,
                panel: <ScreenRights />,
                scope: constants.SCOPES.INVENTORY,
                scopeAction: constants.SCOPE_ACTIONS.READ,
                userData: userData,
              },
              // {
              //   id: "rate_cards",
              //   title: "Rate Cards",
              //   //TODO: Fetch and show actual count, once the api is available
              //   count: 3,
              //   goto: `/screens/${screen.id}/rate_cards`,
              //   panel: <ScreenRateCards />,
              //   scope: constants.SCOPES.INVENTORY,
              //   scopeAction: constants.SCOPE_ACTIONS.READ,
              //   userData: userData,
              // },
              {
                id: "spot_status",
                title: "Spot Status",
                goto: `/screens/${screen.id}/spot_status`,
                panel: <ScreenSpotStatus />,
                scope: constants.SCOPES.INVENTORY,
                scopeAction: constants.SCOPE_ACTIONS.READ,
                userData: userData,
              },
            ]}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({ screens, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ScreenView);
