const IS_LOADING = "inventory/campaigns/IS_LOADING";
const IS_PARTIAL_LOADING = "inventory/theatres/IS_PARTIAL_LOADING";
const IS_ERROR = "inventory/campaigns/IS_ERROR";
const IS_CAMPAIGN_LOADING = "inventory/campaigns/IS_CAMPAIGN_LOADING";
const IS_TARGET_GROUP_LOADING = "inventory/campaigns/IS_TARGET_GROUP_LOADING";
const IS_MEDIA_LOADING = "inventory/campaigns/IS_MEDIA_LOADING";
const NEW_CAMPAIGN_TARGET_GROUP = "inventory/campaigns/NEW_CAMPAIGN_TARGET_GROUP";
const NEW_CAMPAIGN_MEDIA = "inventory/campaigns/NEW_CAMPAIGN_MEDIA";
const NEW_CAMPAIGN_MEDIA_LIST = "inventory/campaigns/NEW_CAMPAIGN_MEDIA_LIST";
const NEW_CAMPAIGN_TARGET_GROUPS = "inventory/campaigns/NEW_CAMPAIGN_TARGET_GROUPS";
const NEW_CAMPAIGN_DETAILS = "inventory/campaigns/NEW_CAMPAIGN_DETAILS";
const CRITERIA_THEATRES = "inventory/campaigns/CRITERIA_THEATRES";
const CRITERIA_SCREENS = "inventory/campaigns/CRITERIA_SCREENS";
const TAB_LOADING = "inventory/campaigns/TAB_LOADING";
const CAMPAIGN_STEPS = "inventory/campaigns/CAMPAIGN_STEPS";
const TARGET_GROUP_ACTION = "inventory/campaigns/TARGET_GROUP_ACTION";
const MEDIA_ACTION = "inventory/campaigns/MEDIA_ACTION";
const IS_MEDIA_DETAILS_LOADING = "inventory/campaigns/IS_MEDIA_DETAILS_LOADING";
const IS_TARGET_GROUP_DETAILS_LOADING = "inventory/campaigns/IS_TARGET_GROUP_DETAILS_LOADING";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  IS_TARGET_GROUP_LOADING,
  NEW_CAMPAIGN_TARGET_GROUP,
  NEW_CAMPAIGN_TARGET_GROUPS,
  CRITERIA_THEATRES,
  CRITERIA_SCREENS,
  IS_MEDIA_LOADING,
  NEW_CAMPAIGN_MEDIA,
  NEW_CAMPAIGN_MEDIA_LIST,
  TAB_LOADING,
  NEW_CAMPAIGN_DETAILS,
  IS_CAMPAIGN_LOADING,
  CAMPAIGN_STEPS,
  TARGET_GROUP_ACTION,
  MEDIA_ACTION,
  IS_MEDIA_DETAILS_LOADING,
  IS_TARGET_GROUP_DETAILS_LOADING,
};
