import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch, checkScopes } from "../../../../utils";
import { SCOPES, SCOPE_ACTIONS } from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import TabView from "../../../common/Tabs";
import ContentInfo from "./ContentInfo";

const _LINKS = [{ name: "PageHeader.content", path: "/content" }];

class ContentDetails extends Component {
  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    actions.getContentById(params);
  };
  render() {
    const {
      actions,
      history,
      match: { params },
      content: {
        contentDetails,
        editContent,
        languages,
        isContentLoading,
        isCreateContentLoading,
        mapBrand,
      },
      userData,
    } = this.props;
    const selectedTabId = params.viewtabId;
    if (isContentLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }
    return (
      <div>
        <Breadcrumb
          isLoading={isContentLoading}
          history={history}
          links={[..._LINKS, { name: contentDetails.name }]}
        />
        <PageHeader name="PageHeader.content" isLoading={isContentLoading} />
        <TabView
          id="navbar"
          selectedTabId={selectedTabId}
          tabs={checkScopes(
            [
              {
                id: "details",
                title: "Details",
                goto: `/content/${params.id}/details`,
                scope: SCOPES.CONTENT,
                scopeAction: SCOPE_ACTIONS.READ,
                panel: (
                  <ContentInfo
                    isLoading={isContentLoading}
                    isCreateContentLoading={isCreateContentLoading}
                    content={contentDetails}
                    editContent={editContent}
                    mapBrand={mapBrand}
                    languages={languages}
                    params={params}
                    actions={actions}
                    history={history}
                    userData={userData}
                  />
                ),
              },
            ],
            userData
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.content,
  (state) => state.userData,
  (content, userData) => ({ content, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(ContentDetails));
