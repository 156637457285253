import React, { Component } from "react";
import { BUTTON_TYPE } from "prefab";
import EmptyState from "../common/EmptyState";
import styles from "../../styles/EmptyState.module.scss";
import { EmptyState as EmptyStateIllustration } from "../../assets";
import LocalizedButton from "../common/LocalizedButton";

class EmptyPage extends Component {
  render() {
    return (
      <>
        <div className={styles.imageAlign}>
          <EmptyStateIllustration />
        </div>
        <EmptyState heading={this.props.heading} body={this.props.body} />
        {this.props.isHomeLinkVisible && (
          <LocalizedButton
            url="/dashboard"
            buttonType={BUTTON_TYPE.PRIMARY}
            className={`flex ${styles.emptyStateButton}`}
            text="Button.gohome"
          />
        )}
      </>
    );
  }
}

export default EmptyPage;
