import { isEmpty, compact } from "lodash";
import { getUserTimeZone } from "..";

const modifyTableColumns = (columns, selectedColumns, reorderedColumns) => {
  if (isEmpty(reorderedColumns)) reorderedColumns = compact(columns.map((c) => c.Header));
  let filteredColumns = [];
  reorderedColumns.forEach((c) => {
    if (!selectedColumns.includes(c)) return;
    filteredColumns.push(columns.find((col) => col.Header === c));
  });
  filteredColumns = [...filteredColumns, ...columns.filter((c) => c.hideIfOnlyColumn)];
  const timeZone = getUserTimeZone().abbr;

  return filteredColumns.map((c) => {
    if (c.showTimeZone) {
      return { ...c, Header: `${c.Header} (${timeZone})` };
    } else return c;
  });
};

export default modifyTableColumns;
