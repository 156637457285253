import * as types from "../types/companies";

const initialState = {
  isLoading: false,
  isAdvertisersLoading: false,
  isCompanySettingLoading: false,
  isMediaAgenciesLoading: false,
  isExhibitorsLoading: false,
  isAdvertisersSettingsLoading: true,
  isError: null,
  companies: {
    advertisersCount: 0,
    exhibitorsCount: 0,
    mediaAgenciesCount: 0,
  },
  advertisers: {
    data: [],
  },
  addorEditAdvertiser: {
    isOpen: false,
    advertiser: {},
  },
  mediaAgencies: {
    data: [],
  },
  exhibitors: {
    data: [],
  },
  mediaAgency: {},
  exhibitor: {},
  advertiser: {},
  advertiserSettings: {},
  thirdpartyIdentifiers: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.COMPANIES:
      return { ...state, companies: payload };
    case types.IS_ADVERTISER_LOADING:
      return { ...state, isAdvertisersLoading: payload };
    case types.THEIRD_PARTY_IDENTIFIERS:
      return { ...state, thirdpartyIdentifiers: payload };
    case types.ADVERTISERS:
      return { ...state, advertisers: payload };
    case types.IS_ADVERTISER_SETTINGS_LOADING:
      return { ...state, isAdvertisersSettingsLoading: payload };
    case types.ADVERTISER:
      return { ...state, advertiser: payload };
    case types.ADVERTISER_SETTINGS:
      return { ...state, advertiserSettings: payload };
    case types.ADD_OR_EDIT_ADVERTISER:
      return { ...state, addorEditAdvertiser: payload };
    case types.IS_MEDIA_AGENCIES_LOADING:
      return { ...state, isMediaAgenciesLoading: payload };
    case types.MEDIA_AGENCIES:
      return { ...state, mediaAgencies: payload };
    case types.MEDIA_AGENCY:
      return { ...state, mediaAgency: payload };
    case types.IS_EXHIBITORS_LOADING:
      return { ...state, isExhibitorsLoading: payload };
    case types.EXHIBITORS:
      return { ...state, exhibitors: payload };
    case types.IS_COMPANY_SETTING_LOADING:
      return { ...state, isAdvertisersLoading: payload };
    case types.EXHIBITOR:
      return { ...state, exhibitor: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    default:
      return state;
  }
}
