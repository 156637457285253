import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import HeaderButton from "../../../common/HeaderButtons";
import FooterControls from "../../../common/FooterControls";
import ActionIcon from "../../../common/ActionIcon";
import EditAdvertiserType from "./EditAdvertiserType";

const _LINKS = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.advertiserTypes" },
];

export class AdvertiserTypes extends Component {
  state = {
    selectedColumns: ["Name", "Order", "Last Updated By", "Last Updated On"],
  };

  componentDidMount = () => {
    const {
      match: { params },
      actions,
      fetchData,
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addAdvertiserType();
      }
      actions.editAdvertiserTypeById(params.action);
    }
    fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (advertiserType) => {
    const { actions } = this.props;
    actions.addOrUpdateAdvertiserType(advertiserType);
  };

  addAdvertiserType = () => {
    const { actions, history } = this.props;
    actions.addAdvertiserType();
    history.replace(`/lists/advertiser-types/new`);
  };

  cancelEdit = () => {
    const { actions, history } = this.props;
    actions.cancelEditAdvertiserType();
    history.replace(`/lists/advertiser-types`);
  };

  editAdvertiserType = (advertiserType) => {
    const { actions, history } = this.props;
    actions.editAdvertiserTypeById(advertiserType.id);
    history.replace(`/lists/advertiser-types/${advertiserType.id}`);
  };

  handlePageSort = () => {
    if (this.props.onSortedChange) {
      this.props.onSortedChange();
    }
  };

  render = () => {
    const {
      advertiserTypes: { advertiserList, isLoading, advertiserTypeEdit },
      history,
      userData,
      onSortedChange,
      sort,
    } = this.props;
    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} />;
    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, advertiserType } = advertiserTypeEdit;
    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Name",
          accessor: "name",
        },
        {
          id: "rank",
          Header: "Order",
          accessor: "rank",
        },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.ADVERTISER_TYPES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editAdvertiserType(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.ADVERTISER_TYPES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addAdvertiserType,
      userData,
    };

    return (
      <div className={classNames("col-12 clearfix")}>
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          name="PageHeader.advertiserTypes"
          count={advertiserList?.data?.length}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              pageSize={advertiserList.data ? advertiserList.data.length : 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              onSortedChange={onSortedChange}
              data={advertiserList?.data ?? []}
              sorted={sort}
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        {isOpen && (
          <EditAdvertiserType
            isOpen={isOpen}
            advertiserType={advertiserType}
            onClose={this.cancelEdit}
            onSave={this.save}
          />
        )}
      </div>
    );
  };
}

const AdvertiserTypesList = WithFetchList("getAdvertiserTypes", {
  sort: [
    {
      id: "rank",
      desc: false,
    },
  ],
})(AdvertiserTypes);

const mapStateToProps = createSelector(
  (state) => state.advertiserTypes,
  (state) => state.userData,
  (advertiserTypes, userData) => ({ advertiserTypes, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(AdvertiserTypesList);
