import { fetchPlaylistPackTypes } from "../../api";
import * as types from "../types/playlistPackTypes";

const updatePlaylistPackTypes = (payload) => {
  return { type: types.LIST, payload: payload };
};
const handleError = (payload) => {
  return { type: types.IS_ERROR, payload: payload };
};

const handleBusy = (payload) => {
  return { type: types.IS_LOADING, payload: payload };
};
const getPlaylistPackTypes = (get = fetchPlaylistPackTypes) => {
  return async (dispatch) => {
    dispatch(handleBusy(true));
    try {
      const { data } = await get();
      dispatch(updatePlaylistPackTypes(data));
      dispatch(handleBusy(false));
    } catch (error) {
      dispatch(handleBusy(false));
      dispatch(handleError(error));
    }
  };
};

export { getPlaylistPackTypes };
