import { INITIALIZE, NAV_COUNT, DIMENSIONS } from "../types/app";
import { getNavCount } from "../../api";

const testAction = (payload) => {
  return {
    type: INITIALIZE,
    payload: payload,
  };
};
const actionCreator = (type, payload) => {
  return { type, payload };
};
const updateNavCount = (payload) => {
  return { type: NAV_COUNT, payload: payload };
};

const updatePageDimensions = (payload) => {
  return { type: DIMENSIONS, payload: payload };
};

const getNavCounts = (fetchNavCount = getNavCount) => {
  return async (dispatch) => {
    try {
      const { data } = await fetchNavCount();
      dispatch(updateNavCount(data));
    } catch (error) {
      return error;
    }
  };
};
export { updateNavCount, updatePageDimensions, testAction, getNavCounts, actionCreator };
