import React, { Component } from "react";
import classNames from "classnames";
import { BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { merge, isEmpty, map, flatten } from "lodash";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { Icons } from "prefab";
import { FORM_FIELD_TYPE, DAY_OF_WEEK } from "../../../../../constants";
import { bindDispatch } from "../../../../../utils";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import styles from "../../../../../styles/Inventory/EditScheduleCutoffTime.module.scss";

const { RemoveIcon } = Icons;

export class EditSchedulingCutoffTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasFormSaved: false,
      errors: {
        exceptions: [],
        allDays: [],
      },
      schedulingCutOffTime: [],
    };
  }

  componentDidMount = () => {
    const {
      screenSettingsEdit: { setting },
    } = this.props;
    const currentSetting = {
      timeInSeconds: setting?.schedulingCutOffTimeInSeconds,
      exceptions: setting?.schedulingCutOffTimeExceptions ?? null,
    };
    const exceptions = currentSetting?.exceptions ? Object.keys(currentSetting?.exceptions) : [];
    const exceptionsList = exceptions.map((day) => ({
      day: day,
      timeInSeconds: currentSetting.exceptions[day],
    }));
    currentSetting.exceptions = exceptionsList;

    this.setState({
      schedulingCutOffTime: currentSetting,
      isLoading: false,
    });
  };

  closeEditPanel = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;

    actions.cancelSettingEdit();
    history.push(`/screens/${params.screenId}/${params.tabId}`);

    this.setState({
      errors: {
        exceptions: [],
        allDays: [],
      },
      schedulingCutOffTime: null,
    });
  };

  onInputChange = (value, accessor, isException, day) => {
    const { schedulingCutOffTime } = this.state;
    const selectedIndex = isException
      ? DAY_OF_WEEK.findIndex((dayOfWeek) => dayOfWeek.value === day)
      : -1;

    if (isException && selectedIndex > -1) {
      schedulingCutOffTime.exceptions[selectedIndex][accessor] = value;
    } else {
      schedulingCutOffTime[accessor] = value;
    }

    this.setState({
      schedulingCutOffTime,
      hasFormSaved: false,
    });
  };

  handleDelete = (day, removedIndex) => {
    const { schedulingCutOffTime } = this.state;
    schedulingCutOffTime.exceptions = schedulingCutOffTime.exceptions.filter(
      (item, index) => removedIndex !== index
    );

    this.setState({
      schedulingCutOffTime,
      hasFormSaved: false,
    });
  };

  validateEmptyExceptions = () => {
    const { schedulingCutOffTime } = this.state;
    const emptyIndex = schedulingCutOffTime.exceptions.findIndex(
      (exception) => exception.day === null || exception.day < 0 || exception.timeInSeconds <= 0
    );

    return emptyIndex < 0;
  };

  validateAndSaveSchedulingCutOffTime = (schedulingCutOffTime = null) => {
    const newSchedulingCutOffTime = this.state.schedulingCutOffTime || schedulingCutOffTime;
    const newErrors = {
      exceptions: [],
      allDays: [],
    };
    map(newSchedulingCutOffTime.exceptions, (exception, index) => {
      newErrors.exceptions[index] = exception.timeInSeconds <= 0 ? ["Invalid Time Input"] : [];
      if (exception.day === null || exception.day < 0) {
        newErrors.exceptions[index].push("Please select a day");
      }
    });
    newErrors.allDays = newSchedulingCutOffTime.timeInSeconds <= 0 ? ["Invalid Time Input"] : [];

    if (isEmpty(flatten(newErrors.exceptions)) && isEmpty(newErrors.allDays)) {
      const cutOffTime = Object.assign({
        schedulingCutOffTimeInSeconds: newSchedulingCutOffTime.timeInSeconds,
      });
      cutOffTime.schedulingCutOffTimeExceptions = newSchedulingCutOffTime.exceptions.reduce(
        (r, e) => {
          r[e.day] = e.timeInSeconds;
          return r;
        },
        {}
      );
      this.props.onSave(cutOffTime);
    }

    this.setState({
      hasFormSaved: true,
      errors: newErrors,
    });
  };

  handleAddException = () => {
    const { schedulingCutOffTime } = this.state;
    schedulingCutOffTime.exceptions.push({ day: -1, timeInSeconds: 0 });

    this.setState({
      schedulingCutOffTime: schedulingCutOffTime,
    });
  };

  renderCutoffTimeInput = (day = null, timeInSeconds = 0, index, isException = true) => {
    const { schedulingCutOffTime, hasFormSaved } = this.state;
    const exceptionsList = schedulingCutOffTime.exceptions
      ? schedulingCutOffTime.exceptions.map((cutoffTime) => Number(cutoffTime.day))
      : [];
    const daysList = DAY_OF_WEEK.filter((d) => !exceptionsList.includes(d.value)).map((d) => ({
      id: d.value,
      value: d.name,
    }));

    return (
      <div className={styles.container} key={index}>
        <div className={classNames("flex", styles.formWrapper)}>
          <div className={classNames("flex", styles.contentInputWrapper)}>
            {isException ? (
              <>
                <div className={styles.exceptionIcon}>
                  <RemoveIcon />
                </div>
                <Form
                  config={[
                    {
                      id: "type",
                      title: "",
                      label: "",
                      singleSelect: true,
                      showLabelInButton: false,
                      type: FORM_FIELD_TYPE.SELECT,
                      containerClassName: styles.contentInput,
                      className: "col col-9",
                      placeholder: "Select Day",
                      placeHolder: "Select Day",
                      usePortal: false,
                      list: daysList,
                      error: hasFormSaved && (day === null || day < 0),
                      selectedList:
                        day !== null && day !== -1
                          ? [
                              {
                                id: DAY_OF_WEEK.find((item) => item.value === day)?.value,
                                value: DAY_OF_WEEK.find((item) => item.value === day)?.name,
                              },
                            ]
                          : ["Select Day"],
                      onSelect: ([data]) =>
                        this.onInputChange(data.id, "day", isException, index, day),
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <div className={styles.emptyExceptionIcon} />
                <Form
                  config={[
                    {
                      id: "type",
                      size: 10,
                      type: FORM_FIELD_TYPE.BUTTON,
                      isNonLocalizedButton: true,
                      buttonText: "All Days",
                      buttonType: BUTTON_TYPE.DEFAULT,
                      className: styles.allDaysButton,
                    },
                  ]}
                />
              </>
            )}
          </div>
          <div className={classNames("flex", styles.inputWrapper)}>
            <Form
              config={[
                {
                  type: FORM_FIELD_TYPE.INPUT_HOURS,
                  id: "cutoffTime",
                  title: "",
                  value: timeInSeconds,
                  buttonPosition: "none",
                  showSeconds: false,
                  error: hasFormSaved && (timeInSeconds === null || timeInSeconds === 0),
                  onChange: (time) => {
                    this.onInputChange(time, "timeInSeconds", isException, index, day);
                  },
                },
              ]}
            />
          </div>
          {isException && (
            <BaseButton
              className={styles.clearButton}
              iconName="CancelRoundedIcon"
              buttonSize={SIZE.MEDIUM}
              onClick={() => this.handleDelete(day, index)}
            />
          )}
        </div>
      </div>
    );
  };

  render = () => {
    const { errors, isLoading, schedulingCutOffTime, hasFormSaved } = this.state;
    const {
      screenSettingsEdit: { isOpen },
    } = this.props;
    const errorList = merge(flatten(errors.exceptions), errors.allDays) ?? [];

    return (
      <RightPanel
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={this.closeEditPanel}
        header="RightPanelHeader.editScheduleCutoffTime"
        showFooter
        width={360}
        primaryButtonProps={{
          text: "Button.save",
          onClick: this.validateAndSaveSchedulingCutOffTime,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.closeEditPanel }}
      >
        {this.renderCutoffTimeInput(null, schedulingCutOffTime.timeInSeconds, "allDays", false)}
        {schedulingCutOffTime?.exceptions?.map((item, index) =>
          this.renderCutoffTimeInput(Number(item.day), item.timeInSeconds, index, true)
        )}
        {hasFormSaved && (
          <div className={classNames("flex", styles.errorWrapper)}>
            {errorList.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}
        {!schedulingCutOffTime.exceptions ||
          (schedulingCutOffTime.exceptions.length < 7 && this.validateEmptyExceptions() && (
            <BaseButton
              iconName="AddFilledIcon"
              buttonText="Add Another Exception"
              buttonSize={SIZE.REGULAR}
              onClick={this.handleAddException}
              buttonType={BUTTON_TYPE.DEFAULT}
              className={styles.addException}
            />
          ))}
      </RightPanel>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({
    screenSettingsEdit: screens.screenSettingsEdit,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(EditSchedulingCutoffTime));
