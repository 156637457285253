import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import styles from "../../../../styles/Lists/Lists.module.scss";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import FooterControls from "../../../common/FooterControls";
import ActionIcon from "../../../common/ActionIcon";
import EditSegmentType from "./EditSegmentType";

const _LINKS = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.segmentTypes" }];

export class SegmentTypes extends Component {
  state = {
    selectedColumns: ["Code", "Name", "Rank", "Color", "Last Updated By", "Last Updated On"],
  };

  componentDidMount = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addSegmentType();
        actions.getSegmentTypes();
      } else {
        actions.editSegmentTypeById(params.action);
      }
    } else actions.getSegmentTypes();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (segmentType) => {
    const { actions } = this.props;
    actions.createOrUpdateSegmentType(segmentType);
  };

  addSegmentType = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addSegmentType();
    history.replace(`/lists/segment-types/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditSegmentType();
    history.replace(`/lists/segment-types${location.search}`);
  };

  editSegmentType = (segmentType) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editSegmentType(segmentType);
    history.replace(`/lists/segment-types/${segmentType.id}${location.search}`);
  };

  render = () => {
    const {
      segmentTypes: { data, isLoading, segmentTypeEdit },
      history,
      userData,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, segmentType } = segmentTypeEdit;
    const columns = checkScopes(
      [
        {
          id: "code",
          Header: "Code",
          accessor: "code",
        },
        {
          id: "name",
          Header: "Name",
          accessor: "name",
        },
        {
          id: "rank",
          Header: "Rank",
          accessor: "rank",
        },
        {
          id: "color",
          sortable: false,
          Header: "Color",
          accessor: "color",
          Cell: (prop) => (
            <div className={`flex col-12`}>
              <span className={`${styles.bullet} mr1`} style={{ backgroundColor: prop.value }} />
              {prop.value}
            </div>
          ),
        },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.SEGMENT_TYPES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editSegmentType(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.SEGMENT_TYPES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addSegmentType,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          isLoading={isLoading}
          name="PageHeader.segmentTypes"
          count={data.totalCount}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              logsButton
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.SEGMENT_TYPES}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              pageSize={data.data ? data.data.length : 10}
              loading={isLoading}
              sortable={false}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={data.data ? data.data : []}
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        <EditSegmentType
          isOpen={isOpen}
          segmentType={segmentType}
          onClose={this.cancelEdit}
          onSave={this.save}
        />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.segmentTypes,
  (state) => state.userData,
  (segmentTypes, userData) => ({ segmentTypes, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(SegmentTypes);
