import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, modifyTableColumns } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import PageHeader from "../../common/PageHeader";
import HeaderButton from "../../common/HeaderButtons";

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.rights" }];

export class ListRights extends Component {
  state = {
    selectedColumns: ["Code", "Name"],
  };
  static propTypes = { app: PropTypes.object, reasons: PropTypes.object };
  componentDidMount = () => {
    const { actions } = this.props;
    actions.getRights();
  };
  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });
  render = () => {
    const {
      rights: { data, isLoading },
      history,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const columns = [
      {
        id: "code",
        Header: "Code",
        accessor: "code",
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
    ];
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          name="PageHeader.rights"
          count={data.length}
          isLoading={isLoading}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              data={data || []}
              sortable={false}
              pageSize={data ? data.length : 5}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.app,
  (state) => state.rights,
  (app, rights) => ({ app, rights })
);

export default connect(mapStateToProps, bindDispatch)(ListRights);
