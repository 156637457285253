import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../utils";
import PageLoadingWithTable from "../common/PageLoadingWithTable";
import { QA_BASE_URL } from "../../config";

class Logout extends Component {
  componentDidMount() {
    this.props.actions.logoutUser(null, this.props.userData);
    window.location = `${QA_BASE_URL}logout?redirect_url=${window.location.origin}`;
  }
  render() {
    return <PageLoadingWithTable />;
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Logout);
