import { fetchBillingCycles } from "../../api";
import * as types from "../types/billingCycles";

const updateBillingCycles = (payload) => ({ type: types.DATA, payload: payload });
const handleError = (payload) => ({ type: types.IS_ERROR, payload: payload });
const handleBusy = (payload) => ({ type: types.IS_LOADING, payload: payload });

const getBillingCycles = (params = {}, fetch = fetchBillingCycles) => {
  const { sort = "rank:asc" } = params;
  return async (dispatch) => {
    dispatch(handleBusy(true));
    try {
      const data = await fetch(sort);
      dispatch(updateBillingCycles(data));
      dispatch(handleBusy(false));
    } catch (error) {
      dispatch(handleBusy(false));
      dispatch(handleError(error));
    }
  };
};

export { getBillingCycles };
