import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "../../common/Form";
import { FORM_FIELD_TYPE, OPERATIONS } from "../../../constants";
import { convertLableToValue, convertValueToLabel } from "../../../utils/convertLabelValue";

class NumericOperationsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: null,
      numericValue: null,
    };
  }

  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  // This function is called from FilterSection.js
  resetState = () => {
    this.setState({
      condition: null,
      numericValue: null,
    });
  };

  componentDidMount() {
    const { selected } = this.props;
    if (selected && selected.length > 0 && selected[0]) {
      const [{ operation, value }] = selected;
      this.setState({ condition: convertValueToLabel(operation), numericValue: value });
    }
  }
  handleConditionChange = (condition) => {
    this.setState({ condition: condition.value });
  };

  handleNumericValueChange = (numericValue) => {
    const { condition } = this.state;
    this.setState({ numericValue });

    if (condition !== null && numericValue !== null) {
      this.props.onFilterChange(
        {
          type: this.props.tagType,
          id: this.props.tagType,
          operation: convertLableToValue(condition),
          value: numericValue,
        },
        true
      );
    }
  };

  render() {
    const { selected } = this.props;
    const conditionOptions = OPERATIONS.map((operation) => ({
      value: operation.label,
      key: operation.value,
    }));

    return (
      <Form
        config={[
          {
            id: "condition",
            key: "condition",
            type: FORM_FIELD_TYPE.SELECT,
            title: "Condition",
            textKey: "label",
            selectedList: this.state.condition
              ? [this.state.condition]
              : selected && selected.length > 0
              ? [convertValueToLabel(selected[0].operation)]
              : [],
            compareKey: "value",
            placeHolder: "Select Condition",
            onSelect: ([data]) => this.handleConditionChange(data),
            list: conditionOptions,
            usePortal: false,
            singleSelect: true,
            showLabelInButton: false,
          },
          {
            id: "numericValue",
            type: FORM_FIELD_TYPE.NUMERIC_INPUT,
            title: "Numeric Value",
            value: this.state.numericValue ? this.state.numericValue : null,
            onValueChange: this.handleNumericValueChange,
            placeHolder: "Enter Numeric Value",
          },
        ]}
      />
    );
  }
}

export default NumericOperationsFilters;
