import moment from "moment";
import * as constants from "../constants";

const getScreenStatusList = (data) => {
  if (data?.screenStatus && data?.startDate) {
    const { screenStatus, startDate, endDate } = data;
    const isFutureValidity = startDate && moment(startDate).isAfter(moment());
    if (isFutureValidity) {
      const { ACTIVE, INACTIVE, INACTIVE_IN_FUTURE, ACTIVE_IN_FUTURE } = constants.SCREEN_STATUSES;
      const currentScreenStatus = screenStatus === ACTIVE ? INACTIVE : ACTIVE;
      const futureScreenStatus = screenStatus === ACTIVE ? ACTIVE_IN_FUTURE : INACTIVE_IN_FUTURE;
      return [
        { screenStatus: futureScreenStatus, startDate, endDate },
        {
          isCurrent: true,
          screenStatus: currentScreenStatus,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment(startDate).subtract(1, "days").format("YYYY-MM-DD"),
        },
      ];
    } else {
      return [{ isCurrent: true, screenStatus, startDate, endDate }];
    }
  }
  return [];
};

export default getScreenStatusList;
