import React, { Component } from "react";
import PropTypes from "prop-types";
import { findIndex, flatten } from "lodash";
import RightPanel from "../RightPanel";
import LocalizedButton from "./../LocalizedButton";
import FilterSection from "./FilterSection";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  static propTypes = {
    tagTypes: PropTypes.array.isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  closePanel = () => this.setState({ isOpen: false });
  togglePanel = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  onFilterSelect = (tag, override) => {
    const { selected, onFilterChange } = this.props;
    const index = findIndex(selected, (f) => f.id === tag.id);

    if (index >= 0) {
      if (override) {
        selected[index] = tag;
        onFilterChange(selected);
      } else onFilterChange(selected.filter((f) => f.id !== tag.id));
    } else onFilterChange([tag, ...selected]);
  };

  onFilterClear = (tagType) => {
    const { selected, onFilterChange } = this.props;
    onFilterChange(selected.filter((f) => !flatten([tagType]).includes(f.type)));
  };

  render() {
    const { tagTypes, selected } = this.props;
    const filterTagTypes = flatten([tagTypes]);

    return (
      <>
        <LocalizedButton
          text="Button.filter"
          iconName="FilterAdjustIcon"
          onClick={this.togglePanel}
        />
        <RightPanel
          isOpen={this.state.isOpen}
          onClose={this.closePanel}
          header="RightPanelHeader.filters"
          hasFooter={false}
        >
          {filterTagTypes.map((tagType) => (
            <FilterSection
              {...this.props}
              tagType={tagType}
              key={tagType}
              selected={selected.filter((f) => flatten([tagType]).includes(f.type))}
              onFilterClear={() => this.onFilterClear(tagType)}
              onFilterChange={this.onFilterSelect}
            />
          ))}
        </RightPanel>
      </>
    );
  }
}

export default Filters;
