import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@blueprintjs/core";

// This component simply maintains a state called isOpen and
// injects into the header & body through RENDER PROPS method.

// For header, another function called toggleCollapse is passed
// to header, to handle onClick actions.

// Make sure to snapshot with mount instead of shallow while
// testing components with Collapsible
const Collapsible = ({ header, body, className = "", defaultOpen = false }) => {
  const [isOpen, toggleOpen] = useState(defaultOpen);

  const toggleCollapse = () => {
    toggleOpen(!isOpen);
  };

  return (
    <div className={className}>
      {header(isOpen, toggleCollapse)}
      <Collapse isOpen={isOpen}>{body(isOpen)}</Collapse>
    </div>
  );
};

Collapsible.propTypes = {
  defaultOpen: PropTypes.bool,
  Header: PropTypes.func,
  Body: PropTypes.func,
};

export default Collapsible;
