import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@blueprintjs/core";
import classNames from "classnames";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import { Icons } from "prefab";
import styles from "../../../styles/NavMenuAccordion.module.scss";
import { bindDispatch, intlKeyPropType } from "../../../utils";
import checkScopeAuth from "../../../utils/checkScopeAuth";
import NavMenuItem from "./NavMenuItem";

const { ChevronUpIcon, ChevronDownIcon } = Icons;
export class NavMenuAccordion extends Component {
  state = { isOpen: true, isSelected: false };

  static propTypes = {
    name: intlKeyPropType.isRequired,
    menus: PropTypes.array.isRequired,
    isExpanded: PropTypes.bool.isRequired,
  };

  toggleMenuAccordion = () => this.setState({ isOpen: !this.state.isOpen });

  renderMenu(allowedMenuItems) {
    const { isExpanded } = this.props;
    return allowedMenuItems.map((menu, i) => {
      return <NavMenuItem key={i} {...menu} isExpanded={isExpanded} />;
    });
  }

  render() {
    const { isExpanded, name, menus, user, icon } = this.props;
    const { isOpen } = this.state;
    var NavIcon = icon ?? menus[0].Icon;
    const allowedMenuItems = menus.filter((m) => checkScopeAuth(user, m.scope, m.scopeAction));
    const isSelected = allowedMenuItems.some((items) => items.isSelected);

    if (allowedMenuItems.length === 0) return null;

    return (
      <>
        <div
          className={classNames(styles.container, {
            [styles.active]: !isExpanded && isSelected,
            [styles.headerExpanded]: isExpanded,
          })}
          onClick={this.toggleMenuAccordion}
        >
          <div className={styles.contentWrapper}>
            {NavIcon && <NavIcon className={styles.navIcon} />}
            {name && (
              <div className={styles.name}>
                <FormattedMessage id={name} defaultMessage={name} />
              </div>
            )}
          </div>
          {isExpanded && (isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />)}
        </div>
        <Collapse isOpen={isOpen}>{this.renderMenu(allowedMenuItems)}</Collapse>
      </>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ user: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(NavMenuAccordion);
