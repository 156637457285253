/*
UploadCSV component
1. it is used to import multiple screens/theatres from a csv file
2. ERPID is used to import screens/theatres from backend
3. it is calledBy:- AddScreens.js
*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { compact, toLower, isEmpty, keys, values, uniq } from "lodash";
import * as PapaParse from "papaparse";
import RightPanel from "../../../common/RightPanel";
import FileUpload from "../../../common/FileUpload";
import { getUserScreenListByFilterId } from "../../../../api";
import { SCHEDULE_ERRORS } from "../../../../constants";
import { pluralize } from "../../../../utils";
import styles from "../../../../styles/Schedules/uploadCSV.module.scss";

const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};

export default class UploadCSV extends Component {
  state = { uploadErrors: [], hasUploadError: false, isLoading: false, screenList: [] };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string,
  };

  handleFormSave = () => {
    const { screenList } = this.state;
    this.props.updateSelectedScreens(screenList);
    this.handleFormCancel();
  };

  handleFormCancel = () => {
    const { errorScreenList } = this.props;
    const screenIds = errorScreenList?.data.map((error) => error.id);
    this.props.onClose(screenIds);
    //SLATE-1348 Sept21-23 anushree:- #3 when canceling form, uploadErrors should be reset to empty array
    //this.setState({ screenList: [] });
    this.setState({ screenList: [], uploadErrors: [] });
  };

  //Method used to get screenList
  //Params:
  //      filterData: array of id's for which we want to fetch screens
  //      idType: type of id being passed in filterData
  getScreenListById = async (filterData, idType) => {
    const { userData } = this.props;
    try {
      this.setState({ isLoading: true });
      //SLATE-1348 Sept21-23 anushree:- #4 id's with null value shoul not be passed to api
      const noEmptyFilterData = filterData.filter((value) => value != null);
      const { data } = await getUserScreenListByFilterId(userData.company.id, idType, {
        //ids:filterData,
        ids: noEmptyFilterData,
      });
      this.setState({
        screenList: data,
        isLoading: false,
      });
    } catch (error) {
      //SLATE-1348 Sept21-23 anushree:- #5 API error message should be visisble on UI
      //this.setState({ list: [], isLoading: false });
      this.setState({
        list: [],
        isLoading: false,
        hasUploadError: true,
        uploadErrors: [error.response.data.message],
      });
    }
  };

  validateAndParseScreenData = (data) => {
    const uploadErrors = [];

    if (!isEmpty(data)) {
      //PapaParse.parse is the parser used to convert csv into json format { data:[],errors:[],meta:{}}
      const csvData = PapaParse.parse(
        data?.content,
        Object.assign(parseOptions, {
          error: "Error",
          encoding: "UTF-8",
        })
      );

      if (csvData?.data.length > 0) {
        // ERP ID is currently available in CSV file
        const filterableColumn = "ERPID";

        //csv file should have column ERPID otherwise ERROR
        //SLATE-1348 Sept21-23 anushree:- #2 column name with ERPID is allowed in all formats, with/without capslock
        const csvColumnName = csvData?.meta?.fields.find(
          (column) => toLower(filterableColumn) === toLower(column)
        );
        if (csvColumnName !== undefined) {
          //const filterIds = csvData?.data.map((screen) => screen[filterableColumn]);
          const filterIds = csvData?.data.map((screen) => screen[csvColumnName]);
          //ERPID column should have data otherwise ERROR
          if (compact(filterIds).length > 0) {
            this.setState(
              {
                hasUploadError: uploadErrors.length > 0,
                uploadErrors,
                isLoading: true,
              },
              //get screenList for all ERPIDs
              () => this.getScreenListById(filterIds, "erpId")
            );
            return;
          } else {
            uploadErrors.push(`${filterableColumn} data is empty`);
          }
        } else {
          uploadErrors.push(`${filterableColumn} column not Found`);
        }
      } else {
        uploadErrors.push("No Data Found");
      }
    }
    this.setState({
      hasUploadError: uploadErrors.length > 0,
      uploadErrors,
      isLoading: false,
    });
  };

  //method to remove all the screens with error
  handleDelete = () => {
    const { errorScreenList, removeScheduleScreens } = this.props;
    const screenIds = errorScreenList?.data.map((error) => error.id);

    if (removeScheduleScreens) {
      removeScheduleScreens(screenIds);
    }
  };

  //method to render screen data for the screenIds passed
  renderScreenErrors = (screenIds) => {
    const { errorScreenList } = this.props;

    if (screenIds?.length === 0 || errorScreenList.data.length === 0) return null;

    return screenIds.map((screenId, index) => {
      const screenData = errorScreenList?.data?.find((screen) => screen.id === screenId);
      return (
        <div className={styles.screen} key={index}>
          <div className={styles.name}>
            {screenData.name} {screenData?.theatreName ? `• ${screenData?.theatreName}` : ""}
          </div>
          <div className={styles.location}>
            {screenData?.cityName}
            {screenData?.countryName ? `• ${screenData?.countryName}` : ""}
          </div>
        </div>
      );
    });
  };

  //method to calculate statistics regarding uploaded screen and theatres
  getCSVDataStats = () => {
    const { screenList } = this.state;

    if (isEmpty(screenList)) return "";

    const screenCount = screenList?.totalCount;
    const theatreCount = screenList?.data?.map((screen) => screen?.theatreId);

    return `${screenCount} Screens of ${uniq(compact(theatreCount)).length} Theatres Found in CSV`;
  };

  render = () => {
    const { isOpen, scheduleErrors, errorScreenList } = this.props;
    //const { isLoading, uploadErrors, hasUploadError } = this.state;
    const { isLoading, uploadErrors, hasUploadError, screenList } = this.state;
    const hasScheduleErrors = values(scheduleErrors)?.length > 0;
    const cancelActionProps = {
      text: "Button.cancel",
      onClick: this.handleFormCancel,
      disabled: isLoading || hasUploadError,
    };

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={this.handleFormCancel}
        header={
          hasScheduleErrors
            ? "RightPanelHeader.scheduleScreenErrors"
            : "RightPanelHeader.selectScreensByCSV"
        }
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.selectScreens",
            onClick: this.handleFormSave,
            isHidden: hasScheduleErrors,
            //SLATE-1348 Sept21-23 anushree:- #1 if no data available in screenList, select screen button should be disabled.
            //disabled: isLoading || hasUploadError,
            disabled: isLoading || hasUploadError || !screenList?.data?.length > 0,
          },
        ]}
        secondaryButtonProps={hasScheduleErrors ? cancelActionProps : null}
      >
        {!hasScheduleErrors && (
          <FileUpload
            showRemove
            title="Button.selectScreenCsv"
            description="(or) Drag & Drop CSV"
            errorMessage="Please upload an CSV file"
            icon="DownloadIcon"
            onSelect={this.validateAndParseScreenData}
            acceptableFormats=".csv"
            mimeType="text/csv"
            isLoading={isLoading}
          />
        )}
        {uploadErrors.length === 0 && (
          <div className={classNames(styles.data, { [Classes.SKELETON]: isLoading })}>
            {this.getCSVDataStats()}
          </div>
        )}
        {uploadErrors.length > 0 && (
          <div className={styles.errorList}>
            {uploadErrors.map((error, index) => (
              //code refactoring:- unique key should be passed
              <div className={styles.error} key={`${error}-${index}`}>
                {error}
              </div>
            ))}
          </div>
        )}
        {hasScheduleErrors && (
          <BaseButton
            className={styles.clearButton}
            iconName="RemoveFilledIcon"
            buttonText={errorScreenList.data.length > 1 ? "Remove All" : "Remove Screen"}
            buttonSize={SIZE.REGULAR}
            buttonType={BUTTON_TYPE.DESTRUCTIVE_SECONDARY}
            onClick={this.handleDelete}
          />
        )}
        {hasScheduleErrors &&
          keys(scheduleErrors).map((error, index) => (
            <div className={styles.errorList} key={index}>
              <div className={styles.errorType}>{`${SCHEDULE_ERRORS[error]} ${pluralize(
                scheduleErrors[error].length,
                "screen"
              )}`}</div>
              <div className={styles.screenErrors}>
                {this.renderScreenErrors(scheduleErrors[error]?.map((err) => err?.screenId))}
              </div>
            </div>
          ))}
      </RightPanel>
    );
  };
}
