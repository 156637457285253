import {
  getAdvertisers as getAdvertisersApi,
  createOrUpdateAdvertiser as createOrUpdateAdvertiserApi,
  getMediaAgencies as getMediaAgenciesApi,
  getExhibitors as getExhibitorsApi,
  getAdvertiserById as fetchAdvertiser,
  saveAdvertiserPreferences as saveSettings,
  saveCompanyPreferences as saveCompanyPreferencesApi,
  getCompanyById as getCompanyByIdApi,
  getThirdpartyIdentifier as fetchThirdpartyIdentifierApi,
  getCompanyAdvertiserPreferences as getAdvertiserPreferencesApi,
} from "../../api";
import * as types from "../types/companies";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateAdvertisers = (payload) => {
  return { type: types.ADVERTISERS, payload: payload };
};

const updateThirdpartyIdentifiers = (payload) => {
  return { type: types.THEIRD_PARTY_IDENTIFIERS, payload: payload };
};

const updateAdvertiser = (payload) => {
  return { type: types.ADVERTISER, payload };
};
const updateAdvertiserSettings = (payload) => ({ type: types.ADVERTISER_SETTINGS, payload });

const updateMediaAgencies = (payload) => {
  return { type: types.MEDIA_AGENCIES, payload: payload };
};

const updateMediaAgency = (payload) => {
  return { type: types.MEDIA_AGENCY, payload: payload };
};

const updateExhibitors = (payload) => {
  return { type: types.EXHIBITORS, payload: payload };
};

const updateExhibitor = (payload) => {
  return { type: types.EXHIBITOR, payload: payload };
};

const updateCompanies = (payload) => {
  return { type: types.COMPANIES, payload: payload };
};

const getCompanies = () => {
  return async (dispatch) => {
    try {
      let mediaAgencies, exhibitors;
      mediaAgencies = exhibitors = {};
      dispatch(actionCreator(types.IS_LOADING, true));

      ({ data: mediaAgencies } = await getMediaAgenciesApi(0, 0, [], {}));
      // TODO: UNCOMMENT WHEN API IS READY
      // ({ data: exhibitors } = await getExhibitorsApi(0, 0, [], {}));

      dispatch(
        updateCompanies({
          exhibitorsCount: exhibitors.totalCount || 0,
          mediaAgenciesCount: mediaAgencies.totalCount || 0,
        })
      );
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getAdvertisers = (params, filters = [], getList = getAdvertisersApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateAdvertisers(data));
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
    }
  };
};

const getAdvertiserById = (advertiserId, getData = fetchAdvertiser) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, true));
      const { data } = await getData(advertiserId);
      dispatch(updateAdvertiser(data));
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getCompanyAdvertiserPreferences = (
  companyId,
  advertiserId,
  getData = getAdvertiserPreferencesApi
) => {
  return async (dispatch, getState) => {
    const { userData } = getState();
    try {
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, true));
      const { data } = await getData(companyId, advertiserId);
      dispatch(updateAdvertiserSettings(data));
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse?.data?.code === 404) {
        dispatch(updateAdvertiserSettings(userData?.user?.company?.preferences));
      }
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const editAdvertiser = (advertiser) => (dispatch) =>
  dispatch(actionCreator(types.ADD_OR_EDIT_ADVERTISER, { advertiser, isOpen: true }));

const cancelEditAdvertiser = () => (dispatch) =>
  dispatch(actionCreator(types.ADD_OR_EDIT_ADVERTISER, { isOpen: false, advertiser: {} }));

const saveAdvertiserPreferences = (setting, companyId, params, callback, save = saveSettings) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, true));
      await save(setting, companyId, params.advertiserId);
      await getCompanyAdvertiserPreferences(companyId, params.advertiserId);
      showToast("Toast.settingsUpdatedSuccessfully");
      callback();
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_ADVERTISER_SETTINGS_LOADING, false));
    }
  };
};

const saveCompanyPreferences = (setting, companyId, save = saveCompanyPreferencesApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_COMPANY_SETTING_LOADING, true));
      await save(setting, companyId);
      showToast("Toast.settingsUpdatedSuccessfully");
      dispatch(actionCreator(types.IS_COMPANY_SETTING_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_COMPANY_SETTING_LOADING, false));
    }
  };
};

const searchThirdpartyIdentifier = (text, search = fetchThirdpartyIdentifierApi) => {
  return async (dispatch) => {
    try {
      const { data } = await search(text);
      dispatch(updateThirdpartyIdentifiers(data));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const createOrUpdateAdvertiser = (
  params,
  filters,
  advertiser,
  save = createOrUpdateAdvertiserApi,
  getList = getAdvertisersApi
) => {
  const { page = 0, ps = 0, sort = "categoryName:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, true));
      await save(advertiser);
      if (params.editAdvertiserId) {
        const { data } = await getList(ps, (page - 1) * ps, sort, filters);
        dispatch(updateAdvertisers(data));
        showToast("Toast.updateAdvertiser");
      } else {
        dispatch(getAdvertiserById(advertiser.id));
        showToast(advertiser.id ? "Toast.updateAdvertiser" : "Toast.advertiserAdded", true);
      }
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ADVERTISER_LOADING, false));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateAdvertisers(data));
      showToast(error.message, false);
      return error;
    }
  };
};

const getMediaAgencies = (params, filters = [], getList = getMediaAgenciesApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_AGENCIES_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateMediaAgencies(data));
      dispatch(actionCreator(types.IS_MEDIA_AGENCIES_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_AGENCIES_LOADING, false));
    }
  };
};

const getMediaAgency = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getCompanyByIdApi(params.companyId);
      dispatch(updateMediaAgency(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getExhibitors = (params, filters = [], getList = getExhibitorsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_EXHIBITORS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateExhibitors(data));
      dispatch(actionCreator(types.IS_EXHIBITORS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_EXHIBITORS_LOADING, false));
    }
  };
};

const getExhibitor = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getCompanyByIdApi(params.companyId);
      dispatch(updateExhibitor(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export {
  getCompanies,
  getAdvertisers,
  getAdvertiserById,
  createOrUpdateAdvertiser,
  getMediaAgencies,
  getExhibitors,
  saveAdvertiserPreferences,
  saveCompanyPreferences,
  getMediaAgency,
  searchThirdpartyIdentifier,
  getExhibitor,
  getCompanyAdvertiserPreferences,
  editAdvertiser,
  cancelEditAdvertiser,
};
