import { TAG_TYPE } from "../../../constants";
import { AUDIT_LOG_SOURCE, PARENT_SOURCE } from "./logSource";

export const logConfig = {
  [AUDIT_LOG_SOURCE.CATEGORIES]: {
    displayName: "Product Categories",
    route: "/lists/product-categories",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.REASONS]: {
    displayName: "Reasons",
    route: "/lists/reasons",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.CONTENT_TYPES]: {
    displayName: "Content Types",
    route: "/lists/content-types",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.NOTIFICATION_TEMPLATES]: {
    displayName: "Notification Templates",
    route: "/lists/notification-templates",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.CAMPAIGN_TARGET_TYPES]: {
    displayName: "Campaign Target Types",
    route: "/lists/campaign-target-types",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.PRODUCT_IDENTIFICATION_NUMBER_TYPES]: {
    displayName: "Product Identification Number Type",
    route: "/lists/product-identification-number-types",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.TIMES_OF_DAY]: {
    displayName: "Times of Day",
    route: "/lists/times-of-day",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.SEGMENT_TYPES]: {
    displayName: "Segment Types",
    route: "/lists/segment-types",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.COUNTRIES]: {
    displayName: "Countries & Taxes",
    route: "/lists/countries",
    parentRoute: PARENT_SOURCE.LISTS,
  },
  [AUDIT_LOG_SOURCE.BRANDS]: {
    displayName: "Brands",
    route: "/brands/parent-brands",
  },
  [TAG_TYPE.PARENT_BRAND]: {
    displayName: "Parent Brands",
    route: "/brands/parent-brands",
  },
  [TAG_TYPE.INDIVIDUAL_BRAND]: {
    displayName: "Individual Brands",
    route: "/brands/individual-brands",
  },
  [TAG_TYPE.PRODUCT]: {
    displayName: "Products",
    route: "/brands/products",
  },
  [TAG_TYPE.VARIANT]: {
    displayName: "Variants",
    route: "/brands/variants",
  },
  [AUDIT_LOG_SOURCE.BRAND_VERIFICATIONS]: {
    displayName: "Brand Verifications",
    route: "/brand-verifications",
  },
  [AUDIT_LOG_SOURCE.THEATRES]: {
    displayName: "Theatres",
    route: "/theatres",
  },
  [AUDIT_LOG_SOURCE.SCREENS]: {
    displayName: "Screens",
    route: "/screens",
  },
  [AUDIT_LOG_SOURCE.COMPANIES]: {
    displayName: "Companies",
    route: "/companies",
  },
  [AUDIT_LOG_SOURCE.ADVERTISERS]: {
    displayName: "Advertisers",
    route: "/companies/advertisers",
  },
  [TAG_TYPE.ADVERTISER]: {
    displayName: "Advertisers",
    route: "/companies/advertisers",
  },
  [AUDIT_LOG_SOURCE.MEDIA_AGENCIES]: {
    displayName: "Media Agencies",
    route: "/companies/media_agencies",
    parentRoute: PARENT_SOURCE.COMPANIES,
  },
  [AUDIT_LOG_SOURCE.EXHIBITORS]: {
    displayName: "Exhibitors",
    route: "/companies/exhibitors",
    parentRoute: PARENT_SOURCE.COMPANIES,
  },
  [AUDIT_LOG_SOURCE.CAMPAIGNS]: {
    displayName: "Campaigns",
    route: "/campaigns",
  },
  [AUDIT_LOG_SOURCE.CPL]: {
    displayName: "Compositions",
    route: "/compositions",
  },
  [AUDIT_LOG_SOURCE.SCHEDULE_ORDERS]: {
    displayName: "Schedule Orders",
    route: "/schedules",
  },
};
