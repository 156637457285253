import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { isEmpty, uniqBy } from "lodash";
import { Icons } from "prefab";
import { Position } from "@blueprintjs/core";
import SwitchButtonWithText from "../../../common/SwitchButtonWithText";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import StatusIcon from "../../../common/StatusIcon";
import ActionIcon from "../../../common/ActionIcon";
import { ActiveTableCell, ActiveTableCellColumnFromArray } from "../../../common/ActiveTableCell";
import ScreenTable from "../../../tables/Screen";
import { getHoursFromSeconds } from "../../../../utils/timeUtils";
import {
  lastUpdatedAtColumn,
  locationColumn,
  getParams,
  getScreenValidity,
  checkScopeAuth,
} from "../../../../utils";
import {
  getScreenStatusLegends,
  getStatusDisplayName,
} from "../../../../utils/screens/statusLegend";
import * as constants from "../../../../constants";
import styles from "../../../../styles/Inventory/ScreenList.module.scss";

const { LogsIcon, ViewIcon, PauseIcon, PlayIcon, EditIcon, CancelIcon } = Icons;

export default class ScreenList extends Component {
  constructor(props) {
    super(props);
    this.screenTable = React.createRef();
  }

  componentDidMount = async () => {
    this.props.fetchData(null, false, [{ companyId: this.props.userData.company.id }]);
  };

  renderScreenWithTheatre = (d) => {
    const theatreId = d?.theatre?.id ?? d.theatreId;
    const theatreName = d?.theatre?.name ?? d.theatreName;
    return (
      <>
        <span>
          {ActiveTableCell(theatreId, theatreName, () => this.props.onFilterIdSelect(theatreId))} •{" "}
        </span>
        {ActiveTableCell(d.id, d.name, () => this.props.onFilterIdSelect(d.id))}
      </>
    );
  };

  renderChainName = (d) => {
    const chainId = d?.chain?.id ?? d?.chainId;
    const chainName = d?.chain?.name ?? d?.chainName;
    return ActiveTableCell(chainId, chainName, () => this.props.onFilterIdSelect(chainId));
  };

  renderCompaniesAssociated = (d) => {
    if (!d.companies) return null;
    return <div>{ActiveTableCellColumnFromArray(d.companies, this.props.onFilterIdSelect)}</div>;
  };

  renderPlaylistTemplate = (playlists) => {
    if (!playlists) return;
    playlists = uniqBy(playlists, "id");
    return playlists.map((playlist) => playlist.playlistTemplateName);
  };

  renderRights = (rights) => {
    if (!rights) return;
    rights = uniqBy(rights, "rightName");
    return rights.map((right, i) => (
      <div key={i}>
        {right.rightName}
        {right.rightName && right.playlistPackName && <span>&nbsp;·&nbsp;</span>}
        {right.playlistPackName}
        {right.playlistPackName && right.segmentName && <span>&nbsp;·&nbsp;</span>}
        {right.segmentName}
      </div>
    ));
  };

  getScreenColumns = () => {
    const { userData } = this.props;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.WRITE
    );
    return [
      {
        id: "name",
        Header: "Theatre • Screen",
        accessor: (d) => this.renderScreenWithTheatre(d),
      },
      {
        id: "chainName",
        Header: "Chain",
        accessor: (d) => this.renderChainName(d),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "companies",
        Header: "Companies Associated",
        accessor: (d) => this.renderCompaniesAssociated(d),
        sortable: false,
      },
      lastUpdatedAtColumn(),
      {
        id: "seatingCapacity",
        Header: "Seating Capacity",
        accessor: "seatingCapacity",
      },
      {
        id: "occupancy",
        Header: "Occupancy",
        accessor: (d) =>
          d.screenSettings?.[0]?.averageOccupancyInPercentage
            ? `${d.screenSettings[0].averageOccupancyInPercentage}%`
            : "",
        sortable: false,
      },
      {
        id: "schedulingCutOffTimeInSeconds",
        sortable: false,
        Header: "Scheduling Cut-off Time",
        accessor: (d) =>
          d.screenSettings?.[0]?.schedulingCutOffTimeInSeconds
            ? getHoursFromSeconds(d.schedulingCutOffTimeInSeconds)
            : "",
      },
      {
        id: "playlistTemplate",
        Header: "Playlist Template",
        accessor: (d) => this.renderPlaylistTemplate(d.playlists),
        sortable: false,
      },
      {
        id: "rights",
        Header: "Rights",
        accessor: (d) => this.renderRights(d.rights),
        sortable: false,
      },
      {
        id: "theatreId",
        Header: "Theatre ID",
        accessor: "theatreId",
        sortable: false,
      },
      {
        id: "id",
        Header: "Screen ID",
        accessor: "id",
        sortable: false,
      },
      {
        id: "erpId",
        Header: "Screen ERP ID",
        accessor: "erpId",
        sortable: false,
      },
      {
        id: "davpCode",
        Header: "DAVP TH Code",
        accessor: "davpCode",
        sortable: false,
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        Cell: (cellProps) => this.renderStatusWithActions(cellProps, hasWriteScope),
      },
    ];
  };

  getScreenStatus = (screenSettings) => {
    if (this.props.isAdmin) return { screenStatus: "" };
    const futureScreenStatus = screenSettings?.screenStatusList?.find((s) => !s.isCurrent) ?? null;

    return isEmpty(futureScreenStatus) ? screenSettings[0]?.screenStatus : futureScreenStatus;
  };

  renderStatusIcon = (cellProps) => {
    if (isEmpty(cellProps?.original?.screenSettings) || this.props.isAdmin) return null;

    const {
      SCREEN_STATUSES: { ACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
      STATUS_ICON_COLORS: { GREEN, GREY },
      STATUS_ICON_TYPES: { DURATION, CIRCLE },
    } = constants;
    const { screenSettings } = cellProps.original;
    const currentScreenStatus = this.getScreenStatus(screenSettings);
    const futureScreenStatus = screenSettings?.screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const { screenStatus: status } = currentScreenStatus;

    // TODO: Update this check once "Action Required" is available in API
    if (!status) return null;
    return (
      <div
        className={classNames("flex align-center", styles.status)}
        onClick={(event) => {
          event.stopPropagation();
          this.props.onFilterSelect({
            id: status,
            displayName: getStatusDisplayName(status),
            type: constants.TAG_TYPE.SCREEN_STATUS,
          });
        }}
      >
        <StatusIcon
          toolTipClass={styles.statusTooltip}
          color={[ACTIVE, INACTIVE_IN_FUTURE].includes(status) ? GREEN : GREY}
          type={[ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(status) ? DURATION : CIRCLE}
          showToolTip={!isEmpty(futureScreenStatus)}
          toolTip={getScreenValidity(
            status,
            futureScreenStatus?.startDate,
            futureScreenStatus?.endDate
          )}
          toolTipPosition={Position.LEFT}
        />
      </div>
    );
  };

  renderStatusWithActions = (cellProps, hasWriteScope) => {
    return (
      <div className={classNames("col-12 flex align-center", styles.actionItems)}>
        {this.renderStatusIcon(cellProps)}
        <div className={classNames("flex align-center", styles.moreItems)}>
          {this.renderDropdownMenu(cellProps, hasWriteScope)}
        </div>
      </div>
    );
  };

  renderDropdownMenu = (props, hasWriteScope) => {
    const {
      SCOPES,
      SCREEN_STATUSES: { ACTIVE, INACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
    } = constants;
    const { history, userData, filters } = this.props;
    const { id, screenSettings } = props.original;
    const futureScreenStatus = screenSettings?.screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const currentScreenStatus = this.getScreenStatus(screenSettings);
    const { screenStatus: status } = currentScreenStatus;
    return (
      <ActionIcon
        iconProps={[
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: [
              {
                text: "View",
                onClick: () => history.push(`/screens/${props.original.id}/details`),
                icon: <ViewIcon />,
              },
              {
                text: "Logs",
                onClick: () => this.props.history.push(`/logs/ref/${id}`),
                hideMenuItem: !checkScopeAuth(userData, SCOPES.LOGS),
                icon: <LogsIcon />,
              },
              {
                text: "Activate",
                onClick: () => this.screenTable?.openScreenAction(ACTIVE, props.original),
                hideMenuItem: !(hasWriteScope && status === INACTIVE),
                icon: <PlayIcon />,
              },
              {
                text: "Deactivate",
                onClick: () => this.screenTable?.openScreenAction(INACTIVE, props.original),
                hideMenuItem: !(hasWriteScope && status === ACTIVE),
                icon: <PauseIcon />,
              },
              {
                text: "Edit Deactivation",
                onClick: () =>
                  this.screenTable?.openScreenAction(INACTIVE_IN_FUTURE, props.original),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === INACTIVE_IN_FUTURE
                ),
                icon: <EditIcon />,
              },
              {
                text: "Clear Deactivation",
                onClick: () =>
                  this.props.actions.updateScreenStatus(
                    [id],
                    {
                      status: ACTIVE,
                      startDate: moment().format("YYYY-MM-DD"),
                    },
                    getParams({
                      ps: this.props.ps,
                      page: this.props.page,
                      sort: this.props.sort,
                      companyId: this.props.userData.company.id,
                    }),
                    filters
                  ),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === INACTIVE_IN_FUTURE
                ),
                icon: <CancelIcon />,
              },
              {
                text: "Edit Activation",
                onClick: () => this.screenTable?.openScreenAction(ACTIVE_IN_FUTURE, props.original),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === ACTIVE_IN_FUTURE
                ),
                icon: <EditIcon />,
              },
              {
                text: "Clear Activation",
                onClick: () =>
                  this.props.actions.updateScreenStatus(
                    [id],
                    {
                      status: INACTIVE,
                      startDate: moment().format("YYYY-MM-DD"),
                    },
                    getParams({
                      ps: this.props.ps,
                      page: this.props.page,
                      sort: this.props.sort,
                      companyId: this.props.userData.company.id,
                    }),
                    filters
                  ),
                hideMenuItem: !(
                  hasWriteScope &&
                  !isEmpty(futureScreenStatus) &&
                  futureScreenStatus?.screenStatus === ACTIVE_IN_FUTURE
                ),
                icon: <CancelIcon />,
              },
            ],
          },
        ]}
      />
    );
  };

  render() {
    const {
      userData,
      history,
      screens: {
        screens,
        isLoading,
        isPartialLoading,
        isSaveScheduleTimingsLoading,
        isUpdateScreenStatusLoading,
      },
      isFilterLoading,
    } = this.props;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.WRITE
    );
    if (isLoading) return <PageLoadingWithTable />;
    return (
      <div>
        <PageHeader
          name="PageHeader.screens"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={screens.totalCount}
          renderRightSideComponent={() => (
            <SwitchButtonWithText
              buttons={[
                {
                  onClick: () => history.push("/theatres"),
                  text: "Button.theatres",
                },
                {
                  isActive: true,
                  text: "Button.screens",
                },
              ]}
            />
          )}
        />
        <ScreenTable
          {...this.props}
          ref={(ref) => (this.screenTable = ref)}
          showScheduleActions
          showScreenStatusActions
          screenList={screens}
          isFilterLoading={isFilterLoading}
          isPartialLoading={isPartialLoading}
          isSaveScheduleTimingsLoading={isSaveScheduleTimingsLoading}
          isUpdateScreenStatusLoading={isUpdateScreenStatusLoading}
          columns={this.getScreenColumns()}
          statusList={getScreenStatusLegends(screens?.data)}
          tagTypes={[
            constants.TAG_TYPE.SCREEN,
            constants.TAG_TYPE.SCREEN_STATUS,
            constants.TAG_TYPE.SCREEN_DAVP_ID,
            constants.TAG_TYPE.SCREEN_ERP_ID,
          ]}
          isSelectable={hasWriteScope}
          updateScreenStatus={this.props.actions.updateScreenStatus}
        />
      </div>
    );
  }
}
