import {
  getCompositions,
  uploadComposition,
  getCompositionById,
  updateComposition,
} from "../../api";
import * as types from "../types/compositions";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateCompositionList = (payload) => ({ type: types.COMPOSITIONS_LIST, payload });

const updateCompositionData = (payload) => ({ type: types.GET_CPL, payload });

const getCompositionList = (params, filters = [], getList = getCompositions, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, false));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCompositionList(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getCompositionDetails = (params, getData = getCompositionById) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_COMPOSITION_DETAILS_LOADING, true));
      if (params.id) {
        const { data } = await getData(params.id);
        dispatch(updateCompositionData(data));
      }
      dispatch(actionCreator(types.IS_COMPOSITION_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_COMPOSITION_DETAILS_LOADING, false));
    }
  };
};

const uploadCPLFile = (xmlData, callback, upload = uploadComposition) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_COMPOSITION_UPLOADING, true));
      const { data } = await upload(xmlData);
      if (data?.id) {
        dispatch(getCompositionDetails({ id: data.id }));
      }
      dispatch(actionCreator(types.IS_COMPOSITION_UPLOADING, false));
    } catch (error) {
      const errorResponse = error.response;
      dispatch(actionCreator(types.IS_COMPOSITION_UPLOADING, false));
      if (errorResponse?.status === 400) {
        showToast("Toast.invalidComposition", false);
        dispatch(resetComposition());
        callback(null, false, true);
        return;
      }
      if (errorResponse?.status === 409) {
        showToast("Toast.compositionExists", false);
        if (errorResponse?.data?.id) {
          dispatch(getCompositionDetails({ id: errorResponse.data.id }));
          callback(errorResponse?.data?.id, true);
          return;
        }
      }
      return error;
    }
  };
};

const saveComposition = (data, callback, save = updateComposition) => {
  return async (dispatch) => {
    try {
      const composition = {
        advertiserTypeId: data.advertiserTypeId,
        contentTypeId: data.contentTypeId,
      };
      await save(composition, data.id);
      showToast("Toast.compositionSaved", true);
      callback(data.id);
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.response.data.message));
      dispatch(actionCreator(types.IS_LOADING, false));
      showToast(error.response.data.message, false);
      callback();
    }
  };
};

const resetComposition = () => {
  return async (dispatch) => {
    dispatch(actionCreator(types.RESET_COMPOSITION));
  };
};

export {
  getCompositionList,
  uploadCPLFile,
  getCompositionDetails,
  saveComposition,
  updateCompositionData,
  resetComposition,
};
