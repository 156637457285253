import { CPL_STATUSES } from "../../../../../constants";

const getStatusFromParams = (props) => {
  const {
    match: {
      params: { status },
    },
  } = props;
  if (status === "mapped") {
    return CPL_STATUSES.MAPPED;
  } else if (status === "ignored") {
    return CPL_STATUSES.IGNORED;
  } else if (status === "movies") {
    return CPL_STATUSES.MOVIE_MAPPED;
  } else {
    return CPL_STATUSES.UNMAPPED;
  }
};

export default getStatusFromParams;
