const viewCPLsList = (status, props) => {
  const { history, actions } = props;
  actions.resetCPLs();
  if (status === "mapped") {
    history.push(`/cpl_mappings/mapped`);
  } else if (status === "ignored") {
    history.push(`/cpl_mappings/ignored`);
  } else if (status === "movie") {
    history.push(`/cpl_mappings/movies`);
  } else {
    history.push(`/cpl_mappings/unmapped`);
  }
};

export default viewCPLsList;
