import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  locationColumn,
} from "../../../utils";
import * as constants from "../../../constants";
import ActionIcon from "../../common/ActionIcon";
import FilterChips from "../../common/Filters/FilterChips";
import WithFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import TableLoading from "../../common/TableLoading";
import FooterControls from "../../common/FooterControls";
import pageStyles from "../../../styles/App.module.scss";

class MediaAgencies extends Component {
  state = {
    selectedColumns: [
      "Media Agency Name",
      "Location",
      "Screens",
      "Theatres",
      "Chains",
      "Last Updated By",
      "Last Updated On",
    ],
  };
  componentDidMount = () => {
    this.props.fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      history,
      companies: { mediaAgencies, isMediaAgenciesLoading },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = mediaAgencies.totalCount || 0;

    const columns = [
      {
        id: "name",
        Header: "Media Agency Name",
        accessor: "name",
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "countScreens",
        Header: "Screens",
        accessor: "countScreens",
        width: 128,
      },
      {
        id: "countTheatres",
        Header: "Theatres",
        accessor: "countTheatres",
        width: 128,
      },
      {
        id: "countChains",
        Header: "Chains",
        accessor: "countChains",
        width: 128,
      },
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),
      //TODO: Enable it when view company story arrives
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (d) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/companies/media_agencies/${d.original.id}`,
                  iconName: "ViewIcon",
                },
              ],
              userData
            )}
          />
        ),
      },
    ];

    return (
      <div className={pageStyles.pageContainer}>
        <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
          <TableControls
            searchBar
            columnFilter
            showRowSize
            pagination
            columns={columns}
            selectedColumns={selectedColumns}
            reorderedColumns={reorderedColumns}
            data={mediaAgencies}
            query={query}
            ps={ps}
            page={page}
            tagTypes={[constants.TAG_TYPE.COMPANY, constants.TAG_TYPE.UPDATED_AT]}
            onSearchFilterSelect={this.props.onFilterSelect}
            onFilterChange={this.props.onFilterChange}
            onColumnFilterSelect={this.onColumnSelect}
            onRowSizeChange={onQueryChange}
            filters={filters}
          />
          <FilterChips
            selected={filters}
            showResultsCount
            resultsCount={resultsCount}
            onFilterChange={this.props.onFilterChange}
          />
          <Table
            data={mediaAgencies.data}
            loading={isMediaAgenciesLoading || isFilterLoading}
            columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            defaultPageSize={constants.DEFAULT_PAGE_SIZE}
            sorted={sort}
            onSortedChange={onSortedChange}
            selectable={false}
            manual={true}
            LoadingComponent={
              <TableLoading
                columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              />
            }
            onRowClick={({ original }) => history.push(`/companies/media_agencies/${original.id}`)}
          />
          <FooterControls
            pagination
            data={mediaAgencies}
            query={query}
            ps={ps}
            page={page}
            onRowSizeChange={onQueryChange}
          />
        </div>
      </div>
    );
  };
}

export const MediaAgenciesWithFilters = WithFetchList("getMediaAgencies", {
  sort: [
    {
      id: "updatedAt",
      desc: true,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(MediaAgencies);

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (companies, userData) => ({ companies, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(MediaAgenciesWithFilters));
