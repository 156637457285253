import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { compact, sum } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import * as constants from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import { bindDispatch, formatDateTime, modifyTableColumns } from "../../../../utils";
import Breadcrumb from "../../../common/BreadCrumb";
import FooterControls from "../../../common/FooterControls";
import PageHeader from "../../../common/PageHeader";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";

import {
  FAILURE_STATUSES,
  STATIC_FILTER_TAG_LIST,
  STATUS_ICON_COLORS,
  STATUS_ICON_TYPES,
  TAG_TYPE,
} from "../../../../constants";
import {
  ActiveTableCell,
  ActiveTableCellColumnFromArray,
  ActiveTableCellRowFromArray,
} from "../../../common/ActiveTableCell";
import FilterChips from "../../../common/Filters/FilterChips";
import StatusIconLegend from "../../../common/StatusIconLegend";
import SwitchButtonWithText from "../../../common/SwitchButtonWithText";
import WithFetchList from "../../../hoc/withFetchList";
import ShowFailuresSubComponent from "./ShowFailuresSubComponent";

const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

const dayBeforeYesterday = new Date();
dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

const today = new Date();
const {
  PENDING_SCHEDULE_DELIVERY,
  PENDING_PLAYLOG_RETRIEVAL,
  UNOPTIMIZED,
  LOG_PENDING,
  PROBLEMATIC,
  ACTIVE,
  COMPLETED,
  PLANNED,
  PLAYED,
  SCHEDULED,
  NOT_PLAYED,
  PENDING,
  RETRIEVED,
  RETRIEVING,
} = FAILURE_STATUSES;

class ShowFailuresSpotStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [
        "Date • Day",
        "Show Time",
        "Segment • Segment Type",
        "Playlist Pack",
        "Segment Type",
        "Position",
        "Spot Status",
        "Content Status",
        "Schedule Status",
        "Playlog Status",
      ],
    };
  }

  getDefaultFilters = () => {
    return [
      {
        from: dayBeforeYesterday,
        id: "dateRange",
        type: "dateRange",
        displayName: `${formatDate(dayBeforeYesterday)}-${formatDate(today)}`,
        to: today,
      },
    ];
  };
  componentDidMount() {
    // Code to run after component mounts
    const {
      actions,
      match: { params },
    } = this.props;

    actions.getShowFailureSpotStatusData({ id: params.id });
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after component updates
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  componentWillUnmount() {
    // Code to run before component unmounts
  }

  getFailuresStatusLegends = (schedules) => {
    const { FAILURE_STATUS } = TAG_TYPE;

    // if 'schedules' array is empty, return an empty array.
    if (schedules.length === 0) return [];

    // an array 'statusList' by flattening the 'showFailureStatus' property array of each 'schedule'.
    const statusList = compact(schedules.flatMap((schedule) => schedule.showFailureStatus ?? ""));

    // a function 'getStatusDisplayName' to retrieve the display name for a status.
    const getStatusDisplayName = (status) =>
      STATIC_FILTER_TAG_LIST[FAILURE_STATUS].find((d) => d.id === status).displayName;

    // an array 'statusLegends' that represents the legends for each failure status.
    const statusLegends = [
      {
        id: PENDING_SCHEDULE_DELIVERY,
        name: getStatusDisplayName(PENDING_SCHEDULE_DELIVERY),
        count: statusList.filter((d) => d === PENDING_SCHEDULE_DELIVERY).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PENDING_PLAYLOG_RETRIEVAL,
        name: getStatusDisplayName(PENDING_PLAYLOG_RETRIEVAL),
        count: statusList.filter((d) => d === PENDING_PLAYLOG_RETRIEVAL).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.YELLOW,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: UNOPTIMIZED,
        name: getStatusDisplayName(UNOPTIMIZED),
        count: statusList.filter((d) => d === UNOPTIMIZED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.RED,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: LOG_PENDING,
        name: getStatusDisplayName(LOG_PENDING),
        count: statusList.filter((d) => d === LOG_PENDING).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BLUE,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PROBLEMATIC,
        name: getStatusDisplayName(PROBLEMATIC),
        count: statusList.filter((d) => d === PROBLEMATIC).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: ACTIVE,
        name: getStatusDisplayName(ACTIVE),
        count: statusList.filter((d) => d === ACTIVE).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BROWN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: COMPLETED,
        name: getStatusDisplayName(COMPLETED),
        count: statusList.filter((d) => d === COMPLETED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.PURPLE,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PLANNED,
        name: getStatusDisplayName(PLANNED),
        count: statusList.filter((d) => d === PLANNED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BROWN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PLAYED,
        name: getStatusDisplayName(PLAYED),
        count: statusList.filter((d) => d === PLAYED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.YELLOW,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: SCHEDULED,
        name: getStatusDisplayName(SCHEDULED),
        count: statusList.filter((d) => d === SCHEDULED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: NOT_PLAYED,
        name: getStatusDisplayName(NOT_PLAYED),
        count: statusList.filter((d) => d === NOT_PLAYED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.YELLOW,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PENDING,
        name: getStatusDisplayName(PENDING),
        count: statusList.filter((d) => d === PENDING).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.RED,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: RETRIEVED,
        name: getStatusDisplayName(RETRIEVED),
        count: statusList.filter((d) => d === RETRIEVED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BLUE,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: RETRIEVING,
        name: getStatusDisplayName(RETRIEVING),
        count: statusList.filter((d) => d === RETRIEVING).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
    ];

    return statusLegends;
  };

  renderStatusIconLegend = () => {
    const {
      showFailureSpotStatusData: { showFailureSpotStatusData, isPartialLoading },
      isFilterLoading,
    } = this.props;
    // Call 'getFailuresStatusLegends' to obtain the legend information based on 'showFailureSpotStatusData'.
    const statusList = this.getFailuresStatusLegends(showFailureSpotStatusData?.data ?? []);

    // If loading or filtering is in progress, return a skeleton component.
    if (isPartialLoading || isFilterLoading) {
      return (
        <div
          className={classNames(
            "flex flex-auto flex-wrap",
            (isPartialLoading || isFilterLoading) && Classes.SKELETON
          )}
        />
      );
    }

    // If the sum of counts in 'statusList' is 0, return null.
    if (sum(statusList.map((status) => status.count)) === 0) return null;

    // Render the status icon legend component with the obtained 'statusList'.
    return (
      <div className={classNames("flex flex-auto flex-wrap align-center")}>
        <StatusIconLegend
          statusInfo={statusList}
          // onFilterSelect={this.props.onFilterSelect}
        />
      </div>
    );
  };

  render() {
    const {
      match: { params },
      ps,
      page,
      sort,
      filters,
      history,
      onQueryChange,
      onSortedChange,
      onFilterChange,
      onFilterSelect,
      onFilterIdSelect,
      showFailureSpotStatusData: { showFailureSpotStatusData, isLoading },
    } = this.props;

    const columns = [
      {
        id: "showDate",
        Header: "Date • Day",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([
            { id: d.showDate, name: formatDateTime(`${d.showDate}`) },
            { id: d.day, name: d.day },
          ]),
        width: 175,
        sortable: false,
      },
      {
        id: "startTime",
        Header: "Show Time",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([
            { id: d.startTime, name: d.startTime },
            {
              id: d.shows,
              name: d.shows,
            },
          ]),
        width: 150,
        sortable: false,
      },
      {
        id: "segment",
        Header: "Segment • Segment Type",
        accessor: (d) => (
          <>
            {ActiveTableCellRowFromArray(
              [
                { id: d.segmentId, name: d.segmentName },
                { id: d.segmentTypeId, name: d.segmentTypeName },
              ],
              onFilterIdSelect,
              constants.SPECIAL_CHARACTERS.MIDDLE_DOT
            )}
          </>
        ),
        width: 200,
        sortable: false,
      },
      {
        id: "position",
        Header: "Position",
        accessor: "position",
        width: 100,
        sortable: false,
      },
      {
        id: "spotStatus",
        Header: "Spot Status",
        accessor: "spotStatus",
      },
      {
        id: "contentStatus",
        Header: "Content Status",
        accessor: "contentStatus",
      },
      {
        id: "scheduleStatus",
        Header: "Schedule Status",
        accessor: "scheduleStatus",
      },
      {
        id: "playLogStatus",
        Header: "Playlog Status",
        accessor: "playLogStatus",
      },
    ];

    const subRowStruct = [
      [
        {
          id: "playlistTemplateName",
          Cell: (d) => (
            <>
              {ActiveTableCellRowFromArray(
                [
                  { id: d.playlistTemplateId, name: d.playlistTemplateName },
                  { id: d.playlistPackId, name: d.playlistPackName },
                ],
                onFilterIdSelect,
                constants.SPECIAL_CHARACTERS.PIPE
              )}
            </>
          ),
          row_flex: 2,
        },
        {
          id: "targetGroup",
          Cell: (d) => `Target Group: ${d.targetGroupName}`,
          row_flex: 2,
        },
        {
          id: "media",
          Cell: (d) => `Media: ${d.MediaName}`,
          row_flex: 1,
        },
        {
          id: "movie",
          Cell: (d) => `Movie: ${d.movie}`,
          row_flex: 1,
        },
      ],
      [
        {
          id: "cplName",
          Cell: (d) => ActiveTableCell(d.cplId, d.cplName, () => onFilterIdSelect(d.cplId)),
          row_flex: 2,
        },
        {
          id: "contentType",
          Cell: (d) => `Content Type: ${d.contentType}`,
          row_flex: 2,
        },
        {
          id: "durationInSeconds",
          Cell: (d) => `Duration: ${d.durationInSeconds}`,
          row_flex: 2,
        },
      ],
    ];

    const { selectedColumns, reorderedColumns } = this.state;

    return (
      <div>
        <Breadcrumb
          isLoading={isLoading}
          history={history}
          links={[
            { name: "ShowFailures Screens", path: "/show-failures" },
            {
              name:
                showFailureSpotStatusData.data && showFailureSpotStatusData.data[0]
                  ? `Screen: ${showFailureSpotStatusData.data[0].screenName}`
                  : "",
            },
          ]}
        />
        <PageHeader
          name="Spot Status"
          count={showFailureSpotStatusData.totalCount}
          renderRightSideComponent={() => (
            <SwitchButtonWithText
              buttons={[
                {
                  onClick: () => history.push(`/show-failures/${params.id}/schedules`),
                  text: "Button.schedules",
                },
                {
                  isActive: true,
                  text: "Button.spotstatus",
                },
              ]}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <div
              className={classNames(
                "flex flex-auto flex-wrap",
                pageStyles.statusGroup,
                true && pageStyles.statusGroupLoading
              )}
            >
              {this.renderStatusIconLegend()}
            </div>
            <TableControls
              columnFilter
              columns={columns}
              data={showFailureSpotStatusData}
              filters={filters}
              onColumnFilterSelect={this.onColumnSelect}
              onFilterChange={
                filters.length === 0 ? onFilterChange(this.getDefaultFilters()) : onFilterChange
              }
              onFilterSelect={onFilterSelect}
              onSearchFilterSelect={onFilterSelect}
              onRowSizeChange={onQueryChange}
              page={page}
              pagination
              ps={ps}
              reorderedColumns={reorderedColumns}
              searchBar
              selectedColumns={selectedColumns}
              showRowSize
              tagTypes={[
                TAG_TYPE.DATE_RANGE,
                TAG_TYPE.SEGMENT_TYPE,
                TAG_TYPE.SPOT_STATUS,
                // TAG_TYPE.CONTENT_STATUS,
                // TAG_TYPE.SCHEDULE_STATUS,
                TAG_TYPE.PLAYLOG_STATUS,
              ]}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={showFailureSpotStatusData.totalCount}
              onFilterChange={
                filters.length === 0 ? onFilterChange(this.getDefaultFilters()) : onFilterChange
              }
              loading={isLoading}
            />
            <Table
              data={showFailureSpotStatusData.data}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              loading={false}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={<TableLoading columns={columns} />}
              SubComponent={
                //if subRowStructure is not empty, then render subComponent
                (row) => {
                  return (
                    <ShowFailuresSubComponent data={row.original} subRowStruct={subRowStruct} />
                  );
                }
              }
            />
            <FooterControls
              pagination
              data={showFailureSpotStatusData}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const ShowFailuresSpotStatusListWithFilters = WithFetchList("getShowFailureSpotStatusData", {
  sort: [{ id: "name", desc: true }],
})(ShowFailuresSpotStatus);
const mapStateToProps = createSelector(
  (state) => state.showFailureSpotStatus,
  (state) => state.userData,
  (showFailureSpotStatusData, userData) => ({ showFailureSpotStatusData, userData })
);

export default connect(mapStateToProps, bindDispatch)(ShowFailuresSpotStatusListWithFilters);
