const IS_LOADING = "inventory/companies/IS_LOADING";
const IS_ERROR = "inventory/companiesIS_ERROR";
const COMPANIES = "inventory/companies/COMPANIES";
const IS_ADVERTISER_LOADING = "inventory/companies/IS_ADVERTISER_LOADING";
const ADVERTISERS = "inventory/companies/ADVERTISERS";
const ADVERTISER = "inventory/companies/ADVERTISER";
const IS_MEDIA_AGENCIES_LOADING = "inventory/companies/IS_MEDIA_AGENCIES_LOADING";
const MEDIA_AGENCIES = "inventory/companies/MEDIA_AGENCIES";
const MEDIA_AGENCY = "inventory/companies/MEDIA_AGENCY";
const MEDIA_AGENCY_CONTENTS = "inventory/companies/MEDIA_AGENCY_CONTENTS";
const IS_EXHIBITORS_LOADING = "inventory/companies/IS_EXHIBITORS_LOADING";
const IS_CONTENTS_LOADING = "inventory/companies/IS_CONTENTS_LOADING";
const EXHIBITORS = "inventory/companies/EXHIBITORS";
const IS_COMPANY_SETTING_LOADING = "inventory/companies/IS_COMPANY_SETTING_LOADING";
const EXHIBITOR = "inventory/companies/EXHIBITOR";
const THEIRD_PARTY_IDENTIFIERS = "inventory/companies/THEIRD_PARTY_IDENTIFIERS";
const IS_ADVERTISER_SETTINGS_LOADING = "inventory/companies/IS_ADVERTISER_SETTINGS_LOADING";
const ADVERTISER_SETTINGS = "inventory/companies/ADVERTISER_SETTINGS";
const ADD_OR_EDIT_ADVERTISER = "inventory/companies/lists/advertiser/ADD_OR_EDIT_ADVERTISER";
export {
  IS_LOADING,
  IS_ERROR,
  COMPANIES,
  IS_ADVERTISER_LOADING,
  ADVERTISERS,
  ADVERTISER,
  IS_MEDIA_AGENCIES_LOADING,
  MEDIA_AGENCIES,
  MEDIA_AGENCY,
  MEDIA_AGENCY_CONTENTS,
  IS_EXHIBITORS_LOADING,
  IS_COMPANY_SETTING_LOADING,
  IS_CONTENTS_LOADING,
  EXHIBITORS,
  EXHIBITOR,
  THEIRD_PARTY_IDENTIFIERS,
  IS_ADVERTISER_SETTINGS_LOADING,
  ADVERTISER_SETTINGS,
  ADD_OR_EDIT_ADVERTISER,
};
