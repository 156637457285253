import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import PageHeader from "../../../common/PageHeader";
import styles from "../../../../styles/Lists/Lists.module.scss";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import ActionIcon from "../../../common/ActionIcon";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import EditContentType from "./EditContentType";

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.contentType" }];

export class ContentTypes extends Component {
  state = {
    selectedColumns: ["Last Updated On", "Last Updated By", "Code", "Name", "Description"],
  };

  componentDidMount = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addContentType();
        actions.getContentTypesList();
      } else {
        actions.editContentTypeById(params.action);
      }
    } else actions.getContentTypesList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  addContentType = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addContentType();
    history.replace(`/lists/content-types/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditContentType();
    history.replace(`/lists/content-types${location.search}`);
  };

  editContentType = (contentType) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editContentType(contentType);
    history.replace(`/lists/content-types/${contentType.id}${location.search}`);
  };

  render = () => {
    const {
      contentType: { data, isLoading, contentTypeEdit },
      history,
      actions,
      userData,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, contentType } = contentTypeEdit;
    const columns = checkScopes(
      [
        {
          id: "code",
          Header: "Code",
          accessor: "code",
        },
        {
          id: "name",
          Header: "Name",
          accessor: "name",
        },
        {
          id: "description",
          Header: "Description",
          accessor: (d) => <span className={styles.truncate}>{d.description}</span>,
        },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.CONTENT_TYPES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editContentType(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.CONTENT_TYPES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addContentType,
      userData,
    };

    return (
      <div className={classNames("col-12 clearfix")}>
        <Breadcrumb history={history} links={links} />
        <PageHeader
          isLoading={isLoading}
          name="PageHeader.contentType"
          count={data.totalCount}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              logsButton
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CONTENT_TYPES}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              data={data.data || []}
              sortable={false}
              pageSize={data.data ? data.data.length : 5}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              LoadingComponent={<TableLoading columns={columns} />}
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        {isOpen && (
          <EditContentType
            isOpen={isOpen}
            onClose={this.cancelEdit}
            actions={actions}
            contentType={contentType}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.contentType,
  (state) => state.userData,
  (contentType, userData) => ({ contentType, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ContentTypes);
