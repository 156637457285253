import React, { Component } from "react";
import classNames from "classnames";
import { flatten, compact, uniqWith, isEmpty } from "lodash";
import { Icons } from "prefab";
import { getStatusDisplayName } from "../../../../utils/screens/statusLegend";
import * as constants from "../../../../constants";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import PageHeader from "../../../common/PageHeader";
import { lastUpdatedAtColumn, locationColumn } from "../../../../utils";
import SwitchButtonWithText from "../../../common/SwitchButtonWithText";
import ActionIcon from "../../../common/ActionIcon";
import StatusIcon from "../../../common/StatusIcon";
import TheatreTable from "../../../tables/Theatre";
import styles from "../../../../styles/Inventory/TheatreList.module.scss";

import { ActiveTableCell, ActiveTableCellColumnFromArray } from "../../../common/ActiveTableCell";

const { LogsIcon, ViewIcon } = Icons;

export default class TheatresList extends Component {
  state = {
    selectedColumns: ["Theatre / Screen", "Chain", "Location", "Theatre ID"],
  };

  componentDidMount = () => {
    this.props.fetchData(null, false, [{ companyId: this.props.userData.company.id }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  handleViewButtonClick = (original, viewType) => {
    const { history } = this.props;
    history.push(`/${viewType}/${original.id}/details`);
  };

  combineScreenProperties = (data, accessor, property) => {
    return uniqWith(
      flatten(compact(data.screens.map((screen) => screen[accessor]))),
      (x, y) => x[property] === y[property]
    );
  };

  renderCompaniesAssociated = (d) => {
    return <div>{ActiveTableCellColumnFromArray(d.companies, this.props.onFilterIdSelect)}</div>;
  };

  renderDropdown = (d) => {
    const { userData, history } = this.props;
    return (
      <div className={classNames("col-12 flex align-center", styles.actionItems)}>
        <div className={classNames("flex align-center", styles.moreItems)}>
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.moreActions",
                iconName: "MoreVerticalIcon",
                dropdown: [
                  {
                    text: "View",
                    onClick: () => history.push(`/theatres/${d.id}/details`),
                    icon: <ViewIcon />,
                  },
                  {
                    text: "Logs",
                    onClick: () => {
                      this.props.history.push(`/logs/ref/${d.id}`);
                    },
                    icon: <LogsIcon />,
                    scope: constants.SCOPES.LOGS,
                    userData: userData,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };

  renderStatusIcon = (cellProps) => {
    const { activationStatuses } = cellProps;
    if (isEmpty(activationStatuses) || this.props.isAdminApp) return null;

    const {
      SCREEN_STATUSES: { ACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
      STATUS_ICON_COLORS: { GREEN, GREY },
      STATUS_ICON_TYPES: { DURATION, CIRCLE },
    } = constants;
    let screenStatus;
    if (activationStatuses.length === 1) {
      [screenStatus] = activationStatuses;
    } else if (activationStatuses.length > 1) {
      [screenStatus] = activationStatuses.filter((status) =>
        [ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(status)
      );
    }

    return (
      <div
        className={classNames("flex align-center", styles.status)}
        onClick={(event) => {
          event.stopPropagation();
          this.props.onFilterSelect({
            id: screenStatus,
            displayName: getStatusDisplayName(screenStatus),
            type: constants.TAG_TYPE.SCREEN_STATUS,
          });
        }}
      >
        <StatusIcon
          toolTipClass={styles.statusTooltip}
          color={[ACTIVE, INACTIVE_IN_FUTURE].includes(screenStatus) ? GREEN : GREY}
          type={[ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(screenStatus) ? DURATION : CIRCLE}
          showToolTip={false}
        />
      </div>
    );
  };

  renderScreenStatusWithActions = (cellProps) => {
    const { userData, history } = this.props;
    return (
      <div className={classNames("col-12 flex align-center", styles.actionItems)}>
        {this.renderStatusIcon(cellProps)}
        <div className={classNames("flex align-center", styles.moreItems)}>
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.moreActions",
                iconName: "MoreVerticalIcon",
                dropdown: [
                  {
                    text: "View",
                    onClick: () => history.push(`/screens/${cellProps.id}/details`),
                    icon: <ViewIcon />,
                  },
                  {
                    text: "Logs",
                    onClick: () => history.push(`/logs/ref/${cellProps.id}`),
                    icon: <LogsIcon />,
                    scope: constants.SCOPES.LOGS,
                    userData: userData,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };

  renderPlaylistTemplate = (playlists) => {
    if (!playlists) return;
    return playlists.map((playlist) => playlist.name);
  };

  renderRights = (rights) => {
    if (!rights) return;
    return rights.map((right, i) => (
      <div key={i}>
        {right.rightName}
        {right.rightName && right.playlistPackName && <span>&nbsp;·&nbsp;</span>}
        {right.playlistPackName}
        {right.playlistPackName && right.segmentName && <span>&nbsp;·&nbsp;</span>}
        {right.segmentName}
      </div>
    ));
  };

  getTheatreColumns = () => {
    return [
      {
        id: "name",
        Header: "Theatre / Screen",
        parentAccessor: "name",
        childAccessor: "name",
      },
      {
        id: "chainName",
        Header: "Chain",
        accessor: (d) =>
          ActiveTableCell(d.chainId, d.chainName, () => this.props.onFilterIdSelect(d.chainId)),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "companies",
        Header: "Companies Associated",
        accessor: (d) => this.renderCompaniesAssociated(d),
      },
      {
        id: "playlistTemplate",
        Header: "Playlist Template",
        parentAccessor: (d) =>
          this.renderPlaylistTemplate(this.combineScreenProperties(d, "playlistTemplates", "id")),
        sortable: false,
      },
      {
        id: "rights",
        Header: "Rights",
        parentAccessor: (d) =>
          this.renderRights(this.combineScreenProperties(d, "rights", "rightName")),
        sortable: false,
      },
      {
        id: "id",
        Header: "Theatre ID",
        parentAccessor: "id",
        sortable: false,
      },
      {
        id: "screenId",
        Header: "Screen ID",
        childAccessor: "id",
        sortable: false,
      },
      {
        id: "erpId",
        Header: "Screen ERP ID",
        childAccessor: "erpId",
        sortable: false,
      },
      {
        id: "davpCode",
        Header: "DAVP TH Code",
        childAccessor: "davpCode",
        sortable: false,
      },
      lastUpdatedAtColumn(),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        parentAccessor: (d) => this.renderDropdown(d),
        childAccessor: (d) => this.renderScreenStatusWithActions(d),
      },
    ];
  };

  render = () => {
    const {
      history,
      theatres: { theatres, isLoading, isPartialLoading },
      isFilterLoading,
    } = this.props;
    const { THEATRE } = constants.TAG_TYPE;

    if (isLoading) return <PageLoadingWithTable />;

    return (
      <div>
        <PageHeader
          name="PageHeader.theatres"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={theatres.totalCount}
          renderRightSideComponent={() => (
            <SwitchButtonWithText
              buttons={[
                {
                  isActive: true,
                  text: "Button.theatres",
                },
                {
                  onClick: () => history.push("/screens"),
                  text: "Button.screens",
                },
              ]}
            />
          )}
        />
        <TheatreTable
          {...this.props}
          theatreList={theatres}
          isFilterLoading={isFilterLoading}
          isPartialLoading={isPartialLoading}
          columns={this.getTheatreColumns()}
          tagTypes={[THEATRE]}
        />
      </div>
    );
  };
}
