/*
1.The code imports a set of action types from another file.
2.It defines an initial state object with three properties: isLoading, iserror, and failureData.
3.It defines a reducer function that takes in a state and an action and returns a new state based on the action type and payload.
4.The reducer function updates the isLoading, failureData, and isError properties based on the action type and payload.
*/
import * as types from "../types/showFailures";

const initialState = {
  isLoading: true,
  iserror: false,
  failureData: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.FAILURE_DATA:
      return { ...state, failureData: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    default:
      return state;
  }
}
