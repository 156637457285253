import React, { Component } from "react";
import PropTypes from "prop-types";
import { NumericInput } from "@blueprintjs/core";

import styles from "../../styles/InputRangeSelector.module.scss";

export default class InputRangeSelector extends Component {
  static propTypes = {
    value: PropTypes.number,
    separator: PropTypes.string,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    minimumPlaceholder: PropTypes.string,
    maximumPlaceholder: PropTypes.string,
    buttonPosition: PropTypes.oneOf(["right", "left", "none"]),
  };
  static defaultProps = {
    buttonPosition: "none",
    separator: "to",
    minimumPlaceholder: "Min Range",
    maximumPlaceholder: "Max Range",
    title: "",
  };
  render() {
    const {
      separator,
      minimumPlaceholder,
      maximumPlaceholder,
      minValue,
      maxValue,
      onChange,
      title,
      ...rest
    } = this.props;

    return (
      <div className={styles.rangeInput}>
        <div className={styles.inputContainer}>
          <NumericInput
            {...rest}
            id="min"
            value={minValue}
            stepSize={1}
            onValueChange={(data) => onChange(data, "rangeFrom")}
            placeholder={minimumPlaceholder}
          />
        </div>
        <div className={styles.separator}>{separator}</div>
        <div className={styles.inputContainer}>
          <NumericInput
            {...rest}
            id="max"
            value={maxValue}
            stepSize={1}
            onValueChange={(data) => onChange(data, "rangeTo")}
            placeholder={maximumPlaceholder}
          />
        </div>
      </div>
    );
  }
}
