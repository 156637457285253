import {
  fetchAdvertiserTypes,
  updateAdvertiserType as updateAdvertiserTypeAPI,
  fetchAdvertiserTypesById,
} from "../../api";
import * as types from "../types/advertiserTypes";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateAdvertiserType = (payload) => {
  return { type: types.ADVERTISERLIST, payload: payload };
};

const getAdvertiserTypes = (
  params = { sort: "rank:asc" },
  filters = [],
  getList = fetchAdvertiserTypes
) => {
  const { sort } = params;
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const advertiserList = await getList(sort);
      dispatch(updateAdvertiserType(advertiserList));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error));
    }
  };
};

const editAdvertiserType = (advertiserType) => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { advertiserType, isOpen: true }));

const addAdvertiserType = () => (dispatch) =>
  dispatch(
    actionCreator(types.EDIT, {
      isOpen: true,
      advertiserType: { name: "", rank: "" },
    })
  );

const cancelEditAdvertiserType = () => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { isOpen: false, advertiserType: {} }));

const editAdvertiserTypeById = (advertiserTypeId, get = fetchAdvertiserTypesById) => {
  return async (dispatch) => {
    try {
      const { data: advertiserType } = await get(advertiserTypeId);
      dispatch(editAdvertiserType(advertiserType));
    } catch (error) {
      dispatch(actionCreator(types.IS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const addOrUpdateAdvertiserType = (
  advertiserTypes,
  save = updateAdvertiserTypeAPI,
  getUpdatedAdvertiserList = getAdvertiserTypes
) => {
  return async (dispatch) => {
    try {
      await save(advertiserTypes);
      await dispatch(getUpdatedAdvertiserList());
      showToast(advertiserTypes.id ? "Toast.advertiserTypeUpdated" : "Toast.advertiserTypeAdded");
    } catch (error) {
      showToast(error.message, false);
      await dispatch(getUpdatedAdvertiserList());
      return error;
    }
  };
};

export {
  updateAdvertiserType,
  getAdvertiserTypes,
  editAdvertiserType,
  addAdvertiserType,
  cancelEditAdvertiserType,
  editAdvertiserTypeById,
  addOrUpdateAdvertiserType,
};
