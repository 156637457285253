import { flatten } from "lodash";
import moment from "moment";
import React from "react";
import styles from "../../../../../src/styles/Reports/ReportCard.module.scss";
import { TAG_TYPE } from "../../../../constants";
import { showToast } from "../../../../utils";
import LocalizedButton from "../../../common/LocalizedButton";

export default class ScheduledReportCard extends React.Component {
  state = {
    timePeriod: {
      fromDate: null,
      toDate: null,
    },
    conditions: [
      {
        type: "is Between",
        id: 1,
      },
    ],
    filters: [],
    filterTypes: [],
    conditionType: "",
    loaderValue: 0,
    isFormEdited: false,
    isDownloadProcessStarted: false,
    isOpen: false,
    error: {
      timePeriodError: false,
      conditionError: false,
      generationFail: false,
    },
  };
  componentDidMount() {
    const {
      report: { filters, filterTypes },
    } = this.props;

    this.setState({ filters, filterTypes });
  }

  /**
   * Closes the report card and resets the state.
   */
  onClose = () => {
    //reset the state
    if (this.state.loaderValue !== 100) {
      this.setState({
        isDownloadProcessStarted: false,
        error: { timePeriodError: false, conditionError: false },
      });
      return;
    }
    //close the popup
    this.setState({
      isDownloadProcessStarted: false,
      loaderValue: 0,
      error: { timePeriodError: false, conditionError: false },
    });
  };

  // Close the panel
  closePanel = () => this.setState({ isOpen: false });

  // Toggle the panel
  togglePanel = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  onFilterSelect = (e) => {
    let { filters } = this.state;
    //check if the filter is already selected
    const index = filters.findIndex((f) => f.id === e.id);

    //check if the filter is a date range
    switch (e.type) {
      case TAG_TYPE.DATE_RANGE: {
        //check if the filter is already selected
        if (this.DateRangeInDays(e.from, e.to) > 61) {
          showToast("Date range should not exceed 60 days", false, 5000);
          return;
        }
        if (index >= 0) filters[index] = { ...e };
        else filters.push(e);
        break;
      }
      default: {
        //check if the filter is already selected
        if (index >= 0) {
          filters = filters.filter((f) => f.id !== e.id);
        } else {
          //add the filter to the list
          filters.push(e);
        }
        break;
      }
    }

    this.setState({ filters });
  };

  /**
   * Clears the filter based on the specified tag type.
   * @param {string} tagType - The type of tag to be cleared.
   */
  onFilterClear = (tagType) => {
    const { filters } = this.state;
    //remove the filter based on the tag type
    this.setState({
      filters: filters.filter((f) => !flatten([tagType]).includes(f.type)),
    });
  };

  DateRangeInDays = (fromDate, toDate) => {
    //return false if the date range is empty
    if (!fromDate || !toDate) return false;
    //get the date range in days
    const dateRange = moment(toDate).diff(moment(fromDate), "days");
    //return the date range
    return dateRange;
  };

  /**
   * Renders a progress bar based on the loaderValue and error state.
   * @returns {JSX.Element} The rendered progress bar component.
   */
  progressBar = () => {
    const { loaderValue, error } = this.state;

    //return the progress bar
    return (
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{
            width: `${loaderValue}%`,
            background: `${
              loaderValue !== 100 ? "#0a5ecc" : error.generationFail ? "#f11717" : "#0acc31"
            }`,
          }}
        ></div>
      </div>
    );
  };

  render() {
    const {
      report: {
        name = "",
        isPrivate = false,
        status,
        totalGeneratedReports = 0,
        lastGeneratedON,
        schedulerType,
        schedulerStartDate,
        schedulerEndDate,
        startTime,
        filters = [],
        mailTo,
      },
    } = this.props;

    return (
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <div className={styles.cardLogo}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00757 17.543H16.9924C18.3053 17.543 19 16.8425 19 15.5472V8.40006C19 7.10486 18.3053 6.41016 16.9924 6.41016H7.00757C5.70059 6.41016 5 7.10486 5 8.40006V15.5472C5 16.8483 5.70059 17.543 7.00757 17.543ZM6.34819 8.54725C6.34819 8.0115 6.61901 7.75835 7.1312 7.75835H11.476V9.87189H6.34819V8.54725ZM12.524 9.87189V7.75835H16.8688C17.3751 7.75835 17.6518 8.0115 17.6518 8.54725V9.87189H12.524ZM12.524 13.0334V10.9198H17.6518V13.0334H12.524ZM11.476 10.9198V13.0334H6.34819V10.9198H11.476ZM16.8688 16.1948H12.524V14.0813H17.6518V15.4001C17.6518 15.9358 17.3751 16.1948 16.8688 16.1948ZM7.1312 16.1948C6.61901 16.1948 6.34819 15.9358 6.34819 15.4001V14.0813H11.476V16.1948H7.1312Z"
                fill="#0A5ECC"
              />
            </svg>
          </div>
          <div className={styles.title}>
            <div className={styles.cardTitle}>
              {name}
              {status && (
                <div className={styles.status}>
                  <div
                    className={styles.statusIndicator}
                    style={{ backgroundColor: status === "Active" ? "#047b24" : "#adbbcc" }}
                  ></div>
                  {status}
                </div>
              )}
            </div>
            <div className={styles.titleTypes}>
              {!isPrivate && (
                <div className={styles.titleType}>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.28666 4.61087C9.84497 4.96123 10.2366 5.55344 10.3078 6.24153C10.5351 6.34739 10.7883 6.40842 11.0561 6.40842C12.0341 6.40842 12.8271 5.61598 12.8271 4.63818C12.8271 3.65963 12.0341 2.86719 11.0561 2.86719C10.0872 2.86719 9.30135 3.64551 9.28666 4.61087ZM8.12615 8.23687C9.10489 8.23687 9.89733 7.44442 9.89733 6.46588C9.89733 5.48808 9.10489 4.69563 8.12615 4.69563C7.14836 4.69563 6.35516 5.48808 6.35516 6.46663C6.35516 7.44442 7.14836 8.23687 8.12615 8.23687ZM8.87753 8.35761H7.37496C6.12479 8.35761 5.10725 9.37515 5.10725 10.6253V12.4634L5.11233 12.492L5.23891 12.5317C6.43163 12.9041 7.46839 13.0285 8.32186 13.0285C9.9885 13.0285 10.9539 12.5538 11.0141 12.5229L11.1319 12.4634H11.1445V10.6253C11.1452 9.37515 10.1275 8.35761 8.87753 8.35761ZM11.8073 6.52992H10.3166C10.3004 7.1259 10.046 7.66368 9.64267 8.05001C10.7546 8.3804 11.5676 9.41056 11.5676 10.6283V11.1942C13.0398 11.1403 13.8882 10.7233 13.9441 10.6952L14.0619 10.6349H14.0751V8.79688C14.0751 7.54671 13.0575 6.52992 11.8073 6.52992ZM5.0189 6.40918C5.36549 6.40918 5.68778 6.30765 5.96072 6.13549C6.04756 5.56964 6.35158 5.07443 6.78481 4.73745C6.78632 4.70449 6.79009 4.67209 6.79009 4.63818C6.79009 3.66039 5.99689 2.86719 5.0189 2.86719C4.04111 2.86719 3.24791 3.66039 3.24791 4.63818C3.24791 5.61598 4.04111 6.40918 5.0189 6.40918ZM6.60982 8.05001C6.2088 7.66519 5.95488 7.13099 5.93642 6.53802C5.88123 6.53425 5.82604 6.52992 5.77029 6.52992H4.26771C3.01679 6.52992 2 7.54671 2 8.79688V10.6349L2.00433 10.6636L2.13091 10.7033C3.08818 11.002 3.94241 11.1397 4.68494 11.1831V10.6283C4.68494 9.41056 5.49791 8.3804 6.60982 8.05001Z"
                        fill="#ADBBCC"
                      />
                    </svg>
                  </div>
                  <div>Shared</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {filter chips are hidden } */}
        {/* <div className={styles.chipContainer}>
          {filters.map((s) => (
            <div className={styles.chip}>{`${s.tag} = ${s.name}`}</div>
          ))}
        </div> */}
        <div className={styles.buttonBox}>
          <LocalizedButton
            iconName="FilterAdjustIcon"
            className={styles.buttonStyleScheduled}
            text={`Filters: ${filters.length}`}
            key={0}
          />
          <LocalizedButton
            iconName="CalendarIcon"
            className={styles.buttonStyleScheduled}
            text={`Time Period: ${
              schedulerStartDate ? new Date(schedulerStartDate).toString().slice(4, 16) : ""
            }${
              schedulerEndDate
                ? `-${new Date(schedulerEndDate).toString().slice(4, 16)}`
                : "- Present"
            }`}
            key={1}
          />
        </div>
        <div className={styles.scheduleTypes}>
          {schedulerType && (
            <div>
              Schedule Type: <span style={{ fontWeight: 500 }}>{schedulerType}</span> |
            </div>
          )}
          {schedulerStartDate && (
            <div>
              Schedule Start Date:{" "}
              <span style={{ fontWeight: 500 }}>
                {new Date(schedulerStartDate).toString().slice(4, 16)}
              </span>{" "}
              |
            </div>
          )}
          {startTime && (
            <div>
              Schedule Time: <span style={{ fontWeight: 500 }}>{startTime}</span>{" "}
            </div>
          )}
        </div>
        {mailTo && (
          <div className={styles.emails}>
            <div>To:</div>
            {mailTo.split(",").map((m) => (
              <a href={`mailto:${m}`} className={styles.email}>
                {m}
              </a>
            ))}
          </div>
        )}
        <div className={styles.dividerLine}></div>
        <div className={styles.bottomContainer}>
          <LocalizedButton
            iconName="EditIcon"
            className={styles.buttonStyleScheduled}
            text="Edit Report Details"
            disabled={true}
          />
          <div className={styles.bottomRightBox}>
            <div>Total Generated Reports : {totalGeneratedReports} </div>
            {lastGeneratedON && <div>Last Generated On : {lastGeneratedON} </div>}
          </div>
        </div>
      </div>
    );
  }
}
