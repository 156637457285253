import { CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST } from "../../../../constants";

//function to validate if for the actionType passed all the selected campaigns are in the allowed status list
//function used to validate for bulk actions if a action can be performed on the selected campaigns or not
const validateCampaignsAction = (dataList, selection, actionType) => {
  //list of campaigns which are selected and are in the allowed status list for the passed actionType
  const selectedCampaigns = dataList?.data?.filter(
    (campaign) =>
      selection.parentSelection.includes(campaign.id) &&
      CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[actionType].includes(campaign.status)
  );
  return selectedCampaigns?.length === selection.parentSelection.length;
};

export default validateCampaignsAction;
