import React, { Component } from "react";
import { BRAND_TAG_TYPE } from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";

export default class BrandVerificationBreadcrumbs extends Component {
  getBreadCrumbLinks = () => {
    const {
      brandVerifications: { brandVerification, brandVerificationDraft },
      match: { params },
    } = this.props;
    if (params.mergeId) {
      const brandToMerge = brandVerificationDraft.find((b) => b.id === params.mergeId);
      if (!brandToMerge) return [{ name: "Merge" }];
      else {
        return [
          { name: "Brands", path: `/brands/parent-brands` },
          {
            name: brandToMerge.parentBrandName,
            path: `/brands/parent-brands/${brandToMerge.parentBrandId}/details`,
          },
          {
            name: brandToMerge.individualBrandName,
            path: `/brands/individual-brands/${brandToMerge.individualBrandId}/details`,
          },
          {
            name: brandToMerge.productName,
            path: `/brands/products/${brandToMerge.productId}/details`,
          },
          {
            name: brandToMerge.name,
            path: `/brands/${BRAND_TAG_TYPE[brandToMerge.brand]}/${brandToMerge.id}/details`,
          },
          { name: "Merge" },
        ].filter((b) => b.name);
      }
    } else
      return [
        { name: "PageHeader.brandVerifications", path: "/brand-verifications" },
        { name: brandVerification.parentBrandName },
      ];
  };

  render() {
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={this.props.history} links={this.getBreadCrumbLinks()} />
      </div>
    );
  }
}
