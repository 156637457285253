import React from "react";
import { startCase } from "lodash";
import styles from "../../../../styles/CampaignCreate/EmptyState.module.scss";

const EmptyState = ({ type }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Invalid {startCase(type)}</div>
      {<div className={styles.body}>No Data Found</div>}
    </div>
  );
};

export default EmptyState;
