import React from "react";

import { Classes } from "@blueprintjs/core";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";

const CardSkeletonLoader = () => (
  <div className={`clearfix ${styles.cardContainer} ${styles.panelLoader}`}>
    <div className={styles.header}>
      <div className={styles.contentWrapper}>
        <div className={styles.titleContent}>
          <div className={`inline ${Classes.SKELETON} ${styles.title}`}>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <span className={`inline ${Classes.SKELETON}`}>&emsp;&emsp;</span>
      </div>
    </div>
    <div className={styles.cardItemWrapper}>
      <div className={`flex ${styles.itemContainer}`}>
        <div className={`inline ${styles.labelContainer}`}>
          <div className={`inline ${Classes.SKELETON} ${styles.label}`}>
            &emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
        <div className={`inline ${Classes.SKELETON} ${styles.infoValue}`}>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
      </div>
      <div className={`flex ${styles.itemContainer}`}>
        <div className={`inline ${styles.labelContainer}`}>
          <div className={`inline ${Classes.SKELETON} ${styles.label}`}>
            &emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
        <div className={`inline ${Classes.SKELETON} ${styles.infoValue}`}>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
      </div>
      <div className={`flex ${styles.itemContainer}`}>
        <div className={`inline ${styles.labelContainer}`}>
          <div className={`inline ${Classes.SKELETON} ${styles.label}`}>
            &emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
        <div className={`inline ${Classes.SKELETON} ${styles.infoValue}`}>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
      </div>
      <div className={`flex ${styles.itemContainer}`}>
        <div className={`inline ${styles.labelContainer}`}>
          <div className={`inline ${Classes.SKELETON} ${styles.label}`}>
            &emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
        <div className={`inline ${Classes.SKELETON} ${styles.infoValue}`}>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
      </div>
    </div>
  </div>
);

export default CardSkeletonLoader;
