import React, { Component } from "react";
import PropTypes from "prop-types";
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { startCase, flatten, uniqWith, isEqual } from "lodash";
import { BUTTON_TYPE } from "prefab";
import { FORM_FIELD_TYPE, CONTENT_STANDARD } from "../../../../constants";
import {
  searchCertificates,
  typeOfContent,
  getLanguages,
  pictureTypes,
  aspectRatios,
  audioTypes,
  resolutionTypes,
} from "../../../../api";
import { getCategoryId } from "../../../../utils";
import RightPanel from "../../../common/RightPanel";
import { SearchIcon } from "../../../common/SearchIcon";
import { CustomMenuItem } from "../../../common";
import RemoveIcon from "../../../common/RemoveIcon";
import LocalizedButton from "../../../common/LocalizedButton";
import styles from "../../../../styles/Content/Content.module.scss";
import Form from "../../../common/Form";
import MapBrand from "../common/MapBrand";

export default class EditContent extends Component {
  state = {
    hasEditError: false,
    content: this.props.content,
  };
  static props = {
    content: PropTypes.object.isRequired,
    mapBrand: PropTypes.object,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  };
  componentWillUnmount = () => {
    this.setState({
      content: {},
    });
  };
  mapContentWitBrand = (map) => {
    const { actions, onCancel } = this.props;
    const { content } = this.state;
    actions.updateContentDetails(
      {
        ...content,
        ...{
          mappedResourceId: map.mappedResource.id,
          mappedResourceType: map.mappedResource.type,
          advertisers: map.advertisers,
        },
      },
      () => onCancel()
    );
  };
  updateContentDetails = () => {
    const { actions, onCancel } = this.props;
    const { content } = this.state;
    actions.updateContentDetails(
      {
        ...content,
        ...{
          advertiserIds: content.advertisers ? content.advertisers.map((a) => a.id) : [],
          categoryIds: content.categories
            ? content.categories.map((c) => getCategoryId(c)).filter((c) => c)
            : [],
          subtitles: content.subtitles ? content.subtitles.map((a) => a.languageId) : [],
        },
      },
      () => onCancel()
    );
  };
  mappedBrand = (content) => {
    return {
      id: content[`${content.mappedResourceType}Id`],
      name: content[`${content.mappedResourceType}Name`],
      type: content.mappedResourceType,
      tag: startCase(content.mappedResourceType),
    };
  };
  inputChange = (key, value) => {
    const { content } = this.state;
    this.setState({ content: { ...content, [key]: value } });
  };
  onCertificationChange = (data, index) => {
    const { content } = this.state;
    content.countryCertifications[index] = { ...content.countryCertifications[index], ...data };
    this.setState({ content });
  };
  addCertification = () => {
    const { content } = this.state;
    this.setState({
      content: {
        ...content,
        countryCertifications: [
          ...content.countryCertifications,
          { countryId: "", countryName: "" },
        ],
      },
    });
  };
  // Should change the MULTI_SELECT as a API_MULTI_SELECT.
  // All the multi-select related things should move to separate utils.
  rendersubtitleMenuItem = (data, { modifiers, handleClick }) => (
    <CustomMenuItem
      key={data.id}
      active={modifiers.active}
      icon={this.isSelectedSubtitle(data) ? IconNames.TICK : IconNames.BLANK}
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  isSelectedSubtitle = (data) => {
    const { content } = this.state;
    return content.subtitles && content.subtitles.find((t) => t.languageId === data.id);
  };
  removeItem = (itemIndex, key) => {
    const { content } = this.state;
    this.setState({
      content: {
        ...content,
        ...{ [key]: content[key].filter((item, index) => index !== itemIndex) },
      },
    });
  };
  clearMultiSelect = (key) => {
    const { content } = this.state;
    this.setState({
      content: {
        ...content,
        ...{ [key]: [] },
      },
    });
  };
  renderCertificationForm = () => {
    const { content } = this.state;
    const formConfig =
      content.countryCertifications &&
      content.countryCertifications.map((certification, index) => {
        return [
          {
            key: "certificationId",
            type: FORM_FIELD_TYPE.API_SEARCH,
            title: "Territory & Certification",
            placeholder: "Search...",
            onSelect: (props) => {
              this.onCertificationChange(
                {
                  certificationId: props.item.certificationId,
                  countryId: props.item.countryId,
                  countryName: props.item.countryName,
                },
                index
              );
            },
            query: certification.countryName,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return (
                data.data &&
                data.data.map((item) => {
                  return {
                    id: item.id,
                    name: item.countryName,
                    tag: item.childSafetyTag,
                    item: item,
                  };
                })
              );
            },
            fetchAction: (text) => searchCertificates(text),
          },
          {
            type: FORM_FIELD_TYPE.API_SELECT,
            id: "certificationId",
            title: "",
            label: "",
            placeholder: "Select certification",
            fetchAction: searchCertificates,
            parseResults: (response) => {
              return [
                ...response.data.map((item) => ({
                  id: item.id,
                  value: item.code,
                })),
              ];
            },
            selected: (list) => list.filter((item) => item.id === certification.certificationId),
            singleSelect: true,
            showLabelInButton: false,
            onSelect: ([value]) => {
              this.onCertificationChange(
                {
                  certificationId: value.id,
                },
                index
              );
            },
            disabled: certification.countryId ? false : true,
          },
        ];
      });
    return (
      <div className={styles.certifications}>
        <Form config={flatten(formConfig)} />
        <div className={styles.addCertificationButton}>
          <LocalizedButton
            onClick={() => this.addCertification()}
            buttonType={BUTTON_TYPE.LINK}
            text="Button.add"
            iconName="AddIcon"
          />
        </div>
      </div>
    );
  };
  renderForm = () => {
    const { hasEditError, content } = this.state;
    const { actions, languages } = this.props;
    return (
      <>
        <Form
          config={[
            {
              key: "Composition name",
              type: FORM_FIELD_TYPE.LABEL,
              title: "Composition name",
              value: content.name,
            },
          ]}
        />
        {this.renderCertificationForm()}
        <Form
          config={[
            {
              key: "Certificate Duration (in sec)",
              type: FORM_FIELD_TYPE.INPUT,
              label: "Certificate Duration (in sec)",
              className: "col-12",
              placeholder: `Enter certificate duration`,
              value: content.censorCertificateBufferTimeInSeconds,
              error:
                hasEditError && content.censorCertificateBufferTimeInSeconds.trim().length === 0,
              errorMessage: "",
              onChange: (e) =>
                this.inputChange("censorCertificateBufferTimeInSeconds", e.target.value),
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "contentTypeId",
              title: "Content Type",
              label: "",
              placeholder: "Select type",
              fetchAction: typeOfContent,
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === content.contentTypeId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputChange("contentTypeId", data.id);
              },
            },
            {
              key: "languageId",
              type: FORM_FIELD_TYPE.API_SEARCH,
              title: "Language",
              placeholder: "Search...",
              onSelect: (props) => {
                this.onCertificationChange({
                  languageId: props.languageId,
                  languageName: props.name,
                });
              },
              query: content.languageName,
              searchIcon: "right",
              showSubText: false,
              parseResults: (data) => {
                return (
                  data &&
                  data.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                      item: item,
                    };
                  })
                );
              },
              fetchAction: (text) => getLanguages(text),
              disabled: false,
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "experienceId",
              title: "Experiences",
              label: "",
              placeholder: "Select type",
              fetchAction: pictureTypes,
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === content.experienceId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputChange("experienceId", data.id);
              },
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "aspectRatioId",
              title: "Aspect Ratio",
              label: "",
              placeholder: "Select aspect ratio",
              fetchAction: aspectRatios,
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === content.aspectRatioId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputChange("aspectRatioId", data.id);
              },
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "audioTypeId",
              title: "Audio Type",
              label: "",
              placeholder: "Select audio type",
              fetchAction: audioTypes,
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === content.audioTypeId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputChange("audioTypeId", data.id);
              },
            },
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "resolutionId",
              title: "Resolution",
              label: "",
              placeholder: "Select resolution",
              fetchAction: resolutionTypes,
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === content.resolutionId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputChange("resolutionId", data.id);
              },
            },
            {
              type: FORM_FIELD_TYPE.SELECT,
              title: "Standard",
              label: "",
              list: CONTENT_STANDARD.map((item) => item.value),
              selectedList: content.isSmpte
                ? [CONTENT_STANDARD.filter((item) => item.id === content.isSmpte)[0].value]
                : ["Select standard"],
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) =>
                this.inputChange(
                  "isSmpte",
                  CONTENT_STANDARD.filter((item) => item.value === data)[0].id
                ),
              usePortal: false,
            },
            {
              key: "subtitles",
              type: FORM_FIELD_TYPE.MULTI_SELECT,
              label: "Subtitles",
              items: languages,
              itemRenderer: this.rendersubtitleMenuItem,
              onItemSelect: (data) =>
                this.setState({
                  content: {
                    ...content,
                    subtitles: uniqWith([...content.subtitles, data], isEqual),
                  },
                }),
              onQueryChange: actions.searchLanguages,
              resetOnSelect: true,
              tagRenderer: (data) => data.name,
              tagInputProps: {
                placeholder: "Select subtitles",
                tagProps: { intent: Intent.NONE, minimal: true },
                disabled: true,
                onRemove: (data, index) => this.removeItem(index, "subtitles"),
                rightElement:
                  content.subtitles && content.subtitles.length ? (
                    <RemoveIcon onClick={() => this.clearMultiSelect("subtitles")} />
                  ) : (
                    <SearchIcon />
                  ),
              },
              selectedItems: content.subtitles,
            },
            {
              id: "notes",
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Notes",
              placeholder: "Enter notes",
              value: content.notes,
              onChange: (e) => this.inputChange("notes", e.target.value),
            },
          ]}
        />
      </>
    );
  };
  render() {
    const { isLoading, content, editContent, mapBrand, onCancel } = this.props;
    return (
      <>
        <RightPanel
          isLoading={isLoading}
          isOpen={editContent.isOpen}
          onClose={onCancel}
          header="RightPanelHeader.editContent"
          showFooter={true}
          primaryButtonProps={{ text: "Button.save", onClick: this.updateContentDetails }}
          secondaryButtonProps={{ text: "Button.cancel", onClick: onCancel }}
        >
          {this.renderForm()}
        </RightPanel>
        {mapBrand.isOpen && (
          <MapBrand
            isLoading={isLoading}
            isOpen={mapBrand.isOpen}
            data={content}
            mappedBrand={this.mappedBrand(content)}
            onClose={onCancel}
            onSave={this.mapContentWitBrand}
          />
        )}
      </>
    );
  }
}
