import React from "react";
import { CPL_STATUSES } from "../../../../../constants";
import CPLButton from "./CPLButton";
import getStatusFromParams from "./getStatusParams";
import viewCPLsList from "./viewCPLsList";

const getHeaderButtons = (props) => () => {
  const status = getStatusFromParams(props);
  const MappedButton = () => (
    <CPLButton id="mapped" iconName="TickIcon" onClick={(id) => viewCPLsList(id, props)} />
  );
  const UnmappedButton = () => (
    <CPLButton id="unmapped" iconName="ListIcon" onClick={(id) => viewCPLsList(id, props)} />
  );
  const IgnoredButton = () => (
    <CPLButton id="ignored" iconName="CancelIcon" onClick={(id) => viewCPLsList(id, props)} />
  );
  const MovieMappedButton = () => (
    <CPLButton id="movie" iconName="MoviesIcon" onClick={(id) => viewCPLsList(id, props)} />
  );

  if (status === CPL_STATUSES.MAPPED) {
    return (
      <>
        <IgnoredButton />
        <UnmappedButton />
        <MovieMappedButton />
      </>
    );
  } else if (status === CPL_STATUSES.IGNORED) {
    return (
      <>
        <MappedButton />
        <UnmappedButton />
        <MovieMappedButton />
      </>
    );
  } else if (status === CPL_STATUSES.MOVIE_MAPPED) {
    return (
      <>
        <IgnoredButton />
        <MappedButton />
        <UnmappedButton />
      </>
    );
  } else {
    return (
      <>
        <IgnoredButton />
        <MappedButton />
        <MovieMappedButton />
      </>
    );
  }
};

export default getHeaderButtons;
