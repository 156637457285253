import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import ScreenList from "./ScreensList";

class AdminScreensList extends Component {
  render = () => {
    return (
      <ScreenList
        {...this.props}
        isAdmin
        withStatusLegend={false}
        selectedColumns={["Companies Associated", "Screen ID", "Last Updated On"]}
      />
    );
  };
}

const AdminScreensListWithFilters = WithFetchList("getAdminScreensList", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(AdminScreensList);

export { AdminScreensList, AdminScreensListWithFilters };

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({ screens, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(AdminScreensListWithFilters);
