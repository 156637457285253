/*
Sep7-2023 Anushree:-
1. BrandPage class is used to display the Detail Brand Page for multiple tabpills : Parent Brand,Individual Brand,Product,Variant
2. It is calledBy- Root.js
*/
import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { findIndex, includes, isEmpty, compact } from "lodash";
import classNames from "classnames";
import { bindDispatch, categoryName, getBusinessType, checkScopes } from "../../../../utils";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import TabView from "../../../common/Tabs";
import HeaderButton from "../../../common/HeaderButtons";
import DetailsLoading from "../../../common/DetailsLoading";
import InfoBlock from "../../../common/InfoBlock";
import { SCOPE_ACTIONS, SCOPES } from "../../../../constants";
import LocalizedButton from "../../../common/LocalizedButton";
import { ActiveTableCellColumnFromArray } from "../../../common/ActiveTableCell";
import pageStyles from "../../../../styles/App.module.scss";
import ButtonStyles from "../../../../styles/Button.module.scss";
import { GET_BRAND_ACTIONS, DEFAULT_BRANDS_PATH, BRAND_TAB_IDS } from "./brandConstants";
import IndividualBrands from "./Tabs/IndividualBrands";
import Products from "./Tabs/Products";
import Variants from "./Tabs/Variants";
import EditBrand from "./EditBrand";
import BrandContentTableWithFilters from "./Tabs/Content";

const defaultCardSize = {
  lg: 3,
  md: 3,
  sm: 6,
};

export class BrandPage extends Component {
  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    //used to fetch brand data depending on the tabIb being passed.
    if (params.tabId) actions[GET_BRAND_ACTIONS[params.tabId]](params, false);
    if (params.action && params.action === "edit") {
      actions.editBrand({});
      actions[GET_BRAND_ACTIONS[params.tabId]](params, true);
    }
    if (params.action && params.action === "new") {
      this.addNewBrand();
    }
    actions.getBusinessTypesList();
    actions.getProductIdentificationNumberTypeList();
  };

  componentWillUnmount = () => {
    this.props.actions.updateEditBrand({ isOpen: false, brand: {} });
  };

  componentDidUpdate = (prevProps) => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.id !== prevProps.match.params.id) {
      actions[GET_BRAND_ACTIONS[params.tabId]](params, false);
    }
  };

  editBrand = () => {
    const {
      actions,
      match: { params },
      history,
    } = this.props;
    history.push(`/brands/${params.tabId}/${params.id}/details/edit`);
    actions.editBrand({});
    actions[GET_BRAND_ACTIONS[params.tabId]](params, true);
  };

  queryString = () => {
    const { history } = this.props;
    return queryString.parse(history.location.search);
  };

  editChildBrand = (brandId) => {
    const { history } = this.props;
    history.push({
      search: queryString.stringify({ ...this.queryString(), editBrandId: brandId }),
    });
  };

  addNewButtonLabel = (tabId) => {
    switch (tabId) {
      case DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS:
        return { buttonLabel: "Button.addProduct" };
      case DEFAULT_BRANDS_PATH.PRODUCTS:
        return { buttonLabel: "Button.addVariant" };
      case DEFAULT_BRANDS_PATH.VARIANTS:
        return { addButton: false };
      default:
        return { buttonLabel: "Button.addIndividualBrand" };
    }
  };

  getBrandTitle = () => {
    const {
      match: { params },
    } = this.props;
    if (params.tabId === DEFAULT_BRANDS_PATH.PARENT_BRANDS)
      return DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS;
    else if (params.tabId === DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS)
      return DEFAULT_BRANDS_PATH.PRODUCTS;
    else if (params.tabId === DEFAULT_BRANDS_PATH.PRODUCTS) return DEFAULT_BRANDS_PATH.VARIANTS;
    else return DEFAULT_BRANDS_PATH.PARENT_BRANDS;
  };

  addNewBrand = async () => {
    const {
      actions,
      history,
      brands: { brand },
      match: { params },
    } = this.props;
    ReactGA.event({
      category: "Adding Brand",
      action: "Brand created in Brandpage",
      label: "Brand added via Brand details page",
    });
    if (params.tabId === DEFAULT_BRANDS_PATH.PARENT_BRANDS) {
      await history.push(
        `/brands/${params.tabId}/${params.id}/${DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS}/new`
      );
      return actions.addBrand(
        { tabId: DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS },
        {
          parentBrandId: brand.id,
          parentBrandName: brand.name,
          type: brand.businessType,
        }
      );
    } else if (params.tabId === DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS) {
      await history.push(
        `/brands/${params.tabId}/${params.id}/${DEFAULT_BRANDS_PATH.PRODUCTS}/new`
      );
      return actions.addBrand(
        { tabId: DEFAULT_BRANDS_PATH.PRODUCTS },
        {
          parentBrandId: brand.parentBrandId,
          parentBrandName: brand.parentBrandName,
          businessType: brand.businessType,
          individualBrandId: brand.id,
          individualBrandName: brand.name,
        }
      );
    } else if (params.tabId === DEFAULT_BRANDS_PATH.PRODUCTS) {
      await history.push(
        `/brands/${params.tabId}/${params.id}/${DEFAULT_BRANDS_PATH.VARIANTS}/new`
      );
      return actions.addBrand(
        { tabId: DEFAULT_BRANDS_PATH.VARIANTS },
        {
          parentBrandId: brand.parentBrandId,
          parentBrandName: brand.parentBrandName,
          businessType: brand.businessType,
          individualBrandId: brand.individualBrandId,
          individualBrandName: brand.individualBrandName,
          productId: brand.id,
          productName: brand.name,
        }
      );
    } else {
      await history.push(`/brands/${params.tabId}/${params.id}/${params.viewtabId}/new`);
      actions.addBrand({ tabId: DEFAULT_BRANDS_PATH.PARENT_BRANDS });
    }
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.cancelEditBrand();
    history.replace(`/brands/${params.tabId}/${params.id}/${params.viewtabId}`);
  };

  handleBrandConfig = () => {
    const {
      brands: { brand },
      businessTypes: { list },
      match: { params },
    } = this.props;
    if (params.tabId === DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS) {
      return [
        {
          title: "Individual Brand",
          value: brand.name,
          size: defaultCardSize,
        },
        {
          title: "Individual Brand Code",
          value: brand.code,
          size: {
            lg: 3,
            md: 3,
            sm: 6,
          },
        },
      ];
    }
    if (params.tabId === DEFAULT_BRANDS_PATH.PRODUCTS) {
      return [
        {
          title: "Product",
          value: brand.name,
          size: defaultCardSize,
        },
        {
          title: "Product Code",
          value: brand.code,
          size: defaultCardSize,
        },
        {
          title: "Product Identification Number",
          value:
            brand.productIdentificationNumberTypeId && brand.productIdentificationNumber
              ? `${brand.productIdentificationNumberTypeName} - ${brand.productIdentificationNumber}`
              : "",
          size: {
            lg: 6,
            md: 6,
            sm: 6,
          },
        },
      ];
    }
    if (params.tabId === DEFAULT_BRANDS_PATH.VARIANTS) {
      return [
        {
          title: "Variant",
          value: brand.name,
          size: defaultCardSize,
        },
        {
          title: "Variant Code",
          value: brand.code,
          size: defaultCardSize,
        },
        {
          title: "Variant Identification Number",
          value:
            brand.variantIdentificationNumberTypeId && brand.variantIdentificationNumber
              ? `${brand.variantIdentificationNumberTypeName} - ${brand.variantIdentificationNumber}`
              : "",
          size: {
            lg: 6,
            md: 6,
            sm: 6,
          },
        },
      ];
    } else {
      return [
        {
          title: "Parent Brand",
          value: brand.name,
          size: defaultCardSize,
        },
        {
          title: "Parent Brand Code",
          value: brand.code,
          size: {
            lg: 3,
            md: 3,
            sm: 6,
          },
        },
        {
          title: "Type ",
          value: brand.businessType ? getBusinessType(list, brand.businessType) : "",
          size: defaultCardSize,
        },
      ];
    }
  };

  handleBrandDetails = () => {
    const {
      brands: { brand },
    } = this.props;
    const brandDetails = [];
    if (!isEmpty(brand.advertisers)) {
      brandDetails.push({
        title: "Advertisers",
        value: ActiveTableCellColumnFromArray(brand.advertisers, null, false),
        size: {
          lg: 6,
          md: 6,
          sm: 12,
        },
      });
    }
    if (!isEmpty(brand.categories)) {
      brandDetails.push({
        title: "Categories",
        value: categoryName(brand.categories || []),
        size: {
          lg: 6,
          md: 6,
          sm: 12,
        },
      });
    }
    return brandDetails;
  };

  renderConfig = () => {
    const {
      match: { params },
      brands: { brand },
      businessTypes: { list },
    } = this.props;

    return [
      this.handleBrandConfig(),
      [
        {
          title: "Product",
          value: brand.productName || "",
          size: defaultCardSize,
        },
        {
          title: "Individual Brand",
          value: brand.individualBrandName || "",
          size: defaultCardSize,
        },
        {
          title: "Parent Brand",
          value: brand.parentBrandName || "",
          size: defaultCardSize,
        },
        {
          title: "Type ",
          value:
            params.tabId !== DEFAULT_BRANDS_PATH.PARENT_BRANDS && brand.businessType
              ? getBusinessType(list, brand.businessType)
              : "",
          size: defaultCardSize,
        },
      ],
      compact(this.handleBrandDetails()),
    ];
  };

  renderBrandInfo = () => {
    const {
      match: { params },
      brands: { isLoading, editBrand },
    } = this.props;
    if (isLoading) return <DetailsLoading />;
    //SLATE-1340 Sep7-2023 Anushree:- fetchDataList prop is passed to trigger the refetching of data after edit completion.
    return (
      <div className={classNames("flex col-12 flex-wrap clearfix", pageStyles.pageContainer)}>
        <InfoBlock config={this.renderConfig()} isLoading={isLoading} />
        <EditBrand
          {...this.props}
          brand={editBrand.brand}
          cancelEdit={this.cancelEdit}
          title={this.getBrandTitle()}
          type={params.tabId}
          //SLATE-1340 Sep7-2023 Anushree:- Inside Edit Brand fetchDataList prop is called after saving the brand. It is used to trigger the refetching of list.
          fetchDataList={() => {
            //SLATE-1340 Sep7-2023 Anushree:- depending on tabId, brand data is refetched.
            if (params.tabId) this.props.actions[GET_BRAND_ACTIONS[params.tabId]](params, false);
          }}
        />
      </div>
    );
  };

  render = () => {
    const {
      history,
      match: { params },
      brands: { isLoading, brand },
      userData,
    } = this.props;
    let selectedTabId = params.viewtabId;
    const tabObject = [
      {
        id: "individual-brands",
        title: "Individual Brands",
        count: brand.countIndividualBrands || 0,
        goto: `/brands/${params.tabId}/${params.id}/individual-brands`,
        panel: (
          <IndividualBrands
            isEdit={true}
            editBrand={this.editChildBrand}
            cancelEdit={this.cancelEdit}
            parentTagId={params.id}
            title={this.getBrandTitle()}
          />
        ),
      },
      {
        id: "products",
        title: "Products",
        count: brand.countProducts || 0,
        goto: `/brands/${params.tabId}/${params.id}/products`,
        panel: (
          <Products
            isEdit={true}
            editBrand={this.editChildBrand}
            cancelEdit={this.cancelEdit}
            parentTagId={params.id}
            title={this.getBrandTitle()}
          />
        ),
      },
      {
        id: "variants",
        title: "Variants",
        count: brand.countVariants || 0,
        goto: `/brands/${params.tabId}/${params.id}/variants`,
        panel: (
          <Variants
            isEdit={true}
            editBrand={this.editChildBrand}
            cancelEdit={this.cancelEdit}
            parentTagId={params.id}
            title={this.getBrandTitle()}
          />
        ),
      },
    ];
    const breadCrumbLinks = [
      { name: brand.parentBrandName, path: `/brands/parent-brands/${brand.parentBrandId}/details` },
      {
        name: brand.individualBrandName,
        path: `/brands/individual-brands/${brand.individualBrandId}/details`,
      },
      { name: brand.productName, path: `/brands/products/${brand.productId}/details` },
    ].filter((b) => b.name);
    if (!includes(BRAND_TAB_IDS, params.viewtabId)) selectedTabId = "details";
    const mergeButton = [
      <LocalizedButton
        id="Merge"
        iconName="BrandVerifications"
        onClick={() => history.push(`/brand-merge/${params.id}`)}
        text="Button.merge"
        className={ButtonStyles.buttonSpacing}
        scope={SCOPES.BRAND_VERIFICATIONS}
        scopeAction={SCOPE_ACTIONS.WRITE}
        userData={userData}
      />,
    ];
    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            history={history}
            links={[
              { name: `Brands`, path: `/brands/parent-brands` },
              ...breadCrumbLinks,
              { name: brand.name },
            ]}
          />
        </div>
        <div className="col-12 clearfix">
          <PageHeader
            isLoading={isLoading}
            name={brand.name}
            renderRightSideComponent={() => (
              <div className="flex align-center flex-wrap">
                <HeaderButton
                  headerButtons={mergeButton}
                  addButton
                  editButton
                  logsButton
                  logsOnlyForSlateAdmin
                  editButtonLabel="Button.edit"
                  history={history}
                  addButtonScope={SCOPES.BRANDS}
                  addButtonScopeAction={SCOPE_ACTIONS.WRITE}
                  editButtonScope={SCOPES.BRANDS}
                  editButtonScopeAction={SCOPE_ACTIONS.WRITE}
                  userData={userData}
                  addNewField={this.addNewBrand}
                  edit={this.editBrand}
                  {...this.addNewButtonLabel(params.tabId)}
                  logsPath={`/logs/ref/${params.id}`}
                />
              </div>
            )}
          />
          <TabView
            id="navbar"
            selectedTabId={selectedTabId}
            tabs={checkScopes(
              [
                {
                  id: "details",
                  title: "Details",
                  goto: `/brands/${params.tabId}/${params.id}/details`,
                  panel: this.renderBrandInfo(),
                },
                ...tabObject.slice(findIndex(tabObject, ["id", params.tabId]) + 1),
                {
                  id: "content",
                  title: "Content",
                  scope: SCOPES.CONTENT,
                  scopeAction: SCOPE_ACTIONS.READ,
                  count: brand.countContents || 0,
                  goto: `/brands/${params.tabId}/${params.id}/content`,
                  panel: <BrandContentTableWithFilters />,
                },
              ],
              userData
            )}
          />
        </div>
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (brands, businessTypes, productIdentificationNumberType, userData) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(BrandPage);
