/* Polyfills for IE 11
   Most of this file is heavily inspired from react-app-polyfill package,
   however I've lifted the code from there instead of just using that package,
   because I wanted to avoid having to polyfill features not used in this project.
   Ref: https://github.com/facebook/create-react-app/blob/45bc6280d109b7e353ffaa497a0352c9d040c36d/packages/react-app-polyfill/ie11.js
*/

// Support for Array and String Includes because nobody likes indexOf ¯\_(ツ)_/¯
import "core-js/features/array/includes";
import "core-js/features/string/includes";

// Support for...of (a commonly used syntax feature that requires Symbols)
import "core-js/features/symbol";

// Support for Array find and findIndex which are used in React Table package
import "core-js/features/array/find";
import "core-js/features/array/find-index";

// Support for Number isNaN which is used in React Table package
import "core-js/features/number/is-nan";

import { polyfill } from "mobile-drag-drop";

polyfill();

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require("object-assign");
