import {
  getCompanyContent as getCompanyContentApi,
  getContentById as getContentByIdApi,
  updateContentDetails as updateContentDetailsApi,
  getLanguages as searchLanguageApi,
  archiveContent,
  unArchiveContent,
} from "../../api";
import { createFilterRequest } from "../../utils";
import * as types from "../types/content";
import * as constants from "../../constants";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateLanguage = (payload) => {
  return { type: types.LANGUAGES, payload };
};
const updateArchivedContent = (payload) => {
  return { type: types.ARCHIVED_CONTENT, payload };
};
const updateContent = (payload) => {
  return { type: types.CONTENT, payload };
};
const contentDetails = (payload) => {
  return { type: types.CONTENT_DETAILS, payload };
};
const updateEditContent = (payload) => {
  return { type: types.EDIT_CONTENT, payload };
};
const updateMapBrand = (payload) => {
  return { type: types.MAP_BRAND, payload };
};
const editContent = (content) => (dispatch) => {
  dispatch(updateEditContent({ isOpen: true, content: content }));
};
const mapBrand = (content) => (dispatch) => {
  dispatch(updateMapBrand({ isOpen: true, brand: content }));
};

const getCompanyContent = (params, filters = [], getList = getCompanyContentApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc", tagId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CONTENT_LOADING, true));
      // TODO: Replace here with tagId
      // const { data: tag } = await getTagByID(tagId);
      const { data } = await getList(ps, (page - 1) * ps, sort, [
        ...filters,
        { id: tagId, type: "company" },
      ]);
      dispatch(updateContent(data));
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    }
  };
};

const getArchivedContent = (params, filters = [], getList = getCompanyContentApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc", archived } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CONTENT_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, archived);
      dispatch(updateArchivedContent(data));
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    }
  };
};

const getContentById = (params = {}, isEdit = false, fetch = getContentByIdApi) => {
  return async (dispatch) => {
    try {
      isEdit
        ? dispatch(actionCreator(types.IS_CREATE_CONTENT_LOADING, true))
        : dispatch(actionCreator(types.IS_CONTENT_LOADING, true));
      const { data } = await fetch(params.id);
      dispatch(contentDetails(data));
      isEdit && dispatch(mapBrand(data));
      isEdit
        ? dispatch(actionCreator(types.IS_CREATE_CONTENT_LOADING, false))
        : dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    } catch (error) {
      isEdit
        ? dispatch(actionCreator(types.IS_CREATE_CONTENT_LOADING, false))
        : dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
    }
  };
};

const updateContentDetails = (data, callback, save = updateContentDetailsApi) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_CONTENT_LOADING, true));
    try {
      await save(data);
      callback();
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
    }
  };
};

const archiveUnarchiveContent = (selectedIds = [], isArchived = false) => {
  return async (dispatch) => {
    dispatch(actionCreator(types.IS_CONTENT_LOADING, true));
    const { req: ft } = createFilterRequest(
      selectedIds.map((id) => ({ id: id, type: constants.TAG_TYPE.CONTENT }))
    );
    try {
      isArchived ? unArchiveContent(ft) : archiveContent(ft);
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_CONTENT_LOADING, false));
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
    }
  };
};

const searchLanguages = (text, search = searchLanguageApi) => {
  return async (dispatch) => {
    try {
      const { data } = await search(text);
      dispatch(updateLanguage(data));
    } catch (error) {
      dispatch(actionCreator(types.IS_CONTENT_ERROR, error.message));
    }
  };
};

export {
  getCompanyContent,
  getArchivedContent,
  getContentById,
  updateMapBrand,
  updateEditContent,
  editContent,
  mapBrand,
  updateContentDetails,
  contentDetails,
  archiveUnarchiveContent,
  searchLanguages,
};
