/*
AddScreens Component
 1.This component enables user to add multiple screens associated with a schedule. 
 2.Screens can either be selected from dropdown or can be uploaded using a csv file
 3.It is calledBy:- createSchedules/index.js
*/
import React, { Component } from "react";
import { BUTTON_TYPE } from "prefab";
import { isEmpty, values } from "lodash";
import InfoBlock from "../../../common/InfoBlock";
import Form from "../../../common/Form";
import { FORM_FIELD_TYPE, TAG_TYPE, DEFAULT_PAGE_SIZE } from "../../../../constants";
import styles from "../../../../styles/Schedules/addScreens.module.scss";
import UploadCSV from "../actions/UploadCSV";
import ScreenList from "./ScreenList";

export default class AddScreens extends Component {
  state = {
    isUploadCsvPanelOpen: false,
    //currentFilter : stores screen/theater object selected in "Add Theatre/Screen" input box
    currentFilter: [],
  };

  //method used to open/close the right Panel
  toggleUploadPanel = (status) => {
    //actions.resetScheduleErrors: resets SCHEDULE_ERRORS to null
    this.props.actions.resetScheduleErrors();
    this.setState({
      isUploadCsvPanelOpen: status,
    });
  };

  //method used to add screen/theatre in screenList
  handleAddScreen = () => {
    const { currentFilter } = this.state;
    const { scheduleDetails } = this.props;
    const params = { page: 1, ps: DEFAULT_PAGE_SIZE, sort: "name:asc" };
    this.props.handleSuggestedScreens(params, [currentFilter], scheduleDetails.id);
    this.setState({ currentFilter: null });
  };

  //method passed in UploadCSV component to remove screens and close the right panel
  handleRemoveScheduleScreens = (scheduleScreens) => {
    //remove screens
    const { removeScheduleScreens } = this.props;

    if (removeScheduleScreens) removeScheduleScreens(scheduleScreens);

    //close the right panel
    this.setState({
      isUploadCsvPanelOpen: false,
    });
  };

  render() {
    const {
      match,
      history,
      scheduleErrors,
      errorScreenList,
      addOrUpdateScheduleScreens,
      scheduleScreens,
      scheduleDetails,
      isError,
      userData,
      componentRef,
    } = this.props;
    const { isUploadCsvPanelOpen, currentFilter } = this.state;
    return (
      <div ref={componentRef}>
        <InfoBlock
          header={scheduleScreens.length === 0 ? `Screens` : `Screens (${scheduleScreens.length})`}
          childClassName={styles.screenFormContainer}
          rightSideButtons={[
            {
              text: "Button.selectScreenCsv",
              iconName: "UploadIcon",
              onClick: () => this.toggleUploadPanel(true),
            },
          ]}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "searchTag",
                label: "Add Theatre/Screen",
                type: FORM_FIELD_TYPE.TAG_SEARCH,
                size: {
                  lg: 4,
                  md: 4,
                  sm: 9,
                  xs: 9,
                },
                placeholder: "Select Screen/Theatre",
                resetOnSelect: false,
                query: currentFilter ? currentFilter.name : "",
                containerClassName: styles.searchField,
                tagTypes: [
                  TAG_TYPE.SCREEN,
                  TAG_TYPE.THEATRE,
                  TAG_TYPE.SCREEN_DAVP_ID,
                  TAG_TYPE.THEATRE_DAVP_ID,
                  TAG_TYPE.SCREEN_ERP_ID,
                  TAG_TYPE.THEATRE_ERP_ID,
                ],
                onSelect: (filter) => this.setState({ currentFilter: filter }),
              },
              {
                id: "addScreen",
                type: FORM_FIELD_TYPE.BUTTON,
                text: "Button.add",
                iconName: "AddFilledIcon",
                containerClassName: styles.addButton,
                buttonType: BUTTON_TYPE.CONSTRUCTIVE,
                size: {
                  lg: 1,
                  md: 1,
                  sm: 2,
                  xs: 2,
                },
                disabled: isEmpty(currentFilter),
                onClick: this.handleAddScreen,
              },
            ]}
          />
          {isError && scheduleScreens.length === 0 && (
            <div className={styles.errors}>Please Add Screens</div>
          )}
          <InfoBlock className={styles.screenListContainer}>
            <ScreenList
              userData={userData}
              match={match}
              history={history}
              removeScreen={this.props.removeScheduleScreens}
              isEdit={!!scheduleDetails.id}
            />
          </InfoBlock>
        </InfoBlock>
        <UploadCSV
          scheduleErrors={scheduleErrors}
          errorScreenList={errorScreenList}
          removeScheduleScreens={this.handleRemoveScheduleScreens}
          userData={userData}
          isOpen={isUploadCsvPanelOpen || values(scheduleErrors)?.length > 0}
          onClose={(screens) => {
            this.handleRemoveScheduleScreens(screens);
            this.toggleUploadPanel(false);
          }}
          updateSelectedScreens={addOrUpdateScheduleScreens}
        />
      </div>
    );
  }
}
