import React, { Component } from "react";
import PropTypes from "prop-types";
import { flattenDeep, compact } from "lodash";
import { searchTags } from "../../../api";
import ApiSearch from "../ApiSearch";
import { getTagDisplayName, getSubTextDisplayName } from "../../../utils";

class TagSearch extends Component {
  static propTypes = {
    tagTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    searchIcon: PropTypes.oneOf(["left", "right", "none"]),
    showSubText: PropTypes.bool,
    resetOnSelect: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: "Search",
    searchIcon: "right",
    showSubText: true,
    resetOnSelect: true,
  };

  render() {
    const {
      placeholder,
      showSubText,
      resetOnSelect,
      searchIcon,
      onSelect,
      tagTypes,
      ...restProps
    } = this.props;

    return (
      <ApiSearch
        placeholder={placeholder}
        onSelect={onSelect}
        fetchAction={(value) => searchTags(value, compact(flattenDeep([tagTypes])))}
        parseResults={(data) =>
          data.slice(0, 15).map((item) => ({
            id: item.id,
            name: getTagDisplayName(item),
            tag: getSubTextDisplayName(item),
            displayName: getTagDisplayName(item),
            type: item.type,
          }))
        }
        showSubText={showSubText}
        searchIcon={searchIcon}
        resetOnSelect={resetOnSelect}
        {...restProps}
      />
    );
  }
}

export default TagSearch;
