import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";

import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdAtColumn,
  createdByColumn,
} from "../../../../utils";
import PageHeader from "../../../common/PageHeader";
import pageStyles from "../../../../styles/App.module.scss";
import { TAG_TYPE } from "../../../../constants";
import WithFetchList from "../../../hoc/withFetchList";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import FilterChips from "../../../common/Filters/FilterChips";
import HeaderButton from "../../../common/HeaderButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";

class PlaylistTemplatesPage extends Component {
  state = {
    selectedColumns: ["Name", "Screen Count", "Last Updated By", "Last Updated On"],
  };

  componentDidMount = () => this.props.fetchData();

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      playlistTemplates: { data, isLoading, isPartialLoading },
      history,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const columns = [
      {
        id: "name",
        Header: "Name",
        accessor: ({ id, name }) =>
          ActiveTableCell(id, name, () => this.props.onFilterIdSelect(id)),
      },
      {
        id: "countScreens",
        Header: "Screen Count",
        accessor: "countScreens",
      },
      {
        id: "countShowsPlayed",
        Header: "Shows Played",
        accessor: "countShowsPlayed",
      },
      {
        id: "countShowsScheduled",
        Header: "Shows Scheduled",
        accessor: "countShowsScheduled",
      },
      createdByColumn(this.props.onFilterIdSelect),
      createdAtColumn(),
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),
    ];

    return (
      <div>
        <PageHeader
          name="PageHeader.playlistTemplates"
          isPartialLoading={isPartialLoading}
          count={data.totalCount}
          renderRightSideComponent={() => <HeaderButton history={history} />}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={data}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[TAG_TYPE.PLAYLIST_TEMPLATES, TAG_TYPE.CREATED_AT, TAG_TYPE.UPDATED_AT]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={data.totalCount}
              onFilterChange={this.props.onFilterChange}
            />
            <Table
              data={data.data || []}
              manual={false}
              sorted={sort}
              loading={isPartialLoading || isFilterLoading}
              onSortedChange={onSortedChange}
              pageSize={data.data ? data.data.length : 5}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              data={data}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}
export const PlaylistTemplates = WithFetchList("fetchPlaylistTemplate", {
  sort: [{ id: "updatedAt", desc: true }],
})(PlaylistTemplatesPage);

const mapStateToProps = createSelector(
  (state) => state.playlistTemplates,
  (playlistTemplates) => ({ playlistTemplates })
);

export default connect(mapStateToProps, bindDispatch)(PlaylistTemplates);
