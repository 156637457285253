import * as types from "../types/categories";

const initialState = {
  isLoading: false,
  isError: null,
  categoryEdit: {
    isEditOpen: false,
    category: {},
    isEditError: false,
  },
  categoryCreate: {
    isCreateOpen: false,
    isCreateError: false,
    category: {
      categoryId: "",
      categoryName: "",
      subCategory1Id: "",
      subCategory1Name: "",
      subCategory2Id: "",
      subCategory2Name: "",
    },
  },
  categories: {
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.CATEGORIES:
      return { ...state, categories: payload };
    case types.CATEGORY_EDIT:
      return { ...state, categoryEdit: payload };
    case types.CATEGORY_CREATE:
      return { ...state, categoryCreate: payload };
    default:
      return state;
  }
}
