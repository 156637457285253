import React from "react";
import { Classes } from "@blueprintjs/core";
import breadcrumbStyles from "../../styles/BreadCrumb.module.scss";

export default function BreadcrumbLoading() {
  return (
    <div className={`col-12 clearfix ${breadcrumbStyles.wrapper}`}>
      <div className={`col-12 clearfix ${breadcrumbStyles.container}`}>
        <p className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</p>
      </div>
    </div>
  );
}
