import { isEmpty } from "lodash";
import { VIEW_PORT_SIZE } from "../../constants";

const getColumnsByViewport = (pageDimensions, mobileColumns, defaultColumns) => {
  if (isEmpty(pageDimensions) || !pageDimensions?.width > 0) return defaultColumns;
  if (pageDimensions?.width <= VIEW_PORT_SIZE.MOBILE) return mobileColumns;
  // Can add more viewports if we need further enhancement
  return defaultColumns;
};

export default getColumnsByViewport;
