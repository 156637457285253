import React, { Component } from "react";
import PageHeader from "../../common/PageHeader";
import TabView from "../../common/Tabs";
import ReportList from "./ReportList/ReportList";
import ScheduledReportList from "./ScheduledReports/ScheduledReportList";

class Reports extends Component {
  state = {
    data: [
      {
        name: "",
        dateRange: ["20/12/2020", "21/12/2020"],
        filters: [],
      },
    ],
  };

  onFilterChange = (index, filters) => {
    const { data } = this.state;
    data[index].filters = filters;
    this.setState(data);
  };

  render() {
    // const { history } = this.props;
    // const { data } = this.state;

    return (
      <div>
        <PageHeader
          name="Reports"
          // buttons={[
          //   {
          //     text: "Generated Reports",
          //     scope: SCOPES.__SELF__,
          //     className: ButtonStyle.addButton,
          //     onClick: () => history.push("/reports/generated"),
          //   },
          // ]}
        />
        <TabView
          id="navbar"
          // selectedTabId={getBrandType(params.tabId, brandTagType)}
          tabs={[
            {
              id: "reports",
              title: "Reports",
              panel: <ReportList />,
            },
            {
              id: "scheduled-reports",
              title: "Scheduled Reports",
              panel: <ScheduledReportList />,
            },
            // {
            //   id: "generated-reports",
            //   title: "Generated Reports",
            //   panel: <></>,
            // },
          ]}
        />
      </div>
    );
  }
}

export default Reports;
