/* 
 This component renders the sub row structure
 calledBy :- showFailures, showFailuresSchedules
 */
import React, { Component } from "react";
import styles from "../../../../styles/SubComponent.module.scss";

class ShowFailuresSubComponent extends Component {
  render() {
    const { data, subRowStruct } = this.props;

    return (
      <div className={`${styles.subComponent}`} onClick={(e) => e.stopPropagation()}>
        {subRowStruct.map((row) => {
          return (
            <div className={styles.row}>
              {row.map((cell) => {
                return <div className={styles[`cell${cell.row_flex}`]}>{cell.Cell(data)}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ShowFailuresSubComponent;
