import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import { TheatresScreensList } from "../../common/TheatresScreensList";

class TargetGroupTheatres extends Component {
  render = () => {
    const {
      history,
      match: { params },
      targetGroups: { isTabLoading, targetGroup, targetGroupTheatres, targetGroupScreens },
    } = this.props;
    return (
      <div className="col-12 clearfix">
        <TheatresScreensList
          isTabLoading={isTabLoading}
          selectedSwitch={params.action}
          targetId={targetGroup.id}
          theatres={targetGroupTheatres}
          screens={targetGroupScreens}
          onSwitchChange={(text) =>
            history.push(`/campaigns/target-groups/${targetGroup.id}/theatres/${text}`)
          }
          {...this.props}
        />
      </div>
    );
  };
}
export const TargetGroupTheatresListWithFilters = WithFetchList("getTargetGroupTheatres", {
  sort: [
    {
      id: "endDate",
      desc: false,
    },
    {
      id: "status",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(TargetGroupTheatres);

const mapStateToProps = createSelector(
  (state) => state.targetGroups,
  (state) => state.userData,
  (state) => state.campaigns,
  (targetGroups, userData, campaigns) => ({ targetGroups, userData: userData.user, campaigns })
);

export default withRouter(
  connect(mapStateToProps, bindDispatch)(TargetGroupTheatresListWithFilters)
);
