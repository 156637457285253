import {
  UPDATE_LOG,
  UPDATE_SOURCE,
  UPDATE_LATEST_LOG,
  IS_LOADING,
  ERROR,
  IS_PARTIAL_LOADING,
} from "../types/logs";

const initialState = {
  log: {},
  source: {},
  logInfo: {},
  isLoading: true,
  isPartialLoading: false,
  error: {
    isError: false,
    message: "",
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_LOG:
      return { ...state, log: payload };
    case UPDATE_SOURCE:
      return { ...state, source: payload };
    case UPDATE_LATEST_LOG:
      return { ...state, logInfo: payload };
    case ERROR:
      return { ...state, error: payload };
    case IS_LOADING:
      return { ...state, isLoading: payload };
    case IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    default:
      return state;
  }
}
