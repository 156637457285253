import ReactGA from "react-ga";
import { GA_TRACKING_ID } from "./config";

// Disable analytics for automation and local environment
if (process.env.REACT_APP_ENV === "automation" && process.env.REACT_APP_ENV === "local")
  window[`ga-disable-${GA_TRACKING_ID}`] = true;

const initialise = () => ReactGA.initialize(GA_TRACKING_ID);

export { initialise };
