import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { setCampaignStatus } from "../../../../../api";
import { CAMPAIGN_STATUSES, CAMPAIGN_STEPS } from "../../../../../constants";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import styles from "../../../../../styles/CampaignCreate/Proposal.module.scss";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import GanttChart from "../../../../common/GanttChart";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import Popup from "../../../../common/Popup";
import TabView from "../../../../common/Tabs";
import CampaignTheatresList from "../../Campaigns/tabs/CampaignTheatres";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import CampaignDetails from "../../common/pages/CampaignDetails";
import MediaDetails from "../../common/pages/MediaDetails";
import TargetGroupDetails from "../../common/pages/TargetGroupDetails";
import {
  RemovePausedDurationsFromDurationGantt,
  convertDateRangeToSingleDaySegments,
} from "../../utils";
import { campaignStepChange, validateMandatoryCampaignFields } from "../../utils/campaignCreate";
import CampaignSteps from "../CampaignSteps";
import campaignRouteHandler from "..";

const links = [
  { name: "PageHeader.campaigns", path: "/campaigns" },
  { name: "PageHeader.sendProposal" },
];

export class CampaignProposal extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isProposal: false,
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    const {
      actions,
      match: {
        params: { campaignId },
      },
    } = this.props;
    await actions.updateActiveStep({
      campaignStep: CAMPAIGN_STEPS.FINISH,
      campaignId: campaignId,
    });
    //await this.fetchCampaignTreeData();
  };

  // fetchCampaignTreeData = () => {
  //   const {
  //     match: { params },
  //   } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  validateCreateCampaignAccess = () => {
    const { userData } = this.props;

    return userData.roles.includes("ADMIN") || userData.roles.includes("APPROVER");
  };

  handleTabChange = (newTabId) => {
    const {
      match: {
        params: { campaignId },
      },
      actions,
    } = this.props;

    if (newTabId === "targetGroup") {
      actions.getNewCampaignTargetGroups({
        campaignId,
      });
    } else if (newTabId === "media") {
      actions.getNewCampaignMediaList({
        campaignId,
      });
    } else if (newTabId === "campaign") {
      actions.getCampaignSummary(campaignId);
    }
    actions.getNewCampaignDetails({
      campaignId,
    });
    this.setState({
      selectedTabId: newTabId,
    });
  };

  handleSendProposal = () => {
    this.setState({
      isPopupOpen: true,
      isProposal: true,
    });
  };
  handleCreateCampaign = () => {
    this.setState({
      isPopupOpen: true,
      isProposal: false,
    });
  };

  onDiscardChanges = () => {
    this.setState({
      isPopupOpen: false,
    });
  };

  handleConfirmCreate = async () => {
    const {
      campaignCreate: { campaign, campaignTargetGroups, campaignMediaList },
      history,
    } = this.props;
    if (
      validateMandatoryCampaignFields(campaign) &&
      campaignTargetGroups.data.length &&
      campaignMediaList.data.length
    ) {
      if (campaign.status === "Draft") await setCampaignStatus(campaign.id, "Proposed");
      history.push(`/campaigns`);
    }
    if (this.state.isProposal) {
      // TODO: handle api call for proposal with data
    }
    // TODO: handle api call for campaign submission with data
    this.setState({
      isPopupOpen: false,
    });
  };

  handleMediaEdit = (id) => {
    const {
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/media/${id}`);
  };

  handleTargetGroupEdit = (id) => {
    const {
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/target-groups/${id}`);
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    const resp = await this.props.actions.deleteCampaign(params.campaignId);
    if (resp && resp.success) {
      history.push(`/campaigns`);
    }
  };

  handleChangeStep = (desiredStep) => {
    const {
      match: { params },
      match: {
        params: { campaignId },
      },
      history,
      campaignCreate: { activeStep },
      actions,
    } = this.props;
    if (desiredStep === activeStep + 1 && params.campaignId) {
      actions.updateActiveStep({
        campaignStep: desiredStep,
        campaignId: campaignId,
      });
    } else {
      campaignStepChange(history, params, activeStep, desiredStep);
    }

    this.setState({
      hasFormSaved: true,
    });
  };

  renderCampaignTree = () => {
    const { isCampaignStructureError } = this.state;

    const {
      match: {
        params: { campaignId },
      },
    } = this.props;
    if (this.props.isCampaignInvalid || isCampaignStructureError) return null;
    return (
      <div className={campaignFormStyles.campaignTree}>
        <CampaignTree
          isNewCampaign
          //isLoading={isCampaignStructureLoading}
          //campaignTreeData={campaignStructure}
          campaignIdForSummary={campaignId}
        />
      </div>
    );
  };

  render() {
    const { selectedTabId } = this.state;
    const {
      history,
      actions,
      userData,
      match: { params },
      timesOfDay: { data: timesOfDay },
      campaignCreate: {
        isTabLoading,
        campaign,
        campaignTargetGroups,
        campaignMediaList,
        campaignSummary,
        activeStep,
        isMediaLoading,
        isTargetGroupLoading,
        isMediaDetailsLoading,
        isTargetGroupDetailsLoading,
      },
    } = this.props;

    const { isPopupOpen } = this.state;
    const campaignPausedStatus = campaign?.statusChanges.filter(
      (statusChange) => statusChange.status === "Paused"
    );
    const campaignGantt = [
      [
        `C: ${campaign.name}`,
        "",
        new Date(campaign.validity.fromDate),
        new Date(campaign.validity.toDate),
      ],
    ];
    /**
     * Represents the rows of a Gantt chart for a campaign proposal.
     * Each row represents a target group and its associated media segments.
     * The Gantt chart includes the validity dates of the target groups and media segments,
     * with any paused durations removed.
     *
     * @type {Array<Array<Array<string | Date>>>}
     */
    const ganttRows = [
      ...RemovePausedDurationsFromDurationGantt(campaignGantt, campaignPausedStatus),
      ...campaignTargetGroups?.data
        ?.map((targetGroup) => [
          ...RemovePausedDurationsFromDurationGantt(
            [
              [
                `T: ${targetGroup.name}`,
                " ",
                new Date(targetGroup.validity.fromDate),
                new Date(targetGroup.validity.toDate),
              ],
            ],
            targetGroup.statusChanges?.filter((statusChange) => statusChange.status === "Paused")
          ),
          ...campaignMediaList?.data
            ?.map((media, i) => {
              if (media.targetGroupId !== targetGroup.id) return [];
              const mediaSegments = [
                ...RemovePausedDurationsFromDurationGantt(
                  [
                    [
                      `M: ${media.name}`,
                      "  ",
                      new Date(media.validity.fromDate),
                      new Date(media.validity.toDate),
                    ],
                  ],
                  media.statusChanges?.filter((statusChange) => statusChange.status === "Paused")
                ),
              ];
              return [
                ...mediaSegments.map((segment) => [
                  ...convertDateRangeToSingleDaySegments(segment[0], "  ", segment[2], segment[3]),
                ]),
              ];
            })
            .flat(2)
            .filter((ele) => ele.length > 0),
        ])
        .flat(1),
    ];
    if (isTabLoading) return <PageLoadingWithTable />;
    return (
      <div className={classNames("col-12 clearfix", styles.pageWrapper)}>
        <Breadcrumb history={history} links={links} />
        <div className={classNames("col-12 clearfix")}>
          <div className={campaignFormStyles.campaignCreateProgressBar}>
            <CampaignPageHeader
              title="PageHeader.sendProposal"
              onSaveCampaignAsDraft={this.handleSaveCampaignAsDraft}
              onDiscardCampaign={this.handleCampaignDelete}
              isDiscardDisabled={!campaign.id || campaign.status !== CAMPAIGN_STATUSES.DRAFT}
              hideDraftButton
            />
            <CampaignSteps
              activeStep={activeStep}
              validTillStep={activeStep}
              disableSave={false}
              disableReset={true}
              onChangeStep={this.handleChangeStep}
              onSendProposal={this.handleSendProposal}
              onCreateCampaign={this.handleCreateCampaign}
              isAdminUser={this.validateCreateCampaignAccess}
              campaign={campaign}
            />
          </div>
          <div className={styles.tabPanel}>
            <TabView
              id="campaignTabs"
              selectedTabId={selectedTabId}
              defaultSelectedTabId="details"
              onChange={this.handleTabChange}
              tabContainerClass={styles.tabPanelClass}
              tabs={[
                {
                  id: "ganttChart",
                  title: "Campaign Chart",
                  panel: (
                    <div>
                      <GanttChart rows={ganttRows} height={800} />
                    </div>
                  ),
                },
                {
                  id: "campaign",
                  title: "Campaign Details",
                  panel: (
                    <div className={styles.marginTopT5}>
                      <CampaignDetails
                        isLoading={isTabLoading}
                        campaign={campaign}
                        campaignSummary={campaignSummary}
                        params={params}
                        userData={userData}
                        showEstimates
                      />
                    </div>
                  ),
                },
                {
                  id: "targetGroup",
                  title: "Target Groups",
                  panel: (
                    <TargetGroupDetails
                      showEstimates
                      params={params}
                      isLoading={isTargetGroupLoading}
                      isDetailsLoading={isTargetGroupDetailsLoading}
                      targetGroups={campaignTargetGroups?.data}
                      showDuplicate={false}
                      showDelete={false}
                      onEdit={this.handleTargetGroupEdit}
                      getTargetGroupDetails={actions.getTargetGroupSummaryById}
                    />
                  ),
                },
                {
                  id: "media",
                  title: "Media",
                  panel: (
                    <MediaDetails
                      params={params}
                      isLoading={isMediaLoading}
                      isDetailsLoading={isMediaDetailsLoading}
                      showEstimates
                      mediaList={campaignMediaList?.data}
                      timesOfDay={timesOfDay}
                      getMediaDetails={actions.getMediaById}
                      showDuplicate={false}
                      showDelete={false}
                      onEdit={this.handleMediaEdit}
                    />
                  ),
                },
                {
                  id: "theatres",
                  title: "Theatres",
                  panel: <CampaignTheatresList />,
                },
              ]}
            />
            <div style={{ paddingTop: "20px" }}>{this.renderCampaignTree()}</div>
          </div>
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={this.onDiscardChanges}
          onConfirm={this.handleConfirmCreate}
          title="Popup.campaignTitle"
          body="Popup.campaignBody"
          confirmButtonText="Popup.campaignConfirmation"
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.campaigns,
  (state) => state.userData,
  (state) => state.timesOfDay,
  (campaignCreate, campaigns, userData, timesOfDay) => ({
    campaignCreate,
    userData: userData.user,
    timesOfDay: timesOfDay,
    campaigns,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(CampaignProposal));
