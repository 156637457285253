import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import { FORM_FIELD_TYPE, REASON_TYPES } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import LocalizedButton from "../../../common/LocalizedButton";
import Form from "../../../common/Form";
import * as constants from "../../../../constants";
import { getAllReasonsByType } from "../../../../api";
import BulkActionButtonsStyles from "../../../../styles/BulkActionButtons.module.scss";
import styles from "../../../../styles/Campaigns/PauseOrResumeActions.module.scss";

export default class PauseOrResumeActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: {
        from: props.isEdit ? props.period.fromDate : null,
        to: props.isEdit ? props.period.toDate : null,
      },
      hasError: false,
      reason: null,
      notes: "",
      showEdit: props.showEdit,
    };
  }

  static propTypes = {
    showEdit: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    onPauseOrResumeAction: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isEdit: false,
    period: { fromDate: null, toDate: null },
    selectionInfo: null,
    validity: null,
  };

  componentWillUnmount = () => {
    this.cancelAction();
  };

  cancelAction = () => {
    this.setState({
      hasError: false,
      period: {
        from: null,
        to: null,
      },
      showEdit: false,
    });
    this.props.onClose();
  };

  showEditForm = (show = false) => {
    this.setState({
      showEdit: show,
      period: {
        from: null,
        to: null,
      },
    });
  };

  //check in selected campaign list if entered fromDate isBefore any campaign's start Date
  validateMinDate = (fromDate) => {
    const { selected, validity } = this.props;
    if (!fromDate) return true;
    return (
      selected.findIndex((campaign) => {
        const validityData = validity ? validity : campaign.validity;
        return moment(fromDate, "YYYY-MM-DD").isBefore(
          moment(validityData?.fromDate, "YYYY-MM-DD")
        );
      }) > -1
    );
  };

  //check in selected campaign list if entered toDate isAfter any campaign's end Date
  validateMaxDate = (toDate) => {
    const { selected, validity } = this.props;

    if (!moment(toDate, "YYYY-MM-DD").isValid()) return false;
    return (
      selected.findIndex((campaign) => {
        const validityData = validity ? validity : campaign.validity;
        return moment(toDate, "YYYY-MM-DD").isAfter(validityData?.toDate);
      }) > -1
    );
  };

  save = () => {
    const { period, reason, notes } = this.state;
    const { isPaused } = this.props;
    //validating fromDate,toDate,reason
    if (this.validateMinDate(period.from) || (isPaused && (!period.to || isEmpty(reason)))) {
      this.setState({ hasError: true });
      return;
    }
    this.props.onPauseOrResumeAction({ ...period, reason, notes });
    this.cancelAction();
  };

  clear = () => {
    this.props.onPauseOrResumeAction({ clearPause: true });
    this.cancelAction();
  };

  onDateInputChange = (value, accessor) => {
    const { period } = this.state;
    this.setState({
      period: { ...period, [accessor]: value },
    });
  };

  inputOnChange = (value, accessor) =>
    this.setState({
      [accessor]: value,
    });

  getConsumedCampaignList = () => {
    const { selected } = this.props;
    const { CAMPAIGN_STATUSES } = constants;

    return selected.filter((campaign) => campaign.status === CAMPAIGN_STATUSES.CONSUMED);
  };

  renderConsumedCampaignInfo = () => {
    const { selected, history } = this.props;
    const consumedCampaignList = this.getConsumedCampaignList();

    if (selected.length === 0) return null;

    if (consumedCampaignList.length) {
      return (
        <>
          <p className={styles.actionInfo}>
            {consumedCampaignList.length > 1
              ? `One of the Selected Campaign has consumed its maximum play`
              : `The Above Campaign has consumed its max play Please Increase its maximum play`}
          </p>
          {consumedCampaignList.length === 1 && (
            <LocalizedButton
              className={`${styles.editButton}`}
              iconName="EditIcon"
              text="Button.editCampaign"
              onClick={(e) => {
                this.cancelAction();
                history.push(`/campaigns/campaigns/${consumedCampaignList[0].id}/details`);
              }}
            />
          )}
        </>
      );
    }
  };

  render = () => {
    const {
      isOpen,
      header,
      selectionInfo,
      isPaused,
      isEdit,
      actionType,
      validity,
      selected,
    } = this.props;
    const { period, hasError, showEdit, reason, notes } = this.state;
    const { CAMPAIGN_ACTIONS } = constants;
    const consumedCampaigns = this.getConsumedCampaignList();
    // TODO: Update Message from copy once updated
    //!Date Range Validation
    //minDate= max(max(startDates...),today)
    let minDate = validity?.fromDate
      ? validity.fromDate
      : moment.max(selected.map((campaign) => moment(campaign?.validity?.fromDate)));
    //minDate not before today
    minDate = new Date() > new Date(minDate) ? new Date() : new Date(minDate);
    //maxDate=min(endDates...)
    const maxDate = validity?.toDate
      ? new Date(this.props.validity.toDate)
      : new Date(moment.min(selected.map((campaign) => moment(campaign?.validity?.toDate))));
    const fromDateMin = minDate;
    const fromDateMax = period.to && maxDate > new Date(period.to) ? new Date(period.to) : maxDate;
    const toDateMin = period.from ? new Date(period.from) : new Date();
    const toDateMax = maxDate;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelAction()} //onClose openbulkAction set null
        header={header}
        showFooter={true}
        primaryButtonProps={
          isEdit
            ? [
                {
                  text: "Button.save",
                  onClick: this.save,
                  className: styles.save,
                  disabled: consumedCampaigns.length > 0 || !showEdit,
                },
                // clear Pause/ clear Resume functionality not used
                {
                  text: "Button.clear",
                  onClick: this.clear,
                  className: styles.clear,
                  disabled: consumedCampaigns.length > 0 || !showEdit,
                },
              ]
            : {
                text: isPaused ? "Button.pause" : "Button.resume",
                onClick: this.save,
                // if status of the selected campaign is consumed then disable the button or if showEdit is false
                disabled: consumedCampaigns.length > 0 || !showEdit,
              }
        }
        secondaryButtonProps={{ text: "Button.close", onClick: this.cancelAction }}
      >
        {!isEmpty(selectionInfo) && (
          <div className={BulkActionButtonsStyles.selectionInfoContainer}>
            <div className={BulkActionButtonsStyles.selectionInfo}>
              <span>{selectionInfo.count}</span> {selectionInfo.label} Selected
            </div>
          </div>
        )}
        {this.renderConsumedCampaignInfo()}
        {consumedCampaigns.length === 0 && isEdit && !showEdit && (
          <>
            <p className={styles.actionInfo}>
              Campaigns have {actionType} action at different dates
            </p>
            <LocalizedButton
              className={`${styles.removeButton}`}
              iconName="CancelFilledIcon"
              text="Button.removeDurations"
              onClick={(e) => this.showEditForm(true)}
            />
          </>
        )}
        {consumedCampaigns.length === 0 && (!isEdit || showEdit) && (
          <Form
            config={[
              {
                type: FORM_FIELD_TYPE.DATE,
                title: `${isPaused ? "Pause" : "Resume"} From`,
                error: hasError && (!period.from || this.validateMinDate(period.from)),
                errorMessage: "Enter Valid From Date",
                invalidDateMessage: "Invalid From Date",
                onChange: (v) => {
                  this.onDateInputChange(moment(v).format("YYYY-MM-DDTHH:mm:ss[Z]"), "from");
                },
                value: period.from ? new Date(period.from) : null,
                placeholder: "MMM DD, YYYY",
                minDate: fromDateMin,
                defaultValue: fromDateMin,
                maxDate: fromDateMax,
                disabled: fromDateMin > fromDateMax,
              },
              {
                type: FORM_FIELD_TYPE.DATE,
                isHidden: !isPaused,
                title: `${isPaused ? "Pause" : "Resume"} To`,
                error: hasError && (!period.to || this.validateMaxDate(period.to)),
                errorMessage: "Enter Valid To Date",
                invalidDateMessage: "Invalid To Date",
                onChange: (v) => {
                  this.onDateInputChange(moment(v).format("YYYY-MM-DDTHH:mm:ss[Z]"), "to");
                },
                value: period.to ? new Date(period.to) : null,
                placeholder: "MMM DD, YYYY",
                minDate: toDateMin,
                defaultValue: period.from ? new Date(period.from) : new Date(),
                maxDate: toDateMax,
                disabled: toDateMin > toDateMax,
              },
              {
                type: FORM_FIELD_TYPE.API_SELECT,
                id: "reason",
                title: "Reason",
                label: "",
                isHidden: !isPaused,
                placeholder: "Select Reason",
                fetchAction: () => getAllReasonsByType(REASON_TYPES.campaign),
                parseResults: (response) => {
                  return response.data.map((item) => ({
                    id: item.id,
                    value: item.reason,
                  }));
                },
                selected: (list) => list.filter((item) => item.id === reason),
                singleSelect: true,
                showLabelInButton: false,
                onSelect: ([value]) => {
                  this.inputOnChange(value.id, "reason");
                },
                error: hasError && !reason,
                errorMessage: "Reason Cannot be Empty",
              },
              {
                id: "comments",
                type: FORM_FIELD_TYPE.TEXT_AREA,
                title: "Comments",
                placeholder: "Enter Comments",
                value: notes,
                onChange: (e) => this.inputOnChange(e.target.value, "notes"),
              },
            ]}
          />
        )}
        <div className={styles.campaignActionInfo}>
          {actionType === CAMPAIGN_ACTIONS.PAUSE ? (
            <span>
              All schedules on the start day may not be paused entirely. Any applicable cancellation
              fees will be charged on pausing the campaign
            </span>
          ) : (
            <span>All schedules on the from day may not be resumed entirely</span>
          )}
        </div>
      </RightPanel>
    );
  };
}
