import { FormGroup, TextArea, Radio, RadioGroup } from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { THEATRE_CRITERIA_MENU_LIST } from "../../constants";
import styles from "../../styles/SelectByIdPanel.module.scss";
import cleanIDSelection from "../../utils/cleanIDSelection";
import DetailsLoading from "./DetailsLoading";
import RightPanel from "./RightPanel";

export default class SelectByIdPanel extends Component {
  state = {
    hasFormSaved: false,
    idInputValue: "",
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getSelectedIds: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    selectedCriteriaType: PropTypes.object.isRequired,
    onCriteriaChange: PropTypes.func.isRequired,
    invalidIds: PropTypes.array,
    isLoading: PropTypes.bool,
    primaryButtonProps: PropTypes.object,
    secondaryButtonProps: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const defaultCriteria =
        THEATRE_CRITERIA_MENU_LIST.length > 0 ? THEATRE_CRITERIA_MENU_LIST[0] : null;

      if (defaultCriteria) {
        this.props.onCriteriaChange(defaultCriteria);
      }
    }

    // Clear TextArea if panel is closed
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ idInputValue: "" });
    }
  }

  handleFormCancel = () => {
    this.props.onClose();
    this.setState({ idInputValue: "" });
  };

  handleInputChange = (event) => {
    if (this.props.getSelectedIds) {
      const rawInput = event.target.value;
      const selection = cleanIDSelection(rawInput);
      this.setState({ idInputValue: rawInput });
      this.props.getSelectedIds(selection);
    }
  };

  handleCriteriaChange = (event) => {
    const selectedCriteriaId = event.target.value;
    const selectedCriteria = THEATRE_CRITERIA_MENU_LIST.find(
      (criteria) => criteria.id === selectedCriteriaId
    );
    const { onCriteriaChange } = this.props;
    if (onCriteriaChange && selectedCriteria) {
      onCriteriaChange(selectedCriteria);
    }
  };

  renderPanelContent = () => {
    if (this.props.isLoading) return <DetailsLoading />;
    const { invalidIds, selectedCriteriaType } = this.props;

    return (
      <div>
        {invalidIds && invalidIds.length > 0 ? (
          <div className={styles.invalidIdsContainer}>
            <div className={styles.invalidIdsHeader}>
              {`No matches found for the following ${invalidIds.length}${
                invalidIds.length > 1 ? " IDs" : " ID"
              }.`}
            </div>
            <div className={styles.invalidIdsContent}>
              {invalidIds.map((id, index) => (
                <div key={index}>
                  <span>{index + 1}. </span>
                  <span>{id}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.criteriaTypeContainer}>
              <label className={styles.criteriaTypeLabel}>ID Type:</label>
              <RadioGroup
                className={styles.criteriaTypeRadioGroup}
                onChange={this.handleCriteriaChange}
                selectedValue={selectedCriteriaType.id}
                inline
              >
                {THEATRE_CRITERIA_MENU_LIST.slice(0, 2).map((criteria) => (
                  <Radio
                    key={criteria.id}
                    value={criteria.id}
                    label={criteria.label === "Screen By ID" ? "Screen" : "Theatre"}
                  />
                ))}
              </RadioGroup>
            </div>
            <FormGroup
              label="IDs:"
              contentClassName={styles.inputContainer}
              helperText="Separate multiple IDs with a new line or a comma. This will override your current selection."
            >
              <TextArea
                fill
                rows={15}
                onChange={this.handleInputChange}
                value={this.state.idInputValue}
              />
            </FormGroup>
          </div>
        )}
      </div>
    );
  };

  render = () => {
    const { isOpen, header, primaryButtonProps, secondaryButtonProps } = this.props;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={this.handleFormCancel}
        header={header}
        showFooter={true}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      >
        {this.renderPanelContent()}
      </RightPanel>
    );
  };
}
