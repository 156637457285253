import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { findIndex, isEmpty, map, flatten, merge } from "lodash";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Icons, BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { FORM_FIELD_TYPE } from "../../../../../constants";
import { bindDispatch } from "../../../../../utils";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import styles from "../../../../../styles/Inventory/EditScheduleTimings.module.scss";
import bulkActionStyles from "../../../../../styles/BulkActionButtons.module.scss";

const { RemoveIcon } = Icons;

export class EditSchedulingTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasFormSaved: false,
      errors: {
        exceptions: [],
        allContent: [],
      },
      schedulingTime: {
        startTime: "00:00:00",
        endTime: "00:00:00",
        exceptions: [],
      },
    };
  }

  componentDidMount = async () => {
    let { schedulingTime } = this.state;
    const {
      actions,
      selectedScreens,
      isBulkAction,
      screens: { screenSettingsEdit },
    } = this.props;

    if (isBulkAction) await actions.getContentTypesList();

    if (!isBulkAction && screenSettingsEdit?.setting?.schedulingTime) {
      schedulingTime = screenSettingsEdit?.setting?.schedulingTime;
    } else if (selectedScreens.length === 1) {
      const [setting] = selectedScreens[0].screenSettings;
      schedulingTime = setting?.schedulingTime;
    }
    this.setState({
      schedulingTime,
      isLoading: false,
    });
  };

  closeEditPanel = () => {
    const {
      onClose,
      isBulkAction,
      actions,
      history,
      match: { params },
    } = this.props;

    if (isBulkAction) {
      onClose();
    } else {
      actions.cancelSettingEdit();
      history.push(`/screens/${params.screenId}/${params.tabId}`);
      this.setState({
        hasFormSaved: false,
        errors: {
          exceptions: [],
          allContent: [],
        },
        schedulingTime: {
          startTime: "00:00:00",
          endTime: "00:00:00",
          exceptions: [],
        },
      });
    }
  };

  onInputChange = (value, accessor, isException, index, contentTypeId) => {
    const { schedulingTime = {} } = this.state;
    const selectedIndex = isException ? this.getSelectedExceptionIndex(index, contentTypeId) : -1;

    if (isException && selectedIndex > -1) {
      schedulingTime.exceptions[selectedIndex][accessor] = value;
    } else {
      schedulingTime[accessor] = value;
    }

    this.setState({
      hasFormSaved: false,
      schedulingTime,
    });
  };

  getSelectedExceptionIndex = (removeIndex, contentTypeId) => {
    const { schedulingTime } = this.state;

    return findIndex(
      schedulingTime.exceptions,
      (item, index) => item.contentTypeId === contentTypeId && index === removeIndex
    );
  };

  handleDelete = (contentTypeId, removedIndex) => {
    const { schedulingTime } = this.state;
    schedulingTime.exceptions = schedulingTime.exceptions.filter(
      (item, index) => contentTypeId !== item.contentTypeId && removedIndex !== index
    );

    this.setState({
      hasFormSaved: false,
      schedulingTime,
    });
  };

  getFilteredContentType = (contentTypeId) => {
    const { schedulingTime } = this.state;
    const { contentTypes } = this.props;

    const selectedContentIds =
      schedulingTime?.exceptions
        ?.filter((data) => data.contentTypeId !== contentTypeId)
        .map((data) => data.contentTypeId) ?? [];

    if (contentTypes.length === 0) return [];

    return contentTypes
      ?.filter((item) => !selectedContentIds.includes(item.id))
      .map((item) => {
        return {
          id: item.id,
          value: item.code,
        };
      });
  };

  validateAndSaveSchedulingTime = (schedulingTime = null) => {
    const newSchedulingTime = this.state.schedulingTime || schedulingTime;
    const newErrors = {
      exceptions: [],
      allContent: [],
    };

    map(newSchedulingTime.exceptions, (exception, index) => {
      const errorList = this.validateSchedulingTimeInput(exception.startTime, exception.endTime);
      newErrors.exceptions[index] = errorList.length > 0 ? errorList : [];
      if (isEmpty(exception.contentTypeId)) {
        newErrors.exceptions[index].push("Please select content type");
      }
    });

    newErrors.allContent = this.validateSchedulingTimeInput(
      newSchedulingTime.startTime,
      newSchedulingTime.endTime
    );

    if (isEmpty(flatten(newErrors.exceptions)) && isEmpty(newErrors.allContent))
      this.props.onSave(newSchedulingTime);

    this.setState({
      hasFormSaved: true,
      errors: newErrors,
    });
  };

  validateSchedulingTimeInput = (startTime, endTime) => {
    const errors = [];

    if (isEmpty(startTime) && isEmpty(endTime)) {
      errors.push("Please enter scheduling timings");
    } else if (isEmpty(startTime)) {
      errors.push("Please enter start time");
    } else if (isEmpty(endTime)) {
      errors.push("Please enter end time");
    } else if (startTime === endTime) {
      errors.push("Start and end time cannot be same");
    }
    return errors;
  };

  validateEmptyExceptions = () => {
    const { schedulingTime } = this.state;
    const emptyIndex =
      schedulingTime?.exceptions?.findIndex(
        (exception) =>
          isEmpty(exception.contentTypeId) ||
          isEmpty(exception.startTime) ||
          isEmpty(exception.endTime)
      ) ?? -1;

    return emptyIndex < 0;
  };

  handleAddException = () => {
    const { schedulingTime } = this.state;
    if (isEmpty(schedulingTime?.exceptions)) {
      schedulingTime.exceptions = [{ contentTypeId: "", startTime: "", endTime: "" }];
    } else {
      schedulingTime.exceptions.push({ contentTypeId: "", startTime: "", endTime: "" });
    }

    this.setState({
      schedulingTime: schedulingTime,
    });
  };

  renderContentScheduleInput = (
    contentTypeId = null,
    startTime = "",
    endTime = "",
    index,
    isException = true
  ) => {
    const { hasFormSaved } = this.state;
    const contentList = this.getFilteredContentType(contentTypeId);

    return (
      <div className={styles.container} key={index}>
        <div className={classNames("flex", styles.formWrapper)}>
          <div className={classNames("flex", styles.contentInputWrapper)}>
            {isException ? (
              <>
                <div className={styles.exceptionIcon}>
                  <RemoveIcon />
                </div>
                <Form
                  config={[
                    {
                      id: "type",
                      title: "",
                      label: "",
                      singleSelect: true,
                      size: {
                        md: 9,
                        lg: 9,
                        xl: 3,
                        sm: 12,
                      },
                      showLabelInButton: false,
                      type: FORM_FIELD_TYPE.SELECT,
                      containerClassName: styles.contentInput,
                      className: "col col-12",
                      placeHolder: "Select Content",
                      usePortal: false,
                      list: contentList,
                      error: hasFormSaved && isEmpty(contentTypeId),
                      selectedList: contentTypeId
                        ? contentList.filter((item) => item.id === contentTypeId)
                        : [],
                      onSelect: ([data]) =>
                        this.onInputChange(
                          data.id,
                          "contentTypeId",
                          isException,
                          index,
                          contentTypeId
                        ),
                    },
                  ]}
                />
              </>
            ) : (
              <Form
                config={[
                  {
                    id: "type",
                    size: 12,
                    type: FORM_FIELD_TYPE.BUTTON,
                    isNonLocalizedButton: true,
                    buttonText: "All Content",
                    buttonType: BUTTON_TYPE.DEFAULT,
                    className: styles.allContentButton,
                  },
                ]}
              />
            )}
          </div>
          <div
            className={classNames(
              "flex",
              styles.timeInputWrapper,
              !isException && styles.allContentWrapper
            )}
          >
            <Form
              config={[
                {
                  type: FORM_FIELD_TYPE.TIME_RANGE_PICKER,
                  id: "fromTime",
                  title: "",
                  size: 5,
                  usePortal: false,
                  className: styles.timePicker,
                  value: new Date(moment(startTime, "HH:mm")),
                  error: hasFormSaved && (isEmpty(startTime) || startTime === endTime),
                  onChange: (time) =>
                    this.onInputChange(
                      moment(time).format(`HH:mm:ss`),
                      "startTime",
                      isException,
                      index,
                      contentTypeId
                    ),
                },
              ]}
            />
            <div className={styles.dividerText}>to</div>
            <Form
              config={[
                {
                  type: FORM_FIELD_TYPE.TIME_RANGE_PICKER,
                  id: "toTime",
                  title: "",
                  usePortal: false,
                  size: 5,
                  className: styles.timePicker,
                  value: new Date(moment(endTime, "HH:mm")),
                  error: hasFormSaved && (isEmpty(startTime) || startTime === endTime),
                  onChange: (time) =>
                    this.onInputChange(
                      moment(time).format(`HH:mm:ss`),
                      "endTime",
                      isException,
                      index,
                      contentTypeId
                    ),
                },
              ]}
            />
          </div>
          {isException ? (
            <BaseButton
              className={styles.clearButton}
              iconName="CancelRoundedIcon"
              buttonSize={SIZE.REGULAR}
              onClick={() => this.handleDelete(contentTypeId, index)}
            />
          ) : (
            <div className={styles.emptyCancelIcon} />
          )}
        </div>
      </div>
    );
  };

  renderScreenSelectionDetails = () => {
    const { selectionInfo } = this.props;
    return (
      <div className={bulkActionStyles.selectionInfoContainer}>
        {selectionInfo.map(({ count, label }, index) => (
          <div className={bulkActionStyles.selectionInfo} key={index}>
            <span>{count}</span> {label} {index + 1 === selectionInfo.length ? "Selected" : null}
          </div>
        ))}
      </div>
    );
  };

  render = () => {
    const { errors, schedulingTime, hasFormSaved, isLoading } = this.state;
    const {
      isOpen,
      isBulkAction,
      screens: { screenSettingsEdit, isSaveScheduleTimingsLoading },
    } = this.props;
    const errorList = merge(flatten(errors.exceptions), errors.allContent) ?? [];
    const isPanelOpen = isBulkAction ? isOpen : screenSettingsEdit?.isOpen;
    return (
      <RightPanel
        isOpen={isPanelOpen}
        isLoading={isSaveScheduleTimingsLoading || isLoading}
        onClose={this.closeEditPanel}
        header="RightPanelHeader.editScheduleTimings"
        showFooter
        width={360}
        className={styles.rightPanelContainer}
        primaryButtonProps={{
          text: "Button.save",
          onClick: this.validateAndSaveSchedulingTime,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.closeEditPanel }}
      >
        {isBulkAction && this.renderScreenSelectionDetails()}
        <div className={classNames("col col-12", styles.header, styles.container)}>
          <div className={classNames("col-4", styles.title)}>Content Type</div>
          <div className={classNames("col-8", styles.title)}>Timings</div>
        </div>
        {this.renderContentScheduleInput(
          null,
          schedulingTime?.startTime,
          schedulingTime?.endTime,
          "allContent",
          false
        )}
        {schedulingTime?.exceptions?.map((item, index) =>
          this.renderContentScheduleInput(item.contentTypeId, item.startTime, item.endTime, index)
        )}
        {this.validateEmptyExceptions() && (
          <BaseButton
            iconName="AddFilledIcon"
            buttonText="Add Another Exception"
            buttonSize={SIZE.REGULAR}
            onClick={this.handleAddException}
            buttonType={BUTTON_TYPE.DEFAULT}
            className={styles.addException}
          />
        )}
        {hasFormSaved && (
          <div className={classNames("flex", styles.errorWrapper)}>
            {errorList.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}
      </RightPanel>
    );
  };
}

EditSchedulingTime.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedScreens: PropTypes.array,
  selectionInfo: PropTypes.array,
  contentTypes: PropTypes.array,
  isBulkAction: PropTypes.bool,
};
EditSchedulingTime.defaultProps = {
  isBulkAction: false,
  selectionInfo: [],
  selectedScreens: [],
  contentTypes: [],
};

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.contentType,
  (state) => state.userData,
  (screens, contentType, userData) => ({
    screens: screens,
    contentTypes: contentType.data.data,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(EditSchedulingTime));
