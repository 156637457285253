import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "workbench";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/TableLoading.module.scss";
import { DEFAULT_LOADING_ROW_COUNT } from "../../constants";

export default class TableLoading extends Component {
  render() {
    const { columns, rowsCount, style } = this.props;

    const modifiedColumns = columns.map((column, index) => {
      if (!column.id && !column.accessor) {
        throw new Error(`Either id or accessor should be provided at Column ${column.Header}`);
      }
      return {
        id: column.id || column.accessor,
        Header: column.Header,
        accessor: () => (
          <span className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        ),
        width: column.width,
        sortable: column.sortable,
        resizable: column.resizable,
        className: styles.clipTextOverflow,
      };
    });
    const data = Array(rowsCount).fill({});
    return (
      <Table
        style={style}
        columns={modifiedColumns}
        data={data}
        headerClassName={Classes.SKELETON}
      />
    );
  }
}

TableLoading.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
      ]),
    })
  ).isRequired,
  rowsCount: PropTypes.number,
};

TableLoading.defaultProps = {
  rowsCount: DEFAULT_LOADING_ROW_COUNT,
};
