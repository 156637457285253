import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
// import { Intent } from "@blueprintjs/core";
import moment from "moment";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { padLeft, checkScopeAuth } from "../../../../utils";
import * as constants from "../../../../constants";
import { FORM_FIELD_TYPE, MONTH_OF_YEAR } from "../../../../constants";
import styles from "../../../../styles/Settings/CompanySettings.module.scss";
import InfoBlock from "../../../common/InfoBlock";
// import { SearchIcon } from "../../../common/SearchIcon";
// import RemoveIcon from "../../../common/RemoveIcon";
import InfoBlockLoading from "../../../common/InfoBlockLoading";
import Form from "../../../common/Form";
import LocalizedButton from "../../../common/LocalizedButton";

export default class CompanySettings extends Component {
  state = {
    settings: {},
    fiscalMonth: "",
    fiscalDate: "",
    errors: [],
    hasFormEdited: false,
    thirdpartyIdentifierTypes: [],
  };

  componentDidMount() {
    this.updateCompanyPreferences();
  }

  updateCompanyPreferences = () => {
    const {
      userData: { company },
    } = this.props;
    const fiscalYear = moment(company.preferences.financialYearBeginning, "DD-MM").toObject();

    this.setState({
      fiscalMonth: fiscalYear.months + 1 || "",
      fiscalDate: fiscalYear.date || null,
      settings: company.preferences,
      hasFormEdited: false,
      errors: [],
    });
  };

  onInputChange = (data, key) => {
    const currentState = this.state;
    currentState.hasFormEdited = true;

    if (key === "fiscalMonth") {
      if (data !== "None") {
        currentState[key] = MONTH_OF_YEAR.find((month) => month.label === data).value;
      } else {
        currentState[key] = "";
        currentState.fiscalDate = null;
      }
    } else {
      currentState[key] = data;
    }

    this.setState(currentState);
  };

  getFiscalYearErrors = (state = null) => {
    const currentState = state ? state : this.state;
    const {
      userData: { company },
    } = this.props;
    const { fiscalMonth, fiscalDate } = currentState;
    const maxDate = moment(fiscalMonth, "MM").daysInMonth();
    const errors = [];
    if (!fiscalMonth) return [];

    if (company.preferences.financialYearBeginning && !fiscalDate) errors.push("Please Enter Date");
    if (parseInt(fiscalDate) > maxDate) errors.push("Please Enter Valid Date");

    return errors;
  };

  handleFormSave = () => {
    const { fiscalMonth, fiscalDate, settings, thirdpartyIdentifierTypes } = this.state;
    const {
      saveCompanyPreferences,
      userData: { company },
    } = this.props;

    const errors = this.getFiscalYearErrors();
    const financialYear = `${padLeft(fiscalDate, 2)}-${padLeft(fiscalMonth, 2)}`;
    settings.thirdpartyIdentifierTypes = thirdpartyIdentifierTypes || "";
    if (fiscalMonth) {
      if (company.preferences.financialYearBeginning !== financialYear && errors.length === 0) {
        settings.financialYearBeginning = `${padLeft(fiscalDate, 2)}-${padLeft(fiscalMonth, 2)}`;
        saveCompanyPreferences(settings, company.id);
      }
      this.setState({ settings, errors });
    } else if (settings.financialYearBeginning && !fiscalMonth && !fiscalDate) {
      settings.financialYearBeginning = "";
      saveCompanyPreferences(settings, company.id);
      this.setState({ settings, errors });
    }
  };

  removeItem = (itemIndex, key) => {
    const { thirdpartyIdentifierTypes } = this.state;
    const filterIdentifier = thirdpartyIdentifierTypes.filter((item, index) => index !== itemIndex);
    this.setState({
      thirdpartyIdentifierTypes: isEmpty(filterIdentifier) ? "" : filterIdentifier,
    });
  };

  isSelected = (data) => {
    const { thirdpartyIdentifierTypes } = this.state;
    return (
      thirdpartyIdentifierTypes &&
      thirdpartyIdentifierTypes.filter((identifier) => identifier.name === data.name).length > 0
    );
  };

  renderMenu = (data, { modifiers, handleClick }) => (
    <MenuItem
      key={data.name}
      active={modifiers.active}
      icon={this.isSelected(data) ? "tick" : "blank"}
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  render() {
    const {
      userData,
      // searchThirdpartyIdentifier,
      // thirdpartyIdentifiers: { data },
    } = this.props;
    const {
      fiscalDate,
      fiscalMonth,
      hasFormEdited,
      errors,
      // thirdpartyIdentifierTypes,
    } = this.state;
    const isLoading = isEmpty(userData.company);
    const hasWriteAccess = checkScopeAuth(
      userData,
      constants.SCOPES.COMPANIES_PREFERENCES,
      constants.SCOPE_ACTIONS.WRITE
    );

    if (isLoading) {
      return <InfoBlockLoading />;
    }

    return (
      <div className={styles.container}>
        <InfoBlock hideHeader>
          <div className="lg-col-6 md-col-12 sm-col-12">
            <div className={styles.labelText}>Fiscal Year Start Date</div>
            <div className={styles.content}>
              <Form
                config={[
                  {
                    id: "fiscalMonth",
                    label: "",
                    type: FORM_FIELD_TYPE.SELECT,
                    disabled: !hasWriteAccess,
                    size: {
                      lg: 3,
                      md: 3,
                      sm: 4,
                      xs: 6,
                    },
                    list: ["None", ...MONTH_OF_YEAR.map((item) => item.label)],
                    selectedList: fiscalMonth
                      ? [MONTH_OF_YEAR.find((month) => month.value === parseInt(fiscalMonth)).label]
                      : [],
                    placeHolder: "Select Month",
                    singleSelect: true,
                    showLabelInButton: false,
                    onSelect: ([value]) => this.onInputChange(value, "fiscalMonth"),
                    usePortal: false,
                  },
                  {
                    id: "fiscalDate",
                    label: "",
                    value: fiscalDate || "",
                    placeholder: "",
                    type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                    size: {
                      lg: 2,
                      md: 2,
                      sm: 2,
                      xs: 4,
                    },
                    maxValue: 31,
                    className: classNames("col-8", {
                      [styles.inputError]: errors.length > 0,
                    }),
                    disabled: !fiscalMonth || !hasWriteAccess,
                    onValueChange: (value) => this.onInputChange(value, "fiscalDate"),
                  },
                ]}
              />
            </div>
            <div className={styles.errorList}>
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
          {/* TODO: UNCOMMENT WHEN API IS READY */}
          {/* <div className="lg-col-12 md-col-12 sm-col-12">
            <div className={styles.labelText}>Third-party ID</div>
            <div className={styles.thirdPartyContent}>
              <Form
                config={[
                  {
                    id: "thirdPartyID",
                    type: FORM_FIELD_TYPE.MULTI_SELECT,
                    label: "",
                    items: data ? data : [],
                    itemRenderer: this.renderMenu,
                    size: {
                      lg: 4,
                      md: 4,
                      sm: 6,
                    },
                    onItemSelect: (thirdpartyIdentifierTypes) =>
                      this.setState({
                        thirdpartyIdentifierTypes: [
                          ...this.state.thirdpartyIdentifierTypes,
                          thirdpartyIdentifierTypes,
                        ],
                      }),
                    onQueryChange: searchThirdpartyIdentifier,
                    resetOnSelect: true,
                    tagRenderer: (data) => data.name,
                    tagInputProps: {
                      placeholder: "Select preferences",
                      tagProps: { intent: Intent.NONE, minimal: true },
                      disabled: true,
                      onRemove: (data, index) =>
                        this.removeItem(index, "thirdpartyIdentifierTypes"),
                      rightElement:
                        thirdpartyIdentifierTypes && thirdpartyIdentifierTypes.length ? (
                          <RemoveIcon
                            onClick={() => this.setState({ thirdpartyIdentifierTypes: [] })}
                          />
                        ) : (
                          <SearchIcon />
                        ),
                    },
                    selectedItems: thirdpartyIdentifierTypes,
                  },
                ]}
              />
            </div>
          </div> */}
        </InfoBlock>
        <div className={styles.actions}>
          <LocalizedButton
            className={styles.saveButton}
            onClick={this.handleFormSave}
            text="Button.saveChanges"
            iconName="ConfirmIcon"
            buttonType="constructive"
            disabled={!hasFormEdited}
            scope={constants.SCOPES.COMPANIES_PREFERENCES}
            scopeAction={constants.SCOPE_ACTIONS.WRITE}
            userData={userData}
          />
          <LocalizedButton
            className={styles.cancelButton}
            onClick={this.updateCompanyPreferences}
            text="Button.cancel"
            disabled={!hasFormEdited}
            scope={constants.SCOPES.COMPANIES_PREFERENCES}
            scopeAction={constants.SCOPE_ACTIONS.WRITE}
            userData={userData}
          />
        </div>
      </div>
    );
  }
}
