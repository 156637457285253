import { INITIALIZE, NAV_COUNT, DIMENSIONS } from "../types/app";

const initialState = {
  initialized: false,
  navCount: {
    archiveMedia: 0,
    campaignApproval: 0,
    campaigns: 0,
    media: 0,
    mediaApprovals: 0,
    movies: 0,
    organizationOrBrand: 0,
    playlistTemplates: 0,
    premiums: 0,
    rateCard: 0,
    theatresOrScreens: 0,
  },
  pageDimensions: {
    width: window.innerWidth,
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INITIALIZE:
      return { ...state, initialized: payload };
    case NAV_COUNT:
      return { ...state, navCount: payload };
    case DIMENSIONS:
      return { ...state, pageDimensions: payload };
    default:
      return state;
  }
}
