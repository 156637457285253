import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { compact, values, every } from "lodash";
import queryString from "query-string";
import { bindDispatch, checkScopeAuth } from "../../utils";
import { CUSTOMER_SEGMENT, COMPANY_STATUS } from "../../constants";
import EmptyPage from "../common/EmptyPage";

// Do not look to simplify or clean up unnecessary comments in this file.
// It is intentionally verbose, since these checks happen on literally every page (or route)!
export class ProtectedRoute extends Component {
  render() {
    const {
      scope,
      scopeAction,
      paramScopeAction,
      userData: { user },
      computedMatch: { params },
      location,
      path,
      actions,
    } = this.props;

    // this is line is add to fix the issue of first time loading in campaign create/edit page
    // this has nothing to do with protected route validation
    // in future this should be removed to find a permanent solution for this issue SLATE-1470
    if (!path?.includes("campaign-create")) actions.ActiveStep(-1);

    // Check if user is logged in or not (Duh!)
    if (user.loggedIn) {
      // Determine if it is currently running as Slate Admin App
      const isAdminApp = !!process.env.REACT_APP_IS_ADMIN_APP;
      // Determine if current user is part of Slate Admin Company
      const isSlateAdminCompanyUser =
        user.company.customerSegment.code === CUSTOMER_SEGMENT.SLATE_ADMIN;
      // If a Non Slate Admin user logs in to Admin App, do not allow them access and show error
      // NOTE: All navigation items need to be hidden as well, check should be in NavigationBar
      if (isAdminApp && !isSlateAdminCompanyUser) {
        // TODO: Might need to add a button to user app and/or a logout button
        return <EmptyPage heading="EmptyState.adminAppHeading" body="EmptyState.adminAppMessage" />;
      }

      // Vice-Versa as above. Slate Admin logs in to User App
      if (!isAdminApp && isSlateAdminCompanyUser) {
        return <EmptyPage heading="EmptyState.userAppHeading" body="EmptyState.userAppMessage" />;
      }

      // Check if user's company is approved by Slate Admin. If pending, do not show anything until approved.
      if (user.company.status === COMPANY_STATUS.PENDING) {
        return (
          <EmptyPage
            heading="EmptyState.waitingApprovalHeading"
            body="EmptyState.waitingApprovalMessage"
          />
        );
      }

      // Check if the user has roles before the scope check, as sometimes they would have been removed
      if (user.roles.length === 0)
        return <EmptyPage heading="EmptyState.noRolesHeading" body="EmptyState.noRolesMessage" />;

      // Check if the user has access to the route based on the scope and scope action
      // NOTE: Default scopeAction is READ in the check util, so can treat scopeAction as optional prop if only need to check READ
      const isAllowedRoute = checkScopeAuth(user, scope, scopeAction);
      if (isAllowedRoute) {
        // To Switch between Separate Admin & User components
        const { adminComponent, userComponent, ...routerProps } = this.props;
        if (adminComponent && userComponent)
          routerProps.component = isSlateAdminCompanyUser ? adminComponent : userComponent;

        // Check if current route has params. Can be undefined since params could be marked as optional.
        const hasParams = compact(values(params)).length > 0;

        // Check if we need to do further checking based on if the current route has param based checks,
        // and there are non empty param values in the current route
        // This is done to support partial scope checking for a page.
        // For instance, a user can have READ access to a scope, but not WRITE.
        // So we can allow access to the user if it is /route but not when it is /route/:actionParam
        const shouldCheckParamScope = paramScopeAction && hasParams;

        if (shouldCheckParamScope) {
          // Check if the user still has access based on param scope action
          let isAllowedParam;
          if (typeof paramScopeAction === "string")
            isAllowedParam = checkScopeAuth(user, scope, paramScopeAction);
          else {
            isAllowedParam = every(
              paramScopeAction,
              (value, key) => !params[key] || (params[key] && checkScopeAuth(user, scope, value))
            );
          }
          if (isAllowedParam) {
            return <Route {...routerProps} />;
          }
          // Show error message since user does not have to param action.
          else {
            return (
              <EmptyPage
                heading="EmptyState.notAllowedHeading"
                body="EmptyState.notAllowedMessage"
              />
            );
          }
        }
        // All is well. Can render the route now.
        else {
          return <Route {...routerProps} />;
        }
      }
      // Show error message since user does not have access to the route's scope
      else {
        return (
          <EmptyPage heading="EmptyState.notAllowedHeading" body="EmptyState.notAllowedMessage" />
        );
      }
    }
    // Kick user out if not logged in
    else {
      const { pathname, search } = location;
      const next = `${pathname}${search}`;
      const nextParam = queryString.stringify({ next });
      return <Redirect replace to={`/login?${nextParam}`} />;
    }
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData })
);

export default connect(mapStateToProps, bindDispatch)(ProtectedRoute);
