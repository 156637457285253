import * as types from "../types/contentTypes";

const initialState = {
  isLoading: true,
  isError: null,
  contentTypeEdit: { isOpen: false, contentType: {} },
  data: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.DATA:
      return { ...state, data: payload };
    case types.CONTENT_TYPE_EDIT:
      return { ...state, contentTypeEdit: payload };
    default:
      return state;
  }
}
