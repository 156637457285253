import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Intent, FormGroup, InputGroup } from "@blueprintjs/core";
import styles from "../../styles/TextInput.module.scss";

class TextInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    leftIcon: PropTypes.object,
    rightElement: PropTypes.element,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    onlyNumbers: PropTypes.bool,
    labelClass: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    isOptional: false,
    type: "text",
    onlyNumbers: false,
  };

  handleInputChange = (event) => {
    const { value } = event?.target;
    const { onlyNumbers, onChange } = this.props;

    if (onlyNumbers) {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (!/^[0-9]*$/.test(value)) event.preventDefault();
      // Adding the check to avoid NaN
      const parsedValue = numericValue ? parseInt(numericValue) : "";
      onChange(parsedValue);
    } else {
      onChange(event);
    }
  };

  renderLabel = () => {
    const { label, isOptional, labelClass } = this.props;
    return (
      <div className={classNames(labelClass, styles.label)}>
        {label}
        {isOptional && <span>(Optional)</span>}
      </div>
    );
  };

  render() {
    const {
      id,
      className,
      error,
      errorMessage,
      onFocus,
      onBlur,
      onKeyDown,
      placeholder,
      leftIcon,
      rightElement,
      value,
      type,
      disabled,
      maxLength,
      min,
      max,
    } = this.props;
    return (
      <FormGroup
        helperText={error && <span>{errorMessage}</span>}
        label={this.renderLabel()}
        labelFor={id}
        className={classNames(className, styles.inputLabel)}
        intent={error ? Intent.DANGER : Intent.NONE}
        inline={true}
      >
        <InputGroup
          className={classNames(styles.inputField, { [styles.withRightIcon]: rightElement })}
          intent={error ? Intent.DANGER : Intent.NONE}
          id={id}
          onChange={this.handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          leftIcon={leftIcon}
          rightElement={rightElement}
          placeholder={placeholder}
          value={value || ""}
          type={type}
          disabled={disabled}
          maxLength={maxLength}
          min={min}
          max={max}
        />
      </FormGroup>
    );
  }
}

export default TextInput;
