import { compact, isEmpty } from "lodash";
import * as constants from "../../constants";

const { ACTIVE, INACTIVE, INACTIVE_IN_FUTURE, ACTIVE_IN_FUTURE } = constants.SCREEN_STATUSES;
const { STATIC_FILTER_TAG_LIST, TAG_TYPE, STATUS_ICON_TYPES, STATUS_ICON_COLORS } = constants;

const getScreenActivationStatus = (screens) => {
  if (screens?.length === 0) return [];

  return compact(
    screens.map((screen) => {
      if (!screen?.screenSettings?.screenStatus) return "";
      const [{ screenStatus, screenStatusList = [] }] = screen.screenSettings;
      const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
      const currentScreenStatus = isEmpty(futureScreenStatus) ? screenStatus : futureScreenStatus;
      // TODO: Update the flow once Action Required is done
      // No Status Available
      if (isEmpty(currentScreenStatus)) return "";

      // Returns either Active or Inactive Status
      return currentScreenStatus.screenStatus;
    })
  );
};

const getStatusDisplayName = (status) =>
  STATIC_FILTER_TAG_LIST[TAG_TYPE.SCREEN_STATUS].find((d) => d.id === status).displayName;

const getScreenStatusLegends = (screens) => {
  const statusList = getScreenActivationStatus(screens);
  const statusLegends = [
    {
      id: ACTIVE,
      name: getStatusDisplayName(ACTIVE),
      count: statusList.filter((d) => d === ACTIVE).length,
      type: STATUS_ICON_TYPES.CIRCLE,
      color: STATUS_ICON_COLORS.GREEN,
      tagType: TAG_TYPE.SCREEN_STATUS,
    },
    {
      id: INACTIVE,
      name: getStatusDisplayName(INACTIVE),
      count: statusList.filter((d) => d === INACTIVE).length,
      type: STATUS_ICON_TYPES.CIRCLE,
      color: STATUS_ICON_COLORS.GREY,
      tagType: TAG_TYPE.SCREEN_STATUS,
    },
    {
      id: INACTIVE_IN_FUTURE,
      name: getStatusDisplayName(INACTIVE_IN_FUTURE),
      count: statusList.filter((d) => d === INACTIVE_IN_FUTURE).length,
      type: STATUS_ICON_TYPES.DURATION,
      color: STATUS_ICON_COLORS.GREEN,
      tagType: TAG_TYPE.SCREEN_STATUS,
    },
    {
      id: ACTIVE_IN_FUTURE,
      name: getStatusDisplayName(ACTIVE_IN_FUTURE),
      count: statusList.filter((d) => d === ACTIVE_IN_FUTURE).length,
      type: STATUS_ICON_TYPES.DURATION,
      color: STATUS_ICON_COLORS.GREY,
      tagType: TAG_TYPE.SCREEN_STATUS,
    },
  ];

  return statusLegends;
};

export { getScreenActivationStatus, getStatusDisplayName, getScreenStatusLegends };
