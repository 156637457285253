import React from "react";
import { STATUS_COLORS, STATUS_ICON_TYPES } from "../constants";
import StatusIconLegend from "../components/common/StatusIconLegend";

const getStatusIconLegend = (data, tagType, onFilterSelect) => {
  const dataStatus = data.map((item) => item.status);
  const statusInfo = STATUS_COLORS.map((statusColor) => ({
    name: statusColor.label,
    count: dataStatus.filter((d) => d === statusColor.name).length,
    type: STATUS_ICON_TYPES.CIRCLE,
    color: statusColor.color,
    tagType,
  }));
  return <StatusIconLegend statusInfo={statusInfo} onFilterSelect={onFilterSelect} />;
};

export default getStatusIconLegend;
