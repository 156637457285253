import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { compact } from "lodash";
import { bindDispatch, getCategory, getBusinessType } from "../../../utils";
import InfoBlock from "../../common/InfoBlock";
import Breadcrumb from "../../common/BreadCrumb";
import styles from "../../../styles/Verifications/BrandVerifications.module.scss";
import InfoBlockLoading from "../../common/InfoBlockLoading";
import { SPECIAL_CHARACTERS } from "../../../constants";
import PageHeader from "../../common/PageHeader";
import pageStyles from "../../../styles/App.module.scss";

const _LINKS = [
  { name: "Brand Verifications", path: "/brand-verifications" },
  { name: "Rejected Brand Verifications", path: "/brand-verifications/rejected" },
];

export class RejectedVerification extends Component {
  componentDidMount = () => {
    this.getRejectedVerification();
    this.props.actions.getBusinessTypesList();
  };

  getRejectedVerification = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    actions.getRejectedBrandVerification(params.brandType, params.draftId);
  };

  renderName = ({ parentBrandName, individualBrandName, productName, name }) =>
    compact([parentBrandName, individualBrandName, productName, name]).join(
      ` ${SPECIAL_CHARACTERS.MIDDLE_DOT}`
    );

  renderUpdateDetails = (data, accessor) => {
    return (
      <>
        <div className={styles.oldText}>{data.old[accessor]}</div>
        <div className={styles.newText}>{data[accessor]}</div>
      </>
    );
  };

  compareCategories = (cat1, cat2) => {
    return (
      cat1.categoryId === cat2.categoryId &&
      cat1.subCategory1Id === cat2.subCategory1Id &&
      cat1.subCategory2Id === cat2.subCategory2Id
    );
  };

  renderCategories = (data, accessor) => {
    if (data.old?.[accessor]) {
      const oldData = data.old[accessor];
      const newData = data[accessor];

      const commonCategories = newData
        .filter((category) => oldData.some((cat) => this.compareCategories(category, cat)))
        .map((category, i) => <div key={i}>{getCategory(category)}</div>);

      const newCategories = newData
        .filter((category) => !oldData.some((cat) => this.compareCategories(category, cat)))
        .map((category, i) => (
          <div key={i} className={styles.newText}>
            {getCategory(category)}
          </div>
        ));

      const oldCategories = oldData
        .filter((category) => !newData.some((cat) => this.compareCategories(category, cat)))
        .map((category, i) => (
          <div key={i} className={styles.oldText}>
            {getCategory(category)}
          </div>
        ));

      return (
        <>
          {commonCategories}
          {oldCategories}
          {newCategories}
        </>
      );
    } else {
      return data[accessor]?.map((category, key) => <div key={key}>{getCategory(category)}</div>);
    }
  };

  renderAdvertisers = (data, accessor) => {
    if (data.old?.[accessor]) {
      const oldData = data.old[accessor];
      const newData = data[accessor];

      const commonAdvertisers = newData
        .filter((advertiser) => oldData.some((adv) => adv.id === advertiser.id))
        .map((advertiser) => <div key={advertiser.id}>{advertiser.name}</div>);

      const newAdvertisers = newData
        .filter((advertiser) => !oldData.some((adv) => adv.id === advertiser.id))
        .map((advertiser) => (
          <div key={advertiser.id} className={styles.newText}>
            {advertiser.name}
          </div>
        ));

      const oldAdvertisers = oldData
        .filter((advertiser) => !newData.some((adv) => adv.id === advertiser.id))
        .map((advertiser) => (
          <div key={advertiser.id} className={styles.oldText}>
            {advertiser.name}
          </div>
        ));

      return (
        <>
          {commonAdvertisers}
          {oldAdvertisers}
          {newAdvertisers}
        </>
      );
    } else {
      return data[accessor]?.map((advertiser) => <div key={advertiser.id}>{advertiser.name}</div>);
    }
  };

  renderDetails = () => {
    const {
      brandVerifications: { rejectedBrandVerification },
    } = this.props;
    const { brand, rejectionReason, rejectionNotes } = rejectedBrandVerification;
    const fields = [
      { name: "Name", accessor: "name" },
      { name: "Code", accessor: "code" },
      { name: "Type", accessor: "businessType" },
      { name: "Advertisers", accessor: "advertisers" },
      { name: "Categories", accessor: "categories" },
      { name: "Product Identification Number", accessor: "productIdentificationNumber" },
      { name: "Variant Identification Number", accessor: "variantIdentificationNumber" },
    ];

    const config = fields.map((field) => {
      if (field.name === "Categories") {
        return (
          (brand[field.accessor]?.length > 0 || brand.old?.[field.accessor]?.length > 0) && {
            title: "Categories",
            value: this.renderCategories(brand, field.accessor),
          }
        );
      } else if (field.name === "Advertisers") {
        return (
          brand[field.accessor] &&
          (brand[field.accessor].length > 0 ||
            (brand.old && brand.old[field.accessor].length > 0)) && {
            title: "Advertisers",
            value: this.renderAdvertisers(brand, field.accessor),
          }
        );
      } else if (field.name === "Type") {
        return {
          title: field.name,
          value:
            brand.old &&
            brand.old[field.accessor] &&
            brand.old[field.accessor] !== brand[field.accessor]
              ? this.renderUpdateDetails(
                  brand,
                  getBusinessType(this.props.businessTypes.list, brand[field.accessor])
                )
              : getBusinessType(this.props.businessTypes.list, brand[field.accessor]),
        };
      } else
        return {
          title: field.name,
          value:
            brand.old &&
            brand.old[field.accessor] &&
            brand.old[field.accessor] !== brand[field.accessor]
              ? this.renderUpdateDetails(brand, field.accessor)
              : brand[field.accessor],
        };
    });

    return (
      <InfoBlock
        config={[
          ...compact(config),
          { title: "Rejection Reason", value: rejectionReason },
          { title: "Rejection Notes", value: rejectionNotes },
        ]}
      />
    );
  };

  render = () => {
    const {
      brandVerifications: { isLoading, rejectedBrandVerification },
    } = this.props;
    if (isLoading) return <InfoBlockLoading />;
    if (!rejectedBrandVerification?.brand) return null;
    const brandName = { name: this.renderName(rejectedBrandVerification.brand) };
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={this.props.history} links={[..._LINKS, brandName]} />
        <div className={pageStyles.pageContainer}>
          <PageHeader {...brandName} />
          <div className={styles.rejectedVerificationContainer}>
            {this.renderDetails(rejectedBrandVerification)}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.brandVerifications,
  (state) => state.businessTypes,
  (brandVerifications, businessTypes) => ({ brandVerifications, businessTypes })
);
export default connect(mapStateToProps, bindDispatch)(RejectedVerification);
