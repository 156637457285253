import React from "react";
import { isEmpty } from "lodash";
import { BUTTON_TYPE } from "prefab";
import { isSlateAdminCompanyUser, getCategory } from "../../../../../utils";
import InfoBlock from "../../../../common/InfoBlock";
import LocalizedButton from "../../../../common/LocalizedButton";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import { getValidity, getName, MediaPlacements } from "../../utils";
import { CAMPAIGN_STATUSES } from "../../../../../constants";
import CampaignActionDetails from "../../utils/CampaignActions";

const mediaDetails = (media, timesOfDay, userData, onContentAdd) => {
  const isAdminApp = isSlateAdminCompanyUser(userData);

  if (isEmpty(media)) return null;

  return (
    <>
      <InfoBlock
        header={"Media Details"}
        config={[
          {
            title: "Media Name & ID",
            value: getName(media, isAdminApp),
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Target Group",
            value: media.targetGroupName,
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Campaign",
            value: media.campaignName,
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Validity",
            value: getValidity(media),
            size: {
              lg: 12,
              md: 12,
            },
          },
          {
            title: "Content",
            value: media.contentId ? (
              media.contentName
            ) : (
              <LocalizedButton
                id="add"
                className={`${styles.addButton}`}
                buttonType={BUTTON_TYPE.PRIMARY}
                iconName="AddIcon"
                onClick={() => onContentAdd()}
                text="Button.add"
              />
            ),
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Content Type",
            value: media.contentTypeName,
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Duration",
            value: `${media.contentDurationInSeconds || 0} Seconds`,
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Brand",
            value: media.brand
              ? [
                  media.brand.parentBrandName,
                  media.brand.individualBrandName,
                  media.brand.productName,
                  media.brand.variantName,
                ]
                  .filter(Boolean)
                  .join(" · ")
              : "",
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
          {
            title: "Category",
            value: media.categories
              ? media.categories.map((category, key) => (
                  <div key={key}>{getCategory(category)}</div>
                ))
              : "",
            size: {
              lg: 6,
              md: 4,
              sm: 6,
            },
          },
        ]}
      />
      {media.status === CAMPAIGN_STATUSES.PAUSED && (
        <CampaignActionDetails
          header="Media Pause Details"
          actionList={media.statusChanges}
          status={media.status}
        />
      )}
      <MediaPlacements media={media} timesOfDay={timesOfDay} />
    </>
  );
};

export default mediaDetails;
