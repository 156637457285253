import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BaseButton, SIZE, BUTTON_TYPE } from "prefab";
import { findIndex, isEmpty, map, flatten, merge } from "lodash";
import { Icons } from "prefab";
import { FORM_FIELD_TYPE, DAY_OF_WEEK } from "../../../../../constants";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import BulkActionButtonsStyles from "../../../../../styles/BulkActionButtons.module.scss";
import styles from "../../../../../styles/Inventory/EditScheduleCutoffTime.module.scss";

const { RemoveIcon } = Icons;

export default class EditSchedulingCutoffTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFormSaved: false,
      isLoading: true,
      errors: {
        exceptions: [],
        allDays: [],
      },
      schedulingCutOffTime: {
        timeInSeconds: 0,
        exceptions: [],
      },
    };
  }

  componentDidMount = () => {
    const { selected } = this.props;
    if (selected.length === 1) {
      const [{ screenSettings: setting }] = selected;
      if (setting.length === 1) {
        const currentSetting = {
          timeInSeconds: setting[0]?.schedulingCutOffTimeInSeconds,
          exceptions: setting[0]?.schedulingCutOffTimeExceptions ?? null,
        };
        const exceptions = currentSetting?.exceptions
          ? Object.keys(currentSetting?.exceptions)
          : [];
        const exceptionsList = exceptions.map((day) => ({
          day: day,
          timeInSeconds: currentSetting.exceptions[day],
        }));
        currentSetting.exceptions = exceptionsList;
        this.setState({
          schedulingCutOffTime: currentSetting,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.onClose();
  };

  closeEditPanel = () => {
    this.setState({
      hasFormSaved: false,
      errors: {
        exceptions: [],
        allDays: [],
      },
      schedulingCutOffTime: {
        timeInSeconds: 0,
        exceptions: [],
      },
    });
    this.props.onClose();
  };

  onInputChange = (value, accessor, isException, day) => {
    const { schedulingCutOffTime } = this.state;
    const selectedIndex = isException
      ? DAY_OF_WEEK.findIndex((dayOfWeek) => dayOfWeek.value === day)
      : -1;

    if (isException && selectedIndex > -1) {
      schedulingCutOffTime.exceptions[selectedIndex][accessor] = value;
    } else {
      schedulingCutOffTime[accessor] = value;
    }

    this.setState({
      hasFormSaved: false,
      schedulingCutOffTime,
    });
  };

  schedulingCutOffTime = (removeIndex, contentTypeId) => {
    const { schedulingCutOffTime } = this.state;

    return findIndex(
      schedulingCutOffTime.exceptions,
      (item, index) => item.contentTypeId === contentTypeId && index === removeIndex
    );
  };

  handleDelete = (day, removedIndex) => {
    const { schedulingCutOffTime } = this.state;
    schedulingCutOffTime.exceptions = schedulingCutOffTime.exceptions.filter(
      (item, index) => removedIndex !== index
    );

    this.setState({
      hasFormSaved: false,
      schedulingCutOffTime,
    });
  };

  validateEmptyExceptions = () => {
    const { schedulingCutOffTime } = this.state;
    const emptyIndex = schedulingCutOffTime.exceptions.findIndex(
      (exception) => exception.day === null || exception.timeInSeconds <= 0
    );

    return emptyIndex < 0;
  };

  validateAndSaveSchedulingCutOffTime = (schedulingCutOffTime = null) => {
    const newSchedulingCutOffTime = this.state.schedulingCutOffTime || schedulingCutOffTime;

    const newErrors = {
      exceptions: [],
      allDays: [],
    };
    map(newSchedulingCutOffTime.exceptions, (exception, index) => {
      newErrors.exceptions[index] = exception.timeInSeconds <= 0 ? ["Invalid Time Input"] : [];
      if (exception.day === null || exception.day < 0) {
        newErrors.exceptions[index].push("Please select a day");
      }
    });

    newErrors.allDays = newSchedulingCutOffTime.timeInSeconds <= 0 ? ["Invalid Time Input"] : [];

    if (isEmpty(flatten(newErrors.exceptions)) && isEmpty(newErrors.allDays)) {
      const cutOffTime = Object.assign({
        schedulingCutOffTimeInSeconds: newSchedulingCutOffTime.timeInSeconds,
      });
      cutOffTime.schedulingCutOffTimeExceptions = newSchedulingCutOffTime.exceptions.reduce(
        (r, e) => {
          r[e.day] = e.timeInSeconds;
          return r;
        },
        {}
      );
      this.props.onSave(cutOffTime);
    }

    this.setState({
      hasFormSaved: true,
      isLoading: isEmpty(flatten(newErrors.exceptions)) && isEmpty(newErrors.allDays),
      errors: newErrors,
    });
  };

  handleAddException = () => {
    const { schedulingCutOffTime } = this.state;
    schedulingCutOffTime.exceptions.push({ day: -1, timeInSeconds: 0 });

    this.setState({
      schedulingCutOffTime: schedulingCutOffTime,
    });
  };

  renderCutoffTimeInput = (day = null, timeInSeconds = 0, index, isException = true) => {
    const { schedulingCutOffTime, hasFormSaved } = this.state;
    const exceptionsList = schedulingCutOffTime.exceptions
      ? schedulingCutOffTime.exceptions.map((cutoffTime) => Number(cutoffTime.day))
      : [];
    const daysList = DAY_OF_WEEK.filter(
      (d) => d.value === day || !exceptionsList.includes(d.value)
    ).map((d) => ({
      id: d.value,
      value: d.name,
    }));

    return (
      <div className={styles.container} key={index}>
        <div className={classNames("flex", styles.formWrapper)}>
          <div className={classNames("flex", styles.contentInputWrapper)}>
            {isException ? (
              <>
                <div className={styles.exceptionIcon}>
                  <RemoveIcon />
                </div>
                <Form
                  config={[
                    {
                      id: "type",
                      title: "",
                      label: "",
                      singleSelect: true,
                      showLabelInButton: false,
                      type: FORM_FIELD_TYPE.SELECT,
                      containerClassName: styles.contentInput,
                      className: "col col-12",
                      placeHolder: "Select Day",
                      usePortal: false,
                      list: daysList,
                      error: hasFormSaved && (day === null || day < 0),
                      selectedList:
                        day !== null && day !== -1
                          ? [
                              {
                                id: DAY_OF_WEEK.find((item) => item.value === day)?.value,
                                value: DAY_OF_WEEK.find((item) => item.value === day)?.name,
                              },
                            ]
                          : ["Select Day"],
                      onSelect: ([data]) =>
                        this.onInputChange(data.id, "day", isException, index, day),
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <div className={styles.emptyExceptionIcon} />
                <Form
                  config={[
                    {
                      id: "type",
                      size: 10,
                      type: FORM_FIELD_TYPE.BUTTON,
                      isNonLocalizedButton: true,
                      buttonText: "All Days",
                      buttonType: BUTTON_TYPE.DEFAULT,
                      className: styles.allDaysButton,
                    },
                  ]}
                />
              </>
            )}
          </div>

          <div className={classNames("flex", styles.inputWrapper)}>
            <Form
              config={[
                {
                  type: FORM_FIELD_TYPE.INPUT_HOURS,
                  id: "cutoffTime",
                  title: "",
                  value: timeInSeconds,
                  buttonPosition: "none",
                  showSeconds: false,
                  error: hasFormSaved && (timeInSeconds === null || timeInSeconds === 0),
                  onChange: (time) => {
                    this.onInputChange(time, "timeInSeconds", isException, index, day);
                  },
                },
              ]}
            />
          </div>
          {isException && (
            <BaseButton
              className={styles.clearButton}
              iconName="CancelRoundedIcon"
              buttonSize={SIZE.MEDIUM}
              onClick={() => this.handleDelete(day, index)}
            />
          )}
        </div>
      </div>
    );
  };

  render = () => {
    const { isOpen, selectionInfo } = this.props;
    const { hasFormSaved, errors, isLoading, schedulingCutOffTime } = this.state;
    const errorList = merge(flatten(errors.exceptions), errors.allDays) ?? [];

    return (
      <RightPanel
        isLoading={isLoading}
        isOpen={isOpen}
        header="RightPanelHeader.editScheduleCutoffTime"
        onClose={this.closeEditPanel}
        showFooter
        width={360}
        primaryButtonProps={{
          text: "Button.save",
          onClick: this.validateAndSaveSchedulingCutOffTime,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.closeEditPanel }}
      >
        <div className={BulkActionButtonsStyles.selectionInfoContainer}>
          {selectionInfo.map(({ count, label }, index) => (
            <div className={BulkActionButtonsStyles.selectionInfo} key={index}>
              <span>{count}</span> {label} {index + 1 === selectionInfo.length ? "Selected" : null}
            </div>
          ))}
        </div>
        {this.renderCutoffTimeInput(null, schedulingCutOffTime.timeInSeconds, "allDays", false)}
        {schedulingCutOffTime.exceptions &&
          schedulingCutOffTime.exceptions.map((item, index) =>
            this.renderCutoffTimeInput(Number(item.day), item.timeInSeconds, index, true)
          )}
        {!schedulingCutOffTime.exceptions ||
          (schedulingCutOffTime.exceptions.length < 7 && this.validateEmptyExceptions() && (
            <BaseButton
              iconName="AddFilledIcon"
              buttonText="Add Another Exception"
              buttonSize={SIZE.REGULAR}
              onClick={this.handleAddException}
              buttonType={BUTTON_TYPE.DEFAULT}
              className={styles.addException}
            />
          ))}
        {hasFormSaved && (
          <div className={classNames("flex", styles.errorWrapper)}>
            {errorList.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}
      </RightPanel>
    );
  };
}

EditSchedulingCutoffTimes.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectionInfo: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
};
