import {
  SCHEDULES_LIST,
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CREATE_SCHEDULES_LOADING,
  IS_SCHEDULE_LOADING,
  IS_ERROR,
  IS_SCREEN_LOADING,
  SCHEDULE_SCREENS,
  SCHEDULE_SCREEN_FILTERS,
  ERROR_SCREEN_LIST,
  SCHEDULE,
  RESET_SCHEDULE,
  SCHEDULE_ERRORS,
} from "../types/schedules";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  schedulesList: {
    data: [],
  },
  isScreenLoading: false,
  isCreateScheduleLoading: false,
  error: {
    isError: false,
    message: "",
  },
  schedule: {},
  scheduleScreens: [],
  scheduleErrors: [],
  screenFilters: [],
  screens: {
    data: [],
  },
  errorScreenList: {
    data: [],
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SCHEDULES_LIST:
      return { ...state, schedulesList: payload };
    case IS_ERROR:
      return { ...state, error: payload };
    case IS_LOADING:
      return { ...state, isLoading: payload };
    case IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case IS_CREATE_SCHEDULES_LOADING:
      return { ...state, isCreateScheduleLoading: payload };
    case IS_SCHEDULE_LOADING:
      return { ...state, isScheduleLoading: payload };
    case IS_SCREEN_LOADING:
      return { ...state, isScreenLoading: payload };
    case SCHEDULE_SCREENS:
      return { ...state, scheduleScreens: payload };
    case SCHEDULE_SCREEN_FILTERS:
      return { ...state, screenFilters: payload };
    case SCHEDULE:
      return { ...state, schedule: payload };
    case RESET_SCHEDULE:
      return initialState;
    case SCHEDULE_ERRORS:
      return { ...state, scheduleErrors: payload };
    case ERROR_SCREEN_LIST:
      return { ...state, errorScreenList: payload };
    default:
      return state;
  }
}
