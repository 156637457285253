import React from "react";
import moment from "moment";
import {
  DEFAULT_ACTION_ICON_COLUMN,
  SCOPES,
  SCOPE_ACTIONS,
  CPL_STATUSES,
} from "../../../../../constants";
import ActionIcon from "../../../../common/ActionIcon";
import UserTableAccessor from "../../../../common/UserTableAccessor";
import { ActiveTableCellColumnFromArray } from "../../../../common/ActiveTableCell";
import styles from "../../../../../styles/Content/cpls.module.scss";
import {
  sortByEarliestDate,
  checkScopes,
  dateColumn,
  checkScopeAuth,
  getCategoryNames,
} from "../../../../../utils";
import { getBasicCPLColumns, getMapCPLColumns, getBrandColumn, getViewCPLIcon } from "./CPLColumns";

const getTableColumns = (statusParam, userData, onFilterIdSelect, openMapCPL, ignoreCPL) => {
  const { token } = userData;
  const unmappedColumns = checkScopes(
    [
      ...getBasicCPLColumns(),
      dateColumn("syncedAt", "Synced On"),
      ...getMapCPLColumns(openMapCPL),
      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        width: checkScopeAuth(userData, SCOPES.COMPOSITIONS, SCOPE_ACTIONS.IGNORE) ? 96 : 64,
        Cell: (prop) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  id: "ignore",
                  toolTip: "Tooltip.ignore",
                  scope: SCOPES.COMPOSITIONS,
                  scopeAction: SCOPE_ACTIONS.IGNORE,
                  onClick: () => ignoreCPL(prop),
                  className: `ignoreButton ${styles.crossButton}`,
                  iconName: "CancelFilledIcon",
                },
                getViewCPLIcon(prop, token),
              ],
              userData
            )}
          />
        ),
      },
    ],
    userData
  );

  const ignoredColumns = checkScopes(
    [
      ...getBasicCPLColumns(),
      dateColumn("syncedAt", "Synced On"),
      dateColumn("mappingUpdatedAt", "Ignored At"),
      {
        id: "mappingUpdatedBy",
        Header: "Ignored By",
        accessor: UserTableAccessor("mappingUpdatedBy", onFilterIdSelect),
      },
      ...getMapCPLColumns(openMapCPL),
      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        Cell: (prop) => <ActionIcon iconProps={[getViewCPLIcon(prop, token)]} />,
      },
    ],
    userData
  );

  const mappedColumns = [
    ...getBasicCPLColumns(),
    getBrandColumn(onFilterIdSelect),
    {
      id: "advertisers",
      Header: "Advertisers",
      sortable: false,
      accessor: (d) => ActiveTableCellColumnFromArray(d.advertisers, onFilterIdSelect),
    },
    {
      id: "categories",
      Header: "Categories",
      sortable: false,
      accessor: (d) => getCategoryNames(d.categories, onFilterIdSelect),
    },
    dateColumn("syncedAt", "Synced On"),
    dateColumn("mappingCreatedAt", "Mapped At"),
    {
      id: "mappingCreatedBy",
      Header: "Mapped By",
      accessor: UserTableAccessor("mappingCreatedBy", onFilterIdSelect),
    },
    dateColumn("mappingUpdatedAt", "Updated At"),
    {
      id: "mappingUpdatedBy",
      Header: "Updated By",
      accessor: UserTableAccessor("mappingUpdatedBy", onFilterIdSelect),
    },
    {
      ...DEFAULT_ACTION_ICON_COLUMN,
      width: checkScopeAuth(userData, SCOPES.COMPOSITIONS, SCOPE_ACTIONS.MAP) ? 96 : 64,
      Cell: (prop) => (
        <ActionIcon
          iconProps={checkScopes(
            [
              {
                id: "edit",
                toolTip: "Tooltip.update",
                onClick: () => openMapCPL(prop.original),
                className: styles.iconButton,
                scope: SCOPES.COMPOSITIONS,
                scopeAction: SCOPE_ACTIONS.MAP,
                iconName: "EditIcon",
              },
              getViewCPLIcon(prop, token),
            ],
            userData
          )}
        />
      ),
    },
  ];

  const movieMappedColumns = [
    ...getBasicCPLColumns(),
    {
      id: "movie",
      Header: "Movie",
      accessor: "movie.name",
    },
    {
      id: "language",
      Header: "Language",
      accessor: "movie.language",
    },
    {
      id: "releaseDate",
      Header: "Release Date",
      accessor: (d) => {
        const [earliestDate] = sortByEarliestDate(
          d.movie.releaseDates,
          "releaseDate",
          "YYYY-MM-DD"
        );
        if (!earliestDate) {
          return "";
        }
        const parsedEarliestDate = moment(earliestDate.releaseDate, "YYYY-MM-DD");
        if (!parsedEarliestDate.isValid()) {
          return "";
        }
        return `${parsedEarliestDate.format("MMM DD, YYYY")}`;
      },
    },
    dateColumn("syncedAt", "Synced On"),
  ];

  if (statusParam === CPL_STATUSES.MAPPED) {
    return mappedColumns;
  } else if (statusParam === CPL_STATUSES.IGNORED) {
    return ignoredColumns;
  } else if (statusParam === CPL_STATUSES.MOVIE_MAPPED) {
    return movieMappedColumns;
  } else {
    return unmappedColumns;
  }
};

export default getTableColumns;
