const CONTENT_STANDARD = [
  {
    id: true,
    value: "SMPTE",
  },
  {
    id: false,
    value: "Non-SMPTE",
  },
];

export { CONTENT_STANDARD };
