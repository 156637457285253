import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import { TAG_TYPE } from "../../../../constants";
import { DEFAULT_BRANDS_PATH } from "../../catalogue/brands/brandConstants";
import ParentBrands from "../../catalogue/brands/Tabs/ParentBrands";
import IndividualBrands from "../../catalogue/brands/Tabs/IndividualBrands";
import Products from "../../catalogue/brands/Tabs/Products";
import Variants from "../../catalogue/brands/Tabs/Variants";
import TabPills from "../../../common/TabPills";

export class Brands extends Component {
  handleActiveTab = (brandTabId) => {
    const {
      history,
      match: { params },
    } = this.props;
    history.push(
      `/companies/advertisers/${params.advertiserId}/${params.advertiserTabId}/${brandTabId}`
    ); // TODO: Move this to props to make this component generic
  };
  render() {
    const {
      match: { params },
      parentTagId,
    } = this.props;
    const hiddenColumn = "advertisers";
    const activeTabs = [
      {
        id: "parent-brands",
        title: "Parent Brands",
      },
      {
        id: "individual-brands",
        title: "Individual Brands",
      },
      {
        id: "products",
        title: "Products",
      },
      {
        id: "variants",
        title: "Variants",
      },
    ];
    const tables = {
      [DEFAULT_BRANDS_PATH.PARENT_BRANDS]: (
        <ParentBrands
          hiddenColumn={hiddenColumn}
          parentTagId={parentTagId}
          hiddenTagTypes={[TAG_TYPE.ADVERTISER]}
          isViewOnly
        />
      ),
      [DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS]: (
        <IndividualBrands
          hiddenColumn={hiddenColumn}
          parentTagId={parentTagId}
          hiddenTagTypes={[TAG_TYPE.ADVERTISER]}
          isViewOnly
        />
      ),
      [DEFAULT_BRANDS_PATH.PRODUCTS]: (
        <Products
          hiddenColumn={hiddenColumn}
          parentTagId={parentTagId}
          hiddenTagTypes={[TAG_TYPE.ADVERTISER]}
          isViewOnly
        />
      ),
      [DEFAULT_BRANDS_PATH.VARIANTS]: (
        <Variants
          hiddenColumn={hiddenColumn}
          parentTagId={parentTagId}
          hiddenTagTypes={[TAG_TYPE.ADVERTISER]}
          isViewOnly
        />
      ),
    };
    return (
      <>
        <TabPills
          onChange={this.handleActiveTab}
          selectedTabId={params.brandTabId || activeTabs[0].id}
          tabs={activeTabs}
        />
        {tables[params.brandTabId || activeTabs[0].id]}
      </>
    );
  }
}
const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Brands);
