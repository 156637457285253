/*
1.The code imports a function called getShowFailureSchedule from an external module.
2.It imports two constants and a set of action types from other files.
3.It defines a function called formatDate that takes in a date and returns a formatted string.
4.It defines a constant called yesterday that is set to yesterday's date.
5.It defines a function called getDefaultFilters that returns an array of default filters.
6.It defines a function called actionCreator that returns an object with a given type and payload.
7.It defines a function called updateFailureData that returns an object with a type of 
  types.SHOWFAILURE_SCHEDULE_DATA and a given payload.
8.It defines a function called getShowFailureScheduleData that takes in some parameters and
   returns an async function that dispatches actions to indicate that it is loading data,
   filters data, retrieves data, and updates the failure data with the retrieved data.
   If there is an error, it dispatches an action to indicate that there is an error and
   that it is no longer loading data.
*/
import { getShowFailureSchedule } from "../../api";
import * as constants from "../../constants";
import * as types from "../types/showFailureSchedule";

const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

const dayBeforeYesterday = new Date();
dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

const today = new Date();

const getDefaultFilters = () => [
  {
    from: dayBeforeYesterday,
    id: "dateRange",
    type: "dateRange",
    displayName: `${formatDate(dayBeforeYesterday)}-${formatDate(today)}`,
    to: today,
  },
];
const actionCreator = (type, payload) => {
  return { type, payload };
};
const updateFailureData = (payload) => {
  return { type: types.SHOWFAILURE_SCHEDULE_DATA, payload: payload };
};
const getShowFailureScheduleData = (
  params,
  filters = [],
  getList = getShowFailureSchedule,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", id } = params;
  if (filters.length === 0) filters = getDefaultFilters();
  return async (dispatch) => {
    try {
      if (!id) return;
      dispatch(actionCreator(types.IS_LOADING, true));
      dispatch(actionCreator(types.FILTER, filters));

      const { data } = await getList(id, ps, (page - 1) * ps, sort, filters);
      dispatch(updateFailureData(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export { getShowFailureScheduleData };
