import { omit } from "lodash";
import {
  getMedia,
  getMediaSummary as getMediaSummaryApi,
  getMediaTheatres as getMediaTheatresApi,
  getMediaScreens as getMediaScreensApi,
  cancelMedia as cancelMediaApi,
  pauseOrResumeMedia as pauseOrResumeMediaApi,
  getMediaSpots as getMediaSpotsApi,
} from "../../api";
import * as types from "../types/media";
import * as constants from "../../constants";
import { showToast, createFilterRequest } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateMedia = (payload) => {
  return { type: types.MEDIA, payload: payload };
};

const updateMediaSummary = (payload) => {
  return { type: types.MEDIA_SUMMARY, payload: payload };
};

const updateMediaTheatres = (payload) => ({ type: types.MEDIA_THEATRES, payload });
const updateMediaScreens = (payload) => ({ type: types.MEDIA_SCREENS, payload });
const updateMediaSpots = (payload) => {
  return { type: types.MEDIA_SPOTS, payload: payload };
};

const getMediaDetails = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getMedia(params.mediaId);
      dispatch(updateMedia(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const getMediaSummary = (mediaId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getMediaSummaryApi(mediaId);
      dispatch(updateMediaSummary(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getTheatresList = (id, params) => {
  //SLATE-1389 Nov17 Anushree:-pagination,offset,sort,filter passed separately not as a single object
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", filters } = params;
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      //return getMediaTheatresApi(id, params);
      return getMediaTheatresApi(id, ps, (page - 1) * ps, sort, filters);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      //return getMediaScreensApi(id, params);
      return getMediaScreensApi(id, ps, (page - 1) * ps, sort, filters);
    default:
      return;
  }
};

const updateTheatresAndScreens = (params, data) => {
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return updateMediaTheatres(data);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return updateMediaScreens(data);
    default:
      return;
  }
};

const getMediaTheatres = (params = {}) => {
  const { id } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getTheatresList(id, params);
      dispatch(updateTheatresAndScreens(params, data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const pauseOrResumeMedia = (data) => {
  const { req: ft } = createFilterRequest(
    data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const media = { ft, ...data };
  return async (dispatch) => {
    try {
      await pauseOrResumeMediaApi(data.mediaId, media);
      dispatch(getMediaDetails({ mediaId: data.mediaId }));
      showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      dispatch(getMediaDetails({ mediaId: data.mediaId }));
      showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const cancelMedia = (data, callback = () => {}) => {
  const { req: ft } = createFilterRequest(
    data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  );
  const targetGroup = { ft, ...data };
  return async (dispatch) => {
    try {
      await cancelMediaApi(data.mediaId, omit(targetGroup, ["ids", "campaignId", "targetGroupId"]));
      dispatch(getMediaDetails({ mediaId: data.mediaId }));
      callback();
      await showToast("Toast.statusUpdatedSuccessfully", true);
    } catch (error) {
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
//action to get Media Spots
const getMediaSpots = (params = {}, filters = [], getList = getMediaSpotsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", mediaId } = params;
  return async (dispatch) => {
    try {
      const { filters: defaultFilter } = params;
      filters = filters.length === 0 ? defaultFilter : filters;
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(mediaId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateMediaSpots(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

export {
  getMediaDetails,
  getMediaSummary,
  getMediaTheatres,
  pauseOrResumeMedia,
  cancelMedia,
  getMediaSpots,
};
