import React, { Component } from "react";
import { isEmpty, find, map } from "lodash";
import { Icons } from "prefab";
import moment from "moment";
import classNames from "classnames";
import ActionIcon from "../../../../../../common/ActionIcon";
import {
  SCREEN_SETTINGS_LIST,
  SCREEN_SETTINGS,
  DAY_OF_WEEK,
  SCREEN_STATUS_LIST,
} from "../../../../../../../constants";
import ListItem from "../../../../../../common/ListItem";
import EditSchedulingTime from "../../../actions/EditSchedulingTime";
import EditSchedulingCutoffTime from "../../../actions/EditSchedulingCutoffTime";
import ActivateOrDeactivateScreens from "../../../actions/ActivateOrDeactivateScreens";
import styles from "../../../../../../../styles/ListItem.module.scss";
import pageStyles from "../../../../../../../styles/App.module.scss";
import { getScreenValidity, checkScopeAuth, padLeft } from "../../../../../../../utils";
import StatusIcon from "../../../../../../common/StatusIcon";
import * as constants from "../../../../../../../constants";

const { RemoveIcon, PauseIcon, PlayIcon, EditIcon, CancelIcon } = Icons;

export default class SettingsDetails extends Component {
  static defaultProps = {
    showEdit: true,
    isAdmin: false,
  };

  state = {
    activationType: "",
  };

  componentDidMount = async () => {
    const {
      history,
      showEdit,
      actions,
      match: { params },
    } = this.props;
    if (params.childTabId && showEdit) {
      await actions.editScreenSettingsById(params);
    } else if (params.childTabId && !showEdit) {
      history.replace(`/screens/${params.screenId}/${params.tabId}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.childTabId && params.childTabId !== prevProps.match.params.childTabId) {
      await actions.editScreenSettingsById(params);
    }
  };

  componentWillUnmount = () => {
    this.props.actions.cancelSettingEdit();
  };

  handleUpdateScreenSettings = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    history.push(`/screens/${params.screenId}/${params.tabId}`);
    actions.cancelSettingEdit();
  };

  saveScreensScheduleTimings = (data) => {
    const {
      actions,
      userData,
      match: { params },
    } = this.props;
    actions.saveScreensScheduleTimings(
      userData.company.id,
      [params.screenId],
      data,
      params,
      [],
      true,
      this.handleUpdateScreenSettings
    );
  };

  saveScreensScheduleCutoffTimes = (data) => {
    const {
      actions,
      userData,
      match: { params },
    } = this.props;
    actions.saveScreensScheduleCutoffTimes(
      userData.company.id,
      [params.screenId],
      data,
      params,
      [],
      true,
      this.handleUpdateScreenSettings
    );
  };

  onSaveScreenValidity = (data) => {
    const {
      actions,
      userData,
      match: { params },
    } = this.props;

    actions.updateScreenStatus(
      [params.screenId],
      data,
      {
        ...params,
        companyId: userData.company.id,
      },
      [],
      true,
      this.handleUpdateScreenSettings
    );
  };

  handleEditSettingItem = (type) => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    history.push(`/screens/${params.screenId}/${params.tabId}/${type}`);
    actions.editScreenSettingsById(params);
  };

  activateOrDeactivateScreen = (type) => {
    const {
      history,
      match: { params },
    } = this.props;
    history.push(`/screens/${params.screenId}/${params.tabId}/screenStatus`);
    this.setState({
      activationType: type,
    });
  };

  renderSchedulingContentItem = (index, startTime, endTime, contentId = null) => {
    const { contentTypes } = this.props;
    const content =
      contentId && contentTypes && find(contentTypes, (content) => content.id === contentId);
    const contentName = content ? `${content.code} - ${content.name}` : "All Content";
    return (
      <div key={index} className="flex">
        <div className={styles.contentName}>
          {contentId && <RemoveIcon />}
          <span>{contentName}</span>
        </div>
        <div className={styles.durations}>
          {startTime && endTime
            ? `${moment(startTime, "HH:mm:ss").format("HH:mm")} - ${moment(
                endTime,
                "HH:mm:ss"
              ).format("HH:mm")}`
            : "-"}
        </div>
      </div>
    );
  };

  renderSchedulingContent = () => {
    const {
      screenSettings: { schedulingTime = null },
    } = this.props;

    return (
      <>
        {this.renderSchedulingContentItem(
          "allContent",
          schedulingTime?.startTime,
          schedulingTime?.endTime
        )}
        {schedulingTime?.exceptions?.map((item, index) =>
          this.renderSchedulingContentItem(index, item.startTime, item.endTime, item.contentTypeId)
        )}
      </>
    );
  };

  renderSchedulingCutoffContentItem = (index, timeInSeconds, dayIndex) => {
    const d = find(DAY_OF_WEEK, (day) => day.value === dayIndex);
    const dayName = d ? d.name : "All Days";
    return (
      <div key={index} className="flex">
        <div className={styles.contentName}>
          {dayName !== "All Days" && <RemoveIcon />}
          <span>{dayName}</span>
        </div>
        <div className={styles.durations}>
          {timeInSeconds > 0
            ? `${padLeft(Math.floor(timeInSeconds / 3600), 2)}:${padLeft(
                Math.floor((timeInSeconds / 60) % 60),
                2
              )}`
            : "-"}
        </div>
      </div>
    );
  };

  renderSchedulingCutoffContent = () => {
    const {
      screenSettings: { schedulingCutOffTimeInSeconds, schedulingCutOffTimeExceptions },
    } = this.props;

    return (
      <>
        {this.renderSchedulingCutoffContentItem("allDays", schedulingCutOffTimeInSeconds)}
        {!isEmpty(schedulingCutOffTimeExceptions) &&
          Object.keys(schedulingCutOffTimeExceptions).map((key, index) =>
            this.renderSchedulingCutoffContentItem(
              index,
              schedulingCutOffTimeExceptions[key],
              Number(key)
            )
          )}
      </>
    );
  };

  renderSettingsValue = (type) => (
    <div className={styles.overbooking}>
      {this.props.screenSettings[type] > 0 ? this.props.screenSettings[type] : "-"}
    </div>
  );

  renderScreenStatus = () => {
    const {
      SCREEN_STATUSES: { ACTIVE, ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE },
      STATUS_ICON_COLORS: { GREEN, GREY },
      STATUS_ICON_TYPES: { DURATION, CIRCLE },
    } = constants;
    const { screenSettings } = this.props;
    const { screenStatusList, screenStatus } = screenSettings;
    const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const currentScreenStatus = isEmpty(futureScreenStatus) ? screenStatus : futureScreenStatus;
    const { screenStatus: status, startDate, endDate } = currentScreenStatus;
    if (!status || this.props.isAdmin)
      return (
        <div className={styles.statusContainer}>
          <div className={styles.status}>-</div>
        </div>
      );

    return (
      <div className={styles.statusContainer}>
        <div className={styles.status}>
          <StatusIcon
            color={[ACTIVE, INACTIVE_IN_FUTURE].includes(status) ? GREEN : GREY}
            type={[ACTIVE_IN_FUTURE, INACTIVE_IN_FUTURE].includes(status) ? DURATION : CIRCLE}
            showToolTip={false}
          />
          <div className={styles.text}>
            {SCREEN_STATUS_LIST.find(({ id }) => id === status)?.displayName}
          </div>
        </div>
        <div className={styles.validity}>{getScreenValidity(status, startDate, endDate)}</div>
      </div>
    );
  };

  renderListContent = (type) => {
    switch (type) {
      case SCREEN_SETTINGS.SCHEDULING_TIME:
        return this.renderSchedulingContent();
      case SCREEN_SETTINGS.SCHEDULING_CUTOFF_TIME:
        return this.renderSchedulingCutoffContent();
      case SCREEN_SETTINGS.AVERAGE_OCCUPANCY:
        return this.renderSettingsValue(SCREEN_SETTINGS.AVERAGE_OCCUPANCY);
      case SCREEN_SETTINGS.OVERBOOKING_PERCENTAGE:
        return this.renderSettingsValue(SCREEN_SETTINGS.OVERBOOKING_PERCENTAGE);
      case SCREEN_SETTINGS.SCREEN_STATUS:
        return this.renderScreenStatus();
      default:
        return null;
    }
  };

  renderEditSettingsPanel = () => {
    const {
      match: { params },
      screenSettingsEdit: { isEdit },
      screen,
      screenSettings,
      isActivationPaneLoading,
    } = this.props;
    if (!screenSettings) return null;

    switch (params.childTabId) {
      case SCREEN_SETTINGS.SCHEDULING_TIME:
        return <EditSchedulingTime onSave={this.saveScreensScheduleTimings} />;
      case SCREEN_SETTINGS.SCHEDULING_CUTOFF_TIME:
        return <EditSchedulingCutoffTime onSave={this.saveScreensScheduleCutoffTimes} />;
      case SCREEN_SETTINGS.SCREEN_STATUS:
        return (
          <ActivateOrDeactivateScreens
            isLoading={isActivationPaneLoading}
            isOpen={isEdit}
            selectedScreens={[
              {
                ...screen,
                screenSettings: [screenSettings],
              },
            ]}
            activationType={this.state.activationType}
            onClose={this.handleUpdateScreenSettings}
            onActivateOrDeactivate={this.onSaveScreenValidity}
          />
        );
      default:
        return null;
    }
  };

  renderActionIcon = (type, iconName = "EditIcon", toolTipInfo = "Tooltip.update") => {
    const { screenSettings, userData } = this.props;
    const { screenStatusList, screenStatus } = screenSettings;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.INVENTORY,
      constants.SCOPE_ACTIONS.WRITE
    );
    const futureScreenStatus = screenStatusList?.find((s) => !s.isCurrent) ?? null;
    const currentScreenStatus = isEmpty(futureScreenStatus) ? screenStatus : futureScreenStatus;
    const { screenStatus: status } = currentScreenStatus;
    if (!status) return null;

    if (type === SCREEN_SETTINGS.SCREEN_STATUS) {
      return (
        <ActionIcon
          iconProps={[
            {
              toolTip: "Tooltip.moreActions",
              iconName: "MoreVerticalIcon",
              toolTipClass: styles.statusIconTooltip,
              dropdown: [
                {
                  text: "Activate",
                  onClick: () => this.activateOrDeactivateScreen(constants.SCREEN_STATUSES.ACTIVE),
                  hideMenuItem:
                    status && !(hasWriteScope && status === constants.SCREEN_STATUSES.INACTIVE),
                  icon: <PlayIcon />,
                },
                {
                  text: "Deactivate",
                  onClick: () =>
                    this.activateOrDeactivateScreen(constants.SCREEN_STATUSES.INACTIVE),
                  hideMenuItem:
                    status && !(hasWriteScope && status === constants.SCREEN_STATUSES.ACTIVE),
                  icon: <PauseIcon />,
                },
                {
                  text: "Edit Deactivation",
                  onClick: () =>
                    this.activateOrDeactivateScreen(constants.SCREEN_STATUSES.INACTIVE_IN_FUTURE),
                  hideMenuItem: !(
                    hasWriteScope &&
                    !isEmpty(futureScreenStatus) &&
                    futureScreenStatus?.screenStatus ===
                      constants.SCREEN_STATUSES.INACTIVE_IN_FUTURE
                  ),
                  icon: <EditIcon />,
                },
                {
                  text: "Edit Activation",
                  onClick: () =>
                    this.activateOrDeactivateScreen(constants.SCREEN_STATUSES.ACTIVE_IN_FUTURE),
                  hideMenuItem: !(
                    hasWriteScope &&
                    !isEmpty(futureScreenStatus) &&
                    futureScreenStatus?.screenStatus === constants.SCREEN_STATUSES.ACTIVE_IN_FUTURE
                  ),
                  icon: <EditIcon />,
                },
                {
                  text: "Clear Deactivation",
                  onClick: () =>
                    this.onSaveScreenValidity({
                      startDate: moment().format("YYYY-MM-DD"),
                      status: constants.SCREEN_STATUSES.ACTIVE,
                    }),
                  hideMenuItem: !(
                    hasWriteScope &&
                    !isEmpty(futureScreenStatus) &&
                    futureScreenStatus?.screenStatus ===
                      constants.SCREEN_STATUSES.INACTIVE_IN_FUTURE
                  ),
                  icon: <CancelIcon />,
                },
                {
                  text: "Clear Activation",
                  onClick: () =>
                    this.onSaveScreenValidity({
                      startDate: moment().format("YYYY-MM-DD"),
                      status: constants.SCREEN_STATUSES.INACTIVE,
                    }),
                  hideMenuItem: !(
                    hasWriteScope && status === constants.SCREEN_STATUSES.ACTIVE_IN_FUTURE
                  ),
                  icon: <CancelIcon />,
                },
              ],
            },
          ]}
        />
      );
    }

    return (
      <ActionIcon
        iconProps={[
          {
            toolTip: toolTipInfo,
            iconName: iconName,
            toolTipClass: styles.toolTip,
            onClick: () => this.handleEditSettingItem(type),
          },
        ]}
      />
    );
  };

  render = () => {
    const { showEdit, screenSettings, screenSettingsEdit = {}, isAdmin } = this.props;
    const { isEdit = false } = screenSettingsEdit;

    return (
      <div
        className={classNames(styles.listItems, {
          [pageStyles.pageContainer]: !isAdmin,
        })}
      >
        {map(SCREEN_SETTINGS_LIST, (item, index) => {
          if (screenSettings[item.id] !== undefined) {
            return (
              <ListItem
                key={index}
                title={item.name}
                sectionContent={this.renderListContent(item.id)}
                actionIcon={showEdit && item.isEditable && this.renderActionIcon(item.id)}
              />
            );
          }
        })}
        {isEdit && <div>{screenSettingsEdit.isOpen && this.renderEditSettingsPanel()} </div>}
      </div>
    );
  };
}
