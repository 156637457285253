import React, { Component } from "react";
import classNames from "classnames";

import { FormattedMessage } from "react-intl";
import styles from "../../styles/ListItem.module.scss";

export default class SettingsListItem extends Component {
  render() {
    const { title, sectionContent, actionIcon } = this.props;

    return (
      <div className={classNames("flex", styles.listItem)}>
        <div className={classNames("col col-3", styles.title)}>
          <FormattedMessage id={title} defaultMessage={title} />
        </div>
        <div className={classNames("col col-8", styles.sectionContent)}>{sectionContent}</div>
        <div className={classNames("col col-1", styles.actions)}>{actionIcon}</div>
      </div>
    );
  }
}
