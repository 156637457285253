import * as types from "../types/notificationTemplate";

const initialState = {
  isLoading: false,
  isError: null,
  data: {},
  notificationTemplate: {},
  notificationTemplateEdit: { isOpen: false, notificationTemplate: {} },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.DATA:
      return { ...state, data: payload };
    case types.NOTIFICATION:
      return { ...state, notificationTemplate: payload };
    case types.EDIT:
      return { ...state, notificationTemplateEdit: payload };
    default:
      return state;
  }
}
