import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, titleCase } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import PageHeader from "../../common/PageHeader";
import TabView from "../../common/Tabs";
import TabPills from "../../common/TabPills";

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.rolesScopes" }];

export class RolesScopes extends Component {
  state = {
    activeUserType: null,
    activeRight: null,
  };

  componentDidMount = () => {
    this.props.actions.getRolesScopesList();
  };

  handleUserType = (activeUserType) => {
    const {
      rolesScopes: { data },
    } = this.props;
    const [activeRight] = data.rights[activeUserType];
    this.setState({ activeUserType, activeRight }); // Reset child as well when changing parent
  };

  handleRight = (activeRight) => this.setState({ activeRight });

  render = () => {
    const {
      rolesScopes: { data, isLoading },
      history,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    let columns = [],
      tableData = [],
      userTabs = [],
      rightsTabs = [];
    let activeUserType, activeRight;
    if (!isLoading) {
      activeUserType = this.state.activeUserType || data.userTypes[0];
      activeRight = this.state.activeRight || data.rights[activeUserType][0];
      columns = [
        { Header: "Scope", accessor: "scopeAction", sortable: true },
        ...data.roles[activeUserType][activeRight].map((role) => ({
          id: `${activeUserType}-${activeRight}-${role}`,
          Header: titleCase(role),
          accessor: (d) => (d[role] ? "Yes" : "No"),
          sortable: false,
        })),
      ];
      tableData = data.rolesData[activeUserType][activeRight];
      userTabs = data.userTypes.map((userType) => ({ id: userType, title: titleCase(userType) }));
      rightsTabs = data.rights[activeUserType].map((right) => ({
        id: right,
        title: titleCase(right),
      }));
    }
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader name="PageHeader.rolesScopes" isLoading={isLoading} />
        {!isLoading && (
          <>
            <TabView
              defaultSelectedTabId={activeUserType}
              onChange={this.handleUserType}
              selectedTabId={activeUserType}
              tabs={userTabs}
              animate
            />
            <TabPills onChange={this.handleRight} selectedTabId={activeRight} tabs={rightsTabs} />
          </>
        )}
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              manual={false}
              data={tableData}
              loading={isLoading}
              columns={columns}
              defaultSorted={[{ id: "scopeAction" }]}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.rolesScopes,
  (rolesScopes) => ({ rolesScopes })
);

export default connect(mapStateToProps, bindDispatch)(RolesScopes);
