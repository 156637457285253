import { saveTimesOfDay, getTimesOfDay, getTimesOfDayById } from "../../api";
import { showToast } from "../../utils";
import * as types from "../types/timesOfDay";

const actionCreator = (type, payload) => ({ type, payload });
const updateList = (payload) => ({ type: types.DATA, payload });
const handleError = (payload) => ({ type: types.IS_ERROR, payload });
const handleLoading = (payload) => ({ type: types.IS_LOADING, payload });
const handlePartialLoading = (payload) => ({ type: types.IS_PARTIAL_LOADING, payload });

const addTimesOfDay = () => (dispatch) =>
  dispatch(
    actionCreator(types.EDIT, {
      isOpen: true,
      timesOfDay: { name: "", isDefault: false, startTime: "00:00", endTime: "00:00" },
    })
  );

const editTimesOfDay = (timesOfDay) => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { timesOfDay, isOpen: true }));

const cancelEditTimesOfDay = () => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { isOpen: false, timesOfDay: {} }));

const getTimesOfDayList = (sort = "startTime") => {
  return async (dispatch) => {
    dispatch(handlePartialLoading(true));
    try {
      const { data } = await getTimesOfDay(sort);
      dispatch(updateList(data));
      dispatch(handlePartialLoading(false));
    } catch (error) {
      dispatch(handlePartialLoading(false));
      dispatch(handleError(error));
    }
  };
};

const editTimesOfDayById = (timesOfDayId, get = getTimesOfDayById) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await get(timesOfDayId);
      dispatch(editTimesOfDay(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const createOrUpdateTimesOfDay = (timesOfday, checkIsUnique, cancelEdit, save = saveTimesOfDay) => {
  return async (dispatch) => {
    try {
      await save(timesOfday);
      cancelEdit();
      await dispatch(getTimesOfDayList());
      showToast(timesOfday.id ? "Toast.timeOfDayUpdated" : "Toast.timeOfDayAdded");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        checkIsUnique(error.response.data.message);
      } else if (error.response) {
        showToast(error.response.data.message, false);
      }
    }
  };
};

export {
  getTimesOfDayList,
  editTimesOfDayById,
  addTimesOfDay,
  editTimesOfDay,
  cancelEditTimesOfDay,
  createOrUpdateTimesOfDay,
};
