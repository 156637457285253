import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons } from "prefab";

import styles from "../../styles/FileUpload.module.scss";
import LocalizedButton from "./LocalizedButton";

const { CancelRoundedIcon } = Icons;

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.fileImport = createRef();
    this.state = {
      fileList: [],
      error: "",
      isDragging: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUploadError !== prevProps.isUploadError) {
      this.setState({
        fileList: [],
      });
    }
  }

  handleFileDragOver = (event, isDragging) => {
    event.preventDefault();
    this.setState({
      isDragging,
    });
  };

  handleFileSelect = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      this.validateAndAddFiles(files);
    }
  };

  handleFileDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      this.validateAndAddFiles(e.dataTransfer.files);
    }
  };

  validateAndAddFiles = (files) => {
    const { mimeType, errorMessage, multiple } = this.props;
    const fileList = Array.from(files);
    const allFilesValid = fileList.every((file) => file.type === mimeType);

    if (allFilesValid) {
      this.setState({ fileList });
      this.props.onSelect(multiple ? fileList : fileList[0]);
    } else {
      this.setState({ error: errorMessage });
    }
  };

  handleRemoveFile = (fileIndex) => {
    const { fileList } = this.state;
    const filteredFiles = fileList.filter((_, index) => index !== fileIndex);
    this.props.onSelect(filteredFiles);
    this.setState({
      fileList: filteredFiles,
    });
  };

  renderUploadedFile = () => {
    const { fileList } = this.state;
    const { showRemove } = this.props;
    if (fileList.length === 0) return null;

    return fileList.map((file, index) => (
      <div
        key={index}
        className={styles.fileContainer}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
      >
        <div className={styles.fileDetails}>
          <div className={styles.fileName}>{file.name}</div>
          {showRemove && (
            <div className={styles.remove} onClick={() => this.handleRemoveFile(index)}>
              <CancelRoundedIcon />
            </div>
          )}
        </div>
      </div>
    ));
  };

  renderFormContent = () => {
    const { title, icon, acceptableFormats, description, multiple, isLoading } = this.props;
    const { fileList, isDragging } = this.state;

    if (isLoading) {
      return (
        <div className={classNames(styles.fileContainer, styles.loadingContainer)}>
          <div className={styles.loader} />
          <span>Uploading</span>
        </div>
      );
    } else if (fileList.length === 0) {
      return (
        <div
          className={classNames(styles.fileUpload, isDragging && styles.active)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(event) => this.handleFileDragOver(event, true)}
          onDragLeave={(event) => this.handleFileDragOver(event, false)}
          onDrop={this.handleFileDrop}
        >
          <LocalizedButton
            text={title}
            iconName={icon}
            onClick={() => this.fileImport.current.click()}
          />
          <input
            multiple={multiple}
            type="file"
            accept={acceptableFormats}
            style={{ display: "none" }}
            ref={this.fileImport}
            onChange={this.handleFileSelect}
          />
          <div className={styles.dragDropOption} onClick={() => this.fileImport.current.click()}>
            {description}
          </div>
        </div>
      );
    } else {
      return this.renderUploadedFile();
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div>
        {this.renderFormContent()}
        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
}

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  acceptableFormats: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileTypes: PropTypes.string,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  multiple: PropTypes.bool,
  showRemove: PropTypes.bool,
  isLoading: PropTypes.bool,
};

FileUpload.defaultProps = {
  description: "Drag & Drop",
  icon: "UploadIcon",
  errorMessage: "Please upload a valid file",
  multiple: false,
  showRemove: false,
};

export default FileUpload;
