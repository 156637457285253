import moment from "moment-timezone";

const getUserTimeZone = () => {
  const zone = moment.tz.guess();
  const offset = moment().utcOffset();
  const abbr = moment.tz.zone(zone).abbr(offset);
  return {
    zone,
    offset,
    abbr,
  };
};

export default getUserTimeZone;
