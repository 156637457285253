/*
MediaSpotStatus is a class-based component that fetches media spot information and passes it down to the SpotStatus component.

1. It is called in Media.js in Spot Status tab
2. It has a default date range filter from today to seven days from today.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import * as constants from "../../../../../constants";
import { bindDispatch } from "../../../../../utils";
import WithFetchList from "../../../../hoc/withFetchList";
import SpotStatus from "../../common/SpotStatus";
import { formatDate } from "../../utils";

const today = new Date();
const dayAfterToday = new Date().setDate(today.getDate() + 7);
const defaultFromDate = today;
const defaultToDate = dayAfterToday;

class MediaSpotStatus extends Component {
  componentDidMount = async () => {
    const {
      match: {
        params: { mediaId },
      },
    } = this.props;

    //default Date range filter is passed in fetchData call
    this.props.fetchData(null, true, [
      { mediaId },
      {
        filters: [
          {
            from: new Date(defaultFromDate),
            id: "showDate",
            type: "showDate",
            displayName: `${formatDate(defaultFromDate)}-${formatDate(defaultToDate)}`,
            to: new Date(defaultToDate),
          },
        ],
      },
    ]);
  };

  render = () => {
    const {
      media: { isTabLoading, mediaSpots },
    } = this.props;
    return (
      <SpotStatus
        {...this.props}
        data={{
          spotStatus: mediaSpots,
          isLoading: isTabLoading,
        }}
      />
    );
  };
}

export const MediaSpotStatusWithFilters = WithFetchList("getMediaSpots", {
  sort: [
    {
      id: "showDate",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(MediaSpotStatus);

const mapStateToProps = createSelector(
  (state) => state.media,
  (state) => state.userData,
  (state) => state.campaigns,

  (media, userData, campaigns) => ({
    media,
    campaigns,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(MediaSpotStatusWithFilters));
