import { Tooltip } from "@blueprintjs/core";
import { compact } from "lodash";
import React from "react";
import { ActiveTableCellColumnFromArray } from "../../components/common/ActiveTableCell";

const _fullName = (value) => compact([value?.firstName, value?.lastName]).join(" ");

export const UserTableCell = ({ user, onIdSelect }) => {
  const userDetails = [
    {
      id: user?.id,
      name: user?.email ? (
        <Tooltip content={user.email} position="top">
          {_fullName(user)}
        </Tooltip>
      ) : (
        _fullName(user)
      ),
    },
  ];

  return ActiveTableCellColumnFromArray(userDetails, onIdSelect);
};

const UserTableAccessor = (key, onIdSelect) => (d) =>
  d[key] && <UserTableCell user={d[key]} onIdSelect={onIdSelect} />;

export default UserTableAccessor;
