import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "../../styles/InputWithSelect.module.scss";
import FormStyles from "../../styles/Form.module.scss";
import Selector from "./Selector";
import Input from "./TextInput";

class InputWithSelect extends Component {
  static propTypes = {
    label: PropTypes.string,
    list: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSelectedLabel: PropTypes.string,
    showLabelInButton: PropTypes.bool,
    selectedList: PropTypes.array,
    usePortal: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  };
  render() {
    const {
      label,
      list,
      onSelect,
      onInputChange,
      inputValue,
      value,
      placeholder,
      defaultSelectedLabel,
      showLabelInButton,
      selectedList,
      usePortal,
      error,
      errorMessage,
    } = this.props;
    return (
      <div className={classNames("flex-wrap", styles.formGroup)}>
        <div className={classNames("col-12 semi h5", styles.header)}>{label}</div>
        <div className={classNames("flex flex-1", styles.container)}>
          <div className="col-5">
            <Selector
              className={styles.inputSelect}
              selectedList={selectedList}
              value={value}
              singleSelect
              list={list}
              onSelect={onSelect}
              label={defaultSelectedLabel || ""}
              usePortal={usePortal}
              error={error}
              errorMessage={errorMessage}
              showLabelInButton={showLabelInButton}
              placeHolder={defaultSelectedLabel}
            />
          </div>
          <Input
            className="col-11"
            onChange={onInputChange}
            placeholder={placeholder}
            value={inputValue}
          />
        </div>
        <>{error && <div className={FormStyles.errorMessage}>{errorMessage}</div>}</>
      </div>
    );
  }
}

export default InputWithSelect;
