import { OPERATIONS } from "../constants";

const convertLableToValue = (label) => {
  const operation = OPERATIONS.find((operation) => operation.label === label);
  return operation.value;
};
const convertValueToLabel = (value) => {
  const operation = OPERATIONS.find((operation) => operation.value === value);
  return operation.label;
};

export { convertLableToValue, convertValueToLabel };
