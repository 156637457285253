import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import FooterControls from "../../../common/FooterControls";
import ActionIcon from "../../../common/ActionIcon";
import TableLoading from "../../../common/TableLoading";
import EditProductIdentificationNumberType from "./EditProductIdentificationNumberType";

const links = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.productIdentificationNumberType" },
];

export class ProductIdentificationNumberType extends Component {
  state = {
    selectedColumns: ["Name", "Last Updated By", "Last Updated On"],
  };

  componentDidMount = async () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addProductIdentificationNumberType();
        actions.getProductIdentificationNumberTypeList();
      } else {
        actions.editProductIdentificationNumberTypeById(params.action);
      }
    } else actions.getProductIdentificationNumberTypeList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (product) => {
    const { actions } = this.props;
    actions.createOrUpdateProductIdentificationNumberType(
      product,
      actions.getProductIdentificationNumberTypeList
    );
  };

  addProductIdentificationNumberType = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addProductIdentificationNumberType();
    history.replace(`/lists/product-identification-number-types/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditProductIdentificationNumberType();
    history.replace(`/lists/product-identification-number-types${location.search}`);
  };

  editProductIdentificationNumberType = (product) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editProductIdentificationNumberType(product);
    history.replace(`/lists/product-identification-number-types/${product.id}${location.search}`);
  };

  render = () => {
    const {
      productIdentificationNumberType: {
        list,
        isLoading,
        isPartialLoading,
        productIdentificationNumberTypeEdit,
      },
      history,
      userData,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, productIdentificationNumberType } = productIdentificationNumberTypeEdit;
    const columns = checkScopes(
      [
        { id: "type", Header: "Name", accessor: "type" },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.PRODUCT_IDENTIFICATION_NUMBER_TYPES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editProductIdentificationNumberType(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.PRODUCT_IDENTIFICATION_NUMBER_TYPES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addProductIdentificationNumberType,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          name="PageHeader.productIdentificationNumberType"
          count={list.data.length}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              logsButton
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.PRODUCT_IDENTIFICATION_NUMBER_TYPES}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              pageSize={list.data.length}
              sortable={false}
              data={list.data}
              loading={isPartialLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        <EditProductIdentificationNumberType
          isOpen={isOpen}
          productIdentificationNumberType={productIdentificationNumberType}
          onSave={this.save}
          onClose={this.cancelEdit}
        />
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (productIdentificationNumberType, userData) => ({
    productIdentificationNumberType,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(ProductIdentificationNumberType);
