import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { isEmpty, sum, keys, map, values } from "lodash";
import Form from "../../../../common/Form";
import SecondaryTable from "../../../../common/SecondaryTable";
import InfoBlock from "../../../../common/InfoBlock";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { DAY_OF_WEEK, FORM_FIELD_TYPE } from "../../../../../constants";

const AddFilledIcon = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6253 9.37435V7.29102C10.6253 6.94584 10.3455 6.66602 10.0003 6.66602C9.65515 6.66602 9.37533 6.94584 9.37533 7.29102V9.37435H7.29199C6.94681 9.37435 6.66699 9.65417 6.66699 9.99935C6.66699 10.3445 6.94681 10.6243 7.29199 10.6243H9.37533V12.7077C9.37533 13.0529 9.65515 13.3327 10.0003 13.3327C10.3455 13.3327 10.6253 13.0529 10.6253 12.7077V10.6243H12.7087C13.0538 10.6243 13.3337 10.3445 13.3337 9.99935C13.3337 9.65417 13.0538 9.37435 12.7087 9.37435H10.6253Z"
      fill="#000000"
      className="fill-color"
    />
  </svg>
);

const RemoveFilledIcon = ({ height, width }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.6 9.375C6.26863 9.375 6 9.65482 6 10C6 10.3452 6.26863 10.625 6.6 10.625C10.4313 10.625 9.56871 10.625 13.4 10.625C13.7314 10.625 14 10.3452 14 10C14 9.65482 13.7314 9.375 13.4 9.375C9.68155 9.375 10.3185 9.375 6.6 9.375Z"
      fill="#000000"
      className="fill-color"
    />
  </svg>
);
export class MediaPlacement extends Component {
  state = {
    showAdvanced: false,
    placementTable: [],
    timesOfDay: [],
    activeCellData: [],
    placementoptions: {
      segmentTypes: [],
      playlistPackTypes: [],
      positions: [],
      positionUnavailabilityOptions: [],
      modes: [],
    },
  };

  componentDidMount() {
    this.calculateStateFromProps();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.timesOfDay?.length !== this.props.timesOfDay?.length) {
      this.calculateStateFromProps();
    }
  }
  calculateStateFromProps = () => {
    const { timesOfDay } = this.props;

    // Sort the timesOfDay array based on the startTime property
    const sortedTimesOfDay = timesOfDay.slice().sort((a, b) => {
      const startTimeA = moment(a.startTime, "HH:mm:ss");
      const startTimeB = moment(b.startTime, "HH:mm:ss");
      return startTimeA.diff(startTimeB);
    });
    // Generate the play data for the placement table using the sorted timesOfDay array
    this.setState({
      placementTable: this.generatePlayData(sortedTimesOfDay, this.props.placement),
      timesOfDay: sortedTimesOfDay,
      activeCellData: this.generatePlayData(sortedTimesOfDay, this.props.placement).map((row) =>
        Object.keys(row.plays).map((cell) => true)
      ),
      placementoptions: this.props.placementoptions,
    });
  };
  generatePlayData = (sortedTimesOfDay, modifiedPlacement = null) => {
    const placement = modifiedPlacement ?? this.props.placement;

    // SLATE-1413 Akash Vinchankar
    // sortedTimesOfDay is an array of objects with isDefault property as true
    const processedTimesOfDay = sortedTimesOfDay.filter((item) => item.isDefault);

    return processedTimesOfDay.map((item) => {
      // SLATE-1430 Akash Vinchankar
      const currentPlay = (placement?.plays || []).filter((p) => p.timeOfDayId === item.id);
      const plays = {};
      DAY_OF_WEEK.forEach(({ value }) => {
        const index = currentPlay?.findIndex((c) => c.dayOfWeek === value);
        plays[value] = index !== -1 ? currentPlay[index]?.numberOfPlays : 0;
      });
      return {
        plays: plays,
        timeOfDayId: item.id,
        startTime: item.startTime,
        endTime: item.endTime,
      };
    });
  };

  onChange = (value, key, selected) => {
    const { index, media, onInputChange, placement } = this.props;
    const modifiedPlacement = { ...placement, [key]: value };
    if (modifiedPlacement?.plays && key === "plays") {
      const numberOfPlays = modifiedPlacement.plays
        ? sum(modifiedPlacement?.plays.map((p) => p.numberOfPlays))
        : 0;
      modifiedPlacement.numberOfPlays = numberOfPlays;
    }
    //removing segementId if playListPackTypeId is changed
    if (key === "playlistPackTypeId" && placement.playlistPackTypeId !== value) {
      modifiedPlacement.segmentId = "";
    }
    const modifiedPlacements = media.placements.map((p, i) =>
      i === index ? { ...modifiedPlacement, index: i } : { ...p, index: i }
    );
    onInputChange(modifiedPlacements, "placements");
    //to do
    //this.removeExistingCombination({ id: value }, key);
  };
  onChangeOfNoOfPlays = (value, key) => {
    const { index, media, onInputChange, placement } = this.props;
    const modifiedPlacement = { ...placement, [key]: value };
    const modifiedPlacements = media.placements.map((p, i) =>
      i === index ? modifiedPlacement : p
    );
    onInputChange(modifiedPlacements, "placements");
  };

  filterPlacementData = (placementsData) => {
    const filteredData = [];
    map(placementsData, (item) => {
      const dayList = keys(item.plays).filter((day) => item.plays[day] > 0);
      dayList.map((day) =>
        filteredData.push({
          timeOfDayId: item.timeOfDayId,
          dayOfWeek: parseInt(day),
          numberOfPlays: item.plays[day],
        })
      );
    });

    return filteredData;
  };
  modifyPlay = (timeOfDayId, day, count) => {
    let { placementTable } = this.state;

    placementTable = map(placementTable, (item) => {
      // Check if the day is valid before modifying plays
      if (item.timeOfDayId === timeOfDayId && this.isValidDay(day)) {
        // Only modify the selected timeOfDayId row if the day is valid
        item.plays[day] += count;
        this.setActiveCell(item, day, item.plays[day]);
      }
      // else if (item.plays[day] > 0 && this.setActiveCell(item.timeOfDayId, timeOfDayId)) {
      //   // Disable other overlapping timeOfDay cells
      //   item.plays[day] = 0;
      // }
      return item;
    });

    const plays = this.filterPlacementData(placementTable);
    this.onChange(plays, "plays");

    this.setState({
      placementTable,
    });
  };

  checkIfTimeOverlaps = (slot1, slot2) => {
    const startTime1 = moment(slot1.startTime, "HH:mm:ss");
    const endTime1 = moment(slot1.endTime, "HH:mm:ss");
    const startTime2 = moment(slot2.startTime, "HH:mm:ss");
    const endTime2 = moment(slot2.endTime, "HH:mm:ss");

    return (
      (startTime1.isSameOrBefore(startTime2) && endTime1.isAfter(startTime2)) ||
      (startTime2.isSameOrBefore(startTime1) && endTime2.isAfter(startTime1))
    );
  };

  isValidDay = (dayValue) => {
    const { media } = this.props;

    const startDate = moment(media.validity.fromDate);
    const endDate = moment(media.validity.toDate);

    // Calculate the number of days in the validity range
    const validityDays = endDate.diff(startDate, "days") + 1;

    // Calculate the day of the week for the start date
    const startDayOfWeek = startDate.day(); // 0 to 6

    // Check if the dayValue falls within the validity range
    // or if it's within the next complete week
    return (
      // Checks if the current day falls on or after the start day of the validity range.
      (dayValue >= startDayOfWeek &&
        // Checks if the current day falls before the end day of the validity range.
        dayValue < startDayOfWeek + validityDays) ||
      // Checks if the current day is a valid day of the week (0 to 6).
      (dayValue >= 0 &&
        // Checks if the current day falls within the next complete week after the validity range.
        dayValue < validityDays - (7 - startDayOfWeek))
    );
  };

  setActiveCell = (clickedSlot, key, count, isloop = false) => {
    const { placementTable, activeCellData } = this.state;
    const overlappingSlotsIndex = [];
    const currentSlotIndex = [];
    placementTable.forEach((slot, i) =>
      // Check if the clicked slot overlaps with any other slot and return the overlapping slot
      {
        if (
          slot.timeOfDayId !== clickedSlot.timeOfDayId &&
          this.checkIfTimeOverlaps(slot, clickedSlot)
        ) {
          overlappingSlotsIndex.push(i);
        }
        if (slot.timeOfDayId === clickedSlot.timeOfDayId && isloop) {
          currentSlotIndex.push(i);
        }
      }
    );
    const activecelldata = [...activeCellData];
    overlappingSlotsIndex.forEach((index) => {
      if (count > 0) activecelldata[index][key] = false;
      else activecelldata[index][key] = true;
    });
    currentSlotIndex.forEach((index) => {
      activecelldata[index][key] = true;
    });
    const newPlacementTable = placementTable.map((item, i) => {
      if (!activecelldata[i][key]) item.plays[key] = 0;
      return item;
    });
    this.setState({
      activeCellData: activecelldata,
      placementTable: newPlacementTable,
    });
  };

  modifyPlaysByRowOrColumn = (data, count, type) => {
    let { placementTable } = this.state;
    placementTable = map(placementTable, (item, key) => {
      if (type === "day") {
        if (this.state.activeCellData[key][data]) item.plays[data] += count;
        if (item.plays[data] < 0) item.plays[data] = 0;
        this.setActiveCell(item, data, item.plays[data]);
      } else if (item.timeOfDayId === data && type === "timeOfDay") {
        keys(item.plays).map((day) => {
          const numday = parseInt(day);
          // Check if the day is valid before modifying plays
          if (this.isValidDay(numday)) {
            this.setActiveCell(item, numday, item.plays[numday] + count, true);
            if (item.plays[day] + count < 0) {
              item.plays[day] = 0;
            } else {
              item.plays[day] += count;
            }
          }
          return null;
        });
      }
      return item;
    });
    const plays = this.filterPlacementData(placementTable);
    this.onChange(plays, "plays");
    this.setState({
      placementTable,
    });
  };

  renderHeader = (day, dayValue) => {
    // const { placementTable } = this.state;
    // const playsByDay = placementTable.map((item) => item.plays[dayValue]);

    // Check if the day is within the validity range
    const isValidDay = this.isValidDay(dayValue);

    return (
      <div className={styles.placementHeader}>
        <span
          className={`${styles.addAndRemoveIcon} 
            ${classNames({
              [styles.decrementDisabled]: !isValidDay,
            })}`}
          onClick={() => this.modifyPlaysByRowOrColumn(dayValue, -1, "day")}
        >
          <RemoveFilledIcon width={20} height={20} />
        </span>
        <div className={styles.placementItem}>{day}</div>
        <span
          onClick={() => this.modifyPlaysByRowOrColumn(dayValue, 1, "day")}
          className={`${styles.addAndRemoveIcon} 
           ${classNames({
             [styles.decrementDisabled]: !isValidDay,
           })}`}
        >
          <AddFilledIcon width={20} height={20} />
        </span>
      </div>
    );
  };

  renderPlay = (d, dayValue, index) => {
    const { activeCellData } = this.state;

    const isValidDay = this.isValidDay(dayValue);

    return (
      <div
        className={classNames({
          [styles.disabledCell]: !activeCellData[index][dayValue],
        })}
      >
        <div className={styles.placementContent}>
          <span
            className={`${styles.addAndRemoveIcon} 
             ${classNames({
               [styles.decrementDisabled]: d.plays[dayValue] === 0 || !isValidDay,
             })}`}
            onClick={() => this.modifyPlay(d.timeOfDayId, dayValue, -1)}
          >
            <RemoveFilledIcon width={20} height={20} />
          </span>
          <div className={styles.placementItem}> {d.plays[dayValue] || 0} </div>
          <span
            onClick={() => this.modifyPlay(d.timeOfDayId, dayValue, 1)}
            className={` ${styles.addAndRemoveIcon} 
              ${classNames({
                [styles.decrementDisabled]: !isValidDay,
              })}`}
          >
            <AddFilledIcon width={20} height={20} />
          </span>
        </div>
      </div>
    );
  };
  /**
   * Removes existing combination from placement options based on the selected key.
   * @param {Object} selected - The selected item.
   * @param {string} key - The key indicating the type of combination to remove.
   */
  removeExistingCombination(selected, key) {
    const {
      media: { placements },
      placementoptions,
      placement,
    } = this.props;
    if (isEmpty(placements) || !selected) return;
    const _placementoptions = {
      ...placementoptions,
    };
    const items = placements.filter(
      (item) => selected.id === item[key] && placement.index !== item.index
    );
    if (key === "segmentId") {
      _placementoptions.playlistPackTypes = _placementoptions.playlistPackTypes.filter(
        (item) => !items.find((i) => i.playlistPackTypeId === item.id)
      );
    }
    if (key === "playlistPackTypeId") {
      _placementoptions.segmentTypes = _placementoptions.segmentTypes.filter(
        (item) => !items.find((i) => i.segmentId === item.id)
      );
    }
    this.setState({
      placementoptions: _placementoptions,
    });
  }

  render() {
    const {
      timesOfDay = [],
      placement,
      hasFormSaved,
      media,
      onInputChange,
      // campaignMedia: { placements },
    } = this.props;
    const { placementTable, activeCellData, placementoptions } = this.state;
    const [selectedSegement] = placementoptions.segmentTypes.filter(
      (item) => item.id === placement?.segmentId
    );
    const [selectedplaylistPackType] = placementoptions.playlistPackTypes.filter(
      (item) => item.id === placement?.playlistPackTypeId
    );
    //selected position is based on placement.position or default 0
    const selectedPosition =
      placementoptions.positions.find((item) => item.id === placement?.position) ||
      placementoptions.positions.find((item) => item.id === 0);

    const showAdvanced = media?.placementMode === "Advanced";
    return (
      <>
        <Form
          isFormGroup={false}
          config={[
            {
              type: FORM_FIELD_TYPE.SELECT,
              id: "playlistPackTypeId",
              title: "Playlist Pack",
              placeHolder: "Select Playlist Pack Type",
              // fetchAction: async () => {
              //   const data = await fetchPlaylistPackTypes();
              //   this.removeExistingCombination(media.placements, placement, data.data);
              //   return data;
              // },
              size: {
                lg: 2,
                md: 2,
              },
              list: placementoptions.playlistPackTypes.map((t) => ({ ...t, value: t.name })),
              selectedList: !isEmpty(selectedplaylistPackType)
                ? [selectedplaylistPackType.name]
                : [],
              //! Have to revisit this logic
              disabled:
                media.status === "Active" || placementoptions.playlistPackTypes.length === 0,
              // parseResults: (response) =>
              //   response.data.map((item) => ({
              //     id: item.id,
              //     value: item.name,
              //   })),
              // selected: (list) => {
              //   return list.filter((item) => item.id === placement?.playlistPackTypeId);
              // },
              compareKey: "id",
              textKey: "name",
              usePortal: false,
              singleSelect: true,
              showLabelInButton: false,
              errorMessage: "Please Select Playlist Pack Type",
              error: hasFormSaved && !placement?.playlistPackTypeId,
              onSelect: ([data]) => this.onChange(data.id, "playlistPackTypeId"),
            },
            // {
            //   id: "numberOfPlays",
            //   title:
            //     placementTable.some((item) => values(item.plays).some((value) => value > 0)) ||
            //     (placements && placements[index]?.plays?.length > 0)
            //       ? "Total Plays"
            //       : "Plays/Show for Week",
            //   type: FORM_FIELD_TYPE.NUMERIC_INPUT,
            //   size: {
            //     lg: 2,
            //     md: 2,
            //   },
            //   placeholder: "Enter Plays",
            //   // ! placement doesn't have direct property numberOfPlays
            //   // value: placement?.numberOfPlays,
            //   value:
            //     placement?.plays?.reduce((acc, curr) => acc + curr.numberOfPlays, 0) ||
            //     placement?.numberOfPlays ||
            //     "",
            //   disabled: showAdvanced || placement?.plays?.length > 0,
            //   errorMessage: "Please Enter Plays Per Screen",
            //   error: hasFormSaved && !placement?.numberOfPlays && !placement?.plays?.length > 0,
            //   onValueChange: (value) => this.onChangeOfNoOfPlays(value, "numberOfPlays"),
            // },

            {
              id: "segmentId",
              key: "segmentId",
              title: "Segment Name",
              type: FORM_FIELD_TYPE.SELECT,
              size: {
                lg: 3,
                md: 3,
              },
              placeHolder: "Select Segment",
              list: placementoptions.segmentTypes
                .filter((item) => item.playlistPackTypeId === placement.playlistPackTypeId)
                .map((t) => ({
                  ...t,
                  value: t.name,
                })),
              selectedList: !isEmpty(selectedSegement) ? [selectedSegement.name] : [],
              disabled:
                media.status === "Active" ||
                placementoptions.segmentTypes.filter(
                  (item) => item.playlistPackTypeId === placement?.playlistPackTypeId
                ).length === 0 ||
                !placement?.playlistPackTypeId,
              singleSelect: true,
              compareKey: "id",
              textKey: "name",
              usePortal: false,
              showLabelInButton: false,
              errorMessage: "Please Select Segment",
              error: hasFormSaved && !placement?.segmentId,
              onSelect: ([data]) => this.onChange(data.id, "segmentId"),
            },
            {
              type: FORM_FIELD_TYPE.SELECT,
              id: "position",
              title: "Position",
              placeHolder: "Select Position",
              size: {
                lg: 2,
                md: 2,
              },
              list: placementoptions.positions.map((t) => ({ ...t, value: t.name })),
              selectedList: !isEmpty(selectedPosition) ? [selectedPosition.name] : [],
              // disabled:
              //   media.status === "Active" || placementoptions.playlistPackTypes.length === 0,
              compareKey: "id",
              textKey: "name",
              usePortal: false,
              singleSelect: true,
              showLabelInButton: false,
              //errorMessage: "Please Select Position",
              //error: hasFormSaved && !placement?.position,
              onSelect: ([data]) => this.onChange(data.id, "position"),
            },
            {
              type: FORM_FIELD_TYPE.SELECT,
              id: "positionUnavailability",
              title: "Position Unavailability",
              placeHolder: "Select Position Unavailability",
              size: {
                lg: 5,
                md: 5,
              },
              list: placementoptions.positionUnavailabilityOptions?.map((t) => ({
                value: t.displayName,
                id: t.name,
                name: t.name,
              })),
              selectedList: !isEmpty(media.positionUnavailability)
                ? [
                    placementoptions.positionUnavailabilityOptions.filter(
                      (p) => p.name === media.positionUnavailability
                    )[0]?.displayName || null,
                  ]
                : [],
              compareKey: "id",
              textKey: "name",
              usePortal: false,
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => onInputChange(data.id, "positionUnavailability"),
              error: hasFormSaved && !media?.positionUnavailability,
              errorMessage: "Please Select Position Unavailability",
            },
            {
              type: FORM_FIELD_TYPE.RADIO,
              title: "Mode",
              className: "col-11",
              // isHidden: isEmpty(campaign?.validity) && billingCycles.length === 0,
              size: {
                lg: 12,
                md: 12,
              },
              placeholder: "Select Billing Cycle",
              value: media?.placementMode || placementoptions.modes[0],
              radioList: placementoptions.modes.map((mode) => ({
                label: mode,
                value: mode,
                id: mode,
              })),
              onChange: (v) => {
                onInputChange(false, "canPlayBackToBack");
                onInputChange(v, "placementMode");
              },
            },
            {
              id: "weeklyPlayCount",
              label: "Weekly Play Count",
              type: FORM_FIELD_TYPE.INPUT,
              size: {
                lg: 3,
                md: 3,
              },
              placeholder: "Max play count per screen per week",
              value: media.weeklyPlayCount || "",
              onChange: (v) => onInputChange(parseInt(v.target.value), "weeklyPlayCount"),
              isHidden: showAdvanced,
              error: hasFormSaved && !(media?.weeklyPlayCount || showAdvanced),
              errorMessage: "Please Enter Weekly Play Count",
            },
            {
              id: "showPlayCount",
              label: "Show Play Count",
              type: FORM_FIELD_TYPE.INPUT,
              size: {
                lg: 3,
                md: 3,
              },
              placeholder: "Enter Show Play Count",
              value: media.showPlayCount || "",
              onChange: (v) => {
                onInputChange(parseInt(v.target.value), "showPlayCount");
                this.onChange(parseInt(v.target.value), "numberOfPlays");
              },
              isHidden: showAdvanced,
              error: hasFormSaved && !(media?.showPlayCount || showAdvanced),
              errorMessage: "Please Enter Show Play Count",
            },
            {
              id: "playBackToBack",
              label: "Play back-to-back",
              type: FORM_FIELD_TYPE.CHECK_BOX,
              containerClassName: styles.alignContainer,
              size: {
                lg: 3,
                md: 3,
              },
              checked: media.canPlayBackToBack,
              onChange: () => onInputChange(!media.canPlayBackToBack, "canPlayBackToBack"),
              isHidden: showAdvanced,
              disabled: (media.showPlayCount || 0) < 2,
            },
          ]}
        />
        {showAdvanced && (
          <>
            <SecondaryTable
              data={placementTable}
              bodyColumnClass={styles.tableColumn}
              rowHeader={styles.headerColumn}
              columns={[
                {
                  id: "timeOfDay",
                  Header: "",
                  accessor: (d) => {
                    const timeOfDay = timesOfDay.find((day) => day.id === d.timeOfDayId);
                    const playsByTime = placementTable.find(
                      (item) => item.timeOfDayId === timeOfDay.id
                    );
                    const findIndex = placementTable.findIndex(
                      (item) => item.timeOfDayId === timeOfDay.id
                    );
                    if (!timeOfDay) return "";
                    return (
                      <div className={styles.timeOfDayHeader}>
                        <div className={styles.timeOfDayContent}>
                          <div className={styles.placementsTimeName}>{timeOfDay.name}</div>
                          <div className={styles.placementsTimeRange}>
                            {moment(timeOfDay.startTime, "HH:mm:ss").format("hh:mm a")}-
                            {moment(timeOfDay.endTime, "HH:mm:ss").format("hh:mm a")}
                          </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div
                            className={`${styles.addAndRemoveIcon}
                              ${classNames({
                                [styles.decrementDisabled]: activeCellData[findIndex].every(
                                  (a) => a === false
                                ),
                              })}`}
                            onClick={() =>
                              this.modifyPlaysByRowOrColumn(timeOfDay.id, 1, "timeOfDay")
                            }
                          >
                            <AddFilledIcon width={20} height={20} />
                          </div>
                          <div
                            className={` ${styles.addAndRemoveIcon}
                              ${classNames({
                                [styles.decrementDisabled]: values(playsByTime.plays).every(
                                  (e) => e === 0
                                ),
                              })}`}
                            onClick={() =>
                              this.modifyPlaysByRowOrColumn(timeOfDay.id, -1, "timeOfDay")
                            }
                          >
                            <RemoveFilledIcon width={20} height={20} />
                          </div>
                        </div>
                      </div>
                    );
                  },
                  width: 160,
                },
                ...DAY_OF_WEEK.map((dayOfWeek) => ({
                  id: dayOfWeek.value,
                  Header: this.renderHeader(dayOfWeek.label, dayOfWeek.value),
                  accessor: (d) =>
                    this.renderPlay(
                      d,
                      dayOfWeek.value,
                      this.state.placementTable.findIndex((p) => p.timeOfDayId === d.timeOfDayId)
                    ),
                })),
              ]}
            />
            <Form
              isFormGroup={false}
              config={[
                {
                  id: "weeklyPlayCount",
                  label: "Weekly Play Count",
                  type: FORM_FIELD_TYPE.LABEL,
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  value: media.totalNumberOfPlays || "0",
                  isHidden: !showAdvanced,
                },
                {
                  id: "playBackToBack",
                  label: "Play back-to-back when applicable",
                  type: FORM_FIELD_TYPE.CHECK_BOX,
                  size: {
                    lg: 5,
                    md: 5,
                  },
                  checked: media.canPlayBackToBack,
                  onChange: () => onInputChange(!media.canPlayBackToBack, "canPlayBackToBack"),
                  isHidden: !showAdvanced,
                  disabled: !placement?.plays.some((p) => p.numberOfPlays > 1),
                },
              ]}
            />
          </>
        )}
      </>
    );
  }
}

// SLATE-1422 Akash Vinchankar
// Validate the data for each placement
// const validateAddPlacement = (data) => {
//   // Check if data is not an array or is an empty array
//   if (!Array.isArray(data) || data.length === 0) {
//     // Handle the case where data is not an array or is an empty array
//     return false;
//   }

//   // Use the every method to check the condition for every item in the array
//   return data.every((item) => {
//     // If plays is undefined, treat it as an empty array
//     const plays = item?.plays || [];

//     return (
//       // Check if playlistPackTypeId and segmentId are not empty, numberOfPlays !== 0
//       !isEmpty(item?.playlistPackTypeId) &&
//       !isEmpty(item?.segmentId) &&
//       // Check if every play in plays has a non-empty timeOfDayId
//       //plays.filter((play) => isEmpty(play.timeOfDayId)).length === 0
//       //check if either numberOfPlays is not 0 (manual) or plays.length >0 (advanced option)
//       ((item?.numberOfPlays && item?.numberOfPlays !== 0) || plays.length !== 0)
//     );
//   });
// };

const PlacementOptions = (props) => {
  return (
    <InfoBlock header="Number of Plays & Placement" childClassName={styles.childWrapper}>
      <div
        className={classNames(
          "flex flex-wrap clearfix",
          styles.formContainer,
          styles.basicDetails,
          styles.placementsWrapper
        )}
        style={{ width: "100%" }}
      >
        {props.media.placements.map((placement, index) => (
          <MediaPlacement {...props} key={index} index={index} placement={placement} />
        ))}
      </div>
    </InfoBlock>
  );
};

export default PlacementOptions;
