import { omit } from "lodash";
import {
  cancelCampaigns as cancelCampaignsApi,
  cancelMedia as cancelMediaApi,
  cancelScreens as cancelScreensApi,
  cancelTargetGroups as cancelTargetGroupsApi,
  createOrUpdateCampaign as createOrUpdateCampaignApi,
  createOrUpdateTargetGroup as createOrUpdateTargetGroupApi,
  getCampaign as getCampaignApi,
  getCampaignFileUrl as getCampaignFileUrlApi,
  getCampaignFiles as getCampaignFilesApi,
  getCampaignMedia as getCampaignMediaApi,
  getCampaignScreens as getCampaignScreensApi,
  getCampaignSummary as getCampaignSummaryApi,
  getCampaignTargetGroups as getCampaignTargetGroupsApi,
  getCampaignTheatres as getCampaignTheatresApi,
  getCampaigns as getCampaignsApi,
  moveCampaign as moveCampaignApi,
  moveMedia as moveMediaApi,
  moveTargetGroup as moveTargetGroupApi,
  pauseOrResumeCampaigns as pauseOrResumeCampaignsApi,
  pauseOrResumeMedia as pauseOrResumeMediaApi,
  pauseOrResumeTargetGroup as pauseOrResumeTargetGroupApi,
  getCampaignSpots as getCampaignSpotsApi,
} from "../../api";
import * as constants from "../../constants";
import { showToast } from "../../utils";
import * as types from "../types/campaigns";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateCampaigns = (payload) => {
  return { type: types.CAMPAIGNS, payload: payload };
};

const updateCampaign = (payload) => {
  return { type: types.CAMPAIGN, payload: payload };
};

const updateCampaignSummary = (payload) => {
  return { type: types.CAMPAIGN_SUMMARY, payload: payload };
};

const updateCampaignTargetGroups = (payload) => {
  return { type: types.CAMPAIGN_TARGET_GROUPS, payload: payload };
};

const updateCampaignFiles = (payload) => {
  return { type: types.CAMPAIGN_FILES, payload: payload };
};

const updateCampaignMedia = (payload) => {
  return { type: types.CAMPAIGN_MEDIA, payload: payload };
};

const updateCampaignTheatres = (payload) => ({ type: types.CAMPAIGN_THEATRES, payload });
const updateCampaignScreens = (payload) => ({ type: types.CAMPAIGN_SCREENS, payload });
const updateCampaignSpots = (payload) => {
  return { type: types.CAMPAIGN_SPOTS, payload: payload };
};

const viewCampaignFiles = (files) => (dispatch) =>
  dispatch(updateCampaignFiles({ files, isOpen: true }));

const getCampaigns = (params = {}, filters = [], getList = getCampaignsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc" } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaigns(data));
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGNS_LOADING, false));
    }
  };
};

const getCampaign = (params) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getCampaignApi(params.campaignId);
      dispatch(updateCampaign(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateCampaignDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      await createOrUpdateCampaignApi(data);
      await showToast("Toast.updatedCampaignMaxPlaysPerScreen", true);
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateTargetGroupDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      await createOrUpdateTargetGroupApi(data);
      await showToast("Toast.updatedTargetGroupMaxPlaysPerScreen", true);
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const pauseOrResumeCampaigns = (
  ids,
  status,
  period,
  params,
  isSingleCampaign = false,
  filters = []
) => {
  // const { req: ft } = createFilterRequest(
  //   ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  // );
  const data = {
    //ft,
    ids,
    //status: status,
    fromDate: period.from,
    ...(period.to != null && { toDate: period.to }),
    ...(period.reason != null && { reasonId: period.reason }),
    comments: period.notes,
    ...(period.clearPause != null && { clearPause: period.clearPause }),
  };
  return async (dispatch) => {
    try {
      const response = await pauseOrResumeCampaignsApi(data, status.toLowerCase());
      isSingleCampaign
        ? await dispatch(getCampaign({ campaignId: ids[0] }))
        : await dispatch(getCampaigns(params, filters));
      response.data.forEach(async (res) => {
        if (res.code === 200) {
          await showToast("Toast.statusUpdatedSuccessfully", true);
        } else {
          await showToast(res.message || "Toast.unexpectedError", false);
        }
      });
    } catch (error) {
      isSingleCampaign
        ? await dispatch(getCampaign({ screenId: ids[0] }))
        : await dispatch(getCampaigns(params, filters));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
const pauseOrResumeTargetGroups = (ids, status, period, params, filters = []) => {
  // const { req: ft } = createFilterRequest(
  //   ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  // );
  const data = {
    //ft,
    ids,
    fromDate: period.from,
    ...(period.to != null && { toDate: period.to }),
    ...(period.reason != null && { reasonId: period.reason }),
    comments: period.notes,
    ...(period.clearPause != null && { clearPause: period.clearPause }),
  };
  return async (dispatch) => {
    try {
      const response = await pauseOrResumeTargetGroupApi(data, status.toLowerCase());
      await dispatch(getCampaignTargetGroups(params, filters));
      response.data.forEach(async (res) => {
        if (res.code === 200) {
          await showToast("Toast.statusUpdatedSuccessfully", true);
        } else {
          await showToast(res.message || "Toast.unexpectedError", false);
        }
      });
    } catch (error) {
      await dispatch(getCampaignTargetGroups(params, filters));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
const pauseOrResumeOneOrMoreMedia = (ids, status, period, params, filters) => {
  // const { req: ft } = createFilterRequest(
  //   ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  // );
  const data = {
    //ft,
    ids,
    fromDate: period.from,
    ...(period.to !== null && { toDate: period.to }),
    ...(period.reason !== null && { reasonId: period.reason }),
    comments: period.notes,
    ...(period.clearPause != null && { clearPause: period.clearPause }),
  };
  return async (dispatch) => {
    try {
      const response = await pauseOrResumeMediaApi(data, status.toLowerCase());
      await dispatch(getCampaignMedia(params, filters));
      response.data.forEach(async (res) => {
        if (res.code === 200) {
          await showToast("Toast.statusUpdatedSuccessfully", true);
        } else {
          await showToast(res.message || "Toast.unexpectedError", false);
        }
      });
    } catch (error) {
      await dispatch(getCampaignMedia(params, filters));
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const cancel = (data, type) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return cancelCampaignsApi(data);
    case constants.CAMPAIGN_TYPE.TARGET_GROUP:
      return cancelTargetGroupsApi(data);
    case constants.CAMPAIGN_TYPE.MEDIA:
      return cancelMediaApi(data);
    case constants.CAMPAIGN_TYPE.SCREEN:
      return cancelScreensApi(data.campaignId, omit(data, ["campaignId"]));
    default:
      return;
  }
};
const fetchList = async (params, data, dispatch, type, filters = []) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return await dispatch(getCampaigns(params, filters));
    case constants.CAMPAIGN_TYPE.TARGET_GROUP:
      return await dispatch(getCampaignTargetGroups(params, filters));
    case constants.CAMPAIGN_TYPE.MEDIA:
      return await dispatch(getCampaignMedia(params, filters));
    case constants.CAMPAIGN_TYPE.SCREEN:
      return await dispatch(getCampaignTheatres(params, filters));
    default:
      return;
  }
};

const fetchDetails = async (data, dispatch, type) => {
  switch (type) {
    case constants.CAMPAIGN_TYPE.CAMPAIGN:
      return await dispatch(getCampaign({ campaignId: data.ids[0] }));
    default:
      return;
  }
};

const cancelCampaigns = (
  data,
  type,
  params,
  callback = () => {},
  isSingleCampaign = false,
  filters = []
) => {
  // const { req: ft } = createFilterRequest(
  //   data.ids.map((id) => ({ id: id, type: constants.TAG_TYPE.CAMPAIGNS }))
  // );
  const campaigns = { ...data };
  return async (dispatch) => {
    try {
      const response = await cancel(
        campaigns.fromDate ? campaigns : omit(campaigns, ["fromDate"]),
        type
      );
      isSingleCampaign
        ? fetchDetails(data, dispatch, type)
        : fetchList(params, data, dispatch, type, filters);
      callback();
      response.data.forEach(async (res) => {
        if (res.code === 200) {
          await showToast("Toast.statusUpdatedSuccessfully", true);
        } else {
          await showToast(res.message || "Toast.unexpectedError", false);
        }
      });
    } catch (error) {
      isSingleCampaign
        ? fetchDetails(data, dispatch, type)
        : fetchList(params, data, dispatch, type, filters);
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};
//action for move campaign,tg,media
const moveCampaigns = (
  data,
  type,
  params,
  callback = () => {},
  isSingleCampaign = false,
  filters = []
) => {
  return async (dispatch) => {
    try {
      const apiCalls = {
        [constants.CAMPAIGN_TYPE.MEDIA]: async () => await moveMediaApi(data),
        [constants.CAMPAIGN_TYPE.TARGET_GROUP]: async () => {
          //api call for target group move
          await moveTargetGroupApi(data);
        },
        [constants.CAMPAIGN_TYPE.CAMPAIGN]: async () => {
          //api call for campaign move
          await moveCampaignApi(data);
        },
      };
      const toastMessages = {
        [constants.CAMPAIGN_TYPE.MEDIA]: "Toast.mediaUpdatedSuccessfully",
        [constants.CAMPAIGN_TYPE.TARGET_GROUP]: "Toast.targetGroupUpdatedSuccessfully",
        [constants.CAMPAIGN_TYPE.CAMPAIGN]: "Toast.campaignUpdatedSuccessfully",
      };

      if (apiCalls[type]) {
        await apiCalls[type]();
      }
      if (!isSingleCampaign) {
        fetchList(params, [], dispatch, type, filters);
      }
      callback();
      if (toastMessages[type]) {
        showToast(toastMessages[type], true);
      }
    } catch (error) {
      if (!isSingleCampaign) {
        fetchList(params, [], dispatch, type, filters);
      }
      await showToast(error.response.data.message || "Toast.unexpectedError", false);
      return error;
    }
  };
};

const getCampaignFiles = (campaignId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, true));
      const { data } = await getCampaignFilesApi(campaignId);
      dispatch(viewCampaignFiles(data));
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_FILE_LOADING, false));
    }
  };
};

const downloadCampaignFile = (campaignId, file) => {
  return async (dispatch) => {
    try {
      const { data } = await getCampaignFileUrlApi(campaignId, file.name, true);
      window.open(data.url, "_blank");
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getCampaignSummary = (campaignId) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getCampaignSummaryApi(campaignId);
      dispatch(updateCampaignSummary(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(updateCampaignSummary({ data: {} }));
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignTargetGroups = (
  params = {},
  filters = [],
  getList = getCampaignTargetGroupsApi
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignTargetGroups(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getCampaignMedia = (params = {}, filters = [], getList = getCampaignMediaApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignMedia(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

const getTheatresList = (id, params) => {
  //SLATE-1390 Nov17 Anushree:- filters should also be passed in the API call
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", filters } = params;
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      //return getCampaignTheatresApi(id, ps, (page - 1) * ps, sort);
      return getCampaignTheatresApi(id, ps, (page - 1) * ps, sort, filters);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      //return getCampaignScreensApi(id, ps, (page - 1) * ps, sort);
      return getCampaignScreensApi(id, ps, (page - 1) * ps, sort, filters);
    default:
      return;
  }
};

const updateTheatresAndScreens = (params, data) => {
  switch (params.targetType) {
    case constants.THEATRE_SCREENS_VIEW_TYPE.THEATRES:
      return updateCampaignTheatres(data);
    case constants.THEATRE_SCREENS_VIEW_TYPE.SCREENS:
      return updateCampaignScreens(data);
    default:
      return;
  }
};

const getCampaignTheatres = (params = {}) => {
  const { id } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getTheatresList(id, params);
      dispatch(updateTheatresAndScreens(params, data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};
//action to get Campaign Spots
const getCampaignSpots = (params = {}, filters = [], getList = getCampaignSpotsApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      const { filters: defaultFilter } = params;
      filters = filters.length === 0 ? defaultFilter : filters;
      dispatch(actionCreator(types.IS_TAB_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignSpots(data));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TAB_LOADING, false));
    }
  };
};

export {
  cancelCampaigns,
  downloadCampaignFile,
  getCampaign,
  getCampaignFiles,
  getCampaignMedia,
  getCampaignSummary,
  getCampaignTargetGroups,
  getCampaignTheatres,
  getCampaigns,
  moveCampaigns,
  pauseOrResumeCampaigns,
  pauseOrResumeOneOrMoreMedia,
  pauseOrResumeTargetGroups,
  updateCampaignDetails,
  updateCampaignFiles,
  updateTargetGroupDetails,
  viewCampaignFiles,
  getCampaignSpots,
};
