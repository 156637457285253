const IS_LOADING = "pendingApprovals/campaignApprovals/IS_LOADING";
const IS_ERROR = "pendingApprovals/campaignApprovals/IS_ERROR";
const CAMPAIGN_APPROVALS = "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVALS";
const REJECTED_PROPOSALS = "pendingApprovals/campaignApprovals/REJECTED_PROPOSALS";
const CAMPAIGN_APPROVAL = "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL";
const ACTION = "pendingApprovals/campaignApprovals/ACTION";
const IS_TAB_LOADING = "pendingApprovals/campaignApprovals/IS_TAB_LOADING";
const CAMPAIGN_APPROVAL_MEDIA = "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_MEDIA";
const CAMPAIGN_APPROVAL_MEDIA_DETAILS =
  "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_MEDIA_DETAILS";
const CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING =
  "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING";
const CAMPAIGN_APPROVAL_TARGET_GROUPS =
  "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_TARGET_GROUPS";
const CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING =
  "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING";
const CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS =
  "pendingApprovals/campaignApprovals/CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS";

export {
  IS_LOADING,
  IS_ERROR,
  CAMPAIGN_APPROVALS,
  REJECTED_PROPOSALS,
  CAMPAIGN_APPROVAL,
  ACTION,
  IS_TAB_LOADING,
  CAMPAIGN_APPROVAL_MEDIA,
  CAMPAIGN_APPROVAL_MEDIA_DETAILS,
  CAMPAIGN_APPROVAL_MEDIA_DETAILS_LOADING,
  CAMPAIGN_APPROVAL_TARGET_GROUPS,
  CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS,
  CAMPAIGN_APPROVAL_TARGET_GROUP_DETAILS_LOADING,
};
