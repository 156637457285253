/**
 * Removes paused durations from the durationGantt array based on the pausedStatus.
 * @param {Array} durationGantt - The array of durationGantt.
 * @param {Array} pausedStatus - The array of pausedStatus.
 * @returns {Array} - The modified durationGantt array.
 */
const RemovePausedDurationsFromDurationGantt = (durationGantt, pausedStatus) => {
  const covertToDisplayFormat = (date1, date2) =>
    `${date1.toString().split(" ").splice(1, 2).reverse().join("/")} -
    ${getYesterday(date2).toString().split(" ").splice(1, 2).reverse().join("/")}
    `;
  const getYesterday = (date) => {
    const today = new Date(date);
    return new Date(today.setDate(today.getDate()));
  };

  pausedStatus.forEach((status) => {
    const fromDate = new Date(status.fromDate);
    const toDate = new Date(status.toDate);
    const index = durationGantt.findIndex((ele) => fromDate > ele[2] && toDate < ele[3]);
    if (index === -1) {
      durationGantt = durationGantt.map((camp) => [
        camp[0],
        covertToDisplayFormat(camp[2], camp[3]),
        camp[2],
        camp[3],
      ]);
      return;
    }
    const campaignSegment = durationGantt[index];
    const newSegment = [
      [
        campaignSegment[0],
        covertToDisplayFormat(campaignSegment[2], fromDate),
        campaignSegment[2],
        fromDate,
      ],
      [
        campaignSegment[0],
        covertToDisplayFormat(toDate, campaignSegment[3]),
        toDate,
        campaignSegment[3],
      ],
    ];
    durationGantt.splice(index, 1);
    durationGantt.push(...newSegment);
  });
  return pausedStatus.length > 0
    ? durationGantt
    : (durationGantt = durationGantt.map((camp) => [
        camp[0],
        covertToDisplayFormat(camp[2], camp[3]),
        camp[2],
        camp[3],
      ]));
};

/**
 * Converts a date range into an array of single-day segments.
 *
 * @param {string} type - The type of segment.
 * @param {string} id - The ID of the segment.
 * @param {string} fromDate - The starting date of the range.
 * @param {string} toDate - The ending date of the range.
 * @returns {Array} - An array of single-day segments.
 */
const convertDateRangeToSingleDaySegments = (type, id, fromDate, toDate) => {
  const segments = [];
  const from = new Date(fromDate);
  const to = new Date(toDate);
  for (let date = from; date < to; date) {
    segments.push([type, id, new Date(date), new Date(date.setDate(date.getDate() + 1))]);
  }
  return segments;
};

export { RemovePausedDurationsFromDurationGantt, convertDateRangeToSingleDaySegments };
