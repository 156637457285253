import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import {
  bindDispatch,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  modifyTableColumns,
} from "../../../../utils";
import { SCOPES, SCOPE_ACTIONS, DEFAULT_ACTION_ICON_COLUMN } from "../../../../constants";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import ActionIcon from "../../../common/ActionIcon";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import HeaderButton from "../../../common/HeaderButtons";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import EditTimesOfDay from "./EditTimesOfDay";

const _LINKS = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.timesOfDay" }];

export class TimesOfDay extends Component {
  state = {
    selectedColumns: ["Name", "Time Range", "Default", "Last Updated By", "Last Updated On"],
  };
  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addTimesOfDay();
      } else {
        actions.editTimesOfDayById(params.action);
      }
    }
    actions.getTimesOfDayList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  addTimesOfDay = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addTimesOfDay();
    history.replace(`/lists/times-of-day/new${location.search}`);
  };

  save = (timesOfDay, checkIsUnique) => {
    const { actions } = this.props;
    actions.createOrUpdateTimesOfDay(timesOfDay, checkIsUnique, this.cancelEdit);
  };

  editTimesOfDay = (data) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editTimesOfDay(data);
    history.replace(`/lists/times-of-day/${data.id}${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditTimesOfDay();
    history.replace(`/lists/times-of-day${location.search}`);
  };
  render() {
    const {
      timesOfDay: { data, isLoading, isPartialLoading, timesOfDayEdit },
      history,
      userData,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    const addButtonProps = {
      addButton: true,
      addButtonScope: SCOPES.TIMES_OF_DAY,
      addButtonScopeAction: SCOPE_ACTIONS.WRITE,
      addNewField: this.addTimesOfDay,
      userData,
    };
    const query = queryString.parse(history.location.search);

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Name",
          accessor: "name",
        },
        {
          id: "startTime",
          Header: "Time Range",
          accessor: (d) => `${d.startTime} - ${d.endTime}`,
        },
        {
          id: "isDefault",
          Header: "Default",
          accessor: (d) => (d.isDefault ? "Yes" : "No"),
        },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...DEFAULT_ACTION_ICON_COLUMN,
          scope: SCOPES.TIMES_OF_DAY,
          scopeAction: SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editTimesOfDay(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );
    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          name="PageHeader.timesOfDay"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={data.length}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              logsButton
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.TIMES_OF_DAY}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              pageSize={(query && parseInt(query.ps)) || 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={data || []}
              loading={isPartialLoading}
              sortable={false}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        {timesOfDayEdit.isOpen && (
          <EditTimesOfDay
            isOpen={timesOfDayEdit.isOpen}
            timesOfDay={timesOfDayEdit.timesOfDay}
            onClose={this.cancelEdit}
            onSave={this.save}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.timesOfDay,
  (state) => state.userData,
  (timesOfDay, userData) => ({ timesOfDay, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(TimesOfDay);
