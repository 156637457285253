import React from "react";
import LocalizedButton from "../../../../common/LocalizedButton";
import styles from "../../../../../styles/Content/cpls.module.scss";

const CPLButton = ({ id, iconName, onClick }) => (
  <LocalizedButton
    className={`${styles.headerButton}`}
    id={id}
    iconName={iconName}
    onClick={() => onClick(id)}
    text={`Button.${id}Cpls`}
  />
);

export default CPLButton;
