import { includes } from "lodash";
import {
  DEFAULT_BRANDS_PATH,
  BRAND_TAG_TYPE,
} from "../components/pages/catalogue/brands/brandConstants";

const getBrandType = (type, brandTagtype) => {
  const PATHS = Object.values(DEFAULT_BRANDS_PATH);
  if (includes(PATHS, type)) return type;
  else {
    return BRAND_TAG_TYPE[brandTagtype.type];
  }
};
export default getBrandType;
