import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditContentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentType: props.contentType,
      hasEditError: false,
    };
  }

  static propTypes = {
    contentType: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.setState({ contentType: this.props.contentType });
  };

  componentWillUnmount = () => {
    this.cancelEdit();
  };

  cancelEdit = () => {
    this.setState({ hasEditError: false, contentType: {} });
    this.props.onClose();
  };

  isValid = (contentType) => {
    if (contentType.code.trim().length === 0) return false;
    if (contentType.name.trim().length === 0) return false;
    if (contentType.description.trim().length === 0) return false;
    return true;
  };

  save = () => {
    const { contentType } = this.state;
    if (!this.isValid(contentType)) {
      this.setState({ hasEditError: true });
      return;
    }
    this.props.actions.saveOrUpdateContentType(contentType);
    this.cancelEdit();
  };

  inputOnChange = (value, accessor) => {
    const { contentType } = this.state;
    this.setState({
      contentType: { ...contentType, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { contentType = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={
          contentType.id ? "RightPanelHeader.editContentType" : "RightPanelHeader.addContentType"
        }
        isEdit={contentType.id ? true : false}
        showFooter={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: contentType.id ? FORM_FIELD_TYPE.LABEL : FORM_FIELD_TYPE.INPUT,
              label: "Code",
              title: "Code",
              className: "col-12",
              placeholder: "Enter Code",
              value: contentType.code,
              error: hasEditError && contentType.code.trim().length === 0,
              errorMessage: "Enter Valid Code",
              onChange: (e) => this.inputOnChange(e.target.value, "code"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Name",
              className: "col-12",
              placeholder: "Enter Name",
              value: contentType.name,
              error: hasEditError && contentType.name.trim().length === 0,
              errorMessage: "Enter Valid Name",
              onChange: (e) => this.inputOnChange(e.target.value, "name"),
            },
            {
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Description",
              placeholder: "Enter Description",
              value: contentType.description,
              error: hasEditError && contentType.description.trim().length === 0,
              errorMessage: "Enter Valid Description",
              onChange: (e) => this.inputOnChange(e.target.value, "description"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
