import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { FORM_FIELD_TYPE } from "../../../../constants";
import styles from "../../../../styles/Lists/TimesOfDay.module.scss";
import formStyle from "../../../../styles/Form.module.scss";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditTimesOfDay extends Component {
  state = {
    timesOfDay: this.props.timesOfDay || {},
    hasEditError: false,
    errorMessage: {
      name: "",
      timeRange: "",
    },
  };

  static propTypes = {
    timesOfDay: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentWillUnmount = () => {
    this.setState({ timesOfDay: {}, hasEditError: false });
  };

  isValid = (data) => {
    if (data.name.trim().length === 0) return false;
    return true;
  };
  getMinutes = (time) => {
    const times = time.split(`:`);
    return times[0] * 60 + times[1];
  };
  save = () => {
    const { timesOfDay, errorMessage } = this.state;
    if (!this.isValid(timesOfDay))
      return this.setState({
        hasEditError: true,
        errorMessage: { ...errorMessage, name: "Enter Valid Name" },
      });
    if (
      Boolean(Number(this.getMinutes(timesOfDay.startTime))) ||
      Boolean(Number(this.getMinutes(timesOfDay.endTime)))
    ) {
      if (this.getMinutes(timesOfDay.startTime) === this.getMinutes(timesOfDay.endTime)) {
        return this.setState({
          hasEditError: true,
          errorMessage: { name: "", timeRange: "Enter the Valid Start Time & End Time." },
        });
      }
    }
    this.setState({
      hasEditError: true,
      errorMessage: { name: "", timeRange: "" },
    });
    this.props.onSave(timesOfDay, this.checkIsUnique);
  };
  checkIsUnique = (message) => {
    this.setState({
      hasEditError: true,
      errorMessage: { name: message, timeRange: "" },
    });
  };

  inputOnChange = (value, accessor) => {
    const { timesOfDay } = this.state;
    this.setState({
      timesOfDay: { ...timesOfDay, [accessor]: value },
      hasEditError: true,
      errorMessage: { name: "", timeRange: "" },
    });
  };

  renderTimeRangePicker = (timesOfDay) => {
    return [
      {
        type: FORM_FIELD_TYPE.TIME_RANGE_PICKER,
        id: "fromTime",
        title: "Start Time ",
        usePortal: false,
        placeholder: "hh:mm",
        value: timesOfDay.startTime ? new Date(moment(timesOfDay.startTime, "HH:mm:ss")) : null,
        onChange: (time) => this.inputOnChange(moment(time).format(`HH:mm`), "startTime"),
        size: 4,
      },
      {
        type: FORM_FIELD_TYPE.TIME_RANGE_PICKER,
        id: "toTime",
        title: "End Time",
        usePortal: false,
        value: timesOfDay.endTime ? new Date(moment(timesOfDay.endTime, "HH:mm:ss")) : null,
        onChange: (time) => this.inputOnChange(moment(time).format(`HH:mm`), "endTime"),
        size: 4,
      },
    ];
  };
  render = () => {
    const { isOpen, onClose } = this.props;
    const { timesOfDay = {}, hasEditError, errorMessage } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={onClose}
        header={timesOfDay.id ? "RightPanelHeader.editTimeOfDay" : "RightPanelHeader.addTimeOfDay"}
        showFooter={true}
        isEdit={timesOfDay.id ? true : false}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: onClose }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Name",
              className: "col-12",
              placeholder: "Enter Name",
              value: timesOfDay.name,
              error: !isEmpty(errorMessage?.name),
              errorMessage: errorMessage.name,
              onChange: (e) => this.inputOnChange(e.target.value, "name"),
            },
          ]}
        />
        <div className={styles.timePicker}>
          <Form config={this.renderTimeRangePicker(timesOfDay)} />
        </div>
        <div className={classNames(formStyle.errorMessage, styles.errorMessage)}>
          {hasEditError ? errorMessage.timeRange : ""}
        </div>
        <Form
          config={[
            {
              id: "isDefault",
              className: styles.defaultField,
              type: FORM_FIELD_TYPE.CHECK_BOX,
              label: "Default",
              checked: timesOfDay.isDefault,
              onChange: () => this.inputOnChange(!timesOfDay.isDefault, "isDefault"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
