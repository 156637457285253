import { getShowFailureSpotStatus, getTagByID } from "../../api";
import * as types from "../types/showFailureSpotStatus";
import * as constants from "../../constants";

const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

const dayBeforeYesterday = new Date();
dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

const today = new Date();

const getDefaultFilters = () => [
  {
    from: dayBeforeYesterday,
    id: "dateRange",
    type: "dateRange",
    displayName: `${formatDate(dayBeforeYesterday)}-${formatDate(today)}`,
    to: today,
  },
];

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateShowData = (payload) => {
  return { type: types.SHOWFAILURE_SPOTSTATUS_DATA, payload: payload };
};

const getShowFailureSpotStatusData = (
  params,
  filters = [],
  getList = getShowFailureSpotStatus,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", id } = params;
  if (filters.length === 0) filters = getDefaultFilters();
  return async (dispatch) => {
    try {
      if (params.parentTagId) {
        const { data } = await getTagByID(params.parentTagId);
        filters = [...filters, data];
      }
      if (!id) return;
      dispatch(actionCreator(types.IS_LOADING, true));
      dispatch(actionCreator(types.FILTER, filters));

      const { data } = await getList(id, ps, (page - 1) * ps, sort, filters);
      dispatch(updateShowData(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export { getShowFailureSpotStatusData };
