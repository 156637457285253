import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Position } from "@blueprintjs/core";
import { Icons } from "prefab";
import TooltipButton from "../common/TooltipButton";
import styles from "../../styles/Lists/Lists.module.scss";
import withScopeAuth from "../hoc/withScopeAuth";
import tableControlStyles from "../../styles/TableControls.module.scss";
import { SCOPES } from "../../constants";
import LocalizedButton from "./LocalizedButton";
import ColumnSelector from "./ColumnSelector";

const TooltipButtonWithAuth = withScopeAuth(TooltipButton);
const { SettingsIcon } = Icons;

class HeaderButton extends Component {
  static propTypes = {
    addButton: PropTypes.bool,
    addButtonScope: PropTypes.string,
    addButtonScopeAction: PropTypes.string,
    editButton: PropTypes.bool,
    editButtonScope: PropTypes.string,
    editButtonScopeAction: PropTypes.string,
    columnFilter: PropTypes.bool,
    columns: PropTypes.array,
    logsButton: PropTypes.bool,
    logsOnlyForSlateAdmin: PropTypes.bool,
    selectedColumns: PropTypes.array,
    onSelect: PropTypes.func,
    logsPath: PropTypes.string,
    headerButtons: PropTypes.array,
    buttonLabel: PropTypes.string,
    icon: PropTypes.string,
    editIcon: PropTypes.string,
    toolTipPosition: PropTypes.string,
  };

  static defaultProps = {
    columnFilter: false,
    addButton: false,
    logsButton: false,
    logsOnlyForSlateAdmin: false,
    buttonLabel: "Button.addNew",
    icon: "AddFilledIcon",
    editIcon: "EditIcon",
    toolTipPosition: Position.BOTTOM_RIGHT,
  };
  cancelEdit = () => {
    this.setState({
      isOpen: false,
    });
  };

  onClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onHandleApply = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleRightPanel() {
    this.setState({
      isOpen: true,
    });
  }

  state = {
    isOpen: false,
  };

  render() {
    const {
      editIcon,
      columnFilter,
      addButton,
      editButton,
      addButtonScope,
      addButtonScopeAction,
      editButtonScope,
      editButtonScopeAction,
      logsButton,
      logsOnlyForSlateAdmin,
      addNewField,
      edit,
      logsPath,
      headerButtons,
      buttonLabel,
      editButtonLabel,
      userData,
      icon,
      toolTipPosition,
    } = this.props;
    return (
      <>
        {headerButtons &&
          headerButtons.map((button, i) => (
            <div
              key={i}
              className={classNames("flex", {
                [styles.headerButton]: i < headerButtons.length - 1,
              })}
            >
              {button}
            </div>
          ))}
        {columnFilter && this.state.isOpen && (
          <ColumnSelector
            selectedColumns={this.props.selectedColumns}
            reorderedColumns={this.props.reorderedColumns}
            isOpen={this.state.isOpen}
            columns={this.props.columns}
            onClose={this.onClose}
            cancelEdit={this.cancelEdit}
            onHandleApply={this.onHandleApply}
            onItemSelect={this.onItemSelect}
            onColumnFilterSelect={this.props.onColumnFilterSelect}
          />
        )}
        {addButton && (
          <div className={styles.headerButton}>
            <LocalizedButton
              id="add"
              iconName={icon}
              onClick={addNewField}
              text={buttonLabel}
              scope={addButtonScope}
              scopeAction={addButtonScopeAction}
              userData={userData}
            />
          </div>
        )}
        {editButton && (
          <div className={styles.headerButton}>
            <LocalizedButton
              id="edit"
              iconName={editIcon}
              onClick={edit}
              text={editButtonLabel}
              scope={editButtonScope}
              scopeAction={editButtonScopeAction}
              userData={userData}
            />
          </div>
        )}
        {logsButton && (
          <TooltipButtonWithAuth
            toolTip="Tooltip.logs"
            //className={`flex ${styles.icon} ${styles.button}`}
            url={logsPath}
            iconName="InvoiceIcon"
            scope={SCOPES.LOGS}
            userData={userData}
            onlyForSlateAdmin={logsOnlyForSlateAdmin}
            toolTipPosition={toolTipPosition}
          />
        )}
        {columnFilter && (
          <>
            <div className={tableControlStyles.settingIcon}>
              <SettingsIcon
                onClick={() => {
                  this.handleRightPanel();
                }}
              />
            </div>
            <div className={tableControlStyles.controls}></div>
          </>
        )}
      </>
    );
  }
}
export default HeaderButton;
