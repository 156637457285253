import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import { sum } from "lodash";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  checkScopeAuth,
  createdAtColumn,
  createdByColumn,
  locationColumn,
  getColumnsByViewport,
} from "../../../utils";
import * as constants from "../../../constants";
import ActionIcon from "../../common/ActionIcon";
import PageHeader from "../../common/PageHeader";
import HeaderButton from "../../common/HeaderButtons";
import FilterChips from "../../common/Filters/FilterChips";
import WithFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import TableLoading from "../../common/TableLoading";
import FooterControls from "../../common/FooterControls";
import { ActiveTableCell } from "../../common/ActiveTableCell";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import EditAdvertiser from "./EditAdvertiser";

const DEFAULT_COLUMNS = [
  "Advertiser",
  "Location",
  "Brands Count",
  "Campaigns",
  "Advertiser Type",
  "Onboarding Status",
  "Added On",
  "Added By",
];

const MOBILE_COLUMNS = ["Advertiser", "Brands Count", "Campaigns"];

class Advertisers extends Component {
  state = {
    selectedColumns: getColumnsByViewport(
      this.props.app.pageDimensions,
      MOBILE_COLUMNS,
      DEFAULT_COLUMNS
    ),
    isEdit: false,
  };
  componentDidMount = () => {
    const { match, actions, fetchData } = this.props;
    if (match.params?.action === "new") {
      actions.editAdvertiser();
    }
    fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  renderCampaignValues = (campaignValues, type) => {
    if (!campaignValues) return null;
    return (
      <>
        {campaignValues.map((value) => (
          <div key={value.currency.id}>{`${value.currency.code} ${value[type]}`}</div>
        ))}
      </>
    );
  };

  addAdvertiser = (prop = {}) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editAdvertiser(prop.original);
    history.replace(`/companies/advertisers/new${location.search}`);
  };

  createOrUpdateAdvertiser = (advertiser) => {
    const { actions, history } = this.props;
    const params = queryString.parse(history.location.search);
    if (advertiser.id) actions.createOrUpdateAdvertiser(params, {}, advertiser);
    else {
      actions.editAdvertiser();
      actions.createOrUpdateAdvertiser(params, {}, advertiser);
    }
    history.replace(`/companies/advertisers/${history.location.search}`);
  };

  cancelEdit = () => {
    const { actions, history } = this.props;
    actions.cancelEditAdvertiser();
    const search = queryString.stringify({ ...this.queryString(), editAdvertiserId: undefined });
    history.replace(`/companies/advertisers/${search}`);
  };

  queryString = () => {
    const { history } = this.props;
    return queryString.parse(history.location.search);
  };

  editAdvertiser = (prop) => {
    const { history, actions } = this.props;
    history.push({
      search: queryString.stringify({ ...this.queryString(), editAdvertiserId: prop.original.id }),
    });
    actions.editAdvertiser(prop.original);
    this.setState({ isEdit: true });
  };

  render = () => {
    const {
      history,
      companies: { advertisers, isAdvertisersLoading, addorEditAdvertiser },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    const { isEdit } = this.state;
    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = advertisers.totalCount;
    const columns = checkScopes(
      [
        {
          Header: "Advertiser",
          accessor: "name",
        },
        locationColumn(this.props.onFilterIdSelect),
        {
          id: "countBrands",
          Header: "Brands Count",
          sortable: false,
          accessor: (d) =>
            sum([d.countParentBrands, d.countIndividualBrands, d.countProducts, d.countVariants]),
        },
        {
          id: "countCampaigns",
          Header: "Campaigns",
          accessor: "countCampaigns",
        },
        {
          id: "advertiserTypeName",
          Header: "Advertiser Type",
          accessor: (d) =>
            ActiveTableCell(d.advertiserTypeId, d.advertiserTypeName, () =>
              this.props.onFilterIdSelect(d.advertiserTypeId)
            ),
        },
        {
          id: "companyMappingStatus",
          Header: "Onboarding Status",
          accessor: (d) =>
            ActiveTableCell(d.companyMappingStatusTag, d.companyMappingStatus, () =>
              this.props.onFilterIdSelect(d.companyMappingStatusTag)
            ),
          onlyInAdmin: true,
        },
        { ...createdAtColumn("Added On"), onlyInAdmin: true },
        { ...createdByColumn(this.props.onFilterIdSelect, "Added By"), onlyInAdmin: true },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          width: checkScopeAuth(userData, constants.SCOPES.BUYERS, constants.SCOPE_ACTIONS.WRITE)
            ? 96
            : 64,
          Cell: (props) => (
            <ActionIcon
              iconProps={checkScopes(
                [
                  {
                    toolTip: "Tooltip.view",
                    url: `/companies/advertisers/${props.original.id}/details`,
                    iconName: "ViewIcon",
                  },
                  {
                    id: "edit",
                    scope: constants.SCOPES.BUYERS,
                    scopeAction: constants.SCOPE_ACTIONS.WRITE,
                    toolTip: "Tooltip.update",
                    onClick: () => this.editAdvertiser(props),
                    iconName: "EditIcon",
                  },
                ],
                userData
              )}
            />
          ),
        },
      ],
      userData
    );

    return (
      <div className="col-12 clearfix">
        <div className="flex">
          <PageHeader
            name="PageHeader.advertisers"
            // tagLine="Advertisers"
            count={resultsCount}
            renderRightSideComponent={() => (
              <div className="flex align-center flex-wrap">
                <HeaderButton
                  addButton
                  logsButton
                  logsOnlyForSlateAdmin
                  buttonLabel="Button.addAdvertiser"
                  addButtonScope={constants.SCOPES.BUYERS}
                  addButtonScopeAction={constants.SCOPE_ACTIONS.WRITE}
                  addNewField={this.addAdvertiser}
                  history={history}
                  logsPath={`/logs/source/${AUDIT_LOG_SOURCE.ADVERTISERS}`}
                  userData={userData}
                />
              </div>
            )}
          />
        </div>
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={advertisers}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[
                constants.TAG_TYPE.ADVERTISER,
                constants.TAG_TYPE.ADVERTISER_STATUS,
                constants.TAG_TYPE.ADVERTISER_TYPE,
                constants.TAG_TYPE.LOCATION,
                constants.TAG_TYPE.CREATED_AT,
                constants.TAG_TYPE.UPDATED_AT,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <Table
              data={advertisers.data}
              loading={isAdvertisersLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              onRowClick={({ original }) =>
                history.push(`/companies/advertisers/${original.id}/details`)
              }
            />
            <FooterControls
              pagination
              data={advertisers}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
              addButton
              buttonLabel="Button.addAdvertiser"
              addButtonScope={constants.SCOPES.BUYERS}
              addButtonScopeAction={constants.SCOPE_ACTIONS.WRITE}
              addNewField={this.addAdvertiser}
              userData={userData}
              history={history}
            />
          </div>
        </div>
        {checkScopeAuth(
          userData,
          constants.SCOPES.ADVERTISER_TYPES,
          constants.SCOPE_ACTIONS.WRITE
        ) && (
          <EditAdvertiser
            isOpen={addorEditAdvertiser.isOpen}
            advertiser={addorEditAdvertiser.advertiser}
            cancelEdit={() => this.cancelEdit(isEdit)}
            onUpdate={this.createOrUpdateAdvertiser}
          />
        )}
      </div>
    );
  };
}

export const AdvertisersWithFilters = WithFetchList("getAdvertisers", {
  sort: [{ id: "createdAt", desc: false }],
})(Advertisers);

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (state) => state.app,
  (companies, userData, app) => ({ companies, userData: userData.user, app })
);

export default connect(mapStateToProps, bindDispatch)(AdvertisersWithFilters);
