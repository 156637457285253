const IS_LOADING = "schedules/IS_LOADING";
const IS_PARTIAL_LOADING = "schedules/IS_PARTIAL_LOADING";
const IS_CREATE_SCHEDULES_LOADING = "schedules/IS_CREATE_SCHEDULES_LOADING";
const IS_SCHEDULE_LOADING = "schedules/IS_SCHEDULE_LOADING";
const IS_SCREEN_LOADING = "schedules/IS_SCREEN_LOADING";
const IS_ERROR = "schedules/IS_ERROR";
const SCHEDULES_LIST = "schedules/SCHEDULES_LIST";
const SCHEDULE = "schedules/SCHEDULE";
const SCHEDULE_ERRORS = "schedules/SCHEDULE_ERRORS";
const ERROR_SCREEN_LIST = "schedules/ERROR_SCREEN_LIST";
const SCHEDULE_SCREENS = "schedules/SCHEDULE_SCREENS";
const SCHEDULE_SCREEN_FILTERS = "schedules/SCHEDULE_SCREEN_FILTERS";
const SUGGESTED_SCHEDULE_SCREENS = "schedules/SUGGESTED_SCHEDULE_SCREENS";
const RESET_SCHEDULE = "schedules/RESET_SCHEDULE";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CREATE_SCHEDULES_LOADING,
  IS_SCHEDULE_LOADING,
  IS_ERROR,
  SCHEDULES_LIST,
  SCHEDULE,
  IS_SCREEN_LOADING,
  SCHEDULE_SCREENS,
  SCHEDULE_SCREEN_FILTERS,
  SUGGESTED_SCHEDULE_SCREENS,
  RESET_SCHEDULE,
  SCHEDULE_ERRORS,
  ERROR_SCREEN_LIST,
};
