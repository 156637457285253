const AUDIT_LOG_SOURCE = {
  NOTIFICATION_TEMPLATES: "notification_templates",
  CAMPAIGN_TARGET_TYPES: "campaign_target_types",
  TIMES_OF_DAY: "times_of_day",
  CATEGORIES: "categories",
  REASONS: "reasons",
  CONTENT_TYPES: "content_types",
  PRODUCT_IDENTIFICATION_NUMBER_TYPES: "product_identification_number_types",
  SEGMENT_TYPES: "segment_types",
  COUNTRIES: "countries",
  BRANDS: "brands",
  BRAND_VERIFICATIONS: "brand_verifications",
  SCREENS: "screen",
  THEATRES: "theatre",
  COMPANIES: "companies",
  ADVERTISERS: "buyers",
  MEDIA_AGENCIES: "media_agency",
  EXHIBITORS: "exhibitors",
  CAMPAIGNS: "campaigns",
  CPL: "cpls",
  SCHEDULE_ORDERS: "schedules",
  FAILURES_SCREENS: "failures_screens",
};

const PARENT_SOURCE = {
  LISTS: {
    displayName: "Lists",
    route: "/lists",
    accessor: "lists",
  },
  THEATRES: {
    displayName: "Theatres",
    route: "/theatres",
    accessor: "theatres",
  },
  SCREENS: {
    displayName: "Screens",
    route: "/screens",
    accessor: "screens",
  },
  COMPANIES: {
    displayName: "Companies",
    route: "/companies",
    accessor: "companies",
  },
};
export { AUDIT_LOG_SOURCE, PARENT_SOURCE };
