import React, { Component } from "react";
import ClassNames from "classnames";
import { isEmpty, isNumber } from "lodash";
import { formatValidityPeriod } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";

const defaultCampaignData = [
  {
    id: "name",
    label: "Campaign Name",
    value: "Enter campaign name",
  },
  {
    id: "campaignTargetTypeName",
    label: "Campaign Type",
    value: "Select campaign type",
  },
  {
    //!
    id: "advertiserName",
    label: "Advertiser",
    value: "Select advertiser",
  },
  {
    //!
    id: "countries",
    label: "Country",
    value: "Select country",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select campaign validity",
  },
  // {
  //   id: "maxPlaysPerScreen",
  //   label: "Max Play Per Screen",
  //   value: "-",
  // },
  // {
  //   id: "budgetPlays",
  //   label: "Budgeted Plays",
  //   value: "-",
  // },
  {
    id: "totalEstimatedPlays",
    label: "Estd. Plays",
    value: "-",
  },
  {
    id: "actualPlays",
    label: "Actual Plays",
    value: "-",
  },
  {
    id: "contentStatus",
    label: "Content",
    value: "Add Content",
  },
];

export default class CampaignCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  static defaultProps = {
    isExpanded: true,
    showCollapse: false,
  };

  handleCardToggle = (isExpanded) => {
    if (this.props.onCardToggle) {
      this.props.onCardToggle(isExpanded);
    }

    this.setState({
      isExpanded: isExpanded,
    });
  };

  formatCampaignValues = (defaultValue, campaignData) => {
    const value = campaignData && campaignData[defaultValue.id];

    if (defaultValue.id === "contentStatus" && value === false) {
      return <span style={{ color: "red" }}>Unavailable</span>;
    }

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value, "DD MMM YYYY");
    }

    if (defaultValue.id === "countries") {
      return value.map((c) => c.name).join(", ");
    }

    return value;
  };

  render() {
    const { campaignData, children } = this.props;

    const updatedCampaignData = [...defaultCampaignData];

    return (
      <div className={ClassNames(styles.cardContainer, styles.campaignContainer)}>
        <div className={styles.cardItemWrapper}>
          {updatedCampaignData.map((itemDefaultData, index) => {
            if (
              itemDefaultData.id === "contentStatus" &&
              campaignData[itemDefaultData.id] === true
            ) {
              return null;
            }
            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>{itemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>
                  {this.formatCampaignValues(itemDefaultData, campaignData)}
                </div>
              </div>
            );
          })}
        </div>
        {this.state.isExpanded && (
          <div className={`${styles.child} ${styles.campaign}`}>{children}</div>
        )}
      </div>
    );
  }
}
