import React, { Component } from "react";
import PropTypes from "prop-types";
import RightPanel from "../../../common/RightPanel";
import FileUpload from "../../../common/FileUpload";
import Form from "../../../common/Form";
import { fetchAdvertiserTypes, searchContentTypes } from "../../../../api";
import * as constants from "../../../../constants";

export default class UploadCPLPanel extends Component {
  state = { hasError: false, isUploadError: false };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string,
  };

  static defaultProps = {
    composition: {},
  };

  componentDidMount() {
    const {
      isEdit,
      composition: { composition, isCompositionDetailsLoading },
    } = this.props;
    this.setState({ composition, isCompositionDetailsLoading, isEdit });
  }

  componentDidUpdate(prevProps) {
    const {
      isEdit,
      composition: { composition, isCompositionDetailsLoading },
    } = this.props;

    if (isEdit !== prevProps.isEdit) {
      this.setState({ isEdit });
    }

    if (
      composition?.id !== prevProps.composition?.composition?.id ||
      isCompositionDetailsLoading !== prevProps.composition?.isCompositionDetailsLoading
    )
      this.setState({ composition, isCompositionDetailsLoading });
  }

  handleFormCancel = () => {
    this.props.onClose();
  };

  save = () => {
    const { actions } = this.props;
    const { composition, contentTypeId, advertiserTypeId } = this.state;
    const selectedContentTypeId = contentTypeId ?? composition?.contentTypeId;
    const selectedAdvertiserTypeId = advertiserTypeId ?? composition?.advertiserTypeId;
    actions.saveComposition(
      {
        id: composition.id,
        advertiserTypeId: selectedAdvertiserTypeId,
        contentTypeId: selectedContentTypeId,
      },
      this.props.onSave
    );
  };

  getCplUploadStatus = (cplId = null, isEdit, isError = false) => {
    this.setState({
      isEdit,
      isUploadError: isError,
    });
  };

  fetchCPLData = (xml) => {
    const { actions } = this.props;
    actions.uploadCPLFile(xml.content, this.getCplUploadStatus);
  };

  onInputChange = (value, accessor) => {
    this.setState({
      [accessor]: value,
    });
  };

  render = () => {
    const { isOpen, isUploading } = this.props;
    const {
      composition,
      isCompositionDetailsLoading,
      contentTypeId,
      advertiserTypeId,
      isEdit,
    } = this.state;
    const { hasError, isUploadError } = this.state;
    const selectedContentTypeId = contentTypeId ?? composition?.contentTypeId;
    const selectedAdvertiserTypeId = advertiserTypeId ?? composition?.advertiserTypeId;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.handleFormCancel()}
        header={isEdit ? "RightPanelHeader.updateCPL" : "RightPanelHeader.uploadCPL"}
        showFooter={true}
        primaryButtonProps={[
          {
            text: isEdit ? "Button.updateCPL" : "Button.uploadCPL",
            onClick: this.save,
          },
        ]}
        secondaryButtonProps={{
          text: "Button.cancel",
          onClick: this.handleFormCancel,
        }}
      >
        <FileUpload
          isUploadError={isUploadError}
          name={composition?.name ?? ""}
          isLoading={isUploading}
          title="Button.selectCPL"
          description="(or) Drag and Drop CPL"
          errorMessage="Please upload an XML file"
          icon="DownloadIcon"
          onSelect={(xml) => this.fetchCPLData(xml)}
          acceptableFormats=".xml"
          mimeType="text/xml"
        />
        {composition?.id && !isCompositionDetailsLoading && (
          <Form
            config={[
              {
                type: constants.FORM_FIELD_TYPE.API_SELECT,
                id: "contentTypeId",
                title: "Content Type",
                placeholder: "Select Content Type",
                className: "col-12",
                fetchAction: () => searchContentTypes(-1, 0, "name"),
                parseResults: (response) =>
                  response.data.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                selected: (list) => list.filter((item) => item.id === selectedContentTypeId),
                singleSelect: true,
                showLabelInButton: false,
                onSelect: ([data]) => this.onInputChange(data.id, "contentTypeId"),
                error: hasError && !selectedContentTypeId,
                errorMessage: "Select Content Type",
              },
              {
                id: "advertiserTypeId",
                type: constants.FORM_FIELD_TYPE.API_SELECT,
                label: "Advertiser Type",
                className: "col-12",
                placeholder: "Select Advertiser Type",
                fetchAction: () => fetchAdvertiserTypes("rank"),
                parseResults: (response) => {
                  return [
                    ...response.map((item) => ({
                      id: item.id,
                      value: item.name,
                    })),
                  ];
                },
                selected: (list) => list.filter((item) => item.id === selectedAdvertiserTypeId),
                singleSelect: true,
                showLabelInButton: false,
                onSelect: ([data]) => this.onInputChange(data.id, "advertiserTypeId"),
                error: hasError && !selectedAdvertiserTypeId,
                errorMessage: "Select Advertiser Type",
              },
            ]}
          ></Form>
        )}
      </RightPanel>
    );
  };
}
