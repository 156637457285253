import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { Table } from "workbench";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import { bindDispatch, modifyTableColumns, lastUpdatedAtColumn } from "../../../../utils";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import ActionIcon from "../../../common/ActionIcon";
import PageHeader from "../../../common/PageHeader";
import WithFetchList from "../../../hoc/withFetchList";
import FilterChips from "../../../common/Filters/FilterChips";
import styles from "../../../../styles/Lists/Lists.module.scss";
import countryStyles from "../../../../styles/Lists/Country.module.scss";
import HeaderButton from "../../../common/HeaderButtons";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";

const links = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.countriesAndTaxes" },
];

class CountriesAndTaxes extends Component {
  state = {
    selectedColumns: [
      "Country",
      "Iso Code 3166-2",
      "Iso Code 3166-3",
      "Currency",
      "Taxes",
      "Last Synced On",
    ],
    sync: false,
  };

  componentDidMount = () => {
    this.props.fetchData();
    this.props.actions.getActiveCountries();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  startSync = () => {
    const { actions } = this.props;
    this.setState({ sync: true });
    actions.syncCountries(this.updateSync);
  };
  updateSync = () => {
    this.setState({ sync: false });
    this.props.fetchData();
  };
  render = () => {
    const {
      countries: { list, isLoading, isPartialLoading, activeCountriesCount },
      history,
      filters,
      userData,
      ps,
      page,
      sort,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const data = list.data || [];
    const { selectedColumns, reorderedColumns, sync } = this.state;
    const query = queryString.parse(history.location.search);

    const columns = [
      {
        id: "name",
        Header: "Country",
        accessor: "name",
      },
      {
        id: "iso3166_2",
        Header: "Iso Code 3166-2",
        accessor: "iso3166_2",
        sortable: false,
      },
      {
        id: "iso3166_3",
        Header: "Iso Code 3166-3",
        accessor: "iso3166_3",
        sortable: false,
      },
      {
        id: "currency",
        Header: "Currency",
        accessor: "currency.name",
        sortable: false,
      },
      {
        id: "taxes",
        Header: "Taxes",
        accessor: (d) => (d.hasTaxes ? "Yes" : "No"),
        sortable: false,
      },
      lastUpdatedAtColumn("Last Synced On"),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                url: `/lists/countries/${props.original.id}/taxes`,
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          name="PageHeader.countriesAndTaxes"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={list.totalCount}
          activeCount={activeCountriesCount}
          activeCountLabel="PageHeader.active"
          buttons={[
            {
              text: "Button.sync",
              className: classNames(Classes.ACTIVE, styles.syncButton, {
                [countryStyles.refresh]: sync,
              }),
              isIconRight: true,
              iconName: "RefreshIcon",
              checkScope: true,
              onClick: this.startSync,
              scope: constants.SCOPES.COUNTRIES,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
          ]}
          renderRightSideComponent={() => (
            <HeaderButton
              userData={userData}
              logsButton
              history={history}
              logsPath={`/logs/source/countries`}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              query={query}
              ps={ps}
              page={page}
              data={list}
              columns={columns}
              tagTypes={[constants.TAG_TYPE.COUNTRY, constants.TAG_TYPE.UPDATED_AT]}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={list.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading}
            />
            <Table
              data={data}
              defaultSortDesc={true}
              pageSize={list.data ? list.data.length : 3}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              manual
              loading={isPartialLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              onRowClick={({ original }) => history.push(`/lists/countries/${original.id}/taxes`)}
            />
            <FooterControls
              pagination
              query={query}
              ps={ps}
              page={page}
              data={list}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const CountriesPage = WithFetchList("getCountries", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(CountriesAndTaxes);

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.countries,
  (userData, countries) => ({ userData: userData.user, countries })
);

export default connect(mapStateToProps, bindDispatch)(CountriesPage);
