import React, { Component } from "react";
import classNames from "classnames";
import { isEmpty, groupBy } from "lodash";
import { Icons } from "prefab";

import InfoBlock from "../../../common/InfoBlock";
import { SCOPE_ACTIONS, SCOPES, THEATRE_CRITERIA_TYPES } from "../../../../constants";
import styles from "../../../../styles/CampaignCreate/CriteriaList.module.scss";
import { renderCriteriaName } from "../utils";

const {
  EditIcon,
  AddIcon,
  RemoveIcon,
  AddRoundedIcon,
  CancelRoundedIcon,
  RemoveFilledIcon,
} = Icons;

export default class CriteriaList extends Component {
  state = {
    isEdit: false,
    criteriaList: {},
  };

  static defaultProps = {
    withEdit: true,
    countries: [],
  };
  componentDidMount() {
    this.setState({
      criteriaList: this.props.criteria,
      //Remove criteria button is shown on edit as well as create action
      isEdit: this.props.targetGroupAction === "edit" || this.props.targetGroupAction === "create",
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.criteria !== this.props.criteria) {
      this.setState({
        criteriaList: this.props.criteria,
        isEdit:
          this.props.targetGroupAction === "edit" || this.props.targetGroupAction === "create",
      });
    }
  };

  handleEditCriteria = () => {
    this.setState({
      isEdit: true,
    });
  };

  handleCancelChanges = () => {
    this.setState({
      isEdit: false,
      criteriaList: this.props.criteria,
    });
  };

  handleSaveChanges = () => {
    const { criteriaList } = this.state;
    if (this.props.onRemoveCriteriaItem) {
      this.props.onRemoveCriteriaItem(criteriaList);
    }
    this.setState({
      isEdit: false,
    });
  };

  handleRemoveCriteriaItem = (id, listType) => {
    const { criteriaList } = this.state;

    const updatedList = criteriaList[listType].filter((item) => item?.tags[0] !== id);
    if (this.props.onRemoveCriteriaItem) {
      this.props.onRemoveCriteriaItem(updatedList, listType);
    }
    this.setState({
      criteriaList: {
        ...criteriaList,
        [listType]: updatedList,
      },
    });
  };

  getRightSideButtons = () => {
    if (isEmpty(this.state.criteriaList)) return [];

    if (this.state.isEdit) {
      return [
        {
          id: "cancel",
          scope: SCOPES.CAMPAIGNS,
          scopeAction: SCOPE_ACTIONS.WRITE,
          text: "Button.cancel",
          onClick: this.handleCancelChanges,
          className: styles.cancelButton,
        },
        {
          id: "save",
          scope: SCOPES.CAMPAIGNS,
          scopeAction: SCOPE_ACTIONS.WRITE,
          text: "Button.done",
          onClick: this.handleSaveChanges,
          icon: <AddIcon />,
          className: styles.saveButton,
        },
      ];
    }

    return [
      {
        id: "edit",
        scope: SCOPES.CAMPAIGNS,
        scopeAction: SCOPE_ACTIONS.WRITE,
        text: "Button.edit",
        onClick: this.handleEditCriteria,
        icon: <EditIcon />,
        className: styles.editButton,
      },
    ];
  };

  validateCriteriaData = () => {
    const { criteria, targetGroupType } = this.props;

    return (
      isEmpty(criteria?.inclusions?.filter((data) => data.targetGroupType === targetGroupType)) &&
      isEmpty(criteria?.exclusions?.filter((data) => data.targetGroupType === targetGroupType))
    );
  };

  renderItemList = (criteria, icon, criteriaListType, index) => {
    const { isEdit } = this.state;
    const { targetGroup } = this.props;
    const iconClassName = classNames(styles.icon, { [styles.editIcon]: isEdit });

    return (
      <div className={styles.itemContent} key={`${criteria.type}-${index}` || criteria.id}>
        <div> {isEdit ? null : icon}</div>
        <div className={styles.displayName}>{renderCriteriaName(criteria)}</div>
        <div
          className={targetGroup?.status === "Active" ? styles.disabledIcon : iconClassName}
          onClick={
            isEdit && targetGroup.status !== "Active"
              ? () => this.handleRemoveCriteriaItem(criteria?.tags[0], criteriaListType)
              : null
          }
        >
          {isEdit ? <RemoveFilledIcon /> : null}
        </div>
      </div>
    );
  };

  renderListOfCriteriaItems = (filteredList, groupedData, icon, listType) => {
    const { targetGroupType } = this.props;

    // Helper function to get the sorting key based on criteria type
    const getSortingKey = (item) => {
      if (item.type === THEATRE_CRITERIA_TYPES.SCREEN_BY_ID) {
        // Check if tagsInfos array is present and not empty
        if (item.tagsInfos && item.tagsInfos.length > 0) {
          const theatreName = item.tagsInfos[0]?.context?.theatre?.name;

          if (theatreName) {
            return theatreName;
          }
        }

        // Fallback to item name if theatreName is not available
        return item.name || "";
      } else if (item.type === THEATRE_CRITERIA_TYPES.THEATRE_BY_ID) {
        // Ensure tagsInfos array is present and not empty
        if (item.tagsInfos && item.tagsInfos.length > 0) {
          // Access name property from the first element of tagsInfos
          return item.tagsInfos[0]?.name || "";
        }
        // Fallback to item name if tagsInfos is not available
        return item.name || "";
      }
      return "";
    };

    if (targetGroupType === "location") {
      if (filteredList.length > 0) {
        // Sort the filtered list based on the sorting key
        filteredList.sort((a, b) => (getSortingKey(a) > getSortingKey(b) ? 1 : -1));

        return (
          <div className={styles.scrollableContainer}>
            {filteredList.map((data, index) => this.renderItemList(data, icon, listType, index))}
          </div>
        );
      }
      return <span>Nil</span>;
    }

    if (isEmpty(groupedData)) return <span>Nil</span>;

    return (
      <div className={styles.scrollableContainer}>
        {Object.keys(groupedData).map((criteria, index) => (
          <div key={index}>
            <div className={styles.itemHeader}>
              {this.itemHeaderDisplay(groupedData[criteria][0])} ({groupedData[criteria].length})
            </div>
            <div className={styles.itemWrapper}>
              {/* Sort items within each criteria group by the sorting key */}
              {groupedData[criteria]
                .sort((a, b) => (getSortingKey(a) > getSortingKey(b) ? 1 : -1))
                .map((item, index) => this.renderItemList(item, icon, listType, index))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Function to determine the item header display for the criteria list
  itemHeaderDisplay(data) {
    if (data.typeDisplayName && data.typeDisplayName !== "") {
      if (data.typeDisplayName.includes("Theatre By ID")) {
        return "Theatre";
      } else if (data.typeDisplayName.includes("Screen By ID")) {
        return "Screen";
      } else {
        return data.typeDisplayName;
      }
    } else if (data.type === THEATRE_CRITERIA_TYPES.THEATRE_BY_ID) {
      return "Theatre";
    } else if (data.type === THEATRE_CRITERIA_TYPES.SCREEN_BY_ID) {
      return "Screen";
    }
  }

  renderInclusionList = () => {
    const { criteriaList } = this.state;
    const { targetGroupType } = this.props;
    if (isEmpty(criteriaList?.inclusions)) return null;

    const filteredList = criteriaList.inclusions.filter(
      (data) => data.targetGroupType === targetGroupType
    );
    const groupedInclusions = groupBy(filteredList, "type");

    return (
      <>
        <div className={styles.header}>
          <div className={styles.icon}>
            <AddIcon />
          </div>
          <div className={styles.title}>Inclusions</div>
        </div>
        <div className={styles.items}>
          {this.renderListOfCriteriaItems(
            filteredList,
            groupedInclusions,
            <AddRoundedIcon />,
            "inclusions"
          )}
        </div>
      </>
    );
  };

  renderExclusionList = () => {
    const { criteriaList } = this.state;
    const { targetGroupType } = this.props;

    if (isEmpty(criteriaList?.exclusions)) return null;

    const filteredList = criteriaList.exclusions.filter(
      (data) => data.targetGroupType === targetGroupType
    );
    const groupedExclusions = groupBy(filteredList, "type");

    return (
      <>
        <div className={styles.header}>
          <div className={classNames(styles.icon, styles.exclusion)}>
            <RemoveIcon />
          </div>
          <div className={styles.title}>Exclusions</div>
        </div>
        <div className={styles.items}>
          {this.renderListOfCriteriaItems(
            filteredList,
            groupedExclusions,
            <CancelRoundedIcon />,
            "exclusions"
          )}
        </div>
      </>
    );
  };

  render() {
    const { countries, criteria, headerName, headerIcon, withEdit, targetGroupType } = this.props;

    if (this.validateCriteriaData() && countries.length === 0) return null;

    return (
      <div className={styles.container}>
        <InfoBlock
          header={headerName}
          headerIcon={headerIcon}
          rightSideButtons={criteria && withEdit ? this.getRightSideButtons() : []}
          rightSideContainerClass={styles.actions}
        >
          {!isEmpty(countries) && targetGroupType === "location" && (
            <div className={styles.countries}>
              <div className={styles.title}>Country :</div>
              <div className={styles.list}>
                {countries.map((country) => country.name).join(", ")}
              </div>
            </div>
          )}
          <div className={styles.criteriaWrapper}>
            <div className={styles.criteriaItem}>{this.renderInclusionList()}</div>
            <div className={styles.criteriaItem}>{this.renderExclusionList()}</div>
          </div>
        </InfoBlock>
      </div>
    );
  }
}
