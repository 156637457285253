import React from "react";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/InfoBlock.module.scss";

const DetailsLoading = ({ type = "primary" }) => {
  return (
    <div className={`col-12 clearfix ${styles.block} ${styles[type]}`} style={{ padding: "1rem" }}>
      <div className="flex col-12 flex-wrap clearfix">
        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsLoading;
