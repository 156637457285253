import combineMessages from "../localization/combineMessages";
import { DEFAULT_LANGUAGE } from "../constants";

const createIntlKeyPropType = (isRequired) => {
  return (props, propName, componentName) => {
    const prop = props[propName];
    if (prop == null) {
      if (isRequired) {
        throw new Error(
          `Required prop \`${propName}\` is missing in the component \`${componentName}\`.`
        );
      }
      // Prop is optional. Do nothing.
    } else {
      const messages = combineMessages(DEFAULT_LANGUAGE);
      if (!messages[prop])
        throw new Error(
          `Invalid intlKey \`${prop}\` is passed to the prop \`${propName}\` of the component \`${componentName}\`.`
        );
    }
  };
};

const intlKeyPropType = createIntlKeyPropType(false);
intlKeyPropType.isRequired = createIntlKeyPropType(true);

export default intlKeyPropType;
