import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import classNames from "classnames";
import { Position } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import styles from "../../styles/MultiSelect.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";

class MultiSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      query: "",
    };
  }
  static propTypes = {
    className: PropTypes.string,
    multiSelectorClassName: PropTypes.string,
    items: PropTypes.array.isRequired,
    itemPredicate: PropTypes.func,
    onQueryChange: PropTypes.func,
    noResults: PropTypes.object,
    onItemSelect: PropTypes.func.isRequired,
    popoverProps: PropTypes.object,
    tagRenderer: PropTypes.func,
    tagInputProps: PropTypes.object,
    selectedItems: PropTypes.array,
  };

  static defaultProps = {
    label: false,
    placeholder: "Search",
  };
  //debounce the search
  debouncedSearch = debounce((data) => this.props.onQueryChange(data), 600);

  onQueryChange = (data, event) => {
    if (event && data.length && data !== this.state.query) {
      this.setState({ loading: false, query: data });
      return this.debouncedSearch(data);
    } else {
      this.setState({ loading: true, query: "" });
      return null;
    }
  };

  render() {
    const {
      label,
      className,
      items,
      itemPredicate,
      itemRenderer,
      onQueryChange,
      noResults,
      onItemSelect,
      popoverProps,
      tagRenderer,
      tagInputProps,
      selectedItems,
      placeholder,
      ...rest
    } = this.props;
    const { loading, query } = this.state;
    return (
      <div className={`${styles.multiSelect} ${className}`}>
        {label && <div className={`col-12 semi h5 ${styles.label}`}>{label}</div>}
        <MultiSelect
          className={classNames(
            styles.inputValue,
            customMenuStyles.menu,
            rest.multiSelectorClassName,
            {
              [customMenuStyles.error]: rest.error,
            }
          )}
          openOnKeyDown={items.length && selectedItems.length === 0 ? true : false}
          items={items}
          itemPredicate={itemPredicate}
          itemRenderer={itemRenderer}
          noResults={
            !loading && query?.length > 0 ? (
              <MenuItem disabled={true} text="No Results Found" />
            ) : undefined
          }
          onItemSelect={onItemSelect}
          popoverProps={{
            minimal: true,
            usePortal: false,
            disabled: tagInputProps.disabled,
            position: Position.BOTTOM,
          }}
          onQueryChange={this.onQueryChange}
          tagRenderer={tagRenderer}
          tagInputProps={tagInputProps}
          selectedItems={selectedItems}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    );
  }
}

export default MultiSelector;
