import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../../constants";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";

export default class RequestChangesCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: "",
      hasError: false,
    };
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onRequestChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  cancelRequestChanges = () => {
    this.setState({ hasError: false, notes: "" });
    this.props.onClose();
  };

  requestChange = () => {
    const { notes } = this.state;
    if (notes !== "") {
      this.props.onRequestChange(notes);
      this.cancelRequestChanges();
    } else {
      return this.setState({ hasError: true });
    }
  };

  inputOnChange = (value) => {
    this.setState({
      notes: value,
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { notes, hasError } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelRequestChanges()}
        header="RightPanelHeader.requestChanges"
        showFooter={true}
        primaryButtonProps={{
          text: "Button.requestChanges",
          onClick: this.requestChange,
        }}
        secondaryButtonProps={{ text: "Button.close", onClick: this.cancelRequestChanges }}
      >
        <Form
          config={[
            {
              id: "comments",
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Comments",
              placeholder: "Enter Comments",
              value: notes,
              onChange: (e) => this.inputOnChange(e.target.value),
              error: hasError,
              errorMessage: "You must enter some comment",
            },
          ]}
        />
      </RightPanel>
    );
  };
}
