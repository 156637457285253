import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../../utils";
import Summary from "../../common/Summary";

export const MediaSummary = ({
  mediaId,
  actions: { getMediaSummary },
  media: { mediaSummary, isTabLoading },
}) => {
  useEffect(() => {
    getMediaSummary(mediaId);
  }, [mediaId, getMediaSummary]);

  return <Summary isLoading={isTabLoading} summary={mediaSummary} />;
};

const mapStateToProps = createSelector(
  (state) => state.media,
  (state) => state.userData,
  (media, userData) => ({ media, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(MediaSummary);
