/*
this appears to be a higher order component (HOC) called withFetchList that wraps a React component to handle common data fetching logic.

1. It takes in a fetchAction function and some configuration like sort, page etc.

2. It returns a new component that wraps the original component passed to it.

3. In this wrapper component, it manages state like filters, page, sort etc.

4. It provides handlers like onFilterChange, onSortedChange etc that call fetchData to retrieve updated data based on state changes.

5. fetchData calls the fetchAction passed in, updates query params in URL, and updates component state.

6. It passes down props like filters, sort etc and handlers to the wrapped component.

This component handles fetching data from an API based on queries, filtering, sorting etc, avoiding duplication of all this logic across multiple components.
The wrapped components simply need to use the props it provides.
*/

import React, { Component } from "react";
import ReactGA from "react-ga";
import queryString from "query-string";
import * as constants from "../../constants";
import { getDisplayName, parseSort, getParams } from "../../utils";
import { getTagByID } from "../../api";

function withFetchList(
  fetchAction,
  { multiColumnSort = false, sort = [], ps = constants.DEFAULT_PAGE_SIZE, page = 1 },
  key
) {
  return (WrappedComponent) => {
    class WithFetchList extends Component {
      constructor(props) {
        super(props);
        const { location } = props.history;
        const params = queryString.parse(location.search) || {};
        this.state = {
          ps: parseInt(params.ps) || ps,
          page: parseInt(params.page) || page,
          sort: parseSort(params.sort) || sort,
          filters: [],
          isFilterLoading: false,
          key: key,
        };
      }

      //method to fetch data w.r.t new filter
      onFilterChange = (filters) => {
        const newState = { ...this.state, page: 1, filters };
        this.fetchData(newState);
      };

      //method to set the new filter
      onFilterSelect = (tag) => {
        const { filters } = this.state;
        let newFilters;
        if (filters.map((f) => f.id).includes(tag.id))
          newFilters = filters.filter((f) => f.id !== tag.id);
        else newFilters = [tag, ...filters];
        this.onFilterChange(newFilters);
      };

      //method to get the tag information for which the filter will be set
      onFilterIdSelect = async (tagId) => {
        this.setState({ isFilterLoading: true });
        try {
          let tag;
          const { filters } = this.state;
          tag = filters.find((f) => f.id === tagId);
          if (!tag) {
            ({ data: tag } = await getTagByID(tagId));
          }
          //SLATE-1346 Sep19-2023 Anushree:- isFilterLoading false should be set after completion of getting new filter and applying it.
          // this.setState({ isFilterLoading: false });
          // this.onFilterSelect(tag);
          this.onFilterSelect(tag);
          this.setState({ isFilterLoading: false });
          if (tag?.type)
            ReactGA.event({ category: "filter", action: "table-cell", label: tag.type });
        } catch {
          this.setState({ isFilterLoading: false });
        }
      };

      // handles pagesize & page changes
      onQueryChange = (key, value) => {
        const newState = { ...this.state };
        if (key === "ps") newState.page = 1;
        newState[key] = value;
        this.fetchData(newState);
      };

      // handles sort change
      onSortedChange = (data) => {
        const [column] = data;
        if (column.id) {
          const newState = { ...this.state };
          const cIndex = newState.sort.findIndex((item) => item.id === column.id);
          if (cIndex !== -1) {
            newState.sort[cIndex].desc = column.desc;
          } else {
            newState.sort = multiColumnSort ? [...newState.sort, column] : [column];
          }
          this.fetchData(newState);
          ReactGA.event({
            category: "sort",
            action: column.id,
            label: column.desc ? "descending" : "ascending",
          });
        }
      };

      // Calls fetchAction with relevant props and updates query params
      fetchData = async (state, isPartial = true, extraParams = [], initialFilterId = null) => {
        const newState = state ? state : this.state;
        const { filters } = newState;
        const params = getParams(newState);
        const { history, actions } = this.props;
        const { key } = this.state;

        if (initialFilterId) {
          this.setState({ isFilterLoading: true });
          try {
            const { data: tag } = await getTagByID(initialFilterId);
            newState.filters = [tag];
            this.setState({ isFilterLoading: false });
          } catch {
            this.setState({ isFilterLoading: false });
          }
        }
        if (extraParams.length > 0) {
          Object.assign(params, ...extraParams);
          Object.assign(newState, ...extraParams);
        }

        history.replace({ search: `ps=${params.ps}&page=${params.page}&sort=${params.sort}` });
        const sortParams = params.sort.split(",");
        const modifiedSortParams = sortParams.map((param) => {
          const order = param.split(":");
          return param.replace(/;/g, `:${order[1]},`);
        });
        let uniqueId;
        let modifiedParams;
        let condition = this.props.match.params.id || this.props.match.params.campaignId;
        if (key) {
          condition = this.props[key];
        }
        if (condition) {
          uniqueId = condition;
          modifiedParams = { ...params, sort: modifiedSortParams.join(","), id: uniqueId };
        } else {
          modifiedParams = { ...params, sort: modifiedSortParams.join(",") };
        }
        actions[fetchAction](modifiedParams, filters, undefined, isPartial);
        this.setState(newState);
      };

      render() {
        const {
          onSortedChange,
          onQueryChange,
          fetchData,
          onFilterChange,
          onFilterSelect,
          onFilterIdSelect,
        } = this;
        const { sort, ps, page, filters, isFilterLoading } = this.state;
        return (
          <WrappedComponent
            onFilterChange={onFilterChange}
            onFilterSelect={onFilterSelect}
            onFilterIdSelect={onFilterIdSelect}
            isFilterLoading={isFilterLoading}
            filters={filters}
            onSortedChange={onSortedChange}
            onQueryChange={onQueryChange}
            sort={sort}
            ps={parseInt(ps)}
            page={parseInt(page)}
            fetchData={fetchData}
            {...this.props}
          />
        );
      }
    }
    WithFetchList.displayName = `WithFetchList(${getDisplayName(WrappedComponent)})`;
    return WithFetchList;
  };
}

export default withFetchList;
