import * as constants from "../constants";
import {
  getParentBrandById,
  getIndividualBrandById,
  getProductById,
  getVariantById,
  getTagByID,
} from ".";

const getBrandById = async (brandId, type) => {
  let response, brandType;
  if (type) {
    brandType = type;
  } else {
    try {
      const { data: tag } = await getTagByID(brandId);
      brandType = tag.type;
    } catch {
      return null;
    }
  }
  switch (brandType) {
    case constants.BRANDS[0]:
      response = await getParentBrandById(brandId);
      break;
    case constants.BRANDS[1]:
      response = await getIndividualBrandById(brandId);
      break;
    case constants.BRANDS[2]:
      response = await getProductById(brandId);
      break;
    case constants.BRANDS[3]:
      response = await getVariantById(brandId);
      break;
    default:
      response = null;
  }
  return response ? response.data : null;
};

export default getBrandById;
