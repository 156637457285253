import React from "react";
import { Position, Toaster, Intent } from "@blueprintjs/core";
import { FormattedMessage, IntlProvider } from "react-intl";
import combineMessages from "../localization/combineMessages";
import { DEFAULT_TOAST_TIMEOUT } from "../constants";

const DefaultToast = Toaster.create({
  position: Position.TOP_RIGHT,
  className: "toast",
});

const Message = (message, values = null) => (
  <IntlProvider
    locale={window.CURRENT_LANGUAGE}
    messages={combineMessages(window.CURRENT_LANGUAGE)}
  >
    <FormattedMessage id={message} defaultMessage={message} values={values} />
  </IntlProvider>
);

const showToast = (message, isSuccess = true, timeout = DEFAULT_TOAST_TIMEOUT, values) => {
  DefaultToast.show({
    intent: isSuccess ? Intent.SUCCESS : Intent.DANGER,
    message: Message(message, values),
    timeout,
  });
};

export default showToast;
