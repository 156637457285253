import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Tooltip, Position } from "@blueprintjs/core";
import { BaseButton } from "prefab";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { BUTTON_TYPE } from "prefab";
import styles from "../../styles/ToolTip.module.scss";

class TooltipButton extends Component {
  static propTypes = {
    toolTip: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    toolTipPosition: PropTypes.string,
    toolTipClass: PropTypes.string,
  };
  static defaultProps = {
    toolTipPosition: Position.TOP,
    toolTipClass: "",
  };
  renderButton = () => {
    const { toolTip, toolTipPosition, toolTipClass, url, text, ...rest } = this.props;
    if (url) {
      return (
        <Link to={url} draggable={false}>
          <BaseButton buttonText={text} buttonType={BUTTON_TYPE.SECONDARY} {...rest} />
        </Link>
      );
    }
    return <BaseButton buttonText={text} buttonType={BUTTON_TYPE.SECONDARY} {...rest} />;
  };
  render() {
    const { toolTip, toolTipPosition, toolTipClass } = this.props;
    return (
      <Tooltip
        className={classNames(styles.toolTip, toolTipClass)}
        boundary={true}
        position={toolTipPosition}
        content={toolTip && <FormattedMessage id={toolTip} defaultMessage={toolTip} />}
        usePortal={false}
      >
        {this.renderButton()}
      </Tooltip>
    );
  }
}

export default TooltipButton;
