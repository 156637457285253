import { createElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "../../styles/Divider.module.scss";

const Divider = ({ className, tagName, ...otherProps }) => {
  return createElement(tagName, {
    ...otherProps,
    className: classNames(styles.divider, className),
  });
};
export default Divider;

Divider.propTypes = {
  tagName: PropTypes.string,
};

Divider.defaultProps = {
  tagName: "div",
};
