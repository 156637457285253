const getInitialsFromName = (text) => {
  if (!text) return "";
  const temp = text.trim().split(" ");

  if (temp.length > 1)
    return (temp[0].substr(0, 1) + temp[temp.length - 1].substr(0, 1)).toUpperCase();
  return temp[0].substr(0, 1).toUpperCase();
};

export default getInitialsFromName;
