import React, { Component } from "react";
import classNames from "classnames";
import { Suggest as BlueprintSuggest } from "@blueprintjs/select";
import { Intent } from "@blueprintjs/core";
import textInputStyles from "../../styles/TextInput.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";

/**
 *  Direct implementation of Blueprint Select Suggest with custom styles.
 *  Refer https://blueprintjs.com/docs/#select/suggest for props
 *  Overriding inputProps and popoverProps for custom styles.
 */
class BPSuggest extends Component {
  static defaultProps = {
    inputProps: {
      className: "",
    },
  };
  render() {
    const { inputProps, popoverProps, error, ...rest } = this.props;
    return (
      <BlueprintSuggest
        {...rest}
        className={customMenuStyles.menu}
        inputProps={{
          ...inputProps,
          className: classNames(textInputStyles.inputField, inputProps.className, {
            [textInputStyles.withRightIcon]: inputProps.rightElement,
          }),
          intent: error ? Intent.DANGER : Intent.NONE,
        }}
        popoverProps={{
          popoverClassName: customMenuStyles.popOver,
          usePortal: false,
          minimal: true,
          ...popoverProps,
        }}
        usePortal={false}
      />
    );
  }
}

export default BPSuggest;
