import React, { Component } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import styles from "../../../../styles/Catalogue/SimilarBrands.module.scss";
import DuplicateBrandsList from "./DuplicateBrandsList";

export class DuplicateBrands extends Component {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    duplicateBrands: PropTypes.array.isRequired,
  };
  componentDidMount = () => {
    this.el.scrollIntoView({ behavior: "smooth" });
  };
  getValues = (brand) => {
    const array = [
      { id: brand.parentBrandId, name: brand.parentBrandName },
      { id: brand.individualBrandId, name: brand.individualBrandName },
      { id: brand.productId, name: brand.productName },
      { id: brand.id, name: brand.name },
    ];
    return array.filter((a) => !isEmpty(a.name));
  };

  renderBrand = (brand) => {
    const brandValues = this.getValues(brand);
    return (
      <div key={brand.id}>
        {brandValues.map((d, i) => {
          return <DuplicateBrandsList key={i} data={d} brandValues={brandValues} index={i} />;
        })}
      </div>
    );
  };

  render() {
    const { duplicateBrands, brand } = this.props;
    return (
      <div
        className={`flex col-12 flex-wrap ${styles.similarBrands}`}
        ref={(el) => {
          this.el = el;
        }}
      >
        <div>
          There are brands similar to <b>{brand.name}</b>. Do you still want to{" "}
          {brand.id ? "update" : "add"} it?
        </div>
        <div className={styles.brandsList}>
          {duplicateBrands &&
            duplicateBrands.map((data, i) => (
              <div className={`flex col-12 ${styles.brand}`} key={i}>
                {this.renderBrand(data)}
              </div>
            ))}
        </div>
      </div>
    );
  }
}
export default DuplicateBrands;
