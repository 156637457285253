import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE, REASON_TYPES } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import { getAllReasonsByType } from "../../../../api";

export default class RejectVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejection: { rejectionReasonId: null, rejectionNotes: "" },
      hasRejectionError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.rejection.rejectionReasonId !== this.props.rejection.rejectionReasonId) {
      this.setState({
        rejection: {
          rejectionReasonId: this.props.rejection.rejectionReasonId,
          rejectionNotes: this.props.rejection.rejectionNotes,
        },
      });
    }
  };

  static propTypes = {
    rejection: PropTypes.objectOf(PropTypes.string),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  cancelRejection = () => {
    this.setState({ hasRejectionError: false });
    this.props.onClose();
  };

  isValid = (rejection) => {
    return rejection.rejectionReasonId && rejection.rejectionReasonId !== "";
  };

  reject = () => {
    const { rejection } = this.state;
    if (this.isValid(rejection)) {
      this.props.onReject({
        rejectionReasonId: rejection.rejectionReasonId,
        rejectionNotes: rejection.rejectionNotes,
      });
      this.cancelRejection();
    } else {
      return this.setState({ hasRejectionError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    const { rejection } = this.state;
    this.setState({
      rejection: { ...rejection, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { rejection, hasRejectionError } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelRejection()}
        header="RightPanelHeader.reject"
        showFooter={true}
        primaryButtonProps={{
          buttonType: "destructive",
          text: "Button.reject",
          onClick: this.reject,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelRejection }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.API_SELECT,
              id: "type",
              title: "Type",
              label: "",
              placeholder: "Select for Reason for Rejection",
              fetchAction: () => getAllReasonsByType(REASON_TYPES.brandVerifications),
              parseResults: (response) => {
                return response.data.map((item) => ({
                  id: item.id,
                  value: item.reason,
                }));
              },
              selected: (list) => list.filter((item) => item.id === rejection.rejectionReasonId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([value]) => {
                this.inputOnChange(value.id, "rejectionReasonId");
              },
              error: hasRejectionError && !rejection.rejectionReasonId,
              errorMessage: "Invalid Reason",
            },
            {
              id: "notes",
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Notes",
              placeholder: "Enter Notes for Rejection",
              value: rejection.rejectionNotes,
              onChange: (e) => this.inputOnChange(e.target.value, "rejectionNotes"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
