/* 
CamoaignsLandingPage is the landing page for the campaigns module.
It contains tabs for campaigns, target groups, and media.
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as constants from "../../../../constants";
import { bindDispatch } from "../../../../utils";
import HeaderButton from "../../../common/HeaderButtons";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import CampaignList from "./CampaignsList";

class CampaignsLandingPage extends Component {
  createNewCampaign = () => {
    const { actions, history } = this.props;
    actions.ActiveStep(1);
    history.replace(`/campaign-create`);
  };
  render = () => {
    const { history, userData } = this.props;
    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.newCampaign",
      iconName: "AddIcon",
      addButtonScope: constants.SCOPES.CAMPAIGNS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.createNewCampaign,
    };
    return (
      <div>
        <PageHeader
          name="Campaigns"
          //count not shown in page header
          // count={campaigns.totaount}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButton
                history={history}
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CAMPAIGNS}`}
                {...addButtonProps}
                userData={userData}
              />
            </div>
          )}
        />
        <CampaignList />
        {/* <TabView
          id="navbar"
          //campaigns tab is selected by default, in url params tabId need to be passed
          selectedTabId={"campaigns"}
          tabs={[
            {
              id: "campaigns",
              title: "Campaigns",
              goto: `/campaigns`,
              count: campaigns.totalCount || 0,
              panel: <CampaignList />,
            },
            {
              id: "target-groups",
              title: "Target Groups",
              //goto: `/targetGroups`,
              panel: null,
            },
            {
              id: "media",
              title: "Media",
              //goto: `/media`,
              panel: null,
            },
          ]}
        /> */}
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CampaignsLandingPage);
