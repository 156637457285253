const IS_LOADING = "lists/countries/IS_LOADING";
const IS_PARTIAL_LOADING = "lists/countries/IS_PARTIAL_LOADING";
const IS_ERROR = "lists/countries/IS_ERROR";
const DATA = "lists/countries/DATA";
const COUNTRY = "lists/countries/COUNTRY";
const CERTIFICATIONS = "lists/countries/CERTIFICATIONS";
const CERTIFICATION = "lists/countries/CERTIFICATION";
const TAXES = "lists/countries/TAXES";
const TAX = "lists/countries/TAX";
const ACTIVE_COUNTRY = "lists/countries/ACTIVE_COUNTRY";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  DATA,
  COUNTRY,
  CERTIFICATIONS,
  CERTIFICATION,
  TAXES,
  TAX,
  ACTIVE_COUNTRY,
};
