import { CPL_STATUSES } from "../../../../../constants";
import getStatusFromParams from "./getStatusParams";

const getPageTitle = (props) => {
  const status = getStatusFromParams(props);

  if (status === CPL_STATUSES.MAPPED) {
    return "PageHeader.mappedCpls";
  } else if (status === CPL_STATUSES.IGNORED) {
    return "PageHeader.ignoredCpls";
  } else if (status === CPL_STATUSES.MOVIE_MAPPED) {
    return "PageHeader.movieCpls";
  } else {
    return "PageHeader.unmappedCpls";
  }
};

export default getPageTitle;
