import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";
import { Icons } from "prefab";
import { checkScopes, getCategoryNames, formatDataBytes } from "../../../../utils";
import { SCOPES, SCOPE_ACTIONS, SPECIAL_CHARACTERS } from "../../../../constants";
import DetailsLoading from "../../../common/DetailsLoading";
import InfoBlock from "../../../common/InfoBlock";
import EditContent from "./EditContent";

const { EditIcon } = Icons;

class ContentInfo extends Component {
  static propTypes = {
    config: PropTypes.array,
  };
  componentDidMount = () => {
    const { params, actions, content } = this.props;
    if (params.action) {
      if (params.action === "map") {
        actions.mapBrand(content);
      } else if (params.action === "edit") {
        actions.editContent(content);
      }
    }
  };
  mapBrand = (brand) => {
    const { actions, history } = this.props;
    actions.mapBrand(brand);
    history.push(`/content/${brand.id}/details/map`);
  };
  editContent = () => {
    const { actions, content, history } = this.props;
    actions.editContent(content);
    history.push(`/content/${content.id}/details/edit`);
  };
  onCancel = () => {
    const { actions, history, params } = this.props;
    actions.updateMapBrand({ isOpen: false, brand: {} });
    actions.updateEditContent({ isOpen: false, content: {} });
    history.push(`/content/${params.id}/details`);
  };
  render() {
    const { actions, isLoading, content, editContent, languages, mapBrand, userData } = this.props;
    if (isLoading) {
      return <DetailsLoading />;
    }
    return (
      <div className="flex col-12 flex-wrap clearfix">
        <InfoBlock
          header="Composition Details"
          rightSideIcons={checkScopes(
            [
              {
                toolTip: "Edit",
                onClick: () => this.editContent(),
                scope: SCOPES.CONTENT,
                scopeAction: SCOPE_ACTIONS.READ,
                icon: <EditIcon />,
              },
            ],
            userData
          )}
          config={[
            { title: "Composition name", value: content.name, size: { lg: 6, md: 6, sm: 6 } },
            { title: "Duration", value: content.durationInSeconds, size: { lg: 3, md: 3, sm: 6 } },
            {
              title: "Size",
              value: formatDataBytes(content.sizeInBytes),
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Territory & Certification",
              value:
                content.countryCertifications &&
                content.countryCertifications.map((country) => (
                  <div
                    key={country.countryId}
                  >{`${country.countryName} ${SPECIAL_CHARACTERS.MIDDLE_DOT} ${country.certificationCode}`}</div>
                )),
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Certificate Duration (in sec)",
              value: content.censorCertificateBufferTimeInSeconds,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Type",
              value: content.type,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Language",
              value: content.languageName,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Experiences",
              value: "-",
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Aspect Ratio",
              value: content.aspectRatioName,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Audio Type",
              value: content.audioType,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Resolution",
              value: content.resolution,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Standard",
              value: content.isSmpte ? "SMPTE" : "",
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Subtitles",
              value: content.subtitles
                ? content.subtitles.map((lang) => (
                    <div key={lang.languageId}>{lang.languageName}</div>
                  ))
                : "N/A",
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Uploaded By",
              value: content.uploadedByCompanyName,
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Uploaded On",
              value: moment(new Date(content.uploadedAt)).format("lll"),
              size: { lg: 3, md: 3, sm: 6 },
            },
            {
              title: "Notes",
              value: content.notes,
              size: { lg: 6, md: 6 },
            },
          ]}
        />
        <InfoBlock
          header="Brand Details"
          rightSideIcons={checkScopes(
            [
              {
                toolTip: "Edit",
                onClick: () => this.mapBrand(content),
                scope: SCOPES.CONTENT,
                scopeAction: SCOPE_ACTIONS.READ,
                icon: <EditIcon />,
              },
            ],
            userData
          )}
          config={[
            { title: "Brand", value: content.name, size: { lg: 12, md: 12 } },
            {
              title: "Advertiser",
              value:
                content.advertisers &&
                content.advertisers.map((advertiser) => (
                  <div key={advertiser.id}>{advertiser.name}</div>
                )),
              size: { lg: 6, md: 6, sm: 6 },
            },
            {
              title: "Categories",
              value: isEmpty(content.categories) ? "" : getCategoryNames(content.categories),
              size: { lg: 6, md: 6, sm: 6 },
            },
          ]}
        />
        <EditContent
          actions={actions}
          isLoading={isLoading}
          content={content}
          languages={languages}
          editContent={editContent}
          mapBrand={mapBrand}
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}
export default ContentInfo;
