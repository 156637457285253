import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Selector from "../../../common/Selector";
import { VERIFICATION_DETAIL_FIELDS, VERIFICATION_STATUSES } from "../../../../constants";
import LocalizedButton from "../../../common/LocalizedButton";
import TooltipButton from "../../../common/TooltipButton";
import styles from "../../../../styles/Verifications/ViewVerification.module.scss";
import ApiSearch from "../../../common/ApiSearch";
import { searchBrands } from "../../../../api";
import renderDetails from "./details";

const MergeOptions = ({
  data,
  brand,
  brandDuplicates,
  brandData,
  getBrandData,
  reset,
  merge,
  businessTypesList,
  isBrandLoading,
  isConflictsLoading,
}) => {
  const [showSearch, toggleShowSearch] = useState(false);
  if (!brandDuplicates) return null;

  const _parseBrandSearchResults = (results) =>
    results
      .map((item) => ({
        id: item[`${item.type}Id`],
        name: item[`${item.type}Name`],
        type: item.type,
      }))
      .filter((item) => item.id !== data.id);

  const isMergedStatus =
    data.status === VERIFICATION_STATUSES.MERGED &&
    (!brandData || data.mergeTo.id === brandData.id);

  const onSelect = (id, type) => {
    reset();
    getBrandData(id, type);
  };

  return (
    <div className={`col col-6 ${styles.mergeOptionsContainer}`}>
      {(data.hasConflict || brandData?.data?.length > 0) && (
        <div className={styles.similarBrandsInfo}>
          {data.hasConflict
            ? `${data.conflicts.length} Conflicting Brands`
            : `${brandDuplicates.data.length} Similar Brands`}
        </div>
      )}
      <div className={styles.mergeSelector}>
        <div className={styles.selector}>
          {data.hasConflict ? (
            <Selector
              selectedList={[]}
              singleSelect
              list={data.conflicts?.map((conf) => ({ ...conf, value: conf.name })) ?? []}
              onSelect={([selected]) => onSelect(selected.id, brand)}
              label={""}
              showLabelInButton={false}
              usePortal={false}
              placeHolder="Select Conflicting Brands"
            />
          ) : brandDuplicates.data && brandDuplicates.data.length > 0 && !showSearch ? (
            <Selector
              selectedList={
                brandDuplicates.data && brandData ? [{ ...brandData, value: brandData.name }] : []
              }
              singleSelect
              list={brandDuplicates.data?.map((dup) => ({ ...dup, value: dup.name })) ?? []}
              onSelect={([selected]) => onSelect(selected.id, brand)}
              label={""}
              showLabelInButton={false}
              usePortal={false}
              placeHolder="Select Similar Brands"
            />
          ) : (
            <ApiSearch
              key={data.id} //To trigger re-render when brand step is changed!
              placeholder="Search a Brand"
              fetchAction={(value) => searchBrands(value, brand, data.businessType)}
              onSelect={(item) => onSelect(item.id, item.type)}
              parseResults={_parseBrandSearchResults}
              showSubText={false}
              resetOnSelect
            />
          )}
        </div>
        {!data.hasConflict && brandDuplicates.data && brandDuplicates.data.length > 0 && (
          <TooltipButton
            toolTip={showSearch ? "Tooltip.disableSearch" : "Tooltip.enableSearch"}
            className={classNames(styles.searchToggleButton, {
              [styles.searchToggleButtonActive]: showSearch,
            })}
            iconName="Search"
            onClick={() => toggleShowSearch(!showSearch)}
          />
        )}
      </div>
      {renderDetails(
        brandData || data.mergeTo,
        VERIFICATION_DETAIL_FIELDS[brand],
        businessTypesList,
        isBrandLoading
      )}
      <div className={styles.actionButtonContainer}>
        <LocalizedButton
          text={isMergedStatus ? "Button.merged" : "Button.merge"}
          isActive={isMergedStatus}
          loading={isConflictsLoading}
          onClick={() => merge({ mergeTo: brandData })}
          disabled={!brandData}
        />
      </div>
    </div>
  );
};

MergeOptions.propTypes = {
  data: PropTypes.object,
  brand: PropTypes.string,
  brandDuplicates: PropTypes.object,
  brandData: PropTypes.object,
  getBrandData: PropTypes.func,
  merge: PropTypes.func,
  businessTypesList: PropTypes.array,
};

export default MergeOptions;
