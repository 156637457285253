import React from "react";
import classNames from "classnames";
import { Table } from "workbench";
import styles from "../../styles/InfoBlock.module.scss";
import Header from "./InfoBlock/Header";

const TableInfoBlock = ({ header, data, columns, isLargeHeader }) => {
  return (
    <div className={classNames("col-12 clearfix", styles.tableBlock)}>
      {header && isLargeHeader && (
        <div className={classNames("flex col-12 py2", styles.borderBottom)}>
          <Header header={header} />
        </div>
      )}
      <div className={classNames("flex col-12 flex-wrap", styles.block, styles.primary)}>
        {header && !isLargeHeader && <Header header={header} />}
        <div className={classNames("col-12", styles.tableContainer)}>
          <Table data={data} columns={columns} sortable={false} resizable={false} />
        </div>
      </div>
    </div>
  );
};

export default TableInfoBlock;
