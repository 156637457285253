import { isEmpty, compact } from "lodash";
import { TAG_TYPE, SPECIAL_CHARACTERS } from "../constants";
import getTagTypeDisplayName from "./getTagTypeDisplayName";

const getSubTextDisplayName = (data) => {
  const { context, type } = data;
  const tagType = getTagTypeDisplayName(type);
  const location = compact([
    context?.city?.name,
    context?.province?.name,
    context?.country?.name,
  ]).join(", ");
  const theatreName = context?.theatre?.name ?? context?.theatreName;
  const chainName = context?.chain?.name ?? context?.chainName;

  if (!isEmpty(location) && (data.type === TAG_TYPE.THEATRE || data.type === TAG_TYPE.CHAIN)) {
    return `${tagType} ${SPECIAL_CHARACTERS.MIDDLE_DOT} ${location}`;
  }

  if (data.type === TAG_TYPE.SCREEN && (!isEmpty(theatreName) || !isEmpty(chainName))) {
    return `${tagType} ${SPECIAL_CHARACTERS.MIDDLE_DOT} ${theatreName || chainName}`;
  }

  return tagType;
};

export default getSubTextDisplayName;
