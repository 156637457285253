import React, { Component } from "react";
import PropTypes from "prop-types";
import { NumericInput } from "@blueprintjs/core";

import styles from "../../styles/DimensionsRangeInput.module.scss";

export default class InputDimensionsRange extends Component {
  static propTypes = {
    rangeSeparator: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    minRangePlaceholder: PropTypes.string,
    maxRangePlaceholder: PropTypes.string,
    dimensionLength: PropTypes.object,
    dimensionWidth: PropTypes.object,
    dimensionHeight: PropTypes.object,
    buttonPosition: PropTypes.oneOf(["right", "left", "none"]),
  };
  static defaultProps = {
    dimensionSeparator: "X",
    rangeSeparator: "to",
    minRangePlaceholder: "Min",
    maxRangePlaceholder: "Max",
    buttonPosition: "none",
  };
  render() {
    const {
      dimensionSeparator,
      rangeSeparator,
      minRangePlaceholder,
      maxRangePlaceholder,
      dimensionLength,
      dimensionWidth,
      dimensionHeight,
      onChange,
      ...rest
    } = this.props;

    //SLATE-1436 Dec12 Anushree:- dimension value should be cleared from UI if undefiened
    return (
      <div className={styles.dimensionInput}>
        <div className={styles.rangeWrapper}>
          <div className={styles.inputContainer}>
            <div className={styles.title}>L (ft)</div>
            <NumericInput
              {...rest}
              id="auditoriumLengthFrom"
              value={dimensionLength && dimensionLength.rangeFrom ? dimensionLength.rangeFrom : ""}
              //value={dimensionLength && dimensionLength.rangeFrom}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeFrom", "auditoriumLength")}
              placeholder={minRangePlaceholder}
            />
          </div>
          <div className={styles.separator}>{dimensionSeparator}</div>
          <div className={styles.inputContainer}>
            <div className={styles.title}>W (ft)</div>
            <NumericInput
              {...rest}
              id="auditoriumWidthFrom"
              value={dimensionWidth && dimensionWidth.rangeFrom ? dimensionWidth.rangeFrom : ""}
              //value={dimensionWidth && dimensionWidth.rangeFrom}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeFrom", "auditoriumWidth")}
              placeholder={minRangePlaceholder}
            />
          </div>
          <div className={styles.separator}>{dimensionSeparator}</div>
          <div className={styles.inputContainer}>
            <div className={styles.title}>H (ft)</div>
            <NumericInput
              {...rest}
              id="auditoriumHeightFrom"
              value={dimensionHeight && dimensionHeight.rangeFrom ? dimensionHeight.rangeFrom : ""}
              //value={dimensionHeight && dimensionHeight.rangeFrom}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeFrom", "auditoriumHeight")}
              placeholder={minRangePlaceholder}
            />
          </div>
        </div>
        <div className={styles.separator}>{rangeSeparator}</div>
        <div className={styles.rangeWrapper}>
          <div className={styles.inputContainer}>
            <div className={styles.title}>L (ft)</div>
            <NumericInput
              {...rest}
              //id="auditoriumHeightTo"
              id="auditoriumLengthtTo"
              value={dimensionLength && dimensionLength.rangeTo ? dimensionLength.rangeTo : ""}
              //value={dimensionLength && dimensionLength.rangeTo}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeTo", "auditoriumLength")}
              placeholder={maxRangePlaceholder}
            />
          </div>
          <div className={styles.separator}>{dimensionSeparator}</div>
          <div className={styles.inputContainer}>
            <div className={styles.title}>W (ft)</div>
            <NumericInput
              {...rest}
              id="auditoriumWidthTo"
              value={dimensionWidth && dimensionWidth.rangeTo ? dimensionWidth.rangeTo : ""}
              //value={dimensionWidth && dimensionWidth.rangeTo}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeTo", "auditoriumWidth")}
              placeholder={maxRangePlaceholder}
            />
          </div>
          <div className={styles.separator}>{dimensionSeparator}</div>
          <div className={styles.inputContainer}>
            <div className={styles.title}>H (ft)</div>
            <NumericInput
              {...rest}
              id="auditoriumHeightTo"
              value={dimensionHeight && dimensionHeight.rangeTo ? dimensionHeight.rangeTo : ""}
              //value={dimensionHeight && dimensionHeight.rangeTo}
              stepSize={1}
              onValueChange={(data) => onChange(data, "rangeTo", "auditoriumHeight")}
              placeholder={maxRangePlaceholder}
            />
          </div>
        </div>
      </div>
    );
  }
}
