import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import PageHeader from "../../../common/PageHeader";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import ActionIcon from "../../../common/ActionIcon";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import EditCampaignTargetType from "./EditCampaignTargetType";

const links = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.campaignTargetTypes" },
];

export class CampaignTargetTypes extends Component {
  state = {
    selectedColumns: ["Name", "Last Updated By", "Last Updated On"],
  };

  componentDidMount = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action) {
      if (params.action === "new") {
        actions.addCampaignTargetType();
      } else {
        actions.getCampaignTargetTypeById(params.action);
      }
    }
    actions.getCampaignTargetTypeList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  addCampaignTargetType = () => {
    const { actions, history } = this.props;
    actions.addCampaignTargetType();
    history.replace(`/lists/campaign-target-types/new`);
  };

  cancelEdit = () => {
    const { actions, history } = this.props;
    actions.cancelEditCampaignTargetType();
    history.replace(`/lists/campaign-target-types`);
  };

  editCampaignTargetType = (data) => {
    const { actions, history } = this.props;
    actions.editCampaignTargetType(data);
    history.replace(`/lists/campaign-target-types/${data.id}`);
  };

  render = () => {
    const {
      campaignTargetType: {
        data,
        isLoading,
        campaignTargetTypeEdit: { isOpen, campaignTargetType },
      },
      history,
      actions,
      userData,
    } = this.props;
    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const columns = checkScopes(
      [
        {
          id: "id",
          Header: "UUID",
          accessor: "id",
        },
        {
          id: "name",
          Header: "Name",
          accessor: "name",
        },
        lastUpdatedByColumn(),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.CONTENT_TYPES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editCampaignTargetType(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const buttonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.CAMPAIGN_TARGET_TYPE,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addCampaignTargetType,
      userData,
    };
    return (
      <div className={classNames("col-12 clearfix")}>
        <Breadcrumb history={history} links={links} />
        <PageHeader
          isLoading={isLoading}
          name="PageHeader.campaignTargetTypes"
          count={data?.data?.length}
          renderRightSideComponent={() => (
            <HeaderButton
              columnFilter
              logsButton
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CAMPAIGN_TARGET_TYPES}`}
              {...buttonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <Table
              data={data.data || []}
              sortable={false}
              manual={false}
              defaultSorted={[{ id: "name", desc: false }]}
              pageSize={data.data ? data.data.length : 5}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              LoadingComponent={<TableLoading columns={columns} />}
            />
            <FooterControls {...buttonProps} />
          </div>
        </div>
        {isOpen && (
          <EditCampaignTargetType
            isOpen={isOpen}
            onClose={this.cancelEdit}
            actions={actions}
            campaignTargetType={campaignTargetType}
          />
        )}
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.campaignTargetType,
  (state) => state.userData,
  (campaignTargetType, userData) => ({ campaignTargetType, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CampaignTargetTypes);
