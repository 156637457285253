import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons } from "prefab";
import { Boundary, Classes, Menu, OverflowList, Popover, Position } from "@blueprintjs/core";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/BreadCrumb.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";
import menuStyles from "../../styles/Menu.module.scss";
import popOverStyles from "../../styles/PopOver.module.scss";
import { CustomMenuItem } from ".";

const { ArrowRightIcon } = Icons;

export default class Breadcrumb extends React.PureComponent {
  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ).isRequired,
    history: PropTypes.object,
  };

  static defaultProps = {
    isLoading: false,
  };

  gotoLink = (path, isCurrent) => {
    if (isCurrent) return;
    this.props.history.push(path);
  };

  renderBreadcrumb = (props, index) => {
    if (props.path != null) {
      return (
        <li key={index}>
          <div className={styles.item} onClick={() => this.gotoLink(props.path, false)}>
            {props.name && <FormattedMessage id={props.name} defaultMessage={props.name} />}
          </div>
          <ArrowRightIcon />
        </li>
      );
    }
    return (
      <li
        className={`${Classes.BREADCRUMB} ${Classes.BREADCRUMB_CURRENT} ${styles.current}`}
        key={index}
      >
        {props.name && <FormattedMessage id={props.name} defaultMessage={props.name} />}
      </li>
    );
  };

  renderOverflow = (items) => {
    const position = Position.BOTTOM_RIGHT;
    const orderedItems = items.slice().reverse();
    const menuItems = orderedItems
      .filter((item) => item.name)
      .map((item, index) => (
        <CustomMenuItem
          className={classNames(customMenuStyles.menuItem, styles.overflowMenuItems)}
          text={<FormattedMessage id={item.name} defaultMessage={item.name} />}
          onClick={() => this.gotoLink(item.path, false)}
          key={index}
        />
      ));
    return (
      <li>
        <Popover
          minimal
          position={position}
          popoverClassName={popOverStyles.popOver}
          content={<Menu className={menuStyles.menu}>{menuItems}</Menu>}
        >
          <span className={Classes.BREADCRUMBS_COLLAPSED} />
        </Popover>
      </li>
    );
  };

  render() {
    const { links, style, isLoading } = this.props;
    return (
      <div className={`col-12 clearfix ${styles.wrapper}`}>
        <div className={`col-12 clearfix ${styles.container}`} style={style}>
          <OverflowList
            minVisibleItems={1}
            className={classNames(Classes.BREADCRUMBS, {
              [Classes.SKELETON]: isLoading,
            })}
            observeParents={true}
            collapseFrom={Boundary.START}
            items={links}
            overflowRenderer={this.renderOverflow}
            visibleItemRenderer={this.renderBreadcrumb}
          />
        </div>
      </div>
    );
  }
}
