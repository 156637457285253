import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "../../styles/TabPills.module.scss";

class TabPills extends Component {
  static propTypes = {
    tabs: PropTypes.array.isRequired,
    selectedTabId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { tabs, selectedTabId, onChange } = this.props;
    return (
      <div className={styles.container}>
        {tabs.map((tab) => (
          <Tag
            key={tab.id}
            minimal={tab.id !== selectedTabId}
            large
            interactive
            className={classNames(styles.tab, { [styles.active]: tab.id === selectedTabId })}
            onClick={() => onChange(tab.id)}
          >
            {tab.title}
          </Tag>
        ))}
      </div>
    );
  }
}

export default TabPills;
