import { compact } from "lodash";
import { TAG_TYPE } from "../constants";
import { OPERATIONS } from "../constants";

const getOperationLabel = (operation) => {
  const foundOperation = OPERATIONS.find((op) => op.value === operation);
  return foundOperation ? foundOperation.label : "Unknown Operation";
};

const getTagDisplayName = (filter) => {
  if (
    filter.type === TAG_TYPE.NO_OF_SCHEDULES ||
    filter.type === TAG_TYPE.TOTAL_TAKEN ||
    filter.type === TAG_TYPE.TOTAL_PLAYED ||
    filter.type === TAG_TYPE.NO_OF_SHOW_DAYS ||
    filter.type === TAG_TYPE.SPOTS_SCHEDULED ||
    filter.type === TAG_TYPE.SPOTS_PLAYED ||
    filter.type === TAG_TYPE.SPOTS_POSSIBLE
  ) {
    return compact([getOperationLabel(filter.operation), filter.value]).join(": ");
  }

  if (filter.type !== TAG_TYPE.CITY && filter.type !== TAG_TYPE.PROVINCE) return filter.displayName;

  return compact([
    filter.displayName,
    filter.context?.province?.name,
    filter.context?.country?.name,
  ]).join(", ");
};

export default getTagDisplayName;
