/*
1.The code defines an initial state object with four properties: isLoading, iserror, filters, and showFailureScheduleData.
2.It defines a reducer function that takes in a state and an action and returns a new state based on the action type and payload.
3.The reducer function updates the isLoading, showFailureScheduleData, isError, and filters properties based on the action type and payload.
4.If the action type is not recognized, it returns the current state.
*/
import * as types from "../types/showFailureSchedule";

const initialState = {
  isLoading: true,
  iserror: false,
  filters: [],
  showFailureScheduleData: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.SHOWFAILURE_SCHEDULE_DATA:
      return { ...state, showFailureScheduleData: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.FILTER:
      return { ...state, filters: payload };
    default:
      return state;
  }
}
