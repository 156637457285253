import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import EmptyPage from "../common/EmptyPage";

class Unauthorized extends Component {
  componentDidMount() {
    Sentry.captureMessage("Unauthorized error has occured!");
  }

  render() {
    return (
      <>
        <EmptyPage
          heading="EmptyState.unauthorizedHeading"
          body="EmptyState.unauthorizedMessage"
          isHomeLinkVisible={true}
        />
      </>
    );
  }
}

export default Unauthorized;
