import classNames from "classnames";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { SCOPES, SCOPE_ACTIONS } from "../../../constants";
import styles from "../../../styles/AdvertiserView.module.scss";
import { bindDispatch } from "../../../utils";
import Breadcrumb from "../../common/BreadCrumb";
import HeaderButton from "../../common/HeaderButtons";
import PageHeader from "../../common/PageHeader";
import PageLoadingWithTabs from "../../common/PageLoadingWithTabs";
import TabView from "../../common/Tabs";
import ContentTableWithFilters from "../content/common/Content";
import EditAdvertiser from "./EditAdvertiser";
import Brands from "./Tabs/Brands";
import CompanyDetails from "./Tabs/Details";

export class AdvertisersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advertiserEdit: this.props.companies.addorEditAdvertiser.advertiser,
    };
  }
  state = {
    selectedColumns: ["In Use", "Campaign", "Played Count"],
  };

  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.action === "edit") actions.editAdvertiser(params.action);
    actions.getAdvertiserById(params.advertiserId);
  };

  componentDidUpdate = (prevProps) => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.advertiser !== prevProps.match.params.advertiser) {
      actions.getAdvertiserById(params.advertiserId);
      this.setState({
        advertiserEdit: this.props.companies.addorEditAdvertiser.advertiser,
      });
    }
  };

  createOrUpdateAdvertiser = (advertiser) => {
    const { actions, history } = this.props;
    const params = queryString.parse(history.location.search);
    actions.createOrUpdateAdvertiser(params, {}, advertiser);
    actions.getAdvertiserById(advertiser.id);
    history.replace(`/companies/advertisers${history.location.search}`);
  };

  editAdvertiser = () => {
    const {
      actions,
      match: { params },
      companies: { advertiser },
      history,
    } = this.props;
    history.replace(`/companies/advertisers/${params.advertiserId}/details/edit`);
    actions.editAdvertiser(advertiser);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.cancelEditAdvertiser();
    history.push(`/companies/advertisers/${params.advertiserId}/${params.advertiserTabId}`);
  };

  onChangeTab = () => {};
  render = () => {
    const {
      history,
      companies: { isAdvertisersLoading, advertiser, addorEditAdvertiser },
      match: { params },
      userData,
    } = this.props;
    if (isAdvertisersLoading) return <PageLoadingWithTabs showBreadcrumb />;
    const { isOpen } = addorEditAdvertiser;
    return (
      <div className="col-12 clearfix">
        <Breadcrumb
          history={history}
          links={[
            { name: `Advertisers`, path: `/companies/advertisers` },
            { name: advertiser.name },
          ]}
        />
        <PageHeader
          name={advertiser.name}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButton
                editButton
                logsButton
                logsOnlyForSlateAdmin
                logsPath={`/logs/ref/${params.advertiserId}`}
                editButtonLabel="Button.editAdvertiser"
                history={history}
                editButtonScope={SCOPES.BUYERS}
                editButtonScopeAction={SCOPE_ACTIONS.WRITE}
                userData={userData}
                edit={this.editAdvertiser}
              />
            </div>
          )}
        />
        <TabView
          scopeCheck
          selectedTabId={params.advertiserTabId}
          onChange={this.onChangeTab}
          tabs={[
            {
              id: "details",
              title: "Details",
              goto: `/companies/advertisers/${params.advertiserId}/details`,
              scope: SCOPES.INVENTORY,
              scopeAction: SCOPE_ACTIONS.READ,
              panel: <CompanyDetails company={advertiser} />,
              userData: userData,
            },
            // {
            //   id: "settings",
            //   title: "Settings",
            //   goto: `/companies/advertisers/${params.advertiserId}/settings`,
            //   scope: SCOPES.INVENTORY,
            //   scopeAction: SCOPE_ACTIONS.READ,
            //   userData: userData,
            //   panel: <Settings {...this.props} />,
            // },
            {
              id: "brands",
              title: "Brands",
              goto: `/companies/advertisers/${params.advertiserId}/brands`,
              panel: <Brands {...this.props} parentTagId={params.advertiserId} />,
              scope: SCOPES.BRANDS,
              scopeAction: SCOPE_ACTIONS.READ,
              userData: userData,
            },
            {
              id: "content",
              title: "Content",
              //TODO: Fetch and show actual count, once the api is available
              count: 50,
              goto: `/companies/advertisers/${params.advertiserId}/content`,
              userData: userData,
              scope: SCOPES.CONTENT,
              scopeAction: SCOPE_ACTIONS.READ,
              panel: (
                <ContentTableWithFilters
                  selectedColumns={this.state.selectedColumns}
                  companyId={params.advertiserId}
                  {...this.props}
                />
              ),
            },
          ]}
        />
        <div className={classNames("flex col-12 flex-wrap clearfix", styles.panelPadding)}>
          <EditAdvertiser
            isOpen={isOpen}
            advertiser={advertiser}
            cancelEdit={this.cancelEdit}
            onUpdate={(advertiserEdit) => this.createOrUpdateAdvertiser(advertiserEdit)}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (companies, userData) => ({ companies, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(AdvertisersPage);
