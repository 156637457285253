import { fetchRights } from "../../api";
import * as types from "../types/rights";

const updateRightsList = (payload) => {
  return { type: types.DATA, payload: payload };
};

const handleError = (payload) => {
  return { type: types.IS_ERROR, payload: payload };
};

const handleBusy = (payload) => {
  return { type: types.IS_LOADING, payload: payload };
};

const getRights = (fetch_rights = fetchRights) => {
  return async (dispatch) => {
    dispatch(handleBusy(true));
    try {
      const { data } = await fetch_rights();
      dispatch(updateRightsList(data));
      dispatch(handleBusy(false));
    } catch (error) {
      dispatch(handleBusy(false));
      dispatch(handleError(error));
    }
  };
};

export { updateRightsList, getRights };
