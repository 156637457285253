import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "../../styles/SecondaryTable.module.scss";

const SecondaryTable = ({ columns, data, rowHeaderClass, bodyColumnClass }) => {
  if (data.length < 1) {
    return "No data";
  }

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr className={classNames(styles.rowHeader, rowHeaderClass)}>
            {columns.map((column, index) => (
              <td
                key={index}
                className={styles.columnHeader}
                style={{
                  width: column.width ? column.width : 80,
                  flex: column.width ? `${column.width} 0 auto` : `80 0 auto`,
                }}
              >
                {column.Header}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className={styles.body}>
          {data.map((row, rowIndex) => (
            <tr className={classNames(styles.row, bodyColumnClass)} key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <td
                  className={styles.column}
                  style={{
                    width: column.width ? column.width : 80,
                    flex: column.width ? `${column.width} 0 auto` : `80 0 auto`,
                  }}
                  key={columnIndex}
                >
                  {typeof column.accessor === "string"
                    ? row[column.accessor]
                    : column.accessor(row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SecondaryTable.propTypes = {
  column: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  data: PropTypes.array,
};

export default SecondaryTable;
