import React, { Component } from "react";
import PropTypes from "prop-types";
import * as constants from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import { searchCategories } from "../../../../api";

const editError = {
  categoryName: "Should not remove the Product Category",
  subCategory1Name: "Should not remove the Product Sub-Category 1",
  subCategory2Name: "Should not remove the Product Sub-Category 2",
};
export default class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      duplicateCat: props.category,
      query: props.category,
      hasEditError: false,
      errorMessage: {
        categoryName: "",
        subCategory1Name: "",
        subCategory2Name: "",
      },
    };
  }

  static propTypes = {
    categories: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isError: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isError: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.category !== prevProps.category)
      this.setState({
        category: this.props.category,
        duplicateCat: this.props.category,
      });
  }

  cancelEdit = () => {
    this.setState({
      hasEditError: false,
      category: this.props.category,
      subCategoryErrorMessage: {
        subCategory1Name: "",
        subCategory2Name: "",
      },
    });
    this.props.onClose();
  };

  isValid = (category) => {
    if (category.categoryName.trim().length === 0) return false;
    if (category.subCategory1Name.trim().length > 1 && category.categoryName.trim().length === 0)
      return false;
    if (
      category.subCategory2Name.trim().length > 1 &&
      category.subCategory1Name.trim().length === 0
    ) {
      this.setState({
        subCategoryErrorMessage: {
          ...this.state.subCategoryErrorMessage,
          subCategory2Name: "Enter valid Product Sub-Category 1",
        },
      });
      return false;
    }
    return true;
  };
  getValidationMessages = () => {
    const { isError } = this.props;
    const { duplicateCat, category } = this.state;
    if (category.categoryId && isError) {
      if (duplicateCat.subCategory1Name && category.subCategory1Name.length === 0) {
        this.setState({
          subCategoryErrorMessage: {
            ...this.state.subCategoryErrorMessage,
            subCategory1Name: editError.subCategory1Name,
          },
        });
        return false;
      }
      if (duplicateCat.subCategory2Name && category.subCategory2Name.length === 0) {
        this.setState({
          subCategoryErrorMessage: {
            ...this.state.subCategoryErrorMessage,
            subCategory2Name: editError.subCategory2Name,
          },
        });
        return false;
      }
    }
    return true;
  };
  editValidation = (accessor) => {
    const { category, duplicateCat } = this.state;
    if (duplicateCat[accessor] && category[accessor].length === 0) {
      return true;
    }
    return false;
  };
  save = () => {
    const { category } = this.state;
    const cat = this.props.category;
    const editValidation = this.getValidationMessages();
    if (!editValidation) return;
    if (!this.isValid(category)) {
      this.setState({ hasEditError: true, catErrorMessage: "Enter Valid Product Category" });
      return;
    }
    if (
      cat.categoryName === category.categoryName &&
      cat.subCategory1Name === category.subCategory1Name &&
      cat.subCategory2Name === category.subCategory2Name
    ) {
      this.cancelEdit();
      return;
    }
    this.props.actions.saveCategoryWithSubCategories(
      category,
      () => this.props.getCategoriesList(),
      this.cancelEdit,
      (msg) => this.setState({ hasEditError: true, catErrorMessage: msg })
    );
  };

  parseCategory = (category) => {
    return {
      category: {
        id: category.categoryId,
        name: category.categoryName,
        type: "Category",
      },
      subCategory1: {
        id: category.subCategory1Id,
        name: category.subCategory1Name,
        type: "Sub Category 1",
      },
      subCategory2: {
        id: category.subCategory2Id,
        name: category.subCategory2Name,
        type: "Sub Category 2",
      },
    };
  };

  inputOnChange = (value, accessor) => {
    const { category } = this.state;

    this.setState({
      category: { ...category, [accessor]: value },
      hasEditError: false,
    });
  };

  onSelect = (value, accessor) => {
    const { category } = this.state;
    this.setState({
      category: {
        ...category,
        [`${accessor}Name`]: value[accessor].name,
        [`${accessor}Id`]: value[accessor].id,
      },
    });
  };

  lastEditableField = (category) => {
    if (category.categoryId && category.subCategory1Id === "") {
      return "categoryName";
    } else if (category.subCategory1Id && category.subCategory2Id === "") {
      return "subCategory1Name";
    } else {
      return "subCategory2Name";
    }
  };

  renderConfig = (category, isError, hasEditError, errorMessage) => {
    switch (this.lastEditableField(category)) {
      case "categoryName":
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.INPUT,
            label: "Product Category",
            className: "col-12",
            placeholder: "Enter Product Category",
            value: category.categoryName,
            error: isError
              ? this.editValidation("categoryName")
              : hasEditError && category.categoryName.trim().length > 1,
            errorMessage: errorMessage.categoryName,
            onChange: (e) => this.inputOnChange(e.target.value, "categoryName"),
          },
        ];

      case "subCategory1Name":
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Category",
            placeholder: "Enter Product Category",
            onSelect: (props) => {
              this.onSelect(props.item, "category");
            },
            query: this.props.category.categoryName,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data.map((item) => ({
                id: item.category.id,
                name: item.category.name,
                tag: item.category.type,
                item: item,
              }));
            },
            fetchAction: (value) => searchCategories(value, "Category"),
            errorMessage: errorMessage.categoryName,
            error: hasEditError && !category.categoryName,
          },
          {
            id: "subCategory1Name",
            type: constants.FORM_FIELD_TYPE.INPUT,
            label: "Product Sub-Category 1",
            className: "col-12",
            placeholder: "Enter Product Sub-Category 1",
            value: category.subCategory1Name,
            error: isError
              ? this.editValidation("subCategory1Name")
              : hasEditError &&
                category.subCategory1Name.trim().length > 1 &&
                category.subCategory2Name.trim().length === 0,
            errorMessage: errorMessage.subCategory1Name,
            onChange: (e) => this.inputOnChange(e.target.value, "subCategory1Name"),
          },
        ];

      default:
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.INPUT,
            label: "Product Category",
            className: "col-12",
            placeholder: "Enter Product Category",
            value: category.categoryName,
            error: isError
              ? this.editValidation("categoryName")
              : hasEditError && category.categoryName.trim().length > 1,
            errorMessage: errorMessage.categoryName,
            onChange: (e) => this.inputOnChange(e.target.value, "categoryName"),
            disabled: true,
          },
          {
            id: "subCategory1Name",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Sub-Category 1",
            placeholder: "Enter Product Sub-Category 1",
            onSelect: (props) => {
              this.onSelect(props.item, "subCategory1");
            },
            query: this.props.category.subCategory1Name,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data
                .filter((item) => item.category.id === category.categoryId)
                .map((item) => {
                  return {
                    id: item.subCategory1.id,
                    name: item.subCategory1.name,
                    tag: item.subCategory1.type,
                    item: item,
                  };
                });
            },
            fetchAction: (value) => searchCategories(value, "Sub Category 1"),
            errorMessage: errorMessage.subCategory1Name,
            error: hasEditError && !category.subCategory1Name,
          },
          {
            id: "subCategory2Name",
            type: constants.FORM_FIELD_TYPE.INPUT,
            label: "Product Sub-Category 2",
            className: "col-12",
            placeholder: "Enter Product Sub-Category 2",
            value: category.subCategory2Name,
            error: isError
              ? this.editValidation("subCategory2Name")
              : hasEditError &&
                category.subCategory2Name.trim().length > 1 &&
                category.subCategory1Name.trim().length === 0,
            errorMessage: errorMessage.subCategory2Name,
            onChange: (e) => this.inputOnChange(e.target.value, "subCategory2Name"),
          },
        ];
    }
  };

  render = () => {
    const { isOpen, isError } = this.props;
    const { category, hasEditError, errorMessage } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header="RightPanelHeader.editProductCategory"
        showFooter={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form config={this.renderConfig(category, isError, hasEditError, errorMessage)} />
      </RightPanel>
    );
  };
}
