import {
  COMPOSITIONS_LIST,
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  GET_CPL,
  IS_COMPOSITION_DETAILS_LOADING,
  IS_COMPOSITION_UPLOADING,
  RESET_COMPOSITION,
} from "../types/compositions";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  compositionsList: {
    data: [],
  },
  isCompositionDetailsLoading: false,
  isUploading: false,
  error: {
    isError: false,
    message: "",
  },
  composition: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case COMPOSITIONS_LIST:
      return { ...state, compositionsList: payload };
    case IS_ERROR:
      return { ...state, error: payload };
    case IS_LOADING:
      return { ...state, isLoading: payload };
    case IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case GET_CPL:
      return { ...state, composition: payload };
    case IS_COMPOSITION_DETAILS_LOADING:
      return { ...state, isCompositionDetailsLoading: payload };
    case IS_COMPOSITION_UPLOADING:
      return { ...state, isUploading: payload };
    case RESET_COMPOSITION:
      return { ...state, composition: {}, isCompositionDetailsLoading: false };
    default:
      return state;
  }
}
