import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/InfoBlock.module.scss";

const TextWithLabel = ({ loading, label, text, className }) => {
  const classes = classNames({
    "col-12": !loading,
    "col-4": loading,
    [Classes.SKELETON]: loading,
  });
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={classNames(styles.label, classes)}>{label}</div>
      <div className={classNames("col-12", styles.textValue, classes)}>{text}</div>
    </div>
  );
};

TextWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

export default TextWithLabel;
