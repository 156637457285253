import { getRolesScopes } from "../../api";
import * as types from "../types/rolesScopes";
import parseRolesScopes from "../../utils/parseRolesScopes";

const updateList = (payload) => {
  return { type: types.DATA, payload };
};

const handleError = (payload) => {
  return { type: types.IS_ERROR, payload };
};

const handleLoading = (payload) => {
  return { type: types.IS_LOADING, payload };
};

const getRolesScopesList = () => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await getRolesScopes();
      dispatch(updateList(parseRolesScopes(data)));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

export { getRolesScopesList };
