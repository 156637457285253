import { isEmpty } from "lodash";

import { CAMPAIGN_STEPS } from "../../../../constants";
import { showToast } from "../../../../utils";

const campaignStepChange = (history, params, activeStep, stepToProceed) => {
  if (activeStep < stepToProceed || !params.campaignId) return null;

  const { campaignId } = params;
  switch (stepToProceed) {
    case CAMPAIGN_STEPS.CAMPAIGN:
      history.push(`/campaign-create/${campaignId}/details`);
      break;
    case CAMPAIGN_STEPS.TARGET_GROUP:
      history.push(`/campaign-create/${campaignId}/target-groups/list`);
      break;
    case CAMPAIGN_STEPS.MEDIA:
      history.push(`/campaign-create/${campaignId}/media/list`);
      break;
    case CAMPAIGN_STEPS.REVIEW:
      history.push(`/campaign-create/${campaignId}/review`);
      break;
    case CAMPAIGN_STEPS.FINISH:
      history.push(`/campaign-create/${campaignId}/finish`);
      break;
    default:
      break;
  }
};

const getDateRange = (validity) => {
  if (!isEmpty(validity)) {
    const { fromDate, toDate } = validity;
    return [fromDate ? new Date(fromDate) : null, toDate ? new Date(toDate) : null];
  }
  return [];
};

const getRestrictedDateLimit = (validity, campaignValidity) => {
  const validityData = isEmpty(validity) ? campaignValidity : validity;
  if (!isEmpty(validityData)) {
    const { fromDate, toDate } = validityData;

    return {
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    };
  }

  return { minDate: undefined, maxDate: undefined };
};

const validateMandatoryCampaignFields = (campaign) => {
  let isValid = false;

  if (campaign?.name) {
    isValid = Boolean(
      campaign.advertiserId &&
        campaign.campaignTargetTypeId &&
        campaign.campaignTargetTypeName &&
        !isEmpty(campaign.currencyId) &&
        !isEmpty(campaign.countryIds) &&
        campaign.validity?.fromDate &&
        campaign.validity?.toDate
    );
  }

  if (isValid) {
    isValid = Boolean(
      campaign?.billingInfo?.billerName &&
        campaign?.billingInfo?.billingAddress &&
        campaign?.billingInfo?.billingCycleId
    );
  }

  if (isValid && campaign?.billingInfo?.isAdvanceAmountRequired) {
    isValid = campaign?.billingInfo?.advanceAmountInFractionalUnit > 0;
  }

  return isValid;
};

const validateMandatoryTargetGroupFields = (targetGroup, actionType) => {
  let isValid = false;

  // if (actionType === "edit") {
  //   return targetGroup?.id;
  // } else {
  //   isValid = true;
  // }

  if (targetGroup.name && !isEmpty(targetGroup.validity)) {
    const isTargetInclusionsSelected = targetGroup.criteria.inclusions.length > 0;
    const isMandatoryFilled = targetGroup.validity?.fromDate && targetGroup.validity?.toDate;

    isValid = Boolean(isMandatoryFilled && isTargetInclusionsSelected);

    //toast message to be shown if user has not selected any inclusions and other required fields are filled
    if (isMandatoryFilled && !isTargetInclusionsSelected) {
      showToast("Please Set Up Targets", false);
    }
  }

  return isValid;
};

const validateMandatoryMediaFields = (media, actionType) => {
  let isValid = false;

  // if (actionType === "edit") {
  //   return media?.id;
  // } else {
  //   isValid = true;
  // }

  //Checking Basic Details
  if (media.name && !isEmpty(media.validity)) {
    const isContentValid = Boolean(
      media?.contentId || (media?.contentTypeId && media?.contentDurationInSeconds)
    );
    isValid = Boolean(
      media.validity?.fromDate &&
        media.validity?.toDate &&
        isContentValid &&
        media?.brandId &&
        media?.categoryIds.length > 0
    );
  }
  //Checking Placement section
  if (isValid) {
    isValid =
      media.placements.every(
        (placement) =>
          (media.placementMode !== "Advanced" || placement.plays?.length > 0) &&
          placement.segmentId &&
          placement.playlistPackTypeId
      ) &&
      (media.placementMode === "Advanced" || (media.weeklyPlayCount && media.showPlayCount)) &&
      media.positionUnavailability;
  }

  return isValid;
};

export {
  campaignStepChange,
  validateMandatoryCampaignFields,
  validateMandatoryTargetGroupFields,
  validateMandatoryMediaFields,
  getDateRange,
  getRestrictedDateLimit,
};
