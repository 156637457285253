import { searchCertificates, syncCertificateWithMovieBuff, fetchCertificate } from "../../api";
import * as types from "../types/contentRatings";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const updateCertifications = (payload) => {
  return { type: types.CERTIFICATIONS, payload: payload };
};
const updateCertificate = (payload) => {
  return { type: types.CERTIFICATE, payload: payload };
};

const handleError = (payload) => {
  return { type: types.IS_ERROR, payload: payload };
};

const handleLoading = (payload) => {
  return { type: types.IS_LOADING, payload: payload };
};

const handlePartialLoading = (payload) => {
  return { type: types.IS_PARTIAL_LOADING, payload: payload };
};

const syncWithMovieBuff = (callBack, sync = syncCertificateWithMovieBuff) => {
  return async (dispatch) => {
    try {
      await sync();
      showToast("Toast.syncCertificateWithMovieBuff");
      await callBack();
    } catch (error) {
      const { response = { data: {} } } = error;
      showToast(
        response.data.code === 429 ? "Toast.syncCertificateWithMovieBuff" : error.message,
        response.data.code === 429
      );
    }
  };
};

const getCertifications = (
  params = {},
  filters = [],
  fetchCertificates = searchCertificates,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    isPartial ? dispatch(handlePartialLoading(true)) : dispatch(handleLoading(true));
    try {
      const { data } = await fetchCertificates(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCertifications(data));
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
    } catch (error) {
      isPartial ? dispatch(handlePartialLoading(false)) : dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const getCertificate = (certificateID, fetch = fetchCertificate) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await fetch(certificateID);
      dispatch(updateCertificate(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};
export {
  handleLoading,
  handlePartialLoading,
  updateCertifications,
  getCertifications,
  syncWithMovieBuff,
  getCertificate,
};
