import React, { Component } from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import moment from "moment";
import { includes } from "lodash";
import * as constants from "../../../../../../constants";
import RightPanel from "../../../../../common/RightPanel";
import Form from "../../../../../common/Form";

export default class EditRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasEditError: false,
      right: props.right,
    };
  }

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    right: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    rights: PropTypes.array,
    companies: PropTypes.array,
  };

  static defaultProps = {
    rights: [],
    companies: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.right !== prevProps.right) this.setState({ right: this.props.right });
  }

  cancelEdit = () => {
    this.setState({ hasEditError: false });
    this.props.onClose();
  };

  isValid = (right) => {
    return moment(right.validity.toDate).isAfter(right.validity.fromDate);
  };

  save = () => {
    const { right } = this.state;
    if (this.isValid(right)) {
      this.props.onSave(right);
      this.cancelEdit();
    } else {
      return this.setState({ hasEditError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    const { right } = this.state;
    this.setState({
      right: { ...right, [accessor]: value },
    });
  };

  onDateChange = (date, time, keys) => {
    this.inputOnChange(
      {
        ...this.state.right.validity,
        [keys[0]]: date,
        [keys[1]]: time,
      },
      "validity"
    );
  };

  renderRightFields = (rights, companies, right, hasEditError) => {
    const [filteredRights] = rights.filter((item) => item.name === right.right.name);
    if (right.right.name === "Seller") {
      return [
        {
          type: constants.FORM_FIELD_TYPE.BP_SUGGEST,
          title: "Company",
          items: companies,
          selectedItem: companies.filter((company) => company.id === right.company.id)[0] || null,
          inputProps: {
            placeholder: "Search for Company",
          },
          onItemSelect: (item) => {
            this.inputOnChange({ id: item.id, name: item.displayName }, "company");
          },
          itemRenderer: (item, itemProps) => (
            <MenuItem
              icon={item.id === right.company.id ? IconNames.SMALL_TICK : ""}
              onClick={itemProps.handleClick}
              key={item.id}
              text={item.displayName}
            />
          ),
          itemPredicate: (query, item) => {
            return includes(item.displayName.toLowerCase(), query.toLowerCase());
          },
          itemsEqual: (itemA, itemB) => itemA.id === itemB.id,
          inputValueRenderer: (item) => item.displayName,
          noResults: <MenuItem disabled={true} text="No results found" />,
          errorMessage: "Select a valid Company",
          error: hasEditError && !right.company.id,
        },
        {
          type: constants.FORM_FIELD_TYPE.SELECT,
          title: "Rights",
          label: "",
          list: rights.map((item) => item.name),
          selectedList: filteredRights ? [filteredRights.name] : [],
          placeHolder: "Select Right",
          singleSelect: true,
          error: hasEditError && !filteredRights,
          errorMessage: "Select Valid Right",
          showLabelInButton: false,
          onSelect: ([value]) =>
            this.inputOnChange(
              {
                id: rights.filter((item) => item.name === value)[0].name,
                name: rights.filter((item) => item.name === value)[0].name,
              },
              "right"
            ),
          usePortal: false,
        },
        {
          type: constants.FORM_FIELD_TYPE.DATE,
          title: "Valid From",
          error: hasEditError && !right.validity.fromDate,
          errorMessage: "Enter Valid Date",
          invalidDateMessage: "Invalid From   Date",
          onChange: (v) => {
            this.onDateChange(moment(v).format("YYYY-MM-DD"), moment(v).format("hh:mm"), [
              "fromDate",
              "startTime",
            ]);
          },
          value:
            right.validity.fromDate && right.validity.startTime
              ? new Date(`${right.validity.fromDate} ${right.validity.startTime}`)
              : undefined,
          placeholder: "MMM DD, YYYY",
        },
        {
          type: constants.FORM_FIELD_TYPE.DATE,
          title: "Valid To",
          error: hasEditError && !right.validity.toDate,
          errorMessage: "Enter Valid Date",
          invalidDateMessage: "Invalid To Date",
          onChange: (v) => {
            if (moment(v).isAfter(right.validity.fromDate)) {
              this.onDateChange(moment(v).format("YYYY-MM-DD"), moment(v).format("hh:mm"), [
                "toDate",
                "endTime",
              ]);
            }
          },
          value:
            right.validity.toDate && right.validity.endTime
              ? new Date(`${right.validity.toDate} ${right.validity.endTime}`)
              : undefined,

          placeholder: "MMM DD, YYYY",
        },
      ];
    } else {
      return [
        {
          type: constants.FORM_FIELD_TYPE.BP_SUGGEST,
          title: "Company",
          items: companies,
          selectedItem: companies.filter((company) => company.id === right.company.id)[0] || null,
          inputProps: {
            placeholder: "Search for Company",
          },
          onItemSelect: (item) => {
            this.inputOnChange({ id: item.id, name: item.displayName }, "company");
          },
          itemRenderer: (item, itemProps) => (
            <MenuItem
              icon={item.id === right.company.id ? IconNames.SMALL_TICK : ""}
              onClick={itemProps.handleClick}
              key={item.id}
              text={item.displayName}
            />
          ),
          itemPredicate: (query, item) => {
            return includes(item.displayName.toLowerCase(), query.toLowerCase());
          },
          itemsEqual: (itemA, itemB) => itemA.id === itemB.id,
          inputValueRenderer: (item) => item.displayName,
          noResults: <MenuItem disabled={true} text="No results found" />,
          errorMessage: "Select a valid Company",
          error: hasEditError && !right.company.id,
        },
        {
          type: constants.FORM_FIELD_TYPE.SELECT,
          title: "Rights",
          label: "",
          list: rights.map((item) => item.name),
          selectedList: filteredRights ? [filteredRights.name] : [],
          placeHolder: "Select Right",
          singleSelect: true,
          error: hasEditError && !filteredRights,
          errorMessage: "Select Valid Right",
          showLabelInButton: false,
          onSelect: ([value]) =>
            this.inputOnChange(
              {
                id: rights.filter((item) => item.name === value)[0].name,
                name: rights.filter((item) => item.name === value)[0].name,
              },
              "right"
            ),
          usePortal: false,
        },
      ];
    }
  };

  render = () => {
    const { isOpen, rights, companies } = this.props;
    const { right, hasEditError } = this.state;

    if (!right.validity) return null;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header="RightPanelHeader.assignRights"
        showFooter={true}
        isEdit={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form config={this.renderRightFields(rights, companies, right, hasEditError)} />
      </RightPanel>
    );
  };
}
