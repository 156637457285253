import moment from "moment";

const formatValidityPeriod = (validity, format = "DD MMM YYYY, h:mma") => {
  if (!validity || !validity?.fromDate || !validity?.toDate) return null;

  const fromDate = validity.startTime
    ? `${validity.fromDate} ${validity.startTime}`
    : `${validity.fromDate}`;
  const toDate = validity.endTime ? `${validity.toDate} ${validity.endTime}` : `${validity.toDate}`;

  return [
    moment(fromDate).format(validity.startTime ? format : "DD MMM YYYY"),
    moment(toDate).format(validity.endTime ? format : "DD MMM YYYY"),
  ].join(" - ");
};

export default formatValidityPeriod;
