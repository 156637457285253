import { SCOPES } from "../constants";
import { checkScopeAuth, isSlateAdminCompanyUser } from ".";

const checkScopes = (columns, user) => {
  if (!user) return columns;
  const isAdmin = isSlateAdminCompanyUser(user);
  return columns.filter((column) => {
    const { scope = SCOPES.__SELF__, scopeAction, onlyInAdmin } = column;
    return (!onlyInAdmin || isAdmin) && checkScopeAuth(user, scope, scopeAction);
  });
};

export default checkScopes;
