/*
1.It takes in an array of iconProps as a prop, each defining the properties of an icon button (tooltip text, click handler, etc).

2.For each iconProp, if a dropdown array is defined, it will render the icon as a dropdown button with a menu populated by the dropdown items.

3.The dropdownIcon method generates the dropdown button and menu for a given iconProp.

4.The menuItem method generates the MenuItem components that populate the dropdown menu.

5.The main component renders the icon buttons by mapping through the iconProps array, conditionally rendering either a regular icon button or a dropdown button if a dropdown is defined.

6.It sets the tooltip position based on the icon's index to adjust for the last item.
*/
import React from "react";
import { Link } from "react-router-dom";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import styles from "../../styles/ActionIcon.module.scss";
import DropdownStyles from "../../styles/Dropdown.module.scss";
import TooltipButton from "./TooltipButton";

const menuItem = (item, key) => {
  const props = omit(item, ["hideMenuItem"]);
  if (item.url) {
    return (
      <Link key={key} to={item.url} draggable={false}>
        <MenuItem className={DropdownStyles.dropdownMenuItem} {...props} />
      </Link>
    );
  }
  return <MenuItem key={key} className={DropdownStyles.dropdownMenuItem} {...props} />;
};

const dropdownIcon = (icon, dropdown, scopeAction, key, toolTipPosition) => {
  const dropdownMenu = (
    <Menu className={DropdownStyles.dropdownMenu}>
      {dropdown.map((item, key) => menuItem(item, key))}
    </Menu>
  );

  return (
    <div className={DropdownStyles.popoverContainer} key={key}>
      <Popover content={dropdownMenu} position={Position.BOTTOM_RIGHT} minimal={true}>
        <TooltipButton toolTipPosition={toolTipPosition} {...icon} />
      </Popover>
    </div>
  );
};

const ActionIcon = ({ iconProps }) => (
  <div
    className={`flex flex-auto justify-center ${styles.wrapper}`}
    onClick={(e) => e.stopPropagation()}
  >
    {iconProps.map(({ scopeAction, dropdown, ...rest }, i) => {
      let toolTipPosition = Position.TOP;
      // Adjust position if it is the last icon in the cell
      if (i === iconProps.length - 1) toolTipPosition = Position.TOP_RIGHT;
      if (dropdown) {
        const dropDownList = dropdown.filter((item) => !item.hideMenuItem);
        return dropdownIcon(rest, dropDownList, scopeAction, i, toolTipPosition);
      }
      return <TooltipButton key={i} toolTipPosition={toolTipPosition} {...rest} />;
    })}
  </div>
);
ActionIcon.propTypes = {
  iconProps: PropTypes.arrayOf(
    PropTypes.shape({
      toolTip: PropTypes.string,
      onClick: PropTypes.func,
      className: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
      dropdown: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          onClick: PropTypes.func,
          className: PropTypes.string,
          icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
        })
      ),
    })
  ),
};

ActionIcon.defaultProps = {
  iconProps: [],
};

export default ActionIcon;
