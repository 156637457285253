import React from "react";
import { Table } from "workbench";
import classNames from "classnames";
import { titleCase } from "../../../../utils";
import pageStyles from "../../../../styles/App.module.scss";

const fetchRoles = (roles) => (roles.length ? roles.map((i) => titleCase(i.code)).join(", ") : "");

export const Users = ({ company }) => {
  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: (d) => `${d.name} ${d.last_name}`,
    },
    {
      id: "role",
      Header: "Role",
      accessor: (d) => fetchRoles(d.role_in_products),
    },
    {
      id: "email",
      Header: "Email",
      accessor: "contact[email]",
    },

    {
      id: "phone_number",
      Header: "Phone Number",
      accessor: (d) =>
        d.contact?.phone_number ? `+${d.contact.country_code} ${d.contact.phone_number}` : "-",
    },
  ];

  return (
    <div className={pageStyles.pageContainer}>
      <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
        <Table sortable={false} data={company.users} columns={columns} />
      </div>
    </div>
  );
};

export default Users;
