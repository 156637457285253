import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch } from "../../../../utils";
import * as constants from "../../../../constants";
import styles from "../../../../styles/Lists/Lists.module.scss";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import HeaderButton from "../../../common/HeaderButtons";
import TabView from "../../../common/Tabs";
import Taxes from "./Taxes";
import Certification from "./Certifications";

const COUNTRY_TAX_TABS = {
  taxes: "taxes",
  certification: "certification",
};

const links = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.countriesAndTaxes", path: "/lists/countries" },
];

export class CountriesPage extends Component {
  static propTypes = { countries: PropTypes.object };
  componentDidMount = async () => {
    const {
      match: { params },
      actions,
    } = this.props;
    await actions.getCountry(params.countryId);
    this.getTaxandCertification();
  };
  componentWillUnmount = () => {
    this.cancelTaxAndCertificationEdit();
  };
  getTaxandCertification = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    if (params.action && params.tabId === COUNTRY_TAX_TABS.taxes) {
      if (params.action === "new") this.addTax();
      else actions.editTaxById(params.countryId, params.action);
    } else if (params.action && params.tabId === COUNTRY_TAX_TABS.certification) {
      if (params.action === "new") this.addCertification();
      else actions.editCertificationById(params.countryId, params.action);
    }
    actions.getContentTypesList();
    actions.getCountryTaxes(params.countryId);
    actions.fetchCountryCertificate(params.countryId);
  };

  addTax = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.addTax();
    history.push(`/lists/countries/${params.countryId}/taxes/new`);
  };

  editTax = (countryId, taxId) => {
    const { actions, history } = this.props;
    actions.editTaxById(countryId, taxId);
    history.push(`/lists/countries/${countryId}/taxes/${taxId}`);
  };
  cancelEditTax = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.cancelTaxEdit();
    history.push(`/lists/countries/${params.countryId}/taxes`);
  };

  addCertification = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.addCertification();
    history.push(`/lists/countries/${params.countryId}/certification/new`);
  };

  editCertification = (countryId, contentTypeId) => {
    const { actions, history } = this.props;
    actions.editCertificationById(countryId, contentTypeId);
    history.push(`/lists/countries/${countryId}/certification/${contentTypeId}`);
  };

  cancelEditCertification = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    actions.cancelCertificationEdit();
    history.push(`/lists/countries/${params.countryId}/certification`);
  };

  cancelTaxAndCertificationEdit = () => {
    const { actions } = this.props;
    actions.cancelTaxEdit();
    actions.cancelCertificationEdit();
  };

  addNewField = () => {
    const {
      match: { params },
    } = this.props;
    if (params.tabId === "taxes") {
      this.addTax();
    } else if (params.tabId === "certification") {
      this.addCertification();
    }
  };

  render = () => {
    const {
      contentType,
      countries: { isLoading, country, taxes, tax, certifications, certification },
      history,
      match: { params },
      actions,
      userData,
    } = this.props;

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={[...links, { name: country.name }]} />
        <PageHeader
          name={country.name}
          isLoading={isLoading}
          renderRightSideComponent={() => (
            <HeaderButton
              logsButton
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.COUNTRIES}`}
              addButton={true}
              addButtonScope={constants.SCOPES.COUNTRIES}
              addButtonScopeAction={constants.SCOPE_ACTIONS.WRITE}
              addNewField={this.addNewField}
              userData={userData}
            />
          )}
        />
        <TabView
          className={styles.tabs}
          onChange={this.cancelTaxAndCertificationEdit}
          animate={true}
          id="navbar"
          large={true}
          selectedTabId={params.tabId}
          tabs={[
            {
              id: "taxes",
              title: "Taxes",
              goto: `/lists/countries/${country.id}/taxes`,
              panel: (
                <Taxes
                  countryId={params.countryId}
                  taxes={taxes}
                  tax={tax}
                  history={history}
                  isLoading={isLoading}
                  actions={actions}
                  params={params}
                  userData={userData}
                  addTax={this.addTax}
                  editTax={this.editTax}
                  cancelEdit={this.cancelEditTax}
                />
              ),
            },
            {
              id: "certification",
              title: "Certification",
              goto: `/lists/countries/${country.id}/certification`,
              panel: (
                <Certification
                  countryId={params.countryId}
                  data={certifications}
                  certification={certification}
                  contentType={contentType}
                  actions={actions}
                  history={history}
                  isLoading={isLoading}
                  params={params}
                  userData={userData}
                  addCertification={this.addCertification}
                  editCertification={this.editCertification}
                  cancelEdit={this.cancelEditCertification}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.contentType,
  (state) => state.countries,
  (state) => state.userData,
  (contentType, countries, userData) => ({ contentType, countries, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CountriesPage);
