import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip, Position } from "@blueprintjs/core";
import { Icons } from "prefab";
import { STATUS_ICON_COLORS, STATUS_ICON_TYPES } from "../../constants";
import styles from "../../styles/StatusIcon.module.scss";
import tooltipStyles from "../../styles/ToolTip.module.scss";

const {
  StatusIcon: StatusIconSVG,
  MoviesIcon: MoviesIconSVG,
  DurationIcon: DurationIconSVG,
  LogsIcon: LogsIconSVG,
  ContentUnavailableIcon: ContentUnavailableIconSVG,
} = Icons;

const getColorClassName = (color) => {
  switch (color) {
    case STATUS_ICON_COLORS.GREEN:
      return styles.green;
    case STATUS_ICON_COLORS.LIGHT_BLUE:
      return styles.lightBlue;
    case STATUS_ICON_COLORS.GREY:
      return styles.grey;
    case STATUS_ICON_COLORS.YELLOW:
      return styles.yellow;
    case STATUS_ICON_COLORS.RED:
      return styles.red;
    case STATUS_ICON_COLORS.PURPLE:
      return styles.purple;
    case STATUS_ICON_COLORS.LIGHT_GREY:
      return styles.lightGrey;
    case STATUS_ICON_COLORS.BLUE:
      return styles.blue;
    default:
      return styles.primary;
  }
};

const getStatusIconSVG = (type) => {
  switch (type) {
    case STATUS_ICON_TYPES.CIRCLE:
      return <StatusIconSVG />;
    case STATUS_ICON_TYPES.MOVIES:
      return <MoviesIconSVG />;
    case STATUS_ICON_TYPES.DURATION:
      return <DurationIconSVG />;
    case STATUS_ICON_TYPES.LOGS:
      return <LogsIconSVG />;
    case STATUS_ICON_TYPES.CONTENT_UNAVAILABLE:
      return <ContentUnavailableIconSVG />;
    default:
      return;
  }
};

const StatusIcon = ({
  color,
  type,
  showToolTip,
  toolTip,
  toolTipPosition = Position.TOP,
  toolTipClass = "",
}) => {
  if (!showToolTip)
    return (
      <div className={`${styles.icon} ${getColorClassName(color)} ${styles.iconContainer}`}>
        {getStatusIconSVG(type)}
      </div>
    );
  return (
    <Tooltip
      className={classNames(toolTipClass, tooltipStyles.toolTip)}
      boundary={true}
      position={toolTipPosition}
      content={<span>{toolTip}</span>}
      usePortal={false}
    >
      <div className={`${styles.icon} ${getColorClassName(color)}`}>{getStatusIconSVG(type)}</div>
    </Tooltip>
  );
};

StatusIcon.propTypes = {
  color: PropTypes.oneOf([...Object.values(STATUS_ICON_COLORS), ""]),
  type: PropTypes.oneOf(Object.values(STATUS_ICON_TYPES)),
  showToolTip: PropTypes.bool,
  toolTip: PropTypes.string,
};

StatusIcon.defaultProps = {
  color: "",
  type: STATUS_ICON_TYPES.CIRCLE,
  showToolTip: false,
  toolTip: "",
};
export default StatusIcon;
