import { getCategoryLists, saveCategory, getCategory } from "../../api";
import * as types from "../types/categories";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const updateCategoryList = (payload) => {
  return { type: types.CATEGORIES, payload: payload };
};

const actionCreator = (type, payload) => {
  return { type, payload };
};

const editCategory = (category) => async (dispatch) => {
  dispatch(
    actionCreator(types.CATEGORY_EDIT, {
      category,
      isEditOpen: true,
      isEditError: true,
    })
  );
};

const addCategory = () => async (dispatch) => {
  dispatch(
    actionCreator(types.CATEGORY_CREATE, {
      isCreateOpen: true,
      isCreateError: false,
      category: {
        categoryId: "",
        categoryName: "",
        subCategory1Id: "",
        subCategory1Name: "",
        subCategory2Id: "",
        subCategory2Name: "",
      },
    })
  );
};

const cancelCreateCategory = () => (dispatch) => {
  dispatch(
    actionCreator(types.CATEGORY_CREATE, {
      isCreateOpen: false,
      isCreateError: false,
      category: {
        categoryId: "",
        categoryName: "",
        subCategory1Id: "",
        subCategory1Name: "",
        subCategory2Id: "",
        subCategory2Name: "",
      },
    })
  );
};

const cancelEditCategory = () => (dispatch) =>
  dispatch(
    actionCreator(types.CATEGORY_EDIT, {
      isEditOpen: false,
      category: {},
      isEditError: false,
    })
  );

const editCategoryById = (categoryId) => {
  return async (dispatch) => {
    try {
      const { data } = await getCategory(categoryId);
      dispatch(editCategory(data));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getCategories = (params, filters = [], getList = getCategoryLists, isPartial = true) => {
  const {
    page = 1,
    ps = constants.DEFAULT_PAGE_SIZE,
    sort = "categoryName:asc,subCategory1Name:asc,subCategory2Name:asc",
  } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateCategoryList(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const saveCategoryWithSubCategories = (category, fetchList, closePanel, errorMessage) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));

      if (category.categoryId && category.subCategory1Id === "") {
        await saveCategory(createPayload(category));
      } else if (category.subCategory1Id && category.subCategory2Id === "") {
        await saveCategory(createPayload(category, "subCategory1", category.categoryId));
      } else {
        await saveCategory(createPayload(category, "subCategory2", category.subCategory1Id));
      }

      showToast(
        category.categoryId ? "Toast.productCategoryUpdated" : "Toast.productCategoryAdded"
      );
      fetchList && fetchList();
      closePanel();
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      errorMessage(error.response.data.message);
      showToast(error.response.data.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.response.data.message));
      dispatch(actionCreator(types.IS_LOADING, false));
      return error;
    }
  };
};

const createPayload = (category, type = "category", parentId) => ({
  name: category[`${type}Name`],
  id: category[`${type}Id`],
  type: constants.CATEGORY_TYPE_MAP[type],
  parentId: parentId,
});

const createCategory = (category, fetchList, closePanel, errorMessage) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      if (category.categoryId.length <= 0) {
        await saveCategory(createPayload(category));
      } else if (category.subCategory1Id.length <= 0) {
        await saveCategory(createPayload(category, "subCategory1", category.categoryId));
      } else if (category.subCategory2Id.length <= 0)
        await saveCategory(createPayload(category, "subCategory2", category.subCategory1Id));

      showToast("Toast.productCategoryAdded", true);
      fetchList && fetchList();
      closePanel();
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      errorMessage(error.response.data.message);
      showToast(error.response.data.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.response.data.message));
      dispatch(actionCreator(types.IS_LOADING, false));
      return error;
    }
  };
};

export {
  updateCategoryList,
  getCategories,
  saveCategoryWithSubCategories,
  editCategory,
  createCategory,
  addCategory,
  cancelCreateCategory,
  cancelEditCategory,
  editCategoryById,
};
