import React, { Component } from "react";
import ReactGA from "react-ga";
import { findIndex } from "lodash";
import styles from "../../styles/ColumnSelector.module.scss";
import RightPanel from "./RightPanel";
import Checkbox from "./Checkbox";

var placeholder = document.createElement("div");
placeholder.className = styles.placeholder;
placeholder.ref = (input) => {
  this.placeholder = input;
};

class ColumnSelector extends Component {
  state = {
    selectedColumnList: this.props.selectedColumns ? this.props.selectedColumns.slice() : [],
    list: this.props.reorderedColumns
      ? this.props.reorderedColumns.map((c) => this.props.columns.find((col) => col.Header === c))
      : this.props.columns
      ? this.props.columns.slice()
      : [],
    events: [],
  };

  findSelectedIndex = (list, item) => {
    if (list.length === 0) return -1;
    if (item.id) return findIndex(list, item);
    return list.indexOf(item);
  };

  trackEvent = (change, columnName) => {
    const { events } = this.state;
    const eventIndex = events.map((e) => e.columnName).indexOf(columnName);
    if (eventIndex > -1) events.splice(eventIndex, 1);
    else events.push({ change, columnName });
    this.setState(events);
  };

  onItemSelect(item) {
    const { selectedColumnList } = this.state;
    const selected = selectedColumnList;
    const selectedIndex = this.findSelectedIndex(selected, item);
    if (selectedIndex > -1) {
      selected.splice(selectedIndex, 1);
      this.trackEvent("remove", item);
    } else {
      selected.push(item);
      this.trackEvent("add", item);
    }
    this.setState({ selectedColumnList: selected });
  }

  cancelEdit = () => {
    const { cancelEdit, selectedColumns } = this.props;
    cancelEdit();
    this.setState({ selectedColumnList: [...selectedColumns] });
  };

  onHandleApply = () => {
    const { onColumnFilterSelect, onHandleApply } = this.props;
    const { list, selectedColumnList, events } = this.state;
    onHandleApply();
    onColumnFilterSelect(
      list.filter((c) => selectedColumnList.includes(c.Header)).map((c) => c.Header),
      list.map((c) => c.Header)
    );
    events.forEach((e) =>
      ReactGA.event({ category: "column-select", action: e.columnName, label: e.change })
    );
  };

  dragStart = (e) => {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", this.dragged);
  };

  dragEnd = (e) => {
    this.dragged.style.display = "block";
    this.dragged.parentNode.removeChild(placeholder);
    const length = this.state.list.length - 1;
    var data = this.state.list;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if (to === length) data.splice(to, 0, data.splice(from, 1)[0]);
    else if (from < to) to--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({ list: data });
  };

  dragOver = (e) => {
    e.preventDefault();
    this.dragged.setAttribute("style", "display: none !important");
    if (e.target.parentNode.className === "" && e.target.ref === undefined) {
      this.over = e.target;
      e.target.parentNode.insertBefore(placeholder, e.target);
    }
  };

  handleRightPanelOpen = () => this.setState({ isOpen: true });

  render() {
    return (
      <RightPanel
        isOpen
        onClose={this.cancelEdit}
        header={"RightPanelHeader.editColumn"}
        showFooter
        isEdit
        primaryButtonProps={{
          text: "Button.apply",
          onClick: this.onHandleApply,
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <div onDragEnter={(e) => e.preventDefault()} onDragOver={this.dragOver}>
          {this.state.list
            .filter((c) => c.Header)
            .map((c) => c.Header)
            .map((item, i) => (
              <div
                data-id={i}
                key={i}
                className={styles.checkbox}
                draggable="true"
                onDragStart={this.dragStart}
                onDragEnd={this.dragEnd}
              >
                <Checkbox
                  data-id={i}
                  key={i}
                  label={item}
                  checked={this.state.selectedColumnList.includes(item) ? true : false}
                  onChange={() => this.onItemSelect(item)}
                  checkBoxRight={true}
                  DragDropIcon={true}
                />
              </div>
            ))}
          <div className={styles.hideText} data-id={this.state.list.length}>
            end
          </div>
        </div>
      </RightPanel>
    );
  }
}

export default ColumnSelector;
