import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isNumber, isArray, isEmpty } from "lodash";
import styles from "../../../styles/ScrollableSummary.module.scss";
import TextWithLabel from "../TextWithLabel";
import Card from "../InfoBlock/Card";

class ScrollableSummary extends Component {
  static propTypes = {
    config: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    config: [],
  };

  getCardSize = (size) => {
    if (isNumber(size)) {
      return {
        lg: size,
        md: size,
        sm: size,
        xs: size,
      };
    }
    return size;
  };

  renderField = (title, value, size, index) => {
    if (value) {
      return (
        <Card key={index} {...this.getCardSize(size)} className={styles.card}>
          <TextWithLabel label={title} text={value} />
        </Card>
      );
    }
    return null;
  };

  renderCards = () => {
    const { config } = this.props;
    return config?.map((item, i) => {
      if (isArray(item)) {
        return (
          <div key={i}>
            {item?.map(({ title, value, size }, index) => {
              if (isEmpty(value)) return null;
              return this.renderField(title, value, size, index);
            })}
          </div>
        );
      } else {
        const { title, value, size } = item;
        return this.renderField(title, value, size, i);
      }
    });
  };

  render() {
    const { config, children, childClassName } = this.props;

    return (
      <div className={classNames(childClassName, styles.cardContainer)}>
        {config.length > 0 ? this.renderCards() : children}
      </div>
    );
  }
}

export default ScrollableSummary;
