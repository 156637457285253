import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "../../../../styles/InfoBlock.module.scss";
import InfoBlock from "../../../common/InfoBlock";
import RightPanel from "../../../common/RightPanel";

const AvailabilityDetails = ({ selectedRowData }) => {
  if (!selectedRowData) return null;

  const {
    name,
    theatreName,
    cityName,
    provinceName,
    countryName,
    targetGroupName,
    mediaName,
    daysUnavailable,
  } = selectedRowData;

  return (
    <div className={styles.primary}>
      <div className={styles.block}>
        <h3 className={styles.label}>Screen</h3>
        <p className={styles.contactInfo}>
          #{selectedRowData.number} Screen {name}
        </p>
        <p className={styles.subTitle}>Cinemas: {theatreName}</p>
        <p className={styles.subTitle}>
          {cityName}, {provinceName}, {countryName}
        </p>
      </div>

      <div className={styles.block}>
        <h3 className={styles.label}>Target Group</h3>
        <p className={styles.subTitle}>{targetGroupName}</p>
      </div>

      <div className={styles.block}>
        <h3 className={styles.label}>Media</h3>
        <p className={styles.subTitle}>{mediaName}</p>
      </div>

      {daysUnavailable && daysUnavailable.length > 0 && (
        <div className={styles.block}>
          <h3 className={styles.label}>Days Unavailable</h3>
          {daysUnavailable?.map((item) => (
            <div key={item.day}>
              <p className={styles.subTitle}>{moment(item.day).format("DD MMM YYYY")}</p>
              <p className={styles.subTitle}>{item.spots} Spots</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

AvailabilityDetails.propTypes = {
  selectedRowData: PropTypes.object,
};
export default function AvailabilityDetailsPanel({ isOpen, onClose, header, selectedRowData }) {
  const handleFormCancel = () => {
    if (onClose) {
      onClose();
    }
  };
  const renderPanelContent = () => {
    return (
      <InfoBlock hideHeader cardType="secondary">
        <AvailabilityDetails selectedRowData={selectedRowData} />
      </InfoBlock>
    );
  };

  return (
    <RightPanel isOpen={isOpen} onClose={handleFormCancel} header={header} showFooter={false}>
      {renderPanelContent(selectedRowData)}
    </RightPanel>
  );
}

AvailabilityDetailsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  selectedRowData: PropTypes.object,
};
