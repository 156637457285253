import React, { Component } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificate: {},
      hasEditError: false,
    };
  }

  static propTypes = {
    certificate: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.setState({
      certificate: this.props.certificate,
    });
  };

  cancelEdit = () => {
    this.setState({
      hasEditError: false,
      certificate: {
        contentTypeId: "",
        hasCertification: false,
      },
    });
    this.props.onClose();
  };
  isNotValid = () => {
    const { certificate } = this.state;
    const { contentTypes } = this.props;
    return contentTypes.find((c) => c.id === certificate.contentTypeId) ? false : true;
  };

  save = () => {
    const { certificate } = this.state;
    if (this.isNotValid()) return this.setState({ hasEditError: true });
    this.props.onSave(certificate);
    this.cancelEdit();
  };
  onSelect = (value) => {
    const { contentTypes } = this.props;
    const { certificate } = this.state;
    this.setState({
      certificate: {
        ...certificate,
        contentTypeId: contentTypes.find((c) => c.name === value).id,
      },
    });
  };
  onClick = (v) => {
    const { certificate } = this.state;
    this.setState({
      certificate: { ...certificate, hasCertification: v === "true" },
    });
  };
  render = () => {
    const { isOpen, contentTypes } = this.props;
    const { certificate = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={
          certificate.updatedAt
            ? "RightPanelHeader.editCertification"
            : "RightPanelHeader.addCertification"
        }
        isEdit={certificate.contentTypeId ? true : false}
        showFooter={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: certificate.contentTypeName ? FORM_FIELD_TYPE.LABEL : FORM_FIELD_TYPE.SELECT,
              title: "Media Type",
              label: "",
              list: map(contentTypes, "name"),
              value: certificate.contentTypeName,
              selectedList: contentTypes.find((c) => c.id === certificate.contentTypeId)
                ? [contentTypes.find((c) => c.id === certificate.contentTypeId).name]
                : [],
              placeHolder: "Select Media",
              singleSelect: true,
              error: hasEditError,
              errorMessage: "Please Select Media type",
              compareKey: "id",
              textKey: "name",
              showLabelInButton: false,
              onSelect: ([e]) => this.onSelect(e),
              usePortal: false,
            },
            {
              type: FORM_FIELD_TYPE.RADIO,
              title: "Certification Applicable",
              value: certificate.hasCertification,
              radioList: [
                { label: "Yes", value: true },
                { label: "No", value: false },
              ],
              onChange: (v) => this.onClick(v),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
