import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import { DateRangeInput } from "@blueprintjs/datetime";
import styles from "../../styles/DatePicker.module.scss";
import { MAX_DATE } from "../../constants";

export default class DateRangePicker extends Component {
  state = {
    focusDate: moment().toDate(),
  };
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    dateRange: PropTypes.array.isRequired,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    handleDateChange: PropTypes.func.isRequired,
    defaultIsOpen: PropTypes.bool,
    popoverProps: PropTypes.object,
  };

  static defaultProps = {
    placeholder: "DD/MM/YYYY",
    defaultIsOpen: false,
    popoverProps: { usePortal: false },
  };

  formatDate = (date, format = "DD/MM/YYYY", locale = "en") => {
    return moment(date)
      .locale(locale)
      .format(Array.isArray(format) ? format[0] : format);
  };

  parseDate = (str, format = "DD/MM/YYYY", locale = "en") => {
    const m = moment(str, format, locale, true);
    if (m.isValid()) {
      return m.toDate();
    }
    return undefined;
  };
  onStartDateFocus = () => {
    this.setState({
      focusDate: this.props.dateRange[0] ? this.props.dateRange[0] : moment().toDate(),
    });
  };
  onEndDateFocus = () => {
    this.setState({
      focusDate: this.props.dateRange[1] ? this.props.dateRange[1] : moment().toDate(),
    });
  };

  render() {
    const {
      placeholder,
      handleDateChange,
      dateRange,
      minDate,
      maxDate,
      popoverProps,
      className,
      ...rest
    } = this.props;
    return (
      <DateRangeInput
        className={classNames(styles.dateRangePicker, className, {
          [styles.error]: rest.error,
          [styles.disabled]: rest.disabled,
        })}
        dayPickerProps={{ className: styles.daypicker }}
        placeholder={placeholder}
        formatDate={this.formatDate}
        parseDate={this.parseDate}
        shortcuts={false}
        singleMonthOnly
        allowSingleDayRange
        onChange={handleDateChange}
        contiguousCalendarMonths={false}
        value={dateRange}
        initialMonth={this.state.focusDate}
        startInputProps={{ onFocus: this.onStartDateFocus }}
        endInputProps={{ onFocus: this.onEndDateFocus }}
        minDate={minDate}
        //SLATE-1425 Dec7 Anushree:- if maxDate is not passed, then it will take default MAX_DATE
        //maxDate={maxDate}
        maxDate={maxDate ? maxDate : new Date(MAX_DATE)}
        popoverProps={popoverProps}
        {...rest}
      />
    );
  }
}
