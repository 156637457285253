import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Icons } from "prefab";
import React, { Component } from "react";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTabs from "../../common/PageLoadingWithTabs";
import { bindDispatch } from "../../../utils";
import PageHeader from "../../common/PageHeader";
import TabView from "../../common/Tabs";
import GeneralSettings from "./Tabs/GeneralSettings";

const { SettingsIcon } = Icons;

export class Settings extends Component {
  render = () => {
    const {
      userData,
      companies: { thirdpartyIdentifiers },
      match: { params },
    } = this.props;

    const isLoading = isEmpty(userData);

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={3} />;
    }

    return (
      <div>
        <PageHeader
          headerIcon={<SettingsIcon />}
          name="PageHeader.companySettings"
          isLoading={isLoading}
        />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <TabView
            id="navbar"
            selectedTabId={params.tabId}
            hideSingleTab
            tabs={[
              {
                id: "general",
                title: "General",
                goto: `/settings/general`,
                panel: <GeneralSettings thirdpartyIdentifiers={thirdpartyIdentifiers} />,
              },
            ]}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (companies, userData) => ({ companies, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Settings);
