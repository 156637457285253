import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import Media from "../../common/Media";

class CampaignMediaList extends Component {
  componentDidMount = () => {
    const {
      match: {
        params: { campaignId },
      },
    } = this.props;
    this.props.fetchData(null, true, [{ campaignId }]);
  };

  render = () => {
    const {
      campaigns: { campaign, campaignMedia, isTabLoading },
    } = this.props;

    return (
      <Media
        data={{ media: campaignMedia, isLoading: isTabLoading, targetGroupId: campaign.id }}
        {...this.props}
      />
    );
  };
}

export const CampaignMediaListWithFilters = WithFetchList("getCampaignMedia", {
  sort: [
    {
      id: "status",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(CampaignMediaList);

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(CampaignMediaListWithFilters));
