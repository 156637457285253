import PropTypes from "prop-types";
import React, { Component } from "react";
import FileUpload from "../../../common/FileUpload";
import RightPanel from "../../../common/RightPanel";
import styles from "../../../../styles/UploadCsv.module.scss";

export default class UploadScreensCSV extends Component {
  state = {
    uploadErrors: [],
    hasUploadError: false,
    isLoading: false,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    title: PropTypes.string,
    primaryButtonProps: PropTypes.object,
    uploadCSVFile: PropTypes.func.isRequired,
    matchedTheatreData: PropTypes.array,
    unmatchedTheatreData: PropTypes.array,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && !this.props.isOpen) {
      this.clearErrors();
    }
  }

  clearErrors = () => {
    this.setState({
      uploadErrors: [],
      hasUploadError: false,
    });
  };

  handleFormCancel = () => {
    this.props.onClose();
    this.clearErrors();
  };

  handleFileSelect = (file) => {
    this.setState({ isLoading: true });
    this.props
      .uploadCSVFile(file)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isOpen,
      header,
      title,
      primaryButtonProps,
      secondaryButtonProps,
      matchedTheatreData,
      unmatchedTheatreData,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={this.handleFormCancel}
        header={header}
        showFooter={true}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      >
        <FileUpload
          title={title}
          description="(or) Drag & drop CSV"
          errorMessage="Please upload a CSV file"
          icon="DownloadIcon"
          onSelect={this.handleFileSelect}
          acceptableFormats=".csv"
          mimeType="text/csv"
          isLoading={isLoading}
        />
        {isLoading ? (
          <div className={styles.loadingContainer}>Loading...</div>
        ) : (
          <div className={styles.uploadedFileContainer}>
            {matchedTheatreData && matchedTheatreData.length > 0 && (
              <>
                <h4>
                  {matchedTheatreData.length === 1 ? "ID" : "IDs"}:{" "}
                  <span
                    className={styles.validCount}
                  >{`(${matchedTheatreData.length} found)`}</span>
                </h4>
              </>
            )}
            {unmatchedTheatreData && unmatchedTheatreData.length > 0 && (
              <>
                <h4 className={styles.invalidCount}>
                  No matches found for the following {unmatchedTheatreData.length}{" "}
                  {unmatchedTheatreData.length === 1 ? "row" : "rows"}.
                </h4>
                <div className={styles.scrollableContainer}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th className={styles.tableHeader}>Row No.</th>
                        <th className={styles.tableHeader}>ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unmatchedTheatreData.map((data, index) => (
                        <tr key={index}>
                          <td className={styles.rowNumber}>{data.order}</td>
                          <td className={styles.invalidId}>
                            {data.request.id || data.request.screen_id}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        )}
      </RightPanel>
    );
  }
}
