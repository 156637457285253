import { getReports, getScheduledReports as getscheduledReports } from "../../api";
import * as types from "../types/reports";

const updateAdminReports = (payload) => ({ type: types.ADMIN_GENERATED, payload });
const updateScheduledReports = (payload) => ({ type: types.SCHEDULED_REPORTS, payload });
const updateUserReports = (payload) => ({ type: types.USER_GENERATED, payload });
const handleError = (payload) => ({ type: types.IS_ERROR, payload });
const handleLoading = (payload) => ({ type: types.IS_LOADING, payload });

const getAdminReports = (params, filters = [], get = getReports) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const data = await get(params, true);
      dispatch(updateAdminReports(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const getUserReports = (params, filters = [], get = getReports) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await get(params, false);
      dispatch(updateUserReports(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const getScheduledReports = (params, filters = [], get = getscheduledReports) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const data = await get(params, true);
      dispatch(updateScheduledReports(data.data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

export { getAdminReports, getUserReports, getScheduledReports };
