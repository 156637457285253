import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { BaseButton, SIZE } from "prefab";
import styles from "../../../../styles/Schedules/screenList.module.scss";
import { modifyTableColumns, bindDispatch, locationColumn } from "../../../../utils";
import ScreenTable from "../../../tables/Screen";
import WithFetchList from "../../../hoc/withFetchList";
import TableLoading from "../../../common/TableLoading";
import * as constants from "../../../../constants";
import { ActiveTableCell } from "../../../common/ActiveTableCell";

class ScheduleScreensList extends Component {
  state = {
    selectedColumns: ["Theatre • Screen", "Chain", "Location", "Seating Capacity"],
  };

  componentDidMount = () => {
    const {
      ps,
      page,
      sort,
      match: { params },
    } = this.props;
    if (this.props.isEdit)
      this.props.fetchData({ ps, page, sort, ...params }, false, [
        { companyId: this.props.user?.company?.id },
      ]);
  };

  getScreenColumns = () => {
    return [
      {
        id: "name",
        Header: "Theatre • Screen",
        accessor: (d) =>
          !this.props.isEdit ? (
            `${d.name}  ${d?.theatreName ? `· ${d.theatreName}` : ""}`
          ) : (
            <>
              <span>
                {ActiveTableCell(d.theatre.id, d.theatre.name, () =>
                  this.props.onFilterIdSelect(d.theatre.id)
                )}
                •{" "}
              </span>
              {ActiveTableCell(d.id, d.name, () => this.props.onFilterIdSelect(d.id))}
            </>
          ),
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "erpId",
        Header: "Screen ERP ID",
        accessor: "erpId",
        sortable: false,
      },
      {
        id: "davpCode",
        Header: "DAVP TH Code",
        accessor: "davpCode",
        sortable: false,
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (cellProps) => (
          <BaseButton
            className={styles.removeButton}
            iconName="RemoveFilledIcon"
            buttonSize={SIZE.REGULAR}
            onClick={(event) => {
              event.stopPropagation();
              this.props.removeScreen([cellProps.original.id]);
            }}
          />
        ),
      },
    ];
  };

  render = () => {
    const {
      isEdit,
      isFilterLoading,
      schedules: { scheduleScreens, isScreenLoading },
    } = this.props;
    const {
      SCREEN,
      SCREEN_DAVP_ID,
      SCREEN_ERP_ID,
      LOCATION,
      THEATRE,
      THEATRE_TYPE,
    } = constants.TAG_TYPE;

    if (isScreenLoading)
      return (
        <div className={styles.container}>
          <TableLoading
            style={{ backgroundColor: "#f5f8fa" }}
            columns={modifyTableColumns(
              this.getScreenColumns(),
              ["Theatre • Screen", "Chain", "Location", "Screen ERP ID", "DAVP TH Code"],
              []
            )}
          />
        </div>
      );

    return (
      <div className={styles.container}>
        <ScreenTable
          {...this.props}
          style={{ backgroundColor: "#f5f8fa" }}
          searchBar={isEdit && scheduleScreens.length > 0}
          showPagination={isEdit && scheduleScreens.length > 0}
          showRowSize={isEdit && scheduleScreens.length > 0}
          columnFilter={scheduleScreens.length > 0}
          screenList={{ data: scheduleScreens, totalCount: scheduleScreens.length }}
          isLoading={isFilterLoading}
          tagTypes={[SCREEN, SCREEN_DAVP_ID, SCREEN_ERP_ID, LOCATION, THEATRE, THEATRE_TYPE]}
          selectedColumns={["Screen ERP ID", "DAVP TH Code"]}
          columns={this.getScreenColumns()}
        />
      </div>
    );
  };
}

const ScheduleScreensWithFilters = WithFetchList("getScheduleScreenList", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(ScheduleScreensList);

export { ScheduleScreensList, ScheduleScreensWithFilters };

const mapStateToProps = createSelector(
  (state) => state.schedules,
  (state) => state.userData,
  (schedules, userData) => ({ schedules, user: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ScheduleScreensWithFilters);
