import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../../../../utils";
import { getScreenSettings } from "../../../../../../../api";
import InfoBlockLoading from "../../../../../../common/InfoBlockLoading";
import NotFound from "../../../../../../auth/NotFound";
import styles from "../../../../../../../styles/Inventory/Settings.module.scss";
import pageStyles from "../../../../../../../styles/App.module.scss";
import SettingsDetails from "./SettingsDetails";

class AdminSettings extends Component {
  state = {
    selectedScreen: {},
    isLoading: true,
  };

  componentDidMount = async () => {
    const {
      actions,
      match: { params },
    } = this.props;
    await actions.getContentTypesList();
    actions.getScreenSettings(params, getScreenSettings, true);

    this.setState({
      isLoading: false,
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      screens: { screenSettings },
    } = this.props;
    if (screenSettings !== prevProps.screens.screenSettings) {
      this.setState({ selectedScreen: screenSettings[0] });
    }
  };
  render = () => {
    const {
      actions,
      history,
      match,
      screens: { screen, screenSettings, isScreenSettingsLoading },
      userData,
      contentTypes,
    } = this.props;
    const { selectedScreen, isLoading } = this.state;

    if (isScreenSettingsLoading || isLoading)
      return (
        <div className={pageStyles.pageContainer}>
          <InfoBlockLoading />
        </div>
      );
    if (!isScreenSettingsLoading && !isLoading && screenSettings.length === 0)
      return <NotFound bodyMessage="EmptyState.noSettingsFound" isHomeLinkVisible={false} />;
    return (
      <div className={pageStyles.pageContainer}>
        <div className={styles.tabMenu}>
          {!isEmpty(screenSettings) &&
            screenSettings.map((data, i) => (
              <div
                key={i}
                className={classnames(styles.tabItems, {
                  [styles.isActive]: data.companyId === selectedScreen.companyId,
                })}
                onClick={() => this.setState({ selectedScreen: data })}
              >
                {data.companyName}
              </div>
            ))}
        </div>
        <div className={styles.settingDetails}>
          {selectedScreen && (
            <SettingsDetails
              match={match}
              history={history}
              screenSettings={selectedScreen}
              userData={userData}
              actions={actions}
              contentTypes={contentTypes}
              showEdit={false}
              screen={screen}
              isAdmin
            />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.contentType,
  (state) => state.userData,
  (screens, contentType, userData) => ({
    screens,
    contentTypes: contentType.data.data,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(AdminSettings));
