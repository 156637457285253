import React from "react";
import ScrollableSummary from "../../../common/ScrollableSummary";
import SummaryCardItem from "../../../common/SummaryCardItem";

const getScrollableSummaryBlock = (
  details,
  size = {
    xs: 12,
    sm: 4,
    md: 3,
    lg: 2,
  }
) => {
  return (
    <ScrollableSummary
      config={[
        {
          title: "Screens",
          value: (
            <SummaryCardItem
              primaryText={details?.noOfScreens}
              secondaryLabel={"Theatres: "}
              secondaryText={details?.noOfTheatres}
            />
          ),

          size,
        },
        {
          title: "Total Estimated Plays",
          value: (
            <SummaryCardItem primaryText={details?.totalEstimatedPlays} secondaryText={"INR 0"} />
          ),
          size,
        },
        {
          title: "Unavailable Plays",
          value: (
            <SummaryCardItem
              primaryText={details?.unavailablePlays}
              secondaryLabel={"Days: "}
              secondaryText={details?.unavailableDays}
            />
          ),
          size,
        },
        {
          title: "Estimated Reach",
          value: <SummaryCardItem primaryText={details?.reach} secondaryText={"INR 0"} />,
          size,
        },
        // {
        //   title: "Max Plays Per Screen",
        //   value: <SummaryCardItem primaryText={details?.maxPlaysPerScreen} />,
        //   size,
        // },
      ]}
    />
  );
};

export default getScrollableSummaryBlock;
