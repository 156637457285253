import classNames from "classnames";
import { includes } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import { getStatusIconLegend } from "../../../../../src/components/pages/campaigns/utils/index.js";
import { DEFAULT_PAGE_SIZE, SPECIAL_CHARACTERS, TAG_TYPE } from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import { bindDispatch, checkScopes, formatDateTime, modifyTableColumns } from "../../../../utils";
import { ActiveTableCell, ActiveTableCellRowFromArray } from "../../../common/ActiveTableCell";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import withFetchList from "../../../hoc/withFetchList.js";
import { getStatusDisplay } from "../utils";
import { safeFormatDateTime } from "../../../../utils/formatDateTime.js";

const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

const dayBeforeYesterday = new Date();
dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 7);

const today = new Date();

const getDefaultFilters = () => {
  return [
    {
      from: dayBeforeYesterday,
      id: "showDate",
      type: "showDate",
      displayName: `${formatDate(dayBeforeYesterday)}-${formatDate(today)}`,
      to: today,
    },
  ];
};

class ShowFailures extends Component {
  state = {
    selectedColumns: [
      "Theatre • Screen",
      "Location",
      "Date • Day",
      "Show Time",
      "Campaign Name",
      "Playlist • Playlist Pack • Segment",
      "Segment Type",
      "Position",
      "Content Status",
      "Schedule Status",
      "Play Log Status",
      "Target Group",
      "Media",
      "Content",
    ],
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      history,
      showFailures: { failureData },
      hiddenColumns,
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      onFilterChange,
    } = this.props;

    if (false) return <PageLoadingWithTable />;
    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);

    const columns = checkScopes(
      [
        {
          id: "screenName",
          Header: "Theatre • Screen",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.theatreId, d.theatreName, () =>
                this.props.onFilterIdSelect(d.theatreId)
              )}{" "}
              {SPECIAL_CHARACTERS.MIDDLE_DOT}
              {ActiveTableCell(d.screenId, d.screenName, () =>
                this.props.onFilterIdSelect(d.screenId)
              )}
            </>
          ),
          width: 200,
        },
        {
          id: "location",
          Header: "Location",
          accessor: (d) => (
            <>
              {ActiveTableCellRowFromArray(
                [
                  { id: d.cityId, name: d.cityName },
                  { id: d.provinceId, name: d.provinceName },
                  { id: d.countryId, name: d.countryName },
                ],
                this.props.onFilterIdSelect
              )}
            </>
          ),
          width: 200,
        },
        {
          id: "showDate",
          Header: "Date • Day",
          accessor: (d) => (
            <>
              <div> {formatDateTime(`${d.showDate}`, "DD MMM YYYY")}</div>
              <div style={{ color: "grey" }}>{d.day}</div>
            </>
          ),
          width: 145,
        },
        {
          id: "startTime",
          Header: "Show Time",
          accessor: (d) => (
            <>
              <div>{formatDateTime(d.startTime, "hh:mm A", true)}</div>
              <div style={{ color: "grey" }}>{d.show}</div>
            </>
          ),
          width: 145,
        },
        {
          id: "campaignName",
          Header: "Campaign Name",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.campaignId, d.campaignName, () =>
                this.props.onFilterIdSelect(d.campaignId)
              )}
            </>
          ),
          width: 200,
        },
        {
          id: "playList_playListPack_Segment",
          Header: "Playlist • Playlist Pack • Segment",
          accessor: (d) => (
            <>
              {
                <>
                  {d.playlistTemplateName} {SPECIAL_CHARACTERS.MIDDLE_DOT} {d.playlistPackName}{" "}
                  {SPECIAL_CHARACTERS.MIDDLE_DOT} {d.segmentName}
                </>
              }
            </>
          ),
          width: 270,
        },
        {
          id: "segmentTypeName",
          Header: "Segment Type",
          accessor: (d) => <>{d.segmentTypeName}</>,
          width: 170,
        },
        {
          id: "position",
          Header: "Position",
          accessor: (d) => <>{d.position}</>,
          width: 130,
        },
        {
          id: "contentStatus",
          Header: "Content Status",
          accessor: (d) =>
            getStatusDisplay(
              d.contentStatus,
              this.props.onFilterSelect,
              TAG_TYPE.CONTENT_STATUS,
              true
            ),

          width: 170,
        },
        {
          id: "scheduleStatus",
          Header: "Schedule Status",
          accessor: (d) => (
            <>
              {getStatusDisplay(
                d.scheduleStatus,
                this.props.onFilterSelect,
                TAG_TYPE.SCHEDULE_STATUS,
                true
              )}
              {d.scheduleStatus ? (
                <div style={{ color: "grey" }}>
                  {formatDateTime(`${d.scheduleUpdatedON}`, "DD MMM YYYY hh:mm A")}
                </div>
              ) : null}
            </>
          ),
          width: 175,
        },
        {
          id: "playLogStatus",
          Header: "Play Log Status",
          accessor: (d) => (
            <>
              {getStatusDisplay(
                d.playLogStatus,
                this.props.onFilterSelect,
                TAG_TYPE.PLAYLOG_STATUS,
                true
              )}
              {d.playLogStatus ? (
                <div style={{ color: "grey" }}>
                  {safeFormatDateTime(`${d.playlogUpdatedON}`, "DD MMM YYYY hh:mm A")}
                </div>
              ) : null}
            </>
          ),
          width: 175,
        },
        {
          id: "targetGroupName",
          Header: "Target Group",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.targetGroupID, d.targetGroupName, () =>
                this.props.onFilterIdSelect(d.targetGroupID)
              )}
            </>
          ),
          width: 160,
        },
        {
          id: "mediaName",
          Header: "Media",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.mediaID, d.mediaName, () =>
                this.props.onFilterIdSelect(d.mediaID)
              )}
            </>
          ),

          width: 140,
        },
        {
          id: "cplName",
          Header: "Content",
          accessor: (d) => (
            <>{ActiveTableCell(d.cplId, d.cplName, () => this.props.onFilterIdSelect(d.cplId))}</>
          ),
          width: 150,
        },
        {
          id: "contentTypeName",
          Header: "Content Type",
          accessor: (d) => <>{d.contentTypeName}</>,
          width: 170,
        },
        {
          id: "durationInSeconds",
          Header: "Duration",
          accessor: (d) => <>{d.durationInSeconds}</>,
          width: 150,
        },
      ],
      userData
    ).filter((column) => !includes(hiddenColumns, column.Header));

    return (
      <div>
        <PageHeader name="Show Failures" count={failureData.totalCount || 0} />
        <div className={classNames("col-12 clearfix", pageStyles.pageTableContainer)}>
          <div className={pageStyles.statusGroup}>
            {getStatusIconLegend(
              failureData?.data,
              TAG_TYPE.FAILURE_STATUS,
              this.props.onFilterSelect,
              "failureStatuses",
              true
            )}
          </div>
          <TableControls
            seletable={true}
            searchBar
            columnFilter
            showRowSize
            pagination
            columns={columns}
            selectedColumns={selectedColumns}
            reorderedColumns={reorderedColumns}
            data={failureData}
            query={query}
            ps={ps}
            page={page}
            tagTypes={[
              TAG_TYPE.SHOW_DATE,
              TAG_TYPE.CAMPAIGN,
              TAG_TYPE.TARGET_GROUP,
              TAG_TYPE.SCHEDULE_STATUS,
              TAG_TYPE.CONTENT_STATUS,
              TAG_TYPE.PLAYLOG_STATUS,
            ]}
            onSearchFilterSelect={this.props.onFilterSelect}
            onFilterChange={
              filters.length === 0 ? onFilterChange(getDefaultFilters()) : onFilterChange
            }
            onColumnFilterSelect={this.onColumnSelect}
            onRowSizeChange={onQueryChange}
            filters={filters}
          />
          <FilterChips
            selected={filters}
            showResultsCount
            resultsCount={failureData.totalCount || 0}
            onFilterChange={onFilterChange}
            defaultFilterId={TAG_TYPE.SHOW_DATE}
          />
          <Table
            data={failureData?.data}
            loading={isFilterLoading}
            columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            sorted={sort}
            onSortedChange={onSortedChange}
            LoadingComponent={
              <TableLoading
                columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              />
            }
          />
          <FooterControls
            pagination
            data={failureData}
            query={query}
            ps={ps}
            page={page}
            onRowSizeChange={onQueryChange}
          />
        </div>
      </div>
    );
  };
}

export const ShowFailuresListWithFilters = withFetchList("getFailuresData", {
  sort: [
    {
      id: "theatreName",
      desc: true,
    },
  ],
})(ShowFailures);
const mapStateToProps = createSelector(
  (state) => state.showFailures,
  (state) => state.userData,
  (showFailures, userData) => ({ showFailures, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ShowFailuresListWithFilters);
