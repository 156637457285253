import {
  fetchProductIdentificationNumberTypeList,
  saveProductIdentificationNumberType,
  getProductIdentificationNumberType,
} from "../../api";

import * as types from "../types/productIdentificationNumberNumberType";
import { showToast } from "../../utils";

const updateProductIdentificationNumberTypes = (payload) => ({ type: types.LIST, payload });

const handleError = (payload) => ({ type: types.IS_ERROR, payload });

const handleLoading = (payload) => ({ type: types.IS_LOADING, payload });

const handleEdit = (payload) => ({ type: types.EDIT, payload });

const getProductIdentificationNumberTypeList = (
  getList = fetchProductIdentificationNumberTypeList
) => {
  const ps = -1,
    offset = 0,
    sort = "type:asc";
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await getList(ps, offset, sort);
      dispatch(updateProductIdentificationNumberTypes(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error));
    }
  };
};

const editProductIdentificationNumberType = (productIdentificationNumberType) => (dispatch) =>
  dispatch(handleEdit({ productIdentificationNumberType, isOpen: true }));

const addProductIdentificationNumberType = () => (dispatch) =>
  dispatch(handleEdit({ isOpen: true, productIdentificationNumberType: { type: "" } }));

const cancelEditProductIdentificationNumberType = () => (dispatch) =>
  dispatch(handleEdit({ isOpen: false, productIdentificationNumberType: {} }));

const editProductIdentificationNumberTypeById = (
  productIdentificationNumberTypeId,
  get = getProductIdentificationNumberType
) => {
  return async (dispatch) => {
    try {
      const { data } = await get(productIdentificationNumberTypeId);
      dispatch(editProductIdentificationNumberType(data));
      dispatch(getProductIdentificationNumberTypeList());
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const createOrUpdateProductIdentificationNumberType = (
  productIdentificationNumberType,
  fetchList = getProductIdentificationNumberTypeList,
  save = saveProductIdentificationNumberType
) => {
  return async (dispatch) => {
    try {
      await save(productIdentificationNumberType);
      await fetchList();
      showToast(
        productIdentificationNumberType.id
          ? "Toast.productIdentificationNumberTypeUpdated"
          : "Toast.productIdentificationNumberTypeAdded"
      );
    } catch (error) {
      await fetchList();
      showToast(error.message, false);
      await dispatch(handleError(error.message));
      return error;
    }
  };
};

export {
  updateProductIdentificationNumberTypes,
  getProductIdentificationNumberTypeList,
  createOrUpdateProductIdentificationNumberType,
  addProductIdentificationNumberType,
  editProductIdentificationNumberType,
  editProductIdentificationNumberTypeById,
  cancelEditProductIdentificationNumberType,
};
