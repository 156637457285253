import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import Media from "../../common/Media";

class TargetGroupMediaList extends Component {
  componentDidMount = () => {
    const {
      match: {
        params: { targetGroupId },
      },
    } = this.props;
    this.props.fetchData(null, true, [{ targetGroupId }]);
  };

  render = () => {
    const {
      match: {
        params: { targetGroupId },
      },
      targetGroups: { targetGroupMedia, isTabLoading },
    } = this.props;
    return (
      <Media
        data={{
          media: targetGroupMedia,
          isLoading: isTabLoading,
          targetGroupId: targetGroupId,
        }}
        hiddenColumns={["Target Group"]}
        {...this.props}
      />
    );
  };
}

export const TargetGroupMediaListWithFilters = WithFetchList("getTargetGroupMedia", {
  sort: [
    {
      id: "status",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(TargetGroupMediaList);

const mapStateToProps = createSelector(
  (state) => state.targetGroups,
  (state) => state.userData,
  (state) => state.campaigns,
  (targetGroups, userData, campaigns) => ({ targetGroups, userData: userData.user, campaigns })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(TargetGroupMediaListWithFilters));
