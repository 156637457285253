import moment from "moment";
import { BUTTON_TYPE } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FORM_FIELD_TYPE } from "../../../../constants";
import styles from "../../../../styles/Campaigns/PauseOrResumeActions.module.scss";
import Form from "../../../common/Form";
import RightPanel from "../../../common/RightPanel";

export default class SplitCampaigns extends Component {
  state = {
    fromDate: this.props.fromDate,
    hasError: false,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    splitCampaigns: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fromDate: null,
    selectionInfo: null,
  };

  componentWillUnmount = () => {
    this.closeAction();
  };

  closeAction = () => {
    this.setState({
      fromDate: null,
      hasError: false,
    });
    this.props.onClose();
  };

  save = () => {
    const { fromDate } = this.state;
    //Validation to check if fromDate is entered
    if (!fromDate) {
      this.setState({ hasError: true });
      return;
    }
    this.props.splitCampaigns({ fromDate: fromDate });
  };

  render = () => {
    const { isOpen, header, validity } = this.props;
    const { fromDate, hasError } = this.state;

    const today = moment();
    const minDate = moment.max(today, moment(validity?.fromDate)).toDate();
    const maxDate = new Date(moment(validity?.toDate));
    const disabled = minDate > maxDate;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.closeAction()}
        header={header}
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.split",
            buttonType: BUTTON_TYPE.PRIMARY,
            onClick: () => this.save(),
          },
        ]}
        secondaryButtonProps={{ text: "Button.close", onClick: this.closeAction }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.DATE,
              title: `Split From`,
              invalidDateMessage: "Invalid From Date",
              onChange: (v) => {
                this.setState({ fromDate: moment(v).format("YYYY-MM-DD") });
              },
              value: fromDate ? new Date(fromDate) : null,
              placeholder: "MM/DD/YYYY",
              error: hasError && !fromDate,
              errorMessage: "Enter Valid From Date",
              minDate: minDate,
              maxDate: maxDate,
              disabled: disabled,
              defaultValue: minDate,
            },
          ]}
        />
        <div className={styles.campaignActionInfo}>
          {disabled ? (
            <span>
              The Target Group valid in Past can not be split. Please select a valid Target Group.
            </span>
          ) : (
            <span>
              The Target Group will be split from the selected date above.
              <span style={{ fontWeight: "500" }}>
                &nbsp; Kindly note that this operation is not reversible.
              </span>
            </span>
          )}
        </div>
      </RightPanel>
    );
  };
}
