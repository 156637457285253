import React from "react";

import { Icons } from "prefab";
import InfoBlock from "../../../common/InfoBlock";
import SummaryItem from "../../../common/SummaryItem";
import { pluralize, getDuration } from "../../../../utils";

const {
  CalendarIcon,
  LocationIcon,
  TheatreIcon,
  //  PlayIcon,
  PremiumIcon,
  PlaylistIcon,
} = Icons;

const getSummaryBlock = (details, header = "Summary") => (
  <InfoBlock
    header={header}
    hideHeader={header === ""}
    config={[
      {
        title: "",
        value: (
          <SummaryItem
            icon={<CalendarIcon />}
            title={pluralize(details?.noOfDays, "Day")}
            body={getDuration(details?.validity, "DD MMM YYYY")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<LocationIcon />}
            title={pluralize(details?.noOfStates, "State")}
            body={pluralize(details?.noOfCities, "city", "s", false, "Cities")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<TheatreIcon />}
            title={pluralize(details?.noOfTheatres, "Theatre", "s")}
            body={pluralize(details?.noOfScreens, "Screen", "s")}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      // {
      //   title: "",
      //   value: (
      //     <SummaryItem
      //       icon={<PlayIcon />}
      //       title="Max Plays Per Screen"
      //       body={details?.maxPlaysPerScreen}
      //     />
      //   ),
      //   size: {
      //     md: 4,
      //     sm: 6,
      //   },
      // },
      {
        title: "",
        value: (
          <SummaryItem
            icon={<PlaylistIcon />}
            title="Total No. of Plays"
            body={details?.totalNoOfPlays}
          />
        ),
        size: {
          md: 4,
          sm: 6,
        },
      },
      {
        name: "",
        value: <SummaryItem icon={<PremiumIcon />} title="Estimated Reach" body={details?.reach} />,
        size: {
          md: 4,
          sm: 6,
        },
      },
    ]}
  />
);

export default getSummaryBlock;
