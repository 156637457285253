/*

*/
import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { Table } from "workbench";
import { BUTTON_TYPE } from "prefab";
import { Icons } from "prefab";
import {
  modifyTableColumns,
  formatDateTime,
  checkScopes,
  formatDataBytes,
} from "../../../../utils";
import { getTimeFromSeconds } from "../../../../utils/timeUtils";
import checkScopeAuth from "../../../../utils/checkScopeAuth";
import * as constants from "../../../../constants";
import FilterChips from "../../../common/Filters/FilterChips";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import LocalizedButton from "../../../common/LocalizedButton";
import ActionIcon from "../../../common/ActionIcon";
import TableControls from "../../../common/TableControls";
import BulkActionButtons from "../../../common/BulkActionButtons";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import Popup from "../../../common/Popup";
import styles from "../../../../styles/Content/Content.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import {
  ActiveTableCell,
  ActiveTableCellRowFromArray,
  ActiveTableCellColumnWithRowFromArray,
} from "../../../common/ActiveTableCell";
import UserTableAccessor from "../../../common/UserTableAccessor";

const { LockedIcon } = Icons;

export default class ContentTable extends Component {
  state = {
    // Common selected columns for all contents can be added here rest is sent via props
    selectedColumns: [
      "Composition Name",
      "Package Name",
      "Content Type",
      "Duration",
      "Type",
      "Language",
      "Certification",
      "Attribute",
      "Size",
      "Brand",
      "Advertisers",
    ],
    selection: [],
    isPopupOpen: false,
  };
  static propTypes = {
    selectedColumns: PropTypes.array,
    isLoading: PropTypes.bool,
  };
  static defaultProps = {
    selectedColumns: [],
  };
  componentDidMount = async () => {
    const { selectedColumns } = this.props;

    this.setState({
      selectedColumns: [...this.state.selectedColumns, ...selectedColumns],
    });
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  handlePopup = () => this.setState((prevState) => ({ isPopupOpen: !prevState.isPopupOpen }));

  onRowSelect = (selection) => this.setState({ selection });

  renderAssignButton = (content) => {
    const { onAssign } = this.props;
    return (
      <div className={styles.mapButtonWrapper}>
        <LocalizedButton
          id="add"
          iconName="AddFilledIcon"
          //SLATE-1336 Sep5-2023 Swayambhu:- added fail safe using double bang operator to check if onAssign exists before calling it.
          onClick={() => {
            if (!!onAssign) {
              onAssign(content);
            }
          }}
          text="Button.assign"
          buttonType={BUTTON_TYPE.LINK}
        />
      </div>
    );
  };

  renderCertifications = (d) => {
    if (d.countryCertifications.length === 0) return this.renderAssignButton(d);

    const certificationList = d.countryCertifications.map(
      ({
        countryId,
        countryName,
        certificationId,
        certificationCode,
        isNotRated,
        isNotRatedTag,
      }) => {
        const dataList = [{ name: countryName, id: countryId }];
        if (isNotRated) dataList.push({ name: "Not Rated", id: isNotRatedTag });
        else dataList.push({ name: certificationCode, id: certificationId });
        return dataList;
      }
    );

    return ActiveTableCellColumnWithRowFromArray(
      certificationList,
      this.props.onFilterIdSelect,
      constants.SPECIAL_CHARACTERS.MIDDLE_DOT
    );
  };

  renderSubtitles = (d) => {
    const subtitleList = d.subtitles.map(({ languageId, languageName }) => ({
      id: languageId,
      name: languageName,
    }));

    return ActiveTableCellRowFromArray(subtitleList);
  };

  renderAssignedAdvertisers = (d) => {
    if (isEmpty(d.advertisers) || isEmpty(d.advertiserId)) return this.renderAssignButton(d);

    return ActiveTableCell(d.advertiserId, d.advertisers, () =>
      this.props.onFilterIdSelect(d.advertiserId)
    );
  };

  renderAssignedBrands = (content) => {
    const { brand } = content;
    if (isEmpty(brand)) return this.renderAssignButton(content);

    const orgList = [{ name: brand.parentBrandName, id: brand.parentBrandId }];
    if (brand.type === "individualBrand" || brand.type === "product") {
      orgList.push({ name: brand.individualBrandName, id: brand.individualBrandId });
    }

    if (brand.type === "product") orgList.push({ name: brand.productName, id: brand.productId });

    return ActiveTableCellRowFromArray(
      orgList,
      this.props.onFilterIdSelect,
      constants.SPECIAL_CHARACTERS.MIDDLE_DOT
    );
  };

  renderNameWithEncryptionStatus = (d) => {
    const { isEncrypted, name, hasDkdm } = d;
    const wrapperClass = classNames("flex flex-auto", {
      [styles.encrypted]: isEncrypted,
      [styles.encryptedWithKey]: isEncrypted && hasDkdm,
      [styles.unEncrypted]: !isEncrypted,
    });
    const iconClass = classNames("fill-color", {
      green: !isEncrypted,
      red: isEncrypted && !hasDkdm,
    });

    return (
      <div className={wrapperClass}>
        <LockedIcon className={iconClass} />
        &nbsp;&nbsp;
        <div>{name}</div>
      </div>
    );
  };

  archiveContent = (isArchive) => {
    const { actions } = this.props;
    const { selection } = this.state;
    actions.archiveUnarchiveContent(selection, isArchive);
  };

  render = () => {
    const {
      history,
      data,
      isLoading,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      userData,
    } = this.props;
    if (isLoading && !data.data) return <PageLoadingWithTable />;

    const { selectedColumns, reorderedColumns, selection, isPopupOpen } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = data.totalCount || 0;
    const isArchived = data?.data?.map((content) => content.isArchived).length > 0;
    const hasWriteScope = checkScopeAuth(
      userData,
      constants.SCOPES.CONTENT,
      constants.SCOPE_ACTIONS.WRITE
    );

    const columns = [
      {
        id: "name",
        Header: "Composition Name",
        accessor: (d) => this.renderNameWithEncryptionStatus(d),
      },
      {
        id: "packageId",
        Header: "Package Name",
        accessor: (d) =>
          ActiveTableCell(d.packageId, d.packageName, () =>
            this.props.onFilterIdSelect(d.packageId)
          ),
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: (d) => getTimeFromSeconds(d.durationInSeconds),
      },
      {
        id: "contentTypeName",
        Header: "Content Type",
        accessor: (d) =>
          ActiveTableCell(d.contentTypeId, d.contentTypeName, () =>
            this.props.onFilterIdSelect(d.contentTypeId)
          ),
      },
      {
        id: "language",
        Header: "Language",
        accessor: (d) =>
          ActiveTableCell(d.languageId, d.languageName, () =>
            this.props.onFilterIdSelect(d.languageId)
          ),
      },
      {
        id: "certification",
        Header: "Certification",
        accessor: (d) => this.renderCertifications(d),
      },
      {
        id: "attribute",
        Header: "Attribute",
        accessor: (d) =>
          ActiveTableCellRowFromArray(
            [
              { name: d.aspectRatioName, id: d.aspectRatioId },
              { name: d.pictureType, id: d.pictureTypeId },
              { name: d.resolution, id: d.resolutionId },
            ],
            this.props.onFilterIdSelect
          ),
      },
      {
        id: "subtitles",
        Header: "Subtitles",
        accessor: (d) => this.renderSubtitles(d),
      },
      {
        id: "sizeInBytes",
        Header: "Size",
        accessor: (d) => formatDataBytes(d.sizeInBytes),
      },
      {
        id: "brand",
        Header: "Brand",
        accessor: (d) => this.renderAssignedBrands(d),
      },
      {
        id: "advertiserName",
        Header: "Advertisers",
        accessor: (d) => this.renderAssignedAdvertisers(d),
      },
      {
        id: "isInUse",
        Header: "In Use",
        accessor: (d) =>
          ActiveTableCell(d.isInUseTag, d.isInUse ? "Yes" : "No", () =>
            this.props.onFilterIdSelect(d.isInUseTag)
          ),
      },
      {
        id: "isSMPTE",
        Header: "SMPTE Status",
        accessor: (d) =>
          ActiveTableCell(d.isSmpteTag, d.isSmpte ? "Yes" : "No", () =>
            this.props.onFilterIdSelect(d.isSmpteTag)
          ),
      },
      {
        id: "countCampaigns",
        Header: "Campaign",
        accessor: "countCampaigns",
      },
      {
        id: "playedCount",
        Header: "Played Count",
        accessor: "playedCount",
      },
      {
        id: "uploadedAt",
        Header: "Uploaded At",
        showTimeZone: true,
        accessor: (prop) => formatDateTime(prop.value),
      },
      {
        id: "uploadedBy",
        Header: "Uploaded By",
        accessor: UserTableAccessor("uploadedBy", this.props.onFilterIdSelect),
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (d) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/content/${d.original.id}/details`,
                  iconName: "ViewIcon",
                },
              ],
              userData
            )}
          />
        ),
      },
    ];
    return (
      <div className={pageStyles.pageContainer}>
        <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
          <TableControls
            searchBar
            columnFilter
            showRowSize
            pagination
            columns={columns}
            selectedColumns={selectedColumns}
            reorderedColumns={reorderedColumns}
            data={data}
            query={query}
            ps={ps}
            page={page}
            tagTypes={[
              constants.TAG_TYPE.CONTENT,
              constants.TAG_TYPE.LANGUAGE,
              constants.TAG_TYPE.BRAND,
              constants.TAG_TYPE.ADVERTISER,
              constants.TAG_TYPE.IN_USE,
              constants.TAG_TYPE.ASPECT_RATIO,
              constants.TAG_TYPE.RESOLUTION_NAME,
              constants.TAG_TYPE.PICTURE_TYPE,
              constants.TAG_TYPE.AUDIO_TYPE,
              constants.TAG_TYPE.SMPTE,
              constants.TAG_TYPE.PACKAGE_NAME,
            ]}
            onSearchFilterSelect={this.props.onFilterSelect}
            onFilterChange={this.props.onFilterChange}
            onColumnFilterSelect={this.onColumnSelect}
            onRowSizeChange={onQueryChange}
            filters={filters}
          />
          <BulkActionButtons
            isOpen={selection.length > 0}
            selection={[
              {
                count: selection.length,
                label: "Contents",
              },
            ]}
            buttons={[
              {
                text: isArchived ? "Button.unArchive" : "Button.archive",
                onClick: this.handlePopup,
                checkScope: true,
                scope: constants.SCOPES.CONTENT,
                scopeAction: constants.SCOPE_ACTIONS.WRITE,
                userData,
                /*
                SLATE-1341 08 Sept 2023 Akash Vinchankar:-
                Added a disabled prop for the Unarchive button for this specific page as the API endpoint is not present in the backend for this action.
                */
                disabled: true,
              },
            ]}
            onClose={() => {
              //SLATE-1344 08-09-23 Swayambhu:- On clicking cross button it closes the popup and uncheck the boxes
              this.setState({ selection: [] });
            }}
          />
          <FilterChips
            selected={filters}
            showResultsCount
            resultsCount={resultsCount}
            onFilterChange={this.props.onFilterChange}
          />
          <Table
            data={data.data}
            loading={isLoading || isFilterLoading}
            columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            defaultPageSize={constants.DEFAULT_PAGE_SIZE}
            sorted={sort}
            onSortedChange={onSortedChange}
            selectable={hasWriteScope}
            selection={selection}
            selectedClassNames="selectedParent"
            selectedBackground="rgba(121, 126, 209, 0.2)"
            onSelect={(selection) => this.onRowSelect(selection)}
            LoadingComponent={<TableLoading columns={columns} />}
            onRowClick={({ original }) => history.push(`/content/${original.id}/details`)}
          />
          <FooterControls
            pagination
            data={data}
            query={query}
            ps={ps}
            page={page}
            onRowSizeChange={this.onQueryChange}
          />
          <Popup
            isOpen={isPopupOpen}
            onClose={this.handlePopup}
            onConfirm={isArchived ? () => this.archiveContent(isArchived) : this.archiveContent}
            title="Popup.title"
            body={isArchived ? "Popup.unArchiveContent" : "Popup.archiveContent"}
          />
        </div>
      </div>
    );
  };
}
