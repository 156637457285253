/*
This is a higher order component (HOC) called withScopeAuth that can be used to check if a user has access to a certain scope before rendering a component.

1.It takes in a WrappedComponent and returns a new component WithScopeAuth.

2. It will Get the userData, scope, scopeAction etc from props.

3. It also will Check if the user is a Slate admin user using isSlateAdminCompanyUser.

4. WithScopeAuth will Check if the user has access to the required scope and scopeAction using checkScopeAuth.

5. If the user does not have access, it returns null which prevents the WrappedComponent from rendering.

6. If the user has access, it renders the WrappedComponent, passing down props.

So this HOC can be used to conditionally show/hide components based on the user's access scope.
It helps enforce authorization in the app.
*/
import React, { Component } from "react";
import { getDisplayName, checkScopeAuth, isSlateAdminCompanyUser } from "../../utils";
import { SCOPES } from "../../constants";

// HOC util to check scope and actions for a given user in any component.
// Returns null if the user does not have access, thereby removing the component.
function withScopeAuth(WrappedComponent) {
  class WithScopeAuth extends Component {
    getWrappedProps = () => {
      const { userData, scope, scopeAction, passUserData, onlyForSlateAdmin, ...rest } = this.props;
      return passUserData ? this.props : rest;
    };
    render() {
      const { userData, scope, scopeAction, onlyForSlateAdmin } = this.props;
      if (scope === SCOPES.__SELF__) return <WrappedComponent {...this.getWrappedProps()} />;
      if (!userData) return null;
      const isAdmin = isSlateAdminCompanyUser(userData);
      const isAllowed =
        (!onlyForSlateAdmin || isAdmin) && checkScopeAuth(userData, scope, scopeAction);
      if (!isAllowed) return null;
      return <WrappedComponent {...this.getWrappedProps()} />;
    }
  }
  WithScopeAuth.displayName = `WithScopeAuth(${getDisplayName(WrappedComponent)})`;
  return WithScopeAuth;
}

export default withScopeAuth;
