import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BaseButton } from "prefab";
import intlKeyPropType from "../../utils/intlKeyPropType";
import withScopeAuth from "../hoc/withScopeAuth";
import { SCOPES } from "../../constants";

const Message = (message) => <FormattedMessage id={message} defaultMessage={message} />;

class LocalizedButton extends Component {
  static propTypes = {
    text: intlKeyPropType.isRequired,
  };
  render() {
    const { text, url, ...rest } = this.props;
    if (url) {
      return (
        <Link to={url} scope={SCOPES.__SELF__} draggable={false}>
          <BaseButton buttonText={Message(text)} {...rest} />
        </Link>
      );
    }
    return <BaseButton buttonText={Message(text)} {...rest} />;
  }
}

const LocalizedButtonWithAuth = withScopeAuth(LocalizedButton);
LocalizedButtonWithAuth.defaultProps = {
  scope: SCOPES.__SELF__,
};
export default LocalizedButtonWithAuth;
