import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";
import styles from "../../../../styles/Lists/Lists.module.scss";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import WithFetchList from "../../../hoc/withFetchList";
import FilterChips from "../../../common/Filters/FilterChips";
import HeaderButton from "../../../common/HeaderButtons";
import TableControls from "../../../common/TableControls";
import FooterControls from "../../../common/FooterControls";
import ActionIcon from "../../../common/ActionIcon";
import TableLoading from "../../../common/TableLoading";
import EditReason from "./EditReasons";

const links = [{ name: "PageHeader.lists", path: "/lists" }, { name: "PageHeader.reasons" }];

class ReasonsList extends Component {
  state = {
    isOpen: false,
    selectedColumns: ["Last Updated On", "Last Updated By", "Reason", "Type"],
    reason: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      actions,
    } = this.props;

    if (params.action) {
      if (params.action === "new") {
        actions.addReason();
        this.getReasonRejectionList();
      } else {
        this.props.onFilterIdSelect(params.action);
        actions.editReasonById(params.action);
      }
    } else this.getReasonRejectionList();
  };

  getReasonRejectionList = () => {
    const {
      fetchData,
      actions: { getReasonTypes },
    } = this.props;
    getReasonTypes();
    fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (reason) => {
    const {
      history: { location },
      actions,
    } = this.props;
    const params = queryString.parse(location.search);
    actions.saveReason(params, reason);
  };

  addReason = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addReason();
    history.replace(`/lists/reasons/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditReason();
    history.replace(`/lists/reasons${location.search}`);
  };

  editReason = (reason) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editReason(reason);
    history.replace(`/lists/reasons/${reason.id}${location.search}`);
  };

  render = () => {
    const {
      reasons: { reasons, types, isLoading = false, isPartialLoading, reasonEdit },
      history,
      filters,
      userData,
      sort,
      onSortedChange,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, reason } = reasonEdit;
    const columns = checkScopes(
      [
        {
          id: "type",
          Header: "Type",
          accessor: (d) => (
            <span className={styles.active} onClick={() => this.props.onFilterIdSelect(d.typeTag)}>
              {d.type}
            </span>
          ),
        },
        {
          id: "reason",
          Header: "Reason",
          accessor: "reason",
        },
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.REASONS,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editReason(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const data = reasons.data || [];

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.REASONS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addReason,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={links} />
        <PageHeader
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          name="PageHeader.reasons"
          count={reasons.totalCount}
          renderRightSideComponent={() => (
            <HeaderButton
              logsButton
              columnFilter
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onColumnFilterSelect={this.onColumnSelect}
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.REASONS}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              tagTypes={[constants.TAG_TYPE.REASON_TYPE, constants.TAG_TYPE.UPDATED_AT]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={reasons.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading}
            />
            <Table
              data={data}
              loading={isPartialLoading}
              sorted={sort}
              pageSize={reasons.data ? reasons.data.length : 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls {...addButtonProps} />
          </div>
        </div>
        <EditReason
          isOpen={isOpen}
          reason={reason}
          types={types}
          onClose={this.cancelEdit}
          onSave={this.save}
        />
      </div>
    );
  };
}

export const ReasonsListWithFilters = WithFetchList("getReasons", {
  sort: [{ id: "type" }],
})(ReasonsList);

const mapStateToProps = createSelector(
  (state) => state.reasons,
  (state) => state.userData,
  (reasons, userData) => ({ reasons, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ReasonsListWithFilters);
