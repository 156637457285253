import { fetchPlaylistTemplatesList } from "../../api";
import * as types from "../types/playlistTemplates";
import * as constants from "../../constants";

const updatePlaylistTemplate = (payload) => {
  return { type: types.DATA, payload: payload };
};

const handleError = (payload) => {
  return { type: types.IS_ERROR, payload };
};

const handleLoading = (payload) => {
  return { type: types.IS_LOADING, payload };
};

const handlePartialLoading = (payload) => {
  return { type: types.IS_PARTIAL_LOADING, payload };
};

const fetchPlaylistTemplate = (
  params = {},
  filters = [],
  fetch = fetchPlaylistTemplatesList,
  isPartialLoading
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    const loadingAction = isPartialLoading ? handlePartialLoading : handleLoading;
    dispatch(loadingAction(true));
    try {
      const { data } = await fetch(ps, (page - 1) * ps, sort, filters);
      dispatch(updatePlaylistTemplate(data));
      dispatch(loadingAction(false));
    } catch (error) {
      dispatch(loadingAction(false));
      dispatch(handleError(error));
    }
  };
};

export { updatePlaylistTemplate, fetchPlaylistTemplate };
