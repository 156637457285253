import { searchContentTypes, saveContentType, getContentTypeById } from "../../api";
import * as types from "../types/contentTypes";
import { showToast } from "../../utils";

const updateContentTypes = (payload) => {
  return { type: types.DATA, payload };
};

const actionCreator = (action) => action;

const getContentTypesList = (params = {}, request = null, getList = searchContentTypes) => {
  const { page = 1, ps = -1, sort = "code:asc" } = params;
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const { data } = await getList(ps, (page - 1) * ps, sort, request);
      dispatch(updateContentTypes(data));
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const editContentType = (contentType) => (dispatch) =>
  dispatch(
    actionCreator({ type: types.CONTENT_TYPE_EDIT, payload: { contentType, isOpen: true } })
  );

const addContentType = () => (dispatch) =>
  dispatch(
    actionCreator({
      type: types.CONTENT_TYPE_EDIT,
      payload: {
        isOpen: true,
        contentType: { code: "", name: "", description: "" },
      },
    })
  );

const cancelEditContentType = () => (dispatch) =>
  dispatch(
    actionCreator({ type: types.CONTENT_TYPE_EDIT, payload: { isOpen: false, contentType: {} } })
  );

const editContentTypeById = (contentTypeId, get = getContentTypeById) => {
  return async (dispatch) => {
    try {
      const { data } = await get(contentTypeId);
      dispatch(editContentType(data));
      dispatch(getContentTypesList());
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const saveOrUpdateContentType = (
  contentType,
  params,
  request,
  save = saveContentType,
  search = getContentTypesList
) => {
  return async (dispatch) => {
    try {
      await save(contentType);
      showToast(contentType.id ? "Toast.contentTypeUpdated" : "Toast.contentTypeAdded");
      dispatch(search(params, request));
    } catch (error) {
      showToast(error.response.data.message, false);
      dispatch(search(params, request));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

export {
  updateContentTypes,
  getContentTypesList,
  saveOrUpdateContentType,
  addContentType,
  editContentType,
  editContentTypeById,
  cancelEditContentType,
};
