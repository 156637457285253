import { STATUS_ICON_COLORS } from "./status";

const THEATRE_SCREENS_VIEW_TYPE = {
  THEATRES: "theatres",
  SCREENS: "screens",
};

const CAMPAIGN_STATUSES = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PLANNED: "Planned",
  REJECTED: "Rejected",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  // CONSUMED: "Consumed",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
};

const CAMPAIGN_STATUS_LABELS = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PLANNED: "Planned",
  REJECTED: "Rejected",
  ACTIVE: "Active",
  PAUSED: "Paused",
  COMPLETED: "Completed",
  // CONSUMED: "Consumed",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
};

const CONTENT_AVAILABILITY_STATUS = [
  {
    id: true,
    displayName: "Yes",
  },
  {
    id: false,
    displayName: "No",
  },
];

const CAMPAIGN_STEPS = {
  CAMPAIGN: 1,
  TARGET_GROUP: 2,
  MEDIA: 3,
  REVIEW: 4,
  FINISH: 5,
};

const CAMPAIGN_ACTIONS = {
  PAUSE: "Pause",
  RESUME: "Resume",
  CANCEL: "Cancel",
  EDIT: "Edit",
  EDIT_VALIDITY: "EditValidity",
  EDIT_ADVERTISER: "EditAdvertiser",
  EDIT_CURRENCY: "EditCurrency",
  EDIT_BILLING_DETAILS: "EditBillingDetails",
  EDIT_COUNTRY: "EditCountry",
  MOVE: "Move",
};

const CAMPAIGN_TYPE = {
  CAMPAIGN: "campaign",
  TARGET_GROUP: "target_group",
  MEDIA: "media",
  SCREEN: "screen",
};

const CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST = {
  [CAMPAIGN_ACTIONS.PAUSE]: [
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    // CAMPAIGN_STATUSES.CONSUMED,
  ],
  [CAMPAIGN_ACTIONS.RESUME]: [CAMPAIGN_STATUSES.PAUSED],
  [CAMPAIGN_ACTIONS.CANCEL]: [
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.PAUSED,
  ],
  [CAMPAIGN_ACTIONS.EDIT]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.PAUSED,
    // CAMPAIGN_STATUSES.CONSUMED,
  ],
  [CAMPAIGN_ACTIONS.EDIT_VALIDITY]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.PLANNED,
  ],
  //SLATE-1399 Nov21 Anushree:- CAMPAIGN_ACTION_ALLOWED_STATUS_LIST returns status that are allowed not actions
  //[CAMPAIGN_ACTIONS.EDIT_ADVERTISER]: [CAMPAIGN_ACTIONS.DRAFT],
  //[CAMPAIGN_ACTIONS.EDIT_CURRENCY]: [CAMPAIGN_ACTIONS.DRAFT],
  [CAMPAIGN_ACTIONS.EDIT_ADVERTISER]: [
    CAMPAIGN_STATUSES.DRAFT,
    // CAMPAIGN_STATUSES.CONSUMED,
    CAMPAIGN_STATUSES.CANCELLED,
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.REJECTED,
    CAMPAIGN_STATUSES.COMPLETED,
  ],
  [CAMPAIGN_ACTIONS.EDIT_CURRENCY]: [
    CAMPAIGN_STATUSES.DRAFT,
    // CAMPAIGN_STATUSES.CONSUMED,
    CAMPAIGN_STATUSES.CANCELLED,
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.REJECTED,
    CAMPAIGN_STATUSES.COMPLETED,
  ],
  [CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS]: [CAMPAIGN_STATUSES.DRAFT, CAMPAIGN_STATUSES.PROPOSED],
  [CAMPAIGN_ACTIONS.EDIT_COUNTRY]: [
    CAMPAIGN_STATUSES.DRAFT,
    // CAMPAIGN_STATUSES.CONSUMED,
    CAMPAIGN_STATUSES.CANCELLED,
    CAMPAIGN_STATUSES.EXPIRED,
    CAMPAIGN_STATUSES.REJECTED,
    CAMPAIGN_STATUSES.COMPLETED,
  ],
  [CAMPAIGN_ACTIONS.MOVE]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.PROPOSED,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PLANNED,
    CAMPAIGN_STATUSES.PAUSED,
    // CAMPAIGN_STATUSES.CONSUMED,
  ],
  [CAMPAIGN_ACTIONS.SPLIT]: [
    CAMPAIGN_STATUSES.DRAFT,
    CAMPAIGN_STATUSES.ACTIVE,
    CAMPAIGN_STATUSES.PAUSED,
  ],
};

const CAMPAIGN_STATUS_LIST = [
  {
    displayName: CAMPAIGN_STATUS_LABELS.DRAFT,
    color: STATUS_ICON_COLORS.GREY,
    id: CAMPAIGN_STATUS_LABELS.DRAFT,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PROPOSED,
    id: CAMPAIGN_STATUS_LABELS.PROPOSED,
    color: STATUS_ICON_COLORS.LIGHT_BLUE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PLANNED,
    id: CAMPAIGN_STATUS_LABELS.PLANNED,
    color: STATUS_ICON_COLORS.PURPLE,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.REJECTED,
    id: CAMPAIGN_STATUS_LABELS.REJECTED,
    color: STATUS_ICON_COLORS.RED,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.ACTIVE,
    id: CAMPAIGN_STATUS_LABELS.ACTIVE,
    color: STATUS_ICON_COLORS.GREEN,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.PAUSED,
    id: CAMPAIGN_STATUS_LABELS.PAUSED,
    color: STATUS_ICON_COLORS.YELLOW,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.COMPLETED,
    id: CAMPAIGN_STATUS_LABELS.COMPLETED,
    color: STATUS_ICON_COLORS.LIGHT_GREY,
  },
  // {
  //   displayName: CAMPAIGN_STATUS_LABELS.CONSUMED,
  //   id: CAMPAIGN_STATUS_LABELS.CONSUMED,
  //   color: STATUS_ICON_COLORS.LIGHT_GREY,
  // },
  {
    displayName: CAMPAIGN_STATUS_LABELS.CANCELLED,
    id: CAMPAIGN_STATUS_LABELS.CANCELLED,
    color: STATUS_ICON_COLORS.RED,
  },
  {
    displayName: CAMPAIGN_STATUS_LABELS.EXPIRED,
    id: CAMPAIGN_STATUS_LABELS.EXPIRED,
    color: STATUS_ICON_COLORS.RED,
  },
];

const POSITION_LIST = [
  { id: 1, name: "A" },
  { id: 2, name: "B" },
  { id: 3, name: "C" },
  { id: 4, name: "D" },
  { id: 5, name: "E" },
  { id: 6, name: "F" },
  { id: 7, name: "G" },
  { id: 8, name: "H" },
  { id: 0, name: "Normal" },
];

export {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STATUS_LABELS,
  CAMPAIGN_STATUS_LIST,
  CAMPAIGN_STEPS,
  CAMPAIGN_TYPE,
  CONTENT_AVAILABILITY_STATUS,
  THEATRE_SCREENS_VIEW_TYPE,
  POSITION_LIST,
};
