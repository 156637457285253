import { getReasonsList, fetchReasonTypes, createReason, getReasonById } from "../../api";
import * as types from "../types/reasons";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateReasons = (payload) => {
  return { type: types.REASONS, payload: payload };
};

const getReasonTypes = (getTypes = fetchReasonTypes) => {
  return async (dispatch) => {
    try {
      const { data } = await getTypes();
      dispatch({ type: types.REASONS_TYPES, payload: data });
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error));
    }
  };
};

const editReason = (reason) => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { reason, isOpen: true }));

const addReason = () => (dispatch) =>
  dispatch(
    actionCreator(types.EDIT, {
      isOpen: true,
      reason: { type: "", reason: "" },
    })
  );

const cancelEditReason = () => (dispatch) =>
  dispatch(actionCreator(types.EDIT, { isOpen: false, reason: {} }));

const editReasonById = (reasonId, get = getReasonById) => {
  return async (dispatch) => {
    try {
      const { data } = await get(reasonId);
      dispatch(editReason(data));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const getReasons = (params, filters = [], getList = getReasonsList, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "type:asc" } = params;
  return async (dispatch) => {
    isPartial
      ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
      : dispatch(actionCreator(types.IS_LOADING, true));
    try {
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      dispatch(updateReasons(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
      dispatch(actionCreator(types.IS_ERROR, error));
    }
  };
};

const saveReason = (params, reason, save = createReason, getReasonsAction = getReasons) => {
  return async (dispatch) => {
    try {
      await save(reason);
      await dispatch(getReasonsAction(params));
      showToast(reason.id ? "Toast.rejectionReasonUpdated" : "Toast.rejectionReasonAdded");
    } catch (error) {
      await dispatch(getReasonsAction(params));
      showToast(error.message, false);
      return error;
    }
  };
};

export {
  updateReasons,
  getReasons,
  getReasonTypes,
  saveReason,
  addReason,
  editReason,
  editReasonById,
  cancelEditReason,
};
