/*
This component:-

Defines a PageHeader component that extends React.Component

Defines propTypes and defaultProps for the component

Renders a flexible header layout with:

   A title/name

   An optional header icon

   Badges for counts

   A tagline

   Buttons 

Ability to render custom components on the right side

Uses classNames and module CSS for styling

Conditionally shows loading state skeletons

Localizes title and active count label via react-intl
*/
import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import { ICON_POS } from "prefab";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "../../styles/PageHeader.module.scss";
import { intlKeyPropType } from "../../utils";
import LocalizedButton from "./LocalizedButton";

export default class PageHeader extends Component {
  static propTypes = {
    name: PropTypes.string,
    headerIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    count: PropTypes.number,
    activeCount: PropTypes.number,
    customBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    renderRightSideComponent: PropTypes.func,
    tagLine: PropTypes.string,
    activeCountLabel: intlKeyPropType,
    isLoading: PropTypes.bool,
    isPartialLoading: PropTypes.bool,
    isFilterLoading: PropTypes.bool,
    buttons: PropTypes.array,
    className: PropTypes.string,
  };

  static defaultProps = {
    isLoading: false,
    isPartialLoading: false,
    isFilterLoading: false,
  };

  renderButtons(buttons) {
    return buttons.map((button, i) => {
      const {
        isHidden,
        isIconRight = false,
        checkScope = false,
        scope,
        scopeAction,
        userData,
        ...rest
      } = button;
      const withScopeProps = checkScope
        ? {
            scope,
            scopeAction,
            userData,
          }
        : null;
      if (isHidden) return null;

      return (
        <div key={i} className="flex">
          <LocalizedButton
            iconPos={isIconRight ? ICON_POS.RIGHT : ICON_POS.LEFT}
            {...rest}
            {...withScopeProps}
          />
        </div>
      );
    });
  }

  render() {
    const {
      name,
      count,
      renderRightSideComponent,
      isLoading,
      isPartialLoading,
      isFilterLoading,
      buttons,
      tagLine,
      activeCount,
      activeCountLabel,
      customBadge,
      headerIcon,
      className,
    } = this.props;
    return (
      <div className={classNames("flex flex-wrap col-12 clearfix", styles.pageHeader, className)}>
        <div className="flex flex-auto flex-wrap">
          <div className={`flex ${styles.header}`}>
            <div
              className={classNames("flex h2", styles.headerText, {
                [Classes.SKELETON]: isLoading,
              })}
            >
              {!isLoading && headerIcon && (
                <div className={`flex ${styles.headerIcon}`}>{headerIcon}</div>
              )}
              {name && <FormattedMessage id={name} defaultMessage={name} />}
            </div>
            {!isLoading && count !== null && count !== undefined && (
              <div className={`flex ${styles.badgeContainer}`}>
                <span
                  className={classNames("flex", styles.badge, {
                    //SLATE-1346 Sep19-2023 Anushree:- count skeleton should be displayed if either condition isPartialLoading || isFilterLoading is true
                    //[Classes.SKELETON]: isPartialLoading,
                    [Classes.SKELETON]: isPartialLoading || isFilterLoading,
                  })}
                >
                  {count}
                </span>
              </div>
            )}
          </div>
          {!isLoading && customBadge && (
            <div className={`flex col-12 ${styles.customBadgeContainer}`}>{customBadge}</div>
          )}
          {tagLine && <div className={`flex col-12 ${styles.tagLine}`}>{tagLine}</div>}
        </div>
        <div className={`flex ${styles.headerButtonContainer}`}>
          <div className="flex-auto" />
          {!isLoading && activeCount !== null && activeCount !== undefined && (
            <>
              <div className={`flex ${styles.badge} ${styles.active}`}>{activeCount}</div>
              {activeCountLabel && (
                <span className={styles.activeCountLabel}>
                  <FormattedMessage id={activeCountLabel} defaultMessage={activeCountLabel} />
                </span>
              )}
            </>
          )}
          {!isLoading && buttons && this.renderButtons(buttons)}
          {!isLoading && renderRightSideComponent && renderRightSideComponent()}
        </div>
      </div>
    );
  }
}
