import React from "react";
import { Button, Classes } from "@blueprintjs/core";
import styles from "../../styles/InfoBlock.module.scss";

const InfoBlockLoading = () => {
  return (
    <div
      className={`col-12 clearfix ${styles.block} ${styles.primary}`}
      style={{ padding: "1.5rem" }}
    >
      <div className={`flex justify-between col-12 ${styles.headerBlock}`}>
        <div className={`${styles.header} ${Classes.SKELETON}`}>
          {" "}
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
        <div className="flex">
          <Button className={Classes.SKELETON} text="&emsp;&emsp;&emsp;&emsp;" />
        </div>
      </div>
      <div className="flex col-12 flex-wrap clearfix">
        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>

        <div
          className={`flex col  md-col-3 sm-col-6 flex-wrap ${styles.wrapper}`}
          style={{ padding: "1rem 0" }}
        >
          <div
            className={`col-12 ${styles.label} ${Classes.SKELETON}`}
            style={{ fontSize: "1.2rem", width: "65%", marginBottom: "0.25rem" }}
          >
            {" "}
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
          <div
            className={`col-12 ${styles.textValue} ${Classes.SKELETON}`}
            style={{ width: "45%" }}
          >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBlockLoading;
