// Logo Imports
import { ReactComponent as SlateUserLogo } from "./logos/qs-logo.svg";
import { ReactComponent as SlateUserLogoSmall } from "./logos/qs-logo-small.svg";
import { ReactComponent as SlateAdminLogo } from "./logos/qs-admin-logo.svg";
import { ReactComponent as SlateAdminLogoSmall } from "./logos/qs-admin-logo-small.svg";
import MoviebuffLogo from "./logos/moviebuff_tm.png";

// Placeholders
import { ReactComponent as EmptyState } from "./placeholders/empty-state-illustration.svg";
import { ReactComponent as LoginBanner } from "./placeholders/login-banner.svg";

const isAdminApp = process.env.REACT_APP_IS_ADMIN_APP;

const SlateLogo = isAdminApp ? SlateAdminLogo : SlateUserLogo;
const SlateLogoSmall = isAdminApp ? SlateAdminLogoSmall : SlateUserLogoSmall;

export { SlateLogo, SlateLogoSmall, MoviebuffLogo, EmptyState, LoginBanner };
