/*
Sep6-2023 Anushree:-
1. Companies class is used to display the Seller's page with Media Agency,Exhibitors Tab
2. It is calledBy Root.js
*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch } from "../../../utils";
import styles from "../../../styles/Inventory/Theatre.module.scss";
// import * as constants from "../../../constants";
import PageHeader from "../../common/PageHeader";
import HeaderButtons from "../../common/HeaderButtons";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import TabView from "../../common/Tabs";
import MediaAgencies from "./MediaAgencies";
// import Exhibitors from "./Exhibitors";

export class Companies extends Component {
  componentDidMount = async () => {
    await this.getCompanies();
  };

  getCompanies = () => {
    const { actions } = this.props;
    actions.getCompanies();
  };

  getCount(exhibitorsCount = 0, mediaAgenciesCount = 0) {
    return exhibitorsCount + mediaAgenciesCount;
  }

  //SLATE-1337 Sep06-2023 Anushree:- count is now calculated from prop mediaAgencies.totalCount instead of companies.mediaAgenciesCount as companies.mediaAgenciesCount does not account for filter being applied.
  render = () => {
    const {
      companies: { companies, mediaAgencies, isLoading },
      match: { params },
      userData,
      history,
    } = this.props;
    const logsPath =
      params.tabId === "exhibitors" ? AUDIT_LOG_SOURCE.EXHIBITORS : AUDIT_LOG_SOURCE.MEDIA_AGENCIES;

    if (isLoading) return <PageLoadingWithTable />;

    return (
      <div>
        <PageHeader
          name="PageHeader.sellers"
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButtons
                logsButton
                history={history}
                logsPath={`/logs/source/${logsPath}`}
                userData={userData}
              />
            </div>
          )}
          //SLATE-1337 Sep06-2023 Anushree:- Sellers total count is sum of exhibitor count + media agency count, as exhibitor tab not yet defined till date, count is only calculated by mediaAgencies.totalCount
          //count={this.getCount(companies.exhibitorsCount, companies.mediaAgenciesCount)}
          count={this.getCount(companies.exhibitorsCount, mediaAgencies.totalCount)}
        />
        <div className={styles.tabsContainer}>
          <TabView
            id="navbar"
            selectedTabId={params.tabId}
            tabs={[
              // TODO: UNCOMMENT WHEN API IS READY
              // {
              //   id: "exhibitors",
              //   title: "Exhibitor",
              //   count: companies.exhibitorsCount || 0,
              //   goto: `/companies/exhibitors`,
              //   panel: <Exhibitors />,
              // },
              {
                id: "media_agencies",
                title: "Media Agency",
                //SLATE-1337 Sep06-2023 Anushree:-mediaAgencies.totalCount passed for Media Agency tab count
                //count: companies.mediaAgenciesCount || 0,
                count: mediaAgencies.totalCount || 0,
                goto: `/companies/media_agencies`,
                panel: <MediaAgencies />,
              },
            ]}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = createSelector(
  (state) => state.companies,
  (state) => state.userData,
  (companies, userData) => ({ companies, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Companies);
