import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import DetailsLoading from "../../../common/DetailsLoading";
import Form from "../../../common/Form";
import styles from "../../../../styles/Campaigns/PauseOrResumeActions.module.scss";

export default class AddMaxPlaysPerScreen extends Component {
  state = {
    maxPlaysPerScreen: null,
    hasFormSaved: false,
    campaignData: null,
    isPanelLoading: true,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    fetchActionId: PropTypes.string.isRequired,
    header: PropTypes.string,
    maximumPlays: PropTypes.number,
  };

  static defaultProps = {
    header: "RightPanelHeader.updateMaxPlaysPerScreen",
    maximumPlays: null,
  };

  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    const { fetchActionId, fetchAction } = this.props;
    if (fetchActionId) {
      const { data: campaignData } = await fetchAction(fetchActionId);

      if (this._isMounted) {
        this.setState({
          campaignData: campaignData,
          isPanelLoading: false,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          isPanelLoading: false,
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // TODO: Update error message once copy is updated
  getErrorMessage = () => {
    const { maxPlaysPerScreen, campaignData } = this.state;
    const { maximumPlays } = this.props;
    if (!maxPlaysPerScreen || maxPlaysPerScreen <= campaignData.maxPlaysPerScreen)
      return "Please Enter Valid Max Play Per Screen";
    if (maximumPlays && maxPlaysPerScreen > maximumPlays)
      return "Current Max Play Per Screen Exceeds the Max Limit";

    return "";
  };

  validateMaxPlaysPerScreen = () => {
    const { maxPlaysPerScreen, campaignData } = this.state;
    const { maximumPlays } = this.props;

    return (
      maxPlaysPerScreen &&
      maxPlaysPerScreen > campaignData.maxPlaysPerScreen &&
      (maximumPlays ? maxPlaysPerScreen <= maximumPlays : !maximumPlays)
    );
  };

  handleFormSave = () => {
    const { maxPlaysPerScreen, campaignData } = this.state;
    if (this.validateMaxPlaysPerScreen()) {
      this.props.onSave({
        maxPlaysPerScreen,
        id: campaignData.id,
      });
    }
    this.setState({
      hasFormSaved: true,
    });
  };

  handleFormCancel = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      maxPlaysPerScreen: 0,
      hasFormSaved: false,
    });
  };

  renderPanelContent = () => {
    const { maxPlaysPerScreen, hasFormSaved, campaignData, isPanelLoading } = this.state;
    if (isPanelLoading) return <DetailsLoading />;
    if (isEmpty(campaignData)) return <div>No Campaign Found</div>;

    return (
      <Form
        config={[
          {
            type: FORM_FIELD_TYPE.LABEL,
            title: "Name",
            value: campaignData?.name,
          },
          {
            type: FORM_FIELD_TYPE.LABEL,
            title: "Played Count",
            value: campaignData?.maxPlaysPerScreen,
          },
          {
            type: FORM_FIELD_TYPE.LABEL,
            title: "Validity",
            value: [
              moment(campaignData?.validity.fromDate).format("MMM DD, YYYY"),
              moment(campaignData?.validity.toDate).format("MMM DD, YYYY"),
            ].join(" to "),
          },
          {
            type: FORM_FIELD_TYPE.LABEL,
            title: "Days Remaining",
            value: moment(campaignData?.validity.toDate).diff(moment.now(), "days"),
          },
          {
            id: "newMaxPlaysPerScreen",
            title: "New Max Play Per Screen",
            value: maxPlaysPerScreen || "",
            placeholder: "New Max Play Per Screen",
            type: FORM_FIELD_TYPE.NUMERIC_INPUT,
            error: hasFormSaved && !this.validateMaxPlaysPerScreen(),
            errorMessage: this.getErrorMessage(),
            onValueChange: (value) =>
              this.setState({
                maxPlaysPerScreen: value,
              }),
          },
        ]}
      />
    );
  };

  render = () => {
    const { isOpen, header } = this.props;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.handleFormCancel()}
        header={header}
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Button.save",
            onClick: this.handleFormSave,
            className: styles.save,
          },
          {
            text: "Button.cancel",
            onClick: this.handleFormCancel,
            className: styles.clear,
            disabled: !this.state.hasFormSaved,
          },
        ]}
      >
        {this.renderPanelContent()}
      </RightPanel>
    );
  };
}
