import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import pageStyles from "../../../../styles/App.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
} from "../../../../utils";

import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import ActionIcon from "../../../common/ActionIcon";
import * as constants from "../../../../constants";
import { AUDIT_LOG_SOURCE } from "../../logs/logSource";
import WithFetchList from "../../../hoc/withFetchList";
import HeaderButton from "../../../common/HeaderButtons";
import TableControls from "../../../common/TableControls";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableLoading from "../../../common/TableLoading";
import EditNotificationTemplate from "./EditNotificationTemplate";

const _LINKS = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.notificationTemplates" },
];

class NotificationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: ["Code", "Subject", "Body", "Last Updated By", "Last Updated On"],
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
      actions,
      fetchData,
    } = this.props;

    if (params.action) {
      if (params.action === "new") {
        actions.addNotificationTemplate();
        fetchData();
      } else {
        this.props.onFilterIdSelect(params.action);
        actions.editNotificationTemplateById(params.action);
      }
    } else fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  save = (data) => {
    const {
      history: { location },
      actions,
    } = this.props;
    const params = queryString.parse(location.search);
    actions.createOrUpdateNotificationTemplate(data, params, this.props.filters);
  };

  addNotificationTemplate = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.addNotificationTemplate();
    history.replace(`/lists/notification-templates/new${location.search}`);
  };

  cancelEdit = () => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.cancelEditNotificationTemplate();
    history.replace(`/lists/notification-templates${location.search}`);
  };

  editNotificationTemplate = (notificationTemplate) => {
    const {
      actions,
      history,
      history: { location },
    } = this.props;
    actions.editNotificationTemplate(notificationTemplate);
    history.replace(`/lists/notification-templates/${notificationTemplate.id}${location.search}`);
  };

  render = () => {
    const {
      notificationTemplate: { data, isLoading, isPartialLoading, notificationTemplateEdit },
      history,
      filters,
      userData,
      ps,
      page,
      sort,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const { isOpen, notificationTemplate } = notificationTemplateEdit;
    const columns = checkScopes(
      [
        {
          id: "code",
          Header: "Code",
          accessor: "code",
        },
        {
          id: "subject",
          Header: "Subject",
          accessor: "subject",
        },
        {
          id: "body",
          Header: "Body",
          accessor: "body",
        },
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.NOTIFICATION_TEMPLATES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => this.editNotificationTemplate(props.original),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const query = queryString.parse(history.location.search);
    const notificationTemplatesData = data.data || [];

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.NOTIFICATION_TEMPLATES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addNotificationTemplate,
      userData,
    };

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          name="PageHeader.notificationTemplates"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={data.totalCount}
          renderRightSideComponent={() => (
            <HeaderButton
              logsButton
              history={history}
              logsPath={`/logs/source/${AUDIT_LOG_SOURCE.NOTIFICATION_TEMPLATES}`}
              {...addButtonProps}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              columnFilter
              showRowSize
              pagination
              query={query}
              ps={ps}
              page={page}
              data={data}
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={data.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading}
            />
            <Table
              pageSize={(query && parseInt(query.ps)) || 10}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={notificationTemplatesData}
              loading={isPartialLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              query={query}
              ps={ps}
              page={page}
              data={data}
              onRowSizeChange={onQueryChange}
              {...addButtonProps}
            />
          </div>
        </div>
        <EditNotificationTemplate
          isOpen={isOpen}
          onClose={this.cancelEdit}
          notificationTemplate={notificationTemplate}
          onSave={this.save}
        />
      </div>
    );
  };
}

export const NotificationTemplatePage = WithFetchList("getNotificationTemplates", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(NotificationTemplate);

const mapStateToProps = createSelector(
  (state) => state.notificationTemplate,
  (state) => state.userData,
  (notificationTemplate, userData) => ({ notificationTemplate, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(NotificationTemplatePage);
