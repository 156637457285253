import { getCurrentActionPeriod } from ".";

const isCampaignStatusChangesAvailable = (data, actionType) => {
  // if (data.length === 1) {
  //   //returns true if the campaign has a statusChanges for the actionType passed, which has toDate>today
  //   return Boolean(getCurrentActionPeriod(data[0], actionType));
  // }
  // return data.filter((campaign) => campaign.statusChanges?.length > 0).length > 0;

  //Arg: data: selected campaigns List, actionType: selected action
  return (
    data
      // checking for each campaign if statusChanges list has same action, and its toDate is after today
      .map((campaign) => Boolean(getCurrentActionPeriod(campaign, actionType)?.status))
      // if any of the campaign return true for above condition return true else, false
      .some((boolValue) => boolValue === true)
  );
};

export default isCampaignStatusChangesAvailable;
