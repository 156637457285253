import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import ScreenList from "./ScreensList";

class UserScreenList extends Component {
  render = () => {
    return (
      <ScreenList
        {...this.props}
        selectedColumns={["Screen ID", "Screen ERP ID", "Seating Capacity", "Last Updated On"]}
      />
    );
  };
}

const UserScreensListWithFilters = WithFetchList("getUserScreensList", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(UserScreenList);

export { UserScreenList, UserScreensListWithFilters };

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({ screens, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(UserScreensListWithFilters);
