import React from "react";
import { map, isObject, compact, isEmpty } from "lodash";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch, isSlateAdminCompanyUser } from "../../../../utils";
import InfoBlock from "../../../common/InfoBlock";
import appStyles from "../../../../styles/App.module.scss";

const renderAddress = ({ address, cityName, provinceName, countryName }) => {
  if (address) {
    const { street, area, city, post_code, province, country } = address;
    const formatAddress = [
      street && `${street}, ${area ? "area, " : ""}`,
      city && `${city.name},`,
      province && post_code && `${province.name} - ${post_code},`,
      country && `${country.name}`,
    ];
    return compact(formatAddress).map((item, i) => <div key={i}>{item}</div>);
  } else {
    return compact([cityName, provinceName, countryName]).join(", ");
  }
};

const detailInfo = [
  { name: "Name", type: "name" },
  { name: "Legal Name", type: "legal_name" },
  { name: "Type", type: "customerSegment", subType: "name" },
  { name: "Advertiser Type", type: "advertiserTypeName" },
  { name: "Onboarding Status", type: "companyMappingStatus", onlyForSlateAdmin: true },
  { name: "Address", type: "address", action: renderAddress, size: { lg: 12, md: 12 } },
  { name: "Domain", type: "domain" },
  { name: "Email Address", type: "contact", subType: "email" },
  { name: "Country Code", type: "contact", subType: "country_code" },
  { name: "Phone Number", type: "contact", subType: "phone_number" },
];

const validateDetailInfo = (company, userData) => {
  const details = [];
  map(
    detailInfo,
    ({ type, name, action = null, subType = null, size, onlyForSlateAdmin = false }) => {
      if (onlyForSlateAdmin && !isSlateAdminCompanyUser(userData)) return;
      const infoValue = company[type];
      if ((infoValue && subType) || action) {
        if (action) {
          details.push({
            title: name,
            value: action(company),
            size,
          });
        } else if (isObject(infoValue) && infoValue[subType]) {
          details.push({
            title: name,
            value: infoValue[subType],
          });
        }
      } else {
        details.push({
          title: name,
          value: infoValue,
        });
      }
    }
  );

  return details;
};

export const Details = ({ company, userData }) => {
  if (isEmpty(company)) return null;

  return (
    <div className={appStyles.viewPageContainer}>
      <InfoBlock hideHeader config={validateDetailInfo(company, userData)} />
    </div>
  );
};

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Details);
