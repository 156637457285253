import { values } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { searchTags } from "../../../../api";
import { FORM_FIELD_TYPE, TAG_TYPE } from "../../../../constants";
import { getTagTypeDisplayName } from "../../../../utils";

import Form from "../../../common/Form";

class ReportSearchFilterSection extends Component {
  static propTypes = {
    tagType: PropTypes.oneOf(values(TAG_TYPE)).isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  onFilterChange = (filter) => {
    this.props.onFilterChange(filter);
    if (filter?.type)
      ReactGA.event({ category: "filter", action: "right-panel-search", label: filter.type });
  };

  render() {
    const { tagType, selected, filterType } = this.props;
    const placeholder = `Search ${getTagTypeDisplayName(tagType)}`;
    return (
      <Form
        isFormGroup={false}
        config={[
          {
            id: "search",
            label: "",
            type: FORM_FIELD_TYPE.API_SEARCH,
            size: {
              lg: 16,
              md: 16,
            },
            placeholder: placeholder,
            query: selected[0]?.displayName ?? "",
            fetchAction: (text) => searchTags(text, tagType),
            parseResults: (response) =>
              response.map((res) => ({
                ...res,
                name: res.displayName,
                tag: res.type,
                isSingleSelect: filterType.selectionType === "single",
              })),
            onSelect: this.onFilterChange,
            showSubText: true,
          },
        ]}
      />
    );
  }
}

export default ReportSearchFilterSection;
