import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../utils";
import * as constants from "../../../../constants";
import WithFetchList from "../../../hoc/withFetchList";
import ContentTable from "./ContentTable";

class ContentList extends Component {
  componentDidMount = async () => {
    const { fetchData, companyId } = this.props;

    fetchData(null, false, [{ tagId: companyId }]);
  };

  render = () => {
    const {
      selectedColumns,
      reorderedColumns,
      content: { content, isContentLoading },
    } = this.props;
    return (
      <ContentTable
        selectedColumns={selectedColumns}
        reorderedColumns={reorderedColumns}
        data={content}
        isLoading={isContentLoading}
        {...this.props}
      />
    );
  };
}

const ContentTableWithFilters = WithFetchList("getCompanyContent", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(ContentList);

const mapStateToProps = createSelector(
  (state) => state.content,
  (state) => state.userData,
  (content, userData) => ({ content, userData: userData.user })
);

export { ContentTableWithFilters, ContentList };

export default connect(mapStateToProps, bindDispatch)(withRouter(ContentTableWithFilters));
