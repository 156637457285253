/*
This component renders a set of filter chips based on the selected filters.

It takes in the selected filters and a callback function onFilterChange as props.

1.Renders a tag for each selected filter, with the filter name and a remove button.

2.Calls the onFilterChange callback when a filter remove button is clicked, to update the selected filters.

3.Optionally renders a results count message based on the resultsCount prop.

4.Renders a "Clear All" button to remove all filters.

5.Doesn't render anything if there are no selected filters.

6.It also takes optional prop defaultFilterId which is the id of the default filter which should not be removed.
 remove icon is not visible for filter chip with defaultFilterId, clear tag is not shown if only default filter is selected.

It displays the active filters, allows removing filters, and clears all filters.
It is a reusable component for showing selected filters and managing them.
*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag } from "@blueprintjs/core";
import { differenceBy } from "lodash";
import classNames from "classnames";
import { getTagTypeDisplayName, getTagDisplayName } from "../../../utils";
import styles from "../../../styles/FilterChips.module.scss";

class FilterChips extends Component {
  static propTypes = {
    showResultsCount: PropTypes.bool,
    resultsCount: PropTypes.number,
    selected: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    defaultFilterId: PropTypes.string,
  };

  static defaultProps = {
    showResultsCount: false,
    loading: false,
  };

  onRemove = (filters) => {
    const { defaultFilterId } = this.props;
    //deafultFilterId is the id of the default filter which should not be removed
    const filtersWithoutDefault = filters.filter((filter) => filter.id !== defaultFilterId);
    const newSelected = differenceBy(this.props.selected, filtersWithoutDefault, "id");
    this.props.onFilterChange(newSelected);
  };

  renderFilters = () => {
    const { selected, defaultFilterId } = this.props;
    return selected.map((filter) => {
      // console.log("chipFilter", filter);
      return (
        <Tag
          key={filter.id}
          className={classNames(styles.primary, styles.chip)}
          minimal
          onRemove={filter.id !== defaultFilterId ? () => this.onRemove([filter]) : null}
        >
          {getTagTypeDisplayName(filter.type)}: {getTagDisplayName(filter)}
        </Tag>
      );
    });
  };

  renderResultsCount = () => {
    const { showResultsCount, resultsCount } = this.props;
    if (!showResultsCount) return null;
    let r;
    if (resultsCount === 0) r = "No results for";
    else if (resultsCount === 1) r = `Showing ${resultsCount} result for`;
    else r = `Showing ${resultsCount} results for`;
    return <span className={styles.resultsCount}>{r}</span>;
  };

  render() {
    const { selected, loading } = this.props;
    if (!selected || selected.length === 0 || loading) {
      return null;
    }
    return (
      <div className={styles.filterChips}>
        {this.renderResultsCount()}
        {this.renderFilters()}
        {(selected.length > 1 || selected[0].id !== this.props.defaultFilterId) && (
          <Tag
            key="CLEAR-ALL"
            minimal
            className={classNames(styles.danger, styles.chip)}
            interactive
            onClick={() => this.onRemove(selected)}
          >
            Clear
          </Tag>
        )}
      </div>
    );
  }
}

export default FilterChips;
