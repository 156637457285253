import { map } from "lodash";
import { THEATRE_CRITERIA_TYPES } from "../../constants";

const inRange = (start, end, number) => {
  return number > Math.min(start, end) && number < Math.max(start, end);
};

const isSame = (start, end, number) => {
  return number === start || number === end;
};

const getRangeOverlapType = (listData, rangeFrom, rangeTo) => {
  const count = {
    full: 0,
    partial: 0,
    duplicate: 0,
  };

  map(listData, ({ rangeFrom: from, rangeTo: to }) => {
    if (isSame(from, to, rangeFrom || 0) && isSame(from, to, rangeTo || Infinity)) {
      count.duplicate += 1;
    } else if (inRange(from, to, rangeFrom || 0) && inRange(from, to, rangeTo || Infinity)) {
      count.full += 1;
    } else if (inRange(from, to, rangeFrom || 0) || inRange(from, to, rangeTo || Infinity)) {
      count.partial += 1;
    }
  });
  return count;
};

const criteriaErrorList = (criteria, newCriteriaItem, criteriaListType) => {
  const errors = [];
  const { inclusions, exclusions } = criteria;
  const { rangeFrom, rangeTo } = newCriteriaItem;

  if (criteriaListType === "inclusions") {
    const inclusionOverlapCount = getRangeOverlapType(inclusions, rangeFrom, rangeTo);
    const exclusionOverlapCount = getRangeOverlapType(exclusions, rangeFrom, rangeTo);

    if (inclusionOverlapCount.duplicate || exclusionOverlapCount.duplicate) {
      errors.push(`Range appears to be duplicate`);
    } else if (inclusionOverlapCount.full) {
      errors.push(`Added range comes under previously added ${criteriaListType}`);
    } else if (inclusionOverlapCount.partial || exclusionOverlapCount.partial) {
      errors.push(`Added range overlaps with previously added ${criteriaListType}`);
    }
  } else if (criteriaListType === "exclusions") {
    const inclusionOverlapCount = getRangeOverlapType(inclusions, rangeFrom, rangeTo);
    const exclusionOverlapCount = getRangeOverlapType(exclusions, rangeFrom, rangeTo);
    if (inclusionOverlapCount.duplicate || exclusionOverlapCount.duplicate) {
      errors.push(`Range appears to be duplicate`);
    } else if (exclusionOverlapCount.full) {
      errors.push(`Added range comes under previously added ${criteriaListType}`);
    } else if (inclusionOverlapCount.partial || exclusionOverlapCount.partial) {
      errors.push(`Added range overlaps with previously added ${criteriaListType}`);
    }
  }
  return errors;
};

//function returning criteria list previously available for that type
const getCriteriaListByType = (data, type) => {
  const { inclusions, exclusions } = data;
  // return {
  //   inclusions: map(inclusions, (item) => item[type] || item),
  //   exclusions: map(exclusions, (item) => item[type] || item),
  // };
  //SLATE-1436 criteria list should be filtered by type
  if (
    type === THEATRE_CRITERIA_TYPES.TICKET_PRICE ||
    type === THEATRE_CRITERIA_TYPES.SEATING_CAPACITY
  ) {
    return {
      inclusions: inclusions.filter((item) => item.type === type),
      exclusions: exclusions.filter((item) => item.type === type),
    };
  } else
    return {
      inclusions: inclusions
        .filter((item) => item.type === THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS)
        .map((item) => item[type]),
      exclusions: exclusions
        .filter((item) => item.type === THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS)
        .map((item) => item[type]),
    };
};

const validateTheatreCriteria = (criteria, newCriteriaItem, criteriaListType) => {
  const { inclusions, exclusions } = criteria;
  const { type, name, id, typeDisplayName } = newCriteriaItem;
  const errors = [];
  const { TICKET_PRICE, SEATING_CAPACITY, AUDITORIUM_DIMENSIONS } = THEATRE_CRITERIA_TYPES;

  if (type === TICKET_PRICE) {
    const priceErrors = criteriaErrorList(
      getCriteriaListByType(criteria, type),
      newCriteriaItem,
      criteriaListType
    );
    errors.push(...priceErrors);
  } else if (type === SEATING_CAPACITY) {
    const capacityErrors = criteriaErrorList(
      getCriteriaListByType(criteria, type),
      newCriteriaItem,
      criteriaListType
    );
    errors.push(...capacityErrors);
  } else if (type === AUDITORIUM_DIMENSIONS) {
    const { auditoriumWidth, auditoriumLength, auditoriumHeight } = newCriteriaItem;
    const widthErrors = criteriaErrorList(
      getCriteriaListByType(criteria, "auditoriumWidth"),
      { ...auditoriumWidth, type: "auditoriumWidth" },
      criteriaListType
    );
    //SLATE-1436 Dec12 Anushree:- auditoriumLength should be checked for lengthErrors
    const lengthErrors = criteriaErrorList(
      //   getCriteriaListByType(criteria, "auditoriumWidth"),
      //   { ...auditoriumLength, type: "auditoriumWidth" },
      getCriteriaListByType(criteria, "auditoriumLength"),
      { ...auditoriumLength, type: "auditoriumLength" },
      criteriaListType
    );
    const heightErrors = criteriaErrorList(
      getCriteriaListByType(criteria, "auditoriumHeight"),
      { ...auditoriumHeight, type: "auditoriumHeight" },
      criteriaListType
    );
    //SLATE-1436 Dec12 Anushree:- additonal string added to differentiate for individual dimensions
    if (widthErrors.length !== 0) {
      widthErrors[0] = `W (ft): ${widthErrors[0]}`;
    }
    if (lengthErrors.length !== 0) {
      lengthErrors[0] = `L (ft): ${lengthErrors[0]}`;
    }
    if (heightErrors.length !== 0) {
      heightErrors[0] = `H (ft): ${heightErrors[0]}`;
    }
    errors.push(...widthErrors, ...lengthErrors, ...heightErrors);
  } else if (exclusions.map((data) => data.id).includes(id)) {
    errors.push(`${name ? name : ""} ${typeDisplayName} is added already in exclusions`);
  } else if (inclusions.map((data) => data.id).includes(id)) {
    errors.push(`${name ? name : ""} ${typeDisplayName} is added already in inclusions`);
  }

  return errors;
};

export { validateTheatreCriteria };
