import React, { Component } from "react";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import PageHeader from "../../common/PageHeader";
import Breadcrumb from "../../common/BreadCrumb";
import TabView from "../../common/Tabs";
import AdminGenerated from "./AdminGenerated";
import UserGenerated from "./UserGenerated";

class GeneratedReports extends Component {
  render() {
    const {
      history,
      match: { params },
    } = this.props;

    const tabs = [
      {
        id: "admin",
        title: "Admin Generated",
        goto: "/reports/generated/admin",
        panel: <AdminGenerated />,
      },
      {
        id: "user",
        title: "User Generated",
        goto: "/reports/generated/user",
        panel: <UserGenerated />,
      },
    ];

    const breadcrumbs = [{ name: "Reports", path: `/reports` }, { name: "Generated Reports" }];

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={breadcrumbs} />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <PageHeader name="Generated Reports" />
          <TabView id="navbar" selectedTabId={params.tab || "admin"} tabs={tabs} />
        </div>
      </div>
    );
  }
}

export default GeneratedReports;
