import * as types from "../types/content";

const initialState = {
  isContentLoading: false,
  isCreateContentLoading: false,
  isError: null,
  content: {
    data: [],
  },
  archivedContent: {
    data: [],
  },
  languages: [],
  contentDetails: {},
  editContent: { isOpen: false, content: {} },
  mapBrand: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.IS_CONTENT_LOADING:
      return { ...state, isContentLoading: payload };
    case types.IS_CREATE_CONTENT_LOADING:
      return { ...state, isCreateContentLoading: payload };
    case types.IS_CONTENT_ERROR:
      return { ...state, isError: payload };
    case types.CONTENT:
      return { ...state, content: payload };
    case types.ARCHIVED_CONTENT:
      return { ...state, archivedContent: payload };
    case types.LANGUAGES:
      return { ...state, languages: payload };
    case types.CONTENT_DETAILS:
      return { ...state, contentDetails: payload };
    case types.EDIT_CONTENT:
      return { ...state, editContent: payload };
    case types.MAP_BRAND:
      return { ...state, mapBrand: payload };
    default:
      return state;
  }
}
