import * as types from "../types/timesOfDay";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  isError: null,
  data: [],
  timesOfDayEdit: {
    isOpen: false,
    timesOfDay: { name: "", isDefault: false, startTime: "00:00", endTime: "00:00" },
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.DATA:
      return { ...state, data: payload };
    case types.EDIT:
      return { ...state, timesOfDayEdit: payload };
    default:
      return state;
  }
}
