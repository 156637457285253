import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import ReportCard from "./ReportCard";
import ReportCardLoading from "./ReportCardLoading";

class ReportList extends React.Component {
  async componentDidMount() {
    this.props.actions.getUserReports();
  }
  render() {
    const {
      reports: {
        isLoading,
        userGenerated: { data = [] },
      },
    } = this.props;
    return (
      <div style={{ padding: "10px" }}>
        {isLoading && data.length === 0 ? (
          <>
            {Array.from({ length: 8 }).map((_, i) => (
              <ReportCardLoading key={i} />
            ))}
          </>
        ) : (
          data.map((item) => <ReportCard report={item} key={item.id} />)
        )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.reports,
  (state) => state.userData,
  (reports, userData) => ({ reports, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ReportList);
