/*
Sep5-2023 Anushree:-
1. DateRangeFilterSection class handels date change in filter component 
2. calledBy- FilterSection.js
*/
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { FORM_FIELD_TYPE, MAX_DATE } from "../../../constants";
import styles from "../../../styles/FilterSection.module.scss";
import { formatDateTime } from "../../../utils";
import Form from "../../common/Form";

class DateRangeFilterSection extends Component {
  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    isSingleInput: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  onChange = (value, key) => {
    const { tagType, onFilterChange, selected } = this.props;
    const [selectedDate] = selected;
    const dates = { ...selectedDate, [key]: value };
    let displayName = "";
    if (dates.from) displayName += formatDateTime(dates.from, "MMM DD, YYYY");
    if (dates.from && dates.to) displayName += ` - `;
    if (dates.to) displayName += formatDateTime(dates.to, "MMM DD, YYYY");
    const tag = { ...dates, id: tagType, type: tagType, displayName };
    onFilterChange(tag, dates.from || dates.to);
    if (dates.from || dates.to)
      ReactGA.event({ category: "filter", action: "date", label: tagType });
  };

  onDateRangeChange = (dateRange, key) => {
    const [from, to] = dateRange;
    if (from) this.onChange(from, "from");
    if (to) this.onChange(to, "to");
  };

  render() {
    const { selected, isSingleInput } = this.props;
    const [selectedDate = {}] = selected;
    //Refractoring: Renaming startDate->FromDate, endDate->ToDate
    //const startDate = selectedDate?.from ? new Date(selectedDate?.from) : new Date();
    //const endDate = selectedDate?.to ? new Date(selectedDate?.to) : new Date(MAX_DATE);
    const fromDate = selectedDate?.from ? new Date(selectedDate?.from) : new Date();
    const toDate = selectedDate?.to ? new Date(selectedDate?.to) : new Date(MAX_DATE);
    return (
      <Form
        config={
          isSingleInput
            ? [
                {
                  id: "dateRange",
                  type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                  size: {
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12,
                  },
                  handleDateChange: (dateRange) => this.onDateRangeChange(dateRange, "dateRange"),
                  dateRange: [
                    selectedDate?.from ? selectedDate?.from : null,
                    selectedDate?.to ? selectedDate?.to : null,
                  ],
                  //error: hasFormSaved && !targetGroup.validity,
                },
              ]
            : [
                {
                  type: FORM_FIELD_TYPE.DATE,
                  title: "From",
                  onChange: (v) => this.onChange(v, "from"),
                  value: selectedDate.from || null,
                  placeholder: "MMM DD, YYYY",
                  //maxDate: endDate,
                  //defaultValue: startDate,
                  maxDate: toDate,
                  defaultValue: fromDate,
                },
                {
                  type: FORM_FIELD_TYPE.DATE,
                  title: "To",
                  onChange: (v) => this.onChange(v, "to"),
                  value: selectedDate.to || null,
                  disabled: !selectedDate.from,
                  containerClassName: styles.toDate,
                  placeholder: "MMM DD, YYYY",
                  //minDate: startDate,
                  //initialMonth: startDate,
                  //defaultValue: startDate,
                  minDate: fromDate,
                  initialMonth: fromDate,
                  defaultValue: fromDate,
                },
              ]
        }
      ></Form>
    );
  }
}

export default DateRangeFilterSection;
