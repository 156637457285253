import { Icons } from "prefab";

const { ScheduleDeliveryIcon, ScheduleIngestIcon } = Icons;
const SCHEDULE_ERRORS = {
  "segment.overflow": "Segment is full for ",
  "position.unavailable": "Placement position is not available for",
  "screen.unavailable": "Cannot schedule for ",
  "advertiserTypeGovt.ineligible": "Compositions with Government Advertiser Type is ineligible for",
};

const SCHEDULE_STATUSES = {
  // CREATED: "Created",
  // DELIVERED: "Delivered",
  // EXPIRED: "Expired",
  // FAILED: "Failed",
  SCHEDULED: "Scheduled",
  DELIVERED: "Delivered",
  DELIVERY_FAILED: "DeliveryFailed",
  INGESTED: "Ingested",
  INGEST_FAILED: "IngestFailed",
};

const SCHEDULE_STATUS_LIST = [
  // {
  //   displayName: "Created",
  //   id: SCHEDULE_STATUSES.CREATED,
  // },
  // {
  //   displayName: "Delivered",
  //   id: SCHEDULE_STATUSES.DELIVERED,
  // },
  // {
  //   displayName: "Expired",
  //   id: SCHEDULE_STATUSES.EXPIRED,
  // },
  // {
  //   displayName: "Failed",
  //   id: SCHEDULE_STATUSES.FAILED,
  // },
  {
    displayName: "Scheduled",
    id: SCHEDULE_STATUSES.SCHEDULED,
    icon: ScheduleDeliveryIcon,
    color: "yellow",
  },
  {
    displayName: "Delivered",
    id: SCHEDULE_STATUSES.DELIVERED,
    icon: ScheduleDeliveryIcon,
    color: "green",
  },
  {
    displayName: "Delivery Failed",
    id: SCHEDULE_STATUSES.DELIVERY_FAILED,
    icon: ScheduleDeliveryIcon,
    color: "red",
  },
  {
    displayName: "Ingested",
    id: SCHEDULE_STATUSES.INGESTED,
    icon: ScheduleIngestIcon,
    color: "green",
  },
  {
    displayName: "Ingest Failed",
    id: SCHEDULE_STATUSES.INGEST_FAILED,
    icon: ScheduleIngestIcon,
    color: "red",
  },
];

const SCHEDULE_MISSING_DATA = {
  displayName: "Missing Data",
  id: true,
};

export { SCHEDULE_ERRORS, SCHEDULE_MISSING_DATA, SCHEDULE_STATUSES, SCHEDULE_STATUS_LIST };
