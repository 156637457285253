import {
  getCPLs as getCPLsApi,
  ignoreCPL as ignoreCPLApi,
  getCPLById,
  mapCPL as mapCPLApi,
  getMovieCPLs,
} from "../../api";
import * as types from "../types/cpls";
import * as constants from "../../constants";

import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

const updateCPLs = (payload) => {
  return { type: types.CPLS, payload: payload };
};

const getCPLs = (params = {}, filters = [], getList = getCPLsApi, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", status } = params;
  return async (dispatch) => {
    try {
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, true))
        : dispatch(actionCreator(types.IS_LOADING, true));
      const { data } =
        status === constants.CPL_STATUSES.MOVIE_MAPPED
          ? await getMovieCPLs(ps, (page - 1) * ps, sort, filters)
          : await getList(status, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCPLs(data));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      isPartial
        ? dispatch(actionCreator(types.IS_PARTIAL_LOADING, false))
        : dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

// resets cpls to [], to avoid the new path to use old cpls data
const resetCPLs = () => {
  return async (dispatch) => {
    dispatch(updateCPLs([]));
  };
};

const ignoreCPL = (id, ignore = ignoreCPLApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_PARTIAL_LOADING, true));
      await ignore(id);
      showToast("Toast.CPLIgnored");
      dispatch(actionCreator(types.IGNORE_CPL, id));
      dispatch(actionCreator(types.IS_PARTIAL_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      showToast(error.message, false);
      dispatch(actionCreator(types.IS_PARTIAL_LOADING, false));
    }
  };
};

const updateCPL = (payload) => {
  return { type: types.MAP_CPL, payload: payload };
};

const openMapCPLId = (cplId) => {
  return async (dispatch) => {
    try {
      dispatch(updateCPL({ isOpen: true, isEditLoading: true }));
      const { data } = await getCPLById(cplId);
      dispatch(updateCPL({ isOpen: true, cpl: data, isEditLoading: false }));
    } catch (error) {
      dispatch(updateCPL({ isOpen: false, isEditLoading: true }));
      dispatch(actionCreator(types.IS_ERROR, error.message));
    }
  };
};

const openMapCPL = (cpl) => (dispatch) =>
  dispatch(updateCPL({ isOpen: true, isEditLoading: false, cpl }));

const cancelMapCPL = () => {
  return (dispatch) => {
    dispatch(
      updateCPL({
        isOpen: false,
        cpl: {},
      })
    );
  };
};

const mapCPL = (id, map, close) => {
  return async (dispatch) => {
    try {
      const { mappedResource, advertiserIds } = map;
      dispatch(updateCPL({ isOpen: true, isEditLoading: true }));
      await mapCPLApi(id, { mappedResource, advertiserIds });
      showToast("Toast.cplMapped");
      getCPLs();
      close(true);
    } catch (error) {
      showToast(error.message, false);
      dispatch(updateCPL({ isOpen: true, isEditLoading: false }));
    }
  };
};

export { getCPLs, ignoreCPL, openMapCPL, openMapCPLId, cancelMapCPL, mapCPL, resetCPLs };
