import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, Classes } from "@blueprintjs/core";
import { FormattedMessage } from "react-intl";
import { IconNames } from "@blueprintjs/icons";
import { BaseButton } from "prefab";
import { BUTTON_TYPE, SIZE } from "prefab";
import styles from "../../styles/Popup.module.scss";
import ButtonStyles from "../../styles/Button.module.scss";

class Popup extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.node,
    body: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isCloseButtonShown: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
    icon: IconNames.INFO_SIGN,
    showIcon: true,
    cancelButtonText: "Popup.cancel",
    confirmButtonText: "Popup.ok",
  };

  render() {
    const {
      isOpen,
      icon,
      showIcon,
      title,
      body,
      onClose,
      onConfirm,
      cancelButtonText,
      confirmButtonText,
      isCloseButtonShown,
    } = this.props;
    const content = typeof body === "string" ? <FormattedMessage id={body} /> : body;
    let popupTitle;
    if (title) popupTitle = typeof title === "string" ? <FormattedMessage id={title} /> : title;
    return (
      <Dialog
        isOpen={isOpen}
        icon={showIcon && icon}
        onClose={onClose}
        isCloseButtonShown={isCloseButtonShown}
        title={popupTitle}
        className={styles.popup}
      >
        <div className={Classes.DIALOG_BODY}>{content}</div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {cancelButtonText && (
              <BaseButton
                onClick={onClose}
                buttonText={
                  <FormattedMessage id={cancelButtonText} defaultMessage={cancelButtonText} />
                }
                buttonSize={SIZE.SMALL}
                className={ButtonStyles.buttonSpacing}
              />
            )}
            {confirmButtonText && (
              <BaseButton
                onClick={onConfirm}
                buttonText={
                  <FormattedMessage id={confirmButtonText} defaultMessage={confirmButtonText} />
                }
                buttonSize={SIZE.SMALL}
                buttonType={BUTTON_TYPE.PRIMARY}
              />
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default Popup;
