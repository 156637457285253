import React, { Component } from "react";
import { getTagByID } from "../../../../api";
import styles from "../../../../styles/ActiveTableCell.module.scss";
import { BRAND_TAG_TYPE } from "./brandConstants";

class DuplicateBrandsList extends Component {
  state = {
    tagType: "",
  };
  componentDidMount = async () => {
    const { data: brand } = this.props;
    const { data } = await getTagByID(brand.id);
    this.setState({
      tagType: BRAND_TAG_TYPE[data.type],
    });
  };

  render() {
    const { data, brandValues, index } = this.props;
    const { tagType } = this.state;
    return (
      <a
        className={styles.highlight}
        href={`/brands/${tagType}/${data.id}/details`}
        target="_blank"
        rel="noopener noreferrer"
      >{`${data.name} ${index < brandValues.length - 1 ? " • " : ""}`}</a>
    );
  }
}
export default DuplicateBrandsList;
