/*
It renders various input fields like name, validity date range, order numbers etc. to capture the schedule details.

It allows selecting an existing composition using the API_SEARCH field.

It shows an option to upload a new composition using the onUploadCpl callback.

It displays any validation errors.

The schedule details are passed in as props and updated via callbacks like onInputChange.

There are some helper methods like formatDateTime, getTimeFromSeconds etc used.

It handles mobile vs desktop view differences using the isMobile prop.

Overall it allows creating/editing a schedule by capturing all the required details through a form.
 */
import React, { Component } from "react";
import moment from "moment";
import { TimePrecision } from "@blueprintjs/datetime";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import InfoBlock from "../../../common/InfoBlock";
import Form from "../../../common/Form";
import { FORM_FIELD_TYPE, TAG_TYPE, SCOPES, SCOPE_ACTIONS } from "../../../../constants";
import { formatDateTime } from "../../../../utils";
import styles from "../../../../styles/Schedules/scheduleDetails.module.scss";
import { searchCompositions } from "../../../../api";
import { getTimeFromSeconds } from "../../../../utils/timeUtils";

export default class ScheduleDetails extends Component {
  state = {
    filters: [],
  };

  static propTypes = {};

  static defaultProps = {};

  handleUploadPanelOpen = () => {
    if (this.props.onUploadCpl) {
      this.props.onUploadCpl(true);
    }
  };

  // TODO: Update the error messages with actual new UI design
  render() {
    const {
      hasFormSaved,
      scheduleDetails,
      onInputChange,
      duplicateScheduleDetails,
      isMobile,
      errors,
      userData,
      onUploadCpl,
    } = this.props;
    const date = new Date(moment().add(1, "day").format("MMM DD, YYYY HH:mm"));
    return (
      <>
        <div className={styles.errors}>
          {errors.map((error) => (
            <FormattedMessage id={error.errorCode} />
          ))}
        </div>
        <InfoBlock header="Schedule Details" childClassName={styles.scheduleWrapper}>
          <div className={classNames(styles.scheduleDetails)}>
            <Form
              isFormGroup={false}
              childContainerClassName={styles.childContainer}
              config={[
                [
                  {
                    id: "duplicateSchedule",
                    label: "Duplicate From",
                    type: FORM_FIELD_TYPE.TAG_SEARCH,
                    showSubText: false,
                    resetOnSelect: false,
                    tagTypes: TAG_TYPE.SCHEDULE,
                    className: isMobile ? "col-12" : "col-5",
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 12,
                      md: 12,
                    },
                    placeholder: "Enter Schedule Name",
                    isHidden: !!scheduleDetails.id,
                    onSelect: (schedule) => duplicateScheduleDetails(schedule.id),
                  },
                  {
                    id: "name",
                    label: "Schedule Order Name",
                    type: FORM_FIELD_TYPE.INPUT,
                    className: isMobile ? "col-12" : "col-11",
                    maxLength: 70,
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    placeholder: "Enter Schedule Order Name",
                    value: scheduleDetails.name || "",
                    errorMessage: "Please Enter Schedule Order Name",
                    error: hasFormSaved && !scheduleDetails.name,
                    onChange: (e) => onInputChange(e.target.value, "name"),
                  },
                  {
                    id: "validity",
                    type: FORM_FIELD_TYPE.CUSTOM_DATE_RANGE_PICKER,
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    title: "Validity",
                    className: classNames(styles.validity, isMobile ? "col-12" : "col-11"),
                    handleClearDateSelection: (type) => onInputChange(null, "validity", type),
                    startDateProps: {
                      onChange: (date) => onInputChange(date, "validity", "validFrom"),
                      formatDate: (date) => moment(date).format("MMM DD, YYYY HH:mm"),
                      defaultDate: new Date(date.setHours(0, 0)),
                      //SLATE-1349:25-09-23: Fix default date to null if the from-date is in the past.
                      value: scheduleDetails.validFrom ? new Date(scheduleDetails.validFrom) : null,
                      timePrecision: TimePrecision.MINUTE,
                      timePickerProps: {
                        precision: TimePrecision.MINUTE,
                        disabled: !scheduleDetails.validFrom,
                        defaultValue: new Date(date.setHours(0, 0)),
                      },
                      disabled: !!scheduleDetails.id,
                      initialMonth: scheduleDetails.validFrom
                        ? moment(scheduleDetails.validFrom).add(1, "day").toDate()
                        : moment().add(1, "day").toDate(),
                      showClearIcon: true,
                      closeOnSelection: false,
                      error: hasFormSaved && !scheduleDetails.validFrom,
                      placeholder: "Start Date",
                      errorMessage: "Please Select Start Date",
                      minDate: scheduleDetails.validFrom
                        ? new Date(scheduleDetails.validFrom)
                        : new Date(),
                    },
                    endDateProps: {
                      onChange: (date) => onInputChange(date, "validity", "validTo"),
                      formatDate: (date) => moment(date).format("MMM DD, YYYY HH:mm"),
                      defaultDate: new Date(date.setHours(23, 59)),
                      //SLATE-1349:25-09-23: Fix default date to null if the to-date is in the past.
                      value:
                        scheduleDetails.validTo && new Date(scheduleDetails.validTo) >= new Date()
                          ? new Date(scheduleDetails.validTo)
                          : null,
                      timePrecision: TimePrecision.MINUTE,
                      timePickerProps: {
                        precision: TimePrecision.MINUTE,
                        disabled: !scheduleDetails.validTo,
                        defaultValue: new Date(date.setHours(23, 59)),
                      },
                      disabled: isEmpty(
                        scheduleDetails?.validFrom ||
                          scheduleDetails?.validFrom === scheduleDetails?.validTo
                      ),
                      initialMonth: scheduleDetails.validFrom
                        ? moment(scheduleDetails.validFrom).add(1, "day").toDate()
                        : moment().add(1, "day").toDate(),
                      showClearIcon: true,
                      closeOnSelection: false,
                      error: hasFormSaved && !scheduleDetails.validTo,
                      placeholder: "End Date",
                      errorMessage: "Please Select End Date",
                      minDate: scheduleDetails.validFrom
                        ? new Date(moment(scheduleDetails.validFrom).add(1, "day").toDate())
                        : new Date(
                            formatDateTime(`${moment().add(1, "day").format("YYYY-MM-DD")} 00:00`)
                          ),
                    },
                  },
                ],
                [
                  {
                    id: "orderNumber",
                    label: "Order Number",
                    type: FORM_FIELD_TYPE.INPUT,
                    className: isMobile ? "col-12" : "col-11",
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    placeholder: "Enter Order Number",
                    maxLength: 70,
                    value: scheduleDetails.orderNumber || "",
                    isOptional: true,
                    onChange: (e) => onInputChange(e.target.value, "orderNumber"),
                  },
                  {
                    id: "suborderNumber",
                    label: "Suborder Number",
                    type: FORM_FIELD_TYPE.INPUT,
                    className: isMobile ? "col-12" : "col-11",
                    maxLength: 70,
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    placeholder: "Enter Suborder Number",
                    value: scheduleDetails.subOrderNumber || "",
                    isOptional: true,
                    onChange: (e) => onInputChange(e.target.value, "subOrderNumber"),
                  },
                ],
                [
                  {
                    id: "scheduleNumber",
                    label: "Schedule Number",
                    type: FORM_FIELD_TYPE.INPUT,
                    className: isMobile ? "col-12" : "col-11",
                    maxLength: 70,
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    placeholder: "Enter Schedule Number",
                    value: scheduleDetails.scheduleNumber || "",
                    errorMessage: "Please Enter Schedule Number",
                    error: hasFormSaved && !scheduleDetails.scheduleNumber,
                    onChange: (e) => onInputChange(e.target.value, "scheduleNumber"),
                  },
                  {
                    id: "customerPurchaseOrderNumber",
                    label: "Customer Purchase Order Number",
                    type: FORM_FIELD_TYPE.INPUT,
                    className: isMobile ? "col-12" : "col-11",
                    maxLength: 70,
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                    },
                    placeholder: "Enter Customer Purchase Order Number",
                    value: scheduleDetails.customerPurchaseOrderNumber || "",
                    errorMessage: "Please Enter Customer Purchase Order Number",
                    error: hasFormSaved && !scheduleDetails.customerPurchaseOrderNumber,
                    isOptional: scheduleDetails?.advertiserTypeName !== "Government",
                    onChange: (e) => onInputChange(e.target.value, "customerPurchaseOrderNumber"),
                  },
                ],
                [
                  {
                    id: "composition",
                    label: "Composition",
                    type: FORM_FIELD_TYPE.API_SEARCH,
                    className: "col-12",
                    containerClassName: styles.inputContainer,
                    size: {
                      lg: 5,
                      md: 5,
                      sm: 12,
                      xs: 12,
                    },
                    placeholder: "Select Existing Composition",
                    resetOnSelect: false,
                    showSubText: true,
                    clearSelectOnReset: true,
                    selectedItem: {
                      id: scheduleDetails.cplId,
                      name: scheduleDetails.cplName,
                    },
                    errorMessage: "Please Select Composition",
                    error: hasFormSaved && !scheduleDetails.cplId,
                    onSelect: (data) => onInputChange(data, "composition"),
                    query: scheduleDetails?.cplName ?? "",
                    parseResults: (data) =>
                      data.data.map((item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                        tag: getTimeFromSeconds(item.durationInSeconds),
                      })),
                    fetchAction: (value) => searchCompositions(value),
                  },
                  // This code is commented to remove the "(or)" label text near composition field.
                  // {
                  //   id: "label",
                  //   type: FORM_FIELD_TYPE.LABEL,
                  //   value: "",
                  //   size: { xl: 1, lg: 1, md: 1, sm: 12, xs: 12 },
                  //   className: styles.cplLabelText,
                  //   containerClassName: styles.labelContainer,
                  // },
                  {
                    id: "uploadCPL",
                    type: FORM_FIELD_TYPE.BUTTON,
                    scope: SCOPES.COMPOSITIONS,
                    scopeAction: SCOPE_ACTIONS.WRITE,
                    iconName: "AddFilledIcon",
                    text: "Button.uploadNewCPL",
                    userData: userData,
                    containerClassName: styles.addButton,
                    size: {
                      lg: 2,
                      md: 2,
                      sm: 12,
                      xs: 12,
                    },
                    disabled: false,
                    onClick: onUploadCpl,
                  },
                ],
              ]}
            />
          </div>
        </InfoBlock>
      </>
    );
  }
}
