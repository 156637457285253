/*
Spot Status is a general component that can be used to display the spot information in table format.
1. It is called from CampaignSpotStatus.js
*/
import classNames from "classnames";
import { includes } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { Table } from "workbench";
import { DEFAULT_PAGE_SIZE, TAG_TYPE } from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import { checkScopes, formatDateTime, modifyTableColumns } from "../../../../utils";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import { getStatusDisplay, getStatusIconLegend } from "../utils";
import { safeFormatDateTime } from "../../../../utils/formatDateTime";

class SpotStatus extends Component {
  constructor(props) {
    super(props);
    const { mediaId, targetGroupId } = this.props.match.params;
    const defaultColumns = [
      "Theatre • Screen",
      "Date • Day",
      "Show Time",
      "Playlist • Playlist Pack • Segment",
      "Segment Type",
      "Position",
      "Spot Status",
      "Content Status",
      "Schedule Status",
      "Play Log Status",
      "Target Group",
      "Media",
      "Content",
    ];
    const selectedColumns = defaultColumns.filter((column) => {
      if (mediaId && (column === "Media" || column === "Target Group")) return false;
      if (targetGroupId && column === "Target Group") return false;
      return true;
    });
    this.state = { selectedColumns };
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      history,
      data: { isLoading, spotStatus },
      hiddenColumns,
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      onFilterChange,
    } = this.props;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = spotStatus?.totalCount || 0;

    const columns = checkScopes(
      [
        {
          id: "screenName",
          Header: "Theatre • Screen",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.theatreId, d.theatreName, () =>
                this.props.onFilterIdSelect(d.theatreId)
              )}{" "}
              •{" "}
              {ActiveTableCell(d.screenId, d.screenName, () =>
                this.props.onFilterIdSelect(d.screenId)
              )}
            </>
          ),
          width: 200,
        },
        {
          id: "showDate",
          Header: "Date • Day",
          accessor: (d) => (
            <>
              <div> {formatDateTime(`${d.showDate}`, "DD MMM YYYY")}</div>
              <div style={{ color: "grey" }}>{d.day}</div>
            </>
          ),
          width: 145,
        },
        {
          id: "startTime",
          Header: "Show Time",
          accessor: (d) => (
            <>
              <div> {formatDateTime(`${d.startTime}`, "hh:mm A", true)}</div>
              <div style={{ color: "grey" }}>{d.show}</div>
            </>
          ),
          width: 145,
        },
        {
          id: "playList_playListPack_Segment",
          Header: "Playlist • Playlist Pack • Segment",
          accessor: (d) => (
            <>
              {d.playlistTemplateName} • {d.playlistPackName} • {d.segmentName}
            </>
          ),
          width: 270,
        },
        {
          id: "segmentTypeName",
          Header: "Segment Type",
          accessor: (d) => <>{d.segmentTypeName}</>,
          width: 170,
        },
        {
          id: "position",
          Header: "Position",
          accessor: (d) => <>{d.position}</>,
          width: 130,
        },
        {
          id: "spotStatus",
          Header: "Spot Status",
          accessor: (d) => (
            <>
              {getStatusDisplay(
                d.spotStatus,
                this.props.onFilterSelect,
                TAG_TYPE.SPOT_STATUS,
                true
              )}
              {d.spotStatus ? (
                <div style={{ color: "grey" }}>
                  {safeFormatDateTime(`${d.playbackUpdatedON}`, "hh:mm A", true)}
                </div>
              ) : null}
            </>
          ),

          width: 150,
        },
        {
          id: "contentStatus",
          Header: "Content Status",
          accessor: (d) =>
            getStatusDisplay(
              d.contentStatus,
              this.props.onFilterSelect,
              TAG_TYPE.CONTENT_STATUS,
              true
            ),

          width: 170,
        },
        {
          id: "scheduleStatus",
          Header: "Schedule Status",
          accessor: (d) => (
            <>
              {getStatusDisplay(
                d.scheduleStatus,
                this.props.onFilterSelect,
                TAG_TYPE.SCHEDULE_STATUS,
                true
              )}
              {d.scheduleStatus ? (
                <div style={{ color: "grey" }}>
                  {safeFormatDateTime(`${d.scheduleUpdatedON}`, "hh:mm A", true)}
                </div>
              ) : null}
            </>
          ),

          width: 175,
        },
        {
          id: "playLogStatus",
          Header: "Play Log Status",
          accessor: (d) => (
            <>
              {getStatusDisplay(
                d.playLogStatus,
                this.props.onFilterSelect,
                TAG_TYPE.PLAYLOG_STATUS,
                true
              )}
              {d.playLogStatus ? (
                <div style={{ color: "grey" }}>
                  {safeFormatDateTime(`${d.playlogUpdatedON}`, "hh:mm A", true)}
                </div>
              ) : null}
            </>
          ),
          width: 175,
        },
        {
          id: "targetGroupName",
          Header: "Target Group",
          accessor: (d) => <>{d.targetGroupName}</>,
          width: 160,
        },
        {
          id: "mediaName",
          Header: "Media",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.mediaID, d.mediaName, () =>
                this.props.onFilterIdSelect(d.mediaID)
              )}
            </>
          ),

          width: 140,
        },
        {
          id: "cplName",
          Header: "Content",
          accessor: (d) => (
            <>{ActiveTableCell(d.cplId, d.cplName, () => this.props.onFilterIdSelect(d.cplId))}</>
          ),
          width: 150,
        },
        {
          id: "contentTypeName",
          Header: "Content Type",
          accessor: (d) => <>{d.contentTypeName}</>,
          width: 170,
        },
        {
          id: "durationInSeconds",
          Header: "Duration",
          accessor: (d) => <>{d.durationInSeconds}</>,
          width: 150,
        },
        {
          id: "movie",
          Header: "Movie",
          accessor: (d) => <>{d.movie}</>,
          width: 150,
        },
      ],
      userData
    ).filter((column) => !includes(hiddenColumns, column.Header));

    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageTableContainer)}>
        <div className={pageStyles.statusGroup}>
          {getStatusIconLegend(
            spotStatus?.data,
            TAG_TYPE.SPOT_STATUS,
            this.props.onFilterSelect,
            "spotStatus"
          )}
        </div>
        <TableControls
          seletable={true}
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={spotStatus}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[
            TAG_TYPE.SHOW_DATE,
            TAG_TYPE.SCHEDULE_STATUS,
            TAG_TYPE.CONTENT_STATUS,
            TAG_TYPE.PLAYLOG_STATUS,
          ]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={onFilterChange}
          defaultFilterId={TAG_TYPE.SHOW_DATE}
        />
        <Table
          data={spotStatus?.data}
          loading={isLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
        />
        <FooterControls
          pagination
          data={spotStatus}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
      </div>
    );
  };
}

SpotStatus.propTypes = {
  data: PropTypes.shape({
    spotStatus: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
};

export default SpotStatus;
