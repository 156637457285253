import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../utils";
import WithFetchList from "../../../hoc/withFetchList";
import PageHeader from "../../../common/PageHeader";
import pageStyles from "../../../../styles/App.module.scss";
import ContentTable from "../common/ContentTable";
import EditContent from "./EditContent";

class ArchivedContentList extends Component {
  componentDidMount = async () => {
    const {
      actions,
      match: { params },
      fetchData,
    } = this.props;
    fetchData(null, false, [{ archived: true }]);
    if (params.id) actions.getContentById(params, true);
  };
  mapBrand = (brand) => {
    const { actions, history } = this.props;
    history.push(`/archived-content/${brand.id}`);
    actions.updateMapBrand({ isOpen: true, brand });
    actions.getContentById({ id: brand.id }, true);
  };
  onCancel = () => {
    const { actions, history } = this.props;
    actions.updateMapBrand({ isOpen: false, brand: {} });
    history.push(`/archived-content`);
  };
  render() {
    const {
      actions,
      content: {
        archivedContent,
        languages,
        contentDetails,
        editContent,
        isContentLoading,
        isCreateContentLoading,
        mapBrand,
      },
    } = this.props;
    return (
      <div>
        <PageHeader name="PageHeader.archivedContent" count={archivedContent.totalCount} />
        <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
          <ContentTable
            data={archivedContent}
            isLoading={isContentLoading}
            onAssign={this.mapBrand}
            {...this.props}
          />
          <EditContent
            actions={actions}
            isLoading={isCreateContentLoading}
            editContent={editContent}
            languages={languages}
            mapBrand={mapBrand}
            content={contentDetails}
            onCancel={this.onCancel}
          />
        </div>
      </div>
    );
  }
}
const ArchivedContentWithFilters = WithFetchList("getArchivedContent", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
})(ArchivedContentList);

const mapStateToProps = createSelector(
  (state) => state.content,
  (state) => state.userData,
  (content, userData) => ({ content, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(ArchivedContentWithFilters));
