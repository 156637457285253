import { fetchCampaignTargetTypes, saveCampaignTargetType, getCampaignTargetType } from "../../api";
import * as types from "../types/campaignTargetType";
import { showToast } from "../../utils";

const updateCampaignList = (payload) => {
  return { type: types.DATA, payload: payload };
};

const actionCreator = (action) => action;

const addCampaignTargetType = () => (dispatch) =>
  dispatch(
    actionCreator({
      type: types.CAMPAIGN_TARGET_TYPE_EDIT,
      payload: {
        isOpen: true,
        campaignTargetType: { name: "" },
      },
    })
  );

const editCampaignTargetType = (campaignTargetType) => (dispatch) =>
  dispatch(
    actionCreator({
      type: types.CAMPAIGN_TARGET_TYPE_EDIT,
      payload: { campaignTargetType, isOpen: true },
    })
  );

const cancelEditCampaignTargetType = () => (dispatch) =>
  dispatch(
    actionCreator({
      type: types.CAMPAIGN_TARGET_TYPE_EDIT,
      payload: { isOpen: false, campaignTargetType: {} },
    })
  );

const getCampaignTargetTypeList = (
  params = {},
  request = null,
  fetch = fetchCampaignTargetTypes
) => {
  const { page = 1, ps = -1, sort = "code:asc" } = params;
  return async (dispatch) => {
    dispatch(actionCreator({ type: types.IS_LOADING, payload: true }));
    try {
      const data = await fetch(ps, (page - 1) * ps, sort, request);
      dispatch(updateCampaignList(data));
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const getCampaignTargetTypeById = (id, get = getCampaignTargetType) => {
  return async (dispatch) => {
    try {
      const { data } = await get(id);
      dispatch(editCampaignTargetType(data));
      dispatch(getCampaignTargetTypeList());
    } catch (error) {
      dispatch(actionCreator({ type: types.IS_LOADING, payload: false }));
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

const saveOrUpdateCampaignTargetType = (data, save = saveCampaignTargetType) => {
  return async (dispatch) => {
    try {
      await save(data);
      showToast(data.id ? "Toast.campaignTargetTypeAdded" : "Toast.campaignTargetTypeUpdated");
      dispatch(getCampaignTargetTypeList());
    } catch (error) {
      showToast("Failed", false);
      dispatch(getCampaignTargetTypeList());
      dispatch(actionCreator({ type: types.IS_ERROR, payload: error }));
    }
  };
};

export {
  updateCampaignList,
  getCampaignTargetTypeList,
  addCampaignTargetType,
  editCampaignTargetType,
  cancelEditCampaignTargetType,
  getCampaignTargetTypeById,
  saveOrUpdateCampaignTargetType,
};
