import * as types from "../types/productIdentificationNumberNumberType";

const initialState = {
  isLoading: false,
  isError: null,
  list: {
    data: [],
  },
  productIdentificationNumberTypeEdit: { isOpen: false, productIdentificationNumberType: {} },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.LIST:
      return { ...state, list: payload };
    case types.EDIT:
      return { ...state, productIdentificationNumberTypeEdit: payload };
    default:
      return state;
  }
}
