import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { concat } from "lodash";
import { Popover, Position, Menu } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Icons } from "prefab";
import styles from "../../../styles/NavProfile.module.scss";
import { SCOPES } from "../../../constants";
import popOverStyles from "../../../styles/PopOver.module.scss";
import menuStyles from "../../../styles/Menu.module.scss";
import MenuItemWithAuth from "../CustomMenuItem";

const { ChevronUpIcon, ChevronDownIcon, LogoutIcon } = Icons;
const defaultMenuItems = [
  {
    name: "PageHeader.signOut",
    route: "/logout",
    Icon: LogoutIcon,
    scope: SCOPES.__SELF__,
  },
];
export default class NavProfile extends Component {
  state = {
    isOpen: true,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    menuList: PropTypes.array,
    isActiveMenu: PropTypes.func,
  };

  static defaultProps = {
    menuList: [],
  };

  componentDidUpdate(prevProps) {
    const { isExpanded } = this.props;

    if (prevProps.isExpanded !== isExpanded) {
      this.setState({
        isOpen: false,
      });
    }
  }

  renderMenu = () => {
    const { menuList, userData, isAdminApp } = this.props;
    const filteredMenus = menuList.filter(
      (menu) => !menu.onlyInUser || (menu.onlyInUser && !isAdminApp)
    );
    return (
      <Menu
        className={classNames(
          menuStyles.menu,
          styles.profileMenuContainer,
          isAdminApp && styles.admin
        )}
      >
        {concat(filteredMenus, defaultMenuItems).map((menu, i) => (
          <Link to={menu.route} key={i} draggable={false}>
            <MenuItemWithAuth
              icon={menu.Icon && <menu.Icon className={styles.popMenuItemIcon} />}
              text={
                <div className={styles.popupMenuText}>
                  <FormattedMessage id={menu.name} defaultMessage={menu.name} />
                </div>
              }
              tagName="div"
              shouldDismissPopover={true}
              scope={menu.scope}
              userData={userData}
            />
          </Link>
        ))}
      </Menu>
    );
  };

  render = () => {
    const { name, companyName, isExpanded } = this.props;
    return (
      <div
        className="flex no-select col-12"
        onClick={() =>
          this.setState({
            isOpen: !this.state.isOpen,
          })
        }
      >
        {isExpanded && (
          <Popover
            portalClassName={popOverStyles.popOver}
            usePortal={true}
            onClose={() =>
              this.setState({
                isOpen: false,
              })
            }
            content={this.renderMenu()}
            position={Position.TOP_RIGHT}
            minimal
          >
            <div className={styles.profileContainer}>
              <div className={classNames("flex flex-column", styles.content)}>
                <div className={classNames("col-11 h4 semi text-ellipsize", styles.userName)}>
                  {name}
                </div>
                <div className={classNames("col-11 h5 text-ellipsize", styles.companyName)}>
                  {companyName}
                </div>
              </div>
              <div className={styles.chevron}>
                {this.state.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
            </div>
          </Popover>
        )}
      </div>
    );
  };
}
