/*
This component renders a footer with controls for adding new items and pagination:

1.It imports React, PropTypes, TablePagination, styles, and LocalizedButton.

2.It defines a FooterControls component that takes props like addButton, pagination, buttonLabel, etc.

3.render() displays a div with conditional rendering for:

  3a.An "Add" button if addButton prop is true. This uses the LocalizedButton component.

  3b.Pagination controls from TablePagination if pagination prop is true.

  3c.It shows the total records, page size, current page, etc. and calls the onRowSizeChange callback when the page changes.

4.The component takes care of displaying the appropriate controls based on the props passed to it.

5.It doesn't contain the actual logic for adding new items or handling pagination. It just renders the UI controls.

6.The parent component would pass callbacks like addNewField and onRowSizeChange to handle the events.

 It's a reusable footer component that can render add and pagination controls based on props. The parent component handles the logic.


*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { TablePagination } from "workbench";
import styles from "../../styles/Footer.module.scss";
import LocalizedButton from "./LocalizedButton";

class FooterControls extends Component {
  static propTypes = {
    addButton: PropTypes.bool,
    addButtonScope: PropTypes.string,
    addButtonScopeAction: PropTypes.string,
    pagination: PropTypes.bool,
    buttonLabel: PropTypes.string,
  };

  static defaultProps = {
    addButton: false,
    pagination: false,
    buttonLabel: "Button.addNew",
  };

  render() {
    const {
      addButton,
      addButtonScope,
      buttonLabel,
      addButtonScopeAction,
      pagination,
      addNewField,
      onRowSizeChange,
      data = [],
      ps,
      page,
      userData,
    } = this.props;
    return (
      <div className={styles.FooterControls}>
        {addButton && (
          <LocalizedButton
            id="add"
            iconName="AddFilledIcon"
            onClick={addNewField}
            text={buttonLabel}
            scope={addButtonScope}
            scopeAction={addButtonScopeAction}
            userData={userData}
          />
        )}
        {pagination && (
          <>
            <div className="flex-auto" />
            <TablePagination
              totalRecords={data.totalCount || 0}
              pageSize={ps}
              pageNeighbours={1}
              onPageChange={(v) => onRowSizeChange("page", v)}
              currentPage={page}
            />
          </>
        )}
      </div>
    );
  }
}
export default FooterControls;
