import { memoize } from "lodash";
import messages from "./messages";

const combineMessages = (lang) => {
  const combinedMessages = {};
  Object.keys(messages).forEach((objectKey) => {
    const keyMessage = messages[objectKey];
    Object.entries(keyMessage[lang]).map((keyValue) => {
      const [key, value] = keyValue;
      combinedMessages[`${objectKey}.${key}`] = value;
      return true;
    });
  });
  return combinedMessages;
};

export default memoize(combineMessages);
