import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icons } from "prefab";
import styles from "../../styles/Layout.module.scss";
import { bindDispatch } from "../../utils";
import { SlateLogo } from "../../assets";
import { VIEW_PORT_SIZE } from "../../constants";
import NavigationBar from "./NavigationBar/NavigationBar";

const { HamburgerIcon } = Icons;

export class Layout extends Component {
  state = {
    isExpanded: false,
    navAction: "collapsed",
    isMobile: this.props.app.pageDimensions?.width <= VIEW_PORT_SIZE.MOBILE,
  };

  componentDidMount() {
    this.props.actions.getNavCounts();
  }

  handleExpand = (isExpanded) => {
    this.setState({
      isExpanded,
      navAction: isExpanded ? "expanded" : "collapsed",
    });
  };

  handleNavBarHover = (isHovered) => {
    const { isExpanded } = this.state;
    const defaultAction = isExpanded ? "expanded" : "collapsed";
    this.setState({ navAction: isHovered ? "hovered" : defaultAction });
  };

  renderNavigationBar = () => {
    const { isExpanded, navAction, isMobile } = this.state;
    const { app, history, location } = this.props;
    return (
      <NavigationBar
        isExpanded={isExpanded}
        isHovered={navAction === "hovered"}
        hasNavigation={false}
        navCount={app.navCount}
        history={history}
        location={location}
        handlePinned={(bool) => this.handleExpand(bool)}
        handleExpand={(bool) => (isMobile ? this.handleExpand(bool) : {})}
        {...this.props}
      />
    );
  };

  renderMobileHeader = (isAdminApp) => {
    const { isExpanded } = this.state;
    return (
      <div className={classNames(styles.mobileHeader, { [styles.admin]: isAdminApp })}>
        <div
          className={classNames("flex col-2", styles.menuIcon)}
          onClick={() => this.handleExpand(true)}
        >
          <HamburgerIcon />
        </div>
        {!isExpanded && (
          <div className={styles.slateLogo}>
            <Link to="/dashboard" onClick={() => this.handleExpand(false)} draggable={false}>
              <SlateLogo />
            </Link>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isExpanded, navAction, isMobile } = this.state;
    const isAdminApp = !!process.env.REACT_APP_IS_ADMIN_APP;
    return (
      <main>
        {isMobile && this.renderMobileHeader(isAdminApp)}
        <div
          id={isMobile ? "drawer" : "sideBar"}
          onMouseEnter={() => (!isExpanded && !isMobile ? this.handleNavBarHover(true) : {})}
          onMouseLeave={() => this.handleNavBarHover(false)}
          className={classNames(styles.sideNavContainer, styles[navAction], {
            [styles.portal]: isMobile,
            [styles.admin]: isAdminApp,
          })}
        >
          {this.renderNavigationBar()}
        </div>
        {isExpanded && isMobile && (
          <div className={styles.overlay} onClick={() => this.handleExpand(false)}></div>
        )}
        <div
          className={classNames(styles.pageLayout, styles[`page_${navAction}`], {
            [styles.mobile]: isMobile,
          })}
          onClick={() => (isMobile ? this.handleExpand(false) : null)}
        >
          <div className={classNames("col-12", styles.container)}>{this.props.children}</div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.app,
  (state) => state.userData,
  (app, userData) => ({ app, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Layout);
