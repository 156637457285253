import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import styles from "../../../styles/NavMenuItem.module.scss";
import { bindDispatch, intlKeyPropType } from "../../../utils";
import withScopeAuth from "../../hoc/withScopeAuth";

export class NavMenuItem extends Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    name: intlKeyPropType.isRequired,
    badge: PropTypes.number,
    route: PropTypes.string.isRequired,
  };

  static defaultProps = {
    badge: 0,
    persistentBadge: false,
  };

  formatNumber = () => {
    const { badge, isExpanded } = this.props;
    if (999999 < badge) {
      return `${badge.toString().slice(0, 6)}+`;
    }
    return !isExpanded ? badge : badge.toLocaleString();
  };

  render() {
    const {
      isSelected,
      isExpanded,
      name,
      badge,
      route,
      persistentBadge,
      handleExpand,
    } = this.props;
    return (
      <Link to={route} onClick={() => handleExpand(false)} draggable={false}>
        <div
          className={classNames(
            "flex cursor relative",
            styles.menuWrapper,
            isSelected && styles.selectedMenu
          )}
        >
          {isExpanded && name && (
            <span className={classNames("h5, flex-auto", styles.menuText)}>
              <FormattedMessage id={name} defaultMessage={name} />
            </span>
          )}
          {isExpanded && badge > 0 && (
            <span className={classNames("h6 mr2", styles.badge, persistentBadge && styles.red)}>
              {this.formatNumber()}
            </span>
          )}
          {persistentBadge && !isExpanded && badge > 0 && (
            <span className={styles.persistentBadge}>{this.formatNumber()}</span>
          )}
        </div>
      </Link>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withScopeAuth(NavMenuItem));
