/*
1. The code imports various modules and components needed for a React application, 
   including those from BlueprintJS, React, Redux, and custom utility functions.

2. The code defines a class-based React component named `ShowFailuresSchedules`, 
   which appears to represent a page or view in the application.

3. In the component's constructor, an initial state is set, including an array of `selectedColumns`.

4. The `componentDidMount` lifecycle method contains logic to fetch data when the
   component is mounted. It retrieves data using actions and parameters from `props`.

5. The `componentDidUpdate` method is left empty, indicating that there's 
   no specific logic to run when the component updates.

6. The `componentWillUnmount` method is left empty, suggesting that 
   there's no cleanup logic required before the component unmounts.

7. The `getFailuresStatusLegends` method calculates status legends based 
   on the failure statuses present in the data.

8. The `renderStatusIconLegend` method renders a legend component showing various 
   failure status icons. It also handles loading and filtering states.

9. The `render` method defines the component's rendering structure, including breadcrumbs, 
   page headers, table controls, and the table itself. It uses data passed in through props.

10. It defines the columns for the table based on a `tableFailuresScheduleColumnStructure` 
   array and some logic for handling display values and onClick functions.

11. The component makes use of various props and state to display table data and control 
   elements like filters and pagination.

12. It uses a `Table` component to render a table, with an optional sub-component 
   (`ShowFailuresSubComponent`) for detailed row information.

13. The `ShowFailuresSchedules` component appears to interact with Redux 
    for managing state and data retrieval.

14. The code also exports a connected version of the `ShowFailuresSchedules` 
   component using `connect` from Redux, making it available for use in other 
   parts of the application.

15. Additionally, there is a `mapStateToProps` function that combines data from 
   the Redux store and passes it as props to the component. This function is used 
   with `createSelector` from the Reselect library to efficiently select data from the store.
*/
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { compact, sum } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import {
  FAILURE_STATUSES,
  STATIC_FILTER_TAG_LIST,
  STATUS_ICON_COLORS,
  STATUS_ICON_TYPES,
  TAG_TYPE,
} from "../../../../constants";

import pageStyles from "../../../../styles/App.module.scss";
import { bindDispatch, formatDateTime, modifyTableColumns } from "../../../../utils";
import {
  ActiveTableCellColumnFromArray,
  ActiveTableCellRowFromArray,
} from "../../../common/ActiveTableCell";
import Breadcrumb from "../../../common/BreadCrumb";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import PageHeader from "../../../common/PageHeader";
import StatusIconLegend from "../../../common/StatusIconLegend";
import SwitchButtonWithText from "../../../common/SwitchButtonWithText";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import WithFetchList from "../../../hoc/withFetchList";
import ShowFailuresSubComponent from "./ShowFailuresSubComponent";

const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

const dayBeforeYesterday = new Date();
dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

const today = new Date();

const {
  PENDING_SCHEDULE_DELIVERY,
  PENDING_PLAYLOG_RETRIEVAL,
  UNOPTIMIZED,
  LOG_PENDING,
  PROBLEMATIC,
  ACTIVE,
  COMPLETED,
  PLANNED,
} = FAILURE_STATUSES;

class ShowFailuresSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [
        "Schedule Name",
        "Played Status",
        "Taken Status",
        "Spots Max/Sch.",
        "Spots Mov./Play",
        "Loss Percent",
        "Reason",
      ],
    };
  }

  getDefaultFilters = () => {
    return [
      {
        from: dayBeforeYesterday,
        id: "dateRange",
        type: "dateRange",
        displayName: `${formatDate(dayBeforeYesterday)}-${formatDate(today)}`,
        to: today,
      },
    ];
  };
  componentDidMount() {
    // code to run after component mounts
    const {
      actions,
      match: { params },
    } = this.props;

    actions.getShowFailureScheduleData({ id: params.id });
  }
  componentDidUpdate(prevProps, prevState) {
    // code to run after component updates
  }

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  componentWillUnmount() {
    // code to run before component unmounts
  }

  getFailuresStatusLegends = (schedules) => {
    const { FAILURE_STATUS } = TAG_TYPE;

    // if 'schedules' array is empty, return an empty array.
    if (schedules.length === 0) return [];

    // an array 'statusList' by flattening the 'showFailureStatus' property array of each 'schedule'.
    const statusList = compact(schedules.flatMap((schedule) => schedule.showFailureStatus ?? ""));

    // a function 'getStatusDisplayName' to retrieve the display name for a status.
    const getStatusDisplayName = (status) =>
      STATIC_FILTER_TAG_LIST[FAILURE_STATUS].find((d) => d.id === status).displayName;

    // an array 'statusLegends' that represents the legends for each failure status.
    const statusLegends = [
      {
        id: PENDING_SCHEDULE_DELIVERY,
        name: getStatusDisplayName(PENDING_SCHEDULE_DELIVERY),
        count: statusList.filter((d) => d === PENDING_SCHEDULE_DELIVERY).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREEN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PENDING_PLAYLOG_RETRIEVAL,
        name: getStatusDisplayName(PENDING_PLAYLOG_RETRIEVAL),
        count: statusList.filter((d) => d === PENDING_PLAYLOG_RETRIEVAL).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.YELLOW,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: UNOPTIMIZED,
        name: getStatusDisplayName(UNOPTIMIZED),
        count: statusList.filter((d) => d === UNOPTIMIZED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.RED,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: LOG_PENDING,
        name: getStatusDisplayName(LOG_PENDING),
        count: statusList.filter((d) => d === LOG_PENDING).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BLUE,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PROBLEMATIC,
        name: getStatusDisplayName(PROBLEMATIC),
        count: statusList.filter((d) => d === PROBLEMATIC).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.GREY,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: ACTIVE,
        name: getStatusDisplayName(ACTIVE),
        count: statusList.filter((d) => d === ACTIVE).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BROWN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: COMPLETED,
        name: getStatusDisplayName(COMPLETED),
        count: statusList.filter((d) => d === COMPLETED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.PURPLE,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
      {
        id: PLANNED,
        name: getStatusDisplayName(PLANNED),
        count: statusList.filter((d) => d === PLANNED).length,
        type: STATUS_ICON_TYPES.CIRCLE,
        color: STATUS_ICON_COLORS.BROWN,
        tagType: TAG_TYPE.FAILURE_STATUS,
      },
    ];

    return statusLegends;
  };

  renderStatusIconLegend = () => {
    const {
      showFailureScheduleData: { showFailureScheduleData, isPartialLoading },
      isFilterLoading,
    } = this.props;
    // Call 'getFailuresStatusLegends' to obtain the legend information based on 'showFailureScheduleData'.
    const statusList = this.getFailuresStatusLegends(showFailureScheduleData?.data ?? []);

    // If loading or filtering is in progress, return a skeleton component.
    if (isPartialLoading || isFilterLoading) {
      return (
        <div
          className={classNames(
            "flex flex-auto flex-wrap",
            (isPartialLoading || isFilterLoading) && Classes.SKELETON
          )}
        />
      );
    }

    // If the sum of counts in 'statusList' is 0, return null.
    if (sum(statusList.map((status) => status.count)) === 0) return null;

    // Render the status icon legend component with the obtained 'statusList'.
    return (
      <div className={classNames("flex flex-auto flex-wrap align-center")}>
        <StatusIconLegend
          statusInfo={statusList}
          // onFilterSelect={this.props.onFilterSelect}
        />
      </div>
    );
  };

  render() {
    const {
      match: { params },
      ps,
      page,
      filters,
      sort,
      history,
      onQueryChange,
      onSortedChange,
      onFilterSelect,
      onFilterChange,
      onFilterIdSelect,
      showFailureScheduleData: { showFailureScheduleData, isLoading },
    } = this.props;
    const columns = [
      {
        id: "name",
        Header: "Schedule Name",
        accessor: "name",
      },
      {
        id: "takenStatus",
        Header: "Taken Status",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([
            { id: "scheduleStatus", name: d.scheduleStatus },
            { id: "takenOn", name: formatDateTime(d.takenOn) },
          ]),
      },
      {
        id: "playedStatus",
        Header: "Played Status",
        accessor: "playLogStatus",
      },
      {
        id: "spotsSchedule",
        Header: "Spots Max/Sch.",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([
            { id: d.spotsMaximum, name: `Max: ${d.spotsMaximum}` },
            { id: d.spotsScheduled, name: `Sch.: ${d.spotsScheduled}` },
          ]),
        sortable: false,
      },
      {
        id: "spotsMovie",
        Header: "Spots Mov./Play",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([
            { id: d.spotsPossible, name: `Movies: ${d.spotsPossible}` },
            { id: d.spotsPlayed, name: `Played: ${d.spotsPlayed}` },
          ]),
        sortable: false,
      },
      {
        id: "lossPercent",
        Header: "Loss Percent",
        accessor: (d) =>
          ActiveTableCellColumnFromArray([{ id: "lossPercent", name: `${d.lossPercent}%` }]),
        width: 150,
        sortable: false,
      },
      {
        id: "reason",
        Header: "Reason",
        accessor: "reason",
        width: 200,
        sortable: false,
      },
    ];

    const subRowStruct = [
      [
        {
          id: "orderId",
          Cell: (d) => `Order Id: ${d.scheduleOrderId}`,
          row_flex: 2,
        },
        {
          id: "govtRoNo",
          Cell: (d) => `Govt. RO No.: ${d.govtRoNo}`,
          row_flex: 2,
        },
        {
          id: "composition",
          Cell: (d) => `${d.cplName}`,
          row_flex: 2,
        },
      ],
      [
        {
          id: "createdOn",
          Cell: (d) => `Created On: ${formatDateTime(d.createdOn)}`,
          row_flex: 2,
        },
        {
          id: "schedulePeriod",
          Cell: (d) => `Period: ${formatDateTime(d.startDate)} to ${formatDateTime(d.endDate)}`,
          row_flex: 2,
        },
        {
          id: "theatre",
          Cell: (d) => (
            <>
              Theatre:{" "}
              {ActiveTableCellRowFromArray(
                [
                  { id: d.theatreId, name: d.theatreName },
                  { id: d.cityId, name: d.cityName },
                  { id: d.provinceId, name: d.provinceName },
                ],
                onFilterIdSelect
              )}
            </>
          ),
          row_flex: 2,
        },
      ],
      [
        {
          id: "shows",
          Cell: (d) => `Shows: ${d.shows}`,
          row_flex: 2,
        },
        {
          id: "days",
          Cell: (d) => `Days: ${d.days}`,
          row_flex: 2,
        },
        {
          id: "position",
          Cell: (d) => `Position: ${d.positions} | ${d.prefPositions}`,
          row_flex: 1,
        },
        {
          id: "repetition",
          Cell: (d) => `Repetition: ${d.spotRepetition}`,
          row_flex: 1,
        },
      ],
    ];

    const { selectedColumns, reorderedColumns } = this.state;

    return (
      <div>
        <Breadcrumb
          isLoading={isLoading}
          history={history}
          links={[
            { name: "ShowFailures Screens", path: "/show-failures" },
            {
              name:
                showFailureScheduleData.data && showFailureScheduleData.data[0]
                  ? `Screen: ${showFailureScheduleData.data[0].screenName}`
                  : "",
            },
          ]}
        />
        <PageHeader
          name="Schedules"
          count={showFailureScheduleData.totalCount}
          renderRightSideComponent={() => (
            <SwitchButtonWithText
              buttons={[
                {
                  isActive: true,
                  text: "Button.schedules",
                },
                {
                  onClick: () => history.push(`/show-failures/${params.id}/spotstatus`),
                  text: "Button.spotstatus",
                },
              ]}
            />
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <div
              className={classNames(
                "flex flex-auto flex-wrap",
                pageStyles.statusGroup,
                true && pageStyles.statusGroupLoading
              )}
            >
              {this.renderStatusIconLegend()}
            </div>
            <TableControls
              columnFilter
              columns={columns}
              data={showFailureScheduleData}
              filters={filters}
              onColumnFilterSelect={this.onColumnSelect}
              onFilterChange={
                filters.length === 0 ? onFilterChange(this.getDefaultFilters()) : onFilterChange
              }
              onFilterSelect={onFilterSelect}
              onSearchFilterSelect={onFilterSelect}
              onRowSizeChange={onQueryChange}
              page={page}
              pagination
              ps={ps}
              reorderedColumns={reorderedColumns}
              searchBar
              selectedColumns={selectedColumns}
              showRowSize
              tagTypes={[TAG_TYPE.DATE_RANGE, TAG_TYPE.THEATRE, TAG_TYPE.CITY, TAG_TYPE.PROVINCE]}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={showFailureScheduleData.totalCount}
              onFilterChange={
                filters.length === 0 ? onFilterChange(this.getDefaultFilters()) : onFilterChange
              }
              loading={isLoading}
            />
            <Table
              data={showFailureScheduleData.data}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              loading={false}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={<TableLoading columns={columns} />}
              SubComponent={
                //if subRowStructure is not empty, then render subComponent
                (row) => {
                  return (
                    <ShowFailuresSubComponent data={row.original} subRowStruct={subRowStruct} />
                  );
                }
              }
            />
            <FooterControls
              pagination
              data={showFailureScheduleData}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const ShowFailuresSchedulesListWithFilters = WithFetchList("getShowFailureScheduleData", {
  sort: [
    {
      id: "name",
      desc: true,
    },
  ],
})(ShowFailuresSchedules);
const mapStateToProps = createSelector(
  (state) => state.showFailureSchedule,
  (state) => state.userData,
  (showFailureScheduleData, userData) => ({ showFailureScheduleData, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ShowFailuresSchedulesListWithFilters);
