import React, { Component } from "react";
import classNames from "classnames";
import ApiSearch from "../../../../common/ApiSearch";
import LocalizedButton from "../../../../common/LocalizedButton";
import { searchLocation } from "../../../../../api";
import styles from "../../../../../styles/CampaignCreate/LocationBasedInput.module.scss";

class LocationBasedInput extends Component {
  state = {
    selectedLocation: null,
  };

  static defaultProps = {
    countries: [],
  };

  handleLocationSelect = (data) => {
    this.setState({
      selectedLocation: data,
    });
  };

  handleUpdateLocation = (type) => {
    const { selectedLocation } = this.state;
    if (this.props.updateCriteriaList) {
      this.props.updateCriteriaList(
        {
          id: selectedLocation.id,
          name: selectedLocation.name,
          locationInfo: {
            ...selectedLocation,
          },
          //SLATE-1405 Nov29 Anushree:- type,tags are mandatory fields for criteria
          type: selectedLocation.type,
          tags: [selectedLocation.id],
        },
        type
      );

      this.setState({
        selectedLocation: null,
      });
    }
  };

  render() {
    const { selectedLocation } = this.state;
    const { countries, targetGroup } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>Location</div>
        <div className={styles.inputContent}>
          <ApiSearch
            placeholder="Select State or City"
            onSelect={this.handleLocationSelect}
            fetchAction={async (value) => {
              const response = await searchLocation(
                value,
                countries.map((c) => c.iso3166_2)
              );
              const { data } = response;
              // if type id state in search result the change the type to province
              const modifiedResults = data["results"].map((item) =>
                item.type === "state" ? { ...item, type: "province" } : item
              );
              data["results"] = modifiedResults;
              return { data };
            }}
            parseResults={(data) =>
              data.results.slice(0, 15).map((item) => ({
                ...item,
                tag: item.type,
                province: {
                  id: item.province_id,
                  name: item.province_name,
                  country: {
                    id: item.country_id,
                    name: item.country_name,
                  },
                },
              }))
            }
            query={selectedLocation ? selectedLocation.name : ""}
            resetOnSelect
            showSubText={true}
          />
          <div className={styles.actions}>
            <LocalizedButton
              id="include"
              className={classNames(styles.button, styles.include)}
              iconName="AddIcon"
              disabled={targetGroup.status === "Active" || !selectedLocation}
              onClick={() => this.handleUpdateLocation("inclusions")}
              text="Button.include"
            />
            <LocalizedButton
              id="exclude"
              className={classNames(styles.button, styles.exclude)}
              iconName="CancelIcon"
              disabled={targetGroup.status === "Active" || !selectedLocation}
              onClick={() => this.handleUpdateLocation("exclusions")}
              text="Button.exclude"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LocationBasedInput;
