import React from "react";
import InfoBlock from "../../../common/InfoBlock";
import Card from "../../../common/InfoBlock/Card";
import Contact from "../../../common/InfoBlock/Contact";
import styles from "../../../../styles/InfoBlock.module.scss";

const getContactDetails = ({ advertisers, contacts }, header = "Contact Details") => {
  if (!contacts) return null;
  return (
    <InfoBlock header={header} childClassName="flex flex-wrap">
      {contacts.map((contact, index) => {
        if (!contact.name) return null;
        return (
          <Card md={12} key={index} className={styles.contactCardList}>
            <Contact
              {...contact}
              type={contact.type === "Person" ? "Contact Person" : contact.type}
              phone={
                contact.phoneNumber ? `${contact.phoneNumberCode} - ${contact.phoneNumber}` : " "
              }
              email={contact.email ? contact.email : " "}
              location={contact.cityName}
              advertiserName={advertisers}
            />
          </Card>
        );
      })}
    </InfoBlock>
  );
};

export default getContactDetails;
