import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { bindDispatch } from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import { TheatresScreensList } from "../../common/TheatresScreensList";

class MediaTheatres extends Component {
  render = () => {
    const {
      history,
      match: { params },
      media: { isTabLoading, media, mediaTheatres, mediaScreens },
    } = this.props;
    return (
      <div className="col-12 clearfix">
        <TheatresScreensList
          isTabLoading={isTabLoading}
          selectedSwitch={params.action}
          targetId={media.id}
          theatres={mediaTheatres}
          screens={mediaScreens}
          onSwitchChange={(text) => history.push(`/campaigns/media/${media.id}/theatres/${text}`)}
          {...this.props}
        />
      </div>
    );
  };
}
export const MediaTheatresListWithFilters = WithFetchList("getMediaTheatres", {
  sort: [
    {
      id: "endDate",
      desc: false,
    },
    {
      id: "status",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(MediaTheatres);

const mapStateToProps = createSelector(
  (state) => state.media,
  (state) => state.userData,
  //SLATE-1388 Nov17 Anushree:- added campaigns in props to access it in the TheatreScreenList component
  (state) => state.campaigns,
  (media, userData, campaigns) => ({ media, userData: userData.user, campaigns })
);

export default withRouter(connect(mapStateToProps, bindDispatch)(MediaTheatresListWithFilters));
