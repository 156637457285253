/*
This is a higher order component called withTracker that can be used to wrap any React component to add page tracking functionality using ReactGA.

1. withTracker accepts the component to wrap (WrappedComponent) and options to pass to ReactGA.

2. It defines a trackPage method that calls ReactGA.set and ReactGA.pageview to track page views.

3. The returned component WithTracker extends React Component.

4. In componentDidMount and componentDidUpdate it calls trackPage to track the current page.

5. It renders the original wrapped component, passing through props.

6. It sets a readable display name for the wrapped component.

This HOC provides page tracking ability by wrapping components without needing to modify the component code.
It tracks on mount and when the page changes.
*/
import React, { Component } from "react";
import ReactGA from "react-ga";
import { getDisplayName } from "../../utils";

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  const WithTracker = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  WithTracker.displayName = `WithTracker(${getDisplayName(WrappedComponent)})`;
  return WithTracker;
};

export default withTracker;
