import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import { BUTTON_TYPE, ICON_POS } from "prefab";
import pageStyles from "../../../styles/App.module.scss";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import { bindDispatch, lastUpdatedAtColumn, modifyTableColumns } from "../../../utils";
import styles from "../../../styles/Lists/Lists.module.scss";
import LocalizedButton from "../../common/LocalizedButton";
import Breadcrumb from "../../common/BreadCrumb";
import PageHeader from "../../common/PageHeader";
import * as constants from "../../../constants";
import FilterChips from "../../common/Filters/FilterChips";
import HeaderButton from "../../common/HeaderButtons";
import TableControls from "../../common/TableControls";
import WithFetchList from "../../hoc/withFetchList";
import TableLoading from "../../common/TableLoading";
import FooterControls from "../../common/FooterControls";

const _LINKS = [
  { name: "PageHeader.lists", path: "/lists" },
  { name: "PageHeader.contentRatings" },
];

class ContentRatings extends Component {
  state = {
    selectedColumns: ["Country", "Certification", "Child Safe", "Last Synced On"],
  };

  componentDidMount = () => {
    this.props.fetchData();
  };

  syncToMovieBuff = () => {
    const { location, actions, filters } = this.props;
    const values = (location && location.search && queryString.parse(location.search)) || {};
    actions.syncWithMovieBuff(() => actions.getCertifications(values, filters));
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      certifications: { certifications, isLoading, isPartialLoading },
      history,
      filters,
      userData,
      ps,
      page,
      sort,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    if (isLoading) return <PageLoadingWithTable showBreadcrumb={true} tableRowsCount={10} />;

    const { selectedColumns, reorderedColumns } = this.state;
    const tagTypes = [
      constants.TAG_TYPE.COUNTRY,
      constants.TAG_TYPE.CHILD_SAFE_CERTIFICATION,
      constants.TAG_TYPE.UPDATED_AT,
    ];

    const columns = [
      {
        id: "countryName",
        Header: "Country",
        accessor: (d) => (
          <span className={styles.active} onClick={() => this.props.onFilterIdSelect(d.countryId)}>
            {d.countryName}
          </span>
        ),
      },
      { id: "code", Header: "Certification", accessor: "code" },
      {
        id: "childSafe",
        Header: "Child Safe",
        accessor: (d) => (
          <span
            className={styles.active}
            onClick={() => this.props.onFilterIdSelect(d.childSafetyTag)}
          >
            {d.childSafe ? "Yes" : "No"}
          </span>
        ),
      },
      lastUpdatedAtColumn("Last Synced On"),
    ];

    const query = queryString.parse(history.location.search);

    const data = certifications.data || [];
    const headerButtons = [
      <LocalizedButton
        id="Sync"
        buttonType={BUTTON_TYPE.LINK}
        iconName="RefreshIcon"
        iconPos={ICON_POS.RIGHT}
        onClick={() => this.syncToMovieBuff()}
        text="Button.sync"
        scope={constants.SCOPES.CERTIFICATIONS}
        scopeAction={constants.SCOPE_ACTIONS.WRITE}
        userData={userData}
      />,
    ];

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader
          name="PageHeader.contentRatings"
          isLoading={isLoading}
          isPartialLoading={isPartialLoading}
          count={certifications.totalCount}
          renderRightSideComponent={() => <HeaderButton headerButtons={headerButtons} />}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              query={query}
              data={certifications}
              tagTypes={tagTypes}
              columns={columns}
              filters={filters}
              ps={ps}
              page={page}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={certifications.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading}
            />
            <Table
              pageSize={(query && parseInt(query.ps)) || 10}
              loading={isPartialLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              data={data}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
            />
            <FooterControls
              pagination
              query={query}
              ps={ps}
              page={page}
              data={certifications}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const ContentRatingsPage = WithFetchList("getCertifications", {
  sort: [
    {
      id: "countryName",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(ContentRatings);

const mapStateToProps = createSelector(
  (state) => state.certifications,
  (state) => state.userData,
  (certifications, userData) => ({ certifications, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(ContentRatingsPage);
