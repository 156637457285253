const IS_LOADING = "catalogue/IS_LOADING";
const IS_PARTIAL_LOADING = "catalogue/IS_PARTIAL_LOADING";
const IS_CREATE_UPDATE_LOADING = "brands/IS_CREATE_UPDATE_LOADING";
const IS_ERROR = "catalogue/IS_ERROR";
const PARENT_BRANDS = "catalogue/PARENT_BRANDS";
const INDIVIDUAL_BRANDS = "catalogue/INDIVIDUAL_BRANDS";
const PRODUCTS = "catalogue/PRODUCTS";
const VARIANTS = "catalogue/VARIANTS";
const BRAND_CONTENT = "catalogue/BRAND_CONTENT";
const BRAND = "catalogue/BRAND";
const EDIT_BRAND = "catalogue/EDIT_BRAND";
const SEARCH = "catalogue/SEARCH";
const CATEGORY_SEARCH = "catalogue/CATEGORY_SEARCH";
const BRAND_TAG_TYPE = "catalogue/BRAND_TAG_TYPE";
const ADVERTISER_SEARCH = "catalogue/ADVERTISER_SEARCH";
export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CREATE_UPDATE_LOADING,
  IS_ERROR,
  PARENT_BRANDS,
  INDIVIDUAL_BRANDS,
  PRODUCTS,
  VARIANTS,
  BRAND_CONTENT,
  BRAND,
  EDIT_BRAND,
  SEARCH,
  CATEGORY_SEARCH,
  BRAND_TAG_TYPE,
  ADVERTISER_SEARCH,
};
