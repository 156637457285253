import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "workbench";
import * as constants from "../../../../constants";
import ActionIcon from "../../../common/ActionIcon";
import FooterControls from "../../../common/FooterControls";
import { checkScopes } from "../../../../utils";
import EditCertificate from "./EditCertification";

export default class Certifications extends Component {
  static propTypes = {
    data: PropTypes.array,
    contentType: PropTypes.object,
    isLoading: PropTypes.bool,
    actions: PropTypes.object,
    certification: PropTypes.object,
    editCertification: PropTypes.func,
    cancelEdit: PropTypes.func,
  };
  addNewField = () => this.props.addCertification();
  onSave = (data) => {
    const { params, countryId, actions } = this.props;
    actions.createOrUpdateCertificate(countryId, { ...data, countryId }, () =>
      actions.getCountry(params.countryId)
    );
  };

  render = () => {
    const {
      data,
      isLoading,
      countryId,
      contentType,
      certification,
      editCertification,
      cancelEdit,
      userData,
    } = this.props;
    const columns = checkScopes(
      [
        {
          id: "contentTypeName",
          Header: "Media Type",
          sortable: false,
          accessor: "contentTypeName",
        },
        {
          id: "hasCertification",
          Header: "Certification Applicable",
          sortable: false,
          accessor: (d) => (d.hasCertification ? "Yes" : "No"),
        },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.COUNTRIES,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  toolTip: "Tooltip.update",
                  onClick: () => editCertification(countryId, props.original.contentTypeId),
                  iconName: "EditIcon",
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    const addButtonProps = {
      addButton: true,
      addButtonScope: constants.SCOPES.COUNTRIES,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: this.addNewField,
      userData,
    };

    return (
      contentType && (
        <div className="col-12 clearfix">
          <Table
            data={data}
            defaultSortDesc={true}
            pageSize={data.length}
            columns={columns}
            loading={isLoading}
          />
          <FooterControls {...addButtonProps} />
          {certification && certification.isOpen && (
            <EditCertificate
              isOpen={certification.isOpen}
              certificate={certification.certification}
              onSave={this.onSave}
              contentTypes={contentType.data.data || []}
              onClose={cancelEdit}
            />
          )}
        </div>
      )
    );
  };
}
