import React from "react";
import moment from "moment";
import InfoBlock from "../../../common/InfoBlock";

const CampaignActionDetails = ({ actionList, status, header = "Campaign Pause Details" }) => {
  const statusChanges = actionList.filter((changes) => changes.status === status);

  if (statusChanges?.length === 0) return null;

  return (
    <InfoBlock
      header={header}
      config={statusChanges.map((action) => [
        {
          title: "Date Range",
          value: [
            moment(action.fromDate).format("MMM DD, YYYY"),
            moment(action.toDate).format("MMM DD, YYYY"),
          ].join(" to "),
          size: {
            lg: 4,
            md: 4,
            sm: 4,
          },
        },
        {
          title: "Reason",
          value: action.reason,
          size: {
            lg: 4,
            md: 4,
            sm: 4,
          },
        },
        {
          title: "Notes",
          value: action.notes,
          size: {
            lg: 4,
            md: 4,
            sm: 4,
          },
        },
      ])}
    />
  );
};

export default CampaignActionDetails;
