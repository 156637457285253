import React from "react";
import { Classes } from "@blueprintjs/core";

const RightPaneLoading = ({ count = 4 }) => {
  return (
    <>
      {Array(count)
        .fill({})
        .map((c, i) => (
          <div className="mb2" key={i}>
            <div
              className={`col-12 mb1 ${Classes.SKELETON}`}
              style={{ fontSize: "1rem", width: "65%" }}
            >
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            </div>
            <div className={`col-12 ${Classes.SKELETON}`} style={{ fontSize: "1.5rem" }}>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            </div>
          </div>
        ))}
    </>
  );
};
export default RightPaneLoading;
