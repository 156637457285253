const IS_LOADING = "cpl_list/IS_LOADING";
const IS_PARTIAL_LOADING = "cpl_list/IS_PARTIAL_LOADING";
const IS_ERROR = "cpl_list/IS_ERROR";
const COMPOSITIONS_LIST = "cpl_list/COMPOSITIONS";
const GET_CPL = "cpl_list/GET_CPL";
const IS_COMPOSITION_DETAILS_LOADING = "cpl_list/IS_COMPOSITION_DETAILS_LOADING";
const IS_COMPOSITION_UPLOADING = "cpl_lists/IS_COMPOSITION_UPLOADING";
const RESET_COMPOSITION = "cpl_lists/RESET_COMPOSITION";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  COMPOSITIONS_LIST,
  GET_CPL,
  IS_COMPOSITION_DETAILS_LOADING,
  RESET_COMPOSITION,
  IS_COMPOSITION_UPLOADING,
};
