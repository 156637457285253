import { first, flatten, compact, uniqBy } from "lodash";
import {
  getBrandVerifications,
  searchDuplicateParentBrands,
  searchDuplicateIndividualBrands,
  searchDuplicateProducts,
  searchDuplicateVariants,
  getBrandVerification as getBrandVerificationApi,
  getRejectedBrandVerifications,
  getRejectedBrandVerification as getRejectedBrandVerificationApi,
  getConflictingIndividualBrands,
  getConflictingProducts,
  getConflictingVariants,
  submitBrandVerification,
  getTagByID,
  getBrandById as getBrandByIdApi,
} from "../../api";
import {
  IS_LOADING,
  IS_ERROR,
  BRAND_VERIFICATIONS,
  REJECTED_BRAND_VERIFICATIONS,
  BRAND_VERIFICATION,
  BRAND_DUPLICATES,
  BRAND_VERIFICATION_DRAFT,
  REJECTED_BRAND_VERIFICATION,
  BRAND_DATA,
  IS_BRAND_LOADING,
  IS_CONFLICTS_LOADING,
} from "../types/brandVerifications";
import { showToast } from "../../utils";
import * as constants from "../../constants";

const handleLoading = (payload) => {
  return { type: IS_LOADING, payload: payload };
};
const handleError = (payload) => {
  return { type: IS_ERROR, payload: payload };
};
const updateBrandVerificationList = (payload) => {
  return { type: BRAND_VERIFICATIONS, payload: payload };
};
const updateRejectedBrandVerificationList = (payload) => {
  return { type: REJECTED_BRAND_VERIFICATIONS, payload: payload };
};
const updateBrandVerification = (payload) => {
  return { type: BRAND_VERIFICATION, payload: payload };
};

const handleBrandLoading = (payload) => {
  return { type: IS_BRAND_LOADING, payload: payload };
};

const handleConflictsLoading = (payload) => {
  return { type: IS_CONFLICTS_LOADING, payload: payload };
};

const addStatusValues = (data) => {
  const pendingParentBrand = data.pendingParentBrand
    ? {
        ...data.pendingParentBrand,
        brand: constants.BRANDS[0],
        status: constants.VERIFICATION_STATUSES.PENDING,
        hasConflict: false,
        conflicts: [],
      }
    : null;

  const pendingIndividualBrands = data.pendingIndividualBrands
    ? data.pendingIndividualBrands.map((brand) => ({
        ...brand,
        brand: constants.BRANDS[1],
        status: constants.VERIFICATION_STATUSES.PENDING,
        hasConflict: false,
        conflicts: [],
      }))
    : null;

  const pendingProducts = data.pendingProducts
    ? data.pendingProducts.map((brand) => ({
        ...brand,
        brand: constants.BRANDS[2],
        status: constants.VERIFICATION_STATUSES.PENDING,
        hasConflict: false,
        conflicts: [],
      }))
    : null;

  const pendingVariants = data.pendingVariants
    ? data.pendingVariants.map((brand) => ({
        ...brand,
        brand: constants.BRANDS[3],
        status: constants.VERIFICATION_STATUSES.PENDING,
        hasConflict: false,
        conflicts: [],
      }))
    : null;

  return flatten(
    compact([pendingParentBrand, pendingIndividualBrands, pendingProducts, pendingVariants])
  );
};

const updateBrandVerificationDraft = (payload) => {
  return { type: BRAND_VERIFICATION_DRAFT, payload: payload };
};

const updateBrandDuplicates = (payload) => {
  return { type: BRAND_DUPLICATES, payload: payload };
};

const updateRejectedBrandVerification = (payload) => {
  return { type: REJECTED_BRAND_VERIFICATION, payload: payload };
};

const updateBrandData = (payload) => {
  return { type: BRAND_DATA, payload: payload };
};

const getBrandVerificationList = (params = {}, filters = [], fetchData = getBrandVerifications) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await fetchData(ps, (page - 1) * ps, sort, filters);
      dispatch(updateBrandVerificationList(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getRejectedBrandVerificationList = (
  params = {},
  filters = [],
  fetchData = getRejectedBrandVerifications
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "updatedAt:asc" } = params;
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await fetchData(ps, (page - 1) * ps, sort, filters);
      dispatch(updateRejectedBrandVerificationList(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getRejectedBrandVerification = (
  brandType,
  draftId,
  fetchData = getRejectedBrandVerificationApi
) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await fetchData(brandType, draftId);
      dispatch(updateRejectedBrandVerification(data));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const fetchDuplicates = (brandId, brandType, companyId, businessType) => {
  return async (dispatch) => {
    try {
      let response;
      switch (brandType) {
        case constants.BRANDS[0]:
          response = await searchDuplicateParentBrands(
            undefined,
            brandId,
            companyId,
            businessType,
            -1,
            0
          );
          break;
        case constants.BRANDS[1]:
          response = await searchDuplicateIndividualBrands(
            undefined,
            brandId,
            companyId,
            businessType,
            -1,
            0
          );
          break;
        case constants.BRANDS[2]:
          response = await searchDuplicateProducts(
            undefined,
            brandId,
            companyId,
            businessType,
            -1,
            0
          );
          break;
        case constants.BRANDS[3]:
          response = await searchDuplicateVariants(
            undefined,
            brandId,
            companyId,
            businessType,
            -1,
            0
          );
          break;
        default:
          response = null;
      }
      if (response) dispatch(updateBrandDuplicates(response.data));
    } catch (error) {
      dispatch(handleError(error.message));
    }
  };
};

const getBrandVerification = (params, get = getBrandVerificationApi) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data } = await get(params.parentBrandId, params.companyId);
      dispatch(updateBrandVerification(data));
      dispatch(updateBrandVerificationDraft(addStatusValues(data)));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

const getBrandById = (brandId, brandType) => {
  return async (dispatch) => {
    dispatch(handleBrandLoading(true));
    try {
      const data = await getBrandByIdApi(brandId, brandType);
      dispatch(updateBrandData(data));
      dispatch(handleBrandLoading(false));
    } catch (error) {
      dispatch(handleError(error.message));
      dispatch(handleBrandLoading(false));
    }
  };
};

const clearGetBrandById = () => {
  return async (dispatch) => {
    dispatch(updateBrandData(null));
  };
};

const updateBrandStatus = (brandData, status, statusParams) => {
  return {
    ...brandData,
    rejectionReasonId: undefined,
    rejectionNotes: undefined,
    mergeTo: undefined,
    status,
    ...statusParams,
  };
};

const modifyBrandVerificationDraft = (dispatch, data, id, status, statusParams) => {
  dispatch(
    updateBrandVerificationDraft(
      data.map((brand) =>
        brand.id === id ? updateBrandStatus(brand, status, statusParams) : brand
      )
    )
  );
};

const checkIfCurrentBrandIsRelated = (brand, currentBrandId, currentBrandType) => {
  switch (currentBrandType) {
    case constants.BRANDS[0]:
      return brand.parentBrandId === currentBrandId;
    case constants.BRANDS[1]:
      return brand.individualBrandId === currentBrandId || brand.parentBrandId === currentBrandId;
    case constants.BRANDS[2]:
      return (
        brand.productId === currentBrandId ||
        brand.individualBrandId === currentBrandId ||
        brand.parentBrandId === currentBrandId
      );
    case constants.BRANDS[3]:
    default:
      return false;
  }
};

const removeConflict = (brand, currentBrandId, currentBrandType) => {
  if (brand && checkIfCurrentBrandIsRelated(brand, currentBrandId, currentBrandType)) {
    if (brand.isNewEntry) {
      return null;
    } else if (brand.hasConflict) {
      return {
        ...brand,
        status: constants.VERIFICATION_STATUSES.PENDING,
        hasConflict: false,
        conflicts: [],
        isNewEntry: false,
        mergeTo: undefined,
      };
    } else {
      return brand;
    }
  } else {
    return brand;
  }
};

const removeConflicts = (data, currentBrandId, currentBrandType) => {
  return compact(data.map((brand) => removeConflict(brand, currentBrandId, currentBrandType)));
};

const approvePendingVerification = (data, id, brandType, onApprove) => {
  return async (dispatch) => {
    // remove remnants of conflicts if any merge happened before
    const modifiedData = await removeConflicts(data, id, brandType);
    modifyBrandVerificationDraft(
      dispatch,
      modifiedData,
      id,
      constants.VERIFICATION_STATUSES.APPROVED,
      {}
    );
    onApprove();
  };
};

const rejectPendingVerification = (data, id, brandType, statusParams, onReject) => {
  return async (dispatch) => {
    // remove remnants of conflicts if any merge happened before
    const modifiedData = await removeConflicts(data, brandType);
    modifyBrandVerificationDraft(
      dispatch,
      modifiedData,
      id,
      constants.VERIFICATION_STATUSES.REJECTED,
      statusParams
    );
    onReject();
  };
};

const resetPendingVerification = (data, id, brandType) => {
  return async (dispatch) => {
    // remove remnants of conflicts if any merge happened before
    const modifiedData = await removeConflicts(data, id, brandType);
    modifyBrandVerificationDraft(
      dispatch,
      modifiedData,
      id,
      constants.VERIFICATION_STATUSES.PENDING,
      {}
    );
  };
};

const addConflicts = async (pendingData, conflict, brandType) => {
  const sourceIndex = pendingData.findIndex((brand) => brand.id === conflict.source.id);
  if (sourceIndex !== -1) {
    //if available add conflict
    pendingData[sourceIndex] = {
      ...pendingData[sourceIndex],
      hasConflict: true,
      status: constants.VERIFICATION_STATUSES.PENDING,
      mergeTo: undefined,
      conflicts: [...pendingData[sourceIndex].conflicts, ...conflict.targets],
    };
  } else {
    //else fetch data and append
    const sourceData = await getBrandByIdApi(conflict.source.id, brandType);
    pendingData.push({
      ...sourceData,
      brand: brandType,
      status: constants.VERIFICATION_STATUSES.PENDING,
      activity: constants.VERIFICATION_ACTIONS.ADD,
      hasConflict: true,
      isNewEntry: true,
      conflicts: conflict.targets,
    });
  }
  return pendingData;
};

const checkConflicts = async (data, sourceId, targetId, brandType, companyId) => {
  let modifiedData = data;

  switch (brandType) {
    case constants.BRANDS[0]:
      const { data: conflictingIndividualBrands } = await getConflictingIndividualBrands(
        sourceId,
        targetId,
        companyId
      );
      if (conflictingIndividualBrands.length > 0) {
        const uniqIndividualBrands = uniqBy(conflictingIndividualBrands, "source.id");
        await Promise.all(
          uniqIndividualBrands.map(async (c) => {
            const conflict = {
              ...c,
              targets: conflictingIndividualBrands
                .filter((cp) => cp.source.id === c.source.id)
                .map((cp) => cp.target),
            };
            modifiedData = await addConflicts(modifiedData, conflict, constants.BRANDS[1]);
          })
        );
      }
      break;
    case constants.BRANDS[1]:
      const { data: conflictingProducts } = await getConflictingProducts(
        sourceId,
        targetId,
        companyId
      );
      if (conflictingProducts.length > 0) {
        const uniqConflictingProducts = uniqBy(conflictingProducts, "source.id");
        await Promise.all(
          uniqConflictingProducts.map(async (c) => {
            const conflict = {
              ...c,
              targets: conflictingProducts
                .filter((cp) => cp.source.id === c.source.id)
                .map((cp) => cp.target),
            };
            modifiedData = await addConflicts(modifiedData, conflict, constants.BRANDS[2]);
          })
        );
      }
      break;
    case constants.BRANDS[2]:
      const { data: conflictingVariants } = await getConflictingVariants(
        sourceId,
        targetId,
        companyId
      );
      if (conflictingVariants.length > 0) {
        const uniqConflictingVariants = uniqBy(conflictingVariants, "source.id");
        await Promise.all(
          uniqConflictingVariants.map(async (c) => {
            const conflict = {
              ...c,
              targets: conflictingVariants
                .filter((cp) => cp.source.id === c.source.id)
                .map((cp) => cp.target),
            };
            modifiedData = await addConflicts(modifiedData, conflict, constants.BRANDS[3]);
          })
        );
      }
      break;
    default:
  }

  return modifiedData;
};

const mergePendingVerification = (data, id, brandType, companyId, statusParams, onMerge) => {
  return async (dispatch) => {
    //checks and adds conflicts
    dispatch(handleConflictsLoading(true));
    const modifiedData = await checkConflicts(
      data,
      id,
      statusParams.mergeTo?.id,
      brandType,
      companyId
    );
    modifyBrandVerificationDraft(
      dispatch,
      modifiedData,
      id,
      constants.VERIFICATION_STATUSES.MERGED,
      statusParams
    );
    onMerge();
    dispatch(handleConflictsLoading(false));
  };
};

const extractSubmissionData = (brand) => ({
  id: brand.id,
  action: brand.status,
  mergeTo: brand.mergeTo?.id,
  rejectionReasonId: brand.rejectionReasonId,
  rejectionNotes: brand.rejectionNotes,
});

const submitPendingVerification = (companyId, verification, successCallback, failureCallback) => {
  return async (dispatch) => {
    const parentBrand = verification
      .filter((brand) => brand.brand === constants.BRANDS[0])
      .map(extractSubmissionData);
    const individualBrands = verification
      .filter((brand) => brand.brand === constants.BRANDS[1])
      .map(extractSubmissionData);
    const products = verification
      .filter((brand) => brand.brand === constants.BRANDS[2])
      .map(extractSubmissionData);
    const variants = verification
      .filter((brand) => brand.brand === constants.BRANDS[3])
      .map(extractSubmissionData);

    try {
      dispatch(handleLoading(true));
      const { data: response } = await submitBrandVerification({
        companyId,
        parentBrand: first(parentBrand),
        individualBrands,
        products,
        variants,
      });
      dispatch(handleLoading(false));

      const [failedBrand] = flatten(
        compact([
          response.parentBrand,
          response.individualBrands,
          response.products,
          response.variants,
        ])
      ).filter((brand) => brand.status === "failed");

      if (!failedBrand) {
        showToast("Verified Successfully");
        successCallback();
      } else {
        showToast(`Error: ${failedBrand.error}`, false);
        await failureCallback();
      }
    } catch {
      dispatch(handleLoading(false));
      showToast("Error in submitting!", false);
    }
  };
};

const clearPendingVerification = (brandVerification) => {
  return async (dispatch) => {
    dispatch(updateBrandVerificationDraft(addStatusValues(brandVerification)));
  };
};

const createPendingVerification = (data, brandType) => {
  switch (brandType) {
    case constants.BRANDS[0]:
      return {
        parentBrandId: data.id,
        parentBrandName: data.name,
        businessType: data.businessType,
        pendingParentBrand: {
          ...data,
          activity: constants.VERIFICATION_ACTIONS.ADD,
          isNewEntry: true,
        },
      };
    case constants.BRANDS[1]:
      return {
        parentBrandId: data.parentBrandId,
        parentBrandName: data.parentBrandName,
        businessType: data.businessType,
        pendingIndividualBrands: [
          {
            ...data,
            activity: constants.VERIFICATION_ACTIONS.ADD,
            isNewEntry: true,
          },
        ],
      };
    case constants.BRANDS[2]:
      return {
        parentBrandId: data.parentBrandId,
        parentBrandName: data.parentBrandName,
        businessType: data.businessType,
        pendingProducts: [
          {
            ...data,
            activity: constants.VERIFICATION_ACTIONS.ADD,
            isNewEntry: true,
          },
        ],
      };
    case constants.BRANDS[3]:
      return {
        parentBrandId: data.parentBrandId,
        parentBrandName: data.parentBrandName,
        businessType: data.businessType,
        pendingVariants: [
          {
            ...data,
            activity: constants.VERIFICATION_ACTIONS.ADD,
            isNewEntry: true,
          },
        ],
      };
    default:
      return null;
  }
};

const initMergeBrandVerification = (params) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const { data: tag } = await getTagByID(params.mergeId);
      const data = await getBrandByIdApi(params.mergeId, tag.type);
      const pendingVerification = createPendingVerification(data, tag.type);
      dispatch(updateBrandVerification(pendingVerification));
      dispatch(updateBrandVerificationDraft(addStatusValues(pendingVerification)));
      dispatch(handleLoading(false));
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(handleError(error.message));
    }
  };
};

export {
  getBrandVerificationList,
  getRejectedBrandVerificationList,
  getRejectedBrandVerification,
  updateBrandVerificationList,
  updateBrandVerification,
  updateBrandVerificationDraft,
  updateRejectedBrandVerificationList,
  updateRejectedBrandVerification,
  updateBrandData,
  updateBrandDuplicates,
  fetchDuplicates,
  getBrandVerification,
  getBrandById,
  clearGetBrandById,
  resetPendingVerification,
  approvePendingVerification,
  rejectPendingVerification,
  mergePendingVerification,
  submitPendingVerification,
  clearPendingVerification,
  initMergeBrandVerification,
};
