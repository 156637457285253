import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/EmptyState.module.scss";
import intlKeyPropType from "../../utils/intlKeyPropType";

class EmptyState extends Component {
  static propTypes = {
    heading: intlKeyPropType.isRequired,
    body: intlKeyPropType,
  };

  render() {
    const { heading, body } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <FormattedMessage id={heading} />
        </div>
        {body && (
          <div className={styles.body}>
            <FormattedMessage id={body} />
          </div>
        )}
      </div>
    );
  }
}

export default EmptyState;
