import { memoize } from "lodash";
import { bindActionCreators } from "redux";

import * as appActions from "../store/actions";

const bindDispatch = memoize((dispatch) => ({
  dispatch,
  actions: bindActionCreators(appActions, dispatch),
}));

export default bindDispatch;
