import ReactGA from "react-ga";
import { Position } from "@blueprintjs/core";
import * as types from "../types/user";
import { intialiseCore } from "../../api";
import { LOCAL_STORAGE_KEY } from "../../constants";
import { showToastWithPosition } from "../../utils";

const setLoginUser = (payload) => ({ type: types.LOGIN_USER, payload });

const setLogoutUser = () => ({ type: types.LOGOUT_USER });

const loginUser = (user) => {
  return (dispatch) => {
    // eslint-disable-next-line no-console
    // console.log("user", user);
    const { token } = user;
    intialiseCore(token);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, token);
    } catch {
      // eslint-disable-next-line no-console
      console.log("Local Storage Error");
    }
    ReactGA.set({ userId: user.user.id });
    // dimension1 is the custom dimension created for tracking companyId
    ReactGA.set({ dimension1: user.company.id });
    ReactGA.event({ category: "user", action: "login", label: user.user.id });
    dispatch(setLoginUser(user));
  };
};

const logoutUser = (error, user) => {
  return (dispatch) => {
    if (error) {
      if (error.response?.status === 401)
        showToastWithPosition("Toast.sessionExpired", false, 5000, Position.TOP_LEFT);
      else showToastWithPosition("Toast.somethingWentWrong", false, 5000, Position.TOP_LEFT);
    } else if (user) ReactGA.event({ category: "user", action: "logout", label: user.user.id });
    intialiseCore(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
    dispatch(setLogoutUser());
  };
};

export { loginUser, logoutUser };
