import * as types from "../types/reasons";

const initialState = {
  isLoading: false,
  isError: null,
  reasons: {},
  types: [],
  reasonEdit: { isOpen: false, reason: {} },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.REASONS:
      return { ...state, reasons: payload };
    case types.REASONS_TYPES:
      return { ...state, types: payload };
    case types.EDIT:
      return { ...state, reasonEdit: payload };
    default:
      return state;
  }
}
