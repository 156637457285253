/*
ScreenSpotStatus is a class-based component that fetches target group spot information and passes it down to the SpotStatus component.

1. It is called in Screen.js in Spot Status tab
2. It has a default date range filter from today to seven days from today.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import * as constants from "../../../../../../constants";
import { bindDispatch } from "../../../../../../utils";
import WithFetchList from "../../../../../hoc/withFetchList";
import SpotStatus from "../../../../campaigns/common/SpotStatus";
import { formatDate } from "../../../../campaigns/utils";

const today = new Date();
const dayAfterToday = new Date().setDate(today.getDate() + 7);
const defaultFromDate = today;
const defaultToDate = dayAfterToday;

class ScreenSpotStatus extends Component {
  componentDidMount = async () => {
    const {
      match: {
        params: { screenId },
      },
    } = this.props;

    //default Date range filter is passed in fetchData call
    this.props.fetchData(null, true, [
      { screenId },
      {
        filters: [
          {
            from: new Date(defaultFromDate),
            id: "showDate",
            type: "showDate",
            displayName: `${formatDate(defaultFromDate)}-${formatDate(defaultToDate)}`,
            to: new Date(defaultToDate),
          },
        ],
      },
    ]);
  };

  render = () => {
    const {
      screens: { isScreenSpotStatusLoading, screenSpots },
    } = this.props;
    console.log("screen props", this.props);
    return (
      <SpotStatus
        {...this.props}
        data={{
          spotStatus: screenSpots,
          isLoading: isScreenSpotStatusLoading,
        }}
      />
    );
  };
}

export const ScreenSpotStatusWithFilters = WithFetchList("getScreenSpotStatus", {
  sort: [
    {
      id: "showDate",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(ScreenSpotStatus);

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({ screens, userData: userData.user })
);
export default connect(mapStateToProps, bindDispatch)(withRouter(ScreenSpotStatusWithFilters));
