import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icons } from "prefab";
import { TimePicker } from "@blueprintjs/datetime";
import TimePickerStyle from "../../styles/TimePicker.module.scss";

const { AddToCalendarIcon } = Icons;

export default class InputTimePicker extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    useAmPm: PropTypes.bool,
    showIcon: PropTypes.bool,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
  };
  static defaultProps = {
    useAmPm: false,
    showIcon: false,
    iconWidth: 24,
    iconHeight: 24,
  };
  render() {
    const { value, onChange, showIcon, iconHeight, iconWidth, error, ...rest } = this.props;
    return (
      <div className={classNames(TimePickerStyle.timePicker, error && TimePickerStyle.error)}>
        <TimePicker value={value} onChange={onChange} {...rest} />
        {showIcon && (
          <div className={TimePickerStyle.iconWrapper}>
            <AddToCalendarIcon height={iconHeight} width={iconWidth} />
          </div>
        )}
      </div>
    );
  }
}
