import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormGroup, Popover, Menu, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ICON_POS, BUTTON_TYPE, BaseButton } from "prefab";
import { findIndex } from "lodash";
import styles from "../../styles/Selector.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";
import { CustomMenuItem } from ".";

class Selector extends Component {
  static propTypes = {
    list: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    ]).isRequired,
    selectedList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    ]),
    label: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    closeOnSelect: PropTypes.bool,
    singleSelect: PropTypes.bool,
    showLabelInButton: PropTypes.bool,
    noSelectedIcon: PropTypes.bool,
    defaultIsOpen: PropTypes.bool,
    usePortal: PropTypes.bool,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedList: [],
    closeOnSelect: false,
    singleSelect: false,
    showLabelInButton: true,
    noSelectedIcon: false,
    defaultIsOpen: false,
    usePortal: true,
    disabled: false,
  };

  findSelectedIndex = (list, item) => {
    if (list.length === 0) return -1;
    if (item.id) return findIndex(list, item);
    return list.indexOf(item);
  };

  isEverythingSelected = (list, selectedList) => list.length === selectedList.length;

  onItemSelect = (item) => {
    const { selectedList, singleSelect, onSelect } = this.props;
    // For single select, regardless of the previously selected item, the current item should be selected and so the initial array is empty.
    const selected = singleSelect ? [] : selectedList;
    const selectedIndex = this.findSelectedIndex(selected, item);
    if (selectedIndex > -1) {
      selected.splice(selectedIndex, 1);
    } else {
      selected.push(item);
    }
    onSelect(selected);
  };

  onSelectAll = () => {
    const { selectedList, list, onSelect } = this.props;
    if (this.isEverythingSelected(list, selectedList)) onSelect([]);
    else onSelect(list);
  };

  getSelectedMenuIcon = (item) => {
    const { selectedList, singleSelect, noSelectedIcon } = this.props;
    if (noSelectedIcon || singleSelect || this.findSelectedIndex(selectedList, item) < 0)
      return null;

    return IconNames.SMALL_TICK;
  };

  renderMenu = () => {
    const { list, selectedList, singleSelect, closeOnSelect, noSelectedIcon } = this.props;
    let selectAllIcon;
    if (!noSelectedIcon && !singleSelect)
      selectAllIcon = this.isEverythingSelected(list, selectedList) ? IconNames.SMALL_TICK : "";
    return (
      <Menu className={customMenuStyles.menu}>
        {!singleSelect && (
          <CustomMenuItem
            key="selectAll"
            text="Select All"
            icon={selectAllIcon}
            onClick={this.onSelectAll}
            shouldDismissPopover={closeOnSelect}
          />
        )}
        {list.map((item, index) => (
          <CustomMenuItem
            key={index}
            text={item?.value || item}
            className={classNames({
              [styles.menuItemSelected]: this.findSelectedIndex(selectedList, item) > -1,
            })}
            icon={this.getSelectedMenuIcon(item)}
            onClick={() => this.onItemSelect(item)}
            shouldDismissPopover={singleSelect || closeOnSelect}
          />
        ))}
      </Menu>
    );
  };

  getSelectedText = () => {
    const { selectedList, placeHolder, showLabelInButton, label } = this.props;
    if (selectedList.length === 0) return placeHolder;
    const selectedText = showLabelInButton
      ? label
      : selectedList.map((s) => s?.value || s).join(", ");
    return selectedText;
  };

  render() {
    const {
      error,
      label,
      selectedList,
      showLabelInButton,
      defaultIsOpen,
      usePortal,
      disabled,
      className,
      buttonType,
    } = this.props;
    return (
      <FormGroup
        label={!showLabelInButton && label}
        inline={true}
        className={classNames(styles.container, className, {
          [styles.placeHolderColor]: selectedList.length === 0,
          [styles.error]: error,
        })}
        disabled={disabled}
      >
        <Popover
          popoverClassName={customMenuStyles.popOver}
          usePortal={usePortal}
          disabled={disabled}
          defaultIsOpen={defaultIsOpen}
          content={this.renderMenu()}
          position={Position.BOTTOM_LEFT}
          minimal
        >
          <BaseButton
            iconName="ChevronDownIcon"
            iconPos={ICON_POS.RIGHT}
            buttonText={this.getSelectedText()}
            buttonType={buttonType ?? BUTTON_TYPE.DEFAULT}
            disabled={disabled}
            className={styles.button}
          />
        </Popover>
      </FormGroup>
    );
  }
}

export default Selector;
