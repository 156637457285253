import React from "react";
import styles from "../styles/Catalogue/CategoryName.module.scss";

const CategoryName = (categories) => (
  <div>
    {categories.map((category, index) => {
      let array = [category.categoryName, category.subCategory1Name, category.subCategory2Name];
      array = array.filter(Boolean);
      const arrayLen = array.length;
      return (
        <div key={index} className={styles.category}>
          {array.map((cat, i) => {
            const arrow = arrayLen !== Number(i) + 1 ? " > " : " ";
            return `${cat} ${arrow}`;
          })}
        </div>
      );
    })}
  </div>
);

export default CategoryName;
