import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import Checkbox from "../Checkbox";
import { getTagListByType } from "../../../api";
import { STATIC_FILTER_TAGS, STATIC_FILTER_TAG_LIST } from "../../../constants";
import styles from "../../../styles/FilterSection.module.scss";
import { getTagDisplayName } from "../../../utils";
import SearchFilterSection from "./SearchFilterSection";

class ListFilterSection extends Component {
  state = {
    tagList: [],
    loading: true,
    showMore: false,
  };

  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  componentDidMount() {
    this.getTagList();
  }

  onFilterChange = (filter) => {
    this.props.onFilterChange(filter);
    if (filter?.type) ReactGA.event({ category: "filter", action: "list", label: filter.type });
  };

  getTagList = () => {
    const { tagType } = this.props;
    if (STATIC_FILTER_TAGS.includes(tagType)) {
      return this.updateTagList(STATIC_FILTER_TAG_LIST[tagType]);
    }

    return getTagListByType({ type: tagType, ps: 11 })
      .then(({ data }) => this.updateTagList(data.data))
      .catch(() => this.updateTagList([], true));
  };

  updateTagList = (data, loading = false) =>
    this.setState({
      tagList: data,
      loading,
    });

  render() {
    const { selected, tagType } = this.props;
    const { tagList, loading, showMore } = this.state;
    if (loading) {
      return (
        <div>
          <Checkbox loading onChange={() => {}} checked={false} />
          <Checkbox loading onChange={() => {}} checked={false} />
        </div>
      );
    }
    // Fallback to Search UI if the list grows larger
    if (tagList.length > 10) return <SearchFilterSection {...this.props} />;
    let list,
      showMoreButton = null;
    if (showMore) list = tagList;
    else list = tagList.slice(0, 5);
    if (tagList.length > 5 && !showMore)
      showMoreButton = (
        <div className={styles.showMore} onClick={() => this.setState({ showMore: true })}>
          Show More
        </div>
      );
    const selectedIds = selected.map((s) => s.id);
    return (
      <div>
        {list.map((tag, index) => (
          <Checkbox
            key={index}
            checked={selectedIds.includes(tag.id)}
            label={getTagDisplayName(tag)}
            onChange={() => this.onFilterChange({ type: tagType, ...tag })}
          />
        ))}
        {showMoreButton}
      </div>
    );
  }
}

export default ListFilterSection;
