import "./polyfills";

/**
 * CSS Imports
 * Third party CSS Imports are moved are moved to top to make our custom styles take precedence.
 */
import "basscss/css/basscss-cp.css";
import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "./styles/index.scss";

/**
 * JS Imports
 */
import React from "react";
import { render } from "react-dom";
import Root from "./components/Root";
import configureStore from "./store";
import * as sentry from "./sentry";
import * as ReactGA from "./analytics";

sentry.initialise();
ReactGA.initialise();

const store = configureStore();
const target = document.getElementById("root");
render(<Root store={store} />, target);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NextApp = require("./components/Root").default;
    render(<NextApp store={store} />, target);
  });
}
