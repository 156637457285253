import React from "react";
import { getCategory, getBusinessType } from "../../../../utils";
import TextWithLabel from "../../../common/TextWithLabel";
import styles from "../../../../styles/Verifications/ViewVerification.module.scss";

const compareCategories = (cat1, cat2) => {
  return (
    cat1.categoryId === cat2.categoryId &&
    cat1.subCategory1Id === cat2.subCategory1Id &&
    cat1.subCategory2Id === cat2.subCategory2Id
  );
};

const renderCategories = (data, accessor) => {
  if (data.old && data.old[accessor]) {
    const oldData = data.old[accessor];
    const newData = data[accessor];

    const commonCategories = newData
      .filter((category) => oldData.some((cat) => compareCategories(category, cat)))
      .map((category, index) => <div key={index}>{getCategory(category)}</div>);

    const newCategories = newData
      .filter((category) => !oldData.some((cat) => compareCategories(category, cat)))
      .map((category, index) => (
        <div className={styles.newText} key={index}>
          {getCategory(category)}
        </div>
      ));

    const oldCategories = oldData
      .filter((category) => !newData.some((cat) => compareCategories(category, cat)))
      .map((category, index) => (
        <div className={styles.oldText} key={index}>
          {getCategory(category)}
        </div>
      ));

    return (
      <>
        {commonCategories}
        {oldCategories}
        {newCategories}
        {!commonCategories && !oldCategories && !newCategories && "-"}
      </>
    );
  } else {
    return data[accessor].map((category, key) => (
      <div key={key}>{getCategory(category) || "-"}</div>
    ));
  }
};

const renderAdvertisers = (data, accessor) => {
  if (data.old && data.old[accessor]) {
    const oldData = data.old[accessor];
    const newData = data[accessor];

    const commonAdvertisers = newData
      .filter((advertiser) => oldData.some((adv) => adv.id === advertiser.id))
      .map((advertiser) => <div key={advertiser.id}>{advertiser.name}</div>);

    const newAdvertisers = newData
      .filter((advertiser) => !oldData.some((adv) => adv.id === advertiser.id))
      .map((advertiser) => (
        <div key={advertiser.id} className={styles.newText}>
          {advertiser.name}
        </div>
      ));

    const oldAdvertisers = oldData
      .filter((advertiser) => !newData.some((adv) => adv.id === advertiser.id))
      .map((advertiser) => (
        <div key={advertiser.id} className={styles.oldText}>
          {advertiser.name}
        </div>
      ));

    return (
      <>
        {commonAdvertisers}
        {oldAdvertisers}
        {newAdvertisers}
        {!commonAdvertisers && !oldAdvertisers && !newAdvertisers && "-"}
      </>
    );
  } else {
    return data[accessor].map((advertiser) => (
      <div key={advertiser.id}>{advertiser.name || "-"}</div>
    ));
  }
};

const renderUpdateDetails = (data, accessor) => {
  return (
    <>
      <div className={styles.oldText}>{data.old[accessor]}</div>
      <div className={styles.newText}>{data[accessor]}</div>
    </>
  );
};

const renderLoader = () => (
  <>
    <TextWithLabel key="1" label="Label" text="Text for Loading" loading />
    <TextWithLabel key="2" label="Label" text="Text for Loading" loading />
    <TextWithLabel key="3" label="Label" text="Text for Loading" loading />
    <TextWithLabel key="4" label="Label" text="Text for Loading" loading />
  </>
);

const renderDetails = (data, fields, businessTypesList, loading = false) => {
  if (loading) return renderLoader();
  if (!data || !fields || fields.length < 1) {
    return null;
  }
  return fields.map((field, key) => {
    if (field.name === "Categories") {
      return (
        data[field.accessor] &&
        (data[field.accessor].length > 0 || (data.old && data.old[field.accessor].length > 0)) && (
          <TextWithLabel
            key={key}
            label="Categories"
            text={renderCategories(data, field.accessor)}
            className={styles.customTextWithLabel}
          />
        )
      );
    } else if (field.name === "Advertisers") {
      return (
        data[field.accessor] &&
        (data[field.accessor].length > 0 || (data.old && data.old[field.accessor].length > 0)) && (
          <TextWithLabel
            key={key}
            label="Advertisers"
            text={renderAdvertisers(data, field.accessor)}
            className={styles.customTextWithLabel}
          />
        )
      );
    } else if (field.name === "Type") {
      return (
        <TextWithLabel
          key={key}
          label={field.name}
          text={
            data.old &&
            data.old[field.accessor] &&
            data.old[field.accessor] !== data[field.accessor] ? (
              <>
                <div className={styles.oldText}>
                  {getBusinessType(businessTypesList, data.old[field.accessor])}
                </div>
                <div className={styles.newText}>
                  {getBusinessType(businessTypesList, data[field.accessor])}
                </div>
              </>
            ) : (
              getBusinessType(businessTypesList, data[field.accessor])
            )
          }
          className={styles.customTextWithLabel}
        />
      );
    } else
      return (
        <TextWithLabel
          key={key}
          label={field.name}
          text={
            data.old &&
            data.old[field.accessor] &&
            data.old[field.accessor] !== data[field.accessor]
              ? renderUpdateDetails(data, field.accessor)
              : data[field.accessor] || "-"
          }
          className={styles.customTextWithLabel}
        />
      );
  });
};

export default renderDetails;
