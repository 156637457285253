import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icons } from "prefab";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/Checkbox.module.scss";

const { CheckboxIcon, CheckboxSelectedIcon, AdjustIcon } = Icons;

class Checkbox extends Component {
  static props = {
    checked: PropTypes.bool.isRequired,
    indeterminate: PropTypes.bool,
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    disabled: false,
    label: "",
  };

  render() {
    const {
      iconProps,
      onChange,
      checked,
      indeterminate,
      label,
      loading,
      disabled,
      className,
      DragDropIcon,
    } = this.props;
    let icon = <CheckboxIcon {...iconProps} />;
    if (checked) {
      icon = <CheckboxSelectedIcon {...iconProps} />;
    } else if (indeterminate) {
      icon = <CheckboxIcon {...iconProps} />;
    }
    return (
      <div className={classNames(className, "col-12", { [styles.notAllowed]: disabled })}>
        <div
          className={classNames("flex", {
            [Classes.SKELETON]: loading,
            [styles.container]: !loading,
            [styles.disabled]: disabled,
          })}
          onClick={onChange}
        >
          {DragDropIcon && <AdjustIcon className={styles.adjustIcon} />}
          <div className={styles.checkbox}>{icon}</div>
          {label && <div className={styles.label}>{label}</div>}
        </div>
      </div>
    );
  }
}

export default Checkbox;
