import { mapValues, forIn, union, flatten, uniq } from "lodash";
import titleCase from "./titleCase";

const parseRolesScopes = (data) => {
  const parsed = {};
  parsed.scopeDisplayName = data.scopes;
  parsed.scopes = Object.keys(data.scopes);
  parsed.userTypes = Object.keys(data.roleScopes);
  parsed.rights = mapValues(data.roleScopes, (o) => Object.keys(o));
  const scopeActionsMap = {};
  forIn(data.roleScopes, (user, _) => {
    forIn(user, (right, _) => {
      forIn(right, (role, _) => {
        forIn(role, (actions, scope) => {
          if (scopeActionsMap[scope])
            scopeActionsMap[scope] = union(scopeActionsMap[scope], actions);
          else scopeActionsMap[scope] = actions;
        });
      });
    });
  });
  parsed.roles = {};
  parsed.rolesData = {};
  forIn(data.roleScopes, (user, userKey) => {
    parsed.roles[userKey] = {};
    parsed.rolesData[userKey] = {};
    forIn(user, (right, rightKey) => {
      const rolesByRight = Object.keys(right);
      parsed.roles[userKey][rightKey] = rolesByRight;
      const scopesByRoleRight = uniq(
        flatten(rolesByRight.map((role) => Object.keys(data.roleScopes[userKey][rightKey][role])))
      );
      parsed.rolesData[userKey][rightKey] = flatten(
        scopesByRoleRight.map((scope) =>
          scopeActionsMap[scope].map((action) => {
            const scopeActionData = { scopeAction: `${data.scopes[scope]}.${titleCase(action)}` };
            rolesByRight.forEach((role) => {
              const allowedActions = data.roleScopes[userKey][rightKey][role][scope] || [];
              scopeActionData[role] = allowedActions.includes(action);
            });
            return scopeActionData;
          })
        )
      );
    });
  });
  return parsed;
};

export default parseRolesScopes;
