import React from "react";
import { compact } from "lodash";
import { ActiveTableCell } from "../../components/common/ActiveTableCell";

const _extractAndFlattenLocation = (d) => {
  const { city, province, country } = d;
  if (city) {
    let location = { cityId: city.id, cityName: city.name };
    if (province) location = { ...location, provinceId: province.id, provinceName: province.name };
    if (country) location = { ...location, countryId: country.id, countryName: country.name };
    return location;
  } else return d;
};

const locationColumn = (onIdSelect, isParentOnly) => {
  const accessorKey = isParentOnly ? "parentAccessor" : "accessor";
  return {
    id: "cityName",
    Header: "Location",
    [accessorKey]: (d) => {
      const {
        cityId,
        cityName,
        provinceId,
        provinceName,
        countryId,
        countryName,
      } = _extractAndFlattenLocation(d);
      if (onIdSelect)
        return (
          <div className="text-ellipsize">
            {cityId ? ActiveTableCell(cityId, `${cityName}, `, () => onIdSelect(cityId)) : ""}
            {provinceId
              ? ActiveTableCell(provinceId, `${provinceName}, `, () => onIdSelect(provinceId))
              : ""}
            {countryId
              ? ActiveTableCell(countryId, `${countryName}`, () => onIdSelect(countryId))
              : ""}
          </div>
        );
      else return compact([cityName, provinceName, countryName]).join(", ");
    },
  };
};

export default locationColumn;
