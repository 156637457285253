import React from "react";
import { MenuItem, Icon } from "@blueprintjs/core";
import styles from "../../../src/styles/PopOver.module.scss";
import withScopeAuth from "../hoc/withScopeAuth";

export class CustomMenuItem extends React.Component {
  render() {
    const { icon, scope, userData, popoverProps, isIconRight = true, ...rest } = this.props;
    return (
      <MenuItem
        className={styles.popOverMenuItem}
        labelClassName={styles.popOverIcon}
        labelElement={isIconRight && icon && <Icon icon={icon} />}
        icon={!isIconRight && icon && <Icon icon={icon} />}
        popoverProps={{
          usePortal: false,
          minimal: true,
          ...popoverProps,
        }}
        {...rest}
      />
    );
  }
}

export default withScopeAuth(CustomMenuItem);
