import {
  IS_ERROR,
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_CREATE_UPDATE_LOADING,
  PARENT_BRANDS,
  INDIVIDUAL_BRANDS,
  PRODUCTS,
  VARIANTS,
  BRAND_CONTENT,
  BRAND,
  EDIT_BRAND,
  SEARCH,
  CATEGORY_SEARCH,
  BRAND_TAG_TYPE,
  ADVERTISER_SEARCH,
} from "../types/brands";

const initialState = {
  isLoading: false,
  isCreateUpdateLoading: false,
  isError: null,
  parentBrandList: {},
  individualBrandList: {},
  productList: {},
  variantList: {},
  brandContent: {},
  mediaList: {},
  search: {},
  categorySearch: [],
  targetOrganisation: {},
  targetBrand: {},
  brand: {},
  editBrand: {
    isOpen: false,
    brand: {},
  },
  brandTagType: {},
  advertiserSearch: [],
};
export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IS_LOADING:
      return { ...state, isLoading: payload };
    case IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case IS_CREATE_UPDATE_LOADING:
      return { ...state, isCreateUpdateLoading: payload };
    case IS_ERROR:
      return { ...state, isError: payload };
    case PARENT_BRANDS:
      return { ...state, parentBrandList: payload };
    case INDIVIDUAL_BRANDS:
      return { ...state, individualBrandList: payload };
    case PRODUCTS:
      return { ...state, productList: payload };
    case VARIANTS:
      return { ...state, variantList: payload };
    case BRAND_CONTENT:
      return { ...state, brandContent: payload };
    case BRAND:
      return { ...state, brand: payload };
    case EDIT_BRAND:
      return { ...state, editBrand: payload };
    case SEARCH:
      return { ...state, search: payload };
    case CATEGORY_SEARCH:
      return { ...state, categorySearch: payload };
    case ADVERTISER_SEARCH:
      return { ...state, advertiserSearch: payload };
    case BRAND_TAG_TYPE:
      return { ...state, brandTagType: payload };
    default:
      return state;
  }
}
