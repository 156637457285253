/*
1.The code imports a function called getFailure from an external module.
2.It imports two constants and a set of action types from other files.
3.It defines a function called actionCreator that returns an object with a given type and payload.
4.It defines a function called updateFailureData that returns an object with a type of types.FAILURE_DATA and a given payload.
5.It defines a function called getFailuresData that takes in some parameters and returns an async function.
6.The async function dispatches an action to indicate that it is loading data.
7.It then calls the getList function with some parameters and filters to retrieve data.
8.It dispatches an action to update the failure data with the retrieved data.
9.It dispatches an action to indicate that it is no longer loading data.
10.If there is an error, it dispatches an action to indicate that there is an error and that it is no longer loading data.
*/
import { getFailure } from "../../api";
import * as types from "../types/showFailures";
import * as constants from "../../constants";

const actionCreator = (type, payload) => {
  return { type, payload };
};
const updateFailureData = (payload) => {
  return { type: types.FAILURE_DATA, payload: payload };
};
const getFailuresData = (params, filters = [], getList = getFailure, isPartial = true) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;

  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);
      //dispatch(updateFailureData(data.data));
      dispatch(updateFailureData(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export { getFailuresData };
