import * as types from "../types/cpls";

const initialState = {
  isLoading: false,
  isPartialLoading: false,
  isError: null,
  cpls: {
    data: [],
  },
  mapCPL: {
    isOpen: false,
    cpl: {},
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.CPLS:
      return { ...state, cpls: payload };
    case types.MAP_CPL:
      return { ...state, mapCPL: payload };
    case types.IGNORE_CPL:
      return {
        ...state,
        cpls: {
          ...state.cpls.data,
          totalCount: state.cpls.totalCount - 1,
          data: state.cpls.data.filter((cpl) => cpl.id !== payload),
        },
      };
    default:
      return state;
  }
}
