import React from "react";
import PropTypes from "prop-types";
import { Icons } from "prefab";
import styles from "../../../styles/InfoBlock.module.scss";

const { LocationIcon, CallIcon, EmailAddressIcon } = Icons;

const Contact = ({ name, type, advertiserName, phone, email, location }) => {
  return (
    <div className={styles.contactContainer}>
      <div className={styles.title}>{name}</div>
      <div className={styles.subTitle}>{
        `${type}`
        // - ${advertiserName}
      }</div>
      {phone && (
        <div className={styles.contactInfo}>
          <CallIcon />
          <span>{phone}</span>
        </div>
      )}
      {email && (
        <div className={styles.contactInfo}>
          <EmailAddressIcon />
          <span>{email}</span>
        </div>
      )}
      {location && (
        <div className={styles.contactInfo}>
          <LocationIcon />
          <span>{location}</span>
        </div>
      )}
    </div>
  );
};

Contact.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  advertiserName: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  location: PropTypes.string,
};

Contact.defaultProps = {
  phone: "",
  email: "",
  location: "",
};

export default Contact;
