import React, { Component } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";

export default class EditProductIdentificationNumberType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productIdentificationNumberType: props.productIdentificationNumberType,
      hasEditError: false,
    };
  }

  static propTypes = {
    productIdentificationNumberType: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.productIdentificationNumberType !== prevProps.productIdentificationNumberType)
      this.setState({
        productIdentificationNumberType: this.props.productIdentificationNumberType,
      });
  }

  cancelEdit = () => {
    this.setState({ hasEditError: false });
    this.props.onClose();
  };

  isValid = (productIdentificationNumberType) => {
    if (productIdentificationNumberType.type.trim().length === 0) return false;
    return true;
  };

  save = () => {
    const { productIdentificationNumberType } = this.state;
    if (this.isValid(productIdentificationNumberType)) {
      this.props.onSave(productIdentificationNumberType);
      this.cancelEdit();
    } else this.setState({ hasEditError: true });
  };

  inputOnChange = (value, accessor) => {
    const { productIdentificationNumberType } = this.state;
    this.setState({
      productIdentificationNumberType: { ...productIdentificationNumberType, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { productIdentificationNumberType = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={
          productIdentificationNumberType.id
            ? "RightPanelHeader.editProductIdentificationNumberType"
            : "RightPanelHeader.addProductIdentificationNumberType"
        }
        showFooter={true}
        isEdit={productIdentificationNumberType.id ? true : false}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Name",
              className: "col-12",
              placeholder: "Enter Name",
              value: productIdentificationNumberType.type,
              error: hasEditError,
              errorMessage: "Enter Valid Name",
              onChange: (e) => this.inputOnChange(e.target.value, "type"),
            },
          ]}
        />
      </RightPanel>
    );
  };
}
