import * as types from "../types/advertiserTypes";

const initialState = {
  isLoading: true,
  isError: null,
  advertiserList: {},
  advertiserTypeEdit: { isOpen: false, advertiserType: {} },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.ADVERTISERLIST:
      return { ...state, advertiserList: payload };
    case types.EDIT:
      return { ...state, advertiserTypeEdit: payload };
    default:
      return state;
  }
}
