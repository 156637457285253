import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isNumber, isArray, isEmpty } from "lodash";
import styles from "../../../styles/InfoBlock.module.scss";
import TextWithLabel from "../TextWithLabel";
import Header from "./Header";
import Card from "./Card";

class InfoBlock extends Component {
  static propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    isSubHeader: PropTypes.bool,
    config: PropTypes.array,
    rightSideButtons: PropTypes.array,
    rightSideIcons: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    hideHeader: PropTypes.bool,
    rightSideContainerClass: PropTypes.string,
    className: PropTypes.string,
    cardType: PropTypes.oneOf(["primary", "secondary", "inline"]),
  };

  static defaultProps = {
    isSubHeader: false,
    header: "",
    rightSideButtons: [],
    rightSideIcons: [],
    hideHeader: false,
    className: "",
    config: [],
    cardType: "primary",
  };

  getCardSize = (size) => {
    if (isNumber(size)) {
      return {
        lg: size,
        md: size,
        sm: size,
        xs: size,
      };
    }

    return size;
  };
  renderField = (title, value, size, index) => {
    if (value) {
      return (
        <Card key={index} className={styles.textLabelField} {...this.getCardSize(size)}>
          <TextWithLabel label={title} text={value} />
        </Card>
      );
    }
    return null;
  };

  renderCards = () => {
    const { config } = this.props;
    return config?.map((item, i) => {
      if (isArray(item)) {
        return (
          <div key={i} className={classNames("col col-12 flex flex-wrap", styles.cardList)}>
            {item?.map(({ title, value, size }, index) => {
              if (isEmpty(value)) return null;
              return this.renderField(title, value, size, index);
            })}
          </div>
        );
      } else {
        const { title, value, size } = item;
        return this.renderField(title, value, size, i);
      }
    });
  };

  render() {
    const {
      config,
      children,
      hideHeader,
      className,
      childClassName,
      header,
      cardType,
    } = this.props;

    return (
      <div
        className={classNames("col-12 clearfix", className, styles.block, {
          [styles.primary]: cardType === "primary",
          [styles.secondary]: cardType === "secondary",
          [styles.inline]: cardType === "inline",
        })}
      >
        {!hideHeader && header && <Header {...this.props} />}
        <div
          className={classNames("flex flex-wrap clearfix", childClassName, styles.cardContainer)}
        >
          {config.length > 0 ? this.renderCards() : children}
        </div>
      </div>
    );
  }
}

export default InfoBlock;
