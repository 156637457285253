import * as types from "../types/campaignCreate";

const initialState = {
  isLoading: true,
  isPartialLoading: false,
  isError: false,
  isTabLoading: false,
  isTargetGroupLoading: true,
  isCampaignLoading: true,
  isMediaLoading: true,
  isMediaDetailsLoading: true,
  isTargetGroupDetailsLoading: true,
  error: {},
  activeStep: -1,
  campaign: {
    id: "71b42994-597a-4865-ae9b-935643d1eccb",
    name: "Campaign 1",
    advertiserId: "roilomnsaasascsa",
    billingCycle: {
      id: "26566257-b3b0-4b91-a234-4242fc551e63",
      code: "MONTHLY",
      name: "Monthly",
      rank: 4,
      updatedAt: "2019-08-06T12:59:21.865799Z",
    },
    countryIds: [
      {
        id: "db2b2006-19a3-4337-91e6-1b8e5202bb75",
        name: "Afghanistan",
        iso3166_2: "AF",
        iso3166_3: "AFG",
        currency: { name: "Afghani", symbol: "", alphabetic_code: "AFN" },
        updatedAt: "2020-03-22T00:00:04.587217Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [],
      },
      {
        id: "64ae0708-b31d-4aef-8bc8-b2340e68b343",
        name: "Albania",
        iso3166_2: "AL",
        iso3166_3: "ALB",
        currency: { name: "Lek", symbol: "", alphabetic_code: "ALL" },
        updatedAt: "2020-03-22T00:00:04.586409Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [],
        hasTaxes: false,
      },
      {
        id: "4a081560-cd0c-4d2e-9597-8638c8616500",
        name: "Aland Islands",
        iso3166_2: "AX",
        iso3166_3: "ALA",
        currency: { name: "Euro", symbol: "", alphabetic_code: "EUR" },
        updatedAt: "2020-03-22T00:00:04.58636Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T08:30:36.289499Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-07-11T04:31:49.927947Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-11T04:31:39.774094Z",
          },
        ],
        hasTaxes: true,
      },
    ],
    currencyId: "08732a91-32321f9t4-b1e8-35cba6d7b35d",
    currency: {
      id: "08732a91-32321f9t4-b1e8-35cba6d7b35d",
      name: "United States - USD",
      item: {
        id: "08732a91-32321f9t4-b1e8-35cba6d7b35d",
        country: "United States",
        currency: "USD",
      },
    },
    validity: { fromDate: "2020-03-01", toDate: "2020-10-28" },
    countries: [
      {
        id: "db2b2006-19a3-4337-91e6-1b8e5202bb75",
        name: "Afghanistan",
        iso3166_2: "AF",
        iso3166_3: "AFG",
        currency: { name: "Afghani", symbol: "", alphabetic_code: "AFN" },
        updatedAt: "2020-03-22T00:00:04.587217Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2020-03-16T06:00:58.226156Z",
          },
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:28:41.72433Z",
          },
          {
            contentTypeId: "b7b235f3-b10f-4cfd-a95c-4557cc488737",
            contentTypeCode: "XSN",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T06:48:53.621904Z",
          },
          {
            contentTypeId: "5e1e1324-73a3-4882-a2c7-37ad143793af",
            contentTypeCode: "ADV",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-06-07T12:08:05.165106Z",
          },
          {
            contentTypeId: "33fb61bb-47f6-40b5-9b56-369b6f5fea0a",
            contentTypeCode: "POL",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-13T04:41:02.767049Z",
          },
          {
            contentTypeId: "9941fe0c-c2d6-4c81-b192-699e49cac1c9",
            contentTypeCode: "ADZ",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-09-12T11:59:45.305761Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-06-24T09:39:56.95705Z",
          },
          {
            contentTypeId: "32f71def-9dc0-41c2-a8b3-bb89244f0d89",
            contentTypeCode: "TST",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-04T07:04:54.219015Z",
          },
          {
            contentTypeId: "9ad489ab-37a6-49e2-9060-2936f6f2bf8e",
            contentTypeCode: "FTR",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-05-14T10:01:12.155878Z",
          },
          {
            contentTypeId: "e2d23d89-aa94-4d13-8c36-37b1520dbb43",
            contentTypeCode: "SHR",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-12T12:00:44.618522Z",
          },
        ],
        hasTaxes: true,
      },
      {
        id: "64ae0708-b31d-4aef-8bc8-b2340e68b343",
        name: "Albania",
        iso3166_2: "AL",
        iso3166_3: "ALB",
        currency: { name: "Lek", symbol: "", alphabetic_code: "ALL" },
        updatedAt: "2020-03-22T00:00:04.586409Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [],
        hasTaxes: false,
      },
      {
        id: "4a081560-cd0c-4d2e-9597-8638c8616500",
        name: "Aland Islands",
        iso3166_2: "AX",
        iso3166_3: "ALA",
        currency: { name: "Euro", symbol: "", alphabetic_code: "EUR" },
        updatedAt: "2020-03-22T00:00:04.58636Z",
        updatedAtTag: "b058fcbc-219a-4b4a-a072-78722165ee0a",
        certifications: [
          {
            contentTypeId: "8802ef2f-02c5-4368-88a0-f31b1b2e7f16",
            contentTypeCode: "EPS",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-09-26T08:30:36.289499Z",
          },
          {
            contentTypeId: "d1205a65-7d1a-492b-9872-48935c9bb935",
            contentTypeCode: "HLT",
            hasCertification: false,
            updatedBy: [Array],
            updatedAt: "2019-07-11T04:31:49.927947Z",
          },
          {
            contentTypeId: "f17407f2-e144-4986-ac9f-0443e911e088",
            contentTypeCode: "CARD",
            hasCertification: true,
            updatedBy: [Array],
            updatedAt: "2019-07-11T04:31:39.774094Z",
          },
        ],
        hasTaxes: true,
      },
    ],
    contacts: [
      {
        type: "Person",
        name: "Raghul",
        email: "raghuldpi95@gmail.com",
        phoneNumber: 917639822188,
      },
      {
        type: "Sales Representative",
        name: "Vijay",
        email: "raghuldpi95@gmail.com",
        phoneNumber: 917639822188,
      },
    ],
    advertiserName: "Google Maps",
    billingCycleId: "26566257-b3b0-4b91-a234-4242fc551e63",
    maxPlaysPerScreen: 2000,
    billingAddress: "Chenni",
    billerName: "Raghul",
    paymentTermInDays: "",
    isTaxExempted: true,
    isAdvancePaymentRequired: true,
  },
  campaignTargetGroups: {
    data: [],
  },
  criteriaTheatres: [],
  campaignTargetGroup: {},
  targetGroupAction: "",
  mediaAction: "",
  campaignMedia: {},
  campaignMediaList: {
    data: [],
  },
  theatres: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_PARTIAL_LOADING:
      return { ...state, isPartialLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: Boolean(payload), error: payload };
    case types.IS_TARGET_GROUP_LOADING:
      return { ...state, isTargetGroupLoading: payload };
    case types.IS_MEDIA_DETAILS_LOADING:
      return { ...state, isMediaDetailsLoading: payload };
    case types.IS_TARGET_GROUP_DETAILS_LOADING:
      return { ...state, isTargetGroupDetailsLoading: payload };
    case types.IS_CAMPAIGN_LOADING:
      return { ...state, isCampaignLoading: payload };
    case types.IS_MEDIA_LOADING:
      return { ...state, isMediaLoading: payload };
    case types.CAMPAIGN_STEPS:
      return { ...state, activeStep: payload };
    case types.TARGET_GROUP_ACTION:
      return { ...state, targetGroupAction: payload };
    case types.MEDIA_ACTION:
      return { ...state, mediaAction: payload };
    case types.NEW_CAMPAIGN_DETAILS:
      return { ...state, campaign: payload };
    case types.NEW_CAMPAIGN_TARGET_GROUP: {
      if (payload.summary) {
        const index = state.campaignTargetGroups.data.findIndex((item) => item.id === payload.id);
        if (index === -1) {
          return { ...state, campaignTargetGroup: payload };
        }
        const { campaignTargetGroups } = state;
        campaignTargetGroups.data[index].summary = payload.summary;
        return { ...state, campaignTargetGroups, campaignTargetGroup: payload };
      } else {
        return { ...state, campaignTargetGroup: payload };
      }
    }
    case types.NEW_CAMPAIGN_TARGET_GROUPS:
      return { ...state, campaignTargetGroups: payload };
    case types.CRITERIA_THEATRES:
      return { ...state, criteriaTheatres: payload };
    case types.CRITERIA_SCREENS:
      return { ...state, criteriaTheatres: payload };
    case types.NEW_CAMPAIGN_MEDIA:
      return { ...state, campaignMedia: payload };
    case types.NEW_CAMPAIGN_MEDIA_LIST:
      return { ...state, campaignMediaList: payload };
    default:
      return state;
  }
}
