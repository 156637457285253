import React from "react";
import styles from "../../styles/ScrollableSummary.module.scss";

const SummaryCardItem = ({ primaryLabel, primaryText, secondaryLabel, secondaryText }) => {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.primary}>
        {primaryLabel}
        <span>{primaryText?.toLocaleString("en-IN")}</span>
      </div>
      <div className={styles.secondary}>
        {secondaryLabel}
        <span> {secondaryText}</span>
      </div>
    </div>
  );
};

export default SummaryCardItem;
