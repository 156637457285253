import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import styles from "../../styles/GanttChart.module.scss";

const columns = [
  { type: "string", id: "Position" },
  { type: "string", id: "Name" },
  { type: "date", label: "Start Date" },
  { type: "date", label: "End Date" },
];

export const options = {
  height: 400,
  gantt: {
    trackHeight: 30,
  },
  timeline: {
    groupByRowLabel: true,
    singleColor: "#4374E0",
  },
  alternatingRowStyle: false,
  fontName: "objektiv-mk3",
};
class GanttChart extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    height: PropTypes.string,
  };
  render() {
    return (
      <div style={{ paddingTop: "10px" }}>
        <Chart
          chartType="Timeline"
          data={[columns, ...this.props.rows]}
          width="100%"
          height="40%"
          options={{ ...options, ...this.props.options }}
          className={styles.ganttChart}
        />
      </div>
    );
  }
}

export default GanttChart;
