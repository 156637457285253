import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../../utils";
import Summary from "../../common/Summary";

export const TargetGroupSummary = ({
  targetGroupId,
  actions: { getTargetGroupSummary },
  targetGroups: { targetGroupSummary, isTabLoading },
}) => {
  useEffect(() => {
    getTargetGroupSummary(targetGroupId);
  }, [targetGroupId, getTargetGroupSummary]);

  return <Summary isLoading={isTabLoading} summary={targetGroupSummary} />;
};

const mapStateToProps = createSelector(
  (state) => state.targetGroups,
  (state) => state.userData,
  (targetGroups, userData) => ({ targetGroups, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(TargetGroupSummary);
