import React, { Component } from "react";
import PropTypes from "prop-types";
import * as constants from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import { searchCategories } from "../../../../api";

export default class CreateCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      hasCreateError: false,
      errorMessage: {
        categoryName: "",
        subCategory1Name: "",
        subCategory2Name: "",
      },
    };
  }

  static propTypes = {
    categories: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isError: PropTypes.bool,
    category: PropTypes.object.isRequired,
    getCategoriesList: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isError: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.category !== prevProps.category)
      this.setState({
        category: this.props.category,
      });
  }

  cancelCreate = () => {
    this.props.onClose();
  };

  save = () => {
    const { category } = this.state;
    this.props.actions.createCategory(
      category,
      () => this.props.getCategoriesList(),
      this.cancelCreate,
      (msg) =>
        this.setState({
          hasEditError: true,
          errorMessage: { ...this.state.errorMessage, categoryName: msg },
        })
    );
  };

  onSelect = (value, accessor) => {
    const { category } = this.state;
    this.setState({
      category: {
        ...category,
        [`${accessor}Name`]: value.name,
        //NOTE: if value.name===value.id, its a create new case
        [`${accessor}Id`]: value.id !== value.name ? value.id : "",
      },
    });
  };

  lastVisibleField = (category) => {
    if (category.categoryId === "") {
      return "categoryName";
    } else if (category.subCategory1Id === "") {
      return "subCategory1Name";
    } else if (category.subCategory2Id === "") {
      return "subCategory2Name";
    }
  };

  renderConfig = (category, hasCreateError, errorMessage) => {
    switch (this.lastVisibleField(category)) {
      case "categoryName":
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Category",
            placeholder: "Enter Product Category",
            onSelect: (props) => {
              this.onSelect(props, "category");
            },
            query: category.categoryName,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data.map((item) => ({
                id: item.category.id,
                name: item.category.name,
                tag: item.category.type,
                item: item,
              }));
            },
            fetchAction: (value) => searchCategories(value, "Category"),
            errorMessage: errorMessage.categoryName,
            error: hasCreateError && !category.categoryName,
            allowCreate: true,
          },
        ];

      case "subCategory1Name":
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Category",
            placeholder: "Enter Product Category",
            onSelect: (props) => {
              this.onSelect(props, "category");
            },
            query: category.categoryName,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data.map((item) => ({
                id: item.category.id,
                name: item.category.name,
                tag: item.category.type,
                item: item,
              }));
            },
            fetchAction: (value) => searchCategories(value, "Category"),
            errorMessage: errorMessage.categoryName,
            error: hasCreateError && !category.categoryName,
            allowCreate: true,
          },
          {
            id: "subCategory1Name",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Sub-Category 1",
            placeholder: "Enter Product Sub-Category 1",
            onSelect: (props) => {
              this.onSelect(props, "subCategory1");
            },
            query: category.subCategory1Name,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data
                .filter((item) => item.category.id === category.categoryId)
                .map((item) => {
                  return {
                    id: item.subCategory1.id,
                    name: item.subCategory1.name,
                    tag: item.subCategory1.type,
                    item: item,
                  };
                });
            },
            fetchAction: (value) => searchCategories(value, "Sub Category 1"),
            errorMessage: errorMessage.subCategory1Name,
            error: hasCreateError && !category.subCategory1Name,
            allowCreate: true,
          },
        ];

      default:
        return [
          {
            id: "categoryName",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Category",
            placeholder: "Enter Product Category",
            onSelect: (props) => {
              this.onSelect(props, "category");
            },
            query: category.categoryName,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data.map((item) => ({
                id: item.category.id,
                name: item.category.name,
                tag: item.category.type,
                item: item,
              }));
            },
            fetchAction: (value) => searchCategories(value, "Category"),
            errorMessage: errorMessage.categoryName,
            error: hasCreateError && !category.categoryName,
            allowCreate: true,
          },
          {
            id: "subCategory1Name",
            type: constants.FORM_FIELD_TYPE.API_SEARCH,
            title: "Product Sub-Category 1",
            placeholder: "Enter Product Sub-Category 1",
            onSelect: (props) => {
              this.onSelect(props, "subCategory1");
            },
            query: category.subCategory1Name,
            searchIcon: "right",
            showSubText: false,
            parseResults: (data) => {
              return data
                .filter((item) => item.category.id === this.state.category.categoryId)
                .map((item) => {
                  return {
                    id: item.subCategory1.id,
                    name: item.subCategory1.name,
                    tag: item.subCategory1.type,
                    item: item,
                  };
                });
            },
            fetchAction: (value) => searchCategories(value, "Sub Category 1"),
            errorMessage: errorMessage.subCategory1Name,
            error: hasCreateError && !category.subCategory1Name,
            allowCreate: true,
          },
          {
            type: constants.FORM_FIELD_TYPE.INPUT,
            label: "Product Sub-Category 2",
            placeholder: "Enter Product Sub-Category 2",
            value: category.subCategory2Name,
            onChange: (e) => {
              this.onSelect(
                {
                  id: "",
                  name: e.target.value,
                },
                "subCategory2"
              );
            },
            errorMessage: errorMessage.subCategory2Name,
            error: hasCreateError && !category.subCategory2Name,
          },
        ];
    }
  };

  render = () => {
    const { isOpen } = this.props;
    const { hasCreateError, errorMessage, category } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelCreate()}
        header="RightPanelHeader.addProductCategory"
        showFooter={true}
        isEdit={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelCreate }}
      >
        <Form config={this.renderConfig(category, hasCreateError, errorMessage)} />
      </RightPanel>
    );
  };
}
