import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { FormattedNumber } from "react-intl";
import queryString from "query-string";
import { ExpandTable } from "workbench";
import classNames from "classnames";
import {
  bindDispatch,
  modifyTableColumns,
  checkScopes,
  createdByColumn,
  createdAtColumn,
  formatDateTime,
  isSlateAdminCompanyUser,
  emptyDataMessage,
} from "../../../../utils";
import pageStyles from "../../../../styles/App.module.scss";
import * as constants from "../../../../constants";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableLoading from "../../../common/TableLoading";
import FilterChips from "../../../common/Filters/FilterChips";
import ActionIcon from "../../../common/ActionIcon";
import UserTableAccessor from "../../../common/UserTableAccessor";
import WithFetchList from "../../../hoc/withFetchList";
import TableControls from "../../../common/TableControls";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";

const _LINKS = [
  { name: "PageHeader.campaignApprovals", path: "/campaign-approvals" },
  { name: `Rejected Proposals` },
];

class RejectedProposalsList extends Component {
  state = {
    selectedColumns: [
      "Campaign Name",
      "Advertiser",
      "Campaign Start Date",
      "Campaign End Date",
      "Max Plays per Screen",
      "Max Plays",
      "Rejection Reason",
      "Rejected By",
      "Rejected On",
      "Created By",
      "Created On",
    ],
  };
  componentDidMount = () => {
    this.props.fetchData();
  };
  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  renderNumber(value) {
    if (isNaN(value)) {
      return <span></span>;
    }

    return <FormattedNumber value={value} />;
  }

  render = () => {
    const {
      history,
      campaignApprovals: { rejectedProposals, isLoading },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    if (isLoading && !rejectedProposals.data) return <PageLoadingWithTable />;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = rejectedProposals.totalCount || 0;

    const columns = [
      {
        id: "name",
        Header: "Campaign Name",
        parentAccessor: "name",
        childAccessor: "name",
      },
      {
        id: "createdFor",
        Header: isSlateAdminCompanyUser(userData) ? "Created For" : "Advertiser",
        accessor: (d) =>
          ActiveTableCell(d.advertiserId, d.advertiserName, () =>
            this.props.onFilterIdSelect(d.advertiserId)
          ),
      },
      {
        id: "startDate",
        Header: "Start Date",
        accessor: (d) => formatDateTime(`${d.validity.fromDate} ${d.validity.startTime}`),
      },
      {
        id: "endDate",
        Header: "End Date",
        accessor: (d) => formatDateTime(`${d.validity.toDate} ${d.validity.endTime}`),
      },
      {
        id: "maxPlaysPerScreen",
        Header: "Max Plays per Screen",
        accessor: "maxPlaysPerScreen",
        Cell: (props) => <FormattedNumber id="maxPlaysPerScreen" value={props.value} />,
      },
      {
        id: "maxPlays",
        Header: "Max Plays",
        accessor: "maxPlays",
        Cell: (props) => this.renderNumber(props.value),
      },
      createdAtColumn(),
      createdByColumn(this.props.onFilterIdSelect),
      {
        id: "rejectedAt",
        Header: "Rejected On",
        showTimeZone: true,
        accessor: "rejectedAt",
        Cell: (props) => formatDateTime(props.value),
      },
      {
        id: "rejectedBy",
        Header: "Rejected By",
        accessor: UserTableAccessor("rejectedBy", this.props.onFilterIdSelect),
      },
      {
        id: "rejectionReasonName",
        Header: "Rejection Reason",
        accessor: (d) =>
          ActiveTableCell(d.rejectionReasonId, d.rejectionReasonName, () =>
            this.props.onFilterIdSelect(d.rejectionReasonId)
          ),
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        parentCell: (props) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/campaigns/campaigns/${props.original.id}/details`,
                  iconName: "ViewIcon",
                },
                {
                  toolTip: "Tooltip.logs",
                  scope: constants.SCOPES.BRANDS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  url: `/logs/ref/${props.original.id}`,
                  iconName: "LogsIcon",
                },
              ],
              userData
            )}
          />
        ),
        childCell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.logs",
                url: `/campaigns/target-groups/${props.original.id}/details`,
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <PageHeader name="PageHeader.rejectedProposals" count={rejectedProposals.totalCount} />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={rejectedProposals}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[constants.TAG_TYPE.CAMPAIGNS]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <ExpandTable
              data={rejectedProposals.data || []}
              loading={isLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              childIdentificationKey="targetGroups"
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              emptyDataMessage={emptyDataMessage(
                `EmptyDataMessage.noCampaignFound`,
                `EmptyDataMessage.noRejectedProposalsBody`
              )}
              onRowClick={({ original }) =>
                history.push(`/campaigns/campaigns/${original.id}/details`)
              }
              onChildRowClick={({ original }) =>
                history.push(`/campaigns/target-groups/${original.id}/details`)
              }
            />
            <FooterControls
              pagination
              data={rejectedProposals}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const RejectedProposalsListWithFilters = WithFetchList("getRejectedProposals", {
  sort: [
    {
      id: "rejectedBy",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(RejectedProposalsList);

const mapStateToProps = createSelector(
  (state) => state.campaignApprovals,
  (state) => state.userData,
  (campaignApprovals, userData) => ({ campaignApprovals, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(RejectedProposalsListWithFilters);
