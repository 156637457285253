import React from "react";
import { ActiveTableCell } from "../../components/common/ActiveTableCell";

const getCategoryNames = (categories = [], onFilter) => {
  const catagories = categories.map((category, index) => {
    let array = [category.categoryName, category.subCategory1Name, category.subCategory2Name];
    let categoryIds = [category.categoryId, category.subCategory1Id, category.subCategory2Id];
    array = array.filter(Boolean);
    categoryIds = categoryIds.filter(Boolean);
    const arrayLen = array.length;
    return (
      <div className="text-ellipsize" key={index}>
        {array.map((cat, i) => {
          const arrow = arrayLen !== Number(i) + 1 ? " > " : " ";
          const categoryName = `${cat} ${arrow}`;
          if (onFilter)
            return ActiveTableCell(categoryIds[i], categoryName, () => onFilter(categoryIds[i]));
          else return categoryName;
        })}
      </div>
    );
  });
  return catagories;
};

export default getCategoryNames;
