import { Classes } from "@blueprintjs/core";
import React from "react";
import styles from "../../../../../src/styles/Reports/ReportCard.module.scss";

export default class ReportCardLoading extends React.Component {
  render() {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <div className={styles.cardLogo}>
            <div className={Classes.SKELETON} style={{ height: "100%", width: "100%" }}>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            </div>
          </div>
          <div className={styles.title} style={{ gap: "3px" }}>
            <div className={styles.cardTitle}>
              <div className={Classes.SKELETON}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div>
            </div>
            <div className={styles.titleTypes}>
              <div className={Classes.SKELETON} style={{ height: "100%", width: "100%" }}>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              </div>
            </div>
          </div>
        </div>
        <div
          className={Classes.SKELETON}
          style={{ height: "40px", width: "50%", marginTop: "5px" }}
        >
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </div>
        <div className={styles.dividerLine}></div>
        <span
          className={Classes.SKELETON}
          style={{ height: "20px", width: "100px", marginTop: "5px" }}
        >
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        </span>
      </div>
    );
  }
}
