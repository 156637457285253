import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popover, Position, Menu, Button } from "@blueprintjs/core";
import { Icons } from "prefab";
import styles from "../../../styles/Footer.module.scss";
import { getInitialsFromName } from "../../../utils";
import popOverStyles from "../../../styles/PopOver.module.scss";
import menuStyles from "../../../styles/Menu.module.scss";
import { CustomMenuItem } from "../CustomMenuItem";
import NavProfile from "./NavProfile";

const {
  InfoFilledIcon,
  SupportIcon,
  PinIcon,
  ChevronUpIcon,
  AboutUsIcon,
  PrivacyPolicyIcon,
  TermsOfServiceIcon,
} = Icons;

const Links = [
  { name: "About Us", path: "https://www.qubecinema.com/about-us", Icon: AboutUsIcon },
  {
    name: "Terms of Service",
    path: "https://www.qubecinema.com/terms-use",
    Icon: TermsOfServiceIcon,
  },
  {
    name: "Privacy Policy",
    path: "https://www.qubecinema.com/privacy-policy",
    Icon: PrivacyPolicyIcon,
  },
];

class FooterView extends Component {
  renderMenu = () => {
    const { isAdminApp } = this.props;
    return (
      <Menu
        className={classNames(
          menuStyles.menu,
          styles.infoMenuContainer,
          isAdminApp && styles.admin
        )}
      >
        {Links.map((link, i) => (
          <a
            key={i}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={link.path}
          >
            <CustomMenuItem
              icon={link.Icon && <link.Icon className={styles.popMenuItemIcon} />}
              text={<div className={styles.popupMenuText}>{link.name}</div>}
              tagName="div"
              isIconRight={false}
              shouldDismissPopover={true}
            />
          </a>
        ))}
      </Menu>
    );
  };

  render() {
    const {
      onClick,
      isAdminApp,
      name,
      companyName,
      isActiveMenu,
      isExpanded,
      menus,
      isPinned,
      userData,
    } = this.props;
    return (
      <div
        data-e2e-id="footer"
        className={classNames("cursor", styles.container, { [styles.admin]: isAdminApp })}
      >
        <div className={styles.profile}>
          {isExpanded ? (
            <Button
              className={classNames(styles.toggleButton, {
                [styles.toggleButtonActive]: isPinned,
                [styles.admin]: isAdminApp,
              })}
              minimal
              icon={<PinIcon />}
              onClick={onClick}
            />
          ) : (
            <div className={styles.userInitial}>
              <span>{getInitialsFromName(name)}</span>
              <ChevronUpIcon />
            </div>
          )}
          {isExpanded && (
            <div className="flex align-center col-12 clearfix">
              <NavProfile
                name={name}
                companyName={companyName}
                isActiveMenu={isActiveMenu}
                isExpanded={isExpanded}
                menuList={menus}
                userData={userData}
                isAdminApp={isAdminApp}
              />
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <div className={styles.help}>
            <SupportIcon />
            <span>Help</span>
          </div>
          {isExpanded && (
            <Popover
              portalClassName={popOverStyles.popOver}
              usePortal={true}
              onClose={() =>
                this.setState({
                  isOpen: false,
                })
              }
              content={this.renderMenu()}
              position={Position.TOP_RIGHT}
              minimal
            >
              <div className={styles.info}>
                <InfoFilledIcon />
                <span>Info</span>
              </div>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}

FooterView.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};

export default FooterView;
