import classNames from "classnames";
import { isEmpty } from "lodash";
import { Icons } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FORM_FIELD_TYPE } from "../../../../../constants";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import Form from "../../../../common/Form";
import InfoBlock from "../../../../common/InfoBlock";

const { AddIcon, CancelFilledIcon } = Icons;

export default class ContactDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
  };

  getInitialState = () => ({
    persons: [
      {
        type: "Person",
      },
    ],
    salesPersons: [
      {
        type: "Sales Representative",
      },
    ],
  });

  state = this.getInitialState();

  componentDidMount() {
    this.updateContactDetails();
  }

  componentDidUpdate(prevProps) {
    const {
      campaign: { contacts = [] },
    } = this.props;

    if (prevProps.campaign.contacts.length !== contacts.length) {
      if (contacts.length === 0) {
        this.setState({
          ...this.getInitialState(),
        });
      } else {
        this.updateContactDetails();
      }
    }
  }

  updateContactDetails = () => {
    const {
      campaign: { contacts },
    } = this.props;
    const persons = contacts.filter((c) => c.type === "Person");
    const salesPersons = contacts.filter((c) => c.type === "Sales Representative");

    if (persons.length > 0) {
      this.setState({
        persons: persons,
      });
    }
    //SLATE-1423 Dec7 Anushree:- using if condition instead of else if, as both persons and salesPersons can be present
    if (salesPersons.length > 0) {
      this.setState({
        salesPersons: salesPersons,
      });
    }
  };

  handleInputChange = (index, value, key, type) => {
    const { onInputChange } = this.props;
    const { persons, salesPersons } = this.state;
    if (type === "Person") {
      persons[index][key] = value;
    } else {
      salesPersons[index][key] = value;
    }
    onInputChange([...persons, ...salesPersons], "contacts");
    this.setState({
      persons,
      salesPersons,
    });
  };

  handleRemoveContact = (removedIndex, type) => {
    const { onInputChange } = this.props;
    let { persons, salesPersons } = this.state;
    if (type === "Person") {
      persons = persons.filter((c, i) => i !== removedIndex);
    } else {
      salesPersons = salesPersons.filter((c, i) => i !== removedIndex);
    }
    onInputChange([...persons, ...salesPersons], "contacts");
    this.setState({
      persons: persons.length > 0 ? persons : this.getInitialState().persons,
      salesPersons: salesPersons.length > 0 ? salesPersons : this.getInitialState().salesPersons,
    });
  };

  addNewContact = (type) => {
    const { onInputChange } = this.props;
    const { persons, salesPersons } = this.state;
    const contacts = [...persons, ...salesPersons, { type }];
    onInputChange(contacts, "contacts");
    this.setState({
      persons: contacts.filter((c) => c.type === "Person"),
      salesPersons: contacts.filter((c) => c.type === "Sales Representative"),
    });
  };

  validateAddContact = (data) => {
    return (
      data.filter((item) => {
        //SLATE-1423 Dec7 Anushree:- phoneNumber field is not numeric, checking for empty string instead of isNumber
        //return !isEmpty(item?.name) && isNumber(item?.phoneNumber) && !isEmpty(item?.email);
        return !isEmpty(item?.name) && !isEmpty(item?.phoneNumber) && !isEmpty(item?.email);
      }).length === data.length
    );
  };

  validateEmail = (email) => {
    let errors = "";

    if (!email) {
      errors = "";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors = "Invalid email address";
    }
    return errors;
  };

  // TODO: Change phone number field to number with code
  // TODO: Add Error Message if field becomes mandatory
  renderContactFields = (contact, index, type) => {
    return [
      {
        id: `${type}Name_${index}`,
        label: type === "Person" ? "Contact Person Name" : "Sales Representative Name",
        type: FORM_FIELD_TYPE.INPUT,
        size: {
          lg: 4,
          md: 4,
          sm: 4,
        },
        placeholder: "Enter Name",
        value: contact?.name || "",
        // errorMessage: "Please Enter Name",
        // error: hasFormSaved && !contact?.name,
        onChange: (e) => this.handleInputChange(index, e.target.value, "name", type),
        isOptional: true,
      },
      {
        id: `${type}Email_${index}`,
        label: "Email",
        type: FORM_FIELD_TYPE.INPUT,
        size: {
          lg: 4,
          md: 4,
          sm: 4,
        },
        placeholder: "Enter Email Address",
        value: contact?.email || "",
        errorMessage: this.validateEmail(contact?.email),
        error: contact?.name && this.validateEmail(contact?.email),
        onChange: (e) => this.handleInputChange(index, e.target.value, "email", type),
        isOptional: true,
      },
      {
        //SLATE-1423 Dec7 Anushree:- phone number field is not numeric
        id: `${type}PhoneNumber_${index}`,
        //title: "Phone Number",
        label: "Phone Number",
        //type: FORM_FIELD_TYPE.NUMERIC_INPUT,
        type: FORM_FIELD_TYPE.INPUT,
        size: {
          lg: 3,
          md: 3,
          sm: 3,
        },
        placeholder: "Enter Phone Number",
        value: contact?.phoneNumber || "",
        // errorMessage: "Please Enter Phone Number",
        // error: hasFormSaved && contact?.name && !contact?.phoneNumber,
        //onValueChange: (value) => this.handleInputChange(index, value, "phoneNumber", type),
        onChange: (e) => this.handleInputChange(index, e.target.value, "phoneNumber", type),
        isOptional: true,
      },
    ];
  };

  render() {
    const { salesPersons, persons } = this.state;

    return (
      <InfoBlock header="Contact Details" childClassName={styles.childWrapper}>
        <div className={classNames("flex flex-wrap clearfix", styles.formContainer)}>
          <div className="flex-column clearfix col-12">
            <div className="flex flex-wrap">
              {persons.map((contact, cIndex) => (
                <div key={cIndex} className={classNames("flex col-12", styles.contactList)}>
                  <Form
                    key={cIndex}
                    config={this.renderContactFields(contact, cIndex, "Person")}
                    isFormGroup={false}
                  />
                  {(!isEmpty(contact.name) || !isEmpty(contact.email) || contact.phoneNumber) && (
                    <div
                      className={styles.removeButton}
                      onClick={() => this.handleRemoveContact(cIndex, "Person")}
                    >
                      <CancelFilledIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              className={classNames(styles.addItem, {
                [styles.disabled]: !this.validateAddContact(persons),
              })}
              onClick={() => this.addNewContact("Person")}
            >
              <AddIcon />
              <span>Add Another Contact</span>
            </div>
          </div>
          <div className="flex-column clearfix col-12">
            <div className="flex flex-wrap">
              {salesPersons.map((salesPerson, spIndex) => (
                <div key={spIndex} className={classNames("flex col-12", styles.contactList)}>
                  <Form
                    key={spIndex}
                    config={this.renderContactFields(salesPerson, spIndex, "Sales Representative")}
                    isFormGroup={false}
                  />
                  {(!isEmpty(salesPerson.name) ||
                    !isEmpty(salesPerson.email) ||
                    salesPerson.phoneNumber) && (
                    <div
                      className={styles.removeButton}
                      onClick={() => this.handleRemoveContact(spIndex, "Sales Representative")}
                    >
                      <CancelFilledIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              className={classNames(styles.addItem, {
                [styles.disabled]: !this.validateAddContact(salesPersons),
              })}
              onClick={() => this.addNewContact("Sales Representative")}
            >
              <AddIcon />
              <span>Add Another Sales Person</span>
            </div>
          </div>
        </div>
      </InfoBlock>
    );
  }
}
