import * as types from "../types/rolesScopes";

const initialState = {
  isLoading: true,
  isError: null,
  data: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.DATA:
      return { ...state, data: payload };
    default:
      return state;
  }
}
