import {
  IS_LOADING,
  IS_ERROR,
  BRAND_VERIFICATIONS,
  REJECTED_BRAND_VERIFICATIONS,
  BRAND_VERIFICATION,
  BRAND_DUPLICATES,
  BRAND_VERIFICATION_DRAFT,
  REJECTED_BRAND_VERIFICATION,
  BRAND_DATA,
  IS_BRAND_LOADING,
  IS_CONFLICTS_LOADING,
} from "../types/brandVerifications";

const initialState = {
  isLoading: true,
  isError: null,
  brandVerificationList: {},
  rejectedBrandVerificationList: {},
  brandVerification: {},
  brandVerificationDraft: {},
  brandVerificationDraft2: {},
  brandDuplicates: {},
  rejectedBrandVerification: {},
  brandData: null,
  isBrandLoading: false,
  isConflictsLoading: false,
};
export default function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case IS_LOADING:
      return { ...state, isLoading: payload };
    case IS_ERROR:
      return { ...state, isError: payload };
    case BRAND_VERIFICATIONS:
      return { ...state, brandVerificationList: payload };
    case REJECTED_BRAND_VERIFICATIONS:
      return { ...state, rejectedBrandVerificationList: payload };
    case BRAND_VERIFICATION:
      return { ...state, brandVerification: payload };
    case BRAND_VERIFICATION_DRAFT:
      return { ...state, brandVerificationDraft: payload };
    case BRAND_DUPLICATES:
      return { ...state, brandDuplicates: payload };
    case REJECTED_BRAND_VERIFICATION:
      return { ...state, rejectedBrandVerification: payload };
    case BRAND_DATA:
      return { ...state, brandData: payload };
    case IS_BRAND_LOADING:
      return { ...state, isBrandLoading: payload };
    case IS_CONFLICTS_LOADING:
      return { ...state, isConflictsLoading: payload };
    default:
      return state;
  }
}
