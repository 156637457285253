const IS_CONTENT_LOADING = "content/IS_CONTENT_LOADING";
const IS_CREATE_CONTENT_LOADING = "content/IS_CREATE_CONTENT_LOADING";
const IS_CONTENT_ERROR = "content/IS_ERROR";
const CONTENT = "content/CONTENT";
const ARCHIVED_CONTENT = "content/ARCHIVED_CONTENT";
const LANGUAGES = "content/LANGUAGES";
const CONTENT_DETAILS = "content/CONTENT_DETAILS";
const EDIT_CONTENT = "content/EDIT_CONTENT";
const MAP_BRAND = "content/MAP_BRAND";

export {
  IS_CONTENT_LOADING,
  IS_CREATE_CONTENT_LOADING,
  IS_CONTENT_ERROR,
  CONTENT,
  ARCHIVED_CONTENT,
  LANGUAGES,
  CONTENT_DETAILS,
  EDIT_CONTENT,
  MAP_BRAND,
};
