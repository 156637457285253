const IS_LOADING = "reports/IS_LOADING";
const IS_PARTIAL_LOADING = "reports/IS_PARTIAL_LOADING";
const IS_ERROR = "reports/IS_ERROR";
const ADMIN_GENERATED = "reports/ADMIN_GENERATED";
const USER_GENERATED = "reports/USER_GENERATED";
const SCHEDULED_REPORTS = "reports/SCHEDULED_REPORTS";

export {
  IS_LOADING,
  IS_PARTIAL_LOADING,
  IS_ERROR,
  ADMIN_GENERATED,
  USER_GENERATED,
  SCHEDULED_REPORTS,
};
