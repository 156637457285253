import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../../../utils";
import * as constants from "../../../../constants";
import WithFetchList from "../../../hoc/withFetchList";
import TheatreList from "./TheatreList";

class AdminTheatresList extends Component {
  render = () => {
    return (
      <TheatreList
        {...this.props}
        withStatusLegend={false}
        selectedColumns={[
          "Theatre / Screen",
          "Chain",
          "Location",
          "Companies Associated",
          "Last Updated On",
        ]}
      />
    );
  };
}

export const AdminTheatresListWithFilters = WithFetchList("getAdminTheatresList", {
  sort: [
    {
      id: "name",
      desc: false,
    },
  ],
  ps: constants.DEFAULT_PAGE_SIZE,
  page: 1,
  multiColumnSort: false,
})(AdminTheatresList);

const mapStateToProps = createSelector(
  (state) => state.theatres,
  (state) => state.userData,
  (theatres, userData) => ({ theatres, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(AdminTheatresListWithFilters);
