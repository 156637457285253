import * as types from "../types/user";

const initialState = {
  isLoading: false,
  isError: null,
  user: {
    loggedOut: false,
  },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    case types.LOGIN_USER:
      return { ...state, user: payload };
    case types.LOGOUT_USER:
      return { ...initialState };
    default:
      return state;
  }
}
