import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FORM_FIELD_TYPE } from "../../../../constants";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import { diffBetweenDays } from "../../../../utils/DatePickerUtil";

/*
  SLATE-1338 6 Sep 2023 :- Tax Percentage value was taking value greater than 100% fixed for this bug.
  Not clear about the actual required behaviour
*/

export default class EditTax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tax: {},
      hasEditError: false,
    };
  }

  static propTypes = {
    tax: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  componentDidMount = () => {
    this.setState({
      tax: this.props.tax,
    });
  };

  cancelEdit = () => {
    this.setState({
      hasEditError: false,
      tax: { code: "", taxPercentage: 0, name: "", description: "", validFrom: "" },
    });
    this.props.onClose();
  };

  isNotValid = () => {
    const { tax } = this.state;
    if (
      tax.code.trim().length === 0 ||
      tax.name.trim().length === 0 ||
      tax.description.trim().length === 0 ||
      tax.taxPercentage <= 0 ||
      tax.validFrom.length === 0
    )
      return true;
    if (tax.validTo && tax.validTo !== "Invalid date") {
      if (diffBetweenDays(tax.validFrom, tax.validTo) <= 0) return true;
    }
    return false;
  };

  save = () => {
    const { tax } = this.state;
    if (this.isNotValid(tax))
      return this.setState({
        hasEditError: true,
      });
    this.props.onSave(tax);
    this.cancelEdit();
  };
  onPercentageChange = (value) => {
    const { tax } = this.state;
    this.setState({ tax: { ...tax, taxPercentage: parseFloat(value) } });
  };
  inputOnChange = (value, accessor) => {
    const { tax } = this.state;
    this.setState({ tax: { ...tax, [accessor]: value } });
  };
  formatDateRFC3999 = (data) => moment(data).locale("en").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  onDateChange = (v, key) => {
    const date = this.formatDateRFC3999(v);
    this.inputOnChange(date, key);
  };
  render = () => {
    const { isOpen } = this.props;
    const { tax = {}, hasEditError } = this.state;
    const isValidDate = tax.validTo && tax.validTo !== "Invalid date";
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelEdit()}
        header={tax.id ? "RightPanelHeader.editTax" : "RightPanelHeader.addTax"}
        isEdit={!!tax.id}
        showFooter={true}
        primaryButtonProps={{ text: "Button.save", onClick: this.save }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form
          config={[
            {
              type: tax.id ? FORM_FIELD_TYPE.LABEL : FORM_FIELD_TYPE.INPUT,
              label: "Code",
              title: "Code",
              className: "col-12",
              placeholder: "Enter Code",
              value: tax.code,
              error: hasEditError && tax.code.trim().length === 0,
              errorMessage: "Enter Valid Code",
              onChange: (e) => this.inputOnChange(e.target.value, "code"),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Tax Percentage",
              className: "col-12",
              placeholder: "Enter Tax Percentage",
              inputType: "number",
              allowNumericCharactersOnly: true,
              value: String(tax.taxPercentage),
              // SLATE-1338 5 Sep 2023 :- Boundry Condition added for the tax percentage value must be less than equal to 100
              // error: hasEditError && tax.taxPercentage <= 0,
              error: (hasEditError && tax.taxPercentage <= 0) || tax.taxPercentage > 100,
              errorMessage: "Enter Valid Tax Percentage",
              onChange: (e) => this.onPercentageChange(e.target.value),
            },
            {
              type: FORM_FIELD_TYPE.INPUT,
              label: "Name",
              className: "col-12",
              placeholder: "Enter Name",
              value: tax.name,
              error: hasEditError && tax.name.trim().length === 0,
              errorMessage: "Enter Valid Name",
              onChange: (e) => this.inputOnChange(e.target.value, "name"),
            },
            {
              type: FORM_FIELD_TYPE.TEXT_AREA,
              title: "Description",
              placeholder: "Enter Description",
              value: tax.description,
              error: hasEditError && tax.description.trim().length === 0,
              errorMessage: "Enter Valid Description",
              onChange: (e) => this.inputOnChange(e.target.value, "description"),
            },
            {
              type: FORM_FIELD_TYPE.DATE,
              title: "Valid From",
              error: hasEditError && tax.validFrom.length === 0,
              errorMessage: "Enter Valid Date",
              onChange: (v) => this.onDateChange(v, "validFrom"),
              value: tax.validFrom ? new Date(tax.validFrom) : null,
            },
            {
              type: FORM_FIELD_TYPE.DATE,
              title: "Valid To",
              error: isValidDate
                ? hasEditError && diffBetweenDays(tax.validFrom, tax.validTo) <= 0
                : false,
              errorMessage: "Enter Valid Date",
              onChange: (v) =>
                this.onDateChange(tax.validTo ? v : moment(v).subtract(1, "minutes"), "validTo"),
              value: tax.validTo ? new Date(tax.validTo) : null,
            },
          ]}
        />
      </RightPanel>
    );
  };
}
